import React from 'react';
import PayprofileDTO from '../../../../common/api/dtos/Payprofile';
import TenantDTO from '../../../../common/api/dtos/Tenant';

interface Props {
  tenant: TenantDTO,
  labels: Record<string, string>,
  vatRate?: number,
  payprofile?: PayprofileDTO,
}

export function TenantSection({ labels, tenant, vatRate, payprofile }: Props) {
  return <React.Fragment>
    <span>{labels.provider}</span>
    <h2>{tenant.legalName}</h2><br />
    <span>{labels.tradeRegistry} {tenant.tradeNo}</span>
    <br />
    <span>{labels.taxId} {tenant.taxId}</span>
    <br />
    <span>{labels.shareCapital} {tenant.shareCapital}</span>
    <br />
    <br />
    <span>{labels.street} {tenant.streetAddress}</span>
    <br />
    <span>{tenant.city}, {tenant.state} {tenant.zipCode}</span>
    <br />
    <span>{tenant.country}</span>
    <br />
    <br />
    <span>{labels.bankAccount}<br />{payprofile ? payprofile.bankAccount : ''} {payprofile ? `(${payprofile.currency})` : ""}</span>
    <br />
    <br />
    <span>{labels.email} <span contentEditable suppressContentEditableWarning={true}>{tenant.contactEmail}</span></span>
    <br />
    <br />
    {vatRate && <span>{labels.vatRate} <span>{vatRate}%</span></span>}
  </React.Fragment>
}