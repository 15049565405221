import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import moment from 'moment';
import SyspropDTO from '../../../common/api/dtos/Sysprop';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import SelectControl from '../../controls/SelectControl/SelectControl';
import { listClients } from '../../../common/api/endpoints/clients';
import ClientDTO from '../../../common/api/dtos/Client';
import { Month, Months } from '../../../common/data/Months';
import ToggleControl from '../../controls/ToggleControl/ToggleControl';
import { computeMonthRange } from '../../generics/Invoices/utils';
import { getWorklogsReport } from '../../../common/api/endpoints/worklogs';
import { WorklogDTO } from '../../../common/api/dtos/Worklog';
import ToolbarControls from '../../generics/Header/ToolbarControls';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../TransitionEvent';

export interface Props {
  sysProps: SyspropDTO[],
  loggedUser: LoggedUser,
}

interface Form {
  year: number,
  month: number,
  clientId?: number,
  billable: boolean,
}

interface State {
  status: TRequestStatus,
  years: number[],
  months: number[],
  form: Form,
  clients?: ClientDTO[],
  clientWorklog?: any,
  clientWorkedDays: any,
}

interface WorkedDays {
  [key: string]: number;
}


class DailyTarget extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      years: [],
      months: [],
      form: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        billable: Boolean(localStorage.getItem("daily_target_billable")) || false,
      },
      clientWorkedDays: null,
    }
  }

  fetchClients = async () => {
    this.setState({
      status: 'loading'
    });

    const clients = await listClients();
    const form = this.state.form;

    this.setState({
      status: 'success',
      clients: clients,
      form: {
        ...form,
        clientId: Number(localStorage.getItem("daily_target_client")) || clients[0].id,
      },
    });
  }

  fetchClientsWorklogs = async () => {
    this.setState({
      status: 'loading'
    });

    const monthRange = computeMonthRange(this.state.form.year, this.state.form.month);
    let clientWorklog = await getWorklogsReport(this.state.form.clientId as number, monthRange.since, monthRange.until, true);
    if(this.state.form.billable) {
      let unbillableWorklogs = await getWorklogsReport(this.state.form.clientId as number, monthRange.since, monthRange.until, false);
      clientWorklog.push(...unbillableWorklogs)
    }
    this.setState({
      status: 'success',
      clientWorklog,
      clientWorkedDays: this.getWorkedDays(clientWorklog)
    });
  }

  getWorkedDays = (clientWorklog: WorklogDTO[]) => {
    // WorkedDays - initially contains all of the days in the selected month with 0 hours for each date
    const workedDays: WorkedDays = {}

    clientWorklog.forEach((workLog: any) => {
      let workedDate = moment(workLog.workLog_date).format("YYYY-MM-DD");

      if (workedDays[workedDate]) workedDays[workedDate] += workLog.workLog_hours
      else workedDays[workedDate] = workLog.workLog_hours;
    })

    return workedDays;
  }

  processTotalHours = () => {
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('week');

    const clientWeekWorklogs = this.state.clientWorklog;

    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format("MMMM Do,dddd"));
    };

    let clientWeekWorklogTotal = 0;
    clientWeekWorklogs.forEach((clientWorklog: WorklogDTO) => {
      clientWeekWorklogTotal = clientWeekWorklogTotal + clientWorklog.workLog_hours
    });
  }

  getYearsArray = (yearsString: string): void => {
    this.setState({
      years: yearsString.split(',').map(Number),
    });
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    this.getYearsArray(this.props.sysProps[1].value);
    await this.fetchClients();
    await this.fetchClientsWorklogs();
  }

  setYear = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, yearNo: number | string | undefined) => this.updateForm('year', Number(yearNo));
  setMonth = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, month: number | string | undefined | Month) => this.updateForm('month', Number(month));
  setClient = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, clientId: number | string | undefined | ClientDTO) => {
    this.updateForm('clientId', Number(clientId))
    localStorage.setItem("daily_target_client", String(clientId))
  };
  
  setBillable = (ev: ChangeEvent<HTMLInputElement>) => { 
    this.updateForm('billable', ev.target.checked)
    localStorage.setItem("daily_target_billable", ev.target.checked ? "true" : "")
  }

  formatDate = (when: Date) => {
    return moment(when).utc().format('Do').replaceAll('-', '.')
  };

  updateForm<K extends keyof Form>(field: K, value: Form[K]) {
    const form = this.state.form;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    }, () => {
      this.fetchClientsWorklogs();
    })
  }

  renderDefaultTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th>
          Week
        </th>
        <th className='text-center'>
          Monday
        </th>
        <th className='text-center'>
          Tuesday
        </th>
        <th className='text-center'>
          Wednesday
        </th>
        <th className='text-center'>
          Thusday
        </th>
        <th className='text-center'>
          Friday
        </th>
        <th className='text-center'>
          Saturday
        </th>
        <th className='text-center'>
          Sunday
        </th>
        <th className='text-right'>
          Total
        </th>
      </tr>
    )
  }

  renderDefaultTBodyRow() {

    // Construct moment object from selected year and month
    const momentMonth = moment().year(this.state.form.year).month(this.state.form.month);
    // Get the first day of the first week in the month
    const firstDayOfFirstWeek: number = momentMonth.clone().startOf("M").day() === 0 ? 6 : momentMonth.clone().startOf("M").day() - 1;
    // Get the last date of the month
    const lastDayOfMonth = momentMonth.clone().endOf("M").date();
    // Construct array containing dates that have logged hours
    const workedDates = Object.keys(this.state.clientWorkedDays);
    
    // Construct array containing each day of the selected month with their particular logged hours
    const workedDays: Array<number> = Array.from({ length: lastDayOfMonth },
      (_, i) => {
        let currentDate = momentMonth.date(i + 1).format("YYYY-MM-DD")
        return workedDates.includes(currentDate) ? this.state.clientWorkedDays[currentDate] : 0;
      }
    )

    const rows = []
    // Have to take into account the index of the first day of the first week (acts as an offset)
    for (let i = 0; i < workedDays.length + firstDayOfFirstWeek; i++) {
      // At the start of each week
      if (i % 7 === 0) {
        rows.push(
          <tr key={`row${i}`}>
            {this.buildRow(i, workedDays, firstDayOfFirstWeek, lastDayOfMonth)}
          </tr>
        )
      }
    }

    return rows;
  }

  buildRow(index: number, workedDays: Array<number>, firstDayOfFirstWeek: number, lastDayOfMonth: number) {
    const row = [];
    let dayIndex = index;
    let weekTotal = 0;

    // Row Index
    row.push(<td key={'row' + dayIndex}></td>)

    let firstDayOfCurrentWeek = dayIndex + (dayIndex === 0 ? 1 : (-firstDayOfFirstWeek + 1))
    let lastDayOfCurrentWeek = dayIndex + (7 - firstDayOfFirstWeek)
    
    // Current Week Dates
    row.push(
      <td key={'week' + dayIndex}>
        {this.state.form.month + 1}/
        {firstDayOfCurrentWeek} - {this.state.form.month + 1}
        /{lastDayOfCurrentWeek < lastDayOfMonth ? lastDayOfCurrentWeek : lastDayOfMonth}
      </td>)

    // Row Data (week days)
    for (let j = 0; j < 7; j++) {
      // Current day of the selected month
      dayIndex = index + j;
      let currentValue: number = workedDays[dayIndex - firstDayOfFirstWeek]
      if (dayIndex >= firstDayOfFirstWeek && dayIndex <= (workedDays.length + firstDayOfFirstWeek - 1)) {
        row.push(<td className="text-center" key={dayIndex}>{currentValue === 0 || currentValue === null ? "" : currentValue.toFixed(2)}</td>)
        weekTotal += currentValue;
      } else {
        row.push(<td className="text-center" key={dayIndex}><span className="na"></span></td>)
      }

      dayIndex += 1;
    }

    // Row Total
    row.push(<td className="text-right" key={'total' + dayIndex}>{weekTotal.toFixed(2)}</td>)

    return row;
  }

  renderDefaultTFootRow() {
    const monthTotal = Object.values<number>(this.state.clientWorkedDays).reduce((prev: number, curr: number) => prev + curr, 0)

    return (
      <tr>
        <th className="text-right" colSpan={9}>MONTH TOTAL</th>
        <th className="text-right">{monthTotal.toFixed(2)}</th>
      </tr>
    )
  }

  render() {
    const monthRange = computeMonthRange(this.state.form.year, this.state.form.month);

    return (
      <div>
        <ToolbarControls>
          <SelectControl
            idName="year"
            value={this.state.form.year}
            options={this.state.years}
            onChange={this.setYear}
          />
          <SelectControl
            idName="month"
            value={this.state.form.month}
            options={Months}
            onChange={this.setMonth}
            getValue={(op) => op.number}
            getLabel={(op) => op.name}
          />
          <SelectControl
            idName="client"
            value={this.state.form.clientId}
            options={this.state.clients || []}
            disabledValue={"Clients"}
            onChange={this.setClient}
            getValue={(op) => op.id}
            getLabel={(op) => op?.name}
          />
          <ToggleControl 
          name="billable" 
          id="billable" 
          isChecked={this.state.form.billable}
          changeMethod={this.setBillable}
          labelText="Include Unbillable"
        />
          <div className="form-group">
            <button className="primary-button" onClick={() => window.print()}><span className="static-icon"><span className="fas fa-print"></span></span> <span className="text">Print</span></button>
          </div>
        </ToolbarControls>
        <BreadcrumbControls
          pageTitle="Daily Target Report"
          status={this.state.status}
        />
        <div className="flex-row tightest-top">
          <div className="column">
            <br />
            <h2 className="primary-title">
              {this.state.clients?.find((client: ClientDTO) => client.id === this.state.form.clientId)?.name} Daily Hours Report
            </h2>
            <br />
            <span>{Months[this.state.form.month].name} {this.formatDate(monthRange.since)} - {this.formatDate(monthRange.until)} {this.state.form.year}</span>
          </div>
        </div>

        {/* Rendered Table */}
        <div className="flex-row fill">
          <div className="column">
            <div className="tableview-component">
              <div className="card">
                <table>
                  <thead>
                    {
                      this.renderDefaultTHeadRow()
                    }
                  </thead>
                  <tbody>
                    {
                      this.state.clientWorkedDays && this.renderDefaultTBodyRow()
                    }
                  </tbody>
                  <tfoot>
                    {
                      this.state.clientWorkedDays && this.renderDefaultTFootRow()
                    }
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



export default withTransitionEvent(DailyTarget);