import Joi from 'joi';
import React, { ChangeEvent, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import ClientDTO from '../../../../common/api/dtos/Client';
import { listClientDetails } from '../../../../common/api/endpoints/clients';
import { addProjectGroup, listProjectGroupDetails, updateProjectGroup } from '../../../../common/api/endpoints/projectGroups';
import { FormErrors } from '../../../../common/data/FormErrors';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import SelectControl from '../../../controls/SelectControl/SelectControl';
import TextControl from '../../../controls/TextControl/TextControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../../TransitionEvent';
import ProgressBar, { incrementProgress, IProgress, newProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';

export interface Props extends RouteComponentProps<RouteParams> {
}

interface RouteParams {
  id?: string,
  eid?: string,
  entity?: string,
}

interface State {
  client?: ClientDTO,
  formData: FormData,
  formErrors: FormErrors,
  formStatus: TRequestStatus,
  progress: IProgress,
  status: TRequestStatus,
}

interface FormData {
  clientId: number,
  deleted: boolean,
  name: string,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class ProjectGroupDetail extends Component<Props, State> {
  formSchema = Joi.object({
    clientId: Joi.number().required().messages(FormErrors.number),
    deleted: Joi.boolean(),
    name: Joi.string().trim(true).required().messages(FormErrors.string),
  })

  constructor(props: Props) {
    super(props);
    this.state = {
      formData: {
        clientId: Number(this.props.match.params.eid),
        deleted: false,
        name: ''
      },
      formErrors: {},
      formStatus: 'idle',
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: 'idle' 
    }
  }

  private initialize = async () => {
    this.setState({
      status: 'loading',
    });

    const client = await listClientDetails(this.state.formData.clientId);
    if (isEditPage((Number(this.props.match.params.id)))) {
      this.getProjectGroupDetails();
    }

    this.setState(prevState => {
      return {
        client: client,
        progress: incrementProgress(prevState.progress),
        status: 'idle'
      }
    })
  }

  getProjectGroupDetails = async () => {
    this.setState({
      status: 'loading',
      progress: newProgress(2)
    });

    const projectGroup = await listProjectGroupDetails(Number(this.props.match.params.id));

    this.setState(prevState => {
      return {
        formData: {
          clientId: projectGroup.clientId,
          deleted: projectGroup.deleted,
          name: projectGroup.name
        },
        progress: incrementProgress(prevState.progress),
        status: 'idle'
      }
    })
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading',
    });

    try {
      await addProjectGroup(this.state.formData);
      
      this.props.history.goBack();
      this.setState({
        formStatus: 'success'
      });

    } catch (error) {
      this.setState({
        formStatus: 'error'
      });
    }
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });

    try {
      await updateProjectGroup(this.state.formData, Number(this.props.match.params.id)); 

      this.setState({
        formStatus: 'success'
      })
      
    } catch (error) {
      this.setState({
        formStatus: 'error'
      })
    }
  }
  
  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      if (!isEditPage(Number(this.props.match.params.id))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });
    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      }
    });
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);

  componentDidMount () {
    this.initialize();
  }

  render() {
    const URLParams = this.props.match.params;

    return (
      <div>
        <BreadcrumbControls 
          items={[
            {label: `${URLParams.entity}s`, path: `/${URLParams.entity}`},
            {label: this.state.client?.name ? this.state.client?.name : '', path: `/${URLParams.entity}/edit/${URLParams.eid}`},
            {label: 'project groups', path: `/${URLParams.entity}/edit/${URLParams.eid}/project-groups`},
            {label: `${!isEditPage(Number(this.props.match.params.id)) ? 'adding' : 'editing'} project group ${this.state.formData.name}`},
          ]}
          status={this.state.status}
        />
        <div className="flex-row">
          <div className="column">
            <div className="flex-row tightest-top">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            <div className="card">
              <TextControl
                label="Name"
                type="text"
                name="name"
                id="name"
                onChange={this.setName}
                value={this.state.formData.name}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.name}
              />
              <SelectControl
                idName="clientId"
                label="Client"
                required={true}
                value={this.state.formData.clientId}
                options={[this.state.client] || []}
                disabled={true}
                onChange={() => {}}
                getValue={(op) => op?.id}
                getLabel={(op) => op?.name || ''}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.clientId}
              />
              {isEditPage((Number(this.props.match.params.id))) &&
                <ToggleControl
                  id="deleted"
                  name="deleted"
                  changeMethod={this.setDeleted}
                  isChecked={this.state.formData.deleted}
                  isDisabled={!loadComplete(this.state.progress)}
                  labelText="Deleted"
                />
              }
              <div className="form-group">
                <ButtonControl
                  class="primary-button"
                  disabled={!loadComplete(this.state.progress)}
                  onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.handleSubmit(ev)}
                >
                  <RequestStatus status={this.state.formStatus} />
                  <span className="text">Save</span>
                </ButtonControl>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(ProjectGroupDetail);