import { ErrorPayload } from "../api/endpoints/errorPayload";

export const errorFormatter = (payload: any): ErrorPayload[] => {
  return payload.errors.map((element: any) => {
    return {
      code: element.code,
      subcode: element.validationCode ? element.validationCode : undefined,
      friendlyMessage: element.validationMessage ? element.validationMessage : undefined,
    }
  });
}