import React, { ChangeEvent, Component } from 'react';
import { Link } from 'react-router-dom';
import GearDTO from '../../../../common/api/dtos/Gear';
import { listComputers } from '../../../../common/api/endpoints/computers';
import { listGears } from '../../../../common/api/endpoints/gears';
import Fetch from '../../../../common/helpers/Fetch';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { ComputerDTO } from '../../../../common/interfaces/Computer';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import TextControl from '../../../controls/TextControl/TextControl';
import DateTCell from '../../../utils/DateTCell/DateTCell';
import { withTransitionEvent } from '../../../TransitionEvent';

interface State {
  items?: GearDTO[],
  tableSort: TableSort,
  status: TRequestStatus,
  computers?: ComputerDTO[],
  showDeleted: boolean,
  showSensitive: boolean,
  deleted: boolean,
  filterValue: string,
}

class ComputerList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'type',
    'description',
    'comments',
    'lastUpdate',
    'user.name',
    'user.fullName',
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      filterValue: '',
      status: 'loading',
      tableSort: new TableSort('name', 'asc'),
      showSensitive: false,
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      deleted: false,
    };
  }

  sensitiveTimeout: number = 0;

  toggleSensitive = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.showSensitive ? this.setState({showSensitive: false}) : this.setState({showSensitive: true});
  }

  fetchComputers = async () => {
    this.setState({
      status: 'loading'
    });
    
    const computers = await listComputers();

    this.setState(prevState => {
      return {
        status: 'success',
        computers: computers,
      }
    });
  }

  showDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  componentDidMount() {
    this.fetchComputers();
  }

  componentDidUpdate(prevState: State) {
    if(this.state.showSensitive !== prevState.showSensitive) {
      if(this.state.showSensitive){
        this.sensitiveTimeout = setTimeout(() => {
          this.setState({showSensitive: false});
        }, parseInt(String(process.env.REACT_APP_SENSITIVE_DATA_TIMEOUT))) as unknown as number;
      } else {
        clearTimeout(this.sensitiveTimeout);
      }
    }
  }
  
  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th>
          <SortButton 
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='type' 
            text='Type'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='description' 
            text='Description'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='comments' 
            text='Comments'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          Configuration
        </th>
        <th>
          <SortButton 
            column='lastUpdate' 
            text='Last update'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='user.fullName'
            text='Employee'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(computer: ComputerDTO) {
    return(
      <tr id={`computer${computer.id}`} key={computer.id}>
        <td></td>
        <td>
          <span>{computer.name}</span>
        </td>
        <td>
          <span>{computer.type}</span>
        </td>
        <td>
          <span>{computer.description}</span>
        </td>
        <td>
          <span>{computer.comments}</span>
        </td>
        <td>
          <table className="tableview-component">
            {computer.gears.map(item => (
              <tr>
                <td></td>
                <td>
                  <small className="fas fa-box" style={{color: item.tileColor}}></small>
                </td>
                <td>
                  <Link to={`/item/edit/${item.id}`} className="link-button"> 
                    <span>{item.name}</span>
                  </Link>
                </td>
                <td>
                  <Link to={`/item/edit/${item.id}`} className="link-button"> 
                    <span>{item.description}</span>
                  </Link>
                </td>
              </tr>
            ))}
          </table>
        </td>
        <DateTCell date={computer.lastUpdate} />
        <td>
          <span>{computer.user?.name ? computer.user.name : 'Unassigned'}</span><br />
          <span className={!this.state.showSensitive ? 'blurred-item' : 'blurred-item deblur'}>{computer.user?.fullName}</span>
        </td>
        <td className="text-right">
          <GhostButtonControl to={`/computer/edit/${computer.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }
 
  render() {
    const filteredComputers = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.computers ? this.state.computers : []).sort(this.state.tableSort.sortByColumn).filter((computer: ComputerDTO) => computer.deleted === this.state.showDeleted);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Computers"
          status={this.state.status}
        />
        {this.state.computers && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showSensitive"
                name="showSensitive"
                changeMethod={this.toggleSensitive}
                isChecked={this.state.showSensitive}
                labelText="Sensitive data"
                icon="fas fa-shield-alt"
                srOnly={true}
                title="Sensitive data"
              />
              <ToggleControl
                id="showDeleted"
                name="showDeleted"
                changeMethod={this.showDeleted}
                isChecked={this.state.showDeleted}
                labelText="Recycle bin"
                icon="fas fa-trash-undo"
                srOnly={true}
                title="Recyle bin"
              />
              <div className="form-group">
                <Link to={'/computer/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add computer</span></Link>
              </div>
            </ToolbarControls>

            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                    card
                    ${!this.state.computers ? 'loader-border' : ''}
                    ${this.state.showDeleted ? "flashing-border" : ""}
                  `}
                    >
                      <table>
                        <thead>
                          {this.renderTHeadRow()}
                        </thead>
                        {this.state.computers &&
                        <React.Fragment>
                          <tbody>
                            {filteredComputers.filter((computer: ComputerDTO) => (computer.deleted === this.state.showDeleted)).map(computer => (
                              this.renderTBodyRow(computer))
                            )}
                          </tbody>
                          <tfoot>
                            {this.state.items?.length === 0 &&
                              <tr>
                                <th colSpan={10}>
                                  <div className="card">
                                    <p className="text-chunk">There are no items defined. Start by <Link className="link-button" to="/computer/add">adding</Link> the first one.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={10}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredComputers.length} out of {this.state.computers.filter((computer: ComputerDTO) => computer.deleted === this.state.showDeleted).length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withTransitionEvent(ComputerList);
