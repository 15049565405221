import { ChangeEvent, Component, KeyboardEvent } from 'react';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import { Month, Months } from '../../../common/data/Months';
import SyspropDTO from '../../../common/api/dtos/Sysprop';
import AppContext from '../../../common/context/AppContext';
import ClientDTO from '../../../common/api/dtos/Client';
import ProjectDTO from '../../../common/api/dtos/Project';
import { listClients } from '../../../common/api/endpoints/clients'
import { filterProjects } from '../../../common/api/endpoints/projects';
import { filterUserActivityWorklogs } from '../../../common/api/endpoints/worklogs';
import { computeMonthRange } from '../../generics/Invoices/utils';
import ToolbarControls from '../../generics/Header/ToolbarControls';
import UserDTO from '../../../common/api/dtos/User';
import { listUsers } from '../../../common/api/endpoints/users';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import SelectControl from '../../controls/SelectControl/SelectControl';
import SelectControlMultiple from '../../controls/SelectControlMultiple/SelectControlMultiple';
import { withTransitionEvent } from '../../TransitionEvent';

interface Props {
  sysProps: SyspropDTO[],
}

interface Form {
  year: number,
  month: number,
  selectedUsers: string[],
  selectedClients: string[],
  selectedProjects: string[],
  usersProjects: ProjectDTO[],
  notes: string,
}

interface State {
  status: TRequestStatus,
  years: number[],
  months: number[],
  form: Form,
  users: UserDTO[],
  clients: ClientDTO[],
  projects: ProjectDTO[],
}

class UserActivity extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      years: [],
      months: [],
      form: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        selectedUsers: [],
        selectedClients: [],
        selectedProjects: [],
        usersProjects: [],
        notes: '',
      },
      users: [],
      clients: [],
      projects: [],
    }
  }

  getYearsArray = (yearsString: string): void => {
    this.setState({
      years: yearsString.split(',').map(Number),
    });
  }

  fetchUsers = async () => {
    this.setState({
      status: 'loading'
    });
    
    const users = await listUsers();

    this.setState({
      status: 'success',
      users,
    });
  }

  fetchClients = async () => {
    this.setState({
      status: 'loading',
    });

    const clients = await listClients();
    const form = this.state.form;

    this.setState({
      status: 'success',
      clients: clients,
      form: {
        ...form
      },
    });
  }

  fetchProjects = async () => {
    this.setState({
      status: 'loading',
    });

    const projects = await filterProjects(this.state.form.selectedClients.map(client => Number(client)));
    const form = this.state.form;

    this.setState({
      status: 'success',
      projects: projects,
      form: {
        ...form
      },
    });
  }

  componentDidMount() {
    this.getYearsArray(this.props.sysProps[1].value);
    this.fetchUsers();
    this.fetchClients();
    this.fetchProjects();
  }

  private setUser = (ev: ChangeEvent<HTMLSelectElement>) => {
    const usersOptions = Array.from(ev.target.options);
    const selectedUsers = usersOptions.filter(o => o.selected).map(o => o.value);

    this.setState({
      form: {
        ...this.state.form,
        selectedUsers: selectedUsers,
      },
    })
  }
  private setClients = (ev: ChangeEvent<HTMLSelectElement>) => {
    const clientsOptions = Array.from(ev.target.options);
    const selectedClients = clientsOptions.filter(o => o.selected).map(o => o.value);

    this.setState({
      form: {
        ...this.state.form,
        selectedClients: selectedClients,
      },
    }, () => {
      this.fetchProjects();
    });
  }

  private setProject = (ev: ChangeEvent<HTMLSelectElement>) => {
    const projectsOptions = Array.from(ev.target.options);
    const selectedProjects = projectsOptions.filter(o => o.selected).map(o => o.value);

    this.setState({
      form: {
        ...this.state.form,
        selectedProjects: selectedProjects
      },
    }, () => {
      this.fetchNotes();
    })
  }

  private async fetchNotes() {
    const users = this.state.form.selectedUsers.map(x => Number(x));
    const projects = this.state.form.selectedProjects.map(x => Number(x));
    const { since, until } = computeMonthRange(this.state.form.year, this.state.form.month);
    const notes = await filterUserActivityWorklogs({
      users, projects, from: since, until
    });

    this.setState(prevState => {
      return {
        form: {
          ...prevState.form,
          notes: "- " + notes
          .map(x => x.trim())
          .filter(x => x)
          .join('\n')
          .replaceAll("\t", "")
          .replace(/^\s*\r?\n/gm, '')
          .replace(/ +/g, ' ')
          .replaceAll("\n", "\n- ")
          .replaceAll("- -", "- "),
        }
      };
    });
  }

  private showNotes = () => {
    // Should return a list of notes
  }

  setYear = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, yearNo: number | string | undefined) => this.updateForm('year', Number(yearNo));
  setMonth = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, month: number | string | undefined | Month) => this.updateForm('month', Number(month));
  setNotes = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateForm('notes', String(ev.target.value));

  updateForm<K extends keyof Form>(field: K, value: Form[K]) {
    const form = this.state.form;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    })
  }

  render() {
    return (
      <div>
        <ToolbarControls>
          <SelectControl
            idName="year"
            value={this.state.form.year}
            options={this.state.years}
            onChange={this.setYear}
          />
          <SelectControl
            idName="month"
            value={this.state.form.month}
            options={Months}
            onChange={this.setMonth}
            getValue={op => op.number}
            getLabel={op => op.name}
          />
          <div className="form-group">
            <button className="primary-button" onClick={() => window.print()}><span className="static-icon"><span className="fas fa-print"></span></span> <span className="text">Print</span></button>
          </div>
        </ToolbarControls>
        <BreadcrumbControls
          pageTitle="User Activity"
          status={this.state.status}
        />
        <div className="flex-row tightest-top">
          <div className="column">
            <br />
            <h2 contentEditable>COMANDA</h2>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column">
            <div className="card">
              <div className="flex-row">
                <div className="column small">
                  <div className="flex-row squeeze">
                    <div className="column">
                      <SelectControlMultiple
                        label='Employees'
                        id="userId"
                        name="userId"
                        value={this.state.form.selectedUsers}
                        changeMethod={this.setUser}
                        disabled={this.state.status === 'loading'}
                        options={this.state.users.filter((user: UserDTO) => user.invisible !== true)}
                        classes="mw-small"
                        minHeight={'300px'}
                        getOptionProps={(op: UserDTO) => {
                          return {
                            key: op.id,
                            value: op.id,
                            label: op.fullName ? op.fullName : op.name,
                          }
                        }}
                        multiple={true}
                      />
                    </div>      
                    <div className="column">
                      <SelectControlMultiple
                        label='Clients'
                        id="clientId"
                        name="clientId"
                        value={this.state.form.selectedClients}
                        changeMethod={this.setClients}
                        disabled={this.state.status === 'loading'}
                        options={this.state.clients.filter((client: ClientDTO) => client.enabled !== false)}
                        classes="mw-small"
                        minHeight={'300px'}
                        getOptionProps={(op: ClientDTO) => {
                          return {
                            key: op.id,
                            value: op.id,
                            label: op.name
                          }
                        }}
                        multiple={true}
                      />
                    </div>
                    <div className="column">
                      <SelectControlMultiple
                        label='Projects'
                        id="projectId"
                        name="projectId"
                        value={this.state.form.selectedProjects}
                        changeMethod={this.setProject}
                        disabled={this.state.status === 'loading' || !this.state.projects.length}
                        options={this.state.projects.length ? this.state.projects : ["No projects"]}
                        classes="mw-small"
                        minHeight={'300px'}
                        getOptionProps={(op: ProjectDTO) => {
                          return {
                            key: op.id,
                            value: op.id,
                            label: op.name
                          }
                        }}
                        multiple={true}
                      />
                    </div>
                  </div>
                </div>
                {this.state.form.notes && 
                  <div className='column large'>
                    <div className="text-chunk fill" contentEditable>{this.state.form.notes}</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTransitionEvent(UserActivity);
UserActivity.contextType = AppContext;