import { RawDraftContentState } from "draft-js";
import Joi from "joi";
import React, { ChangeEvent, Component } from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import ProcedureDTO from "../../../../common/api/dtos/Procedure";
import RoleDTO from "../../../../common/api/dtos/Role";
import { FormErrors } from "../../../../common/data/FormErrors";
import Fetch from "../../../../common/helpers/Fetch";
import { isEditPage } from "../../../../common/helpers/isEditPage";
import { loadComplete } from "../../../../common/helpers/LoadComplete";
import { processJoiError } from "../../../../common/helpers/processJoiError";
import { RichTextContent, RichTextHTML } from "../../../../common/helpers/RichText";
import { LoggedUser } from "../../../../common/interfaces/LoggedUser";
import { TRequestStatus } from "../../../../common/types/RequestStatus";
import ButtonControl from "../../../controls/ButtonControl/ButtonControl";
import HTMLControl from "../../../controls/HTMLControl/HTMLControl";
import TextAreaControl from "../../../controls/TextAreaControl/TextAreaControl";
import TextControl from "../../../controls/TextControl/TextControl";
import ToggleControl from "../../../controls/ToggleControl/ToggleControl";
import BreadcrumbControls from "../../../generics/Header/BreadcrumbControls";
import ToolbarControls from "../../../generics/Header/ToolbarControls";
import { withTransitionEvent } from "../../../TransitionEvent";
import ProgressBar, { incrementProgress, IProgress } from "../../../utils/ProgressBar/ProgressBar";
import RequestStatus from "../../../utils/RequestStatus/RequestStatus";

export interface Props extends RouteComponentProps<RouteParams> {
  loggedUser: LoggedUser,
}

interface RouteParams {
  id?: string,
}

interface FormData {
  name: string,
  description: string,
  content: string,
  category: string,
  tags: string,
  deleted: boolean,
}

interface State {
  procedureID?: number,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  roles?: RoleDTO[],
  formData: FormData,
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]? :string;
};

class ProcedureViewer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      procedureID: Number(this.props.match.params.id),
      formData: {
        name: '',
        description: '',
        content: '',
        category: '',
        tags: '',
        deleted: false,
      },
      progress: {
        currentStep: 0,
        totalSteps: 0,
      },
      pageStatus: 'idle',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchProcedureView = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch<ProcedureDTO>(`/procedures/view/${this.state.procedureID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    })

    await fetchRequest.fetch();
    if(fetchRequest.getStatus() === 'success') {
      this.setState((prevState: State) => {
        const procedure = fetchRequest.getPayload();
        return {
          formData: {
            name: procedure.name,
            description: procedure.description,
            content: procedure.content,
            category: procedure.category,
            tags: procedure.tags,
            deleted: procedure.deleted,
          },
          progress: incrementProgress(prevState.progress),
          pageStatus: 'success',
        }
      });
    }
  }

  componentDidMount() {
    if(isEditPage(Number(this.state.procedureID))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1,
          }
        }
      });
      this.fetchProcedureView();
    }
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData, 
        [field]: value
      }
    })
  }

  render() {
    return (
      <React.Fragment>
        <BreadcrumbControls
          items={[
            {label: 'Procedures', path: '/assigned-procedures'},
            {label: `${this.state.formData.name}`},
          ]}
          status={this.state.pageStatus}
        />
        <div className="flex-row">
          <div className="column large">
            <div className="flex-row tightest-top">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            <article className={`
              article-component
              card
              ${!loadComplete(this.state.progress) ? 'loader-border' : ''}`}
            >
              <section className="article-header">
                <span className="faint-text">{this.state.formData.description}</span>
              </section>
                {!loadComplete(this.state.progress) ?
                  <>
                    . . .
                  </>
                :
                  <hr />
                }
              <section className="article-body">
                  <div dangerouslySetInnerHTML={{__html: RichTextHTML(this.state.formData.content)}}></div>
              </section>
            </article>
            {["Admin", "Manager", "Accountant", "Reviewer"].includes(this.props.loggedUser.role.name) &&
              <div className="flex-row">
                <div className="column">
                  <div className="form-group">
                    <Link to={`/procedure/edit/${this.props.match.params.id}`} className="primary-button"><span className="static-icon"><span className="fas fa-pencil"></span></span> <span className="text">Edit</span></Link>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTransitionEvent(ProcedureViewer);