import Fetch from "../../helpers/Fetch";
import SyspropDTO from "../dtos/Sysprop";

interface AddPayload {
  name: string,
  value: string,
  description: string,
}

export async function listSysprops(): Promise<SyspropDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SyspropDTO[]>(`/sysprops`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch sysprops');
}

export async function listSysPropDetails(syspropId: string): Promise<SyspropDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SyspropDTO>(`/sysprops/${syspropId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch sysprop details');
}

export async function addSysprop(form: AddPayload): Promise<SyspropDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SyspropDTO>(`/sysprops`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add sysprop`);
}

export async function updateSysprop(form: AddPayload, syspropId: string): Promise<SyspropDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SyspropDTO>(`/sysprops/${syspropId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update sysprop`);
}