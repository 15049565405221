import Fetch from "../../helpers/Fetch";
import { ClockingDayDTO } from "../dtos/Clocking";

export interface ClokingData {
  userId: number,
  contractId: number,
  dateFrom: string,
  dateTo: string
}

export async function listClockingDays(clockingData: ClokingData): Promise<ClockingDayDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch(`/clockings?userId=${clockingData.userId}&contractId=${clockingData.contractId}&dateFrom=${clockingData.dateFrom}&dateTo=${clockingData.dateTo}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch all clocking days for period`);
  // return dummyData;
}

export async function createClockingDay(clockingDay: ClockingDayDTO) {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ClockingDayDTO>(`/clocking`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(clockingDay)
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to post clocking day`);
}

export async function updateClockingDay(clockingDay: ClockingDayDTO, clockingDayId: number) {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ClockingDayDTO>(`/clocking/${clockingDayId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(clockingDay)
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update clocking day`);
}

export async function getClockingReportToken() {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch(`/clockings/download-token`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to get clockings report download token`);
}