import React, { Component } from 'react';

type Options<T> = Array<number | string | T>

interface Props<T> {
  id?: string,
  name?: string,
  value?: string | string[] | number | undefined,
  changeMethod?: (ev: React.ChangeEvent<HTMLSelectElement>) => void,
  
  getOptionProps?: (option: T, index: number) => { key: number, value: number, label: string }
  options?: Options<T>,
  disabledValue?: string,
  disabled?: boolean,
  autoComplete?: string,
  label?: string,
  multiple?: boolean,
  onBlur?: (ev: React.FocusEvent<HTMLSelectElement>) => void,
  error?: string,
  classes?: string,
  groupClasses?: string,
  required?: boolean,
  minHeight?: string,
}

class SelectControlMultiple<T> extends Component<Props<T>> {
  render() {
    return (
      <div className={[
        `form-group`,
        this.props.groupClasses
      ].join(" ")}
      >
        {this.props.label && (
          <>
            <label htmlFor={this.props.id}>{this.props.label}{this.props.required && '*'}</label>
            <br />
          </>
        )}
        <select
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.changeMethod}
          disabled={this.props.disabled}
          autoComplete={this.props.autoComplete}
          multiple={this.props.multiple}
          onBlur={this.props.onBlur}
          className={this.props.classes}
          aria-required={this.props.required}
          aria-invalid={!!this.props.error}
          style={{
            "minHeight": this.props.minHeight ? this.props.minHeight : ""
          }}
        > 
        {this.props.children}
        {this.props.options && 
          this.props.options.map((option, index) => {
            if (typeof option === 'number') {
              return <option key={option} value={option}>{option}</option>
            }

            if (typeof option === 'string') {
              return <option key={option} value={option}>{option}</option>
            }

            if (! this.props.getOptionProps) {
              throw Error("Expected a 'getOptionProps' prop when passing non-number/non-string options");
            }

            const optionProps = this.props.getOptionProps(option, index);
            return <option key={optionProps.key} value={optionProps.value}>{optionProps.label || "[Unknown option]"}</option>;
          })
        }
      </select>
      {this.props.error ? <ul className="error-list light"><li>{this.props.error}</li></ul> : ''}
      </div>
    )
  }
}

export default SelectControlMultiple;