import moment from "moment";
import ContractDTO from "../../../common/api/dtos/Contract";
import HolidayDTO from "../../../common/api/dtos/Holiday";
import SuspensionDTO from "../../../common/api/dtos/Suspension";
import UserDTO from "../../../common/api/dtos/User";
import { LoggedUser } from "../../../common/interfaces/LoggedUser";

export interface suspensionEntry {
  user: UserDTO,
  date: Date,
  startDate: Date,
  endDate: Date,
  countable: boolean,
  deleted: boolean,
  description: string,
  contractId: number,
  id: number,
  contract: ContractDTO
}

export interface ContractPair {
  id: number,
  name: string,
  startDate: Date,
  endDate: Date | null,
  deleted: boolean
}

export function processSuspensions (suspensions: SuspensionDTO[], currentYear: number): suspensionEntry[] {   
  const processedSuspensions: suspensionEntry[] = [];
  const filteredSuspensions = suspensions.filter((suspension: SuspensionDTO) => ((suspension.deleted !== true) && (new Date(suspension.endDate).getFullYear() === currentYear)));

  filteredSuspensions.map((suspension: SuspensionDTO) => {
    let suspensionEntry: suspensionEntry;

    getDatesInInterval(suspension.startDate, suspension.endDate).forEach((date: Date) => {
      if (isWeekend(date)) {
        return;
      }

      suspensionEntry = {
        ...suspension,
        date: date,
      }
      processedSuspensions.push(suspensionEntry);
    })
  })
  
  return processedSuspensions;
}

export function getDatesInInterval(startDate: Date, endDate: Date) {
  var arrOfDays = new Array();
  var date = new Date(startDate);

  while (date <= new Date(endDate)) {
    arrOfDays.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return arrOfDays;
}

export function isWeekend (inputDate: Date): boolean {
  return moment(inputDate).isoWeekday() >= 6;
}

export function getDaysInMonth (month: number, year: number): number {
  return new Date(year, month + 1, 0).getDate();
}

export function trimDate (date: Date | null) {
  if (date !== null) return date.toISOString().split('T')[0];
}



export function hasElevatedAccess (loggedUser: LoggedUser): boolean {
  const hasElevatedAccess = loggedUser.role.name === 'Admin' || 
                            loggedUser.role.name === 'Manager' || 
                            loggedUser.role.name === 'Accountant' || 
                            loggedUser.role.name === 'Reviewer';
                            
  return hasElevatedAccess;
}

export function hasFullAccess (loggedUser: LoggedUser): boolean {
  const hasFullAccess = loggedUser.role.name === 'Admin' || 
                        loggedUser.role.name === 'Manager';
  
  return hasFullAccess;
}

export function hasManagerAccess (loggedUser: LoggedUser): boolean {
  return loggedUser.role.name === 'Manager';  
}

export function getHolidayName (holidays: HolidayDTO[], date: string): string {
  let holidayName: string = '';

    holidays.forEach((holiday:HolidayDTO) => {
      if(holiday.deleted) return;
      if (trimDate(new Date(holiday.date)) === date) {
        holidayName = holiday.name;
      }
    });
    
    return holidayName;
}

export function formatHour (input: string) {
  let formattedInput = '';
  if (input === null || input ===undefined || input.length === 0) return;

  switch (input.length)  {
    case 1:
      if (input.indexOf(':') !== -1 ) {
        formattedInput = '00:00';
      } else {
        formattedInput = input.padStart(2, '0').padEnd(5, ':00');
      }
      break;
    case 2:
      if (input.indexOf(':') !== -1 ) {
        formattedInput = input.substring(0, input.indexOf(':')).padStart(2, '0') + input.substring(input.indexOf(':'), input.length).padEnd(3, '0');
      } else {
        formattedInput = input.padEnd(5, ':00');
      }
      break;
    case 3:
      if (!input.includes(':')) {
        formattedInput = (input.substring(0, 2) + ':' + input.substring(2, input.length)).padEnd(5, '0');
      } else if (input.indexOf(':') < 2) {
        formattedInput = input.substring(0, input.indexOf(':')).padStart(2, '0') + input.substring(input.indexOf(':'), input.length).padEnd(3, '0');
      } else {
        formattedInput = input.padEnd(5, '0');
      }
      break;
    case 4:
      if (!input.includes(':')) {
        formattedInput = (input.substring(0, 2) + ':' + input.substring(2, input.length)).padEnd(5, '0');
      } else if (input.indexOf(':') < 2) {
        formattedInput = input.substring(0, input.indexOf(':')).padStart(2, '0') + input.substring(input.indexOf(':'), input.length).padEnd(2, '0');
      } else {
        formattedInput = input.padEnd(5, '0');
      }
      break;
    case 5:
      if (!input.includes(':')) {
        formattedInput = input.substring(0, 2) + ':' + input.substring(2, 4);
      } else {
        formattedInput = input;
      }
      break;
    default: 
      formattedInput = input;
      break;
  }

  return formattedInput;
}