import moment from 'moment';
import React, { Component } from 'react';
import { Holiday } from '../../../common/interfaces/Holiday';

export interface Props {
  holidays?: Holiday[],
}

interface State {
  nextHolidays: Holiday[]
}

class Holidays extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      nextHolidays: this.setNextHolidays()
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.holidays !== prevProps.holidays) {
      this.setState({ nextHolidays: this.setNextHolidays() });
    }
  }

  setNextHolidays = (): Holiday[] => {
    if(this.props.holidays) {
      let currentDate = new Date();
      let holidays: Holiday[] = new Array();
      
      this.props.holidays.forEach((holiday) => {
        var holidayMoment = moment(new Date(holiday.date));
        if(holidayMoment.isoWeekday() !== 6 && holidayMoment.isoWeekday() !== 7 && holidayMoment.isAfter(currentDate)) {
          holidays.push(holiday);
        }
      });
      
      return holidays.slice(0,3);
    }

    return []
  }

  calcDaysUntil(needle: Date): number {
    let today = new Date();
    needle = new Date(needle);
    if (today.getMonth() === 11 && today.getDate()>25) {
      needle.setFullYear(needle.getFullYear()+1); 
    }  
    let oneDay=1000*60*60*24;
    return Math.ceil((needle.getTime()-today.getTime())/(oneDay));
  }

  renderNextHolidays() {
    if (!this.props.holidays) {
      return null;
    }

    const nextHolidays = this.setNextHolidays();
    if (nextHolidays.length < 1) {
      return null;
    }

    return (
      <React.Fragment>
        <li className="next">There are <strong>{this.calcDaysUntil(nextHolidays[0].date)}</strong> days left until <strong>{nextHolidays[0].name}</strong>. The holiday takes place on {moment(new Date(nextHolidays[0].date)).format('LL')}.</li>
        {nextHolidays.slice(1,2).map((holiday) => {
          return (
            <li key={`${holiday.id}`}>
              {holiday.name}
              <br />
              <small>{moment(new Date(holiday.date)).format('LL')}</small>
            </li>
          )
        })}
      </React.Fragment>
    )
  }

  renderCurrentHoliday() {
    let currentDate = new Date();
    
    if (!this.props.holidays) {
      return null;
    }

    let currentHoliday: Holiday | undefined;

    this.props.holidays.some((holiday) => {
      let holidayDate = new Date(holiday.date);
      if(
        holidayDate.getFullYear() === currentDate.getFullYear() &&
        holidayDate.getMonth() === currentDate.getMonth() &&
        holidayDate.getDate() === currentDate.getDate()
      ) {
        return currentHoliday = holiday;
      }
    });

    return (
      <React.Fragment>
        {currentHoliday &&
          <li className="current">
            Today is <strong>{currentHoliday.name}</strong>. The office is closed!
          </li>
        }
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <h2 className="primary-title">Holidays</h2>
        <div
          className={`
            card holiday-widget-component
            ${!this.props.holidays ? 'loader-border' : ''}
          `}
        >
          {!this.props.holidays &&
            <div className="card text-center">
              rounding up holidays...
            </div>
          }
          <ul>
            {this.renderCurrentHoliday()}
            {this.renderNextHolidays()}
          </ul>
        </div>
      </React.Fragment>
    )
  }
}

export default Holidays;
