import React, { ChangeEvent, Component } from 'react';
import { ColorGroup } from '../../../common/interfaces/ColorGroup';

interface Props {
  caption: string,
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void,

  options?: string[],
  colors?: ColorGroup[],
  optionType?: string,
  styleColor?: string,
  defaultChecked?: string,
}

class RadioGroup extends Component<Props> {
  render() {
    return (
        <fieldset className="form-group radio-group">
          <legend>{this.props.caption}</legend>
          {this.props.options && this.props.options.map((option, index) => (
            <div className="avatar-tile" key={this.props?.defaultChecked?.concat(index.toString())}>
              <React.Fragment >
                <input
                  type="radio"
                  name={this.props.caption.replace(/\s/g,'')}
                  id={option !== "" ? option : "none" + this.props.caption.replace(/\s/g,'')}
                  value={option}
                  onChange={this.props.onChange}
                  defaultChecked={option === this.props.defaultChecked}
                />
                <label
                  htmlFor={option !== "" ? option : "none" + this.props.caption.replace(/\s/g,'')}
                >
                  {option !== "" &&
                    <img src={`${process.env.REACT_APP_ASSETS}/avatars/${this.props.optionType}/${this.props.optionType == 'eyes' ? 'thumbs/' : ''}${option}${this.props.styleColor ? '-' + this.props.styleColor : ''}.png`} alt={this.props.caption.replace(/\s/g,'')}/>
                  }
                  <span className="">{option.slice(option.indexOf("-") + 1, option.length)}</span>
                  <div className="selection-helper"></div>
                </label>
              </React.Fragment>
            </div>
          ))}
          {this.props.colors && this.props.colors.map((color, index) => (
            <div className="avatar-tile" key={this.props?.defaultChecked?.concat(index.toString())}>
              <React.Fragment >
                <input 
                  type="radio"
                  id={`${this.props.optionType}-${color.colorName}`}
                  name={this.props.caption.replace(/\s/g,'')}
                  className=""
                  value={color.colorName}
                  onChange={this.props.onChange}
                  defaultChecked={color.colorName.replace(/\s/g,'') === this.props.defaultChecked}
                  />
                <label
                  htmlFor={`${this.props.optionType}-${color.colorName}`}
                  style={{background: color.colorHex}}
                  title={color.colorName}
                  className="color-badge"
                >
                  <span className="">{color.colorName !== "" ? color.colorName : color.colorHex}</span>
                  <div className="selection-helper"></div>
                </label>
              </React.Fragment>
            </div>
          ))}
        </fieldset>
    )
  }
}

export default RadioGroup;
