import moment from 'moment';
import React, { Component, CSSProperties } from 'react';
import SyspropDTO from '../../../common/api/dtos/Sysprop';
import { getWeather, IWeather } from '../../../common/api/endpoints/weather';
import AppContext from '../../../common/context/AppContext';
import GenericCard from '../../utils/GenericCard/GenericCard';

export interface Props {
  welcomeTheme: string;
}

interface State {
  now: Date,
  weather: IWeather | null,
}

export interface WelcomeTheme {
  name: string,
  dawn: CSSProperties,
  day: CSSProperties,
  night: CSSProperties
}

interface MomentOfDay {
  backgroundImage: CSSProperties,
  backgroundPosition: CSSProperties,
  weatherPosition: string
}

class Welcome extends Component<Props, State> {
  timeUpdate = setInterval(() => {});
  context!: React.ContextType<typeof AppContext>;

  fetchWeather = async () => {
    const showWeather = localStorage.getItem(this.context.loggedUser!.id + 'showWeather') === 'false' ? false : true;
    if(!showWeather) return false;

    try {
      const weather: IWeather = await getWeather();
      this.setState({
        weather: weather,
      });
    } catch (err) {
      console.error(err);
    }
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      now: new Date(),
      weather: null,
    };
  }

  momentOfDay = () => {
    if(this.state.now.getHours() < 4) {
      return 'night';
    } else if(this.state.now.getHours() < 12) {
      return 'dawn';
    } else if(this.state.now.getHours() < 20) {
      return 'day';
    } else {
      return 'night';
    }
  }

  componentDidMount() {
    if(process.env.REACT_APP_PROD == "true") {
      this.fetchWeather();
    }
    setInterval(this.fetchWeather, 3600000);
    this.timeUpdate = setInterval(() => {
      this.setState({
        now: new Date()
      })
    }, 1000);
  }

  evalWelcomeTheme(): MomentOfDay {
    const welcomeThemes = JSON.parse((this.context.sysProps?.find(sysProp => sysProp.name === 'welcomeThemes') as SyspropDTO).value);
    const selectedTheme = welcomeThemes.find((theme: WelcomeTheme) => theme.name === this.props.welcomeTheme)
    const defaultTheme = welcomeThemes.find((theme: WelcomeTheme) => theme.name === 'default');

    if (selectedTheme !== undefined) {
      return selectedTheme[this.momentOfDay()];
    } else {
      return defaultTheme[this.momentOfDay()];
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeUpdate);
  }

  render() {
    const showWeather = localStorage.getItem(this.context.loggedUser!.id + 'showWeather') === 'false' ? false : true;
    const selectedTheme = this.evalWelcomeTheme();

    return (
      <React.Fragment>
          <section className="welcome-widget-component hidden-mobile">
            <div
              className={`
                card info-card
                ${this.momentOfDay()}
                ${!this.context.loggedUser ? 'loader-border' : ''}
              `}
            >
              <div
                className="bg-helper"
                style={({backgroundImage: selectedTheme.backgroundImage, backgroundPosition: selectedTheme.backgroundPosition}) as CSSProperties}
              >
              </div>
              {this.context.loggedUser &&
                <React.Fragment>
                  <div 
                    className={`
                      content-wrapper card backdrop
                      ${this.momentOfDay()}
                    `}
                  >
                    <h2 className="primary-title">
                        Hello {this.context.loggedUser.name}
                    </h2>
                    <div className="body-text">
                      <span>
                        Today is <strong>{moment(new Date(this.state.now)).format('LL')}</strong>.
                        It's <strong>{moment(new Date(this.state.now)).format('HH:mm')}</strong> in Romania.
                      </span>
                      <br />
                      <br />
                      <br />
                      <br />
                      <p dangerouslySetInnerHTML={{ __html: this.context.sysProps?.find((prop: SyspropDTO) => prop.name === 'welcomeText')?.value || ""}}></p>
                    </div>
                  </div>
                </React.Fragment>
                
              }
                {(showWeather && this.state.weather) && 
                  <div className={selectedTheme.weatherPosition}>
                    <div className="card-list-component">
                      <GenericCard
                        cardTitle={`${(Math.round(this.state.weather.main.temp || NaN)).toString()}° C`}
                        thumbnailURL={`${process.env.REACT_APP_WEATHER_PATH}${this.state.weather.weather[0].icon}.svg`}
                        thumbnailBackground='transparent'
                        avatarClasses="medium"
                        classes="backdrop"
                        >
                        <>
                          in {this.state.weather.name} but it feels like <span className="no-wrap">{Math.round(this.state.weather.main.feels_like || NaN)}° C</span>.
                        </>
                      </GenericCard>
                    </div>
                  </div>
                }
            </div>
          </section>
      </React.Fragment>
    );
  }
}

export default Welcome;

Welcome.contextType = AppContext;