import React, { ChangeEvent, Component } from 'react';
import GearDTO from '../../../../common/api/dtos/Gear';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import AppContext from '../../../../common/context/AppContext';
import { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import TableSort from '../../../../common/helpers/TableSort';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import TextControl from '../../../controls/TextControl/TextControl';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import Thumbnail from '../../../utils/Thumbnail/Thumbnail';
import { listUserSecukeys } from '../../../../common/api/endpoints/secukeys';
import { listUserSecucards } from '../../../../common/api/endpoints/secucards';
import { listUserComputers } from '../../../../common/api/endpoints/computers';
import { listUserGears } from '../../../../common/api/endpoints/gears';
import { ComputerDTO } from '../../../../common/interfaces/Computer';
import SecucardDTO from '../../../../common/api/dtos/Secucard';
import { SecukeyDTO } from '../../../../common/api/dtos/Secukey';

export interface Props {

}

interface State {
  progress: IProgress,
  status: TRequestStatus,
  tableSort: TableSort,
  filterValue: string,
  gears: GearDTO[],
  computers: ComputerDTO[],
  secucards: SecucardDTO[],
  secukeys: SecukeyDTO[],
  userDetails: object[]
}

class GearList extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      gears: [],
      computers: [],
      secucards: [],
      secukeys: [],
      userDetails: [],
      status: 'loading',
      tableSort: new TableSort('name', 'asc'),
      filterValue: '',
    }
  }

  private incrementProgress = <T,>(result: T) => {
    this.setState(prevState => {
      return {
        progress: incrementProgress(prevState.progress),
      }
    });

    return result;
  }

  groupOverArrays = ((gears: GearDTO[], computers: ComputerDTO, cards: SecucardDTO[], keys: any) => {
    let groupedArrays = [];
    let userDetails: object[] = [];
    
    groupedArrays.push(gears, computers, cards, keys)
    groupedArrays.map((arr) => {
      arr.filter((item: any) => {
        userDetails.push({
          id: item.id,
          name: item.name,
          description: item.description,
          color: item.tileColor,
          icon: item.iconUrl,
          fallbackIcon: process.env.REACT_APP_DEFAULT_ITEM_ICON
        })
      })
    })    

    return userDetails
  });

  
  fetchGears = async () => {
    this.setState({
      status: 'loading'
    });
    
    const [userItems, userComputers, userSecucards, userSecukeys] = await Promise.all([
      listUserGears(this.context.loggedUser?.id).then(this.incrementProgress),
      listUserComputers(this.context.loggedUser?.id).then(this.incrementProgress),
      listUserSecucards(this.context.loggedUser?.id).then(this.incrementProgress),
      listUserSecukeys(this.context.loggedUser?.id).then(this.incrementProgress),
    ])
    
    const userDetails = this.groupOverArrays(userItems, userComputers, userSecucards, userSecukeys);
    this.setState(prevState => {
      return {
        status: 'success',
        gears: [...userItems],
        computers: [userComputers],
        secucards: [...userSecucards],
        secukeys: [...userSecukeys],
        userDetails: userDetails,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  componentDidMount() {
    if(this.context.loggedUser) {
      this.fetchGears();
    }
  }
  
  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  render() {
    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
          </ToolbarControls>
        </div>
        <div className={`card ${!this.state.gears ? 'loader-border' : ''}`}>
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 315px)'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="text-left">
                          <SortButton
                            column='name'
                            text='Name'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th className="text-left">
                          <SortButton
                            column='description'
                            text='Description'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                      </tr>
                    </thead>
                    {this.state.gears &&
                      <React.Fragment>
                        <tbody>
                          {getFilteredItems(this.state.filterValue, this.availableFilters, this.state.userDetails).sort(this.state.tableSort.sortByColumn).map((item: any, index) => (
                            <React.Fragment key={index}>
                              <tr id={`gear${index}`}>
                                <td></td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center">
                                      <Thumbnail
                                        thumbnailBackground={item.color}
                                        thumbnailURL={item.icon === undefined || '' ? item.fallbackIcon : process.env.REACT_APP_INVENTORY_PATH + item.icon}
                                        classes="thumbnail radius padded"
                                      />
                                        <span>{item.name}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <p className="text-chunk">{item.description}</p>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.userDetails?.length === 0 &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk">There are no items assigned. If you're an employee talk to a Workstrym Admin or Manager for more information.</p>
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk">Showing {getFilteredItems(this.state.filterValue, this.availableFilters, this.state.userDetails).sort(this.state.tableSort.sortByColumn).length} out of {this.state.userDetails.length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GearList;

GearList.contextType = AppContext;