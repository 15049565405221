import React, { Component } from 'react';

interface Props {
  onClick?: (() => void) | ((ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
  disabled?: boolean,
  type?: 'submit' | 'button' ,
  class: string,
  tabIndex?: number,
}

class ButtonControl extends Component<Props> {

  render() {
    return (
    <button className={this.props.class}
      onClick={this.props.onClick}
      disabled={this.props.disabled}
      type={this.props.type}
      tabIndex={this.props.tabIndex}
      >
        {this.props.children}
    </button>
  )
}
}

export default ButtonControl;