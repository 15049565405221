import React, { PureComponent } from 'react';
import {Line} from 'react-chartjs-2';
import {ChartDataset} from 'chart.js'
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import AppContext from '../../../common/context/AppContext';

type Position = "left" | "right" | "top" | "bottom" | "center";

export interface Props {
  chartData: ChartDataset[],
  labels: string[],
  suggestedMaxA: number,
  suggestedMaxB: number,
}

class UltraChart extends PureComponent<Props> {
  getLocalStorageAccent = (userId: number) => {
    return localStorage.getItem(userId + 'accent') === null ? '' : localStorage.getItem(userId + 'accent')!;
  }

  render() {
    if(!this.props.chartData) {
      return;
    }

    const data = (canvas: HTMLCanvasElement) => {
      let primaryRGB = 'rgba(222, 55, 102, 1)';
      let primaryRGBTransparent = 'rgba(222, 55, 102, 0)';

      switch(this.getLocalStorageAccent(this.context.loggedUser.id)) {
        case "":
          primaryRGB ='rgba(222, 55, 102, 1)';
          primaryRGBTransparent ='rgba(222, 55, 102, 0)';
          break;
        case "blue-accent":
          primaryRGB ='rgba(22, 163, 245, 1)';
          primaryRGBTransparent ='rgba(22, 163, 245, 0)';
          break;
        case "yellow-accent":
          primaryRGB ='rgba(255, 204, 64, 1)';
          primaryRGBTransparent ='rgba(255, 204, 64, 0)';
          break;
        case "green-accent":
          primaryRGB ='rgba(70, 195, 81, 1)';
          primaryRGBTransparent ='rgba(70, 195, 81, 0)';
          break;
        case "purple-accent":
          primaryRGB ='rgba(146, 66, 211, 1)';
          primaryRGBTransparent ='rgba(146, 66, 211, 0)';
          break;
        case "red-accent":
          primaryRGB ='rgba(234, 25, 25, 1)';
          primaryRGBTransparent ='rgba(234, 25, 25, 0)';
          break;
      }
      let ctx = canvas.getContext('2d');
      let gradient = ctx!.createLinearGradient(0, 0, 0, canvas.offsetHeight);
      gradient.addColorStop(0, 'rgba(150, 150, 150, 1)');
      gradient.addColorStop(1, 'rgba(150, 150, 150, 0)');

      let borderColor = 'rgba(150, 150, 150, 1)';
      let pointBackgroundColor = 'rgba(150, 150, 150, 1)';
      let pointBorderColor = '#fff';
      let pointHoverBackgroundColor = '#fff';
      let pointHoverBorderColor = 'rgba(148,159,177,0.8)';

      let gradient2 = ctx!.createLinearGradient(0, 0, 0, canvas.offsetHeight);
      gradient2.addColorStop(0, primaryRGB);
      gradient2.addColorStop(1, primaryRGBTransparent);

      let borderColor2 = primaryRGB;
      let pointBackgroundColor2 = primaryRGB;
      let pointBorderColor2 = '#fff';
      let pointHoverBackgroundColor2 = '#fff';
      let pointHoverBorderColor2 = 'rgba(148,159,177,0.8)';

      return {
        labels: this.props.labels,
        datasets: [
          {
            data: [...this.props.chartData[0].data],
            label: this.props.chartData[0].label,
            backgroundColor: gradient,
            borderColor: borderColor,
            pointBackgroundColor: pointBackgroundColor,
            pointBorderColor: pointBorderColor,
            pointHoverBackgroundColor: pointHoverBackgroundColor,
            pointHoverBorderColor: pointHoverBorderColor,
            yAxisID: 'yAxisA',
            fill: true,
            position: 'left',
          },
          {
            data: [...this.props.chartData[1].data],
            label: this.props.chartData[1].label,
            backgroundColor: gradient2,
            borderColor: borderColor2,
            pointBackgroundColor: pointBackgroundColor2,
            pointBorderColor: pointBorderColor2,
            pointHoverBackgroundColor: pointHoverBackgroundColor2,
            pointHoverBorderColor: pointHoverBorderColor2,
            yAxisID: 'yAxisB',
            position: 'right',
            fill: true,
          },
        ],
      }
    };

    

    const options = {
      tension: 0,
      scales: {
        yAxisA: {
          suggestedMin: 0,
          suggestedMax: this.props.suggestedMaxA,
          position: 'left' as Position,
          grid: {
            display: false,
          }
        },
        yAxisB: {
          suggestedMin: 0,
          suggestedMax: this.props.suggestedMaxB,
          position: 'right' as Position,
          grid: {
            display: false,
          }
        }
      }
    };

    


    return (
      <React.Fragment>
        <Line data={data} options={options} height={85} />
      </React.Fragment>
    );
  }
}

export default UltraChart;

UltraChart.contextType = AppContext;
