import React, { ChangeEvent, Component } from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import Joi from 'joi';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { FormErrors } from '../../../../common/data/FormErrors';
import { getFilteredItems } from "../../../../common/helpers/Filter";
import { RoleEndpointDTO, updateNewRole } from '../../../../common/api/endpoints/roles';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TreeviewControl from '../../../controls/TreeviewControl/TreeviewControl';
import { ITreeview } from '../../../../common/interfaces/Treeview';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import TextControl from '../../../controls/TextControl/TextControl';
import { EndpointDTO, EndpointResponseDTO, listEndpoints } from '../../../../common/api/endpoints/endpoints';

export interface Props {
  roleId: number,
  roleEndpoints: RoleEndpointDTO[],
}

interface RoleEndpoint {
  identifier: string,
  controlFlags: string[],
  responseMask: ResponseMask,
}

type ResponseMask = boolean | {[key: string]: boolean | ResponseMask};

interface FormData {
  roleEndpoints: { [key: string]: RoleEndpoint },
}

interface State {
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  formData: FormData,
  formErrors: FormErrors,
  treeview: ITreeview[],
  endpoints: EndpointDTO[],
  filterValue: string,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class RoleConfiguration extends Component<Props, State> {
  availableFilters: string[] = [
    'identifier',
  ]

  formSchema = Joi.object({
    endpoints: Joi.array().items(Joi.object({
      identifier: Joi.string().required(),
      controlFlags: Joi.array().items(Joi.string()).required(),
      responseMask: [Joi.bool(), Joi.object()],
    })),
  });

  constructor(props: Props) {
    super(props);

    const roleEndpoints: { [key: string]: RoleEndpoint } = {};
    for (const item of this.props.roleEndpoints) {
      roleEndpoints[item.identifier] = item;
    }

    this.state = {
      formData: {
        roleEndpoints,
      },
      treeview: [],
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      formErrors: {},
      endpoints: [],
      filterValue: '',
    }
  }

  normalizeTreeview(endpoints: EndpointDTO[]) {
    return endpoints.map((endpoint) => {
      const treeview: ITreeview = {
        id: endpoint.identifier,
        label: endpoint.identifier,
        description: endpoint.description,
        state: "unchecked",
        collapsed: false,
        children: [],
      };

      // When the response type is an array, find the inner response type
      let endpointResponse = this.evaluateResponse(endpoint);

      // In case the endpoint retuns a non-object value we only have one child which
      // will be the value in the response
      if (['number', 'string', 'bool', 'hidden'].includes(endpointResponse.type)) {
        treeview.children = [{
          id: `${endpoint.identifier}_response`,
          label: '<response>',
          description: '',
          state: "unchecked",
          collapsed: false,
          children: [],
        }];
      // In case the endpoint return returns an object, include all the fields as children
      } else if (endpointResponse.type === 'object') {
        for (const [field, type] of Object.entries(endpointResponse.objectType)) {
          const id = `${endpoint.identifier}_${field}`;
          treeview.children!.push({
            id,
            label: field,
            description: '',
            state: "unchecked",
            collapsed: false,
            children: this.normalizeChildren(id, type),
          });
        }
      } else {
        console.error(`Unexpected type '${endpointResponse.type}'`);
      }

      return treeview;
    });
  }

  normalizeChildren(parentId: string, item: EndpointResponseDTO): ITreeview[] {
    const children: ITreeview[] = [];

    // When the response type is an array, find the inner response type
    let value = item;
    while (value.type === 'array') {
      value = value.itemType;
    }

    // In case the endpoint return returns an object, include all the fields as children
    if (value.type === 'object') {
      for (const [field, type] of Object.entries(value.objectType)) {
        const id = `${parentId}_${field}`;
        children.push({
          id,
          label: field,
          description: '',
          state: "unchecked",
          collapsed: false,
          children: this.normalizeChildren(id, type),
        });
      }
    }

    return children;
  }

  applyTreeviewToMask(treeViews: ITreeview[], mask: ResponseMask) {
    if (typeof mask === 'boolean') {
      throw Error(`Unexpected type 'boolean' for mask`);
    }

    for (const view of treeViews) {
      const ids = view.id.split('_');
      const field = ids[ids.length - 1];

      // compute children state
      if (view.children && view.children.length) {
        if (!mask[field]) {
          mask[field] = {};
        }
        mask[field] = this.applyTreeviewToMask(view.children, mask[field]);
      } else {
        if (view.state === 'checked') {
          mask[field] = true;
        } else {
          delete mask[field];
        }
      }
    }

    return mask;
  }

  applyMaskToTreeview(mask: ResponseMask, treeViews: ITreeview[]) {
    if (typeof mask === 'boolean') {
      throw Error(`Unexpected type 'boolean' for mask`);
    }

    for (const view of treeViews) {
      const ids = view.id.split('_');
      const field = ids[ids.length - 1];
      let viewMask = mask[field];

      // compute children state
      if (viewMask && view.children && view.children.length) {
        view.state = this.applyMaskToTreeview(viewMask, view.children);
      } else {
        view.state = viewMask === true ? 'checked' : 'unchecked';
      }
    }

    if (treeViews.every(v => v.state === 'checked')) {
      return 'checked';
    } else if (treeViews.some(v => v.state === 'checked')) {
      return 'indeterminate';
    }
    return 'unchecked';
  }

  fetchEndpoints = async () => {
    this.setState({
      pageStatus: 'loading'
    });

    const endpoints = await listEndpoints();

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        treeview: this.normalizeTreeview(endpoints),
        endpoints,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  updateEntity = async () => {
    if(this.props.roleId) {
      this.setState({
        formStatus: 'loading'
      });

      const payload = {
        endpoints: Object.values(this.state.formData.roleEndpoints),
      };
      await updateNewRole(payload, this.props.roleId.toString());

      this.setState({
          formStatus: 'success',
      });
    }
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();
    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });

      this.updateEntity();
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const payload = this.getEndpointPayload();
    const result = this.formSchema.validate(payload, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  getEndpointPayload() {
    return {
      endpoints: Object.values(this.state.formData.roleEndpoints),
    };
  }

  componentDidMount() {
    this.fetchEndpoints();
    this.setState((prevState: State) => {
      return {
        progress: {
          currentStep: prevState.progress.currentStep,
          totalSteps: 1,
        }
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.roleEndpoints !== prevProps.roleEndpoints) {
      const roleEndpoints: { [key: string]: RoleEndpoint } = {};
      for (const item of this.props.roleEndpoints) {
        roleEndpoints[item.identifier] = item;
      }

      this.setState({
        formData: { roleEndpoints }
      });
    }
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value
      }
    }, () => {
      this.validateFormField(field);
    })
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  setEndpointConfig = (identifier: string, treeview: ITreeview[]) => {
    this.setState(prevState => {
      const formData = { ...prevState.formData };
      const endpoint = formData.roleEndpoints[identifier];
      endpoint.responseMask = this.applyTreeviewToMask(treeview, endpoint.responseMask);

      return { formData };
    });
  }

  setRoleEndpoint = (endpoint: EndpointDTO) => {
    this.setState((prevState) => {
      // copy role endpoints and if we already have it, we remove it,
      // otherwise we add it
      let roleEndpoints = {...prevState.formData.roleEndpoints};
      if (roleEndpoints.hasOwnProperty(endpoint.identifier)) {
        delete roleEndpoints[endpoint.identifier];
      } else {
        roleEndpoints[endpoint.identifier] = {
          identifier: endpoint.identifier,
          controlFlags: [],
          responseMask: this.evaluateResponse(endpoint).type === "object" ? {} : false
        };
      }

      return {
        formData: {
          ...prevState.formData,
          roleEndpoints
        }
      }
    })
  }

  evaluateResponse = (endpoint: EndpointDTO): EndpointResponseDTO => {
    let endpointResponse = endpoint.response;
    while (endpointResponse.type === 'array') {
      endpointResponse = endpointResponse.itemType;
    }

    return endpointResponse;
  }

  setRoleEndpointResponse = (identifier: string, value: ResponseMask) => {
    this.setState((prevState) => {
      let formData = {...prevState.formData}
      formData.roleEndpoints[identifier].responseMask = value;
      return {
        formData
      }
    });
  };

  setRoleEndpointControlFlag = (endpointIdentifier: string, flagIdentifier: string, value: boolean) => {
    this.setState((prevState) => {
      let formData = { ...prevState.formData };
      let endpoint = formData.roleEndpoints[endpointIdentifier];
      if (value) {
        endpoint.controlFlags.push(flagIdentifier);
      } else {
        endpoint.controlFlags = endpoint.controlFlags.filter(f => f !== flagIdentifier);
      }

      return { formData };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  getFilteredEndpoints() {
    return getFilteredItems(this.state.filterValue, this.availableFilters, this.state.endpoints!);
  }

  renderEndpointBox(endpoint: EndpointDTO) {
    let hasAccess = Boolean(this.state.formData.roleEndpoints[endpoint.identifier]);
    let endpointResponse = this.evaluateResponse(endpoint);

    let options = this.normalizeChildren(endpoint.identifier, endpoint.response);
    let mask = this.state.formData.roleEndpoints[endpoint.identifier]?.responseMask;

    if (mask && endpointResponse.type === "object") {
      this.applyMaskToTreeview(mask, options);
    }

    return (
      <>
        <ToggleControl
          id={endpoint.identifier}
          name={endpoint.identifier}
          isChecked={hasAccess}
          changeMethod={() => this.setRoleEndpoint(endpoint)}
          multiline={true}
          tight={true}
          >
          <label htmlFor={endpoint.identifier}>
            <span>{endpoint.identifier}</span>
            <small className="faint-text">{endpoint.description}</small>
          </label>
        </ToggleControl>
        {hasAccess &&
          <div className="card form-group">
            <div className="flex-row">
              <div className="column small">
                <div className="form-group">
                  {endpointResponse.type === "object" ?
                    <TreeviewControl
                      label="Masking"
                      controlId={endpoint.identifier + "_response"}
                      required={false}
                      options={options}
                      onUpdate={(ev) => this.setEndpointConfig(endpoint.identifier, ev)}
                    />
                    :
                    <ToggleControl
                      name={endpoint.identifier + "_response"}
                      id={endpoint.identifier + "_response"}
                      isChecked={this.state.formData.roleEndpoints[endpoint.identifier].responseMask as boolean}
                      changeMethod={(ev: ChangeEvent<HTMLInputElement>) => { this.setRoleEndpointResponse(endpoint.identifier, ev.target.checked)}}
                      labelText="Response access"
                    />
                  }
                </div>
              </div>
              <div className="column large">
                <div className="form-group">
                  <label>Flags</label>
                  {endpoint.controlFlags.map(flag => {
                    return <ToggleControl
                      id={`${endpoint.identifier}_${flag.identifier}`}
                      name={flag.name}
                      changeMethod={(ev: ChangeEvent<HTMLInputElement>) => { this.setRoleEndpointControlFlag(endpoint.identifier, flag.identifier, ev.target.checked)}}
                      multiline={true}
                      tight={true}
                      >
                      <label htmlFor={`${endpoint.identifier}_${flag.identifier}`}>
                        <span>{flag.name}</span>
                        <small className="faint-text">{flag.description}</small>
                      </label>
                    </ToggleControl>;
                  })}
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  }

  render() {
    const filteredItems = this.getFilteredEndpoints();
    return (
      <>
        <ToolbarControls>
          <TextControl
            label="Filter"
            type="text"
            name="filterBox"
            id="filterBox"
            onChange={this.setFilterValue}
            placeholder="Filter"
            srOnly={true}
          />
        </ToolbarControls>
        <div className="flex-row fill tightest-top">
          <div className="column">
            <div className="flex-row fill">
              <div className="column">
                {filteredItems.map((endpoint: EndpointDTO) => this.renderEndpointBox(endpoint))}
              </div>
            </div>
            <ButtonControl class="primary-button" onClick={this.handleSubmit} disabled={!loadComplete(this.state.progress)}>
              <RequestStatus status={this.state.formStatus} />
              <span className="text">Save</span>
            </ButtonControl>
          </div>
        </div>
      </>
    )
  }
}

export default RoleConfiguration;