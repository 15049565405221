import Joi from 'joi';
import React, { ChangeEvent, Component } from 'react';
import { Link, RouteComponentProps } from "react-router-dom";
import BadgeDTO from '../../../../common/api/dtos/Badge';
import RoleDTO from '../../../../common/api/dtos/Role';
import { FormErrors } from '../../../../common/data/FormErrors';
import Fetch from '../../../../common/helpers/Fetch';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { LoggedUser } from '../../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TextAreaControl from '../../../controls/TextAreaControl/TextAreaControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { withTransitionEvent } from '../../../TransitionEvent';

export interface Props extends RouteComponentProps<RouteParams> {
  loggedUser: LoggedUser,
}

interface RouteParams {
  id?: string,
}

interface FormData {
  name: string,
  description: string,
  iconURL: string,
  enabled: boolean,
  deleted: boolean,
}

interface State {
  badgeID?: number,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  roles?: RoleDTO[],
  formData: FormData,
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

class BadgeDetail extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    description: Joi.string().trim(true).allow(null, ''),
    iconURL: Joi.string().trim(true).required().messages(FormErrors.string),
    enabled: Joi.boolean(),
    deleted: Joi.boolean(),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      badgeID: Number(this.props.match.params.id),
      formData: {
        name: '',
        description: '',
        iconURL: '',
        enabled: false,
        deleted: false,
      },
      progress: {
        currentStep: 0,
        totalSteps: 0,
      },
      pageStatus: 'idle',
      formStatus: 'idle',
      formErrors: {},
    }
  }
  
  fetchBadgeDetails = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch<BadgeDTO>(`/badges/${this.state.badgeID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    })
    
    await fetchRequest.fetch();
    if(fetchRequest.getStatus() === 'success') {
      this.setState((prevState: State) => {
        const badge = fetchRequest.getPayload();
        return {
          formData: {
            name: badge.name,
            description: badge.description,
            iconURL: badge.iconURL,
            enabled: badge.enabled,
            deleted: badge.deleted,
          },
          progress: incrementProgress(prevState.progress),
          pageStatus: 'success',
        }
      });
    }
  }

  addEntity = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/badges`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(this.state.formData),
    })
    
    await fetchRequest.fetch();
    this.setState({
      formStatus: fetchRequest.getStatus(),
    },() => {
      if(this.state.formStatus === 'success'){
        this.props.history.push({
          pathname: '/badge',
        })
      }
    });
  }

  updateEntity = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/badges/` + this.state.badgeID, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(this.state.formData),
    })
    
    await fetchRequest.fetch();
    this.setState({
      formStatus: fetchRequest.getStatus(),
    });
  }
  
  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
  
      if(!isEditPage(Number(this.state.badgeID))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    if(isEditPage(Number(this.state.badgeID))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1,
          }
        }
      });
      this.fetchBadgeDetails();
    }
  } 

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value
      }
    })
  }

  renderBadgeEditCard() {
    return(
      <div className="card">
        <TextControl
          label="Name"
          type="text"
          name="name"
          id="name"
          value={this.state.formData.name}
          onChange={this.setName}
          disabled={!loadComplete(this.state.progress)}
          required={true}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.name}
        />
        <TextAreaControl
          label="Description"
          id="description"
          name="description"
          value={this.state.formData.description}
          onChange={this.setDescription}
          disabled={!loadComplete(this.state.progress)}
          rows={3}
          cols={30}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        <div className="form-group">
          <div className="flex-row">
            <div className="column large">
              <TextControl
                label="Icon"
                type="text"
                name="iconURL"
                id="iconURL"
                value={this.state.formData.iconURL}
                onChange={this.setIconURL}
                disabled={!loadComplete(this.state.progress)}
                required={true}
                br={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.iconURL}
              />
              <small className="faint-text"><span className="fas fa-info-circle"></span> You can use both relative or absolute paths.</small>
            </div>
            <div className="column small flex-v-center">
              <img
                className="thumbnail radius padded large"
                src={this.state.formData.iconURL}
                alt="avatar"
              />
            </div>
          </div>
        </div>
        <ToggleControl 
          name="enabled" 
          id="enabled" 
          isChecked={this.state.formData.enabled}
          changeMethod={this.setEnabled}
          isDisabled={!loadComplete(this.state.progress)}
          labelText="Enabled"
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        {isEditPage(Number(this.state.badgeID)) && 
          <ToggleControl 
            name="deleted" 
            id="deleted" 
            isChecked={this.state.formData.deleted}
            changeMethod={this.setDeleted}
            isDisabled={!loadComplete(this.state.progress)}
            labelText="Deleted"
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          />
        }
        <ButtonControl
          class="primary-button"
          onClick={this.handleSubmit}
          disabled={!loadComplete(this.state.progress)}
        >
          <RequestStatus status={this.state.formStatus} />
          <span className="text">Save</span>
        </ButtonControl>
      </div>
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);
  setIconURL = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('iconURL', ev.target.value);
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);
  setEnabled = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('enabled', ev.target.checked);

  render() {
    return (
      <React.Fragment>
        <BreadcrumbControls
          items={[
            {label: 'Badges', path: '/badge'},
            {label: `${!isEditPage(Number(this.state.badgeID)) ? 'adding' : 'editing'} badge ${this.state.formData.name}`},
          ]}
          status={this.state.pageStatus}
        />
        <div className="flex-row">
          <div className="column">
            <div className="flex-row tightest-top">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            {this.renderBadgeEditCard()}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTransitionEvent(BadgeDetail);