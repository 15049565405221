import Joi from 'joi';
import React, { ChangeEvent, Component, FormEvent } from 'react';
import { listLoggedUser, updateLoggedUserName } from '../../../common/api/endpoints/loggeduser';
import AppContext from '../../../common/context/AppContext';
import { FormErrors } from '../../../common/data/FormErrors';
import { processJoiError } from '../../../common/helpers/processJoiError';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import ButtonControl from '../../controls/ButtonControl/ButtonControl';
import TextControl from '../../controls/TextControl/TextControl';
import { withTransitionEvent } from '../../TransitionEvent';
import RequestStatus from '../../utils/RequestStatus/RequestStatus';

export interface Props {
}

interface State {
  formStatus: TRequestStatus,
  formData: FormData,
  formErrors: FormErrors,
  loggedUser?: LoggedUser[]
}

interface FormData {
  name: string,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class AccountInfo extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string().required().messages(FormErrors.string),
  });

  constructor(props: Props) {
    super(props); 
    this.state = {
      formData: {
        name: '',
      },
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchUserName = async () => {
    this.setState({
      formStatus: 'loading'
    });
    
    const loggedUser = await listLoggedUser();

    const formData = this.state.formData;
    this.setState({
      formStatus: 'success',
      formData: {
        ...formData,
        name: loggedUser.name
      }
    });
  }

  updateEntity = async (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();

    this.setState({
      formStatus: 'loading',
    });
    await updateLoggedUserName(this.state.formData, this.context.loggedUser.id);
    
    this.setState({
      formStatus: 'success',
    });
  }

  hasFullAccess = this.context ? this.context.loggedUser?.role.name === 'Admin' || this.context.loggedUser?.role.name === 'Manager' : false;

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
        this.updateEntity(ev);
    }
  } 

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
    this.updateFormError(field, result.error.message);
    } else {
    this.updateFormError(field, "");
    }
  };

  validateForm = () => {
  // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  };

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const propsFormData = this.state.formData;
    this.setState({
      formData: {
        ...propsFormData,
        [field]: value,
      }
    })
  }

  componentDidMount() {
    this.fetchUserName();
  }

  setUserName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);

  render() {
    return(
      <form>
        <div className="flex-row pb-medium fill">
          <div className="column">
            <div className={`card ${this.state.formStatus === 'loading' ? 'loader-border' : ''}`}>
                <TextControl
                  label="Name"
                  type="text"
                  name="name"
                  id="name"
                  onChange={this.setUserName}
                  value={this.state.formData.name}
                  disabled={false}
                  br={true}
                  required={true}
                  onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                  error={this.state.formErrors.name}
                />
                <TextControl
                  label="Email"
                  type="text"
                  name="email"
                  id="email"
                  value={this.context.loggedUser?.email}
                  disabled={true}
                  br={true}
                />
                <TextControl
                  label="Role"
                  type="text"
                  name="role"
                  id="role"
                  tight={true}
                  value={this.context.loggedUser?.role.name}
                  disabled={true}
                  br={true}
                />
            </div>
          </div>
        </div>
        <ButtonControl
          class="primary-button" type="submit"
          disabled={!this.state.formStatus}
          onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            this.handleSubmit(ev)
          }}
        >
          <RequestStatus status={this.state.formStatus} />
          <span className="text">Save</span>
        </ButtonControl>
      </form>
    )
  }
}

export default withTransitionEvent(AccountInfo);

AccountInfo.contextType = AppContext;