import React, { Component, Context } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { DeepFlowContext } from '../../../common/interfaces/DeepFlowContext';
import { RouteParams } from '../../pages/Bookings/Bookings';

interface Props {
  baseURL: string,
  context: DeepFlowContext,
  index: number
}

class DeepFlowOption extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment key={this.props.index}>
        <div className="form-group anim-pull-right tight" id={`option-${this.props.index}`}>
          {!this.props.context.disabled ?
            <NavLink to={`${this.props.baseURL}/${this.props.context.navLink}`} className='card-button'>
              <span className={`icon ${this.props.context.icon}`}></span>
              <span className="text-container">
                {this.props.context.navName}
                <br />
                <small className='faint-text'>{this.props.context.description}</small>
              </span>
              <span className="fas fa-angle-right"></span>
            </NavLink>
          :
            <div className='card-button disabled'>
              <span className={`icon ${this.props.context.icon}`}></span>
              <span className="text-container">
                {this.props.context.navName}
                <br />
                <small className='faint-text'>{this.props.context.description}</small>
              </span>
            </div>
          }
        </div>
      </React.Fragment>
    )
  }
}

export default DeepFlowOption;