import React from "react";
import HolidayDTO from "../api/dtos/Holiday";
import SyspropDTO from "../api/dtos/Sysprop";
import UserDTO from "../api/dtos/User";
import { LoggedUser } from "../interfaces/LoggedUser";

export interface IAppContext {
  loggedUser?: LoggedUser,
  holidays?: HolidayDTO[],
  users?: UserDTO[],
  sysProps?: SyspropDTO[],
  mobileNavOpen?: boolean,
}

const AppContext = React.createContext<IAppContext>({});

export default AppContext;