import Fetch from "../../helpers/Fetch";
import { LoggedUser } from "../../interfaces/LoggedUser";

interface LoggedUserName {
  name: string
}

interface LoggedUserAvatar {
  avatar: string
}

export async function listLoggedUser(): Promise<LoggedUser> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<LoggedUser>(`/self`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch logged user');
}

export async function updateLoggedUserName(formData: LoggedUserName, loggedUserId: string): Promise<LoggedUser> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<LoggedUser>(`/user/edit-name/${loggedUserId}`, {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(formData)
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to update logged user name');
}

export async function updateLoggedUserAvatar(formData: LoggedUserAvatar, loggedUserId: number): Promise<LoggedUser> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<LoggedUser>(`/user/edit-avatar/${loggedUserId}`, {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(formData)
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to update logged user name');
}