import { Component } from 'react';

export interface Props {
  text: string,
  column: string,
  tableSort: {
    sortBy: string,
    sortDirection: string 
  },
  onClick: (column: string) => void,
}


class SortButton extends Component<Props> {
  renderDirectionalArrow() {
    return (
      <>
      {this.props.tableSort.sortDirection == 'asc' ?
        <span className="icon fas fa-angle-up"></span>
        :
        <span className="icon fas fa-angle-down"></span>
      }
      </>
    )
  }

  render() {
    return (
      <button
        className="ghost-button"
        onClick={() => this.props.onClick(this.props.column)}
      >
        <span>{this.props.text}</span>
        {this.props.tableSort.sortBy === this.props.column &&
          this.renderDirectionalArrow()
        }
      </button>
    );
  }
}

export default SortButton;
