import Fetch from "../../helpers/Fetch";
import ClientDTO from "../dtos/Client";
import ProjectDTO from "../dtos/Project";

interface AddPayload {
  name: string,
  avatar: string,
  clientId?: number,
  billable: boolean,
  enabled: boolean
  client?: ClientDTO,
}

export async function filterProjects(clients: number[]): Promise<ProjectDTO[]> {
  
  if(!clients.length) {
    return [];
  }

  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProjectDTO[]>(`/projects/filter`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({ clients })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch projects for clients with IDs ${clients.toString()}`);
}

export async function listProjects(): Promise<ProjectDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProjectDTO[]>(`/projects`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch projects');
}

export async function listProjectDetails(projectId: string): Promise<ProjectDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProjectDTO>(`/projects/${projectId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch project details');
}

export async function addProject(form: AddPayload): Promise<ProjectDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProjectDTO>(`/projects`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add project`);
}

export async function updateProject(form: AddPayload, projectId: string): Promise<ProjectDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProjectDTO>(`/projects/${projectId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update project`);
}