import { RawDraftContentState } from "draft-js";
import Joi from "joi";
import React, { ChangeEvent, Component } from "react";
import { Route, RouteComponentProps } from "react-router";
import { NavLink } from "react-router-dom";
import ProcedureDTO from "../../../../common/api/dtos/Procedure";
import RoleDTO from "../../../../common/api/dtos/Role";
import { addProcedure, listProcedureDetails, updateProcedure } from "../../../../common/api/endpoints/procedures";
import { FormErrors } from "../../../../common/data/FormErrors";
import Fetch from "../../../../common/helpers/Fetch";
import { isEditPage } from "../../../../common/helpers/isEditPage";
import { loadComplete } from "../../../../common/helpers/LoadComplete";
import { processJoiError } from "../../../../common/helpers/processJoiError";
import { RichTextContent } from "../../../../common/helpers/RichText";
import { LoggedUser } from "../../../../common/interfaces/LoggedUser";
import { TRequestStatus } from "../../../../common/types/RequestStatus";
import ButtonControl from "../../../controls/ButtonControl/ButtonControl";
import HTMLControl from "../../../controls/HTMLControl/HTMLControl";
import TextAreaControl from "../../../controls/TextAreaControl/TextAreaControl";
import TextControl from "../../../controls/TextControl/TextControl";
import ToggleControl from "../../../controls/ToggleControl/ToggleControl";
import ProceduresUserForm from "../../../forms/ProceduresForm/ProceduresUserForm";
import BreadcrumbControls from "../../../generics/Header/BreadcrumbControls";
import { withTransitionEvent } from "../../../TransitionEvent";
import ProgressBar, { incrementProgress, IProgress } from "../../../utils/ProgressBar/ProgressBar";
import RequestStatus from "../../../utils/RequestStatus/RequestStatus";
import Thumbnail from "../../../utils/Thumbnail/Thumbnail";

export interface Props extends RouteComponentProps<RouteParams> {
}

interface RouteParams {
  id?: string,
}

interface FormData {
  name: string,
  description: string,
  content: string,
  category: string,
  tags: string,
  deleted: boolean,
}

interface State {
  procedureId: number,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  formData: FormData,
  formErrors: FormErrors
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

class ProcedureDetail extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    description: Joi.string().trim(true).required().messages(FormErrors.string),
    content: Joi.string().trim(true).allow(null, ''),
    category: Joi.string().trim(true).required().messages(FormErrors.string),
    tags: Joi.string().trim(true).allow(null, ''),
    deleted: Joi.boolean(),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      procedureId: Number(this.props.match.params.id),
      formData: {
        name: '',
        description: '',
        content: '',
        category: '',
        tags: '',
        deleted: false,
      },
      progress: {
        currentStep: 0,
        totalSteps: 0
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchProcedureDetails = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const procedure = await listProcedureDetails(this.state.procedureId);
    this.setState(prevState => {
      return {
        formData: {
          name: procedure.name,
          description: procedure.description,
          content: procedure.content,
          category: procedure.category,
          tags: procedure.tags,
          deleted: procedure.deleted,
        },
        progress: incrementProgress(prevState.progress),
        pageStatus: 'success',
      }
    });
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addProcedure(this.state.formData);

    this.setState({
        formStatus: 'success',
    }, () => {
      this.props.history.push({
        pathname: '/procedure'
      })
    });
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await updateProcedure(this.state.formData, this.state.procedureId);

    this.setState({
        formStatus: 'success',
    });
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
      
      if(!isEditPage(Number(this.state.procedureId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    if(isEditPage(Number(this.state.procedureId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1
          }
        }
      });
      this.fetchProcedureDetails()
    } else {
      this.setState({
        pageStatus: "idle",
      })
    }
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value
      }
    })
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setContent = (rawContentState: RawDraftContentState) => {
    this.updateFormData('content', JSON.stringify(rawContentState))
  }
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);
  setCategory = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('category', ev.target.value);
  setTags = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('tags', ev.target.value);
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);
  
  checkNestedContext = () => {
    const isNested = this.getContextPanel().some(context => this.props.location.pathname.includes(context.navLink));
    return isNested;
  }

  getContextName = () => {
    const contextName = this.getContextPanel().find(context => context.navLink === this.props.location.pathname.split('/').pop())?.navName;
    return contextName ? contextName : '';
  }

  getContextPanel() {
    return [
      {
        icon: 'fas fa-clipboard',
        navLink: 'details',
        navName: 'Procedure Details',
        description: 'Edit general information for this procedure',
        disabled: false,
        page: this.renderProcedureEditCard()
      },
      {
        icon: 'fas fa-users',
        navLink: 'assigned-users',
        navName: 'Users',
        description: 'Manage which users can see this procedure',
        disabled: false,
        page: (
          <React.Fragment>
            {this.state.procedureId && 
              <ProceduresUserForm editProcedureId={this.state.procedureId} />
            }
          </React.Fragment>
        )
      },
    ]
  }

  renderProcedureEditCard() {
    return (
      <div className="card">
        <TextControl
          label="Name"
          type="text"
          name="name"
          id="name"
          value={this.state.formData.name}
          onChange={this.setName}
          disabled={!loadComplete(this.state.progress)}
          required={true}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.name}
        />
        <TextAreaControl
          label="Description"
          id="description"
          name="description"
          value={this.state.formData.description}
          onChange={this.setDescription}
          disabled={!loadComplete(this.state.progress)}
          rows={3}
          cols={30}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        {loadComplete(this.state.progress) &&
          <div className="form-group">
            <label htmlFor="content">Content</label>
            <HTMLControl
              id="content"
              name="content"
              value={RichTextContent(this.state.formData.content)}
              onChange={this.setContent}
            />
          </div>
        } 
         <TextControl
          label="Category"
          type="text"
          name="category"
          id="category"
          value={this.state.formData.category}
          onChange={this.setCategory}
          disabled={!loadComplete(this.state.progress)}
          required={true}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.category}
        />
         <TextAreaControl
          label="Tags"
          id="tags"
          name="tags"
          value={this.state.formData.tags}
          onChange={this.setTags}
          disabled={!loadComplete(this.state.progress)}
          rows={3}
          cols={30}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
       {isEditPage(Number(this.state.procedureId)) &&
        <ToggleControl
          name="deleted"
          id="deleted"
          isChecked={this.state.formData.deleted}
          changeMethod={this.setDeleted}
          isDisabled={!loadComplete(this.state.progress)}
          labelText="Deleted"
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        }
        <ButtonControl
          class="primary-button"
          onClick={this.handleSubmit}
          disabled={!loadComplete(this.state.progress)}
        >
          <RequestStatus status={this.state.formStatus} />
          <span className="text">Save</span>
        </ButtonControl>
      </div>
    );
  }

  render() {
    let entityName = this.state.formData.name ? this.state.formData.name : 'loading';
    return (
      <div>
        {this.checkNestedContext() === false ?
          <BreadcrumbControls
            items={[
              {label: 'procedures', path: '/procedure'},
              {label: `${!isEditPage(Number(this.state.procedureId)) ? 'adding procedure ' : ''}${this.state.formData.name}`},
            ]}
          />
        :
          <BreadcrumbControls
            items={[
              {label: 'procedures', path: '/procedure'},
              {label: entityName ? entityName : '', path: `/procedure/edit/${this.state.procedureId}`},
              {label: this.getContextName()},
            ]}
          />
        }
        <div className="flex-row tightest-top">
          <div className="column large">
            <div className="flex-row">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            <div className="card-list-component flex-row flex-v-center">
              <div className="column">
                <div className="card transparent">
                  <div className="text-container story">
                    <span className="primary-title">{this.state.formData.name}</span><br />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="card transparent">
                  <div className="text-container story">
                    <span>{this.state.formData.category}</span><br />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="card transparent">
                  <div className="text-container story">
                    <span>{this.state.formData.tags}</span><br />
                  </div>
                </div>
              </div>
            </div>
            {isEditPage(Number(this.state.procedureId)) ?
              this.getContextPanel().map((context, index) => {
                if(this.checkNestedContext() === true) {
                  return (
                    <Route
                      path={`${this.props.match.url}/${context.navLink}`}
                      render={() => context.page}
                    />
                  )
                } else {
                  return (
                    <React.Fragment key={index}>
                      <div className="form-group anim-pull-right tight" id={`procedureDetail${index}`}>
                        {!context.disabled ?
                          <NavLink to={`${this.props.match.url}/${context.navLink}`} className='card-button'>
                            <span className={`icon ${context.icon}`}></span>
                            <span className="text-container">
                              {context.navName}
                              <br />
                              <small className='faint-text'>{context.description}</small>
                            </span>
                            <span className="fas fa-angle-right"></span>
                          </NavLink>
                        :
                          <div className='card-button disabled'>
                            <span className={`icon ${context.icon}`}></span>
                            <span className="text-container">
                              {context.navName}
                              <br />
                              <small className='faint-text'>{context.description}</small>
                            </span>
                          </div>
                        }
                      </div>
                    </React.Fragment>
                  )
                }
              })
            :
              this.renderProcedureEditCard()
            }
          </div>
        </div>          
      </div>
    )
  }

}

export default withTransitionEvent(ProcedureDetail);
