import { IAvatar } from "../interfaces/Avatar";

export function AvatarFormatter(data: string): IAvatar {
    const avatarParts = data?.split('|', 8) || [];

    let avatar: IAvatar = {
        link: avatarParts[0] || '',
        background: avatarParts[1] || '',
        hair: avatarParts[2] || '', 
        mouth: avatarParts[3] || '', 
        eyes: avatarParts[4] || '', 
        beard: avatarParts[5] || '', 
        face: avatarParts[6] || '',
        eyewear: avatarParts[7] || '', 
    };

    return avatar;
}

export function thumbnailURL(data: IAvatar): string {
    return `${process.env.REACT_APP_AVATAR_SERVICE}/${data.link}?w=150&h=150&q=100&skin=${data.face}&beard=${data.beard}&hair=${data.hair}&eyes=${data.eyes}&mouth=${data.mouth}&background=${data.background.substring(1)}&eyewear=${data.eyewear}`;
}