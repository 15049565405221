import React, { ChangeEvent, Component } from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import BenefitDTO from '../../../../common/api/dtos/Benefit';
import AppContext from '../../../../common/context/AppContext';
import { listAssignedBenefits } from '../../../../common/api/endpoints/users';
import { IProgress } from '../../../utils/ProgressBar/ProgressBar';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import TextControl from '../../../controls/TextControl/TextControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import SortButton from '../../../utils/SortButton/SortButton';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import Thumbnail from '../../../utils/Thumbnail/Thumbnail';

export interface Props {

}

interface State {
  progress: IProgress,
  status: TRequestStatus,
  benefits: BenefitDTO[],
  tableSort: TableSort,
  filterValue: string,
}

class BenefitsBoard extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'conditions',
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: 'loading',
      benefits: [],
      tableSort: new TableSort('name', 'asc'),
      filterValue: '',
    }
  }

  fetchAssignedBenefits = async () => {
    this.setState({
      status: 'loading'
    });
    
    const assignedBenefits = await listAssignedBenefits(this.context.loggedUser.id);

    this.setState({
      status: 'success',
      benefits: assignedBenefits.length ? assignedBenefits : [],
    });
  }

  componentDidMount() {
    this.fetchAssignedBenefits();
  }

  // renderBenefit(benefit: BenefitDTO) {
  //   return (
  //     <React.Fragment>
  //       <div className="column stretch">
  //         <div className="card wrap align-top">
  //           <div>
  //             <img
  //               className="text-icon"
  //               src={benefit.iconURL}
  //               alt="icon"
  //             />
  //             <h2 className="primary-title section-title uppercase">
  //               {benefit.name}
  //             </h2>
  //             <hr />
  //             <p className="text-chunk faint-text"><small>{benefit.description}</small></p>
  //             <hr />
  //             <div className="faint-text text-chunk"><small>{benefit.conditions}</small></div>
  //           </div>
  //         </div>
  //       </div>
  //     </React.Fragment>
  //   )
  // }
  
  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  render() {
    const filteredBenefits: Array<BenefitDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.benefits).sort(this.state.tableSort.sortByColumn).filter((benefit: BenefitDTO) => benefit.enabled === true && benefit.deleted === false);

    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
          </ToolbarControls>
        </div>
        <div className={`card ${!this.state.benefits ? 'loader-border' : ''}`}>
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 315px)'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="text-left">
                          <SortButton
                            column='name'
                            text='Name'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th className="text-left">
                          <SortButton
                            column='description'
                            text='Mechanism'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th>
                          <SortButton
                            column='conditions'
                            text='Conditions'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                      </tr>
                    </thead>
                    {this.state.benefits &&
                      <React.Fragment>
                        <tbody>
                          {filteredBenefits
                            .map(benefit => (
                            <React.Fragment>
                              <tr id={`benefit${benefit.id}`} key={benefit.id}>
                                <td></td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center">
                                      <Thumbnail
                                        thumbnailURL={benefit.iconURL}
                                        classes="thumbnail padded"
                                      />
                                        <span>{benefit.name}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <p className="text-chunk">{benefit.description}</p>
                                </td>
                                <td>
                                  <p className="text-chunk">{benefit.conditions}</p>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.benefits?.length === 0 &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk">There are no benefits assigned. If you're an employee talk to a Workstrym Admin or Manager for more information.</p>
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk">Showing {getFilteredItems(this.state.filterValue, this.availableFilters, this.state.benefits).sort(this.state.tableSort.sortByColumn).length} out of {this.state.benefits.length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BenefitsBoard;

BenefitsBoard.contextType = AppContext;