import Joi from 'joi';
import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import ClientDTO from '../../../../common/api/dtos/Client';
import { ProjectGroupDTO } from '../../../../common/api/dtos/ProjectGroup';
import { listClients } from '../../../../common/api/endpoints/clients';
import { listProjectGroups } from '../../../../common/api/endpoints/projectGroups';
import { addProject, listProjectDetails, updateProject } from '../../../../common/api/endpoints/projects';
import { FormErrors } from '../../../../common/data/FormErrors';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import SelectControl from '../../../controls/SelectControl/SelectControl';
import TextControl from '../../../controls/TextControl/TextControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { incrementProgress, IProgress, newProgress } from '../../../utils/ProgressBar/ProgressBar';
import ProgressBar from '../../../utils/ProgressBar/ProgressBar.lazy';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import { withTransitionEvent } from '../../../TransitionEvent';

interface RouteParams {
  eid?: string,
  entity?: string,
  id?: string,
}
interface FormData {
  avatar: string,
  billable: boolean,
  client?: ClientDTO,
  clientId?: number | undefined,
  enabled: boolean
  name: string,
  projectGroupId: number | null
}
interface State {
  clients?: ClientDTO[],
  clientStatus: TRequestStatus,
  formData: FormData,
  formErrors: FormErrors,
  formStatus: TRequestStatus,
  pageStatus: TRequestStatus,
  projectGroups?: ProjectGroupDTO[],
  projectId?: string,
  progress: IProgress,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class ProjectDetail extends Component<RouteComponentProps<RouteParams>, State> {
  formSchema = Joi.object({
    avatar: Joi.string().trim(true).allow(null, ''),
    billable: Joi.boolean(),
    clientId: Joi.number().allow(null, 0),
    enabled: Joi.boolean(),
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    projectGroupId: Joi.number().allow(null),
  });

  constructor(props: RouteComponentProps<RouteParams>) {
    super(props);
    this.state = {
      projectId: this.props.match.params.id,
      formData: {
        name: '',
        avatar: '/assets/icons/project-icon.png',
        billable: false,
        enabled: false,
        clientId: this.props.match.params.entity == 'client' ? Number(this.props.match.params.eid) : undefined,
        projectGroupId: null,
      },
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      clientStatus: 'idle',
      formErrors: {},
    }
  }

  fetchProjectDetails = async () => {
    if(this.state.projectId) {
    this.setState({
      pageStatus: 'loading'
    });
    
      const project = await listProjectDetails(this.state.projectId);
      this.setState(prevState => {
        return {
          formData: {
            avatar: project.avatar,
            billable: project.billable,
            clientId: project.clientId,
            enabled: project.enabled,
            name: project.name,
            projectGroupId: project.projectGroupId
          },
          progress: incrementProgress(prevState.progress),
          pageStatus: 'success',
        }
      }, () => {
        this.fetchProjectGroups();
      });
    }
  }

  fetchClients = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const clients = await listClients();

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        clients: clients,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  fetchProjectGroups = async () => {
    this.setState({
      pageStatus: 'loading',
    })

    const projectGroups = await listProjectGroups(Number(this.state.formData.clientId) as number);

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        projectGroups,
        progress: incrementProgress(prevState.progress)
      }
    })
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addProject(this.state.formData);

    let entityName = this.props.match.params.entity;
    let entityId = this.props.match.params.eid;

    let returnPath = entityId ? `/${entityName}/edit/${entityId}/projects` : '/project';

    this.setState({
      formStatus: 'success',
      }, () => {
      if(this.state.formStatus === 'success'){
        this.props.history.push({
          pathname: returnPath,
        })
      }
    });
  }

  updateEntity = async () => {
    if(this.state.projectId) {
      this.setState({
        formStatus: 'loading'
      });
      await updateProject(this.state.formData, this.state.projectId);
  
      this.setState({
          formStatus: 'success',
      });

    }
  }

  handleSubmit = async (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();
    
    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
  
      if(!isEditPage(Number(this.state.projectId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });
    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    this.fetchClients();

    if (this.state.formData.clientId !== undefined) {
      this.fetchProjectGroups();
    }

    if(isEditPage(Number(this.state.projectId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 3,
          }
        }
      });
      this.fetchProjectDetails();
    }
    
  } 

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      }
    }, () => {
      if (field === 'clientId') {
        this.validateFormField('clientId');
        this.fetchProjectGroups();
      }
    });
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setAvatar = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('avatar', ev.target.value);
  setClient = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, clientId: number | string | undefined | ClientDTO) => this.updateFormData('clientId', Number(clientId));
  setProjectGroup = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, projectGroupId: number | string | undefined | ProjectGroupDTO) => this.updateFormData('projectGroupId', projectGroupId === null ? null : Number(projectGroupId));
  setBillable = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('billable', ev.target.checked);
  setEnabled = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('enabled', ev.target.checked);

  renderProjectEditCard() {
    const currentClient = this.state.clients?.find((client: ClientDTO) => client.id == this.state.formData.clientId);
    return(
      <div className="card">
        <TextControl
          label="Name"
          type="text"
          name="name"
          id="name"
          onChange={this.setName}
          value={this.state.formData.name}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.name}
        />
        <SelectControl
          idName="clientId"
          label="Client"
          required={true}
          value={this.state.formData.clientId}
          options={this.state.clients || []}
          disabled={!loadComplete(this.state.progress) || isEditPage(Number(this.state.projectId)) || (!!this.props.match.params.eid && this.props.match.params.entity == 'client')}
          disabledValue={!isEditPage(Number(this.state.projectId)) ? "Select a client" : null}
          onChange={this.setClient}
          getValue={(op) => op.id}
          getLabel={(op) => op?.name}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.client}
        />
        {currentClient && 
          <div className="form-group">
            <div className="card">
              <div className="flex-row squeeze">
                <div className="column flex-v-center">
                  <span className="fas fa-exclamation-circle negative-text"></span>
                </div>
                <div className="column">
                  <small>{!isEditPage(Number(this.state.projectId)) ? 'You are about to permanently this project to the following client:' : 'This project is permanently attached to the following client:'}</small><br />
                  <small className="faint-text">
                    {currentClient.legalName}
                  </small>
                </div>
              </div>
            </div>
          </div>
        }
        {this.state.projectGroups &&
          <SelectControl
            idName="projectGroupId"
            label="Project Group"
            required={false}
            disabled={!loadComplete(this.state.progress)}
            value={this.state.formData.projectGroupId ? this.state.formData.projectGroupId : null}
            options={[{ id: null, name: 'None' } as any, ...this.state.projectGroups!]}
            onChange={this.setProjectGroup}
            getValue={(op) => op.id}
            getLabel={(op) => op?.name}
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
            error={this.state.formErrors.client}
          />
        }
       
        <div className="form-group">
          <div className="flex-row">
            <div className="column large">
              <TextControl
                label="Icon"
                type="text"
                name="avatar"
                id="avatar"
                onChange={this.setAvatar}
                value={this.state.formData.avatar}
                disabled={!loadComplete(this.state.progress)}
                br={true}
              />
              <small className="faint-text"><span className="fas fa-info-circle"></span> You can use both relative or absolute paths.</small>
            </div>
            <div className="column small flex-v-center">
              <img
                className="thumbnail large"
                src={this.state.formData.avatar}
                alt="avatar"
              />
            </div>
          </div>
        </div>
        <ToggleControl
          id="billable"
          name="billable"
          changeMethod={this.setBillable}
          isChecked={this.state.formData.billable}
          isDisabled={!loadComplete(this.state.progress)}
          labelText="Billable"
        />
        <ToggleControl
          id="enabled"
          name="enabled"
          changeMethod={this.setEnabled}
          isChecked={this.state.formData.enabled}
          isDisabled={!loadComplete(this.state.progress)}
          labelText="Enabled"
        />
        <div className="form-group">
          <ButtonControl
            class="primary-button"
            disabled={!loadComplete(this.state.progress)}
            onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              this.handleSubmit(ev)
            }}
          >
            <RequestStatus status={this.state.formStatus} />
            <span className="text">Save</span>
          </ButtonControl>
        </div>
      </div>
    );
  }

  renderBreadcrumbs() {
    const entity = this.props.match.params.entity;
    const entityId: number = Number(this.props.match.params.eid);

    let entityName;
    
    if(entity) {
      entityName = entity == 'client' ? this.state.clients?.find((client: ClientDTO) => client.id == entityId)!.name : 'unknown';
    }

    return(
      !entity ?
        <BreadcrumbControls 
          items={[
            {label: 'projects', path: '/project'},
            {label: `${!isEditPage(Number(this.state.projectId)) ? 'adding' : 'editing'} project ${this.state.formData.name}`},
          ]}
          status={this.state.pageStatus}
        />
      :
        <BreadcrumbControls 
          items={[
            {label: `${entity}s`, path: `/${entity}`},
            {label: entityName ? entityName : '', path: `/${entity}/edit/${entityId}`},
            {label: 'projects', path: `/${entity}/edit/${entityId}/projects`},
            {label: `${!isEditPage(Number(this.state.projectId)) ? 'adding' : 'editing'} project ${this.state.formData.name}`},
          ]}
          status={this.state.pageStatus}
        />
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderBreadcrumbs()}
        <div className="flex-row">
          <div className="column">
            <div className="flex-row tightest-top">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            {this.renderProjectEditCard()}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTransitionEvent(ProjectDetail);