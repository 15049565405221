import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OfficeRemoteDTO from '../../../common/api/dtos/OfficeRemote';
import Fetch from '../../../common/helpers/Fetch';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import Thumbnail  from '../../utils/Thumbnail/Thumbnail';
import GenericCard from '../../utils/GenericCard/GenericCard';
import AppContext from '../../../common/context/AppContext';

export interface Props {
  theme: string,
}

interface State {
  officeRemotes?: OfficeRemoteDTO[],
  status: TRequestStatus,
}

class Remotes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'loading',
    }
  }

  fetchOfficeRemotes = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch('/remotes/out-of-office?tzOffset=-180', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    });

    await fetchRequest.fetch();
    let rawData = fetchRequest.getPayload();
    let officeRemotes = new Array<OfficeRemoteDTO>();

    rawData.items.forEach((element: any) => {
      officeRemotes.push({
        userId: element['user_id'] || null,
        userName: element['user_name'] || '',
        userAvatar: element['user_avatar'] || '',
        returns: element['returns'] || null,
        leaves: element['leaves'] || null,
      })
    });

    if(fetchRequest.getStatus() === 'success') {
      this.setState({
        officeRemotes: officeRemotes,
        status: fetchRequest.getStatus(),
      });
    } else {
      this.setState({
        status:fetchRequest.getStatus(),
      })
    }
  }

  componentDidMount() {
    this.fetchOfficeRemotes();
  }

  evalURLAccess(userId: number) {
    const userRole = this.context.loggedUser?.role.name;
    if(userRole === 'Admin' || userRole === 'Manager' || userRole === 'Accountant' || userRole === 'Reviewer') {
      return `/remotes/${userId}`;
    } else {
      return null;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Link to={`/remotes/${this.context.loggedUser?.id}`} className="link-button"><h2 className="primary-title">Remotes</h2></Link>
        <div>
          <div className="card-list-component flex-row tightest">
            {!this.state.officeRemotes &&
              <div className="column stretch">
                <GenericCard 
                  thumbnailURL={`/assets/characters/ralph_default_${this.props.theme}.png`}
                  avatarClasses="medium"
                  thumbnailBackground='transparent'
                  cardTitle="..."
                >
                  <>
                    <strong>...</strong>
                    <br />
                    ...
                  </>
                </GenericCard>
              </div>
            }
            {this.state.officeRemotes?.length === 0 &&
              <div className="column stretch">
                <div className="card">
                  <Thumbnail
                    thumbnailURL={`/assets/characters/ralph_default_${this.props.theme}.png`}
                    classes="medium radius"
                    thumbnailBackground='transparent'
                    />
                  <div className="text-container">
                    <p className="story">
                      <span className="primary-title">No remotes!</span>
                      <br />
                      Everyone is here, throw a <img className="icon" src="/assets/icons/pizza-100.png" style={{width: "16px"}} alt="icon"/> party or something before they're gone!
                    </p>
                  </div>
                </div>
              </div>
            }
            {this.state.officeRemotes && this.state.officeRemotes.map(officeRemote => (
              <div key={officeRemote.userId} className="column stretch">
                <GenericCard 
                  avatarData={officeRemote.userAvatar}
                  cardTitle={officeRemote.userName}
                  internalURL={this.evalURLAccess(officeRemote.userId)}
                  classes={officeRemote.returns ? "flashing-border" : ""}
                >
                  <>
                    {officeRemote.returns ?
                      <React.Fragment>
                        <strong><span className="accent-text fas fa-arrow-alt-circle-right"></span> comes </strong> to office {officeRemote.returns && moment(officeRemote.returns).format('LL')}
                      </React.Fragment>
                    :
                      <React.Fragment>
                        <strong>remote</strong> from {officeRemote.leaves && moment(officeRemote.leaves).format('LL')}
                      </React.Fragment>
                    }
                  </>
                </GenericCard>
              </div>
              ))
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Remotes;

Remotes.contextType = AppContext;