import React, { ChangeEvent, Component } from 'react';
import Fetch from '../../../../common/helpers/Fetch';
import RoleDTO from '../../../../common/api/dtos/Role';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import { listRoles, listNewRoles } from '../../../../common/api/endpoints/roles';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import TextControl from '../../../controls/TextControl/TextControl';
import { Link } from 'react-router-dom';
import { withTransitionEvent } from '../../../TransitionEvent';

interface State {
  roles?: RoleDTO[],
  status: TRequestStatus,
  tableSort: TableSort,
  filterValue: string,
}

class RoleList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'description',
  ];

  constructor(props: {}) {
    super(props);
    this.state = {
      status: 'loading',
      filterValue: '',
      tableSort: new TableSort('name', 'asc'),
    };
  }

  fetchRoles = async () => {
    this.setState({
      status: 'loading'
    });
    
    // const roles = await listRoles();
    const roles = await listNewRoles();

    this.setState({
        status: 'success',
        roles: roles,
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchRoles();
  }

  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th>
          <SortButton 
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='description' 
            text='Description'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(role: RoleDTO) {
    return(
      <tr id={`role${role.id}`} key={`${role.id}`}>
        <td></td>
        <td>
          <span>{role.name}</span>
        </td>
        <td>
          <p className="text-chunk">{role.description}</p>
        </td>
        <td className="text-right">
          <GhostButtonControl to={`/role/edit/${role.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredRoles: Array<RoleDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.roles ? this.state.roles : []).sort(this.state.tableSort.sortByColumn);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Roles"
          status={this.state.status}
        />
        {this.state.roles && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <div className="form-group">
                <Link to={'/role/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add Role</span></Link>
              </div>
            </ToolbarControls>
            
            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component ">
                  <div className={`
                      card
                      ${!this.state.roles ? 'loader-border' : ''}
                  `}>
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.roles &&
                        <React.Fragment>
                          <tbody>
                            {filteredRoles.map(role => (
                              this.renderTBodyRow(role)
                            ))}
                          </tbody>
                          <tfoot>
                            {this.state.roles?.length === 0 &&
                              <tr>
                                <th colSpan={4}>
                                  <div className="card">
                                    <p className="text-chunk">There are no roles defined. Check with DevOps for more info.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={4}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredRoles.length} out of {this.state.roles.length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}
export default withTransitionEvent(RoleList);

