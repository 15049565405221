import React, { Component, ChangeEvent } from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { Link } from 'react-router-dom';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { listGasUtils } from '../../../../common/api/endpoints/gasUtils';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../../TransitionEvent';
import GasUtilDTO from '../../../../common/api/dtos/GasUtil';
import moment from 'moment';

interface State {
  gasUtils?: GasUtilDTO[],
  status: TRequestStatus
  tableSort: TableSort,
  showDeleted: boolean,
  filterValue: string,
}

class GasUtilList extends Component<{}, State> {
  availableFilters: string[] = [
    'code',
    'description',
  ];

  constructor(props: {}) {
    super(props);
    this.state = {
      status: 'loading',
      tableSort: new TableSort('code', 'asc'),
      showDeleted: localStorage.getItem('showDeletedEvents') === 'false' || localStorage.getItem('showDeletedEvents') === null ? false : true,
      filterValue: '',
    }
  }

  toggleDisabled = (gasUtil: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(gasUtil.target.name, JSON.stringify(gasUtil.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  fetchEvents = async () => {
    this.setState({
      status: 'loading'
    });
    
    const gasUtils = await listGasUtils();

    this.setState({
        status: 'success',
        gasUtils: gasUtils,
    });
  }
  
  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchEvents();
  }

  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th className="text-left">
          <SortButton 
            column='code' 
            text='Invoice Code'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-left">
          <SortButton 
            column='date' 
            text='Date'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-left">
          <SortButton 
            column='description' 
            text='Description'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='quantity' 
            text='Quantity'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='amount' 
            text='Amount'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='amountPerUnit' 
            text='PPU '
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th></th>
      </tr>
    )
  }

  renderTBodyRow(gasUtil: GasUtilDTO) {
    return (
      <tr id={`gasUtil${gasUtil.id}`} key={`${gasUtil.id}`}>
        <td></td>
        <td>
          <span>{gasUtil.code}</span>
        </td>
        <td>
          <span>{moment(gasUtil.date).format('MMMM, YYYY')}</span>
        </td>
        <td>
          <p className="text-chunk">{gasUtil.description}</p>
        </td>
        <td className="text-center">
          <span>{gasUtil.quantity}</span>
        </td>
        <td className="text-center">
          <span>{gasUtil.amount}</span>
        </td>
        <td className="text-center">
          <span>{gasUtil.amountPerUnit}</span>
        </td>
        <td className="text-right">
          <GhostButtonControl to={`/gas/edit/${gasUtil.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredEvents: Array<GasUtilDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.gasUtils ? this.state.gasUtils : []).sort(this.state.tableSort.sortByColumn).filter((gasUtil: GasUtilDTO) => gasUtil.deleted === this.state.showDeleted);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Gas"
          status={this.state.status}
        />
        {this.state.gasUtils && 
          <div>
            <div className="flex-row fill">
              <div className="column">
                <ToolbarControls>
                  <TextControl
                    label="Filter"
                    placeholder="Filter"
                    type="text"
                    name="filterBox"
                    id="filterBox"
                    onChange={this.setFilterValue}
                    srOnly={true}
                  />
                  <ToggleControl
                    id="showDeletedEvents"
                    name="showDeletedEvents"
                    changeMethod={this.toggleDisabled}
                    isChecked={this.state.showDeleted}
                    labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
                  />
                  <div className="form-group">
                    <Link to='/gas/add' className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add record</span></Link>
                  </div>
                </ToolbarControls>
                <div className="flex-row squeeze flex-v-center">
                  <div className="column">
                      <img className="thumbnail large release" style={{backgroundColor: "transparent", imageRendering: "auto"}} src="/assets/icons/gas.png" />
                  </div>
                  <div className="column">
                      <div className="card">
                          <h2 className="primary-title">averages</h2>
                          <br />
                          <span className="faint-text">9 mᶟ / mo | 10.8 kmᶟ / yr</span>
                      </div>
                  </div>
                  <div className="column">
                      <span className="faint-text">Up next</span>
                      <br />
                      <h2 className="primary-title">9 mᶟ at 540.00 lei</h2>
                      <br />
                      <small className="faint-text">Predicted water consumption for December, 2022</small>
                  </div>
                </div>
                <div className="flex-row fill">
                  <div className="column">
                    <div className="tableview-component">
                        <div className={`
                            card
                            ${!this.state.gasUtils ? 'loader-border' : ''}
                            ${this.state.showDeleted ? "flashing-border" : ""}
                          `}
                        >
                        <table>
                          <thead>
                            {this.renderTHeadRow()}
                          </thead>
                          {this.state.gasUtils &&
                            <React.Fragment>
                              <tbody>
                                {filteredEvents.map(gasUtil => (
                                  this.renderTBodyRow(gasUtil)
                                ))}
                              </tbody>
                              <tfoot>
                                {this.state.gasUtils?.length === 0 &&
                                  <tr>
                                    <th colSpan={9}>
                                      <div className="card">
                                        <p className="text-chunk">There are no gas records defined. Start by <Link className="link-button" to="/gas/add">adding</Link> the first one.</p>
                                      </div>
                                    </th>
                                  </tr>
                                }
                                {this.state.filterValue &&
                                  <tr>
                                    <th colSpan={9}>
                                      <div className="card">
                                        <p className="text-chunk">Showing {filteredEvents.length} out of {this.state.gasUtils.filter((gasUtil: GasUtilDTO) => gasUtil.deleted === this.state.showDeleted).length} results.</p>
                                      </div>
                                    </th>
                                  </tr>
                                }
                              </tfoot>
                            </React.Fragment>
                          }
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withTransitionEvent(GasUtilList);