import moment from 'moment';
import React, { Component } from 'react';
import Fetch from '../../../common/helpers/Fetch';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import Thumbnail  from '../../utils/Thumbnail/Thumbnail';
import GenericCard from '../../utils/GenericCard/GenericCard';
import AppContext from '../../../common/context/AppContext';
import EventDTO from '../../../common/api/dtos/Event';

export interface Props {
  theme: string,
}

interface State {
  events?: EventDTO[],
  status: TRequestStatus,
}

class Events extends Component<Props, State> {
  nowTime = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'loading',
    }
  }

  fetchOfficeEvents = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch('/events/upcoming?days=60', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    });

    await fetchRequest.fetch();
    let events = fetchRequest.getPayload();
    this.setState({
      events
    });

    if(fetchRequest.getStatus() === 'success') {
      this.setState({
        events: events,
        status: fetchRequest.getStatus(),
      });
    } else {
      this.setState({
        status:fetchRequest.getStatus(),
      })
    }
  }

  componentDidMount() {
    this.fetchOfficeEvents();
  }

    evalURLAccess(eventId: number) {
    const userRole = this.context.loggedUser?.role.name;
    if(userRole === 'Admin' || userRole === 'Manager' || userRole === 'Accountant') {
      return `/event/edit/${eventId}`;
    } else {
      return null;
    }
  }

  render() {
    return (
      <React.Fragment>
        <h2 className="primary-title">Events</h2>
        <div>
          <div className="card-list-component flex-row fill tightest">
            {!this.state.events &&
              <div className="column stretch">
                <GenericCard
                  thumbnailURL={`/assets/characters/ralph_default_${this.props.theme}.png`}
                  avatarClasses="medium"
                  thumbnailBackground='transparent'
                  cardTitle="..."
                >
                  <>
                    <strong>...</strong>
                    <br />
                    ...
                  </>
                </GenericCard>
              </div>
            }
            {this.state.events?.length === 0 &&
              <div className="column stretch">
                <div className="card">
                  <Thumbnail
                    thumbnailURL={`/assets/characters/ralph_playful_${this.props.theme}.png`}
                    classes="medium radius"
                    thumbnailBackground='transparent'
                    />
                  <div className="text-container">
                    <p className="story">
                      <span className="primary-title">No events!</span>
                      <br />
                      Let us plan <img className="icon" src="/assets/icons/sunday-100.png" style={{width: "16px"}} alt="icon"/> one, so we have something to get excited about!
                    </p>
                  </div>
                </div>
              </div>
            }
            {this.state.events && this.state.events.sort((a: EventDTO, b: EventDTO) => {return (new Date(a.date)).getTime() - (new Date(b.date)).getTime()}).map(event => (
              <div key={event.id} className="column stretch">
                
                <GenericCard 
                  thumbnailBackground='transparent'
                  thumbnailURL={event.iconURL ? event.iconURL : `/assets/icons/weekday-${moment(event.date).isoWeekday()}-100.png`}
                  avatarClasses={' release tight'}
                  cardTitle={event.name}
                  internalURL={this.evalURLAccess(event.id)}
                  classes={new Date(event.date).getTime() < this.nowTime ? "flashing-border" : ""}
                >
                  <React.Fragment>
                    {event.iconURL &&
                      <img
                        className="smaller release top left thumbnail"
                        style={{
                            "backgroundColor": "transparent",
                            "transform": "translate(-10px, -10px)"
                        }}
                        src={`/assets/icons/weekday-${moment(event.date).isoWeekday()}-100.png`}
                      />
                    }
                    <strong>{moment(event.date).format('LL')}</strong>
                  </React.Fragment>
                </GenericCard>
              </div>
              ))
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Events;
Events.contextType = AppContext;
