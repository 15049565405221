
export const InvoiceLabels: any = {
    "en-ro": {
        "title": "INVOICE / FACTURĂ",
        "series": "Series/Seria:",
        "no": "No./Nr.",
        "date": "Date/Data:",
        "due": "Due on/Scadentă la:",
        "summary": "Summary/Sumar",
        "totalDueA": "TOTAL AMOUNT DUE",
        "totalDueB": "TOTAL DE PLATĂ",
        "provider": "Provider/Furnizor",
        "customer": "Customer/Client",
        "tradeRegistry": "Trade Reg./Reg. Com:",
        "taxId": "Tax ID/CUI:",
        "shareCapital": "Share Capital/Capital Social:",
        "street": "Street/Strada:",
        "bankAccount": "Bank Account (IBAN)/Cont Bancar (IBAN):",
        "email": "Email:",
        "vatRate": "Vat Rate/Cota TVA:",
        "noA": "No.",
        "noB": "Nr.",
        "productA": "Product/Service",
        "productB": "Produs/Serviciu",
        "webServicesA": "Web services - Resource ",
        "webServicesB": "Servicii web - Resursa ",
        "unitA": "Unit",
        "unitB": "U.M.",
        "hourA": "Hour",
        "hourB": "Oră",
        "otherProjectGroups": "Other/Altele",
        "pieceA": "Piece",
        "pieceB": "Bucată",
        "quantityA": "Quantity",
        "quantityB": "Cantitate",
        "unitPriceA": "Unit price",
        "unitPriceB": "Preț Unitar",
        "unitPriceNoVatA": "Unit price (VAT exluded)",
        "unitPriceNoVatB": "Preț Unitar (fără TVA)",
        "amountA": "Amount",
        "amountB": "Valoare",
        "vatAmountA": "VAT Amount",
        "vatAmountB": "Valoare TVA",
        "vatSuffixA": "(incl. VAT)",
        "vatSuffixB": "(incl. TVA)",
        "totalA": "TOTAL",
        "totalB": "TOTAL",
        "grandTotalA": "GRAND TOTAL",
        "grandTotalB": "TOTAL GENERAL",
        "instructionA": "Please make the payment to the International Bank Account Number (IBAN) indicated above. Please make the payment using the same currency as the invoice.",
        "instructionB": "Vă rugăm să efectuați plata utilizând codul IBAN indicat anterior. Vă rugăm să efectuați plata folosind moneda pe care o folosește și factura.",
        "disclaimerA": "The invoice circulates without signature and stamp according to art. V, paragraph (2) of the Ordinance no.17 / 2015 and art. 319 paragraph (29) of Law no. 227/2015 regarding the Fiscal Code.",
        "disclaimerB": "Factura circulă fără semnătură și ștampilă cf. art.V, alin (2) din Ordonanța nr.17/2015 și art. 319 alin (29) din Legea nr. 227/2015 privind Codul fiscal.",
        "pieceNameA": "According to Annex 1",
        "pieceNameB": "Conform Anexa 1",
    },
    "ro-ro": {
        "title": "FACTURĂ",
        "series": "Seria:",
        "no": "Nr.",
        "date": "Data:",
        "due": "Scadentă la:",
        "summary": "Sumar",
        "totalDueA": "TOTAL DE PLATĂ",
        "totalDueB": "",
        "provider": "Furnizor",
        "customer": "Client",
        "tradeRegistry": "Reg. Com:",
        "taxId": "CUI:",
        "shareCapital": "Capital Social:",
        "street": "Strada:",
        "bankAccount": "Cont Bancar (IBAN):",
        "email": "Email:",
        "vatRate": "Cota TVA:",
        "noA": "Nr.",
        "noB": "",
        "productA": "Produs/Serviciu",
        "productB": "",
        "webServicesA": "Servicii Web - Resursa ",
        "webServicesB": "",
        "unitA": "U.M.",
        "unitB": "",
        "hourA": "Oră",
        "hourB": "",
        "otherProjectGroups": "Altele",
        "pieceA": "Bucată",
        "pieceB": "",
        "quantityA": "Cantitate",
        "quantityB": "",
        "unitPriceA": "Preț Unitar",
        "unitPriceB": "",
        "unitPriceNoVatA": "Preț Unitar (fără TVA)",
        "unitPriceNoVatB": "",
        "amountA": "Valoare",
        "amountB": "",
        "vatAmountA": "Valoare TVA",
        "vatAmountB": "",
        "totalA": "TOTAL",
        "totalB": "",
        "vatSuffixA": "(incl. TVA)",
        "vatSuffixB": "",
        "grandTotalA": "TOTAL GENERAL",
        "grandTotalB": "",
        "instructionA": "Vă rugăm să efectuați plata utilizând codul IBAN indicat anterior. Vă rugăm să efectuați plata folosind moneda pe care o folosește și factura.",
        "instructionB": "",
        "disclaimerA": "Factura circulă fără semnătură și ștampilă cf. art.V, alin (2) din Ordonanța nr.17/2015 și art. 319 alin (29) din Legea nr. 227/2015 privind Codul fiscal.",
        "disclaimerB": "",
        "pieceNameA": "Conform Anexa 1",
        "pieceNameB": "",
    }
}