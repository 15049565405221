import Fetch from "../../helpers/Fetch";
import { ComputerDTO } from "../../interfaces/Computer";
import UserDTO from "../dtos/User";

export async function listComputers(): Promise<ComputerDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ComputerDTO[]>(`/computers`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch computers');
}

export async function listComputerDetails(computerId: string): Promise<ComputerDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ComputerDTO>(`/computers/${computerId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch computer details');
}

export async function listUserComputers(userId: string): Promise<ComputerDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ComputerDTO>(`/computers/user/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch computer details');
}