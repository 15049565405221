import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import { TransitionContext } from '../../TransitionEvent';
import RequestStatus from '../../utils/RequestStatus/RequestStatus';

export interface IBeardcrumb {
    label: string,
    path?: string,
}

export interface Props {
  items?: IBeardcrumb[],
  pageTitle?: string,
  status?: TRequestStatus,
}

interface State {
  container: HTMLElement | null
}

class BreadcrumbControls extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      container: null
    }
  }

  componentDidMount(): void {
    this.setState({
      container: document.getElementById('headerBreadcrums')
    })
  }

  renderItems(items: IBeardcrumb[]) {
    return(items.map((item: IBeardcrumb, index) => (
      item.path ?
        <span key={index}>
          <Link to={item.path} className="link-button">
            {item.label}
          </Link>
          <small className="fas fa-angle-right"></small>
        </span>
      :
        <span key={index}>{item.label}</span>
    )))
  }

  render() {
      if(this.state.container && !this.context.isExiting) {
        return ReactDOM.createPortal(
          <div className="inner-breadcrumb">
            <h1 className="primary-title hide-print">
              {this.props.items ?
                this.renderItems(this.props.items)
              :  
                <>
                  {this.props.pageTitle}
                </>
              }
              <RequestStatus status={this.props.status ? this.props.status : 'idle'} />
            </h1>
          </div>,
          this.state.container
        )
      }
    return (<></>);
  }
}

export default BreadcrumbControls;
BreadcrumbControls.contextType = TransitionContext;