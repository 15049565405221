import React, { Component } from 'react';

interface Props {
  label: string,
  type: string,
  name: string,
  id: string,
  
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void,
  autoComplete?: string,
  br?: boolean,
  placeholder?: string,
  srOnly?: boolean,
  value?: string | number,
  disabled?: boolean,
  required?: boolean,
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void,
  error?: string,
  maxLength?: number,
  tight?: boolean,
}

class TextControl extends Component<Props> {
  render() {
    return (
      <div className={`form-group ${this.props.tight ? 'tight' : ''}`}>
        <label className={`${this.props.srOnly ? "sr-only" : ""}`} htmlFor={this.props.id}>{this.props.label}{this.props.required ? "*" : ""}</label>
        {this.props.br && <br />}
        <input
          type={this.props.type}
          name={this.props.name}
          id={this.props.id}
          autoComplete={this.props.autoComplete}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          value={this.props.value}
          required={this.props.required}
          onBlur={this.props.onBlur}
          maxLength={this.props.maxLength}
          area-invalid={`${!!this.props.error}`}
        />
        {this.props.error ? <ul className="error-list light"><li>{this.props.error}</li></ul> : ''}
      </div>
    )
  }
}

export default TextControl;
