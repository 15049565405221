import React, { Component, KeyboardEvent as ReactKeyboardEvent } from 'react';

type Options<T> = Array<number | string | T>

interface Props<T> {
  idName: string,
  label?: string,
  required?: boolean,
  value: unknown,
  options: Options<T>,
  disabled?: boolean,
  disabledValue?: string | number | T | null,
  classes?: string,
  error?: string,

  onChange: (ev: ReactKeyboardEvent | React.MouseEvent<HTMLLIElement>, value: number | string | undefined | T) => void,
  getValue: (option: T) => unknown,
  getLabel: (option: T) => string,
  onBlur?: (ev: React.FocusEvent<HTMLButtonElement>) => void,
}

interface State {
  showMenu: Boolean,
  showError: Boolean,
  verticalPos: number | undefined,
  dropdownXPos?: number,
  dropdownYPos?: number,
  dropUp: boolean,
  minWidth?: number,
  dropdownMinWidth?: number,
  selectedValue: unknown,
  shiftKey: boolean,
  substring: string,
  lastKeyPress: number,
}

class SelectControl<T> extends Component<Props<T>, State> {
  dropdownOptionsRef: React.RefObject<HTMLUListElement>;
  dropdownButtonRef: React.RefObject<HTMLButtonElement>;

  static defaultProps = {
    getValue: (option: unknown) => option,
    getLabel: (option: unknown) => option,
  };

  

  constructor(props: Props<T>) {
    super(props);
    this.dropdownOptionsRef = React.createRef();
    this.dropdownButtonRef = React.createRef();
    let windownInnerHeight = window.innerHeight;
    const opensAbove = this.dropdownButtonRef.current && this.dropdownOptionsRef.current && windownInnerHeight - this.dropdownButtonRef.current.getBoundingClientRect().y < this.dropdownOptionsRef.current?.offsetHeight + 72; 
    this.state = {
      showMenu: false,
      showError: false,
      verticalPos: this.dropdownOptionsRef.current?.offsetHeight,
      selectedValue: null,
      shiftKey: false,
      dropUp: opensAbove ?? false,
      substring: '',
      lastKeyPress: 0,
    }
  }

  toggleMenu = () => {
    this.setState((prevState: State) => {
      return {
        showMenu: !prevState.showMenu,
        selectedValue: !prevState.showMenu ? this.props.value : null,
      };
    }, () => {
      //Check the position of the DropdownMenu in the page and set the CSS top of the element
      let windownInnerHeight = window.innerHeight;
      const opensAbove = this.dropdownButtonRef.current && this.dropdownOptionsRef.current && windownInnerHeight - this.dropdownButtonRef.current.getBoundingClientRect().y < this.dropdownOptionsRef.current?.offsetHeight + 72; 

      if (opensAbove) {
        this.setState({
          verticalPos: 0,
          dropUp: true,
          dropdownXPos: this.dropdownButtonRef.current ? this.dropdownButtonRef.current.getBoundingClientRect().x : 0,
          dropdownYPos: this.dropdownButtonRef.current ? this.dropdownButtonRef.current.getBoundingClientRect().y + this.dropdownButtonRef.current.getBoundingClientRect().height : (this.props.label ? 22 : 0)
        });
      } else {
        this.setState({
          verticalPos: this.props.label ? 22 : 0,
          dropUp: false,
          dropdownXPos: this.dropdownButtonRef.current ? this.dropdownButtonRef.current.getBoundingClientRect().x : 0,
          dropdownYPos: this.dropdownButtonRef.current ? this.dropdownButtonRef.current.getBoundingClientRect().y + this.dropdownButtonRef.current.getBoundingClientRect().height : (this.props.label ? 22 : 0)
        });
      }
    })
  };

  handleClickOutside = (ev: MouseEvent) => {
    if (this.dropdownOptionsRef && !this.dropdownOptionsRef.current?.contains(ev.target as HTMLElement) && !this.dropdownButtonRef.current?.contains(ev.target as HTMLElement)) {
      this.setState({
        showMenu: false,
      })
    }
  }

  handleScrollOutside = (ev: Event) => {
    if (this.state.showMenu && this.dropdownOptionsRef && !this.dropdownOptionsRef.current?.contains(ev.target as HTMLElement) && !this.dropdownButtonRef.current?.contains(ev.target as HTMLElement)) {
      this.setState({
        showMenu: false,
      })
    }
  }

  handleToggleKeyDown = (ev: ReactKeyboardEvent) => {
    let options = this.props.options;
    let currentValue = this.state.showMenu ? this.state.selectedValue : this.props.value;
    let getOptionValue = this.props.getValue;
    let dropdownOptions: HTMLUListElement | null = this.dropdownOptionsRef.current;
    const currentTime = new Date().getTime();

    let selectedOption: T | unknown;
    let selectedOptionIndex: number = options.indexOf(options.find(option => getOptionValue(option as T) === currentValue) as T)
    let optionNode: HTMLElement | null | undefined;
    let lastOption = options[options.length - 1];

    const getNextOption = () => {
      switch (currentValue) {
        case getOptionValue(lastOption as T):
          //If last option is selected and you want to select the next option
          break;
          
          default:
            // Selecting next option
            let nextOption = options[selectedOptionIndex + 1];
            selectedOption = getOptionValue(nextOption as T);
            optionNode = dropdownOptions?.querySelector(`[value="${selectedOption}"]`);
      }
    }

    const getPrevOption = () => {
      switch (currentValue) {
        case undefined:
        case 0:
          //If there is no value selected
        break;

        case getOptionValue(options[0] as T):
          //If there is a disabled value return so getOptionValue does not return an error
          if(this.props.disabledValue) {
            break;
          }

        break;

        default:
          //Selecting previous option
          selectedOption = getOptionValue(options[selectedOptionIndex - 1] as T);
          optionNode = dropdownOptions?.querySelector(`[value="${selectedOption}"]`);
      }
    }

    const getOption = () => {
      selectedOptionIndex = options.indexOf(currentValue as string | number | T);
      const normalizedString = (string: string) => {
        return string.normalize("NFD").replace(/\p{Diacritic}/gu, "");
      }

      let optionStartsWithSubstring = options.find(option => normalizedString(String(this.props.getLabel(option as T)))
                                                             .toLocaleLowerCase()
                                                             .startsWith(normalizedString(this.state.substring)));

      if (optionStartsWithSubstring !== undefined) {
        selectedOption = getOptionValue(optionStartsWithSubstring as T);
        optionNode = dropdownOptions?.querySelector(`[value="${selectedOption}"]`);
      }
    }

    switch (ev.key) {
      case "ArrowDown":
      case "ArrowRight":
        ev.preventDefault();
        getNextOption();
      break;

      case "ArrowUp":
      case "ArrowLeft":
        ev.preventDefault();
        getPrevOption();
      break;
      case "Tab":
        if(this.state.showMenu == true) {
          this.setState({
            showMenu: false,
          });
        }
      break;
      case "Escape":
        //Close dropdown when pressing Escape/Tab
        this.setState({
          showMenu: false,
        });
      break;

      case " ":
      case "CapsLock":
      break;

      case "Enter": 
        if (this.state.showMenu) {
          this.props.onChange(ev as ReactKeyboardEvent, this.state.selectedValue as T);
          this.setState({showMenu: false})
        }
      break;

      default:
        //Select the first option in the options array which starts with key
        if (currentTime - this.state.lastKeyPress < 800) {
          this.setState(prevState => {
            return {
              ...prevState,
              substring: prevState.substring + ev.key.toLocaleLowerCase()
            }
          }, () => {
            getOption();
            selectOption();
          })
        } else {
          this.setState({substring: ev.key.toLocaleLowerCase()}, () => {
            getOption();
            selectOption();
          })
        }
        
    }

    const selectOption = () => {
      if (selectedOption !== undefined) {
        if (dropdownOptions?.classList.contains('open')) {
          this.scrollToDropdownOption(optionNode, dropdownOptions);
          this.setState({selectedValue: selectedOption});
        } else {
          this.props.onChange(ev as ReactKeyboardEvent, selectedOption as T);
        }
      }
    }

    selectOption();
  }

  scrollToDropdownOption(option: HTMLElement | null | undefined, dropdownOptions: HTMLUListElement | null) {
    let menuY = dropdownOptions?.getBoundingClientRect().y;
    let optionY = option && dropdownOptions && option.getBoundingClientRect().y + dropdownOptions.scrollTop;

    dropdownOptions?.querySelector(".selected")?.classList.remove("selected");
    option && option.classList.add("selected");
    option && option.focus();

    if (menuY && optionY) {
      dropdownOptions?.scrollTo({ top: optionY - menuY - 4, behavior: "auto" })
    }
  }

  getMinWidth() {
    let elements = this.dropdownOptionsRef.current?.querySelectorAll<HTMLSpanElement>("li > span") as NodeList;
    
    if(!elements) return;

    const nodeList = Array.from(elements);
    
    const widths = nodeList.map((listItem) => (listItem as HTMLSpanElement).offsetWidth);
    this.setState({
      //magic numbers and magic timeout
      minWidth: (widths.length > 1 ? Math.max(...widths) : Number(widths[0])) + 42,
    })
  }

  getDropdownMinWidth() {
    if (this.dropdownButtonRef.current) {
      this.setState({
        dropdownMinWidth: this.dropdownButtonRef.current.offsetWidth 
      })
    }
  }

  handleDocumentKeyDownEvent = (ev: KeyboardEvent) => {
    const currentTime = new Date().getTime();
    this.setState({lastKeyPress: currentTime});

    if (ev.shiftKey) {
      this.setState({shiftKey: true})
    }
  }

  handleDocumentKeyUpEvent = () => {
    this.setState({shiftKey: false})
  }

  componentDidMount() {
    this.getMinWidth();
    this.getDropdownMinWidth();

    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleDocumentKeyDownEvent);
    document.addEventListener("keyup", this.handleDocumentKeyUpEvent);
    document.addEventListener("scroll", this.handleScrollOutside, true);
  }

  //Need to show the error with a small delay because because when clicking an option we trigger option onBlur method which validates the field before the state is changed;
  componentDidUpdate(prevProps: Props<T>) {
    if (this.props.error !== prevProps.error) {
      if(this.props.error !== "" || this.props.error !== undefined) {
        setTimeout(() => {
          this.setState({
            showError: true,
          })
        }, 100)
      } else {
        this.setState({
          showError: true,
        })
      }
    }
    if (this.props.options !== prevProps.options && this.props.options.length) {
      this.getMinWidth();
      this.getDropdownMinWidth();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleDocumentKeyDownEvent);
    document.removeEventListener('keyup', this.handleDocumentKeyUpEvent);
    document.removeEventListener('scroll', this.handleScrollOutside, true);
  }

  render() {
    const valueLabel = this.props.options !== undefined && this.props.getLabel(this.props.options.find(op => this.props.getValue(op as T) === this.props.value) as T);
    let buttonText;

    if (this.props.value !== undefined) {
      buttonText = valueLabel;
    } else {
      buttonText = this.props.disabledValue ? this.props.disabledValue : this.props.value;
    }

    const errorMessage = <ul className="error-list light"><li>{this.props.error}</li></ul>;
    
    return (
      <>
        {this.props.options.length > 0 &&
          <div className={`form-group`}>
            <div className={`dropdown ${this.props.error ? 'error' : ''}`}
              style={{
                minWidth: this.state.minWidth
              }}>
              {this.props.label && (
                <>
                  <label htmlFor={this.props.idName}>
                    {this.props.label}{this.props.required && '*'}
                  </label>
                  <br />
                </>
              )}
              <button
                ref={this.dropdownButtonRef}
                className={`dropdown-toggle ${this.props.classes ?? ''}`}
                id={this.props.idName}
                name={this.props.idName}
                onClick={() => {
                  this.toggleMenu();
                  //Scroll to selected option when opening the dropdown
                  setTimeout(() => {
                    this.scrollToDropdownOption(this.dropdownOptionsRef.current?.querySelector(`[value="${this.props.value}"]`), this.dropdownOptionsRef.current)
                  }, 10)
                }}
                onKeyDown={this.handleToggleKeyDown}
                disabled={this.props.disabled}
                onBlur={this.props.onBlur}
              >
                {buttonText}
              </button>

              <ul className={`dropdown-options ${this.state.showMenu ? 'open' : ''} ${this.state.dropUp ? 'reversed-anim' : ''}`} ref={this.dropdownOptionsRef}
                style={{ minWidth: this.state.dropdownMinWidth, top: this.state.dropdownYPos, left: this.state.dropdownXPos, transform: `${this.state.dropUp ? 'translateY(-100%)' : 'translateY(-2rem)'}` }}
              >
                {this.props.disabledValue && (
                  <li className='disabled'><span>{this.props.disabledValue}</span></li>
                )}
                {this.props.options?.map((option, index) => {
                  const optionValue = this.props.getValue(option as T) as string | number;
                  return (
                    <li
                      key={index}
                      value={optionValue}
                      tabIndex={0}
                      onClick={(ev) => {
                        if (this.props.value !== optionValue) {
                          this.props.onChange(ev, optionValue);
                        }
                        this.setState({
                          showMenu: false
                        });
                      }}
                      onKeyDown={this.handleToggleKeyDown}
                    >
                      <span>{this.props.getLabel(option as T)}</span>
                    </li>
                  );
                })}
              </ul>
            </div>

            {this.state.showError && this.props.error ? errorMessage : ''}
          </div>
        }
      </>
    )
  }
}
export default SelectControl;