import Joi from 'joi';
import moment from 'moment';
import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import UserDTO from '../../../../common/api/dtos/User';
import AppContext from '../../../../common/context/AppContext';
import { addSuspension, listSuspensionDetails, updateSuspension } from '../../../../common/api/endpoints/suspensions';
import { FormErrors } from '../../../../common/data/FormErrors';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SelectControl from '../../../controls/SelectControl/SelectControl';
import TextAreaControl from '../../../controls/TextAreaControl/TextAreaControl';
import { listUserContracts } from '../../../../common/api/endpoints/contracts';
import ContractDTO from '../../../../common/api/dtos/Contract';
import { listUsers } from '../../../../common/api/endpoints/users';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TextControl from '../../../controls/TextControl/TextControl';
import { withTransitionEvent } from '../../../TransitionEvent';

export interface Props extends RouteComponentProps<RouteParams> {
}

interface RouteParams {
  id?: string,
  eid?: string,
  entity?: string,
}

interface FormData {
  userId?: number,
  description: string,
  startDate: Date | null,
  endDate: Date | null,
  deleted: boolean,
  countable: boolean,
  contractId?: number,
}

interface State {
  suspensionId?: number,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  formData: FormData,
  formErrors: FormErrors,
  users?: UserDTO[],
  userContracts?: ContractDTO[],
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class SuspensionDetail extends Component<Props, State> {
  formSchema = Joi.object({
    userId: Joi.number().required().messages(FormErrors.number),
    contractId: Joi.number().required().messages(FormErrors.number),
    description: Joi.string().trim(true).required().messages(FormErrors.string),
    startDate: Joi.date().required().messages(FormErrors.date),
    endDate: Joi.date().required().messages(FormErrors.date),
    deleted: Joi.boolean(),
    countable: Joi.boolean(),
  })

  constructor(props: Props) {
    super(props);
    this.state = {
      suspensionId: Number(this.props.match.params.id),
      formData: {
        description: '',
        startDate: null,
        endDate: null,
        deleted: false,
        countable: false,
        userId: this.props.match.params.entity == 'user' ? Number(this.props.match.params.eid) : undefined,
      },
      progress: {
        currentStep: 0,
        totalSteps: 0,
      },
      pageStatus: 'idle',
      formStatus: 'idle',
      formErrors: {},
    }
  }
  
  fetchSuspensionDetails = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const suspension = await listSuspensionDetails(this.state.suspensionId!);
    this.setState(prevState => {
      return {
        formData: {
          description: suspension.description,
          startDate: suspension.startDate,
          endDate: suspension.endDate,
          deleted: suspension.deleted,
          countable: suspension.countable,
          userId: suspension.user?.id,
          contractId: suspension.contractId,
        },
        progress: incrementProgress(prevState.progress),
        pageStatus: 'success',
      }
    }, () => {
      this.fetchUserContracts();
    });
  }

  fetchUsers = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const users = await listUsers();

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        users,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  fetchUserContracts = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const userContracts = await listUserContracts(this.state.formData.userId!);
    this.setState({
        pageStatus: 'success',
        userContracts: userContracts,
    });
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addSuspension(this.state.formData);

    let entityName = this.props.match.params.entity;
    let entityId = this.props.match.params.eid;

    let returnPath = entityId ? `/${entityName}/edit/${entityId}/suspensions` : '/suspension';

    this.setState({
        formStatus: 'success',
    }, () => {
      this.props.history.push({
        pathname: returnPath,
      })
    });
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await updateSuspension(this.state.formData, this.state.suspensionId!);

    this.setState({
        formStatus: 'success',
    });
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
  
      if(!isEditPage(this.state.suspensionId)) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    this.fetchUsers();
    
    if(this.props.match.params.eid) {
      this.fetchUserContracts();
    }

    if(isEditPage(this.state.suspensionId)) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1,
          }
        }
      });
      this.fetchSuspensionDetails();
    }
  } 

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      }
    })
  }

  renderBreadcrumbs() {
    const entity = this.props.match.params.entity;
    const entityId: number = Number(this.props.match.params.eid);

    let entityName;
    
    if(entity) {
      entityName = entity == 'user' ? this.state.users?.find((user: UserDTO) => user.id == entityId)!.name : 'Unknown Entity';
    }

    return(
      !entity ?
        <BreadcrumbControls 
          items={[
            {label: 'suspensions', path: '/suspensions'},
            {label: `${!isEditPage(this.state.suspensionId) ? 'adding' : 'editing'} suspension ${this.state.formData.description}`},
          ]}
          status={this.state.pageStatus}
        />
      :
        <BreadcrumbControls 
          items={[
            {label: `${entity}s`, path: `/${entity}`},
            {label: entityName ? entityName : '', path: `/${entity}/edit/${entityId}`},
            {label: 'suspensions', path: `/${entity}/edit/${entityId}/suspensions`},
            {label: `${!isEditPage(this.state.suspensionId) ? 'adding' : 'editing'} suspension ${this.state.formData.description}`},
          ]}
          status={this.state.pageStatus}
        />
    )
  }

  renderForm() {
    const currentContract = this.state.userContracts?.find((userContract: ContractDTO) => userContract.id == this.state.formData.contractId);
    return(
      <div className="card">
        <SelectControl
          idName="userId"
          label="Signatory"
          required={true}
          value={this.state.formData.userId}
          options={this.state.users || []}
          disabled={!loadComplete(this.state.progress) || isEditPage(Number(this.state.suspensionId)) || (!!this.props.match.params.eid && this.props.match.params.entity == 'user')}
          disabledValue={!isEditPage(Number(this.state.suspensionId)) ? "Select a signatory" : null}
          onChange={this.setUserId}
          getValue={(op: UserDTO) => op.id}
          getLabel={(op: UserDTO) => op?.fullName ? op.fullName : 'Unknown user'}
          onBlur={(ev: React.FocusEvent<HTMLButtonElement>) => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.userId}
        />
        <SelectControl
          idName="contractId"
          label="Contract"
          required={true}
          value={this.state.formData.contractId}
          options={this.state.userContracts || []}
          disabled={!loadComplete(this.state.progress) || isEditPage(Number(this.state.suspensionId)) || !this.state.userContracts?.length}
          disabledValue={!isEditPage(Number(this.state.suspensionId)) ? "Select a contract" : null}
          onChange={this.setContractID}
          getValue={op => op.id}
          getLabel={op => op?.name}
          onBlur={(ev: React.FocusEvent<HTMLButtonElement>) => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.contractId}
        />
        {currentContract && 
          <div className="form-group">
            <div className="card">
              <div className="flex-row squeeze">
                <div className="column flex-v-center">
                  <span className="fas fa-exclamation-circle negative-text"></span>
                </div>
                <div className="column">
                  <small>{!isEditPage(Number(this.state.suspensionId)) ? 'You are permanently attaching this suspension to the following contract:' : 'This suspension is permanently attached to the following contract:'}</small><br />
                  <small className="faint-text">
                    {currentContract.description}<br />
                    {moment(currentContract.startDate).format('LL')} - 
                    {currentContract.endDate ? moment(currentContract.endDate).format('LL') : ' indefinite'}<br />
                    {currentContract.tenant.legalName}<br />
                  </small>
                </div>
              </div>
            </div>
          </div>
        }
        <TextAreaControl
          label="Description"
          id="description"
          name="description"
          value={this.state.formData.description}
          onChange={this.setDescription}
          disabled={!loadComplete(this.state.progress)}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.description}
        />
        <TextControl
          label="Start Date"
          type="date"
          name="startDate"
          id="startDate"
          value={moment(this.state.formData.startDate).format('Y-MM-DD')}
          onChange={this.setStartDate}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.startDate}
        />
        <TextControl
          label="End Date"
          type="date"
          name="endDate"
          id="endDate"
          value={moment(this.state.formData.endDate).format('Y-MM-DD')}
          onChange={this.setEndDate}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.endDate}
        />  
        <ToggleControl
          id="countable"
          name="countable"
          changeMethod={this.setCountable}
          isChecked={this.state.formData.countable}
          isDisabled={!loadComplete(this.state.progress)}
          labelText="Affects Paid Days"
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        {isEditPage(this.state.suspensionId) && 
          <ToggleControl
            id="deleted"
            name="deleted"
            changeMethod={this.setDeleted}
            isChecked={this.state.formData.deleted}
            isDisabled={!loadComplete(this.state.progress)}
            labelText="Deleted"
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          />
        }
        <div className="form-group">
          <ButtonControl
            class="primary-button"
            onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              this.handleSubmit(ev)
            }}
            disabled={!loadComplete(this.state.progress)}
          >
            <RequestStatus status={this.state.formStatus} />
            <span className="text">Save</span>
          </ButtonControl>
        </div>
      </div>
    );
  }

  setUserId = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, userId: number | string | undefined | UserDTO) => {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        userId: Number(userId),
      }
    }, () => {
      this.fetchUserContracts();
      this.validateFormField('userId')
    })
  };

  setContractID = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, contractId: number | string | undefined | ContractDTO) => {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        contractId: Number(contractId),
      }
    }, () => {
      this.validateFormField('contractId');
    })
  };
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);
  setStartDate = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('startDate', new Date(ev.target.value));
  setEndDate = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('endDate', new Date(ev.target.value));
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);
  setCountable = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('countable', ev.target.checked);

  render() {
    return (
     <React.Fragment>
      {this.renderBreadcrumbs()}
      <div className="flex-row">
        <div className="column">
          <div className="flex-row tightest-top">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
          {this.renderForm()}
        </div>
      </div>
     </React.Fragment>
    );
  }
}

export default withTransitionEvent(SuspensionDetail);

SuspensionDetail.contextType = AppContext;