import React, { ChangeEvent } from 'react';
import currencyFormatter from '../../../../common/helpers/CurrencyFormatter';
import { RowComponentProps } from '../../../generics/Invoices/InvoiceTable/InvoiceTable';
import ButtonControl from '../../../../components/controls/ButtonControl/ButtonControl';

/**
 * Pased of off InvoiceRow and customized for piece invoices.
 */

interface State {
  unitPrice: string,
}
export default class PieceInvoiceRow extends React.Component<RowComponentProps, State> {
  constructor(props: RowComponentProps) {
    super(props);
    this.state = {
      unitPrice: props.invoiceItem.unitPrice.toString(),
    }
  }

  private onUnitPriceChange = (ev: ChangeEvent<HTMLInputElement>) => {
    let input = ev.target.value;
    const isValidNumber = !Number.isNaN(Number(input))
    const isOnlyMinus = input === "-"
    // don't emit for non-number values
    if (isValidNumber || isOnlyMinus) {
      this.setState({ unitPrice: input });
      this.props.onChange({
        ...this.props.invoiceItem,
        unitPrice: Number(input)
      });
    }
  };

  private onQuantityChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const number = Number(ev.target.value);
    // don't emit for non-number values
    if (Number.isNaN(number) === false) {
      this.props.onChange({
        ...this.props.invoiceItem,
        quantity: number
      });
    }
  };

  render() {
    const { invoiceItem, hasVat, currencySymbol, quantityLabelA, quantityLabelB, onItemDelete, onChange } = this.props;
    // Issue a warning if we're not receiving the expected fields when 'hasVat' is true
    console.assert(hasVat ? typeof invoiceItem.unitPriceExclVat === 'number' : true, "Expected 'unitPriceExclVat' prop of InvoiceRow to be a number when 'hasVat' is true");
    console.assert(hasVat ? typeof invoiceItem.vatAmount === 'number' : true, "Expected 'vatAmount' prop of InvoiceRow to be a number when 'hasVat' is true");
    
    return <tr>
      <td></td>
      <td>
        <span contentEditable suppressContentEditableWarning={true}>
          {invoiceItem.name.split("\n").map(function(item, index) {
            return (
              <React.Fragment key={index}>
                {index !== 0 &&
                  <br />
                }
                <span>
                  {item}
                </span>
              </React.Fragment>
            )
          })}
        </span>
      </td>
      <td>
        <span contentEditable suppressContentEditableWarning={true}>{quantityLabelA}<br />{quantityLabelB}</span>
      </td>
      <td className="text-right">
        <input value={invoiceItem.quantity} type="number" onChange={this.onQuantityChange} />
      </td>
      <td className="text-right">
        <input value={this.state.unitPrice} type="text" onChange={this.onUnitPriceChange} />
        {hasVat && <span>&nbsp;{currencyFormatter(invoiceItem.unitPriceExclVat!, currencySymbol)}</span>}
      </td>
      <td className="text-right">
        <span>{currencyFormatter(invoiceItem.amount, currencySymbol)}</span>
      </td>
      {hasVat && <td className="text-right">
        <span>{currencyFormatter(invoiceItem.vatAmount!, currencySymbol)}</span>
      </td>}
      <td className="text-right hide-print">
        {invoiceItem.id !== 1 &&
          <ButtonControl
          class="primary-button"
          onClick={() => onItemDelete?.(invoiceItem.id) && onChange(invoiceItem)}
          >
          <span>Delete</span>  
        </ButtonControl>}
      </td>

    </tr>;
  }
}