import { TSortDirection } from "../types/SortDirection";
import TableSort from "./TableSort";

export function ChangeSortBy(newColumn: string, prevColumn: string, prevDirection: TSortDirection) {
		let newSortDirection: TSortDirection = 'asc';

		if(prevColumn === newColumn) {
			newSortDirection = prevDirection === 'asc' ? 'desc' : 'asc';
		}

	return new TableSort(newColumn, newSortDirection);
}