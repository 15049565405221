import 'moment-timezone';
import moment from 'moment-timezone';
import React, { Component, KeyboardEvent } from 'react';
import { ClockingDayDTO } from '../../../common/api/dtos/Clocking';
import ContractDTO from '../../../common/api/dtos/Contract';
import HolidayDTO from '../../../common/api/dtos/Holiday';
import SuspensionDTO from '../../../common/api/dtos/Suspension';
import SyspropDTO from '../../../common/api/dtos/Sysprop';
import UserDTO from '../../../common/api/dtos/User';
import VacationsDTO from '../../../common/api/dtos/Vacations';
import { ClokingData, createClockingDay, listClockingDays, updateClockingDay } from '../../../common/api/endpoints/clocking';
import { listEmployeeContractsByUser } from '../../../common/api/endpoints/contracts';
import { listUserSuspensions } from '../../../common/api/endpoints/suspensions';
import { listUserVacations } from '../../../common/api/endpoints/vacations';
import AppContext from '../../../common/context/AppContext';
import { ClockingTiles } from '../../../common/data/LegendTiles';
import { Month, Months } from '../../../common/data/Months';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import SelectControl from '../../controls/SelectControl/SelectControl';
import { ContractPair, getDaysInMonth, getHolidayName, hasElevatedAccess, hasFullAccess, processSuspensions, suspensionEntry, trimDate } from '../../generics/Booking/bookingUtils';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import ToolbarControls from '../../generics/Header/ToolbarControls';
import { computeMonthRange, extractYears } from '../../generics/Invoices/utils';
import { withTransitionEvent } from '../../TransitionEvent';
import ClockingDay from '../../utils/ClockingDay/ClockingDay';
import ProgressBar, { incrementProgress, IProgress, newProgress } from '../../utils/ProgressBar/ProgressBar';
import LegendTile from '../Bookings/LegendTile';
import { RouteComponentProps } from 'react-router';
import hasAccess from '../../../common/helpers/Access';

type DayType = 'holiday' | 'paid' | 'unpaid' | 'absence' | 'untracked' | 'sick' | 'extra' | 'loyalty' | 'unemployed' | '';

export interface RouteParams {
  id?: string
}

interface Props extends RouteComponentProps<RouteParams> {
  sysProps: SyspropDTO[],
  loggedUser: LoggedUser,
}

interface State {
  form: Form,
  progress: IProgress,
  status: TRequestStatus,
  years: number[],
  clockingDays: ClockingDayEntry[],
  vacations: VacationsDTO[],
  suspensions: SuspensionDTO[],
  suspensionsByDay: suspensionEntry[],
  contracts: ExtendedContractPair[],
  counters: Counters,
}

interface Form {
  selectedYear: number,
  month: number,
  userId: number,
  contractId?: number | null
}

export interface ClockingDayEntry  {
  id?: number,
  dayNo: number,
  timeFrom: string | null,
  timeTo: string | null,
  dayType: DayType,
  date: string,
  totalHours: number,
  inputStatus: EntryRequestStatus
}

interface EntryRequestStatus {
  timeFrom: TRequestStatus,
  timeTo: TRequestStatus,
}

interface Counters {
  totalHours: number,
  totalTickets: number,
  paid: number,
  extra: number, 
  sick: number,
  holiday: number,
  absence: number,
}

interface ExtendedContractPair extends ContractPair {
  hasTickets: boolean
}

const POST_METOD_FLAG = -1;

class Clocking extends Component<Props, State> {
  context!: React.ContextType<typeof AppContext>;
  noOfTickets: number;

  constructor(props: Props) {
    super(props);
    const targetUserId = Number(this.props.match.params.id || this.props.loggedUser.id);
    const access = hasAccess(this.props.loggedUser?.role);
    const userId = access ? targetUserId : this.props.loggedUser.id;

    this.noOfTickets = 0;
    this.state = {
      ...this.getLocalStorageData(),
      form: {
        selectedYear: new Date().getFullYear(),
        month: new Date().getMonth(),
        userId: userId,
      },
      progress: {
        currentStep: 0,
        totalSteps: 4,
      },
      status: 'idle',
      years: [],
      clockingDays: [],
      vacations: [],
      suspensions: [],
      suspensionsByDay: [],
      contracts: [],
      counters: {
        totalHours: 0,
        totalTickets: 0,
        paid: 0,
        extra: 0, 
        sick: 0,
        holiday: 0,
        absence: 0
      }
    }
  }

  private getLocalStorageData() {
    let hideFootnote = JSON.parse(localStorage.getItem("hideTimetrackFootnote") || 'false');
    let hidFootnoteAt = JSON.parse(localStorage.getItem("hidTimetrackFootnoteAt") || 'false');
    let validFor = 7 * 24 * 60 * 60 * 1000; //7 days
    let validUntil = hidFootnoteAt + validFor;
    let currentStamp = (new Date()).getTime();

    //expired
    if(currentStamp > validUntil) {
      hideFootnote = false;
      localStorage.removeItem("hideTimetrackFootnote");
      localStorage.removeItem("hidTimetrackFootnoteAt");
    }

    return {
      hideFootnote: hideFootnote,
      hidFootnoteAt: hidFootnoteAt,
    }
  }

  private incrementProgress = <T,>(result: T) => {
    this.setState(prevState => {
      return {
        progress: incrementProgress(prevState.progress),
      }
    });

    return result;
  }

  private async initialize() {
    this.setState({
      status: 'loading',
      years: extractYears(this.props.sysProps),
      progress: newProgress(4),
    })    

    // 1.Get all data
    const contracts: ExtendedContractPair[] = [];
    const [vacations, suspensions, userContracts] = await Promise.all([
      listUserVacations(this.state.form.userId).then(this.incrementProgress),
      listUserSuspensions(this.state.form.userId).then(this.incrementProgress),
      listEmployeeContractsByUser(this.state.form.userId).then(this.incrementProgress)
    ]);

    // Create contracts array for select control
    userContracts.forEach((userContract: ContractDTO) => {
      if(!userContract.deleted) {
        contracts.push({
          id: userContract.id,
          name: userContract.name,
          startDate: userContract.startDate,
          endDate: userContract.endDate,
          deleted: userContract.deleted,
          hasTickets: userContract.hasTickets
        })
      }
    });

    // 2.Set the state
    this.setState((prevState) => {
      return {
        status: 'success',
        vacations,
        suspensions,
        contracts: contracts,
        form: {
          ...prevState.form,
          contractId: contracts.length ? this.getDefaultContract(contracts)?.id as number : null
        }
      }
    })
  }

  getDefaultContract = (contracts: ContractPair[]) => {
    const defaultContract = contracts.find((contract: ContractPair) => contract.endDate === null);
    
    return defaultContract !== undefined ? defaultContract : contracts[0];
  }

  getSuspensionsByDay = () => {   
    const filteredSuspensions = this.state.suspensions.filter((suspension: SuspensionDTO) => suspension.contractId === this.state.form.contractId); 
    const suspensionsByDay = processSuspensions(filteredSuspensions, this.state.form.selectedYear);

    this.setState({
      suspensionsByDay
    }, () => {
      // 4.Once you have the suspensions you can eval all day types
      this.createTimeEntries();
    })
  }

  async createTimeEntries() {    
    // 5.Create timeEntries for each day and assign dayType
    let clockingDays: ClockingDayEntry[] = [];
    let nrOfDays = getDaysInMonth(this.state.form.month, this.state.form.selectedYear);
    this.noOfTickets = getDaysInMonth(this.state.form.month, this.state.form.selectedYear);
    let arrayOfDays = Array.from({length: nrOfDays}, (_, i) => i +1);
    let monthRange = computeMonthRange(this.state.form.selectedYear, this.state.form.month);
    this.resetCounters();

    const requestData: ClokingData = {
      userId: this.state.form.userId,
      contractId: this.state.form.contractId as number,
      dateFrom: trimDate(monthRange.since) as string,
      dateTo: trimDate(monthRange.until) as string,
    }

    const loggedTimeEntries = await listClockingDays(requestData).then(this.incrementProgress);
    const isCurrentTennant = loggedTimeEntries[0] !== undefined ? loggedTimeEntries[0].contractId === this.state.form.contractId : false;


    arrayOfDays.forEach((dayNo: number) => {
      const currentDate: Date = new Date(this.state.form.selectedYear, this.state.form.month, dayNo + 1);
      const dayType: DayType = this.evalDayType(currentDate, dayNo);
      const currentTimeEntry = loggedTimeEntries.find((timeEntry: ClockingDayDTO) => trimDate(new Date(timeEntry.date)) === trimDate(currentDate));
      const loggedTimeEntry = isCurrentTennant ? currentTimeEntry : undefined;

      clockingDays.push({
        id: loggedTimeEntry !== undefined ? loggedTimeEntry.id : POST_METOD_FLAG,
        dayNo: dayNo,
        timeFrom: loggedTimeEntry !== undefined ? loggedTimeEntry.timeFrom : null,
        timeTo: loggedTimeEntry !== undefined ? loggedTimeEntry.timeTo : null,
        dayType: dayType,
        date: loggedTimeEntry !== undefined ? loggedTimeEntry.date : trimDate(currentDate) as string,
        totalHours: this.computeTotalHours(loggedTimeEntry as ClockingDayDTO),
        inputStatus: {
          timeFrom: 'idle',
          timeTo: 'idle'
        }
      })
    })

    this.setState(prevState => {
      return {
        clockingDays,
        counters: {
          ...prevState.counters,
          totalTickets: this.noOfTickets
        }
      }
    }, () => {
      this.getTotalHours();
    })
  }

  evalDayType = (dayOfWeek: Date, dayNo: number): DayType => {
    let dayType: DayType = '';

    // Check if day is weekend
    if (this.isWeekend(dayNo)) {
      this.noOfTickets -= 1;
      return '';
    }
    
    // Check if day is unemployment
    if (this.isUnemployed(dayOfWeek)) {
      dayType = 'unemployed';
      this.noOfTickets -= 1;
    } 

    // Check if day is holiday
    (this.context.holidays as HolidayDTO[]).forEach((holiday:HolidayDTO) => {
      if(holiday.deleted) return;
      
      if (trimDate(new Date(holiday.date)) === trimDate(dayOfWeek)) {
        dayType = 'holiday';

        this.setState(prevState => {
          return {
            counters: {
              ...prevState.counters,
              holiday: prevState.counters.holiday + 1
            }
          }
        })

        if (!this.isUnemployed(dayOfWeek)) {
          this.noOfTickets -= 1;  
        }
      }
    })

    // Check if day is vacation
    const filteredVacations = this.state.vacations.filter(vacation => vacation.contractId === this.state.form.contractId);
    filteredVacations.forEach((vacation: VacationsDTO) => {
      if(vacation.deleted || vacation.type === 'untracked' || vacation.type === 'loyalty') return;

      if (trimDate(new Date(vacation.date)) === trimDate(dayOfWeek)) {
        dayType = vacation.type as DayType;
        this.noOfTickets -= 1;

        this.setState(prevState => {
          return {
            counters: {
              ...prevState.counters,
              [dayType]: prevState.counters[dayType as keyof Counters] + 1
            }
          }
        })
      }
    })

    // Check if day is suspension
    this.state.suspensionsByDay?.forEach((suspensionEntry: suspensionEntry) => {
      if (suspensionEntry.deleted) return;
      
      if (trimDate(new Date(suspensionEntry.date)) === trimDate(dayOfWeek)) {
        dayType = 'unpaid';
        this.noOfTickets -= 1;
      }
    })

    return dayType;
  }

  updateTimeEntries = async (dayNumber: number, timeFrom: string | null, timeTo: string | null, updatedInput: string) => {  
    const clockingDays: ClockingDayEntry[] = [...this.state.clockingDays];
    const currentTimeEntry = this.state.clockingDays.find((clockingDay) => clockingDay.dayNo === dayNumber) as ClockingDayEntry;

    if (!currentTimeEntry) return;

    currentTimeEntry.timeTo = timeTo;
    currentTimeEntry.timeFrom = timeFrom;
    currentTimeEntry.totalHours = this.computeTotalHours(currentTimeEntry);

    // If timeEntry exists update else create
    const requestData: ClockingDayDTO = {
      userId: this.state.form.userId,
      contractId: this.state.form.contractId as number,
      date: currentTimeEntry.date,
      timeFrom: currentTimeEntry.timeFrom !== undefined ? currentTimeEntry.timeFrom : null,
      timeTo: currentTimeEntry.timeTo !== undefined ? currentTimeEntry.timeTo : null
    }

    const isEmptyEntry = currentTimeEntry?.id === POST_METOD_FLAG;

    this.updateRequestStatus(dayNumber, clockingDays, updatedInput, 'loading');

    if (isEmptyEntry) {
      // If the clockingday does not exist CREATE
      await createClockingDay(requestData)
        .then((response) => {
          // Then set the new id and timeEntry for the current day
          const id = response.id as number;
          this.processClockingData(clockingDays, currentTimeEntry, id, dayNumber, updatedInput);
        })
        .catch(err => {
          console.error(err);
          this.updateRequestStatus(dayNumber, clockingDays, updatedInput, 'error');
        });

    } else {
      // If the clocking day exists UPDATE
      await updateClockingDay(requestData, currentTimeEntry.id as number)
        .then(() => {
          const id = currentTimeEntry.id as number;

          this.processClockingData(clockingDays, currentTimeEntry, id, dayNumber, updatedInput);
        })
        .catch(err => {
          console.error(err);
          this.updateRequestStatus(dayNumber, clockingDays, updatedInput, 'error');
        });
    }

    this.getTotalHours();
  }

  processClockingData = (
    clockingDays: ClockingDayEntry[],
    currentTimeEntry: ClockingDayEntry,
    id: number,
    dayNumber: number,
    updatedInput: string
  ) => {
    const LOADING_CHECKMARK_DELAY = 1000;
    currentTimeEntry.id = id;
    currentTimeEntry.inputStatus[updatedInput as keyof EntryRequestStatus] = "success";

    this.setState(prevState => {
      return {
        ...prevState,
        clockingDays: clockingDays
      }
    }, () => {
      setTimeout(() => {
        this.updateRequestStatus(dayNumber, clockingDays, updatedInput, 'idle');
      }, LOADING_CHECKMARK_DELAY)
    });
  }

  updateRequestStatus = (dayNumber: number, clockingDays: ClockingDayEntry[], updatedInput: string, requestStatus: TRequestStatus) => {
    const newClockingDays = clockingDays.map((clockingDay: ClockingDayEntry) => {
      if (clockingDay.dayNo !== dayNumber) {
        return clockingDay;
      } else {
        return {
          ...clockingDay,
          inputStatus: {
            ...clockingDay.inputStatus,
            [updatedInput]: requestStatus
          }
        };
      }
    })

    this.setState(prevState => {
      return {
        ...prevState,
        clockingDays: newClockingDays as ClockingDayEntry[]
      }
    });
  }

  computeTotalHours = (timeEntry: ClockingDayDTO | ClockingDayEntry) => {
    let total = 0;

    if (timeEntry === undefined || timeEntry.timeFrom === null || timeEntry.timeTo === null || timeEntry.timeFrom === undefined || timeEntry.timeTo === undefined) return total;

    const timeTo = parseInt(timeEntry.timeTo as string) + parseInt((timeEntry.timeTo as string).substring(3, 5)) / 60;
    const timeFrom = parseInt(timeEntry.timeFrom as string) + parseInt((timeEntry.timeFrom as string).substring(3, 5)) / 60;

    if (timeEntry?.timeTo !== null && timeEntry?.timeFrom !== null)  {
      total = Math.round((timeTo - timeFrom) * 100) / 100;
    }

    return total;
  }

  isUnemployed = (date: Date) => {
    const currentContract = this.state.contracts.find((contract: ContractPair) => contract.id === this.state.form.contractId);
    const isUnemployed = moment(date).isBefore(currentContract?.startDate) ||
                         moment(trimDate(date)).isAfter(currentContract?.endDate);
    
    if (isUnemployed) {
      return true;
    }
  }

  isToday = (dayIndex: number): boolean => {
    return new Date().getDate() === dayIndex && new Date().getMonth() === this.state.form.month && new Date().getFullYear() === this.state.form.selectedYear;
  }

  isWeekend = (day: number): boolean => {
    let currentDay = new Date(this.state.form.selectedYear, this.state.form.month, day);
    return currentDay.getDay() === 6 || currentDay.getDay() === 0;
  }

  getTotalHours = () => {
    let totalHours = 0;
    const counters = this.state.counters;

    this.state.clockingDays.forEach(clockingDay => totalHours = totalHours + clockingDay.totalHours);
    this.setState({
      counters: {
        ...counters,
        totalHours
      }
    })
  }

  resetCounters = () => {
    this.setState(prevState => {
      return {
        counters: {
          ...prevState.counters,
          totalHours: 0,
          paid: 0,
          extra: 0, 
          sick: 0,
          holiday: 0,
          absence: 0
        }
    }});
  }

  setProgressStep = (step: number) => {
    this.setState(prevState => {
      return {
        progress: {
          ...prevState.progress,
          currentStep: step
        }
      }
    });
  }
  
  updateForm<K extends keyof Form>(field: K, value: Form[K]) {
    const form = this.state.form;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    });
  }
  
  setUser = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, userId: number | string | undefined | UserDTO) => this.updateForm('userId', Number(userId));
  setYear = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, yearNo: number | string | undefined) => this.updateForm('selectedYear', Number(yearNo));
  setMonth = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, month: number | string | undefined | Month) => this.updateForm('month', Number(month));
  setContract = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, contractId: number | string | undefined | ContractPair) => this.updateForm('contractId', Number(contractId));
  
  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const form = this.state.form;
    const prevForm = prevState.form;
    
    if (form.userId !== prevForm.userId) {
      // If a different user is selected then reinitialize 
      this.setProgressStep(0);
      this.initialize();
      this.setUserUrl();

    } else if (form.contractId !== prevForm.contractId || form.month !== prevForm.month || form.selectedYear !== prevForm.selectedYear) {
      // 3.Process the suspensions after setting state
      // If a different contract is selected or if the month or year is changed call this function which creates the suspensionsByDay array then creates the timeEntries
      //  you only need to recreate the timeEntries 
      this.setProgressStep(3);
      !!this.state.contracts.length && this.getSuspensionsByDay();
    }
  }

  setUserUrl = () => {
    this.props.history.replace(`/${this.props.location.pathname.split('/')[1]}/${this.state.form.userId}`);
  }

  render() {
    const foodStampsTile = {title: 'Stampls', type: 'totalTickets', description: ' - The number of food stamps.', action: 'accrued'};
    const currentContract = this.state.contracts.find(contract => contract.id === this.state.form.contractId);
    const clockingTiles = currentContract?.hasTickets ?  [foodStampsTile, ...Object.values(ClockingTiles)] : Object.values(ClockingTiles);
    const today = new Date();
    const currentMonth = this.state.form.selectedYear === today.getFullYear() && this.state.form.month === today.getMonth();
    return (
      <div>
        <ToolbarControls>
          {(hasElevatedAccess(this.props.loggedUser) && this.context.users) &&
            <SelectControl
              idName="userId"
              value={this.state.form.userId}
              options={this.context.users as UserDTO[]}
              disabled={this.state.status === 'loading'}
              onChange={this.setUser}
              getValue={(op: UserDTO) => op.id}
              getLabel={(op: UserDTO) => op?.name}
            />
          }
          <SelectControl
            idName="year"
            value={this.state.form.selectedYear}
            options={this.state.years}
            onChange={this.setYear}
          />
          <SelectControl
            idName="month"
            value={this.state.form.month}
            options={Months}
            disabled={this.state.status === 'loading'}
            onChange={this.setMonth}
            getValue={op => op.number}
            getLabel={op => op?.name}
          />
          {this.state.contracts.length > 0 && 
            <SelectControl
              idName="contractId"
              value={this.state.form.contractId ?? undefined}
              options={this.state.contracts}
              disabled={this.state.status === 'loading'}
              onChange={this.setContract}
              getValue={(op) => op.id}
              getLabel={(op) => op?.name}
            />
          }
        </ToolbarControls>
        <BreadcrumbControls
          pageTitle="Clocking"
          status={this.state.status}
        />
        <div className="flex-row tightest-top fill">
          <div className="column">
            <ProgressBar
              currentStep={this.state.progress.currentStep}
              totalSteps={this.state.progress.totalSteps}
            />
            <div className="flex-row fill">
              <div className="column">
                <div className="clocking-component">
                  <div className={`
                      card
                    `}
                  >
                    {!!this.state.contracts.length ? (
                      <>
                      <div className="flex-row wrap squeeze">
                        {this.state.clockingDays.map((clockingDay: ClockingDayEntry) => {
                          const editable = hasFullAccess(this.props.loggedUser)||
                                          (!this.isWeekend(clockingDay.dayNo) &&
                                          (clockingDay.dayType === '' || clockingDay.dayType === 'loyalty') &&
                                          currentMonth);

                          return (
                            <ClockingDay
                              key={clockingDay.dayNo}
                              clockingDay={clockingDay}
                              isDisabled={!editable}
                              isWeekend={this.isWeekend(clockingDay.dayNo)}
                              isToday={this.isToday(clockingDay.dayNo)}
                              holidayName={getHolidayName(this.context.holidays!, clockingDay.date)}
                              onBlur={this.updateTimeEntries}
                            />
                          )
                        })}
                      </div> 
                      <table className='clocking-tiles'>
                        <tfoot>
                            <tr className='visible-desktop'>
                              <th colSpan={33}>
                                <div className="flex-row booking-legend-component">
                                    {clockingTiles.map((tile, index) => {
                                      return (
                                        <div className="column stretch" key={index}>
                                          <LegendTile 
                                            title={tile.title}
                                            type={tile.type}
                                            description={tile.description}
                                            action={tile.action}
                                            counter={this.state.counters[tile.type as keyof Counters]}
                                          />
                                        </div>  
                                      )
                                    })}
                                    {!currentContract?.hasTickets ? 
                                      <React.Fragment>
                                        <div className="column stretch"></div>
                                        <div className="column stretch"></div>
                                      </React.Fragment>
                                      :
                                      <div className="column stretch"></div>
                                    }
                                </div>
                              </th>
                            </tr>
                        </tfoot>
                      </table>
                    </>
                    ) : (
                      <div>The user doesn't have a contract yet.</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>
    );
  }
}
export default withTransitionEvent(Clocking);
Clocking.contextType = AppContext;