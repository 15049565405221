import React, { Component } from 'react';
import moment from 'moment';
import { IBookingTile } from '../../../common/interfaces/BookingTile';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import ButtonControl from '../../controls/ButtonControl/ButtonControl';
import GhostButtonControl from '../../controls/GhostButtonControl/GhostButtonControl';
import RequestStatus from '../../utils/RequestStatus/RequestStatus';
import Thumbnail from '../../utils/Thumbnail/Thumbnail';

interface Props {
  tileData: IBookingTile,
  requestRemote: (inputDate: Date, type: string) => void,
  cancelRemote: (inputDate: Date, remoteId: number) => void,
  changeUser: (userId: number) => void,
  hasAccess: boolean,
  hasFullAccess: boolean,
  selectedUserId: number,
}

interface State {
  isOpen: boolean,
  isOverlapListOpen: boolean,
  status: TRequestStatus
}

class RemoteTile extends Component<Props, State> {
  wrapperRef: React.RefObject<HTMLDivElement>;
  currentDate = new Date();

  constructor(props: Props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      isOpen: false,
      isOverlapListOpen: false,
      status: this.props.tileData.status
    }
  }
  
  openOptionList = () => {
    this.closeOverlapList();
    this.setState({
      isOpen: true
    });
  }

  openOverlapList = () => {
    this.closeOptionList();
    this.setState({
      isOverlapListOpen: true
    });
  }

  toggleOptionList = () => {
    this.closeOverlapList();
    this.setState((prevState: State) => {
      return {
        isOpen: !prevState.isOpen
      }
    });
  }

  toggleOverlapList = () => {
    this.closeOptionList();
    this.setState((prevState: State) => {
      return {
        isOverlapListOpen: !prevState.isOverlapListOpen
      }
    });
  }

  closeOptionList = () => {
    this.setState({
      isOpen: false
    });
  }

  closeOverlapList = () => {
    this.setState({
      isOverlapListOpen: false
    });
  }

  setWrapperRef = (node: React.RefObject<HTMLDivElement>) => {
    this.wrapperRef = node;
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target as HTMLElement)) {
      this.closeOptionList();
      this.closeOverlapList();
    }
  }

  handleFocusOuside = (event: FocusEvent) => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target as HTMLElement)) {
      this.closeOptionList();
      this.closeOverlapList();
    }
  }

  requestRemote = (type: string) => {
    this.closeOptionList();
    this.props.requestRemote(this.props.tileData.date, type);
  }

  changeUser = (userId: number) => {
    this.closeOverlapList();
    this.props.changeUser(userId)
  }
  
  cancelRemote = () => {
    this.closeOptionList();
    if(this.props.tileData?.vacation && this.props.tileData?.vacation.id) {
      this.props.cancelRemote(this.props.tileData.date, this.props.tileData.vacation.id);
    }
  }

  isUserSuspended = () => {
    return this.props.tileData.suspension?.user.id === this.props.selectedUserId;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('focus', this.handleFocusOuside);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if(this.props.tileData.status !== prevProps.tileData.status) {
      // show the remote clock icon after RequestStatus component has shown the status icon
      this.setState({
        status: this.props.tileData.status
      }, () => {
        setTimeout(() => {
          this.setState({
            status: 'idle'
          })
        }, 2000)
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('focus', this.handleFocusOuside);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.tileData &&
          <td className={[
            moment(this.props.tileData.date).isSame(this.currentDate, 'day') ? 'today' : '',
            this.props.tileData.vacation ? this.props.tileData.vacation.type : '',
            this.props.tileData.isWeekend ? 'weekend' : '',
            this.props.tileData.isHoliday ? 'holiday' : '',
            this.props.tileData.isUnemployed ? 'unemployed' : '',
            this.props.tileData.suspension ? 'unpaid' : '',
          ].join(' ')}>
            <div ref={this.wrapperRef}>
              <div className={[
                "content",
                this.state.isOpen || this.state.isOverlapListOpen ? 'active' : ''
              ].join(" ")}>
                {this.props.tileData.overlaps.length > 0 &&
                  <div className={[
                      'overlap',
                      this.props.tileData.overlaps.length > 2 && this.props.tileData.overlaps.length < 4 ? 'highlight-medium' : 
                      this.props.tileData.overlaps.length == 4 ? 'highlight-high' : 
                      this.props.tileData.overlaps.length >= 5 ? 'highlight-higher' : ''
                    ].join(' ')}
                  >
                    <button onClick={this.toggleOverlapList}>{this.props.tileData.overlaps.length}</button>
                    {this.state.isOverlapListOpen &&
                      <ul className="context-menu open overlap-list">
                        {this.props.tileData.overlaps.map((overlap) => (
                          <li key={overlap.id}>
                            <ButtonControl 
                              class="context-menu-button" 
                              onClick={() => this.changeUser(overlap.id)} 
                              disabled={!this.props.hasAccess}>
                              <Thumbnail
                                avatarData={overlap.avatar}
                                classes="smaller"  
                              /> <span>{overlap.name}</span>
                            </ButtonControl>
                          </li>
                        ))}
                      </ul>
                    }
                  </div>
                }
                <GhostButtonControl
                  class={[
                    'ghost-button calendar-button',
                    this.state.isOpen ? 'active' : '',
                    this.state.status !== 'idle' ? 'progress' : ''
                  ].join(' ')}
                  disabled={(!this.props.hasFullAccess && !this.props.tileData.isHoliday ) || (!this.props.tileData.isHoliday && (this.props.tileData.isWeekend || this.props.tileData.isUnemployed))}
                  onClick={this.toggleOptionList}
                >
                {
                  this.state.status !== 'idle' ? 
                    <RequestStatus status={this.props.tileData.status} />
                  :
                    <span className="fas fa-clock"></span>
                }
                </GhostButtonControl>
                {(this.state.isOpen && this.props.hasFullAccess) &&
                  <ul className="context-menu open option-list">
                    {!this.props.tileData.vacation && !this.props.tileData.isHoliday && !this.isUserSuspended() &&
                      <React.Fragment>
                        <li>
                          <ButtonControl
                            class="context-menu-button remotePlanned" onClick={() => this.requestRemote('remotePlanned')}>
                            <span className="icon fas fa-circle"></span> <span>Remote Friday</span>
                          </ButtonControl>
                        </li>
                        <li>
                          <ButtonControl
                            class="context-menu-button remote" onClick={() => this.requestRemote('remote')}>
                            <span className="icon fas fa-circle"></span> <span>Emergency Remote</span>
                          </ButtonControl>
                        </li>
                      </React.Fragment>
                    }
                    {this.props.tileData.vacation && this.props.tileData.vacation.type !== "unpaid" &&
                      <React.Fragment>
                        <li>
                          <ButtonControl class="context-menu-button absence" onClick={() => this.cancelRemote()}>
                            <span className="icon fas fa-times"></span> <span>Remove entry</span>
                          </ButtonControl>
                        </li>
                      </React.Fragment>
                    }
                    {this.props.tileData.suspension &&
                      <React.Fragment>
                        <li>
                            <p className="info">In order to remove this entry you must first remove the suspension.</p>
                        </li>
                      </React.Fragment>
                    }
                  </ul>
                }
                {(this.state.isOpen && this.props.tileData.isHoliday && !this.isUserSuspended()) &&
                  <ul className="context-menu open option-list">
                    <li>
                        <p className="info">{this.props.tileData.holiday?.name}</p>
                    </li>
                  </ul>
                }
              </div>
            </div>
          </td>
        }
      </React.Fragment>
    )
  }
}

export default RemoteTile;