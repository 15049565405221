import React from 'react';
import ClientDTO from '../../../../common/api/dtos/Client';

interface Props {
  client: ClientDTO,
  labels: Record<string, string>,
}

export function ClientSection({ labels, client }: Props) {
  return <React.Fragment>
    <span>{labels.customer}</span>
    <h2>{client.legalName}</h2>
    <strong contentEditable suppressContentEditableWarning={true}></strong>
    <br />
    <span>{labels.tradeRegistry} {client.tradeNo}</span>
    <br />
    <span>{labels.taxId} {client.taxId}</span>
    <br />
    <span>{labels.shareCapital} {client.shareCapital}</span>
    <br />
    <br />
    <span>{labels.street} {client.streetAddress}</span>
    <br />
    <span>{client.city}, {client.state} {client.zipCode}</span>
    <br />
    <span>{client.country}</span>
    <br />
    <br />
    <span>{labels.bankAccount}<br />{client.bankAccount} </span>
    <br />
    <br />
    <span>{labels.email} <span contentEditable suppressContentEditableWarning={true}>{client.contactEmail}</span></span>
  </React.Fragment>
}