import moment from 'moment';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { listRemainingPaidDays } from '../../../common/api/endpoints/vacations';
import Fetch from '../../../common/helpers/Fetch';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import SelectControl from '../../controls/SelectControl/SelectControl';


export interface Props {
  loggedUser: LoggedUser,
}

interface State {
  showClockCentral: boolean,
  showClockEastern: boolean,
  showClockPacific: boolean,
  showClockWET: boolean,
  now: Date,
  status: TRequestStatus,
  remainingPaidDays: string | number,
  
}

class FlashCards extends Component<Props, State> {
  timeUpdate = setInterval(() => {});

  constructor(props: Props) {
    super(props); 
    this.state = {
      showClockCentral: localStorage.getItem(this.props.loggedUser?.id + 'showClockCentral') === 'false' ? false : true,
      showClockEastern: localStorage.getItem(this.props.loggedUser?.id + 'showClockEastern') === 'false' ? false : true,
      showClockPacific: localStorage.getItem(this.props.loggedUser?.id + 'showClockPacific') === 'false' ? false : true,
      showClockWET: localStorage.getItem(this.props.loggedUser?.id + 'showClockWET') === 'false' ? false : true,
      now: new Date(),
      status: 'loading',
      remainingPaidDays: '?',
    }
  }

  fetchRemainingPaidDays = async () => {
    this.setState({
      status: 'loading'
    });
    
    const remainingPaidDays = await listRemainingPaidDays(this.state.now.getFullYear(), this.props.loggedUser.id, null);

    this.setState({
        status: 'success',
        remainingPaidDays: remainingPaidDays.toString() ? remainingPaidDays.toString() : 0,
    });
  }

  componentDidMount() {
    // this.fetchRemainingPaidDays();
    this.timeUpdate = setInterval(() => {
      this.setState({
        now: new Date()
      })
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeUpdate);
  }

  render() {
    return(
      <div className="flex-row tightest flash-card-widget-component hidden-mobile">
        {this.state.showClockEastern &&
          <div className="column stretch">
            <TimeZoneFlashCard now={this.state.now} timezone={'America/New_York'} name={'ET (Eastern Time)'} />
          </div>
        }
        {this.state.showClockCentral &&
          <div className="column stretch">
            <TimeZoneFlashCard now={this.state.now} timezone={'America/Chicago'} name={'CT (Central Time)'} />
          </div>
        }
        {this.state.showClockPacific &&
          <div className="column stretch">
            <TimeZoneFlashCard now={this.state.now} timezone={'America/Los_Angeles'} name={'PT (Pacific Time)'} />
          </div>
        }
        {/* {this.state.showClockWET &&
          <div className="column stretch small">
            <TimeZoneFlashCard now={this.state.now} timezone={'Europe/London'} name={'WET (Western European Time)'} />
          </div>
        } */}
        {/* <div className="column stretch small">
          <div className={`
            flash-card card
            ${this.state.status === 'loading' ? 'loader-border': ''}
          `}>
            <div className="content">
              {this.state.status !== 'loading' && 
                <React.Fragment>
                  <span className="primary-title">
                    <Link className="link-button" to="/bookings">{this.state.remainingPaidDays}</Link>
                  </span>
                  <small className="faint-text">Unbooked days</small>
                </React.Fragment>
              }
            </div>
          </div>
        </div> */}
      </div>
    )
  }
}

export default FlashCards;

interface TimezoneProps {
  timezone: string,
  name: string,
  now: Date,
}

export function TimeZoneFlashCard(props: TimezoneProps) {
  return(
    <div className="flash-card card">
      <div className="content">
        <span className="primary-title">{moment(props.now).tz(props.timezone).format('HH:mm')}</span>
        <small className="faint-text">{props.name}</small>
      </div>
    </div>
  );
}
