import moment from "moment";
import SyspropDTO from "../../../common/api/dtos/Sysprop";
import { Currency } from "./types";

export interface PriceInfo {
  amount: number,
  unitPriceExclVat?: number,
  vatAmount?: number,
}

export function calculateInvoicePriceInfo(quantity: number, unitPrice: number, vatRate?: number): PriceInfo {
  if (vatRate === undefined) {
    return {
      amount: quantity * unitPrice
    };
  }

  const unitPriceExclVat = unitPrice / ((100 + vatRate) / 100);
  const amount = quantity * unitPriceExclVat;

  return {
    amount,
    unitPriceExclVat,
    vatAmount: (quantity * unitPrice) - amount
  }
}

export function extractYears(sysProps: SyspropDTO[]): number[] {
  const yearsProp = sysProps.find(prop => prop.name === 'activeYears');
  if (!yearsProp) {
    throw new Error("Failed to locate 'currencies' sysprop");
  }

  return yearsProp.value.split(',').map(Number);
}

export function extractCurrencies(sysProps: SyspropDTO[]): Currency[] {
  const currenciesProp = sysProps.find(prop => prop.name === 'currencies');
  if (!currenciesProp) {
    throw new Error("Failed to locate 'currencies' sysprop");
  }

  return currenciesProp.value
    .split(',')
    .map(value => {
      let temp = value.split('|');
      return {
        symbol: temp[0] ?? '',
        name: temp[1] ?? '',
        invoiceSeries: temp[2] ?? ''
      }
    });
}

/**
 * Calculate the start and end of a month.
 */
 export function computeMonthRange(year: number, month: number): { since: Date, until: Date } {
  return {
    since: month === 12 ? moment.utc([year + 1, 0]).startOf('month').toDate() : moment.utc([year, month]).startOf('month').toDate(),
    until: month === 12 ? moment.utc([year + 1, 0]).endOf('month').toDate() : moment.utc([year, month]).endOf('month').toDate(),
  }
}