import moment from "moment";
import React, { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import ProjectDTO from "../../../common/api/dtos/Project";
import { filterProjects } from "../../../common/api/endpoints/projects";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import GhostButtonControl from "../../controls/GhostButtonControl/GhostButtonControl";
import ToolbarControls from "../../generics/Header/ToolbarControls";
import DateTCell from "../../utils/DateTCell/DateTCell";
import TextControl from "../../controls/TextControl/TextControl";
import SortButton from "../../utils/SortButton/SortButton";
import Thumbnail from "../../utils/Thumbnail/Thumbnail";

interface Props {
  entityId: number,
  entity: 'client',
  hasFullAccess?: boolean
}

interface State {
  status: TRequestStatus,
  formStatus: TRequestStatus,
  projects?: ProjectDTO[],
  showDisabled: boolean,
  tableSort: TableSort,
	propsForm: PropForm,
  filterValue: string,
}

interface PropForm {
  employment: string,
  fullName: string,
  position: string,
  hourNorm: number,
  phone: string,
  userId: number,
  projects: ProjectDTO[],
}

class ProjectListForm extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'projectGroup.name'
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      formStatus: 'idle',
      propsForm: {
        employment: '',
        fullName: '',
        position: '',
        hourNorm: 8,
        phone: '',
        userId: this.props.entityId,
        projects: [],
      },
      tableSort: new TableSort('name', 'asc'),
      showDisabled: localStorage.getItem('showDisabledProjects') === 'false' || localStorage.getItem('showDisabledProjects') === null ? false : true,
      filterValue: '',
    }
  }

  fetchEntityProjects = async () => {
    switch(this.props.entity) {
      case 'client': 
        return await filterProjects([this.props.entityId]);
    }
  }

  fetchProjects = async () => {
    this.setState({
      status: 'loading'
    });
    
    const projects = await this.fetchEntityProjects();

    this.setState({
        status: 'success',
        projects: projects,
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({ showDisabled: false }) : this.setState({ showDisabled: true });
  }

  componentDidMount() {
    this.fetchProjects();
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  setAssigned = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentProjectId: number = Number(ev.target.value);
    this.setState((prevState: State) => {
      let assignedProjects = prevState.propsForm.projects.slice(0);
      let alreadyAssigned = assignedProjects.map((project: ProjectDTO) => { return project.id; }).indexOf(currentProjectId);

      if(alreadyAssigned === -1) {
        let selectedProject = this.state.projects?.filter((project: ProjectDTO) => project.id === currentProjectId)[0];
        if(selectedProject) {
          assignedProjects.push(selectedProject);
        }
      } else {
        assignedProjects.splice(alreadyAssigned, 1);
      }
      
      return {
        propsForm: {
          ...prevState.propsForm,
          projects: assignedProjects
        }
      }
    });
    
  }

  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th className="text-left">
          <SortButton 
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-left">
          <SortButton 
            column='projectGroup.name' 
            text='Project Group'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='billable' 
            text='Billable'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        {this.state.showDisabled &&
          <th className="text-center">
            <SortButton
              column='enabled'
              text='Enabled'
              tableSort={this.state.tableSort}
              onClick={this.handleSortChange}
            ></SortButton>
          </th>
        }
        <th></th>
      </tr>
    )
  }

  render() {
    const filteredProjects: Array<ProjectDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.projects ? this.state.projects : []).sort(this.state.tableSort.sortByColumn).filter((project: ProjectDTO) => project.enabled === true || this.state.showDisabled);
    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
            {this.props.hasFullAccess &&
              <>
                <ToggleControl 
                  id="showDisabledProjects"
                  name="showDisabledProjects" 
                  changeMethod={this.toggleDisabled} 
                  isChecked={this.state.showDisabled}
                  labelText="Disabled"
                  />
                <div className="form-group">
                  <Link to={`/${this.props.entity}/deep/${this.props.entityId}/project/add`} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add project</span></Link>
                </div>
              </>
            }
            </ToolbarControls>
        </div>
        <div className={`card ${!this.state.projects ? 'loader-border' : ''}`}>
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 315px)'}}>
                  <table>
                    <thead>
                      {this.renderTHeadRow()}
                    </thead>
                    {this.state.projects &&
                      <React.Fragment>
                        <tbody>
                          {filteredProjects.map(project => (
                            <tr id={`project${project.id}`} key={`${project.id}`}>
                              <td></td>
                              <td>
                                <div className="flex-row fill">
                                  <div className="column flex-v-center">
                                    <Thumbnail
                                      thumbnailURL={project.avatar}
                                      classes="thumbnail"
                                    />
                                    <span>{project.name}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="flex-row fill">
                                  <div className="column flex-v-center">
                                    <span>{project.projectGroupId !== null ? project.projectGroup?.name : '-'}</span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                {project.billable && <span className="fas fa-check"></span>}
                              </td>
                              {this.state.showDisabled &&
                                <td className="text-center">
                                  {project.enabled && 
                                    <span className="fas fa-check"></span>
                                  }
                                </td>
                              }
                              <td className="text-right">
                                {this.props.hasFullAccess && 
                                  <GhostButtonControl
                                    to={`/${this.props.entity}/deep/${this.props.entityId}/project/edit/${project.id}`}
                                    class="ghost-button"
                                    icon="fas fa-angle-right"
                                  ></GhostButtonControl>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.projects?.length === 0 &&
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  {this.props.hasFullAccess ? 
                                    <p className="text-chunk">
                                      There are no projects defined for this {this.props.entity}. Start by <Link className="link-button" to={`/${this.props.entity}/${this.props.entityId}/project/add`}>adding</Link> the first one.
                                    </p>
                                  :
                                    <p className="text-chunk">
                                      There are no projects.
                                    </p>
                                  }
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  <p className="text-chunk">Showing {filteredProjects.length} out of {this.state.projects.filter((project: ProjectDTO) => project.enabled === true || this.state.showDisabled).length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectListForm;