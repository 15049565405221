import React, { ChangeEvent } from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import TextAreaControl from '../../../controls/TextAreaControl/TextAreaControl';

export interface Props {
  index: number,
  isToday: boolean,
  isHoliday: boolean,
  isWeekend: boolean,
  isSuspension: boolean
  personalDayType: string,
  status: unknown,
  comment: string,
  hours?: string,
  disabled: boolean,

  onChange: (index: number, hours: string, comment: string) => void,
  onBlur: (index: number) => void,
}

export class ProjectDay extends React.Component<Props> {
  private onBlur = () => {
    this.props.onBlur(this.props.index);
  }

  private onHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(this.props.index, event.target.value, this.props.comment);
  }

  private onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const hours = this.props.hours ? this.props.hours.toString() : '';
    this.props.onChange(this.props.index, hours, event.target.value);
  }

  render() {
    const { isToday, isHoliday, isWeekend, isSuspension, personalDayType, status, comment, hours, disabled } = this.props;

    return (
      <React.Fragment>
          <td className={
            `narrow-p-lr text-center
              ${isToday ? 'today': ''}
              ${isHoliday ? 'holiday': ''}
              ${isWeekend ? 'weekend' : ''}
              ${isSuspension ? 'unpaid' : ''}
              ${personalDayType}
            `
          }>
            <div className="content">
              <div className="inner-content">
                {status != 'idle' &&
                  <div className={`
                      status
                      ${status}
                    `}
                  >
                    <RequestStatus status={status as TRequestStatus} />
                  </div>
                }
                <React.Fragment>
                  {comment &&
                    <div className="comment-badge">
                      <div className="comment-body">
                        <p>{comment}</p>
                      </div>
                    </div>
                  }
                  <input
                    className="hour-box"
                    type="text"
                    value={hours || ''}
                    placeholder="Hours"
                    disabled={disabled}
                    onChange={this.onHoursChange}
                    onBlur={this.onBlur}
                    maxLength={4}
                  />
                  <TextAreaControl
                    name="comments"
                    commentBox="comment-box"
                    placeholder="Comments"
                    value={comment || ''}
                    disabled={disabled}
                    onChange={this.onCommentChange}
                    onBlur={this.onBlur}
                  >
                  </TextAreaControl>
                </React.Fragment>
              </div>
            </div>
          </td>
      </React.Fragment>
    )
  }
}