import React, { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import ProcedureDTO from "../../../../common/api/dtos/Procedure";
import { listProcedures, listAssignedUsers, listProceduresOverview } from "../../../../common/api/endpoints/procedures";
import { getFilteredItems } from "../../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../../common/helpers/Sorting";
import TableSort from "../../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../../common/types/RequestStatus";
import ButtonControl from "../../../controls/ButtonControl/ButtonControl";
import TextControl from "../../../controls/TextControl/TextControl";
import ToggleControl from "../../../controls/ToggleControl/ToggleControl";
import GhostButtonControl from "../../../controls/GhostButtonControl/GhostButtonControl";
import BreadcrumbControls from "../../../generics/Header/BreadcrumbControls";
import ToolbarControls from "../../../generics/Header/ToolbarControls";
import { incrementProgress, IProgress } from "../../../utils/ProgressBar/ProgressBar";
import SortButton from "../../../utils/SortButton/SortButton";
import Thumbnail from "../../../utils/Thumbnail/Thumbnail";
import { withTransitionEvent } from "../../../TransitionEvent";

interface State {
  procedures?: ProcedureDTO[],
  progress: IProgress,
  status: TRequestStatus,
  showDeleted: boolean,
  tableSort: TableSort,
  selectedUser: string,
  filterValue: string,
  isOpen: boolean,
  openUsersList: number | null,
}

class ProceduresList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'category',
    'tags',
    'users'
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 2,
      },
      status: 'loading',
      showDeleted: localStorage.getItem('showDeletedProcedures') === 'false' || localStorage.getItem('showDeletedProcedures') === null ? false : true,
      tableSort: new TableSort('name', 'asc'),
      selectedUser: '',
      filterValue: '',
      isOpen: false,
      openUsersList: null,
    }
  }

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  fetchProcedures = async () => {
    this.setState({
      status: 'loading'
    });
    
    const procedures = await listProcedures();
    
    this.setState(prevState => {
      return {
        progress: incrementProgress(prevState.progress),
      }
    }, () => {
      this.fetchProceduresOverview(procedures);
    });
  }

  fetchProceduresOverview = async (procedures: ProcedureDTO[]) => {
    this.setState({
      status: 'loading'
    });

    const proceduresOverview = await listProceduresOverview();
    const fullProcedures = procedures.map((procedure: ProcedureDTO) => {
      const procedureUsers = proceduresOverview.find((procedureOverview: ProcedureDTO) => procedureOverview.id === procedure.id)!.users;
      
      return {...procedure, users: procedureUsers}
    })
    
    this.setState(prevState => {
      return {
        status: 'success',
        procedures: fullProcedures,
        progress: incrementProgress(prevState.progress),
      }
    })
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
      };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchProcedures();

    document.addEventListener("click", this.handleClickOutside);
  }
  
  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th>
          <SortButton
            column="name"
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
            ></SortButton>
        </th>
        <th>
          <SortButton
            column="description"
            text="Description"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
            ></SortButton>
        </th>
        <th>
          <SortButton
            column="category"
            text="Category"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
            ></SortButton>
        </th>
        <th>
          <SortButton
            column="tags"
            text="Tags"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
            ></SortButton>
        </th>
        <th>
          <SortButton
            column="users"
            text="Assigned Users"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
            ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(procedure: ProcedureDTO) {
    return (
      <tr id={`procedure${procedure.id}`} key={`${procedure.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <span>{procedure.name}</span>
            </div>
          </div>
        </td>
        <td>
          <p className="text-chunk">{procedure.description}</p>
        </td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <span>{procedure.category}</span>
            </div>
          </div>
        </td>
        <td>
          <p className="text-chunk">{procedure.tags}</p>
        </td>
        <td id={`userList-${procedure.id}`} className="user-list">
          {procedure.users.length > 0 ?
          <button 
            className="link-button"
            id={`listTrigger-${procedure.id}`}
            onClick={() => {
              if (procedure.users.length === 0) return;
                this.openUsersList(procedure.id)}
            }
            >
              {`${procedure.users.length} User${procedure.users.length > 1 ? 's' : ''}`}
          </button>
            : <span>No users assigned</span>
          }
        </td>
        <td className="text-right">
          <GhostButtonControl
            to={`/procedure/edit/${procedure.id}`}
            class="ghost-button"
            icon="fas fa-angle-right"
          />
        </td>
      </tr>
    );
  }

  renderUserList () {
    const procedure = this.state.procedures?.find((procedure: ProcedureDTO) => procedure.id === this.state.openUsersList);
    const isListOpen = this.state.openUsersList === procedure?.id;
    const listPosition = this.setListPosition();

    return (
      isListOpen 
      ? 
        <ul 
          className="context-menu open overlap-list out-of-table"
          style={{
            left: listPosition.x + listPosition.padding, 
            top: listPosition.opensAbove === false ? listPosition.y + listPosition.height : 'unset', 
            bottom: listPosition.opensAbove === true ? window.innerHeight - listPosition.y + listPosition.height : 'unset'
          }}
        >
          {procedure?.users.map((user) => {
            return  (
              <li key={user.id}>
                <Link to={`/user/edit/${user.id}/user-procedures`} className="link-button">
                    <Thumbnail avatarData={user.avatar}/>
                    <span>{user.name}</span>
                </Link>
            </li>
            )
          })}
        </ul>
      :
        null
    )
  }

  setListPosition () {
    let userList = document.querySelector(`#userList-${this.state.openUsersList}`)
    let userListRect = userList!.getBoundingClientRect();
    let userListPadding = Number(window.getComputedStyle(userList as HTMLElement).getPropertyValue('padding').replace('px', ''));
    let windownInnerHeight = window.innerHeight;
    let dropdownMinHeight = 350;
    let rowHeight = 32;
    let opensAbove = windownInnerHeight - userListRect.top < dropdownMinHeight;

    if (opensAbove) {
      return {
        x: userListRect.left,
        y: userListRect.bottom,
        opensAbove: true,
        padding: userListPadding,
        height: userListRect.height
      }
    } else {
      return {
        x: userListRect.left,
        y: userListRect.top - rowHeight,
        opensAbove: false,
        padding: userListPadding,
        height: userListRect.height + rowHeight 
      }
    }
  }

  openUsersList = (procedureId: number) => {
    // First close any list that is open
    this.closeUsersList();
    this.setState({
      // Check if you are clicking on the trigger for the same list that is open
      isOpen: this.state.openUsersList !== procedureId ? true : !this.state.isOpen,
      //Marker to have only one list open at the same time
      openUsersList: procedureId
    })
  }

  closeUsersList = () => {
    this.setState({
      isOpen: false,
      openUsersList: null
    })
  }

  handleClickOutside = (ev: MouseEvent) => {
    let userList = document.querySelector(`#userList-${this.state.openUsersList}`);
    let listTrigger = document.querySelector(`#listTrigger-${this.state.openUsersList}`);

    if (this.state.openUsersList === null) {
      return;
    } else if (ev.target !== userList && ev.target !== listTrigger) {
      this.closeUsersList();
    }
  }

  render() {
    const filteredProcedures: Array<ProcedureDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.procedures ? this.state.procedures : []).sort(this.state.tableSort.sortByColumn).filter((procedure: ProcedureDTO) => procedure.deleted === this.state.showDeleted);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Procedures"
          status={this.state.status}
        />
        {this.state.procedures &&
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="FilterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showDeletedProcedures"
                name="showDeletedProcedures"
                changeMethod={this.toggleDeleted}
                isChecked={this.state.showDeleted}
                labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
              />
              <div className="form-group">
                <Link to={`/procedure/add`}
                className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add procedure</span></Link>
              </div>
            </ToolbarControls>

            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                      card
                      ${!this.state.procedures ? 'loader-border' : ''}
                      ${this.state.showDeleted ? "flashing-border" : ""}
                  `}>
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.procedures &&
                        <React.Fragment>
                          <tbody>
                            {filteredProcedures.map(procedure => (
                              this.renderTBodyRow(procedure)
                              ))}
                              </tbody>
                              <tfoot>
                                {this.state.procedures?.length === 0 &&
                                  <tr>
                                    <th colSpan={8}>
                                      <div className="card">
                                        <p className="text-chunk">There are no procedures defined. Start by <Link className="link-button" to="/procedure/add">adding</Link> the first one.</p>
                                      </div>
                                    </th>
                                  </tr>
                                }
                                {this.state.filterValue &&
                                  <tr>
                                    <th colSpan={8}>
                                      <div className="card">
                                        <p className="text-chunk">Showing {filteredProcedures.length} out of {this.state.procedures.filter((procedure: ProcedureDTO)=> procedure.deleted === this.state.showDeleted).length} results.</p>
                                      </div>
                                    </th>
                                  </tr>
                                }
                              </tfoot>
                            </React.Fragment>
                          }
                    </table>
                  </div>            
                </div>
              </div>
            </div>
            {this.state.isOpen && this.renderUserList()}
          </div>
        }
      </>
    )
  };
}
export default withTransitionEvent(ProceduresList); 