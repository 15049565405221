import React from "react";

export const TransitionContext = React.createContext({ isExiting: false });

export function withTransitionEvent(Component: any) {
  return class TransitionEventComponent extends React.Component<any, any> {
    private testRef: React.RefObject<HTMLDivElement>;
  
    constructor(props: {}) {
      super(props);
      this.testRef = React.createRef();
      this.state = {
        isExiting: false,
      };
    }
  
    componentDidMount() {
      // Assign observer to watch for class changes on target element
      const targetNode = this.testRef.current;
      const observer = new MutationObserver((mutationList, observer) => {
        mutationList.forEach((mutation) => {
          switch(mutation.type) {
            case 'attributes':
              const classes = Array.from((mutation.target as any).classList);
              this.setState({
                isExiting: classes.includes('vertical-exit-active') || classes.includes('horizontal-reverse-exit-active') || classes.includes('horizontal-exit-active') || classes.includes('instant-exit-active'),
              })
              break;
          }
        });
      });
      observer.observe(targetNode!, { attributes: true });
    }
  
    render() {
      return <TransitionContext.Provider value={{ isExiting: this.state.isExiting }} >
        <div ref={this.testRef} className="will-transition">
          <div 
            className="v-scroll"
            style={{
              maxHeight: "calc(100vh - 81px)"
            }}
          >
            <div className="scroll-content">
              <Component {...this.props} />
            </div>
          </div>
        </div>
      </TransitionContext.Provider>;
    }
  }
}