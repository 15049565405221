export const LegendTiles = {
    paid: {
        title: 'Paid',
        type: 'paid',
        description: ' - Requested by filling an application.',
        action: 'left'
    },
    extra: {
        title: 'Extra',
        type: 'extra',
        description: ' - In special cases (documents needed).',
        action: 'booked'
    },
    sick: {
        title: 'Sick',
        type: 'sick',
        description: ' - Awarded by doctors (written proof).',
        action: 'booked'
    },
    untracked: {
        title: 'Untracked',
        type: 'untracked',
        description: ' - Compensatory day (internal).',
        action: 'booked'
    },
    loyalty: {
        title: 'Loyalty',
        type: 'loyalty',
        description: ' - Extra days awarded every year.',
        action: 'booked'
    },
    unpaid: {
        title: 'Unpaid',
        type: 'unpaid',
        description: ' - Suspension by mutual agreement.',
        action: 'marked'
    },
    holiday :{
        title: 'Holiday',
        type: 'holiday',
        description: ' - National day off.',
        action: 'marked'
    },
    absence: {
        title: 'Absence',
        type: 'absence',
        description: ' - Missed a workday.',
        action: 'marked'
    },
}

export const ClockingTiles = {
    hoursTile: {
        title: 'Normal',
        type: 'totalHours',
        description: ' - Total hours worked this month.',
        action: 'hours'
    },
    paid: {
        title: 'Paid',
        type: 'paid',
        description: ' - amount of paid leave in hours.',
        action: 'hours'
    },
    extra: {
        title: 'Extra',
        type: 'extra',
        description: ' - amount of extra paid leave in hours.',
        action: 'hours'
    },
    sick: {
        title: 'Sick',
        type: 'sick',
        description: ' - amount of medical leave in hours.',
        action: 'hours'
    },
    holiday :{
        title: 'Holidays',
        type: 'holiday',
        description: ' - amount of holiday time.',
        action: 'hours'
    },
    absence: {
        title: 'Absence',
        type: 'absence',
        description: ' - amount of absent time.',
        action: 'hours'
    }
}

export const RemotesTiles = {
    remotePlanned: {
        title: "Friday",
        type: "remotePlanned",
        description: " - worked remotely Friday.",
        action: "marked"
    },
    remote: {
        title: "Emergency",
        type: "remote",
        description: " - worked remotely as an emergency.",
        action: "marked"
    },
    holiday :{
        title: 'Holidays',
        type: 'holiday',
        description: ' - National day off.',
        action: 'marked'
    }
}