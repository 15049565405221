import { Component } from 'react';
import { EditorState, convertToRaw, ContentState, convertFromHTML, convertFromRaw, RawDraftContentState  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

interface Props {
  id?: string,
  name: string,
  value: RawDraftContentState | undefined,
  onChange: (contentState: RawDraftContentState) => void,
}

interface State {
  contentState: ContentState,
}

class HTMLControl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contentState: this.props.value ? convertFromRaw(this.props.value) : ContentState.createFromText(""),
    }
  }

  onContentStateChange = (rawContentState : RawDraftContentState) => {
    this.setState({
      contentState: convertFromRaw(rawContentState),
    }, () => {
      this.props.onChange(rawContentState);
    });
  };
  
  componentDidMount() {
  }

  // componentDidUpdate(prevProps: Props) {
  //   if (this.props.value !== prevProps.value) {
  //     this.onEditorStateChange(this.state.editorState)
  //   }
  // }
  

  render() {
    return (
      <>
        <div className="html-control">
          <Editor
            wrapperClassName="html-control-wrapper"
            editorClassName="html-control-editor"
            toolbarClassName="html-control-toolbar"
            defaultContentState={this.props.value}
            onContentStateChange={this.onContentStateChange}
            toolbar={{
              emoji: {
                className: "hidden",
              },
              remove: {
                className: "hidden",
              },
              colorPicker: {
                className: "hidden",
              },
              fontSize: {
                className: "hidden",
              },
              fontFamily: {
                className: "hidden",
              },
            }}
          />
        </div>
      </>
    )
  }
}

export default HTMLControl;
