import { NavLabel } from "../interfaces/NavLabel";

export const NavigationLabels: {
    user: NavLabel[],
    data: NavLabel[],
    office: NavLabel[],
    tracking: NavLabel[],
    invoicing: NavLabel[],
    entities: NavLabel[],
    system: NavLabel[]
} = {
    "user": [
        {
            path: "/timetrack",
            imageURL: "timer-100",
            labelText: "Timetrack",
            production: true,
            basePaths: [
                '/timetrack'
            ]
        },
        {
            path: "/clocking",
            imageURL: "name-tag-100",
            labelText: "Clocking",
            production: true,
            basePaths: [
                '/clocking'
            ]
        },
        {
            path: "/bookings",
            imageURL: "beach-umbrella-100",
            labelText: "Bookings",
            production: true,
            basePaths: [
                '/bookings'
            ]
        },
        {
            path: "/remotes",
            imageURL: "working-with-a-laptop-100",
            labelText: "Remotes",
            production: true,
            basePaths: [
                '/remotes'
            ]
        },
        {
            path: "/groups-board",
            imageURL: "staff-100",
            labelText: "Groups",
            production: true,
        },
        {
            path: "/team",
            imageURL: "contacts-100",
            labelText: "Team",
            production: true,
        },
        {
            path: "/assigned-procedures",
            imageURL: "user-manual-100",
            labelText: "Procedures",
            production: true,
            basePaths: [
                "/assigned-procedures",
            ]
        },
    ],
    "data": [
        {
            path: "/reports-daily-target",
            imageURL: "goal-100",
            labelText: "Daily Target Report",
            production: true,
        },
        {
            path: "/clocking-report",
            imageURL: "name-tag-red-100",
            labelText: "Clocking Report",
            production: true,
        },
        {
            path: "/reports-billability",
            imageURL: "stack-of-coins-100",
            labelText: "Billability",
            production: true,
        },
        {
            path: "/reports-user-activity",
            imageURL: "transaction-list-100",
            labelText: "User Activity",
            production: true,
        },
        {
            path: "/procedure",
            imageURL: "literature-100",
            labelText: "Procedures",
            production: true,
            basePaths: [
                "/procedure",
            ]
        },
    ],
    "office": [
        {
            path: "/event",
            imageURL: "weekday-7-100",
            labelText: "Events",
            production: true,
            basePaths: [
                "/event",
            ]
        },
        {
            path: "/item",
            imageURL: "empty-box-100",
            labelText: "Items",
            production: true,
            basePaths: [
                "/item",
            ]
        },
        {
            path: "/computer",
            imageURL: "laptop-100",
            labelText: "Computers",
            production: true,
            basePaths: [
                "/computer",
            ]
        },
        {
            path: "/secucard",
            imageURL: "nfc-tag-100",
            labelText: "Access cards",
            production: true,
            basePaths: [
                "/secucard",
            ]
        },
        {
            path: "/secucode",
            imageURL: "card-code-100",
            labelText: "Security codes",
            production: true,
            basePaths: [
                "/secucode",
            ]
        },
        {
            path: "/secukey",
            imageURL: "key-100",
            labelText: "Physical keys",
            production: true,
            basePaths: [
                "/secukey",
            ]
        },
        {
            path: "/provider",
            imageURL: "supplier-100",
            labelText: "Providers",
            production: true,
            basePaths: [
                "/provider",
            ]
        },
    ],
    "tracking": [
        {
            path: "/electricity",
            imageURL: "lightning-bolt-100",
            labelText: "Electricity",
            production: true,
            basePaths: [
                "/electricity",
            ]
        },
        {
            path: "/water",
            imageURL: "water-100",
            labelText: "Water",
            production: true,
            basePaths: [
                "/water",
            ]
        },
        {
            path: "/gas",
            imageURL: "hex-burner-100",
            labelText: "Gas",
            production: true,
            basePaths: [
                "/gas",
            ]
        },
    ],
    "invoicing": [
        {
            path: "/invoices-hourly-by-project",
            imageURL: "document-100",
            labelText: "Hourly by project",
            production: true,
        },
        {
            path: "/invoices-hourly-by-user",
            imageURL: "document-100",
            labelText: "Hourly by resource",
            production: true,
        },
        {
            path: "/invoices-piece",
            imageURL: "document-100",
            labelText: "Sold by piece",
            production: true,
        },
    ],
    "entities": [
        {
            path: "/user",
            imageURL: "group-100",
            labelText: "Users",
            production: true,
            basePaths: [
                "/user",
            ]
        },
        {
            path: "/client",
            imageURL: "briefcase-100",
            labelText: "Clients",
            production: true,
            basePaths: [
                "/client",
            ]
        },
        {
            path: "/tenant",
            imageURL: "organization-100",
            labelText: "Tenants", 
            production: true,
            basePaths: [
                "/tenant",
            ]
        },
        {
            path: "/project",
            imageURL: "rocket-100",
            labelText: "Projects",
            production: true,
            basePaths: [
                "/project",
            ]
        },
        {
            path: "/contract",
            imageURL: "document-100",
            labelText: "Contracts",
            production: true,
            basePaths: [
                "/contract",
            ]
        },
    ],
    "system": [
        {
            path: "/role",
            imageURL: "merge-git-100",
            labelText: "Roles", 
            production: true,
            basePaths: [
                "/role",
            ]
        },
        {
            path: "/holiday",
            imageURL: "weekday-7-100",
            labelText: "Holidays", 
            production: true,
            basePaths: [
                "/holiday",
            ]
        },
        {
            path: "/group",
            imageURL: "staff-100",
            labelText: "Groups",
            production: true,
            basePaths: [
                "/group",
            ]
        },
        {
            path: "/benefit",
            imageURL: "gift-card-100",
            labelText: "Benefits",
            production: true,
            basePaths: [
                "/benefit",
            ]
        },
        {
            path: "/logo",
            imageURL: "image-gallery-100",
            labelText: "Logos", 
            production: true,
            basePaths: [
                "/logo",
            ]
        },
        {
            path: "/badge",
            imageURL: "warranty-100",
            labelText: "Badges",
            production: true,
            basePaths: [
                "/badge",
            ]
        },
        {
            path: "/sysprop",
            imageURL: "new-property-100",
            labelText: "Properties", 
            production: true,
            basePaths: [
                "/sysprop",
            ]
        },
    ]
}