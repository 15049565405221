import { Component } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  id?: string,
  path: string,
  imageURL: string,
  labelText: string,
  classes?: string,
  exact?: boolean,
  onClick?: () => void,
  basePaths?: string[],
}

class NavElement extends Component<Props> {
  render() {
    return (
      <li className={`nav-li ${this.props.classes}`}>
        <NavLink
          id={this.props.id}
          exact={this.props.exact}
          to={this.props.path}
          className="nav-link"
          activeClassName="active"
          isActive={(_, { pathname }) => this.props.basePaths ? !!this.props.basePaths!.find((basePath: string) => {
            if(pathname.startsWith(basePath)) return true;
          }) : pathname == this.props.path}
          onClick={this.props.onClick ? this.props.onClick : () => {}}
        >
          <div className="content-wrapper">
            <img
              className="icon"
              src={`/assets/icons/${this.props.imageURL}.png`}
              alt="icon"
            />
            <span>{this.props.labelText}</span>
          </div>
        </NavLink>
      </li>
    )
  }
}

export default NavElement;
