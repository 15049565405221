import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserDTO from '../../../common/api/dtos/User';
import { listUsers } from '../../../common/api/endpoints/users';
import { fetchWorklogsByClientUser } from '../../../common/api/endpoints/worklogs';
import { InvoiceLabels } from '../../../common/data/InvoiceLabels';
import { mergeRequestStatus, TRequestStatus } from '../../../common/types/RequestStatus';
import { ClientSection } from '../../generics/Invoices/ClientSection/ClientSection';
import { InvoiceSection } from '../../generics/Invoices/InvoiceSection/InvoiceSection';
import { ComponentProps } from '../../generics/Invoices/InvoicesLayout/InvoicesLayout';
import { withInvoicesLayout } from '../../generics/Invoices/InvoicesLayout/withInvoicesLayout';
import { InvoiceTable } from '../../generics/Invoices/InvoiceTable/InvoiceTable';
import { TenantSection } from '../../generics/Invoices/TenantSection/TenantSection';
import { LogoSection } from '../../generics/Invoices/LogoSection/LogoSection';
import { InvoiceItem } from '../../generics/Invoices/types';
import { calculateInvoicePriceInfo } from '../../generics/Invoices/utils';
import ProgressBar, { IProgress, incrementProgress, newProgress, restartProgress } from '../../utils/ProgressBar/ProgressBar';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../TransitionEvent';

interface Props extends ComponentProps { }

interface State {
  users: UserDTO[],
  items: {
    id: number,
    name: string,
    quantity: number,
    unitPrice: number,
  }[],

  progress: IProgress,
  invoiceDataProgress: IProgress,
  requestStatus: TRequestStatus,
}

class HourlyUserInvoice extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      users: [],
      items: [],

      progress: newProgress(1),
      invoiceDataProgress: newProgress(1),
      requestStatus: 'idle',
    }
  }

  componentDidMount() {
    this.init();
  }

  private async init() {
    this.setState({
      requestStatus: 'loading'
    });
    const users = await listUsers();
    this.setState(prevState => {
      return {
        requestStatus: 'success',
        users,
        progress: incrementProgress(prevState.progress),
      }
    });

    if (this.props.client) {
      const range = this.props.dateRange;
      this.loadClientReport(this.props.client.id, range.since, range.until);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.client && this.props.client !== prevProps.client) {
      const range = this.props.dateRange;
      this.loadClientReport(this.props.client.id, range.since, range.until);
    }

    if (
      this.props.dateRange.since.getTime() !== prevProps.dateRange.since.getTime()
      && this.props.dateRange.until.getTime() !== prevProps.dateRange.until.getTime()
    ) {
      // fetch information for this time period (for the current client;)
      if (this.props.client) {
        const range = this.props.dateRange;
        this.loadClientReport(this.props.client.id, range.since, range.until);
      }
    }
  }

  private async loadClientReport(clientId: number, since: Date, until: Date) {
    this.setState(prevState => {
      return {
        requestStatus: 'loading',
        invoiceDataProgress: restartProgress(prevState.invoiceDataProgress),
      };
    });

    const reports = await fetchWorklogsByClientUser(clientId, since, until, true);

    this.setState(prevState => {
      return {
        requestStatus: 'success',
        invoiceDataProgress: incrementProgress(prevState.invoiceDataProgress),
        items: reports.map(x => {
          const user = this.state.users.find(u => u.id === x.userId);
          return {
            id: user!.id,
            name: user!.name,
            quantity: x.workLog_hours,
            unitPrice: 0,
          };
        }),
      }
    });
  }

  /**
   * Update a row based on an invoice item.
   */
  private setInvoiceItem = (item: InvoiceItem) => {
    const index = this.state.items.findIndex(row => row.id === item.id);
    if (index === -1) {
      throw new Error('Failed to find invoice row during replace operation');
    }

    const rows = [...this.state.items];
    rows[index] = {
      ...this.state.items[index],
      quantity: item.quantity,
      unitPrice: item.unitPrice,
    };

    this.setState({ items: [...rows] });
  }


  render() {
    const translations = InvoiceLabels[this.props.language];
    const payprofile = this.props.payprofile;
    const tenant = this.props.tenant;
    const client = this.props.client;
    const language = this.props.language;
    const hasVat = this.props.vat !== undefined;

    const monthRange = this.props.dateRange;

    let totalAmount = 0;
    let totalVatAmount = 0;

    const invoiceItems = this.state.items.map(i => {
      const price = calculateInvoicePriceInfo(i.quantity, i.unitPrice, this.props.vat);

      // sum about the amounts for all the rows
      totalAmount += price.amount;
      totalVatAmount += price?.vatAmount || 0;

      return {
        id: i.id,
        name: (translations.webServicesA + i.id) + (translations.webServicesB ? `\n${translations.webServicesB + i.id}` : ""),
        quantity: i.quantity,
        unitPrice: i.unitPrice,
        amount: price.amount,
        unitPriceExclVat: price.unitPriceExclVat,
        vatAmount: price.vatAmount,
      }
    });

    return (
      <div>
        <ProgressBar
          currentStep={this.state.progress.currentStep + this.props.requestProgress.currentStep}
          totalSteps={this.state.progress.totalSteps + this.props.requestProgress.totalSteps}
        />
        <BreadcrumbControls
          pageTitle="Hourly by resource"
          status={mergeRequestStatus(this.props.requestStatus, this.state.requestStatus)}
        />
        <div className="flex-row fill">
          <div className="column">
            <div className="card">
              <div className="flex-row fill">
                <div className="column stretch">
                  <InvoiceSection
                    translations={translations}
                    invoiceSeries={payprofile?.invoiceSeries}
                    since={monthRange.since}
                    until={monthRange.until}
                    language={this.props.language}
                    tenant={tenant}
                  />
                </div>
                <div className="column stretch">
                  <LogoSection
                    translations={translations}
                    currencySymbol={payprofile?.currency}
                    amount={totalAmount + totalVatAmount}
                    language={language}
                    hasVat={hasVat}
                    tenant={tenant}
                  />
                </div>
              </div>
              <div className="flex-row fill">
                <div className="column stretch">
                  <div className="fill">
                    {tenant
                      ? <TenantSection tenant={tenant} vatRate={this.props.vat} labels={translations} payprofile={payprofile} />
                      : <div className="card">
                        <p className="text-chunk">There are no tenants defined. Start by <Link className="link-button" to="/tenant/add">adding</Link> the first one.</p>
                      </div>
                    }
                  </div>
                </div>
                <div className="column stretch">
                  <div className="fill">
                    {client
                      ? <ClientSection client={client} labels={translations} />
                      : <div className="card">
                        <p className="text-chunk">There are no clients defined. Start by <Link className="link-button" to="/client/add">adding</Link> the first one.</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProgressBar
          currentStep={this.state.invoiceDataProgress.currentStep}
          totalSteps={this.state.invoiceDataProgress.totalSteps}
        />
        <div className="flex-row fill">
          <div className="column">
            <div className="card">
              <div className="tableview-component">
                <InvoiceTable
                  currencySymbol={payprofile?.currency}
                  hasVat={hasVat}
                  invoiceItems={invoiceItems}
                  totalAmount={totalAmount}
                  totalVatAmount={totalVatAmount}
                  translations={translations}
                  onItemChange={this.setInvoiceItem}
                />
              </div>
            </div>
            <p contentEditable suppressContentEditableWarning={true}>
              {translations?.instructionA}
              <br />
              {translations?.instructionB}
            </p>
            <br />
            <p contentEditable suppressContentEditableWarning={true}>
              {translations?.disclaimerA}
              <br />
              {translations?.disclaimerB}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(withInvoicesLayout(HourlyUserInvoice));
