import React, { Component, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import Fetch from '../../../../common/helpers/Fetch';
import LogoDTO from '../../../../common/api/dtos/Logo';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import DateTCell from '../../../utils/DateTCell/DateTCell';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { listLogos } from '../../../../common/api/endpoints/logos';
import errorImage from '../../../../common/helpers/ImageError';
import Thumbnail from '../../../utils/Thumbnail/Thumbnail';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../../TransitionEvent';

interface State {
  logos?: LogoDTO[],
  status: TRequestStatus,
  tableSort: TableSort,
  showDisabledLogos: boolean,
  filterValue: string,
}

class LogoList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'fromDate',
    'toDate',
    'priority',
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      status: 'loading',
      filterValue: '',
      tableSort: new TableSort('name', 'asc'),
      showDisabledLogos: localStorage.getItem('showDisabledLogos') === 'false' || localStorage.getItem('showDisabledLogos') === null ? false : true,
    }
  }

  showDisabledLogos = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabledLogos ? this.setState({showDisabledLogos: false}) : this.setState({showDisabledLogos: true});
  }

  fetchLogos = async () => {
    this.setState({
      status: 'loading'
    });
    
    const logos = await listLogos();

    this.setState({
        status: 'success',
        logos: logos,
      });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  componentDidMount() {
    this.fetchLogos();
  }

  renderTHeadRow() {
    return(
      <tr>
        <th>#</th>
        <th>
          <SortButton 
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='fromDate' 
            text='From Date'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='toDate' 
            text='To Date'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='priority' 
            text='Priority'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        {this.state.showDisabledLogos &&
          <th className="text-center">
            <SortButton 
              column='enabled' 
              text='Enabled'
              tableSort={this.state.tableSort}
              onClick={this.handleSortChange}
            ></SortButton>
          </th>
        }
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(logo: LogoDTO) {
    return(
      <tr id={`logo${logo.id}`} key={`${logo.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <Thumbnail
                thumbnailURL={logo.url}
                thumbnailBackground={'transparent'}
                classes="release"
              />
              <span>{logo.name}</span>
            </div>
          </div>
        </td>
        <DateTCell date={logo.fromDate}/>
        <DateTCell date={logo.toDate}/>
        <td className="text-center">
          <span>{logo.priority}</span>
        </td>
        {this.state.showDisabledLogos && 
          <td className="text-center">
            {logo.enabled && 
              <span className="fas fa-check"></span>
            }
          </td>
        }
        <td className="text-right">
          <GhostButtonControl to={`/logo/edit/${logo.id}`} class="ghost-button"icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredLogos: Array<LogoDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.logos ? this.state.logos : []).sort(this.state.tableSort.sortByColumn).filter((logo: LogoDTO) => logo.enabled === true || this.state.showDisabledLogos);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Logos"
          status={this.state.status}
        />
        {this.state.logos && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showDisabledLogos"
                name="showDisabledLogos"
                changeMethod={this.showDisabledLogos}
                isChecked={this.state.showDisabledLogos}
                labelText="Disabled"
              />
              <div className="form-group">
                <Link to={'/logo/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add Logo</span></Link>
              </div>
            </ToolbarControls>

            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                      card
                      ${!this.state.logos ? 'loader-border' : ''}
                    `}
                  >
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.logos &&
                        <React.Fragment>
                          <tbody>
                            {filteredLogos.filter((logo: LogoDTO) => logo.enabled === true || logo.enabled === !this.state.showDisabledLogos).map(logo => (
                              this.renderTBodyRow(logo)
                            ))}
                          </tbody>
                          <tfoot>
                            {this.state.logos?.length === 0 &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">There are no logos defined. Start by <Link className="link-button" to="/logo/add">adding</Link> the first one.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredLogos.length} out of {this.state.logos.filter((logo: LogoDTO) => logo.enabled === true || this.state.showDisabledLogos).length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withTransitionEvent(LogoList);
