import Fetch from "../../helpers/Fetch";
import { PartialProjectGroup } from "../dtos/Project";
import { ProjectGroupDTO } from "../dtos/ProjectGroup";

interface AddPayload {
	clientId: number,
	deleted?: boolean,
	name: string,
	projectIds?: number[]
}

export async function listProjectGroups(clientId: number | null): Promise<ProjectGroupDTO[]> {
	const token = localStorage.getItem('id_token');
	const queryParam = clientId !== null && clientId !== undefined ? `?clientId=${clientId}` : '';
	const fetchRequest = new Fetch<ProjectGroupDTO[]>(`/projectGroups${queryParam}`, {
		method: "GET",
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		},
	});

	await fetchRequest.fetch();

	const status = fetchRequest.getStatus();

	if (status === 'success') {
		return fetchRequest.getPayload();
	}
	throw new Error('Failed to fetch project groups');
}

export async function listProjectGroupDetails(projectGroupId: number): Promise<ProjectGroupDTO> {
	const token = localStorage.getItem('id_token');
	const fetchRequest = new Fetch<ProjectGroupDTO>(`/projectGroup/${projectGroupId}`, {
		method: "GET",
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		},
	});

	await fetchRequest.fetch();

	const status = fetchRequest.getStatus();

	if (status === 'success') {
		return fetchRequest.getPayload();
	}
	throw new Error('Failed to fetch project group');
}

export async function addProjectGroup(formData: AddPayload): Promise<ProjectGroupDTO> {
	const token = localStorage.getItem('id_token');
	const fetchRequest = new Fetch<ProjectGroupDTO>(`/projectGroup`, {
		method: "POST",
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		},
		body: JSON.stringify(formData),
	});

	await fetchRequest.fetch();

	const status = fetchRequest.getStatus();

	if (status === 'success') {
		return fetchRequest.getPayload();
	}
	throw new Error('Failed to create project group');
}

export async function updateProjectGroup(formData: AddPayload, projectGroupId: number): Promise<ProjectGroupDTO> {
	const token = localStorage.getItem('id_token');
	const fetchRequest = new Fetch<ProjectGroupDTO>(`/projectGroup/${projectGroupId}`, {
		method: "PUT",
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		},
		body: JSON.stringify(formData),
	});

	await fetchRequest.fetch();

	const status = fetchRequest.getStatus();

	if (status === 'success') {
		return fetchRequest.getPayload();
	}
	throw new Error('Failed to update project group');
}