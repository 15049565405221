import React, { Component, ChangeEvent } from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { Link } from 'react-router-dom';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { listWaterUtils } from '../../../../common/api/endpoints/waterUtils';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../../TransitionEvent';
import WaterUtilDTO from '../../../../common/api/dtos/WaterUtil';
import moment from 'moment';

interface State {
  waterUtils?: WaterUtilDTO[],
  status: TRequestStatus
  tableSort: TableSort,
  showDeleted: boolean,
  filterValue: string,
}

class WaterUtilList extends Component<{}, State> {
  availableFilters: string[] = [
    'code',
    'description',
  ];

  tableHeaderColumns = [
    { property: '', text: '#', alignment: 'center' },
    { property: 'code', text: 'Invoice Code', alignment: 'left' },
    { property: 'date', text: 'Date', alignment: 'left' },
    { property: 'description', text: 'Description', alignment: 'left' },
    { property: 'quantity', text: 'Quantity', alignment: 'center' },
    { property: 'amount', text: 'Amount', alignment: 'center' },
    { property: 'amountPerUnit', text: 'PPU', alignment: 'center' },
    { property: '', text: '' },
  ];

  constructor(props: {}) {
    super(props);
    this.state = {
      status: 'loading',
      tableSort: new TableSort('code', 'asc'),
      showDeleted: localStorage.getItem('showDeletedEvents') === 'false' || localStorage.getItem('showDeletedEvents') === null ? false : true,
      filterValue: '',
    }
  }

  toggleDisabled = (waterUtil: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(waterUtil.target.name, JSON.stringify(waterUtil.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  fetchEvents = async () => {
    this.setState({
      status: 'loading'
    });
    
    const waterUtils = await listWaterUtils();

    this.setState({
        status: 'success',
        waterUtils: waterUtils,
    });
  }
  
  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchEvents();
  }

  renderTHeadRow() {
    return (
      <tr>
        {this.tableHeaderColumns.map((cell, index) => (
          <th key={index} className={`text-${cell.alignment ? cell.alignment : 'center'}`}>
            {cell.property ? (
              <SortButton
                column={cell.property}
                text={cell.text}
                tableSort={this.state.tableSort}
                onClick={this.handleSortChange}
              />
            ) : (
              cell.text
            )}
          </th>
        ))}
      </tr>
    );
  }
  
  renderTBodyRow(waterUtil: WaterUtilDTO) {
    return (
      <tr id={`waterUtil${waterUtil.id}`} key={`${waterUtil.id}`}>
        <td></td>
        <td>
          <span>{waterUtil.code}</span>
        </td>
        <td>
          <span>{moment(waterUtil.date).format('MMMM, YYYY')}</span>
        </td>
        <td>
          <p className="text-chunk">{waterUtil.description}</p>
        </td>
        <td className="text-center">
          <span>{waterUtil.quantity}</span>
        </td>
        <td className="text-center">
          <span>{waterUtil.amount}</span>
        </td>
        <td className="text-center">
          <span>{waterUtil.amountPerUnit}</span>
        </td>
        <td className="text-right">
          <GhostButtonControl to={`/water/edit/${waterUtil.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredEvents: Array<WaterUtilDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.waterUtils ? this.state.waterUtils : []).sort(this.state.tableSort.sortByColumn).filter((waterUtil: WaterUtilDTO) => waterUtil.deleted === this.state.showDeleted);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Water"
          status={this.state.status}
        />
        {this.state.waterUtils && 
          <div>
            <div className="flex-row fill">
              <div className="column">
                <ToolbarControls>
                  <TextControl
                    label="Filter"
                    placeholder="Filter"
                    type="text"
                    name="filterBox"
                    id="filterBox"
                    onChange={this.setFilterValue}
                    srOnly={true}
                  />
                  <ToggleControl
                    id="showDeletedEvents"
                    name="showDeletedEvents"
                    changeMethod={this.toggleDisabled}
                    isChecked={this.state.showDeleted}
                    labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
                  />
                  <div className="form-group">
                    <Link to='/water/add' className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add record</span></Link>
                  </div>
                </ToolbarControls>

                <div className="flex-row squeeze flex-v-center">
                  <div className="column">
                      <img className="thumbnail large release" style={{backgroundColor: "transparent", imageRendering: "auto"}} src="/assets/icons/water.png" />
                  </div>
                  <div className="column">
                      <div className="card">
                          <h2 className="primary-title">averages</h2>
                          <br />
                          <span className="faint-text">9 mᶟ / mo | 10.8 kmᶟ / yr</span>
                      </div>
                  </div>
                  <div className="column">
                      <span className="faint-text">Up next</span>
                      <br />
                      <h2 className="primary-title">9 mᶟ at 540.00 lei</h2>
                      <br />
                      <small className="faint-text">Predicted water consumption for December, 2022</small>
                  </div>
                </div>
                <div className="flex-row fill">
                  <div className="column">
                    <div className="tableview-component">
                        <div className={`
                          card
                          ${!this.state.waterUtils ? 'loader-border' : ''}
                          ${this.state.showDeleted ? "flashing-border" : ""}
                        `}>
                        <table>
                          <thead>
                            {this.renderTHeadRow()}
                          </thead>
                          {this.state.waterUtils &&
                            <React.Fragment>
                              <tbody>
                                {filteredEvents.map(waterUtil => (
                                  this.renderTBodyRow(waterUtil)
                                ))}
                              </tbody>
                              <tfoot>
                                {this.state.waterUtils?.length === 0 &&
                                  <tr>
                                    <th colSpan={8}>
                                      <div className="card">
                                        <p className="text-chunk">There are no water records defined. Start by <Link className="link-button" to="/water/add">adding</Link> the first one.</p>
                                      </div>
                                    </th>
                                  </tr>
                                }
                                {this.state.filterValue &&
                                  <tr>
                                    <th colSpan={8}>
                                      <div className="card">
                                        <p className="text-chunk">Showing {filteredEvents.length} out of {this.state.waterUtils.filter((waterUtil: WaterUtilDTO) => waterUtil.deleted === this.state.showDeleted).length} results.</p>
                                      </div>
                                    </th>
                                  </tr>
                                }
                              </tfoot>
                            </React.Fragment>
                          }
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withTransitionEvent(WaterUtilList);