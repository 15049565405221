import React, { ChangeEvent, Component } from 'react';
import { Link } from 'react-router-dom';
import TenantDTO from '../../../../common/api/dtos/Tenant';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { listTenants } from '../../../../common/api/endpoints/tenants';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import errorImage from '../../../../common/helpers/ImageError';
import { listLogos } from '../../../../common/api/endpoints/logos';
import LogoDTO from '../../../../common/api/dtos/Logo';
import Thumbnail from '../../../utils/Thumbnail/Thumbnail';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../../TransitionEvent';
interface State {
  tenants?: TenantDTO[],
  status: TRequestStatus,
  showSensitive: boolean,
  showDisabled: boolean,
  tableSort: TableSort,
  filterValue: string,
  logos?: LogoDTO[],
}

class TenantList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'legalName',
    'tradeNo',
    'taxId',
    'shareCapital',
    'streetAddress',
    'city',
    'state',
    'zipCode',
    'contactEmail',
    'bankAccount',
  ]

  constructor(props: {}) {
    super(props); 
    this.state = {
      filterValue: '',
      status: 'loading',
      showSensitive: false,
      showDisabled: localStorage.getItem('showDisabledTenants') === 'false' || localStorage.getItem('showDisabledTenants') === null ? false : true,
      tableSort: new TableSort('name', 'asc'),
    };
  }

  fetchLogos = async () => {
    this.setState({
      status: 'loading'
    });
    
    const logos = await listLogos();

    this.setState({
        status: 'success',
        logos: logos,
      });
  }

  sensitiveTimeout: number = 0;

  toggleSensitive = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.showSensitive ? this.setState({showSensitive: false}) : this.setState({showSensitive: true});
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({showDisabled: false}) : this.setState({showDisabled: true});
  }

  fetchTenants = async () => {
    this.setState({
      status: 'loading'
    });
    
    const tenants = await listTenants();

    this.setState({
        status: 'success',
        tenants: tenants,
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  componentDidMount() {
    this.fetchTenants();
    this.fetchLogos();
  }

  componentDidUpdate(prevState: State) {
    if(this.state.showSensitive !== prevState.showSensitive) {
      if(this.state.showSensitive){
        this.sensitiveTimeout = setTimeout(() => {
          this.setState({showSensitive: false});
        }, parseInt(String(process.env.REACT_APP_SENSITIVE_DATA_TIMEOUT))) as unknown as number;
      } else {
        clearTimeout(this.sensitiveTimeout);
      }
    }
  }
  
  renderTHeadRow() {
    return (
      <tr className='sensitive-data-header-row'>
        <th>#</th>
        <th className='text-left'>
          <SortButton 
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          />
        </th>
        <th className='text-left'>
          <SortButton 
            column='legalName' 
            text='General'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          />
        </th>
        <th className='text-left'>
          <SortButton 
            column='taxId' 
            text='Accounting'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          />
        </th>
        <th className='text-left'>
          <SortButton 
            column='state' 
            text='Misc'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          />
        </th>
        {this.state.showDisabled &&
          <th className='text-center'>
            <SortButton
              column='enabled'
              text='Enabled'
              tableSort={this.state.tableSort}
              onClick={this.handleSortChange}
            ></SortButton>
          </th>
        }
        <th></th>
      </tr>
    )
  }

  renderTbodyRow(tenant: TenantDTO) {
    return (
      <tr id={`tenant${tenant.id}`} key={`${tenant.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <Thumbnail
                thumbnailURL={tenant.logoURL}
                classes="thumbnail padded"
              />
              <span>{tenant.name}</span>
            </div>
          </div>
        </td>
        <td>
          <span>Legal Name <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{tenant.legalName}</span></span><br/>
          <span>Trade No. <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{tenant.tradeNo}</span></span><br/>
          <span>Address <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{tenant.streetAddress}</span></span><br/>
          <span>Country <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{tenant.country}</span></span>
        </td>
        <td>
          <span>Tax Id <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{tenant.taxId}</span></span><br/>
          <span>Share Capital <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{tenant.shareCapital}</span></span><br/>
          <span>City <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{tenant.city}</span></span><br/>
        </td>
        <td>
          <span>State <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{tenant.state}</span></span><br/>
          <span>Zip <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{tenant.zipCode}</span></span><br/>
          <span>Email <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{tenant.contactEmail}</span></span><br/>
        </td>
        {this.state.showDisabled &&
          <td className="text-center">
            {tenant.enabled &&
              <span className="fas fa-check"></span>
            }
          </td>
        }
        <td className="text-right">
          <GhostButtonControl to={`/tenant/edit/${tenant.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    )
  }

  errorPlaceholder = (event: HTMLImageElement) => {
    event.src = process.env.REACT_APP_ERROR_IMG_URL!;
  }

  render() {
    const filteredTenants: Array<TenantDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.tenants ? this.state.tenants : []).sort(this.state.tableSort.sortByColumn).filter((tenant: TenantDTO) => tenant.enabled === true || this.state.showDisabled);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Tenants"
          status={this.state.status}
        />
        {this.state.tenants?.length && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showSensitive"
                name="showSensitive"
                changeMethod={this.toggleSensitive}
                isChecked={this.state.showSensitive}
                labelText="Sensitive data"
                icon="fas fa-shield-alt"
                srOnly={true}
                title="Sensitive data"
              />
              <ToggleControl 
                id="showDisabledTenants"
                name="showDisabledTenants"
                changeMethod={this.toggleDisabled}
                isChecked={this.state.showDisabled}
                labelText="Disabled"
              />
              <div className="form-group">
                <Link to={'/tenant/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add tenant</span></Link>
              </div>
            </ToolbarControls>

            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                      card
                      ${!this.state.tenants ? 'loader-border' : ''}
                  `}>
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.tenants &&
                        <React.Fragment>
                          <tbody>
                            {filteredTenants.map((tenant: TenantDTO) => this.renderTbodyRow(tenant))}
                          </tbody>
                          <tfoot>
                            {this.state.tenants?.length === 0 &&
                              <tr>
                                <th colSpan={16}>
                                  <div className="card">
                                    <p className="text-chunk">There are no tenants defined. Start by <Link className="link-button" to="/tenant/add">adding</Link> the first one.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={16}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredTenants.length} out of {this.state.tenants.filter((tenants: TenantDTO) => tenants.enabled === true || this.state.showDisabled).length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withTransitionEvent(TenantList);
