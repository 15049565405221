import { errorFormatter } from "../../helpers/ErrorFormatter";
import Fetch from "../../helpers/Fetch";
import WorkProfileDTO from "../dtos/WorkProfile";

interface AddPayload {
}

type ListWorkProfile = {
  id: number,
  name: string,
  description: string,
  contractId: number,
  startDate: string, // YYYY-MM-DD
  hourNorm: number,
  hasTickets: boolean,
  clockIn: string, // format: HH:mm
  clockOut: string, // format: HH:mm
  deleted: boolean,
};

export async function getWorkProfile(id: number): Promise<WorkProfileDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ListWorkProfile>(`/workProfile/${id}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    const item = fetchRequest.getPayload();
    return {
      ...item,
      startDate: createDateFromDateStr(item.startDate),
    };
  }

  throw new Error('Failed to fetch workProfiles');
}

export async function listWorkProfiles(): Promise<WorkProfileDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ListWorkProfile[]>(`/workProfiles`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload().map(item => {
      return {
        ...item,
        startDate: createDateFromDateStr(item.startDate),
      }
    });
  }

  throw new Error('Failed to fetch workProfiles');
}

export async function listContractWorkProfiles(contractId: number): Promise<WorkProfileDTO[]> {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch<ListWorkProfile[]>(`/workProfiles?contractId=${contractId}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    })

    await fetchRequest.fetch();

    const status = fetchRequest.getStatus();

    if (status === 'success') {
      return fetchRequest.getPayload().map(item => {
        return {
          ...item,
          startDate: createDateFromDateStr(item.startDate),
        }
      });
    }

    throw new Error('Failed to fetch contract\'s workProfiles');
  }

  export async function addWorkProfile(form: any): Promise<WorkProfileDTO> {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch<WorkProfileDTO>(`/workProfile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        ...form,
        startDate: formatDate(form.startDate)
      })
    });

    await fetchRequest.fetch();

    const status = fetchRequest.getStatus();

    if (status === 'success') {
      return fetchRequest.getPayload();
    }

    throw errorFormatter(fetchRequest.getPayload());
  }

  export async function updateWorkProfile(form: any, contractId: number): Promise<WorkProfileDTO> {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch<WorkProfileDTO>(`/workProfile/${contractId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        ...form,
        startDate: formatDate(form.startDate)
      })
    });

    await fetchRequest.fetch();

    const status = fetchRequest.getStatus();

    if (status === 'success') {
      return fetchRequest.getPayload();
    }
    
    throw errorFormatter(fetchRequest.getPayload());
  }


export function createDateFromDateStr(str: string) {
  const parts = str.split('-');
  const year = Number(parts[0]);
  const month = Number(parts[1]);
  const day = Number(parts[2]);

  return new Date(year, month - 1, day);
}

export function formatDate(date: Date) {
    return [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getDate().toString().padStart(2, '0'),
    ].join('-');
}