import moment from 'moment';
import React, { ChangeEvent, Component } from 'react';
import SyspropDTO from '../../../common/api/dtos/Sysprop';
import UserDTO from '../../../common/api/dtos/User';
import { listUsers } from '../../../common/api/endpoints/users';
import { fetchWorklogsBilabillity } from '../../../common/api/endpoints/worklogs';
import AppContext from '../../../common/context/AppContext';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import ToggleControl from '../../controls/ToggleControl/ToggleControl';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import ToolbarControls from '../../generics/Header/ToolbarControls';
import { extractYears } from '../../generics/Invoices/utils';
import SelectControl from '../../controls/SelectControl/SelectControl';
import UltraChart from '../../utils/UltraChart/UltraChart';
import { FlashCard } from '../../widgets/GeneralFlashCards/GeneralFlashCards';
import SelectControlMultiple from '../../controls/SelectControlMultiple/SelectControlMultiple';
import { withTransitionEvent } from '../../TransitionEvent';

export interface Props {
  sysProps: SyspropDTO[],
}

interface State {
  form: Form,
  showWeekends: boolean,
  status: TRequestStatus,
  activeYears: number[],
  users: UserDTO[],

  currentYear?: {
    billable: number[],
    unbillable: number[],
  },
  lastYear?: {
    billable: number[],
    unbillable: number[],
  },

  /**
   * Rendering the chart with the same data causes it to flicker. To avoid that cache the
   * presentation information which is computed based on the current/last year billability info.
   */
  cache: {
    bilabillity: string,
    lastYearBilabillity: string,
    totalChange: string,
    chartData: {data: number[], label: string}[],
    suggestedMax: number,
    dayLabels: string[],
  }
}

interface Form {
  selectedYear: number,
  selectedMonths: number[],
  selectedUsers: string[],
  month: number,
}

const months = [
  {
    label: 'Jan',
    value: '0',
  },
  {
    label: 'Feb',
    value: '1',
  },
  {
    label: 'Mar',
    value: '2',
  },
  {
    label: 'Apr',
    value: '3',
  },
  {
    label: 'May',
    value: '4',
  },
  {
    label: 'Jun',
    value: '5',
  },
  {
    label: 'Jul',
    value: '6',
  },
  {
    label: 'Aug',
    value: '7',
  },
  {
    label: 'Sep',
    value: '8',
  },
  {
    label: 'Oct',
    value: '9',
  },
  {
    label: 'Nov',
    value: '10',
  },
  {
    label: 'Dec',
    value: '11',
  },
];

class Billability extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        selectedYear: new Date().getFullYear(),
        selectedMonths: [new Date().getUTCMonth()],
        selectedUsers: [],
        month: new Date().getMonth(),
      },
      showWeekends: true,
      activeYears: extractYears(this.props.sysProps),
      status: 'idle',
      users: [],
      cache: {
        bilabillity: 'N/A',
        lastYearBilabillity: 'N/A',
        totalChange: 'N/A',
        chartData: [
          { label: 'Non-Billable', data: [] },
          { label: 'Billable', data: [] },
        ],
        suggestedMax: 0,
        dayLabels: [],
      }
    }
  }

  private async fetchWorklogsBilabillity(userIds: number[], year: number, selectedMonths: number[]) {
    // Make a list of pairs out of a sorted list of months.
    // Example: [1, 3, 4, 5, 7, 8, 10], to: [[1, 1], [3, 5], [7, 8], [10, 10]]
    const initialPair: [number, number][] = [[selectedMonths[0], selectedMonths[0]]];
    const monthPairs = selectedMonths.slice(1).reduce((prev: [number, number][], current: number) => {
      const lastPair = prev[prev.length - 1];
      if (lastPair[1] + 1 === current) {
        lastPair[1] = current;
        return prev;
      }
      return [...prev, [current, current] as [number, number]];
    }, initialPair);

    const dateRanges = this.createDateRanges(year, monthPairs);
    const lastYearDateRanges = this.createDateRanges(year - 1, monthPairs);

    const results = await Promise.all([
      fetchWorklogsBilabillity(userIds, dateRanges),
      fetchWorklogsBilabillity(userIds, lastYearDateRanges),
    ]);

    // In case there are multiple months, use Jan since it has 31 days
    const month = selectedMonths.length == 1 ? selectedMonths[0] : 0;
    const nrOfDays = new Date(year, month + 1, 0).getDate();

    const result: Pick<State, 'currentYear' | 'lastYear'> = {
      currentYear: undefined,
      lastYear: undefined,
    };

    if (results[0].days.length) {
      result.currentYear = this.processDays(nrOfDays, results[0].days);
    }
    if (results[1].days.length) {
      result.lastYear = this.processDays(nrOfDays, results[1].days);
    }

    return result;
  }

  private createDateRanges(year: number, monthPairs: [number, number][]) {
    return monthPairs.map(([first, second]) => {
      return {
        from: moment.utc({ year, month: first }).startOf('month').toDate(),
        until: moment.utc({ year, month: second }).endOf('month').toDate(),
      }
    });
  }

  /**
   * Transform data from API into a form which is more easily useable.
   */
  private processDays(nrOfDays: number, days: {day: number, billableHours: number, unbillableHours: number}[]) {
    const daysMap: {[key: number]: {billableHours: number, unbillableHours: number}} = {};
    for (const day of days) {
      daysMap[day.day] = {billableHours: day.billableHours, unbillableHours: day.unbillableHours};
    }

    const billable = new Array(nrOfDays).fill(0) as number[];
    const unbillable = new Array(nrOfDays).fill(0)  as number[];

    for (let index = 0; index < nrOfDays; index++) {
      const dayNr = index + 1;
      // default to 0 in case there isn't a result for this day
      let day = daysMap[dayNr] || {billableHours: 0, unbillableHours: 0};
      billable[index] = day.billableHours;
      unbillable[index] = day.unbillableHours;
    }

    return { billable, unbillable };
  }

  private updateWorklogBilabillity = async () => {
    this.setState({
      status: 'loading'
    });

    const userIds = this.state.form.selectedUsers.map(v => Number(v));
    const year = this.state.form.selectedYear;
    const months = this.state.form.selectedMonths;
    const result = await this.fetchWorklogsBilabillity(userIds, year, months);

    this.setState({
      status: 'success',
      ...result,
      cache: this.createCache(result.currentYear, result.lastYear, this.state.showWeekends),
    });
  }

  /**
   * Create cache object based on current/last year billability information.
   *
   * The cache is used the avoid a flicker in the chart when rendering the same data.
   */
  private createCache(
    currentYear: {billable: number[], unbillable: number[]} | undefined,
    lastYear: {billable: number[], unbillable: number[]} | undefined,
    showWeekends: boolean
  ) {
    const year = this.state.form.selectedYear;
    const selectedMonths = this.state.form.selectedMonths;

    // In case there are multiple months, use Jan since it has 31 days
    const month = selectedMonths.length == 1 ? selectedMonths[0] : 0;
    const nrOfDays = new Date(year, month + 1, 0).getDate();

    let currentYearBillableDays: number[] = [];
    let currentYearUnbillableDays: number[] = [];

    let lastYearBillableDays: number[] = [];
    let lastYearUnbillableDays: number[] = [];

    let labels = Array.from({ length: nrOfDays }, (_, i) => (i + 1).toString());
    let dayLabels = [];

    if (showWeekends || selectedMonths.length > 1) {
      dayLabels = labels;
      if (currentYear) {
        currentYearBillableDays = currentYear.billable;
        currentYearUnbillableDays = currentYear.unbillable;
      }
      if (lastYear) {
        lastYearBillableDays = lastYear.billable;
        lastYearUnbillableDays = lastYear.unbillable;
      }
    } else {
      if (currentYear) {
        const nrOfDays = new Date(year, month + 1, 0).getDate();
        for (let index = 0; index < nrOfDays; index++) {
          let currDay = moment(new Date(year, month, index + 1));
          const isWeekday = currDay.isoWeekday() !== 6 && currDay.isoWeekday() !== 7;

          if (isWeekday) {
            dayLabels.push((index + 1).toString());
            currentYearBillableDays.push(currentYear.billable[index]);
            currentYearUnbillableDays.push(currentYear.unbillable[index]);
          }
        }
      }

      if (lastYear) {
        const nrOfDays = new Date(year - 1, month + 1, 0).getDate();
        for (let index = 0; index < nrOfDays; index++) {
          let currDay = moment(new Date(year - 1, month, index + 1));
          const isWeekday = currDay.isoWeekday() !== 6 && currDay.isoWeekday() !== 7;

          if (isWeekday) {
            lastYearBillableDays.push(lastYear.billable[index]);
            lastYearUnbillableDays.push(lastYear.unbillable[index]);
          }
        }
      }
    }

    const maxBillable = Math.max(...currentYearBillableDays) * 1.1;
    const maxUnbillable = Math.max(...currentYearUnbillableDays) * 1.1;
    const suggestedMax = maxBillable > maxUnbillable ? maxBillable : maxUnbillable;

    const currentYearBilabille = currentYearBillableDays.reduce((prev, val) => prev + val, 0);
    const currentYearUnbillable = currentYearUnbillableDays.reduce((prev, val) => prev + val, 0);

    const lastYearBillable = lastYearBillableDays.reduce((prev, val) => prev + val, 0);
    const lastYearUnbillable = lastYearUnbillableDays.reduce((prev, val) => prev + val, 0);

    const bilabillity = currentYearBilabille / (currentYearBilabille + currentYearUnbillable) * 100;
    const lastYearBilabillity = lastYearBillable / (lastYearBillable + lastYearUnbillable) * 100;

    const hasCurrentYear = Boolean(currentYear) && bilabillity > 0;
    const hasLastYear = Boolean(lastYear) && lastYearBilabillity > 0;

    let totalChange = 'N/A';
    if (hasLastYear) {
      if (hasCurrentYear) {
        totalChange = `${(bilabillity - lastYearBilabillity).toFixed(2)}`;
        totalChange = Number(totalChange) > 0 ? '+' + totalChange + '%' : totalChange + '%';
      } else {
        totalChange = `-${lastYearBilabillity.toFixed(2)}%`;
      }
    } else {
      if (hasCurrentYear) {
        totalChange = `+${bilabillity.toFixed(2)}%`;
      }
    }

    return {
      bilabillity: hasCurrentYear ? `${bilabillity.toFixed(2)}%` : 'N/A',
      lastYearBilabillity: hasLastYear ? `${lastYearBilabillity.toFixed(2)}%` : 'N/A',
      totalChange: totalChange,
      chartData: [
        {
          data: currentYearUnbillableDays,
          label: 'Non-Billable',
        },
        {
          data: currentYearBillableDays,
          label: 'Billable',
        },
      ],
      suggestedMax: suggestedMax,
      dayLabels: dayLabels,
    }
  }

  private setMonth = async (e: ChangeEvent<HTMLSelectElement>) => {
    let options = Array.from(e.target.options);
    let selectedMonths = options.filter(o => o.selected).map(o => Number(o.value));

    this.setState({
      form: {
        ...this.state.form,
        selectedMonths,
      }
    }, this.updateWorklogBilabillity);
  }

  private setUser = (e: ChangeEvent<HTMLSelectElement>) => {
    const usersOptions = Array.from(e.target.options);
    const selectedUsers = usersOptions.filter(o => o.selected).map(o => o.value);

    this.setState({
      form: {
        ...this.state.form,
        selectedUsers: selectedUsers,
      },
    }, this.updateWorklogBilabillity);
  }

  private setUserCustom = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, usersIds: number[] | string[] | undefined) => {
    const selectedUsers = usersIds?.map(userId => String(userId));

    this.setState({
      form: {
        ...this.state.form,
        selectedUsers: selectedUsers as string[], 
      },
    }, () => {
      this.state.form.selectedUsers.length !== 0 && this.updateWorklogBilabillity();
    });
  }

  private setYear = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, yearNo: number | string | undefined) => {
    this.setState({
      form: {
        ...this.state.form,
        selectedYear: Number(yearNo),
      },
    }, this.updateWorklogBilabillity);
  }

  private showWeekends = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      showWeekends: event.target.checked,
      cache: this.createCache(this.state.currentYear, this.state.lastYear, event.target.checked),
    });
  }

  componentDidMount() {
    this.initialize();
  }

  private async initialize() {
    this.setState({ status: 'loading' });

    const users = await listUsers();
    const filteredUser = users.filter((user: UserDTO) => !user.invisible);

    const userIds = filteredUser.map(u => u.id);
    const year = this.state.form.selectedYear;
    const months = this.state.form.selectedMonths;
    const bilabillityResult = await this.fetchWorklogsBilabillity(userIds, year, months);

    this.setState({
      status: 'success',
      ...bilabillityResult,
      users: filteredUser,
      cache: this.createCache(bilabillityResult.currentYear, bilabillityResult.lastYear, this.state.showWeekends),
      form: {
        ...this.state.form,
        selectedUsers: filteredUser.map(u => u.id.toString()),
      }
    });
  }

  render() {
    return (
      <div>
        <ToolbarControls>
          <SelectControl
            idName="year"
            value={this.state.form.selectedYear}
            options={this.state.activeYears}
            onChange={this.setYear}
          />
          <div className="form-group">
            <ToggleControl
              id="showWeekends"
              name="showWeekends"
              changeMethod={this.showWeekends}
              isDisabled={this.state.form.selectedMonths.length > 1}
              isChecked={this.state.showWeekends && this.state.form.selectedMonths.length < 2}
              labelText='Weekends'
            ></ToggleControl>
          </div>
          <div className="form-group">
            <button className="primary-button" onClick={() => window.print()}><span className="static-icon"><span className="fas fa-print"></span></span> <span className="text">Print</span></button>
          </div>
        </ToolbarControls>
        <BreadcrumbControls
          pageTitle="Billability"
          status={this.state.status}
        />
        <div className="flex-row tightest">
          <div className="column">
            <div className="flex-row tightest-top flash-card-widget-component">
              <div className="column stretch small">
                <FlashCard reverse title={this.state.cache.bilabillity} name={'billable'} />
              </div>
              <div className="column stretch small">
                <FlashCard reverse title={this.state.cache.lastYearBilabillity} name={`billable (${this.state.form.selectedYear-1})`} />
              </div>
              <div className="column stretch small">
                <div className="flash-card card">
                  <div className="content">
                    <React.Fragment>
                      <small className="faint-text">change <span className="fas fa-chart-line"></span></small>
                      <span className="primary-title">{this.state.cache.totalChange}
                      </span>
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column">
            <div className="card">
              <div className="flex-row squeeze">
                <div className="column v-fill">
                  <SelectControlMultiple
                    id="userId"
                    name="userId"
                    value={this.state.form.selectedUsers}
                    changeMethod={this.setUser}
                    disabled={this.state.status === 'loading'}
                    options={this.context.users.filter((user: UserDTO) => user.invisible !== true)}
                    classes="mw-small"
                    groupClasses="v-fill"
                    getOptionProps={(op: UserDTO) => {
                      return {
                        key: op.id,
                        value: op.id,
                        label: op.name,
                      }
                    }}
                    multiple={true}
                  />
                  {/* <CustomDropdown
                    id="userId"
                    value={this.state.form.selectedUsers || []}
                    options={this.context.users.filter((user: UserDTO) => user.invisible !== true)}
                    onChange={this.setUserCustom}
                    getValue={(op: UserDTO) => op?.id}
                    getLabel={(op: UserDTO) => op?.name as string}
                  /> */}
                </div>
                <div className="column">
                  <SelectControlMultiple
                    multiple={true}
                    classes="horizontal hide-scroll"
                    changeMethod={this.setMonth}
                    value={this.state.form.selectedMonths.map(v => v.toString())}
                  >
                    {months.map((month) => (
                      <option
                        key={month.value}
                        value={month.value}
                      >
                        {month.label}
                      </option>
                    ))}
                  </SelectControlMultiple>
                  <div className="chart-container">
                    <UltraChart
                      chartData={this.state.cache.chartData}
                      suggestedMaxA={this.state.cache.suggestedMax}
                      suggestedMaxB={this.state.cache.suggestedMax}
                      labels={this.state.cache.dayLabels}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTransitionEvent(Billability);
Billability.contextType = AppContext;
