import Joi from 'joi';
import React, {Component, ChangeEvent} from 'react';
import { FormErrors } from '../../../common/data/FormErrors';
import { processJoiError } from '../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import ButtonControl from '../../controls/ButtonControl/ButtonControl';
import TextControl from '../../controls/TextControl/TextControl';
import RequestStatus from '../../utils/RequestStatus/RequestStatus';

interface State {
  status: TRequestStatus,
  errorMessage: string,
  formData: FormData,
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

interface FormData {
  email: string,
  password: string
}

export interface Props {
  sessionExpired: boolean,
  unlockApp: () => void,
  setSessionExpired: (sessionExpired: boolean) => void
}

class Login extends Component<Props, State> {
  formSchema = Joi.object({
    email: Joi.string().trim(true).email({ minDomainSegments: 2, tlds: false}).required().messages(FormErrors.string),
    password: Joi.string().required().messages(FormErrors.string)
  });
  
  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      errorMessage: '',
      formData: {
        email: '',
        password: '',
      },
      formErrors: {},
    }
  }

  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const formData = this.state.formData;

    this.setState({
      formData: {
        ...formData,
        [event.target.name]: event.target.value
      }
    } as any);
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  handleSubmit = async (event: any) => {
    event.preventDefault();
    const isValid = this.validateForm();
    
    if (isValid) {
      this.props.setSessionExpired(false);

      this.setState({
        status: 'loading'
      });

      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({'email': this.state.formData.email, 'password': this.state.formData.password})
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth`, requestOptions);
      const data = await response.json();

      if (response.status >= 400 && response.status < 600) {
        this.setState({
          status: 'error',
          errorMessage: data.message,
        });
      } else {
        this.setState({
          status: 'success'
        });

        localStorage.setItem("id_token", data['token']['access_token']);
        localStorage.setItem("id_token_expiration", data['token']['expires_in']);
        localStorage.setItem("id_token_stamp", Date.now().toString());

        this.props.unlockApp();
      }
    }
  }

  render() {
    return (
      <div data-testid="Login" className="login-component will-transition">
        <div className="login-container">
          <div className="card p-large">
            <img className="logo" src={process.env.REACT_APP_STATIC_LOGO} alt="Workstrym" />
            <br />
            <div
              className={`
                avatar-strip
                ${this.state.status === 'loading' ? 'animate': ''}
              `}
              style={
                {backgroundImage: `url(${process.env.REACT_APP_AVATARS_PATH}avastrip.png)`}
              }
            ></div>
            <ul
              className={`
                error-list
                ${this.state.status === 'error' || this.props.sessionExpired ? 'visible' : ''}
              `}
            >
              {
                this.state.status === 'error' &&
                <li>{this.state.errorMessage}</li>
              }
              {
                this.props.sessionExpired &&
                this.state.status !== 'error' &&
                <li>Your previous session has expired.</li>
              }
            </ul>
            <form onSubmit={this.handleSubmit}>
              <TextControl
                label="Email"
                type="email"
                name="email"
                id="email"
                onChange={this.handleInput}
                required={true}
                br={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.email}
              />
              <TextControl
                label="Password"
                type="password"
                name="password"
                id="password"
                onChange={this.handleInput}
                required={true}
                br={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.password}
              />
              <ButtonControl class="primary-button">
                <RequestStatus status={this.state.status} />
                <span className="text">Log in</span>
              </ButtonControl>
            </form>
          </div>
          <br />
          <small className="faint-text">For access to the Application you need to enter your provided credentials. If you are unable to log in please contact your Administrator.</small>
        </div>
      </div>
    )
  }

}

export default Login;
