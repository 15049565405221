export type TRequestStatus = 'idle' | 'success' | 'loading' | 'error';

/**
 * Merge the request status from two different sources.
 */
export function mergeRequestStatus(a: TRequestStatus, b: TRequestStatus) {
  if (a === 'error' || b === 'error') {
    return 'error';
  }

  if (a === 'loading' || b === 'loading') {
    return 'loading';
  }

  if (a === 'success' || b === 'success') {
    return 'success';
  }

  return 'idle';
}