import { override } from "joi";
import Fetch from "../../helpers/Fetch";
import WaterUtilDTO, { WaterUtilPayload } from "../dtos/WaterUtil";

export async function listWaterUtils(): Promise<WaterUtilDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<WaterUtilDTO[]>(`/waters`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    const payloadWithDateObj = fetchRequest.getPayload().map(el => ({
      ...el,
      date: new Date(el.year, el.month - 1, 1)
    }));

    return payloadWithDateObj;
  }
  throw new Error('Failed to fetch waterUtils');
}

export async function listWaterUtilDetails(waterUtilId: string): Promise<WaterUtilDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<WaterUtilDTO>(`/water/${waterUtilId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return { ...fetchRequest.getPayload(), month: fetchRequest.getPayload().month - 1 }
  }
  throw new Error('Failed to fetch waterUtil details');
}

export async function addWaterUtil(form: WaterUtilPayload): Promise<WaterUtilDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<WaterUtilDTO>(`/water`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      ...form,
      month: form.month + 1
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add waterUtil`);
}

export async function updateWaterUtil(form: WaterUtilPayload, waterUtilId: string): Promise<WaterUtilDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<WaterUtilDTO>(`/water/${waterUtilId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      ...form,
      month: form.month + 1
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update waterUtil`);
}