import Fetch from "../../helpers/Fetch";
import BadgeDTO from "../dtos/Badge";
import BenefitDTO from "../dtos/Benefit";
import GroupDTO from "../dtos/Group";
import ProjectDTO from "../dtos/Project";
import RoleDTO from "../dtos/Role";

interface AddPayload {
  name: string,
  description: string,
}

export async function listRoles(): Promise<RoleDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<RoleDTO[]>(`/role`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch roles');
}

export async function listRoleDetails(roleId: string): Promise<RoleDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<RoleDTO>(`/role/${roleId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch role details');
}

export async function addRole(form: AddPayload): Promise<RoleDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<RoleDTO>(`/role`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add usser`);
}

export async function updateRole(form: AddPayload, roleId: string): Promise<RoleDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<RoleDTO>(`/role/${roleId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update role`);
}

export interface RoleEndpointDTO {
  identifier: string,
  controlFlags: string[],
  responseMask: EndpointResponseMaskDTO,
}

export type EndpointResponseMaskDTO =
    | boolean
    | { [key: string]: boolean | EndpointResponseMaskDTO };

export interface NewRoleListDTO {
  id: number,
  name: string,
  description: string,
}

export interface NewRoleDTO extends NewRoleListDTO {
  endpoints: Array<RoleEndpointDTO>
}

export interface UpdatePayload {
  name?: string,
  description?: string,
  endpoints?: RoleEndpointDTO[],
}

export async function listNewRoles(): Promise<RoleDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<RoleDTO[]>(`/roles`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  });
  fetchRequest.baseUrl = process.env.REACT_APP_API_NEW_BASE_URL;

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch roles');
}

export async function getNewRole(roleId: string): Promise<NewRoleDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<NewRoleDTO>(`/roles/${roleId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  });
  fetchRequest.baseUrl = process.env.REACT_APP_API_NEW_BASE_URL;

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch roles');
}

export async function addNewRole(form: AddPayload): Promise<NewRoleDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<NewRoleDTO>(`/roles`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      endpoints: [],
      ...form,
    })
  });
  fetchRequest.baseUrl = process.env.REACT_APP_API_NEW_BASE_URL;

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update role`);
}

export async function updateNewRole(form: UpdatePayload, roleId: string): Promise<NewRoleDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<NewRoleDTO>(`/roles/${roleId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });
  fetchRequest.baseUrl = process.env.REACT_APP_API_NEW_BASE_URL;

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update role`);
}