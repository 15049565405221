import moment from 'moment';
import React, { Component, KeyboardEvent } from 'react';
import SyspropDTO from '../../../common/api/dtos/Sysprop';
import TenantDTO from '../../../common/api/dtos/Tenant';
import { getClockingReportToken } from '../../../common/api/endpoints/clocking';
import { listTenants } from '../../../common/api/endpoints/tenants';
import { Month, Months } from '../../../common/data/Months';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import SelectControl from '../../controls/SelectControl/SelectControl';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import ToolbarControls from '../../generics/Header/ToolbarControls';
import { withTransitionEvent } from '../../TransitionEvent';
import { incrementProgress, IProgress } from '../../utils/ProgressBar/ProgressBar';

interface Props {
  sysProps: SyspropDTO[]
}

interface State {
  years: number[],
  months: number[],
  tenants: TenantDTO[],
  status: TRequestStatus,
  progress: IProgress,
  form: FormData
}

interface FormData {
  year: number,
  month: number,
  tenantId?: number,
  downloadToken: string
}

class ClockingReport extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      years: [],
      months: [],
      status: 'idle',
      tenants: [],
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      form: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        downloadToken: ''
      },
    }
  }

  private init = async () => {
    this.getYearsArray(this.props.sysProps[1].value);

    this.setState({
      status: 'loading'
    });

    const tenants = await listTenants().then(this.incrementProgress);
    const downloadToken = await getClockingReportToken();

    this.setState(prevState => {
      return {
        status: 'success',
        tenants,
        form: {
          ...prevState.form,
          tenantId: tenants[0].id,
          downloadToken: downloadToken.downloadToken,
        }
      }
    })
  }

  getYearsArray = (yearsString: string): void => {
    this.setState({
      years: yearsString.split(',').map(Number),
    });
  }
  
  private incrementProgress = <T,>(result: T) => {
    this.setState(prevState => {
      return {
        progress: incrementProgress(prevState.progress),
      }
    });

    return result;
  }

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const form = this.state.form;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    });
  }

  setYear = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, yearNo: number | string | undefined) => this.updateForm('year', Number(yearNo));
  setMonth = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, month: number | string | undefined | Month) => this.updateForm('month', Number(month));
  setTenant = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, tenantId: number | string | undefined | TenantDTO) => this.updateForm('tenantId', Number(tenantId));

  componentDidMount() {
    this.init();
  }

  render() {
    const selectedTenant = this.state.tenants.find(tenant => tenant.id === this.state.form.tenantId)?.name;
    const downloadURL = `${process.env.REACT_APP_API_BASE_URL}/clockings/download?token=${this.state.form.downloadToken}&tenantId=${this.state.form.tenantId}&year=${this.state.form.year}&month=${this.state.form.month + 1}`
    return (
      <div>
        <ToolbarControls>
        </ToolbarControls>
        <BreadcrumbControls
          pageTitle="Clocking Report"
          status={this.state.status}
        />
        <div className="flex-row tightest-top">
          <div className="column large">
            <div className="flex-row squeeze">
              <div className="column">
                <SelectControl
                  idName="year"
                  label="Year"
                  value={this.state.form.year}
                  options={this.state.years}
                  onChange={this.setYear}
                />
              </div>
              <div className="column">
                <SelectControl
                  idName="month"
                  label="Month"
                  value={this.state.form.month}
                  options={Months}
                  disabled={this.state.status === 'loading'}
                  onChange={this.setMonth}
                  getValue={op => op.number}
                  getLabel={op => op?.name}
                />
              </div>
              <div className="column">
                <SelectControl
                  idName="tenantId"
                  label="Tenant"
                  value={this.state.form.tenantId ?? undefined}
                  options={this.state.tenants}
                  disabled={this.state.status === 'loading'}
                  onChange={this.setTenant}
                  getValue={(op) => op.id}
                  getLabel={(op) => op?.name}
                />
              </div>
            </div>
            <div className="card">
                <div className="card">
                  <div className="form-group">
                    <small><span className="faint-text">You are generating a report on </span>{moment().month(this.state.form.month).format('MMMM')}, {this.state.form.year}<span className="faint-text"> for tenant </span>{selectedTenant}</small><span className="faint-text">.</span>
                    <br />
                    {selectedTenant ?
                      <small className="faint-text">{selectedTenant}_{this.state.form.year}_{this.state.form.month+1}.xlsx</small>
                    :
                    <small className="faint-text">Loading...</small>
                    }
                  </div>
                  <a
                    href={downloadURL}
                    className="primary-button"
                  >
                    <span className="static-icon">
                      <span className="fas fa-download"></span>
                    </span>
                    <span className="text">Download</span>
                  </a>
                </div>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(ClockingReport);