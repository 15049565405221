import React, { Component, ChangeEvent } from 'react';
import Fetch from '../../../../common/helpers/Fetch';
import EventDTO from '../../../../common/api/dtos/Event';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus'; 
import { Link } from 'react-router-dom';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { listEvents } from '../../../../common/api/endpoints/events';
import Thumbnail from '../../../utils/Thumbnail/Thumbnail';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import DateTCell from '../../../utils/DateTCell/DateTCell';
import { withTransitionEvent } from '../../../TransitionEvent';
import moment from 'moment';

interface State {
  events?: EventDTO[],
  status: TRequestStatus
  tableSort: TableSort,
  showDeleted: boolean,
  filterValue: string,
}

class EventList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'client.name',
  ];

  constructor(props: {}) {
    super(props);
    this.state = {
      status: 'loading',
      tableSort: new TableSort('name', 'asc'),
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      filterValue: '',
    }
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  fetchEvents = async () => {
    this.setState({
      status: 'loading'
    });
    
    const events = await listEvents();

    this.setState({
        status: 'success',
        events: events,
    });
  }
  
  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchEvents();
  }

  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th className="text-left">
          <SortButton 
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-left">
          <SortButton 
            column='date' 
            text='Date'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-left">
          Roles
        </th>
        <th></th>
      </tr>
    )
  }

  renderTBodyRow(event: EventDTO) {
    return (
      <tr id={`event${event.id}`} key={`${event.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <Thumbnail
                thumbnailURL={event.iconURL ? event.iconURL : `/assets/icons/weekday-${moment(event.date).isoWeekday()}-100.png`}
                thumbnailBackground={'transparent'}
                classes="radius"
              />
              <span>{event.name}</span>
            </div>
          </div>
        </td>
        <DateTCell date={event.date}/>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              {event.roles.map(role => role.name).join(', ')}
            </div>
          </div>
        </td>
        <td className="text-right">
          <GhostButtonControl to={`/event/edit/${event.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredEvents: Array<EventDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.events ? this.state.events : []).sort(this.state.tableSort.sortByColumn).filter((event: EventDTO) => event.deleted === this.state.showDeleted);
    return (
      <>
        <BreadcrumbControls
          pageTitle="events"
          status={this.state.status}
        />
        {this.state.events && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showDeleted"
                name="showDeleted"
                changeMethod={this.toggleDisabled}
                isChecked={this.state.showDeleted}
                labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
              />
              <div className="form-group">
                <Link to='/event/add' className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add event</span></Link>
              </div>
            </ToolbarControls>

            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                    <div className={`
                        card
                        ${!this.state.events ? 'loader-border' : ''}
                        ${this.state.showDeleted ? "flashing-border" : ""}
                      `}
                    >
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.events &&
                        <React.Fragment>
                          <tbody>
                            {filteredEvents.map(event => (
                              this.renderTBodyRow(event)
                            ))}
                          </tbody>
                          <tfoot>
                            {this.state.events?.length === 0 &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">There are no events defined. Start by <Link className="link-button" to="/event/add">adding</Link> the first one.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredEvents.length} out of {this.state.events.filter((event: EventDTO) => event.deleted === this.state.showDeleted).length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withTransitionEvent(EventList);