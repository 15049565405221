import React, { ChangeEvent, Component } from 'react';
import { Link, RouteComponentProps, Route, NavLink } from "react-router-dom";
import UserDTO from '../../../common/api/dtos/User';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import PasswordForm from '../../forms/PasswordForm/PasswordForm';
import AccountInfo from '../../widgets/AccountInfo/AccountInfo';
import Thumbnail  from '../../utils/Thumbnail/Thumbnail';
import AppContext from '../../../common/context/AppContext';
import Fetch from '../../../common/helpers/Fetch';
import moment from 'moment';
import ContractsForm from '../../forms/ContractsForm/ContractsForm';
import AvatarGenerator from '../../generics/AvatarGenerator/AvatarGenerator';
import BenefitsBoard from '../Benefits/BenefitsBoard/BenefitsBoard';
import GearList from '../Gear/GearList/GearList';
import { listUserDetails, listUsers } from '../../../common/api/endpoints/users';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import SuspensionsForm from '../../forms/SuspensionsForm/SuspensionsForm';
import CompensationsForm from '../../forms/CompensationsForm/CompensationsForm';
import { withTransitionEvent } from '../../TransitionEvent';
import { RouteParams } from '../Remotes/Remotes';

interface Form {
  currentPassword: string,
  newPassword: string,
  confirmPassword: string,
}

interface RequestPayload {
  statusCode: number,
  message: string,
}

interface State {
  form: Form,
  formStatus: TRequestStatus,
  invalidPasswordMatch: boolean,
  formPayload: RequestPayload,
  status: TRequestStatus,
  user?: UserDTO,
}

export interface Props extends RouteComponentProps<RouteParams> {
  setLoggedUser: (loggedUser: LoggedUser) => void
  user?: UserDTO,
  loggedUser?: LoggedUser,
}

class Preferences extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      status: 'loading',
      invalidPasswordMatch: true,
      formStatus: 'idle',
      formPayload: {
        statusCode: -1,
        message: '',
      },
    }
  }

  fetchUserDetails = async () => {
    this.setState({
      status: 'loading'
    });
    
    const user = await listUserDetails(this.context.loggedUser?.id);

    this.setState({
        user,
        status: 'success',
    });
  }

  componentDidMount() {
    this.fetchUserDetails();
  }

  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.target.name]: event.target.checked,
    } as any);
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
  }

  getContextPanel() {
    return [
      {
        icon: 'fal fa-user',
        navLink: 'profile-details',
        navName: 'Profile Details',
        description: 'Edit profile information',
        disabled: false,
        page: (
          <React.Fragment>
            <AccountInfo />
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-image',
        navLink: 'avatar',
        navName: 'Avatar Generator',
        description: 'Customize your avatar',
        disabled: false,
        page: (
          <React.Fragment>
            {this.props.loggedUser && 
            <AvatarGenerator
              setLoggedUser={this.props.setLoggedUser} 
              loggedUser={this.props.loggedUser} />
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-lock',
        navLink: 'password',
        navName: 'Password Settings',
        description: 'Manage your password',
        disabled: false,
        page: (
          <React.Fragment>
            <PasswordForm
              enforced={true}
            />
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-coins',
        navLink: 'compensations',
        navName: 'Compensated Days',
        description: 'View paid days for which you requested compensatory payment',
        disabled: false,
        page: (
          <React.Fragment>
            {this.props.loggedUser?.id && 
              <CompensationsForm
                entityId={this.props.loggedUser?.id}
                entity={'user'}
              />
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-pause',
        navLink: 'suspensions',
        navName: 'Suspension Periods',
        description: 'View periods of time when your contracts were suspended',
        disabled: false,
        page: (
          <React.Fragment>
            {this.props.loggedUser?.id && 
              <SuspensionsForm
                entityId={this.props.loggedUser?.id}
                entity={'user'}
              />
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-file-contract',
        navLink: 'signed-contracts',
        navName: 'Signed Contracts',
        description: 'View summaries for signed contracts',
        disabled: false,
        page: (
          <React.Fragment>
            {this.props.loggedUser?.id && 
              <ContractsForm
                entityId={this.props.loggedUser?.id}
                entity={'user'}
              />
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-box',
        navLink: 'my-items',
        navName: 'Assigned Items',
        description: 'View inventory items assigned to you',
        disabled: false,
        page: (
        <React.Fragment>
          <GearList />
        </React.Fragment>
        )
      },
      {
        icon: 'fal fa-gift',
        navLink: 'benefits',
        navName: 'Active Benefits',
        description: 'View your active benefits',
        disabled: false,
        page: (
          <React.Fragment>
            <BenefitsBoard />
          </React.Fragment>
        )
      },
    ]
  }

  checkNestedContext = () => {
    const isNested = this.getContextPanel().some(context => this.props.location.pathname.includes(context.navLink));
    return isNested;
  }

  getContextName = () => {
    const contextName = this.getContextPanel().find(context => context.navLink === this.props.location.pathname.split('/').pop())?.navName;
    return contextName ? contextName : '';
  }

  render() {
    return (
      <div>
        {this.checkNestedContext() === false ?
          <BreadcrumbControls
            items={[
              {label: 'account'}
            ]}
          />
        :
          <BreadcrumbControls
            items={[
              {label: 'account', path: '/account'},
              {label: this.getContextName()},
            ]}
          />
        }
        <div className="flex-row tightest-top">
          <div className="column large">
            <div className="card-list-component flex-row tightest-top flex-v-center">
              <div className="column">
                <div className="card transparent">
                  <Thumbnail
                    avatarData={this.props.loggedUser?.avatar}
                    classes="medium"
                  />
                  <div className="text-container story">
                    <span className="primary-title">{this.state.user?.fullName ? this.state.user?.fullName : this.state.user !== null ? "Unknown" : ""}</span><br />
                    <span>{this.props.loggedUser?.email}</span><br />
                    <span>{this.state.user?.phone ? this.state.user?.phone : this.state.user !== null ? "No phone number" : ""}</span><br />
                  </div>
                </div>
              </div>
            </div>
            {this.getContextPanel().map((context, index) => {
                if(this.checkNestedContext() === true) {
                  return (
                    <Route
                      path={`${this.props.match.url}/${context.navLink}`}
                      render={() => context.page}
                    />
                  )
                } else {
                  return (
                    <React.Fragment key={index}>
                      <div className="form-group tight anim-pull-right" id={`preferences${index}`}>
                        {!context.disabled ?
                          <NavLink to={`${this.props.match.url}/${context.navLink}`} className='card-button'>
                            <span className={`icon ${context.icon}`}></span>
                            <span className="text-container">
                              {context.navName}
                              <br />
                              <small className='faint-text'>{context.description}</small>
                            </span>
                            <span className="fas fa-angle-right"></span>
                          </NavLink>
                        :
                          <div className='card-button disabled'>
                            <span className={`icon ${context.icon}`}></span>
                            <span className="text-container">
                              {context.navName}
                              <br />
                              <small className='faint-text'>{context.description}</small>
                            </span>
                          </div>
                        }
                      </div>
                    </React.Fragment>
                  )
                }
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(Preferences);
Preferences.contextType = AppContext;
