export const AvatarProps = {
    bgColor: [
              {colorName: "cerise-red", colorHex: "#D8345E"},
              {colorName: "crimson-red", colorHex: "#DF1D41"},
              {colorName: "lake-red", colorHex: "#E22828"},
              {colorName: "cadmium-orange", colorHex: "#ED813A"},
              {colorName: "jonquil-yellow", colorHex: "#FAD218"},
              {colorName: "sun-yellow", colorHex: "#F7E520"},
              {colorName: "pear-green", colorHex: "#D4E32C"},
              {colorName: "lime-green", colorHex: "#4ACB40"},
              {colorName: "mountain-green", colorHex: "#1BB891"},
              {colorName: "maximum-blue", colorHex: "#25C7B6"},
              {colorName: "turquiose-blue", colorHex: "#49D7F0"},
              {colorName: "cornflower-blue", colorHex: "#5391E7"},
              {colorName: "purpureus-purple", colorHex: "#8F3EB9"},
              {colorName: "deep-fuchsia", colorHex: "#BD62B6"},
              {colorName: "space-gray", colorHex: "#444444"},
              {colorName: "light-gray", colorHex: "#CCCCCC"},
              {colorName: "default-color", colorHex: "#AAAAAA"},
            ],
    skinTone: [
                {colorName: "white", colorHex: "#fbd9a7"},
                {colorName: "pink", colorHex: "#fbcba7"},
                {colorName: "brown", colorHex: "#ddae84"},
                {colorName: "black", colorHex: "#674d36"}
              ],
    faceShape: [
                "round",
                "long"
               ],
    hairStyle: [
                "",
                "buzzcut",
                "clean",
                "overtop",
                "plainbangs",
                "sidecut",
                "shortbangs",
                "spikybangs",
                "spiky",
                "wavybangs",
                "tied",
                "short_bangs",
                "medium",
                "short_sidebangs",
                "short_wavy",
                "dreads",
                "long_bangs",
                "long_knot",
                "long_ponytail",
                "long_sidebangs",
                "long_wavy",
                "longhair",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "stella",
              ],
    hairColor: [
      {colorName: "silver", colorHex: "#999999"},
      {colorName: "dark", colorHex: "#333333"},
      {colorName: "brown", colorHex: "#806229"},
      {colorName: "blonde", colorHex: "#c59a25"},
      {colorName: "red", colorHex: "#bc4d0a"},
      {colorName: "pink", colorHex: "#bc0ab1"},
      {colorName: "green", colorHex: "#6bbc0a"},
      {colorName: "blue", colorHex: "#0a9bbc"},
              ],
    beardStyle: [
     "",
     "small",
     "low",
     "high",
     "full",
     "moustache",
     "stubble",
     "stubble_sideburns",
     "stubble_moustache",
     "sideburns",
     "sideburns_moustache",
    ],
    beardColor: [
      {colorName: "silver", colorHex: "#999999"},
      {colorName: "dark", colorHex: "#333333"},
      {colorName: "brown", colorHex: "#806229"},
      {colorName: "blonde", colorHex: "#c59a25"},
      {colorName: "red", colorHex: "#bc4d0a"},
      {colorName: "green", colorHex: "#6bbc0a"},
      // {colorName: "blue", colorHex: "#0a9bbc"},
      // {colorName: "pink", colorHex: "#bc0ab1"},
    ],
    mouthStyle: [
      "frown-redgloss-small",
      "frown-small",
      "grin-extrawide",
      "grin-redgloss-extrawide",
      "sad-shut-redgloss",
      "sad-shut",
      "smile-extrawide",
      "smile-redgloss-extrawide",
      "smile-redgloss-shut",
      "smile-redgloss-wide",
      "smile-redgloss",
      "smile-shut-blush",
      "smile-shut-redgloss-blush",
      "smile-shut",
      "smile-wide-blush",
      "smile-wide-redgloss-blush",
      "smile-wide",
      "smirk-redgloss-extrawide",
      "smirk-redgloss-shut-large",
      "smirk-redgloss-shut",
      "smirk-redgloss-wide",
      "smirk-shut-large",
    ],
    eyewear: [
              "",
              "glasses-01",
              "glasses-02",
              "glasses-03",
              "glasses-04",
              "glasses-05",
              "glasses-06",
              "glasses-07",
              "glasses-08",
              "glasses-09",
              "glasses-10",
              "glasses-11",
              "glasses-12",
              "glasses-13",
              "glasses-14",
              "glasses-15",
              "glasses-16",
              "glasses-17",
              "glasses-18",
              "glasses-19",
              "glasses-20",
              "glasses-21",
              "glasses-22",
              "glasses-23",
              "glasses-24",
              "glasses-25",
              "glasses-26",
              "glasses-27",
            ],
    eyesColor: ["silver",
                "amber",
                "green",
                "dark-blue",
                "magenta",
                "brown",
                "black",
              ],
    shirts: [
            "ava1.png",
             "ava2.png",
             "ava3.png",
             "ava4.png",
             "ava5.png",
             "ava6.png",
             "ava7.png",
             "ava8.png",
             "ava9.png",
             "ava10.png",
             "ava11.png",
             "ava12.png",
             "ava13.png",
             "ava14.png",
             "ava15.png",
             "ava16.png",
             "ava17.png",
             "ava18.png",
             "ava19.png",
             "ava20.png",
             "ava21.png",
             "ava22.png",
             "ava23.png",
             "ava24.png",
             "ava25.png",
             "ava26.png",
             "ava27.png",
             "ava28.png",
             "ava29.png",
             "ava30.png",
             "ava31.png",
             "ava32.png",
             "ava33.png",
             "ava34.png",
             "ava35.png",
             "ava36.png",
             "ava37.png",
             "ava38.png",
             "ava39.png",
             "ava40.png",
             "ava41.png",
             "ava42.png",
             "ava43.png",
             "ava44.png",
             "ava45.png"
            ],
  }
