import Fetch from "../../helpers/Fetch";
import ProjectDTO from "../dtos/Project";
import { WorklogDTO } from "../dtos/Worklog";

export interface WorklogHoursAllByPeriod {
  workLog_date: string,
  workLog_unbillable: number,
  workLog_billable: number,
  userId: number,
}

interface FastLoggers {
  user_id: number,
  user_name: string,
  user_avatar: string,
  secondsPassed: number,
}

export async function getFastLoggers(from: Date, until: Date): Promise<FastLoggers[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch(`/worklogs/fast-loggers`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      from,
      until,
    })
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch all worklog hours for period`);
}

export async function getFastLoggersAvg(from: Date, until: Date): Promise<FastLoggers[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch(`/worklogs/fast-loggers-avg`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      from,
      until,
    })
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch all worklog hours for period`);
}

export async function getAllWorklogHoursByPeriod(from: Date, until: Date): Promise<WorklogHoursAllByPeriod[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch(`/worklogs/hours-all-by-period`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      from,
      until,
    })
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch all worklog hours for period`);
}

interface CreateWorklogInput {
  comment: string,
  date: Date,
  hours: string,
  project: { id: number },
  user: { id: number },
}

export async function updateWorklog(id: number, input: CreateWorklogInput) {
  const token = localStorage.getItem('id_token');
  const fetchRequest  = new Fetch<WorklogDTO>(`/worklogs/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },

    body: JSON.stringify({
      comments: input.comment,
      date: input.date,
      hours: input.hours,
      project: input.project,
      user: input.user,
    })
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to post worklog`);
}

export async function createWorklog(input: CreateWorklogInput) {
  const token = localStorage.getItem('id_token');
  const fetchRequest  = new Fetch<WorklogDTO>(`/worklogs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },

    body: JSON.stringify({
      comments: input.comment,
      date: input.date,
      hours: input.hours,
      project: input.project,
      user: input.user,
    })
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to post worklog`);
}

interface FilterWorklogsInput {
  uid: number,
  from: Date,
  until: Date,
  billable: boolean | null,
}

export interface FilterWorklogsItem {
  comments: string,
  date: string,
  hours: string,
  id: number,
  project: ProjectDTO
}

export async function filterWorklogs(filter: FilterWorklogsInput) {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<FilterWorklogsItem[]>(`/worklogs/filter`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(filter)
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch worklogs for user with id ${filter.uid}`);
}

export async function filterUserActivityWorklogs(filter: {
  users: number[],
  projects: number[],
  from: Date,
  until: Date,
}) {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<string[]>(`/worklogs/user-activity-filter`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(filter)
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch worklogs for user activity`);
}

export async function getWorklogsReport(clientId: number, from: Date, until: Date, billable?: boolean) {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<WorklogDTO[]>(`/worklogs/report`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      cid: clientId,
      from,
      until,
      billable
    })
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch worklogs for client with id ${clientId}`);
}

export async function fetchWorklogsByClientUser(clientId: number, from: Date, until: Date, billable?: boolean) {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<{workLog_hours: number, userId: number}[]>(`/worklogs/byClientUser/`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      cid: clientId,
      from,
      until,
      billable,
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch worklogs for client with id ${clientId}`);
}

export async function fetchWorklogsBilabillity(userIds: number[], dateRanges: {from: Date, until: Date}[]) {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<{days: {day: number, billableHours: number, unbillableHours: number}[]}>(`/worklogs/bilabillity`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      userIds,
      dateRanges,
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();
  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch worklogs bilability`);
}
