import React, { Component } from 'react';
import UserDTO from '../../../common/api/dtos/User';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import RequestStatus from '../../utils/RequestStatus/RequestStatus';
import GenericCard from '../../utils/GenericCard/GenericCard';
import AppContext from '../../../common/context/AppContext';
import Thumbnail  from '../../utils/Thumbnail/Thumbnail';
import BadgeDTO from '../../../common/api/dtos/Badge';
import { listTeam } from '../../../common/api/endpoints/users';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import { IProgress } from '../../utils/ProgressBar/ProgressBar';
import TableSort from '../../../common/helpers/TableSort';
import { withTransitionEvent } from '../../TransitionEvent';

export interface Props {

}

interface Phone {
  uid: number,
  phone: string
}

interface State {
  progress: IProgress,
  status: TRequestStatus,
  phones: Phone[],
  users: UserDTO[],
  tableSort: TableSort,
}

class Team extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: 'loading',
      tableSort: new TableSort('name', 'asc'),
      phones: [],
      users: []
    }
  }

  
  fetchAllAssignedBadges = async () => {
    this.setState({
      status: 'loading'
    });
    
    const users = await listTeam();

    this.setState({
      status: 'success',
      users: users.length ? users : [],
    });
  }

  evalURLAccess(user: UserDTO) {
    const userRole = this.context.loggedUser?.role.name;
    if(userRole === 'Admin' || userRole === 'Manager') {
      return `/user/edit/${user.id}`;
    } else {
      return null;
    }
  }

  filterUsers(userProps: UserDTO) {
    return userProps.invisible === false;
  }

  componentDidMount() {
    this.fetchAllAssignedBadges();
  }

  renderPhone = (phone: string | undefined) => {
    if(phone && phone.length) {
      return (
        <>
          &nbsp;or by calling <strong>{phone}</strong>
        </>
      )
    }
  }

  renderBadges = (badges: BadgeDTO[] | undefined) => {
    if(badges && badges.length) {
      return (
        badges.map((badge: BadgeDTO) => (
          <>
            {badge.enabled &&
              <div className="badge-component">
                <Thumbnail
                  thumbnailURL={badge.iconURL ? badge.iconURL : ''}
                  classes="small mr-small radius"
                  thumbnailBackground='transparent'
                />
              </div>
            }
          </>
        ))
      )
    }
  }

  render() {
    return (
      <div>
        <BreadcrumbControls
          pageTitle="Team"
          status={this.state.status}
        />
        <ul className="team-structure card-list-component flex-row tightest">
          {this.state.users.filter((user: UserDTO) => this.filterUsers(user)).sort(this.state.tableSort.sortByColumn).map((user: UserDTO) => (
            <li id={`user${user.id}`} key={user.id} className="column large">
              <GenericCard   
                avatarData={user.avatar}
                cardTitle={user.name}
                internalURL={this.evalURLAccess(user)}
              >
                <>
                  has <strong>{user.role?.name}</strong> access rights 
                  {user.enabled &&
                      <>
                      , and can be reached
                        by sending an email at <a href={`mailto:${user.email}`} className="link-button"><strong>{user.email}</strong></a>
                        {this.renderPhone(user.phone)}.
                        <div className="flex-row fill">
                          <div className="column">
                            {this.renderBadges(user.badges)}
                          </div>
                        </div>
                      </>
                  }
                  </>
              </GenericCard>
            </li>
          ))}
        </ul>
        <br />
      </div>
    )
  };
}

export default withTransitionEvent(Team);

Team.contextType = AppContext;