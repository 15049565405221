import Fetch from "../../helpers/Fetch";
import TenantDTO from "../dtos/Tenant";

interface AddPayload {
  name: string,
  logoURL: string,
  legalName: string,
  tradeNo: string,
  taxId: string,
  shareCapital: string,
  streetAddress: string,
  city: string,
  state: string,
  country: string,
  contactEmail: string,
  zipCode: string,
}

export async function listTenants(): Promise<TenantDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<TenantDTO[]>(`/tenant`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch tenants`);
}


export async function listTenantDetails(tenantId: number): Promise<TenantDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<TenantDTO>(`/tenant/${tenantId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch tenant details');
}

export async function addTenant(form: AddPayload): Promise<TenantDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<TenantDTO>(`/tenant`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add tenant`);
}

export async function updateTenant(form: AddPayload, tenantId: number): Promise<TenantDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<TenantDTO>(`/tenant/${tenantId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });


  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update client`);
}