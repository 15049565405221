import Fetch from "../../helpers/Fetch";


export interface ControlFlagDTO {
    identifier: string;
    name: string;
    description: string;
}

export interface EndpointDTO {
    identifier: string;
    description: string;
    response: EndpointResponseDTO;
    controlFlags: ControlFlagDTO[];
}

export type EndpointResponseDTO =
    | {
          type: 'object';
          nullable: boolean;
          objectType: { [key: string]: EndpointResponseDTO };
      }
    | {
          type: 'array';
          nullable: boolean;
          itemType: EndpointResponseDTO;
      }
    | {
          /**
           * hidden - hidden properties can't have their content masked. This is intended
           * for recursive types and the likes where masking wouldn't be possible with the
           * current type system.
           */
          type: 'string' | 'number' | 'boolean' | 'hidden';
          nullable: boolean;
      };

export async function listEndpoints(): Promise<EndpointDTO[]> {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch<EndpointDTO[]>(`/endpoints`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    });
    fetchRequest.baseUrl = process.env.REACT_APP_API_NEW_BASE_URL;

    await fetchRequest.fetch();

    const status = fetchRequest.getStatus();

    if (status === 'success') {
      return fetchRequest.getPayload();
    }
    throw new Error('Failed to fetch roles');
  }