import moment from "moment";
import React, { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import BadgeDTO from "../../../common/api/dtos/Badge";
import { listBadges } from "../../../common/api/endpoints/badges";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import Thumbnail  from "../../utils/Thumbnail/Thumbnail";
import TextControl from "../../controls/TextControl/TextControl";
import RequestStatus from "../../utils/RequestStatus/RequestStatus";
import SortButton from "../../utils/SortButton/SortButton";
import ToolbarControls from "../../generics/Header/ToolbarControls";
import { listAssignedBadges, updateAssignedBadges } from "../../../common/api/endpoints/users";

interface Props {
  editUserId: number,
}

interface State {
  userPropId: number | undefined | null,
  status: TRequestStatus,
  formStatus: TRequestStatus,
  badges?: BadgeDTO[],
  showDisabled: boolean,
  showOnlyAssignedBadges: boolean,
  tableSort: TableSort,
	formData: FormData,
  filterValue: string,
}

interface FormData {
  badges: BadgeDTO[],
}

class BadgesForm extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'iconURL',
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      formStatus: 'idle',
      formData: {
        badges: [],
      },
      userPropId: undefined,
      tableSort: new TableSort('name', 'asc'),
      showDisabled: localStorage.getItem('showDisabledBadges') === 'false' || localStorage.getItem('showDisabledBadges') === null ? false : true,
      showOnlyAssignedBadges: false,
      filterValue: '',
    }
  }

  fetchBadges = async () => {
    this.setState({
      status: 'loading'
    });
    
    const badges = await listBadges();

    this.setState({
        status: 'success',
        badges: badges,
    });
  }

  fetchAssignedBadges = async () => {
    this.setState({
      status: 'loading'
    });
    
    const assignedBadges = await listAssignedBadges(this.props.editUserId);

    this.setState({
      status: 'success',
      formData: {
        badges: assignedBadges.length ? assignedBadges : [],
      }
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({ showDisabled: false }) : this.setState({ showDisabled: true });
  }

  toggleVisible = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showOnlyAssignedBadges ? this.setState({ showOnlyAssignedBadges: false }) : this.setState({ showOnlyAssignedBadges: true });
  }

  checkIfAssigned = (badgeId: number): boolean => {
    return this.state.formData.badges.map((badge: BadgeDTO) => { return badge.id; }).indexOf(badgeId) === -1 ? false : true;
  }

  componentDidMount() {
    this.fetchBadges();
    this.fetchAssignedBadges();
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });

    await updateAssignedBadges(
      this.state.formData, 
      this.props.editUserId
    );

    this.setState({
        formStatus: 'success',
    });

  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  setAssigned = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentProjectId: number = Number(ev.target.value);
    this.setState((prevState: State) => {
      let assignedBadges = prevState.formData.badges.slice(0);
      let alreadyAssigned = assignedBadges.map((badge: BadgeDTO) => { return badge.id; }).indexOf(currentProjectId);

      if(alreadyAssigned === -1) {
        let selectedProject = this.state.badges?.filter((badge: BadgeDTO) => badge.id === currentProjectId)[0];
        if(selectedProject) {
          assignedBadges.push(selectedProject);
        }
      } else {
        assignedBadges.splice(alreadyAssigned, 1);
      }
      
      return {
        formData: {
          ...prevState.formData,
          badges: assignedBadges
        }
      }
    });
    
  }

  addAllListed = () => {
    const filteredItems = this.getFilteredBadges();

    this.setState((prevState: State) => {
      const existingIds = prevState.formData.badges.map(e => e.id);
      const newItems = filteredItems.filter(item => !existingIds.includes(item.id));
      return {
        formData: {
          ...prevState.formData,
          badges: [...prevState.formData.badges, ...newItems]
        }
      }
    });
  }

  removeAllListed = () => {
    const filteredItems = this.getFilteredBadges();

    this.setState((prevState: State) => {
      const filteredIds = filteredItems.map(u => u.id);
      return {
        formData: {
          ...prevState.formData,
          badges: prevState.formData.badges.filter((element) => !filteredIds.includes(element.id))
        }
      }
    });
  }

  getFilteredBadges() {
    return getFilteredItems(this.state.filterValue, this.availableFilters, this.state.badges ? this.state.badges : []).sort(this.state.tableSort.sortByColumn).filter((badge: BadgeDTO) => (badge.enabled === true || this.state.showDisabled) && (this.state.showOnlyAssignedBadges === false || this.checkIfAssigned(badge.id)))
  }

  renderToggler = () => {
    if(!this.state.badges) {
      return;
    }

    const filteredItems = this.getFilteredBadges();
    const formIds = this.state.formData.badges.map(u => u.id);
    const diffAdd = filteredItems.filter(item => !formIds.includes(item.id)).length;
    const diffRemove = filteredItems.filter(item => formIds.includes(item.id)).length;

    return (
      <div className="flex-row tight flex-v-center squeeze">
        <div className="column">
          <button
            className="primary-button"
            onClick={this.addAllListed}
            disabled={!diffAdd}
            >
            Add listed {`(${diffAdd})`}
          </button>
        </div>
        <div className="column">
          <button
            className="primary-button"
            onClick={this.removeAllListed}
            disabled={!diffRemove}
          >
            Remove listed {`(${diffRemove})`}
          </button>
        </div>
      </div>
    )
  }

  render() {
    const filteredBadges = this.getFilteredBadges();
    const totalBadges = this.state.badges?.filter((badge: BadgeDTO) =>  badge.enabled === true || this.state.showDisabled)
    const totalAssignedBadges = this.state.badges?.filter((badge: BadgeDTO) => this.checkIfAssigned(badge.id));

    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
            <ToggleControl 
              id="showDisabledBadges"
              name="showDisabledBadges" 
              changeMethod={this.toggleDisabled} 
              isChecked={this.state.showDisabled}
              labelText="Disabled"
            />
            <ToggleControl 
              id="showOnlyAssignedBadges"
              name="showOnlyAssignedBadges" 
              changeMethod={this.toggleVisible} 
              isChecked={this.state.showOnlyAssignedBadges}
              labelText="Assigned"
            />
          </ToolbarControls>
        </div>
        <div className={`card ${!this.state.badges ? 'loader-border' : ''}`}>
          {this.renderToggler()}
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 315px)'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="text-left">
                          <SortButton
                            column='name'
                            text='Name'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th className="text-left">
                          <SortButton
                            column='description'
                            text='Description'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        {this.state.showDisabled &&
                          <th className="text-center">
                            <SortButton
                              column='enabled'
                              text='Enabled'
                              tableSort={this.state.tableSort}
                              onClick={this.handleSortChange}
                            ></SortButton>
                          </th>
                        }
                        <th className="text-center">
                          Awarded
                        </th>
                      </tr>
                    </thead>
                    {this.state.badges &&
                      <React.Fragment>
                        <tbody>
                          {this.getFilteredBadges().map(badge => (
                            <React.Fragment>
                              <tr id={`badge${badge.id}`} key={badge.id}>
                                <td></td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center">
                                      <Thumbnail
                                        thumbnailURL={badge.iconURL}
                                        thumbnailBackground={'transparent'}
                                        classes="release"
                                      />
                                      <span>{badge.name}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <p className="text-chunk">{badge.description}</p>
                                </td>
                                {this.state.showDisabled &&
                                  <td className="text-center">
                                    {badge.enabled &&
                                      <span className="fas fa-check"></span>
                                    }
                                  </td>
                                }
                                <td className="text-center">
                                  <ToggleControl 
                                    id={`badge-${badge.id}`}
                                    name={`badge-${badge.id}`} 
                                    changeMethod={this.setAssigned} 
                                    isChecked={this.checkIfAssigned(badge.id)}
                                    value={String(badge.id)}
                                    labelText={this.checkIfAssigned(badge.id) ? 'ON' : 'OFF'}
                                    classes="text-center"
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.badges?.length === 0 &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk">There are no badges defined. Start by <Link className="link-button" to="/badges/add">adding</Link> the first one.</p>
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                   <p className="text-chunk">Showing {filteredBadges.length} out of {this.state.showOnlyAssignedBadges ? totalAssignedBadges?.length : totalBadges?.length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
            <button
              className="primary-button"
              onClick={this.updateEntity}
              disabled={Boolean(!this.state.badges)}
            >
              <RequestStatus status={this.state.formStatus} />
              <span className="text">Save</span>
            </button>
          </div>
      </div>
    );
  }
}

export default BadgesForm;