import React from "react";

interface Props {
  id?: number,
  children: React.ReactNode,
  rowHeight: number;
  numberOfPrerenders: number
}

interface State {
  isInView: boolean
}

export default class VirtualTableRow extends React.Component<Props, State> {
  private rowRef: React.RefObject<HTMLTableRowElement>;
  private observer: IntersectionObserver;
  private topMargin: number;
  private bottomMargin: number;

  constructor(props: Props) {
    super(props);
    this.rowRef = React.createRef();
    this.topMargin = this.props.rowHeight * this.props.numberOfPrerenders;
    this.bottomMargin = this.topMargin;
    this.observer = new IntersectionObserver(() => {});
    this.state = {
      isInView: false,
    }
  }

  setIsInView = (isInView: boolean) => {
    this.setState({
      isInView 
    })
  }

  componentDidMount() {
    this.observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					this.setIsInView(true)
				} else {
					this.setIsInView(false)
				}
			})
    }, {
      root: document.querySelector(".v-scroll"),
      rootMargin: `
        ${this.topMargin}px 
        0px 
        ${this.bottomMargin}px
      `
    })

    this.observer.observe(this.rowRef.current!);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    return (
      <tr 
        id={`item${this.props.id}`}
        ref={this.rowRef}
        style={{
          height: this.props.rowHeight 
        }}
      >
        {this.state.isInView ? this.props.children : null}
      </tr>
    )
  }
}