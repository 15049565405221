import { TimeScale } from 'chart.js';
import Joi, { boolean } from 'joi';
import React, { ChangeEvent, Component } from 'react';
import { Route, RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import TenantDTO from '../../../../common/api/dtos/Tenant';
import { addTenant, listTenantDetails, updateTenant } from '../../../../common/api/endpoints/tenants';
import { FormErrors } from '../../../../common/data/FormErrors';
import Fetch from '../../../../common/helpers/Fetch';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import ContractsForm from '../../../forms/ContractsForm/ContractsForm';
import Thumbnail  from '../../../utils/Thumbnail/Thumbnail';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import PayprofilesForm from '../../../forms/PayprofilesForm/PayprofilesForm';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TextControl from '../../../controls/TextControl/TextControl';
import { listTenantPayprofiles } from '../../../../common/api/endpoints/payprofiles';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { withTransitionEvent } from '../../../TransitionEvent';
import DeepFlowOption from '../../../utils/DeepFlopOption/DeepFlowOption';

export interface Props {
  id: string,
}

interface FormData {
  name: string,
  logoURL: string,
  legalName: string,
  tradeNo: string,
  taxId: string,
  shareCapital: string,
  streetAddress: string,
  city: string,
  state: string,
  country: string,
  contactEmail: string,
  zipCode: string,
  enabled: boolean,
}

interface State {
  tenantId: number,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  formData: FormData,
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

class TenantDetail extends Component<RouteComponentProps<Props>, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    logoURL: Joi.string().trim(true).allow(null, ''),
    legalName: Joi.string().trim(true).required().messages(FormErrors.string),
    tradeNo: Joi.string().trim(true).required().messages(FormErrors.string),
    taxId: Joi.string().trim(true).required().messages(FormErrors.string),
    shareCapital: Joi.string().trim(true).required().messages(FormErrors.string),
    streetAddress: Joi.string().trim(true).required().messages(FormErrors.string),
    city: Joi.string().trim(true).required().messages(FormErrors.string),
    state: Joi.string().trim(true).required().messages(FormErrors.string),
    country: Joi.string().trim(true).required().messages(FormErrors.string),
    contactEmail: Joi.string().trim(true).email({ minDomainSegments: 2, tlds: false}).allow(null, '').messages(FormErrors.string),
    zipCode: Joi.string().trim(true).max(6).required().messages(FormErrors.string),
    enabled: Joi.boolean(),
  });

  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {
      tenantId: parseInt(this.props.match.params.id),
      formData: {
        name: '',
        logoURL: '',
        legalName: '',
        tradeNo: '',
        taxId: '',
        shareCapital: '',
        streetAddress: '',
        city: '',
        state: '',
        country: '',
        contactEmail: '',
        zipCode: '',
        enabled: false,
      },
      progress: {
        currentStep: 0,
        totalSteps: 0
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchTenantDetails = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const tenant = await listTenantDetails(this.state.tenantId);
    this.setState(prevState => {
      return {
        formData: {
          name: tenant.name,
          logoURL: tenant.logoURL,
          legalName: tenant.legalName,
          tradeNo: tenant.tradeNo,
          taxId: tenant.taxId,
          shareCapital: tenant.shareCapital,
          streetAddress: tenant.streetAddress,
          city: tenant.city,
          state: tenant.state,
          country: tenant.country,
          contactEmail: tenant.contactEmail,
          zipCode: tenant.zipCode,
          enabled: tenant.enabled,
        },
        progress: incrementProgress(prevState.progress),
        pageStatus: 'success',
      }
    });
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addTenant(this.state.formData);

    this.setState({
        formStatus: 'success',
    }, () => {
      this.props.history.push({
        pathname: '/tenant'
      })
    });
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await updateTenant(this.state.formData, this.state.tenantId);

    this.setState({
        formStatus: 'success',
    });
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
      
      if(!isEditPage(Number(this.state.tenantId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    if(isEditPage(Number(this.state.tenantId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1
          }
        }
      });
      this.fetchTenantDetails()
    } else {
      this.setState({
        pageStatus: "idle",
      })
    }
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value
      }
    })
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setLogoURL = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('logoURL', ev.target.value);
  setLegalName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('legalName', ev.target.value);
  setTradeNo = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('tradeNo', ev.target.value);
  setTaxId = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('taxId', ev.target.value);
  setShareCapital = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('shareCapital', ev.target.value);
  setStreetAddress = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('streetAddress', ev.target.value);
  setCity = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('city', ev.target.value);
  setIState = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('state', ev.target.value);
  setCountry = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('country', ev.target.value);
  setContactEmail = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('contactEmail', ev.target.value);
  setHidden = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('enabled', ev.target.checked)}
  setZipCode = (ev: ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(ev.target.value))) {
      return;
    };

    this.updateFormData('zipCode', ev.target.value);
  };
  
  inDeepFlow = () => {
    const isNested = this.getContextPanel().some(context => this.props.location.pathname.includes(context.navLink));
    return isNested;
  }

  getContextName = () => {
    const contextName = this.getContextPanel().find(context => context.navLink === this.props.location.pathname.split('/').pop())?.navName;
    return contextName ? contextName : '';
  }

  getContextPanel() {
    return [
      {
        icon: 'fal fa-user',
        navLink: 'profile',
        navName: 'Tenant Details',
        description: 'Edit general information for this tenant',
        disabled: false,
        page: this.renderDetailsForm()
      },
      {
        icon: 'fal fa-coins',
        navLink: 'payprofile',
        navName: 'Payprofiles',
        description: 'Manage bank accounts and currencies',
        disabled: false,
        page: (
          <React.Fragment>
              <PayprofilesForm
                entityId={this.state.tenantId}
              />
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-file-contract',
        navLink: 'contract',
        navName: 'Contracts',
        description: 'Manage contracts signed by this tenant',
        disabled: false,
        page: (
          <React.Fragment>
            {this.state.tenantId && 
              <ContractsForm
                entityId={this.state.tenantId} 
                entity={'tenant'}
                hasFullAccess={true}
              />
            }
          </React.Fragment>
        )
      },
    ]
  }

  renderDetailsForm() {
    return(
      <div>
        <div className="card">
          <div className="flex-row fill">
            <div className="column">
              <TextControl
                label="Name"
                type="text"
                name="name"
                id="name"
                value={this.state.formData.name}
                onChange={this.setName}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.name}
              />
              <TextControl
                label="Legal Name"
                type="text"
                name="legalName"
                id="legalName"
                value={this.state.formData.legalName}
                onChange={this.setLegalName}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.legalName}
              />
              <TextControl
                label="Trade No"
                type="text"
                name="tradeNo"
                id="tradeNo"
                value={this.state.formData.tradeNo}
                onChange={this.setTradeNo}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.tradeNo}
              />
              <TextControl
                label="Tax Id"
                type="text"
                name="taxId"
                id="taxId"
                value={this.state.formData.taxId}
                onChange={this.setTaxId}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.taxId}
              />
              <TextControl
                label="Shared Capital"
                type="text"
                name="shareCapital"
                id="shareCapital"
                value={this.state.formData.shareCapital}
                onChange={this.setShareCapital}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.shareCapital}
              />
              <TextControl
                label="Street Address"
                type="text"
                name="streetAddress"
                id="streetAddress"
                value={this.state.formData.streetAddress}
                onChange={this.setStreetAddress}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.streetAddress}
              />
              <ToggleControl 
                name="enabled"
                id="enabled"
                isChecked={this.state.formData.enabled}
                changeMethod={this.setHidden}
                isDisabled={!loadComplete(this.state.progress)}
                labelText="Enabled"
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
              />
            </div>
            <div className="column">
              <TextControl
                label="City"
                type="text"
                name="city"
                id="city"
                value={this.state.formData.city}
                onChange={this.setCity}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.city}
              />
              <TextControl
                label="State"
                type="text"
                name="state"
                id="state"
                value={this.state.formData.state}
                onChange={this.setIState}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.state}
              />
              <TextControl
                label="Country"
                type="text"
                name="country"
                id="country"
                value={this.state.formData.country}
                onChange={this.setCountry}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.country}
              />
              <TextControl
                label="Contact email"
                type="text"
                name="contactEmail"
                id="contactEmail"
                value={this.state.formData.contactEmail}
                onChange={this.setContactEmail}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.contactEmail}
              />
              <TextControl
                label="Zip Code"
                type="text"
                name="zipCode"
                id="zipCode"
                value={this.state.formData.zipCode}
                onChange={this.setZipCode}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.zipCode}
                maxLength={6}
              />
              <div className="form-group">
                <div className="flex-row">
                  <div className="column large">
                    <TextControl
                      label="Icon"
                      type="text"
                      name="logoURL"
                      id="logoURL"
                      value={this.state.formData.logoURL}
                      onChange={this.setLogoURL}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    />
                    <small className="faint-text"><span className="fas fa-info-circle"></span> You can use both relative or absolute paths.</small>
                  </div>
                  <div className="column small flex-v-center">
                    <img
                      className="thumbnail large radius padded"
                      src={this.state.formData.logoURL}
                      alt="avatar"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <ButtonControl
              class="primary-button"
              onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                this.handleSubmit(ev)
              }}
              disabled={!loadComplete(this.state.progress)}
            >
              <RequestStatus status={this.state.formStatus} />
              <span className="text">Save</span>
            </ButtonControl>
          </div>
        </div>
      </div>
    );
  }

  renderEntityHeaders() {
    return(
      <div className="card-list-component flex-row flex-v-center">
        <div className="column">
          <div className="card transparent">
            <Thumbnail
              thumbnailURL={this.state.formData.logoURL}
              classes="medium padded"
            />
            <div className="text-container story">
              <span className="primary-title">{this.state.formData.legalName}</span><br />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card transparent">
            <div className="text-container story">
              <span>{this.state.formData.streetAddress}</span><br />
              <span>{this.state.formData.state}{this.state.formData.state && this.state.formData.country ? ", " : ""}{this.state.formData.country}</span><br />
              <span>{this.state.formData.zipCode}</span><br />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card transparent">
            <div className="text-container story">
              <span>{this.state.formData.tradeNo}</span><br />
              <span>{this.state.formData.taxId}</span><br />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let entityName = this.state.formData.name ? this.state.formData.name : 'loading';
    return (
      <div>
        {this.getContextName() === 'Payprofiles' &&
        <>

        </>
        }
        {this.inDeepFlow() === false ?
          <BreadcrumbControls
            items={[
              {label: 'tenants', path: '/tenant'},
              {label: `${!isEditPage(Number(this.state.tenantId)) ? 'adding tenant ' : ''}${this.state.formData.name}`},
            ]}
          />
        :
          <BreadcrumbControls
            items={[
              {label: 'tenants', path: '/tenant'},
              {label: entityName ? entityName : '', path: `/tenant/edit/${this.state.tenantId}`},
              {label: this.getContextName()},
            ]}
          />
        }
        <div className="flex-row tightest-top">
          <div className="column large">
            <div className="flex-row">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            {isEditPage(Number(this.state.tenantId)) ?
              <>
                {this.renderEntityHeaders()}
                {this.getContextPanel().map((context, index) => {
                  return(
                    this.inDeepFlow() ?
                      <Route
                        path={`${this.props.match.url}/${context.navLink}`}
                        render={() => context.page}
                      />
                    :
                      <DeepFlowOption
                        baseURL={this.props.match.url}
                        context={context}
                        index={index}
                      />
                  )
                })}
              </>
            :
              this.renderDetailsForm()
            }
          </div>
        </div>          
      </div>
    )
  }

}

export default withTransitionEvent(TenantDetail);
