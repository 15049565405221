import React from "react";
import { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import ProcedureDTO from "../../../common/api/dtos/Procedure";
import { listProcedures } from "../../../common/api/endpoints/procedures";
import { listAssignedProcedures } from "../../../common/api/endpoints/users";
import Fetch from "../../../common/helpers/Fetch";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import TextControl from "../../controls/TextControl/TextControl";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import ToolbarControls from "../../generics/Header/ToolbarControls";
import { withTransitionEvent } from "../../TransitionEvent";
import RequestStatus from "../../utils/RequestStatus/RequestStatus";
import SortButton from "../../utils/SortButton/SortButton";

interface Props {
  editUserId: number,
}

interface State {
  userPropId: number | undefined | null, 
  status: TRequestStatus,
  formStatus: TRequestStatus,
  procedures?: ProcedureDTO[],
  showDeleted: boolean,
  showOnlyAssignedProcedures: boolean,
  tableSort: TableSort,
  formData: FormData,
  filterValue: string,
}

interface FormData {
  procedures: ProcedureDTO[],
}

class ProceduresForm extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'category',
    'tags',
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      formStatus: 'idle',
      formData: {
        procedures: [],
      },
      userPropId: undefined,
      tableSort: new TableSort('name', 'asc'),
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      showOnlyAssignedProcedures: false,
      filterValue: '',
    }
  }

  fetchProcedures = async () => {
    this.setState({
      status: 'loading'
    });

    const procedures = await listProcedures();

    this.setState({
      status: 'success',
      procedures: procedures,
    });
  }

  fetchAssignedProcedures = async () => {
    this.setState({
      status: 'loading'
    });
    
    const assignedProcedures = await listAssignedProcedures(this.props.editUserId);

    this.setState({
      status: 'success',
      formData: {
        procedures: assignedProcedures.length ? assignedProcedures : [],
      }
    });
  }

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) => 
  {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({ showDeleted: false}) : this.setState({ showDeleted: true });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  toggleVisible = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showOnlyAssignedProcedures ? this.setState({
      showOnlyAssignedProcedures: false }) : this.setState({
      showOnlyAssignedProcedures: true });
  }

  checkIfAssigned = (procedureId: number): boolean => {
    return this.state.formData.procedures.map((procedure: ProcedureDTO) => { return procedure.id; }).indexOf(procedureId) === -1 ? false : true;
  }

  componentDidMount() {
    this.fetchProcedures();
    this.fetchAssignedProcedures();
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });

    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/user/assigned-procedures/` + this.props.editUserId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(this.state.formData)
    })
    
    await fetchRequest.fetch();

    this.setState({
      formStatus: fetchRequest.getStatus()
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  setAssigned = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentProjectId: number = Number(ev.target.value);
    this.setState((prevState: State) => {
      let assignedProcedures = prevState.formData.procedures.slice(0);
      let alreadyAssigned = assignedProcedures.map((procedure: ProcedureDTO) => { return procedure.id; }).indexOf(currentProjectId);

      if(alreadyAssigned === -1) {
        let selectedProject = this.state.procedures?.filter((procedure: ProcedureDTO) => procedure.id === currentProjectId)[0];
        if(selectedProject) {
          assignedProcedures.push(selectedProject);
        }
      } else {
        assignedProcedures.splice(alreadyAssigned, 1);
      }

      return {
        formData: {
          ...prevState.formData,
          procedures: assignedProcedures
        }
      }
    });
  }

  addAllListed = () => {
    const filteredItems = this.getFilteredProcedures();

    this.setState((prevState: State) => {
      const existingIds = prevState.formData.procedures.map(e => e.id);
      const newItems = filteredItems.filter(item => !existingIds.includes(item.id));
      return {
        formData: {
          ...prevState.formData,
          procedures: [...prevState.formData.procedures, ...newItems]
        }
      }
    });
  }

  removeAllListed = () => {
    const filteredItems = this.getFilteredProcedures();

    this.setState((prevState: State) => {
      const filteredIds = filteredItems.map(u => u.id);
      return {
        formData: {
          ...prevState.formData,
          procedures: prevState.formData.procedures.filter((element) => !filteredIds.includes(element.id))
        }
      }
    });
  }

  getFilteredProcedures() {
    return getFilteredItems(this.state.filterValue, this.availableFilters, this.state.procedures ? this.state.procedures : [])
    .filter((procedure: ProcedureDTO) => 
      (procedure.deleted === this.state.showDeleted) 
      &&
      (this.state.showOnlyAssignedProcedures === false || this.checkIfAssigned(procedure.id))
    );
  }

  renderToggler = () => {
    if(!this.state.procedures) {
      return;
    }

    const filteredItems = this.getFilteredProcedures();
    const formIds = this.state.formData.procedures.map(u => u.id);
    const diffAdd = filteredItems.filter(item => !formIds.includes(item.id)).length;
    const diffRemove = filteredItems.filter(item => formIds.includes(item.id)).length;

    return (
      <div className="flex-row tight squeeze">
        <div className="column">
          <button
            className="primary-button"
            onClick={this.addAllListed}
            disabled={!diffAdd}
            >
            Add listed {`(${diffAdd})`}
          </button>
        </div>
        <div className="column">
          <button
            className="primary-button"
            onClick={this.removeAllListed}
            disabled={!diffRemove}
          >
            Remove listed {`(${diffRemove})`}
          </button>
        </div>
      </div>
    )
  }

  render() {
    const filteredProcedures = this.getFilteredProcedures().sort(this.state.tableSort.sortByColumn);
    const totalProcedures = this.state.procedures?.filter((procedure: ProcedureDTO) => procedure.deleted === this.state.showDeleted);
    const totalAssignedProcedures = this.state.procedures?.filter((procedure: ProcedureDTO) => this.checkIfAssigned(procedure.id));

    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
            <ToggleControl
              id="showDeleted"
              name="showDeleted"
              changeMethod={this.toggleDeleted}
              isChecked={this.state.showDeleted}
              labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
            />
            <ToggleControl
              id="showOnlyAssignedProcedures"
              name="showOnlyAssignedProcedures"
              changeMethod={this.toggleVisible}
              isChecked={this.state.showOnlyAssignedProcedures}
              labelText="Assigned"
            />
          </ToolbarControls>
        </div>
        <div className={`card ${!this.state.procedures ? 'loader-border' : ''} ${this.state.showDeleted ? "flashing-border" : ""}`}>
          {this.renderToggler()}
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 350px'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="text-left">
                          <SortButton
                            column='name'
                            text='Name'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}></SortButton>
                        </th>
                        <th className="text-left">
                          <SortButton
                            column="description"
                            text="Description"
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}></SortButton>
                        </th>
                        <th className="text-left">
                          <SortButton
                            column="tags"
                            text="Tags"
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}></SortButton>
                        </th>
                        <th className="text-center">
                         Assigned
                        </th>
                      </tr>
                    </thead>
                    {this.state.procedures && 
                      <React.Fragment>
                        <tbody>
                          {filteredProcedures.map(procedure => (
                            <React.Fragment>
                              <tr id={`procedure${procedure.id}`} key={`${procedure.id}`}>
                                <td></td>
                                <td>
                                  <span>{procedure.name}</span>
                                </td>
                                <td>
                                  <p className="text-chunk">{procedure.description}</p>
                                </td>
                                <td>
                                  <span>{procedure.tags}</span>
                                </td>
                                <td className="text-center">
                                  <ToggleControl
                                    id={`procedure-${procedure.id}`}
                                    name={`procedure-${procedure.id}`}
                                    changeMethod={this.setAssigned}
                                    isChecked={this.checkIfAssigned(procedure.id)}
                                    value={String(procedure.id)}
                                    labelText={this.checkIfAssigned(procedure.id) ? 'ON' : 'OFF'}
                                    classes="text-center"
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.procedures.length === 0 &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk">There are no procedures defined. Start by <Link className="link-button" to="/procedures-list/add">adding</Link> the first one.</p>
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk">Showing {filteredProcedures.length} out of {this.state.showOnlyAssignedProcedures ? totalAssignedProcedures?.length : totalProcedures?.length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
            <button
              className="primary-button"
              onClick={this.updateEntity}
              disabled={Boolean(!this.state.procedures)}
            >
              <RequestStatus status={this.state.formStatus} />
              <span className="text">Save</span>
            </button>
        </div>
      </div>
    )
  }
}

export default ProceduresForm;