import React, { ChangeEvent, Component } from 'react';

interface Props {
  id: string,
  name: string,
  changeMethod: (event: ChangeEvent<HTMLInputElement>) => void,

  labelText?: string,
  isDisabled?: boolean,
  icon?: string,
  value?: string,
  isChecked?: boolean,
  multiline?: boolean,
  tight?: boolean,
  classes?: string,
  srOnly?: boolean,
  title?: string,

  children?: JSX.Element,
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void,
}

class ToggleControl extends Component<Props> {
  counter: ReturnType<typeof setTimeout>;

  constructor(props: Props) {
    super(props);
    this.counter = setTimeout(() => { }, 0);
  }

  setCounter = () => this.counter = setTimeout((e: any) => this.props.changeMethod(e), 300000)

  eventListener = () => {
    if (this.props.isChecked) {
      clearTimeout(this.counter)
      this.setCounter()
    }
  }

  componentDidMount() {
    if (this.props.name === 'showSensitive') {
      ['keydown', 'mousemove', 'mousedown'].forEach(evt =>
        window.addEventListener(evt, this.eventListener, false)
      )
    }
  }

  componentDidUpdate() {
    if (this.props.name === 'showSensitive') {
      if (this.props.isChecked) {
        this.setCounter()
      } else clearTimeout(this.counter)
    }
  }

  componentWillUnmount() {
    if (this.props.name === 'showSensitive') {
      ['keydown', 'mousemove', 'mousedown'].forEach(evt =>
        window.removeEventListener(evt, this.eventListener, false)
      )
      clearTimeout(this.counter);
    }
  }



  render() {
    return (
      <div className={`form-group ${this.props.tight ? 'tight' : ''}`}>
        <div className={`toggle  ${this.props.classes} ${this.props.multiline ? 'multiline' : ''}`}
        title={this.props.title}>
          <input
            id={this.props.id}
            type="checkbox"
            name={this.props.name}
            onChange={this.props.changeMethod}
            disabled={this.props.isDisabled}
            value={this.props.value}
            checked={this.props.isChecked}
            onBlur={this.props.onBlur}
          />
          {!this.props.multiline ? (
            <label htmlFor={this.props.id}>
              {this.props.srOnly &&
                <span className="sr-only">{this.props.labelText}</span>
              }
              {this.props.icon && 
                <span className={this.props.icon}></span> 
              }
              {!this.props.srOnly &&
                <span>{this.props.labelText}</span>
              }
            </label>
          ) : (
            <>
              {this.props.children}
            </>
          )
          }
        </div>
      </div>
    )
  }
}

export default ToggleControl;
