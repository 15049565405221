import { override } from "joi";
import Fetch from "../../helpers/Fetch";
import GasUtilDTO, { GasUtilPayload } from "../dtos/GasUtil";

export async function listGasUtils(): Promise<GasUtilDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<GasUtilDTO[]>(`/gases`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    const payloadWithDateObj = fetchRequest.getPayload().map(el => ({
      ...el,
      date: new Date(el.year, el.month - 1, 1)
    }));

    return payloadWithDateObj;
  }
  throw new Error('Failed to fetch gasUtils');
}

export async function listGasUtilDetails(gasUtilId: string): Promise<GasUtilDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<GasUtilDTO>(`/gas/${gasUtilId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return { ...fetchRequest.getPayload(), month: fetchRequest.getPayload().month - 1 }
  }
  throw new Error('Failed to fetch gasUtil details');
}

export async function addGasUtil(form: GasUtilPayload): Promise<GasUtilDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<GasUtilDTO>(`/gas`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      ...form,
      month: form.month + 1
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add gasUtil`);
}

export async function updateGasUtil(form: GasUtilPayload, gasUtilId: string): Promise<GasUtilDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<GasUtilDTO>(`/gas/${gasUtilId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      ...form,
      month: form.month + 1
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update gasUtil`);
}