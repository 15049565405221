import React, { ChangeEvent, Component } from 'react';
import { Link } from 'react-router-dom';
import SecucodeDTO from '../../../../common/api/dtos/Secucode';
import { listSecucodes } from '../../../../common/api/endpoints/secucodes';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import errorImage from '../../../../common/helpers/ImageError';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import TextControl from '../../../controls/TextControl/TextControl';
import SortButton from '../../../utils/SortButton/SortButton';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import DateTCell from '../../../utils/DateTCell/DateTCell';
import Thumbnail from '../../../utils/Thumbnail/Thumbnail';
import { withTransitionEvent } from '../../../TransitionEvent';

interface State {
  secucodes?: SecucodeDTO[],
  tableSort: TableSort,
  status: TRequestStatus,
  showDeleted: boolean,
  showSensitive: boolean,
  filterValue: string,
}

class SecucodeList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'user.name',
    'user.fullName',
    'internalId',
    'description',
    'comments'
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      status: 'loading',
      filterValue: '',
      tableSort: new TableSort('name', 'asc'),
      showSensitive: false,
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
    };
  }

  sensitiveTimeout: number = 0;

  fetchSecucodes = async () => {
    this.setState({
      status: 'loading'
    });

    const secucodes = await listSecucodes();

    this.setState({
      status: 'success',
      secucodes: secucodes,
    });
  }

  
  toggleSensitive = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.showSensitive ? this.setState({showSensitive: false}) : this.setState({showSensitive: true});
  }

  showDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({ showDeleted: false }) : this.setState({ showDeleted: true });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchSecucodes();
  }

  componentDidUpdate(prevState: State) {
    if(this.state.showSensitive !== prevState.showSensitive) {
      if(this.state.showSensitive){
        this.sensitiveTimeout = setTimeout(() => {
          this.setState({showSensitive: false});
        }, parseInt(String(process.env.REACT_APP_SENSITIVE_DATA_TIMEOUT))) as unknown as number;
      } else {
        clearTimeout(this.sensitiveTimeout);
      }
    }
  }
  
  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th>
          <SortButton
            column='name'
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column='internalId'
            text='Internal ID'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column='description'
            text='Description'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column='comments'
            text='Comments'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column='lastUpdate'
            text='Last update'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column='user.fullName'
            text='Employee'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(secucode: SecucodeDTO) {
    return (
      <tr id={`secucode${secucode.id}`} key={`${secucode.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <Thumbnail
                thumbnailURL={process.env.REACT_APP_INVENTORY_PATH + secucode.iconUrl}
                thumbnailBackground={secucode.tileColor}
                classes="padded"
              />
              <div>
                <span>{secucode.name}</span>
              </div>
            </div>
          </div>
        </td>
        <td>
          <span>{secucode?.internalId}</span>
        </td>
        <td>
          <p className="text-chunk">{secucode.description}</p>
        </td>
        <td>
          <p className="text-chunk">{secucode.comments}</p>
        </td>
        <DateTCell date={secucode.lastUpdate} />
        <td>
          <span>{secucode.user?.name}</span><br />
          <span className={!this.state.showSensitive ? 'blurred-item' : 'blurred-item deblur'}>{secucode.user?.fullName}</span>
        </td>
        <td className="text-right">
          <GhostButtonControl to={`/secucode/edit/${secucode.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredSecucodes = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.secucodes ? this.state.secucodes : []).sort(this.state.tableSort.sortByColumn).filter((secucode: SecucodeDTO) => secucode.deleted === this.state.showDeleted);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Security codes"
          status={this.state.status}
        />
        {this.state.secucodes && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showSensitive"
                name="showSensitive"
                changeMethod={this.toggleSensitive}
                isChecked={this.state.showSensitive}
                labelText="Sensitive data"
                icon="fas fa-shield-alt"
                srOnly={true}
                title="Sensitive data"
              />
              <ToggleControl
                id="showDeleted"
                name="showDeleted"
                changeMethod={this.showDeleted}
                isChecked={this.state.showDeleted}
                labelText="Recycle bin"
                icon="fas fa-trash-undo"
                srOnly={true}
                title="Recyle bin"
              />
              <div className="form-group">
                <Link to={'/secucode/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add code</span></Link>
              </div>
            </ToolbarControls>

            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                      card
                      ${!this.state.secucodes ? 'loader-border' : ''}
                      ${this.state.showDeleted ? "flashing-border" : ""}
                  `}>
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.secucodes &&
                        <React.Fragment>
                          <tbody>
                            {filteredSecucodes.map(secucode => (
                              this.renderTBodyRow(secucode)
                            ))}
                          </tbody>
                          <tfoot>
                            {this.state.secucodes?.length === 0 &&
                              <tr>
                                <th colSpan={12}>
                                  <div className="card">
                                    <p className="text-chunk">There are no security codes defined. Start by <Link className="link-button" to="/secucode/add">adding</Link> the first one.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={12}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredSecucodes.length} out of {this.state.secucodes.filter((secucode: SecucodeDTO) => secucode.deleted === this.state.showDeleted).length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withTransitionEvent(SecucodeList);
