import { ChartDataset } from 'chart.js';
import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import { Link } from 'react-router-dom';
import UserDTO from '../../../common/api/dtos/User';
import AppContext from '../../../common/context/AppContext';
import Fetch from '../../../common/helpers/Fetch';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import ToggleControl from '../../controls/ToggleControl/ToggleControl';
import { IProgress, incrementProgress } from '../../utils/ProgressBar/ProgressBar';
import SelectControl from '../../controls/SelectControl/SelectControl';
import UltraChart from '../../utils/UltraChart/UltraChart';
import CustomDropdown from '../../utils/CustomDropdown/CustomDropdown';

export interface Props {
  loggedUser: LoggedUser,
  users: UserDTO[],
}

interface WeekdHours {
  billableHrs:  number,
  unbillableHrs:  number,
  totalDayHours: number
}

interface State {
  progress: IProgress,
  status: TRequestStatus,
  userId?: number,
  chartDataThisWeek: ChartDataset[],
  chartDataLastWeek: ChartDataset[],
  billable: boolean,
  dateFrom?: Date,
  dateUntil?: Date,
  reports?: Array<any>,
  isLastWeek: boolean,
  thisWeekHours: WeekdHours,
  lastWeekHours: WeekdHours,
  form: Form,
}

interface Form {
  userId: number,
}

class Timetrack extends Component<Props, State> {
  days: Array<{number: number, name: string}> = [
    {number: 0, name: "Monday" },
    {number: 1, name: "Tuesday" },
    {number: 2, name: "Wednesday" },
    {number: 3, name: "Thursday" },
    {number: 4, name: "Friday" }
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        userId : this.props.loggedUser.id
      },
      status: 'idle',
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      chartDataLastWeek: [
        { data: [], label: 'Non-billable' },
        { data: [], label: 'Billable' },
      ],
      chartDataThisWeek: [
        { data: [], label: 'Non-billable' },
        { data: [], label: 'Billable' },
      ],
      billable: false,
      isLastWeek: false,
      thisWeekHours: {
        billableHrs: 0,
        unbillableHrs: 0,
        totalDayHours:0
      },
      lastWeekHours: { 
        billableHrs: 0,
        unbillableHrs: 0,
        totalDayHours: 0
      }
    }
  }

  
  fetchWorklogs = async () => {
    this.setState({
      status: 'loading'
    })

    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/worklogs/filter`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        from: this.state.dateFrom,
        until: this.state.dateUntil,
        billable: null,
        uid: this.state.form.userId,
      })
    })

    await fetchRequest.fetch();

    const status = fetchRequest.getStatus();

    this.setState({
      status: status
    }, () => {
      if(status === 'success') {
        const fetchedReports = fetchRequest.getPayload();
        this.setState((prevState: State) => {
          return {
            reports: [...fetchedReports],
            progress: incrementProgress(prevState.progress)
          }
        }, () => {
          this.processWorklogs();
        })
      }
    })

  }

  processWorklogs = () => {
    if(this.state.dateFrom && this.state.reports) {
      let billableMap = new Map<number, number>();
      let unbillableMap = new Map<number, number>();
      let chartDataLastWeek: ChartDataset[] = [
        { data: [], label: 'Non-billable' },
        { data: [], label: 'Billable' },
      ];

      let chartDataThisWeek: ChartDataset[] = [
        { data: [], label: 'Non-billable' },
        { data: [], label: 'Billable' },
      ];


      for(let i = 0; i < 14; i++) {
        let currentDate = new Date(Date.UTC(this.state.dateFrom.getFullYear(), this.state.dateFrom.getMonth(), this.state.dateFrom.getDate()+i)).getTime();
        if(!billableMap.get(currentDate)) {
          billableMap.set(currentDate, 0);
        }
        if(!unbillableMap.get(currentDate)) {
          unbillableMap.set(currentDate, 0);
        }
      }
      
      this.state.reports.forEach(report => {
        let rawDate = new Date(report.date);
        let reportDate = new Date(Date.UTC(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate())).getTime();
        if(report.project.billable) {
          billableMap.set(reportDate, billableMap.get(reportDate) + report.hours);
        } else {
          unbillableMap.set(reportDate, unbillableMap.get(reportDate) + report.hours);
        }
      });
      
      for(let i = 0; i < 7; i++) {
        let currentDate = new Date(Date.UTC(this.state.dateFrom.getFullYear(), this.state.dateFrom.getMonth(), this.state.dateFrom.getDate()+i)).getTime();
        let hourEntry;

        hourEntry = unbillableMap.get(currentDate)
        chartDataLastWeek[0].data.push(hourEntry ? hourEntry : 0);
        hourEntry = billableMap.get(currentDate);
        chartDataLastWeek[1].data.push(hourEntry ? hourEntry : 0);
      }
      
      for(let i = 7; i < 14; i++) {
        let currentDate = new Date(Date.UTC(this.state.dateFrom.getFullYear(), this.state.dateFrom.getMonth(), this.state.dateFrom.getDate()+i)).getTime();
        let hourEntry;

        hourEntry = unbillableMap.get(currentDate);
        chartDataThisWeek[0].data.push(hourEntry ? hourEntry : 0);

        hourEntry = billableMap.get(currentDate);
        chartDataThisWeek[1].data.push(hourEntry ? hourEntry : 0);

      }

      this.setState({
        chartDataLastWeek: chartDataLastWeek,
        chartDataThisWeek: chartDataThisWeek
      }, () => {
        this.calculateTotalWorkedHours();
      });
    }
  }
  
  calculateInterval = () => {
    let curr = new Date();
    let firstday = curr.getDate() - curr.getDay() - 6;
    let lastday = firstday + 13;
    this.setState({
      dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), firstday),
      dateUntil: new Date(new Date().getFullYear(), new Date().getMonth(), lastday),
    }, () => {
      this.fetchWorklogs();
    });
  }

  calculateTotalWorkedHours = () => {
    let thisWeek = {
      billableHrs: 0,
      unbillableHrs: 0,
      totalDayHours:0
    };
    
    let lastWeek = { 
      billableHrs: 0,
      unbillableHrs: 0,
      totalDayHours: 0
    }

    if(this.state.chartDataThisWeek?.length) {
      for(let i = 0; i < this.state.chartDataThisWeek[0].data.length; i++) {
        thisWeek.unbillableHrs = thisWeek.unbillableHrs + Number(this.state.chartDataThisWeek[0].data[i])
        thisWeek.totalDayHours =  thisWeek.totalDayHours + Number(this.state.chartDataThisWeek[0].data[i])
      }

      for(let i = 0; i < this.state.chartDataThisWeek[1].data.length; i++) {
        thisWeek.billableHrs = thisWeek.billableHrs + Number(this.state.chartDataThisWeek[1].data[i])
        thisWeek.totalDayHours = thisWeek.totalDayHours + Number(this.state.chartDataThisWeek[1].data[i])
      }
    }

    if(this.state.chartDataLastWeek?.length) {
      for(let i = 0; i < this.state.chartDataLastWeek[0].data.length; i++) {
        lastWeek.unbillableHrs = lastWeek.unbillableHrs + Number(this.state.chartDataLastWeek[0].data[i])
        lastWeek.totalDayHours = lastWeek.totalDayHours + Number(this.state.chartDataLastWeek[0].data[i])
      }

      for(let i = 0; i < this.state.chartDataLastWeek[1].data.length; i++) {
        lastWeek.billableHrs = lastWeek.billableHrs + Number(this.state.chartDataLastWeek[1].data[i])
        lastWeek.totalDayHours = lastWeek.totalDayHours + Number(this.state.chartDataLastWeek[1].data[i])
      }
    }

    this.setState({
      thisWeekHours: {...thisWeek},
      lastWeekHours: {...lastWeek}
    })
  }

  toggleLastWeek = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.isLastWeek ? this.setState({isLastWeek: false}) : this.setState({isLastWeek: true});
  }

  hasElevatedAccess = (): boolean => (
    this.props.loggedUser?.role.name === 'Admin' || this.props.loggedUser?.role.name === 'Manager' || this.props.loggedUser?.role.name === 'Lead'
  )

  componentDidMount() {
    this.calculateInterval();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if(this.state.form.userId !== prevState.form.userId) {
      this.calculateInterval();
    }
  }

  setUser = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, userId: number | string | undefined | UserDTO) => this.updateForm('userId', Number(userId));

  updateForm<K extends keyof Form>(field: K, value: Form[K]) {
    const form = this.state.form;
    this.setState({
      form: {
        ...form,
        [field]: value,
      }
    })
  }

  render() {
    var usersOptions = this.context.users ? this.context.users.filter((user: UserDTO) => (user.enabled === true)) : [];
    return(
      <React.Fragment>
        <div
          className={`
            card
            ${this.state.status === 'loading' ? 'loader-border' : ''}
          `}
        >
          {this.state.reports &&
            <React.Fragment>
              <div className="flex-row fill">
                <div className="column">
                  <Link to="/timetrack" className="link-button">
                    <h2 className="primary-title">Timetrack</h2>
                  </Link>
                </div>
                <div className="column">
                  <div className="horizontal-flow-mirrored">
                    {this.hasElevatedAccess() &&
                      <SelectControl
                        idName="userId"
                        value={this.state.form.userId}
                        options={usersOptions || []}
                        disabled={this.state.status === 'loading'}
                        //   classes="mw-small"
                        onChange={this.setUser}
                        getValue={(op) => op?.id}
                        getLabel={(op) => op?.name}
                      />
                    }
                    <ToggleControl
                      id="showLastWeek"
                      name="showLastWeek"
                      changeMethod={this.toggleLastWeek}
                      isChecked={this.state.isLastWeek}
                      labelText="Last week"
                    />
                  </div>
                </div>
              </div>
              <div className="chart-container">
                <UltraChart
                  chartData={this.state.isLastWeek ? this.state.chartDataLastWeek : this.state.chartDataThisWeek}
                  labels={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']}
                  suggestedMaxA={10}
                  suggestedMaxB={10}
                />
              </div>

              {this.state.chartDataThisWeek &&
                <div>
                  <span className="faint-text"><small>{this.state.isLastWeek ? 'last' : 'this'} week</small> <br /> total hours: {!this.state.isLastWeek ? this.state.thisWeekHours.totalDayHours : this.state.lastWeekHours.totalDayHours}</span>
                  <ul>
                    {/* {this.days.map(day => {
                      return (
                        <li>
                          {day.name}
                          <span> Nonbillable= {!this.state.isLastWeek ? this.state.chartDataThisWeek[0].data[day.number] : this.state.chartDataLastWeek[0].data[day.number]}</span>
                          <span> Billable= {!this.state.isLastWeek ? this.state.chartDataThisWeek[1].data[day.number] : this.state.chartDataLastWeek[1].data[day.number]}</span>
                          <span> Total hours= {!this.state.isLastWeek ? this.state.chartDataThisWeek[1].data[day.number] : this.state.chartDataLastWeek[1].data[day.number]}</span>
                        </li>
                      )
                    })} */}
                  </ul>
                </div>
              }
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default Timetrack;

Timetrack.contextType = AppContext;