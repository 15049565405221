import Fetch from "../../helpers/Fetch";
import BadgeDTO from "../dtos/Badge";
import BenefitDTO from "../dtos/Benefit";
import GroupDTO from "../dtos/Group";
import ProcedureDTO from "../dtos/Procedure";
import ProjectDTO from "../dtos/Project";
import UserDTO from "../dtos/User";

interface AddPayload {
  name: string,
  email: string,
  rid?: number,
  enabled: boolean,
  invisible: boolean,
  avatar: string,
  fullName?: string,
  phone?: string,
  hourTarget?: number | undefined,
}

export async function listUsers(): Promise<UserDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<UserDTO[]>(`/user`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch users');
}

export async function listAllUsers(): Promise<UserDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<UserDTO[]>(`/items/findAllUsers`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch all users');
}

export async function listUserDetails(userId: string): Promise<UserDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<UserDTO>(`/user/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch user details');
}

export async function listAssignedProjects(userId: number): Promise<ProjectDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProjectDTO[]>(`/user/assigned-projects/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch user assigned projects');
}

export async function listAssignedGroups(userId: number): Promise<GroupDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<GroupDTO[]>(`/user/assigned-groups/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch user assigned groups');
} 

export async function listAssignedProcedures(userId: number): Promise<ProcedureDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProcedureDTO[]>(`/user/assigned-procedures/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch user assigned procedures');
} 

export async function listAssignedBenefits(userId: number): Promise<BenefitDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<BenefitDTO[]>(`/user/assigned-benefits/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch user assigned benefits');
}

export async function listAssignedBadges(userId: number): Promise<BadgeDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<BadgeDTO[]>(`/user/assigned-badges/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch user assigned badges');
}

export async function listTeam(): Promise<UserDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<UserDTO[]>(`/user/team`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch user assigned badges');
}

export async function updateAssignedBadges(form: {badges: BadgeDTO[]}, userId: number): Promise<BadgeDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<BadgeDTO[]>(`/user/assigned-badges/${userId}`, {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to update user assigned badges');
}

export async function addUser(form: AddPayload): Promise<UserDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<UserDTO>(`/user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add usser`);
}

export async function updateUser(form: AddPayload, userId: string): Promise<UserDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<UserDTO>(`/user/${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update user`);
}