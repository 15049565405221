import React, { Component, KeyboardEvent } from 'react';
import { ITreeview } from '../../../common/interfaces/Treeview';
import { ENDPOINT_MOCK } from '../../../data/endpoint.mock';

interface Props {
  label?: string,
  controlId: string,
  required: boolean,
  options: ITreeview[]
  onUpdate: (treeview: ITreeview[]) => void
}

interface State {
  treeview: ITreeview[]
}


class TreeviewControl<T> extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      treeview: this.props.options.slice()
    }
  }

  treeviewSearchById(data: ITreeview[], identifier: string): ITreeview | undefined {
    let result;
    let iter = (item: ITreeview) => {
        if (item.id === identifier) {
            result = item;
            return true;
        }
        return Array.isArray(item.children) && item.children.some(iter);
    }

    data.some(iter);
    return result;
  }

  toggleTreeview = (identifier: string) => {
    let treeview = this.state.treeview.slice(0);
    // let item: ITreeview | undefined = treeview.find((element: ITreeview) => element.id == identifier);
    let item = this.treeviewSearchById(treeview, identifier)
    if(item !== undefined) {
      item.collapsed = !item?.collapsed;
    }

    this.setState({
        treeview
    }, () => this.props.onUpdate(this.state.treeview));
  }

  uncheckTreeview = (identifier: string) => {
    let treeview = this.state.treeview.slice(0);
    let item = this.treeviewSearchById(treeview, identifier)
    if(item !== undefined) {
      item.state = "unchecked";
      if(item.children) {
        item.children.map((child) => { child.state = "unchecked"
          if(child.children) {
            this.uncheckTreeview(child.id);
          }
        });
      }
    }

    this.setState({
        treeview
    }, () => this.props.onUpdate(this.state.treeview));
  }

  checkTreeview = (identifier: string) => {
    let treeview = this.state.treeview.slice(0);
    let item = this.treeviewSearchById(treeview, identifier)
    if(item !== undefined) {
      item.state = "checked";
      if(item.children) {
        item.children.map((child) => { child.state = "checked"
          if(child.children) {
            this.checkTreeview(child.id);
          }
        });
      }
    }

    this.setState({
        treeview
    }, () => this.props.onUpdate(this.state.treeview));
  }

  updateTreeviewstate = (identifier: string, inRecursion: boolean) => {
    let treeview = this.state.treeview.slice(0);
    let item = this.treeviewSearchById(treeview, identifier)
    if(item !== undefined) {
      if(!inRecursion) {
        switch(item.state) {
          case "checked":
            this.uncheckTreeview(identifier);
            break;
          case "indeterminate":
          case "unchecked":
            this.checkTreeview(identifier);
            break;
        }
      } else {
        this.evaluateTreeviewNode(item.id);
      }

      this.checkForParents(identifier);
    }

    this.setState({
        treeview
    }, () => this.props.onUpdate(this.state.treeview));
  }

  checkForParents(identifier: string) {
    const lastIndex = identifier.lastIndexOf("_");
    const before = identifier.slice(0, lastIndex);
    if(before) {
      this.updateTreeviewstate(before, true);
    }
  }

  evaluateTreeviewNode = (identifier: string) => {
    let treeview = this.state.treeview.slice(0);
    let item = this.treeviewSearchById(treeview, identifier)
    if(item !== undefined) {
      if(item.children) {
        let checked = item.children.find((child) => child.state == "checked");
        let indeterminate = item.children.find((child) => child.state == "indeterminate");
        let unchecked = item.children.find((child) => child.state == "unchecked");
        if(indeterminate) {
          item.state = "indeterminate";
        } else if(checked && unchecked) {
          item.state = "indeterminate";
        } else if(checked && !unchecked) {
          item.state = "checked";
        } else {
          item.state = "unchecked";
        }
      }

      this.checkForParents(identifier);
    }

    this.setState({
        treeview
    }, () => this.props.onUpdate(this.state.treeview));
  }
  
  renderItem(item: ITreeview) {
    return(
      <>
        <li>
          <div className={`form-group ${item.state == "checked" ? "active" : ""} ${item.collapsed ? "closed" : ""}`}>
              <input
                type="checkbox"
                id={item.id}
                name={item.id}
                checked={item.state == "checked"}
                onClick={() => { this.updateTreeviewstate(item.id, false)}}
                ref={input => {
                  if (input) {
                    input.indeterminate = (item.state == "indeterminate");
                  }
                }}
              />
              {(item.children && item.children.length > 0) &&
                <span className="icon fas fa-chevron-down" onClick={() => this.toggleTreeview(item.id)}></span>
              }
              <label
                htmlFor={item.id}
              >
                {item.label}
                <p className="faint-text">{item.description}</p>
              </label>
          </div>
          {(item.children && item.children.length > 0) &&
            <ul>
              {item.children.map((child: ITreeview) => {
                return (
                  this.renderItem(child)
                )
              })}
            </ul>
          }
        </li>
      </>
    )
  }

  render() {
    return (
      <>
        <div className="form-group">
          <label htmlFor={this.props.controlId}>
            {this.props.label}{this.props.required && '*'}
          </label>
          <ul className="treeview">
            {this.props.options.map((treeItem: ITreeview) => { return (
                this.renderItem(treeItem)
              )})
            }
          </ul>
        </div>
      
        {/* Below is the JS to make the indeterminate state */}
        {/* <script>
            let indeterminateCheckbox1 = document.getElementById("PersonalDocuments");
            let indeterminateCheckbox2 = document.getElementById("HomeRemodel");
            indeterminateCheckbox1.indeterminate = true;
            indeterminateCheckbox2.indeterminate = true;
        </script> */}
      </>
    )
  }
}
export default TreeviewControl;