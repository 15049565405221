import { TSortDirection } from "../types/SortDirection";

class TableSort {

    readonly sortBy: string;
    readonly sortDirection: TSortDirection;

    constructor(sortBy: string, sortDirection: TSortDirection) {
        this.sortBy = sortBy;
        this.sortDirection = sortDirection;
    }

    escapeCase(value: any): any {
      if(typeof value == "string") {
        value = value.toLowerCase();
      }
      return value;
    }

    sortByColumn = (elementA: any, elementB: any) => {
        let sortedProp: string[] = [];
        if(this.sortBy.indexOf('.')) {
          sortedProp = this.sortBy.split('.');
        }
    
        if(sortedProp.length === 1) {
          if(elementA[sortedProp[0]] !== null && elementB[sortedProp[0]] !== null) {
            if(this.escapeCase(elementA[this.sortBy as any]) < this.escapeCase(elementB[this.sortBy as any])) {
              return this.sortDirection === 'asc' ? -1 : 1;
            }
            if(this.escapeCase(elementA[this.sortBy as any]) > this.escapeCase(elementB[this.sortBy as any])) {
              return this.sortDirection === 'asc' ? 1 : -1;
            }
          } else if(elementA[sortedProp[0]] !== null) {
            return this.sortDirection === 'asc' ? -1 : 1;
          //if B doesn't exist A wins
          } else if(elementB[sortedProp[0]] !== null) {
            return this.sortDirection === 'asc' ? 1 : -1;
          }
          return 0;
        } else {
          //if both A and B exist compare subprops
          if(elementA[sortedProp[0]] !== null && elementB[sortedProp[0]] !== null) {
            if(this.escapeCase(elementA[sortedProp[0] as any][sortedProp[1] as any]) < this.escapeCase(elementB[sortedProp[0] as any][sortedProp[1] as any])) {
              return this.sortDirection === 'asc' ? -1 : 1;
            }
            if(this.escapeCase(elementA[sortedProp[0] as any][sortedProp[1] as any]) > this.escapeCase(elementB[sortedProp[0] as any][sortedProp[1] as any])) {
              return this.sortDirection === 'asc' ? 1 : -1;
            }
          //if A doesn't exist B wins
          } else if(elementA[sortedProp[0]] !== null) {
            return this.sortDirection === 'asc' ? -1 : 1;
          //if B doesn't exist A wins
          } else if(elementB[sortedProp[0]] !== null) {
            return this.sortDirection === 'asc' ? 1 : -1;
          }
          return 0;
        }
    }
}

export default TableSort;