import { faSleigh } from "@fortawesome/free-solid-svg-icons";
import Joi from "joi";
import React, { ChangeEvent, Component } from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import GroupDTO from "../../../../common/api/dtos/Group";
import RoleDTO from "../../../../common/api/dtos/Role";
import { FormErrors } from "../../../../common/data/FormErrors";
import Fetch from "../../../../common/helpers/Fetch";
import { loadComplete } from "../../../../common/helpers/LoadComplete";
import { processJoiError } from "../../../../common/helpers/processJoiError";
import { LoggedUser } from "../../../../common/interfaces/LoggedUser";
import { TRequestStatus } from "../../../../common/types/RequestStatus";
import ButtonControl from "../../../controls/ButtonControl/ButtonControl";
import ToggleControl from "../../../controls/ToggleControl/ToggleControl";
import BreadcrumbControls from "../../../generics/Header/BreadcrumbControls";
import ProgressBar, { IProgress, incrementProgress } from "../../../utils/ProgressBar/ProgressBar";
import RequestStatus from "../../../utils/RequestStatus/RequestStatus";
import TextAreaControl from "../../../controls/TextAreaControl/TextAreaControl";
import { isEditPage } from '../../../../common/helpers/isEditPage';
import TextControl from "../../../controls/TextControl/TextControl";
import { withTransitionEvent } from "../../../TransitionEvent";

export interface Props extends RouteComponentProps<RouteParams> {
  loggedUser: LoggedUser,
}

interface RouteParams {
  id?: string,
}

interface FormData {
  name: string,
  description: string,
  iconURL: string,
  deleted: boolean,
}

interface State {
  groupID?: number,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  roles?: RoleDTO[],
  formData: FormData,
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]? :string;
};

class GroupDetail extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    description: Joi.string().trim(true).allow(null, ''),
    iconURL: Joi.string().trim(true).required().messages(FormErrors.string),
    deleted: Joi.boolean(),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      groupID: Number(this.props.match.params.id),
      formData: {
        name: '',
        description: '',
        iconURL: '',
        deleted: false,
      },
      progress: {
        currentStep: 0,
        totalSteps: 0,
      },
      pageStatus: 'idle',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchGroupDetails = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch<GroupDTO>(`/groups/${this.state.groupID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    })

    await fetchRequest.fetch();
    if(fetchRequest.getStatus() === 'success') {
      this.setState((prevState: State) => {
        const group = fetchRequest.getPayload();
        return {
          formData: {
            name: group.name,
            description: group.description,
            iconURL: group.iconURL,
            deleted: group.deleted,
          },
          progress: incrementProgress(prevState.progress),
          pageStatus: 'success',
        }
      });
    }
  }

  addEntity = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/groups`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(this.state.formData),
    })

    await fetchRequest.fetch();
    this.setState({
      formStatus: fetchRequest.getStatus(), 
    }, () => {
      if(this.state.formStatus === 'success') {
        this.props.history.push({
          pathname: '/group',
        })
      }
    });
  }

  updateEntity = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/groups/` + this.state.groupID, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(this.state.formData),
    })

    await fetchRequest.fetch();
    this.setState({
      formStatus: fetchRequest.getStatus(),
    });
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if(isValid) {
      this.setState({
        formStatus: 'loading',
      });

      if(!isEditPage(Number(this.state.groupID))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], {
      abortEarly: false
    });

    if(result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false
    });
    if(result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    if(isEditPage(Number(this.state.groupID))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1,
          }
        }
      });
      this.fetchGroupDetails();
    }
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData, 
        [field]: value
      }
    })
  }

  renderGroupEditCard() {
    return (
      <div className="card">
        <TextControl
          label="Name"
          type="text"
          name="name"
          id="name"
          value={this.state.formData.name}
          onChange={this.setName}
          disabled={!loadComplete(this.state.progress)}
          required={true}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.name}
        />
        <TextAreaControl
          label="Description"
          id="description"
          name="description"
          value={this.state.formData.description}
          onChange={this.setDescription}
          disabled={!loadComplete(this.state.progress)}
          rows={3}
          cols={30}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        <div className="form-group">
          <div className="flex-row">
            <div className="column large">
              <TextControl
                label="Icon"
                type="text"
                name="iconURL"
                id="iconURL"
                value={this.state.formData.iconURL}
                onChange={this.setIconURL}
                disabled={!loadComplete(this.state.progress)}
                required={true}
                br={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.iconURL}
              />
              <small className="faint-text"><span className="fas fa-info-circle"></span> You can use both relative or absolute paths.</small>
            </div>
            <div className="column small flex-v-center">
              <img
                className="thumbnail radius padded large"
                src={this.state.formData.iconURL}
                alt="avatar"
              />
            </div>
          </div>
        </div>
       {isEditPage(Number(this.state.groupID)) &&
        <ToggleControl
          name="deleted"
          id="deleted"
          isChecked={this.state.formData.deleted}
          changeMethod={this.setDeleted}
          isDisabled={!loadComplete(this.state.progress)}
          labelText="Deleted"
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        }
        <ButtonControl
          class="primary-button"
          onClick={this.handleSubmit}
          disabled={!loadComplete(this.state.progress)}
        >
          <RequestStatus status={this.state.formStatus} />
          <span className="text">Save</span>
        </ButtonControl>
      </div>
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);
  setIconURL = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('iconURL', ev.target.value);
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);

  render() {
    return (
      <React.Fragment>
        <BreadcrumbControls
          items={[
            {label: 'Groups', path: '/group'},
            {label: `${!isEditPage(Number(this.state.groupID)) ? 'adding' : 'editing'} group ${this.state.formData.name}`},
          ]}
          status={this.state.pageStatus}
        />
        <div className="flex-row">
          <div className="column">
            <div className="flex-row tightest-top">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            {this.renderGroupEditCard()}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTransitionEvent(GroupDetail);