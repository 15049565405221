import Joi from 'joi';
import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import { Link, RouteComponentProps } from "react-router-dom";
import PayprofileDTO from '../../../../common/api/dtos/Payprofile';
import TenantDTO from '../../../../common/api/dtos/Tenant';
import RoleDTO from '../../../../common/api/dtos/Role';
import { listTenantDetails } from '../../../../common/api/endpoints/tenants';
import { FormErrors } from '../../../../common/data/FormErrors';
import Fetch from '../../../../common/helpers/Fetch';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { LoggedUser } from '../../../../common/interfaces/LoggedUser';
import { UserPropName } from '../../../../common/interfaces/UserPropName';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SelectControl from '../../../controls/SelectControl/SelectControl';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import TextControl from '../../../controls/TextControl/TextControl';
import TextAreaControl from '../../../controls/TextAreaControl/TextAreaControl';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../../TransitionEvent';

export interface Props extends RouteComponentProps<RouteParams> {
  loggedUser: LoggedUser,
}

interface RouteParams {
  id?: string,
  eid?: string,
  entity?: string,
}

interface FormData {
  name: string,
  description: string,
  invoiceSeries: string,
  currency: string,
  bankAccount: string,
  tenantId: number,
  enabled: boolean,
}

interface State {
  tenantId?: number,
  payprofileID?: number,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  tenants?: TenantDTO[],
  roles?: RoleDTO[],
  usersPropsNames?: UserPropName[],
  formData: FormData,
  formErrors: FormErrors,
  indefinite: boolean,
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

class PayprofileDetail extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    description: Joi.string().trim(true).allow(null, ''),
    currency: Joi.string().trim(true).required().messages(FormErrors.string),
    invoiceSeries: Joi.string().trim(true).required().messages(FormErrors.string),
    bankAccount: Joi.string().trim(true).required().messages(FormErrors.string),
    tenantId: Joi.number().required().messages(FormErrors.string),
    enabled: Joi.boolean(),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      payprofileID: Number(this.props.match.params.id),
      indefinite: true,
      formData: {
        name: '',
        description: '',
        invoiceSeries: '',
        currency: '',
        bankAccount: '',
        tenantId: Number(this.props.match.params.eid),
        enabled: true,
      },
      progress: {
        currentStep: 0,
        totalSteps: 1
      },
      pageStatus: 'idle',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchPayprofileDetails = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch<PayprofileDTO>(`/payprofiles/${this.state.payprofileID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    })
    
    await fetchRequest.fetch();
    if(fetchRequest.getStatus() === 'success') {
      this.setState((prevState: State) => {
        const payprofile = fetchRequest.getPayload();
        return {
          formData: {
            name: payprofile.name,
            description: payprofile.description,
            tenantId: payprofile.tenant?.id,
            invoiceSeries: payprofile.invoiceSeries,
            currency: payprofile.currency,
            bankAccount: payprofile.bankAccount,
            enabled: payprofile.enabled,
          },
          progress: incrementProgress(prevState.progress),
          pageStatus: 'success',
        }
      });
    }
  }

  fetchTenants = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const tenants: TenantDTO[] = [];
    tenants.push(await listTenantDetails(this.state.formData.tenantId));

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        tenants: tenants,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  addEntity = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/payprofiles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(this.state.formData),
    })
    
    await fetchRequest.fetch();
    this.setState({
      formStatus: fetchRequest.getStatus(),
    },() => {
      if(this.state.formStatus === 'success'){
        this.props.history.push({
          pathname: `/tenant/edit/${this.state.formData.tenantId}/payprofile`,
        })
      }
    });
  }

  updateEntity = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/payprofiles/` + this.state.payprofileID, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(this.state.formData),
    })
    
    await fetchRequest.fetch();
    this.setState({
      formStatus: fetchRequest.getStatus(),
    });
  }
  
  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
  
      if(!isEditPage(Number(this.state.payprofileID))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    this.fetchTenants();
    
    if(isEditPage(Number(this.state.payprofileID))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 2,
          }
        }
      });
      this.fetchPayprofileDetails();
    }
  } 

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      }
    });
  }

  renderPayprofileEditCard() {
    return(
      <div className="card">
        <TextControl
          label="Name"
          type="text"
          name="name"
          id="name"
          value={this.state.formData.name}
          onChange={this.setName}
          disabled={!loadComplete(this.state.progress)}
          required={true}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.name}
        />
        <TextAreaControl
          label="Description"
          id="description"
          name="description"
          value={this.state.formData.description}
          onChange={this.setDescription}
          disabled={!loadComplete(this.state.progress)}
          rows={3}
          cols={30}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        <SelectControl
          idName="tenantId"
          label="Tenant"
          required={true}
          value={this.state.formData.tenantId}
          options={this.state.tenants || []}
          disabled={!loadComplete(this.state.progress) || isEditPage(Number(this.state.payprofileID)) || !!this.props.match.params.eid}
          disabledValue={"Select a tenant"}
          // autoComplete="new-password"
          onChange={this.setTenantId}
          getValue={(op) => op.id}
          getLabel={(op) => op?.name}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.tenantId}
        />
        <TextControl
          label="Bank Account"
          type="text"
          name="bankAccount"
          id="bankAccount"
          value={this.state.formData.bankAccount}
          onChange={this.setBankAccount}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.bankAccount}
        />
        <TextControl
          label="Currency"
          type="text"
          name="currency"
          id="currency"
          value={this.state.formData.currency}
          onChange={this.setCurrency}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.currency}
        />
        <TextControl
          label="Invoice Series"
          type="text"
          name="invoiceSeries"
          id="invoiceSeries"
          value={this.state.formData.invoiceSeries}
          onChange={this.setInvoiceSeries}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.invoiceSeries}
        />
        {isEditPage(Number(this.state.payprofileID)) && 
          <ToggleControl 
            name="enabled" 
            id="enabled" 
            isChecked={this.state.formData.enabled}
            changeMethod={this.setEnabled}
            isDisabled={!loadComplete(this.state.progress)}
            labelText="Enabled"
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          />
        }
        <ButtonControl
          class="primary-button"
          onClick={this.handleSubmit}
          disabled={!loadComplete(this.state.progress)}
        >
          <RequestStatus status={this.state.formStatus} />
          <span className="text">Save</span>
        </ButtonControl>
      </div>
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);
  setTenantId = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, tenantId: number | string | undefined | TenantDTO) => this.updateFormData('tenantId', Number(tenantId));
  setBankAccount = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('bankAccount', ev.target.value);
  setCurrency = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('currency', ev.target.value);
  setInvoiceSeries = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('invoiceSeries', ev.target.value);
  setEnabled = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('enabled', ev.target.checked);

  renderBreadcrumbs() {
    const entity = this.props.match.params.entity;
    const entityId: number = Number(this.props.match.params.eid);

    let entityName;
    
    if(entity) {
      entityName = this.state.tenants?.find((tenant: TenantDTO) => tenant.id == entityId)!.name;
    }

    return(
      !entity ?
        <BreadcrumbControls 
          items={[
            {label: 'payprofiles', path: '/payprofile'},
            {label: `${!isEditPage(Number(this.state.payprofileID)) ? 'adding payprofile ' : 'editing payprofile '}${this.state.formData.name}`}
          ]}
          status={this.state.pageStatus}
        />
      :
        <BreadcrumbControls 
          items={[
            {label: `${entity}s`, path: `/${entity}`},
            {label: entityName ? entityName : '', path: `/${entity}/edit/${entityId}`},
            {label: 'payprofiles', path: `/${entity}/edit/${entityId}/payprofile`},
            {label: `${!isEditPage(Number(this.state.payprofileID)) ? 'adding payprofile ' : 'editing payprofile '}${this.state.formData.name}`}
          ]}
          status={this.state.pageStatus}
        />
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderBreadcrumbs()}
        <ProgressBar
          currentStep={this.state.progress.currentStep}
          totalSteps={this.state.progress.totalSteps}
        />
        <div className="flex-row">
          <div className="column">
            {this.renderPayprofileEditCard()}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTransitionEvent(PayprofileDetail);