import React, { Component, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import CompensationDTO from '../../../../common/api/dtos/Compensation';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import DateTCell from '../../../utils/DateTCell/DateTCell';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { listCompensations } from '../../../../common/api/endpoints/compensations';
import { listUsers } from '../../../../common/api/endpoints/users';
import UserDTO from '../../../../common/api/dtos/User';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import { withTransitionEvent } from '../../../TransitionEvent';

interface State {
  compensations?: CompensationDTO[],
  status: TRequestStatus,
  showSensitive: boolean,
  showDeleted: boolean,
  tableSort: TableSort,
  filterValue: string,
  progress: IProgress,
  users: UserDTO[],
}

class CompensationList extends Component<{}, State> {
  availableFilters: string[] = [
    'user.name',
    'description',
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      status: 'loading',
      showSensitive: false,
      showDeleted: localStorage.getItem('showDeletedCompensations') === 'false' || localStorage.getItem('showDeletedCompensations') === null ? false : true,
      tableSort: new TableSort('user.name', 'asc'),
      filterValue: '',
      users: [],
      progress: {
        currentStep: 0,
        totalSteps: 2
      },
    }
  }

  sensitiveTimeout: number = 0;

  toggleSensitive = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.showSensitive ? this.setState({showSensitive: false}) : this.setState({showSensitive: true});
  }

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  fetchCompensations = async () => {
    this.setState({
      status: 'loading'
    });
    
    const compensations = await listCompensations();

    this.setState({
        status: 'success',
        compensations: compensations,
    });
  }


  fetchUsers = async () => {
    this.setState({
      status: 'loading'
    });
    
    const users = await listUsers();

    this.setState(prevState => {
      return {
        status: 'success',
        users,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchCompensations();
    this.fetchUsers();
  }

  componentDidUpdate(prevState: State) {
    if(this.state.showSensitive !== prevState.showSensitive) {
      if(this.state.showSensitive){
        this.sensitiveTimeout = setTimeout(() => {
          this.setState({showSensitive: false});
        }, parseInt(String(process.env.REACT_APP_SENSITIVE_DATA_TIMEOUT))) as unknown as number;
      } else {
        clearTimeout(this.sensitiveTimeout);
      }
    }
  }
  
  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th>
          <SortButton 
            column='user.name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='description' 
            text='Description'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='requestedOn' 
            text='Requested on'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='honoredOn' 
            text='Honored on'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='year' 
            text='For'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='amount' 
            text='Amount'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(compensation: CompensationDTO) {
    return (
      <tr id={`compensation${compensation.id}`} key={`${compensation.id}`}>
        <td></td>
        <td>
          <span>{compensation.user.name}</span><br />
          <small className={!this.state.showSensitive ? 'blurred-item faint-text' : 'blurred-item deblur faint-text'}>{this.state.users.find((user: UserDTO) => user.id === compensation.user.id)?.fullName}</small>
        </td>
        <td>
          <p className="text-chunk">{compensation.description}</p>
        </td>
        <DateTCell date={compensation.requestedOn}/>
        <DateTCell date={compensation.honoredOn} fallbackText="pending"/>
        <td className="text-center">
          {compensation.year}
        </td>
        <td className="text-center">
          {compensation.amount} day{compensation.amount != 1 && 's'}
        </td>
        <td className="text-right">
          <GhostButtonControl to={`/compensation/edit/${compensation.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredCompensations: Array<CompensationDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.compensations ? this.state.compensations : []).sort(this.state.tableSort.sortByColumn).filter((compensation: CompensationDTO) => compensation.deleted === this.state.showDeleted);
    return (
      <div>
        <ToolbarControls>
          <TextControl
            label="Filter"
            placeholder="Filter"
            type="text"
            name="filterBox"
            id="filterBox"
            onChange={this.setFilterValue}
            srOnly={true}
          />
          <ToggleControl
            id="showSensitive"
            name="showSensitive"
            changeMethod={this.toggleSensitive}
            isChecked={this.state.showSensitive}
            labelText="Sensitive data"
                icon="fas fa-shield-alt"
                srOnly={true}
                title="Sensitive data"
          /> 
          <ToggleControl
            id="showDeletedCompensations"
            name="showDeletedCompensations"
            changeMethod={this.toggleDeleted}
            isChecked={this.state.showDeleted}
            labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
          />
          <div className="form-group">
            <Link to={'/compensation/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add compensation</span></Link>
          </div>
        </ToolbarControls>
        <BreadcrumbControls
          pageTitle="Compensations"
          status={this.state.status}
        />
        <div className="tableview-component flex-row fill">
          <div className="column">
            <div className={`
                card
                ${!this.state.compensations ? 'loader-border' : ''}
                ${this.state.showDeleted ? "flashing-border" : ""}
              `}
            >
              <div className="inner-v-scroll">
                <table>
                  <thead>
                    {this.renderTHeadRow()}
                  </thead>
                  {this.state.compensations &&
                    <React.Fragment>
                      <tbody>
                        {filteredCompensations.map(compensation => (
                          this.renderTBodyRow(compensation)
                        ))}
                      </tbody>
                      <tfoot>
                        {this.state.compensations.length === 0 &&
                          <tr>
                            <th colSpan={8}>
                              <div className="card">
                                <p className="text-chunk">There are no compensations defined. Start by <Link className="link-button" to="/compensation/add">adding</Link> the first one.</p>
                              </div>
                            </th>
                          </tr>
                        }
                        {this.state.filterValue &&
                          <tr>
                            <th colSpan={8}>
                              <div className="card">
                                <p className="text-chunk">Showing {filteredCompensations.length} out of {this.state.compensations.filter((compensation: CompensationDTO) => compensation.deleted === this.state.showDeleted).length} results.</p>
                              </div>
                            </th>
                          </tr>
                        }
                      </tfoot>
                    </React.Fragment>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTransitionEvent(CompensationList);
