import Fetch from "../../helpers/Fetch";
import GearDTO from "../dtos/Gear";

interface AddPayload {
  name: string,
  internalId?: string,
  accountingId?: string,
  iconUrl: string,
  description: string,
  tileColor: string,
  inboundDate?: string,
  outboundDate?: string,
  userId?: number | null,
  computerId?: number | null,
  deleted: boolean,
}

export async function listGears(): Promise<GearDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<GearDTO[]>(`/gears`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch gears');
}

export async function listUserGears(userId?: number): Promise<GearDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<GearDTO[]>(`/gears/user/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch logged user gears');
}

export async function listGearDetails(itemId?: string): Promise<GearDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<GearDTO>(`/gears/${itemId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch gear details');
}


export async function addGear(form: AddPayload): Promise<GearDTO | GearError> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<GearDTO>(`/gears`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success' || status === 'error') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update gear`);
}

export async function updateGear(form: AddPayload, itemId: string): Promise<GearDTO | GearError> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<GearDTO>(`/gears/${itemId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success' || status === 'error') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update gear`);
}

export type GearError = {
  error: {
    field: keyof AddPayload,
    code: string,
  }
}