import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import { RouteComponentProps, Route, NavLink } from "react-router-dom";
import RoleDTO from '../../../../common/api/dtos/Role';
import { UserDTO } from '../../../../common/api/dtos/User';
import { listRoles } from '../../../../common/api/endpoints/roles';
import { LoggedUser } from '../../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import BenefitsForm from '../../../forms/BenefitsForm/BenefitsForm';
import PasswordForm from '../../../forms/PasswordForm/PasswordForm';
import ProjectsForm from '../../../forms/ProjectsForm/ProjectsForm';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SelectControl from '../../../controls/SelectControl/SelectControl';
import Joi from 'joi';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { FormErrors } from '../../../../common/data/FormErrors';
import { addUser, listUserDetails, listUsers, updateUser } from '../../../../common/api/endpoints/users';
import Thumbnail  from '../../../utils/Thumbnail/Thumbnail';
import BadgesForm from '../../../forms/BadgesForm/BadgesForm';
import ContractsForm from '../../../forms/ContractsForm/ContractsForm';
import GroupsForm from '../../../forms/GroupsForm/GroupsForm';
import ProceduresForm from '../../../forms/ProceduresForm/ProceduresForm';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TextControl from '../../../controls/TextControl/TextControl';
import SuspensionsForm from '../../../forms/SuspensionsForm/SuspensionsForm';
import CompensationsForm from '../../../forms/CompensationsForm/CompensationsForm';
import { withTransitionEvent } from '../../../TransitionEvent';
import DeepFlowOption from '../../../utils/DeepFlopOption/DeepFlowOption';

export interface Props extends RouteComponentProps<RouteParams> {
  loggedUser: LoggedUser,
}

interface RouteParams {
  id?: string,
}

interface FormData {
  name: string,
  email: string,
  rid?: number,
  enabled: boolean,
  visible: boolean,
  avatar: string,
  mustUpdatePassword: boolean,
  fullName: string | undefined,
  phone: string | undefined,
  hourTarget?: number | undefined,
}

interface UserData {
  id: number,
  employment: string | null,
  userId: number,
  hourNorm: number,
  phone: string,
  fullName: string,
  position: string,
  projectCount: number,
  benefitCount: number,
  user: UserDTO,
}

interface State {
  userId?: number,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  users?: UserDTO[],
  roles?: RoleDTO[],
  formData: FormData,
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class UserDetail extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    fullName: Joi.string().trim(true).allow('').messages(FormErrors.string),
    phone: Joi.string().trim(true).allow('').messages(FormErrors.string),
    email: Joi.string().trim(true).email({ minDomainSegments: 2, tlds: false}).required().messages(FormErrors.string),
    rid: Joi.number().required().messages(FormErrors.number),
    avatar: Joi.string(),
    mustUpdatePassword: Joi.boolean(),
    hourTarget: Joi.number().messages(FormErrors.string),
    enabled: Joi.boolean(),
    visible: Joi.boolean(),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      userId: Number(this.props.match.params.id),
      users: [],
      formData: {
        name: '',
        email: '',
        avatar: 'ava45.png|#aaaaaa|none|none||none-none',
        enabled: false,
        visible: true,
        mustUpdatePassword: true,
        fullName: '',
        phone: '',
        hourTarget: 0,
      },
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchUserDetails = async () => {
    if(this.state.userId) {
      this.setState({
        pageStatus: 'loading'
      });
      
      const users = await listUsers();
      const user = await listUserDetails(this.state.userId.toString());
  
      this.setState(prevState => {
        return {
          formData: {
            name: user.name,
            email: user.email,
            avatar: user.avatar,
            rid: user.role?.id,
            enabled: user.enabled,
            visible: !user.invisible,
            mustUpdatePassword: user.mustUpdatePassword,
            fullName: user.fullName,
            phone: user.phone,
            hourTarget: user.hourTarget,
          },
          progress: incrementProgress(prevState.progress),
          pageStatus: 'success',
          users: users,
        }
      });
    }
  }

  fetchRoles = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const roles = await listRoles();

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        roles: roles,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addUser({
      ...this.state.formData,
      invisible: !this.state.formData.visible,
    });

    this.setState({
        formStatus: 'success',
    }, () => {
      this.props.history.push({
        pathname: '/user'
      })
    });
  }

  updateEntity = async () => {
    if(this.state.userId) {
      this.setState({
        formStatus: 'loading'
      });
      await updateUser({
        ...this.state.formData, 
        invisible: !this.state.formData.visible
      }, 
        this.state.userId.toString());
  
      this.setState({
          formStatus: 'success',
      });
    }
  }

  isAdmin = () => {
    if (this.props.loggedUser.role.name === 'Admin') {
      return true;
    } else {
      return false;
    }
  }
  
  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
      
      if(!isEditPage(Number(this.state.userId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    this.fetchRoles();
    if(isEditPage(Number(this.state.userId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 2,
          }
        }
      });
      this.fetchUserDetails();
    }
  } 

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value
      }
    }, () => {
      this.validateFormField(field);
    })
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }
  
  getContextPanel() {
    let canChangePass = !(!this.state.formData.rid || this.state.formData.rid == 1 && this.props.loggedUser.role.id != this.state.formData.rid);
    return [
      {
        icon: 'fal fa-user',
        navLink: 'account',
        navName: 'User Details',
        description: 'Edit general information for this user',
        page: this.renderDetailsForm()
      },
      {
        icon: 'fal fa-lock',
        navLink: 'password',
        navName: 'Password Settings',
        description: canChangePass ? 'Change user\'s password' : 'Insuficient access rights. Can\'t change Admin password.',
        disabled: !canChangePass,
        page: (
          <React.Fragment>
            {canChangePass && this.state.userId !== undefined && !isNaN(this.state.userId) &&
              <React.Fragment>
                <PasswordForm 
                  editUserId={this.state.userId}
                  enforced={false}
                />
              </React.Fragment>
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-rocket',
        navLink: 'user-projects',
        navName: 'Projects',
        description: 'Manage which projects this user is assigned to',
        disabled: false,
        page: (
          <React.Fragment>
            {this.state.userId && 
              <ProjectsForm editUserId={this.state.userId}/>
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-users',
        navLink: 'user-groups',
        navName: 'Groups',
        description: 'Manage which groups this user is part of',
        disabled: false,
        page: (
          <React.Fragment>
            {this.state.userId && 
              <GroupsForm editUserId={this.state.userId} />
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-users',
        navLink: 'user-procedures',
        navName: 'Procedures',
        description: 'Manage which procedures this user should follow',
        disabled: false,
        page: (
          <React.Fragment>
            {this.state.userId && 
              <ProceduresForm editUserId={this.state.userId} />
            }
          </React.Fragment>
        )
      },
      
      {
        icon: 'fal fa-coins',
        navLink: 'compensations',
        navName: 'Compensations',
        description: 'Define and manage when this user is compensated for paid days',
        disabled: false,
        page: (
          <React.Fragment>
            {this.state.userId && 
              <CompensationsForm
                entityId={this.state.userId}
                entity={'user'}
                hasFullAccess={true}
              />
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-pause',
        navLink: 'suspensions',
        navName: 'Suspensions',
        description: 'Define and manage whether this user was suspended',
        disabled: false,
        page: (
          <React.Fragment>
            {this.state.userId && 
              <SuspensionsForm
                entityId={this.state.userId}
                entity={'user'}
                hasFullAccess={true}
              />
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-file-contract',
        navLink: 'contract',
        navName: 'Contracts',
        description: 'Define and manage which contracts this user has signed',
        page: (
          <React.Fragment>
            {this.state.userId && 
              <ContractsForm
                entityId={this.state.userId}
                entity={'user'}
                hasFullAccess={true}
              />
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-gift',
        navLink: 'benefits',
        navName: 'Benefits',
        description: 'Manage which benefits apply to this user',
        disabled: false,
        page: (
          <React.Fragment>
            {this.state.userId && 
              <BenefitsForm editUserId={this.state.userId}/>
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-badge',
        navLink: 'badges',
        navName: 'Badges',
        description: 'Manage which badges this user has been awarded',
        disabled: false,
        page: (
          <React.Fragment>
            {this.state.userId && 
              <BadgesForm editUserId={this.state.userId} />
            }
          </React.Fragment>
        )
      },
    ]
  }

  renderDetailsForm() {
    return(
      <div>
        <div className="card">
          <TextControl
            label="Name"
            type="text"
            name="name"
            id="name"
            value={this.state.formData.name}
            onChange={this.setName}
            disabled={!loadComplete(this.state.progress)}
            required={true}
            br={true}
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
            error={this.state.formErrors.name}
          />
          <TextControl
            label="Email"
            type="email"
            name="email"
            id="email"
            value={this.state.formData.email}
            onChange={this.setEmail}
            disabled={!loadComplete(this.state.progress) || (isEditPage(Number(this.state.userId)) && !this.isAdmin())}
            required={true}
            br={true}
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
            error={this.state.formErrors.email}
          />
          <SelectControl
            idName="rid"
            label="Role"
            required={true}
            value={this.state.formData.rid}
            options={this.state.roles || []}
            disabled={!loadComplete(this.state.progress) || !this.isAdmin()}
            disabledValue={!isEditPage(Number(this.state.userId)) ? "Select a role" : null}
            onChange={this.setRole}
            getValue={(op) => op.id}
            getLabel={(op) => op?.name}
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
            error={this.state.formErrors.rid}
          />
          <TextControl
            label="Full Name"
            type="text"
            name="fullName"
            id="fullName"
            onChange={this.setFullName}
            placeholder="Full Name"
            value={this.state.formData.fullName}
            disabled={!loadComplete(this.state.progress)}
            br={true}
          />
          <TextControl
            label="Hour target"
            type="number"
            name="hourTarget"
            id="hourTarget"
            onChange={this.setHourTarget}
            placeholder="Hour target"
            value={this.state.formData.hourTarget}
            disabled={!loadComplete(this.state.progress)}
            br={true}
          />
          <TextControl
            label="Phone"
            type="text"
            name="phone"
            id="phone"
            onChange={this.setPhone}
            placeholder="Phone"
            value={this.state.formData.phone}
            disabled={!loadComplete(this.state.progress)}
            br={true}
          />
          {isEditPage(Number(this.state.userId)) &&
            <div className="form-group tight">
              <div className="flex-row squeeze">
                <div className="column flex-v-center">
                  <Thumbnail
                    avatarData={this.state.formData.avatar}
                    classes="medium"
                    />
                </div>
                <div className="column flex-v-center">
                  <ButtonControl
                    type="button"
                    class="link-button"
                    onClick={this.clearAvatar}
                    >
                    <span className="text">Clear Avatar</span>
                  </ButtonControl>
                </div>
              </div>
            </div>
          }
        </div>
        <ToggleControl 
          name="enabled" 
          id="enabled" 
          isChecked={this.state.formData.enabled}
          changeMethod={this.setEnabled}
          isDisabled={!loadComplete(this.state.progress)}
          multiline={true}
          tight={true}
        >
          <>
            <label htmlFor="enabled">
              <span>Enabled</span>
              <br />
              <small className="faint-text">Determines whether the user is allowed to log in</small>
              <span className="state">{this.state.formData.enabled ? 'On' : 'Off'}</span>
            </label>
          </>
        </ToggleControl>
        <ToggleControl 
          name="visible" 
          id="visible" 
          isChecked={this.state.formData.visible}
          changeMethod={this.setHidden}
          isDisabled={!loadComplete(this.state.progress)}
          multiline={true}
          tight={true}
        >
          <>
            <label htmlFor="visible">
              <span>Visible</span>
              <br />
              <small className="faint-text">Allows the user to be seen by the team</small>
              <span className="state">{this.state.formData.visible ? 'On' : 'Off'}</span>
            </label>
          </>
        </ToggleControl>
        <ToggleControl 
          name="mustUpdatePassword" 
          id="mustUpdatePassword" 
          isChecked={this.state.formData.mustUpdatePassword}
          changeMethod={this.setMustUpdatePassword}
          isDisabled={!loadComplete(this.state.progress)}
          multiline={true}
        >
          <>
            <label htmlFor="mustUpdatePassword">
              <span>Require password update</span>
              <br />
              <small className="faint-text">User must update password on next log in</small>
              <span className="state">{this.state.formData.mustUpdatePassword ? 'On' : 'Off'}</span>
            </label>
          </>
        </ToggleControl>
        <ButtonControl
          class="primary-button"
          onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            this.handleSubmit(ev)
          }}
          disabled={!loadComplete(this.state.progress)}
        >
          <RequestStatus status={this.state.formStatus} />
          <span className="text">Save</span>
        </ButtonControl>
      </div>
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setEmail = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('email', ev.target.value);
  setFullName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('fullName', ev.target.value);
  setPhone = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('phone', ev.target.value);
  setRole = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, rid: number | string | undefined | RoleDTO) => this.updateFormData('rid', Number(rid));
  setHidden = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('visible', ev.target.checked);
  setEnabled = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('enabled', ev.target.checked);
  setMustUpdatePassword = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('mustUpdatePassword', ev.target.checked);
  setHourTarget = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('hourTarget', Number(ev.target.value));

  inDeepFlow = (): boolean => {
    const isNested = this.getContextPanel().some(context => this.props.location.pathname.includes(context.navLink));
    return isNested;
  }

  getContextName = () => {
    const contextName = this.getContextPanel().find(context => context.navLink === this.props.location.pathname.split('/').pop())?.navName;
    return contextName ? contextName : '';
  }

  clearAvatar = async () => {
    const formData = this.state.formData;
    
    this.setState({
      formData: {
        ...formData,
        avatar: 'ava45.png|#aaaaaa|||none|none-none|none-none'
      }
    });
  }

  renderEntityHeaders() {
    return(
      <div className="card-list-component flex-row tightest-top flex-v-center">
        <div className="column">
          <div className="card transparent">
            <Thumbnail
              avatarData={this.state.formData.avatar}
              classes="medium"
            />
            <div className="text-container story">
              <span className="primary-title">{this.state.formData.fullName ? this.state.formData.fullName : this.state.formData!== null ? "Unknown user" : ""}</span><br />
              <span>{this.state.formData.email}</span><br />
              <span>{this.state.formData.phone ? this.state.formData.phone : this.state.formData!== null ? "No phone number" : ""}</span><br />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card transparent">
            <div className="text-container story">
              <span>Hour target: {this.state.formData.hourTarget}</span><br />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card transparent">
            <div className="text-container story">
              {/* <span>{this.state.userProps?.projectCount ? `Assigned to ${this.state.userProps?.projectCount} ${this.state.userProps?.projectCount != 1 ? "projects" : "project"}` : this.state.userProps !== null ? "Not assigned to projects" : ""}</span><br />
              <span>{this.state.userProps?.benefitCount ? `${this.state.userProps?.benefitCount} ${this.state.userProps?.benefitCount != 1 ? "benefits" : "benefit"}` : this.state.userProps !== null ? "No benefits assigned" : ""}</span><br /> */}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let entityName = this.state.formData.name ? this.state.formData.name : 'loading';
    return (
      <div>
        {this.inDeepFlow() === false ?
          <BreadcrumbControls
            items={[
              {label: 'users', path: '/user'},
              {label: `${!isEditPage(Number(this.state.userId)) ? 'adding user ' : ''}${this.state.formData.name}`},
            ]}
          />
        :
          <BreadcrumbControls
            items={[
              {label: 'users', path: '/user'},
              {label: entityName ? entityName : '', path: `/user/edit/${this.state.userId}`},
              {label: this.getContextName()},
            ]}
          />
        }
        <div className="flex-row tightest-top">
          <div className="column large">
            <div className="flex-row">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            {isEditPage(Number(this.state.userId)) ?
              <>
                {this.renderEntityHeaders()}
                {this.getContextPanel().map((context, index) => {
                  return(
                    this.inDeepFlow() ?
                      <Route
                        path={`${this.props.match.url}/${context.navLink}`}
                        render={() => context.page}
                      />
                    :
                      <DeepFlowOption
                        baseURL={this.props.match.url}
                        context={context}
                        index={index}
                      />
                  )
                })}
              </>
            :
              this.renderDetailsForm()
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(UserDetail);