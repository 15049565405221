import Fetch from "../../helpers/Fetch";
import BadgeDTO from "../dtos/Badge";

export async function listBadges(): Promise<BadgeDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<BadgeDTO[]>(`/badges`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch badges');
}