import './vendors/wavemyth/conduit/scss/main.scss';
import React, { Component, KeyboardEvent } from 'react';
import Login from './components/pages/Login/Login';
import { BrowserRouter, Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import Dashboard from './components/pages/Dashboard/Dashboard';
import Preferences from './components/pages/Preferences/Preferences';
import { LoggedUser } from './common/interfaces/LoggedUser';
import Navigation from './components/generics/Navigation/Navigation';
import { Holiday } from './common/interfaces/Holiday';
import ProgressBar, { IProgress, incrementProgress } from './components/utils/ProgressBar/ProgressBar';
import Topbar from './components/generics/Header/Topbar';
import Timetrack from './components/pages/Timetrack/Timetrack';
import Bookings from './components/pages/Bookings/Bookings';
import Team from './components/pages/Team/Team';
import UserList from './components/pages/Users/UserList/UserList';
import DailyTarget from './components/pages/DailyTarget/DailyTarget';
import Billability from './components/pages/Billability/Billability';
import HourlyProjectInvoice from './components/pages/HourlyProjectInvoice/HourlyProjectInvoice';
import HourlyUserInvoice from './components/pages/HourlyUserInvoice/HourlyUserInvoice';
import PieceInvoice from './components/pages/PieceInvoice/PieceInvoice';
import Clocking from './components/pages/Clocking/Clocking';
import SyspropDTO from './common/api/dtos/Sysprop';
import ProcedureDTO from './common/api/dtos/Procedure';
import UserDetail from './components/pages/Users/UserDetail/UserDetail';
import ClientList from './components/pages/Client/ClientList/ClientList';
import SuspensionList from './components/pages/Suspensions/SuspensionList/SuspensionList';
import ProjectList from './components/pages/Projects/ProjectList/ProjectList';
import TenantList from './components/pages/Tenants/TenantList/TenantList';
import RoleList from './components/pages/Roles/RoleList/RoleList';
import LogoList from './components/pages/Logos/LogoList/LogoList';
import SysPropList from './components/pages/SysProps/SysPropList/SysPropList';
import { TRequestStatus } from './common/types/RequestStatus';
import SuspensionDetail from './components/pages/Suspensions/SuspensionDetail/SuspensionDetail';
import ClientDetail from './components/pages/Client/ClientDetail/ClientDetail';
import TenantDetail from './components/pages/Tenants/TenantDetail/TenantDetail';
import LogoDetail from './components/pages/Logos/LogoDetail/LogoDetail';
import SysPropDetail from './components/pages/SysProps/SysPropDetail/SysPropDetail';
import HolidayList from './components/pages/Holidays/HolidayList/HolidayList';
import HolidayDetail from './components/pages/Holidays/HolidayDetail/HolidayDetail';
import ComputerList from './components/pages/Inventory/ComputerList/ComputerList';
import ItemList from './components/pages/Inventory/ItemList/ItemList';
import ItemDetail from './components/pages/Inventory/ItemDetail/ItemDetail';
import ProjectDetail from './components/pages/Projects/ProjectDetail/ProjectDetail';
import ComputerDetail from './components/pages/Inventory/ComputerDetail/ComputerDetail';
import AppSettings from './components/pages/AppSettings/AppSettings';
import { listHolidays } from './common/api/endpoints/holidays';
import { listSysprops } from './common/api/endpoints/sysprops';
import { listLoggedUser } from './common/api/endpoints/loggeduser';
import BenefitList from './components/pages/Benefits/BenefitList/BenefitList';
import GroupsList from './components/pages/Groups/GroupsList/GroupsList';
import ProceduresList from './components/pages/Procedures/ProceduresList/ProceduresList';
import BenefitDetail from './components/pages/Benefits/BenefitDetail/BenefitDetail';
import GroupsBoard from './components/pages/Groups/GroupsBoard';
import AppContext, { IAppContext } from './common/context/AppContext';
import { listUsers } from './common/api/endpoints/users';
import NotFoundPage from './components/pages/Standalone/NotFoundPage';
import UserActivity from './components/pages/UserActivity/UserActivity';
import BadgeList from './components/pages/Badges/BadgeList/BadgeList';
import BadgeDetail from './components/pages/Badges/BadgeDetail/BadgeDetail';
import ContractList from './components/pages/Contracts/ContractList/ContractList';
import ContractDetail from './components/pages/Contracts/ContractDetail/ContractDetail';
import Remotes, { RouteParams } from './components/pages/Remotes/Remotes';
import GroupDetail from './components/pages/Groups/GroupDetail/GroupDetail';
import PayprofileDetail from './components/pages/Payprofiles/PayprofileDetail/PayprofileDetail';
import ResetPass from './components/pages/ResetPass/ResetPass';
import ProceduresBoard from './components/pages/Procedures/ProceduresBoard/ProceduresBoard';
import CompensationDetail from './components/pages/Compensations/CompensationDetail/CompensationDetail';
import CompensationList from './components/pages/Compensations/CompensationList/CompensationList';
import RoleDetail from './components/pages/Roles/RoleDetail/RoleDetail';
import SecucardList from './components/pages/Security/SecucardList/SecucardList';
import SecucardDetail from './components/pages/Security/SecucardDetail/SecucardDetail';
import SecukeyList from './components/pages/Security/SecukeyList/SecukeyList';
import SecukeyDetail from './components/pages/Security/SecukeyDetail/SecukeyDetail';
import SecucodeList from './components/pages/Security/SecucodeList/SecucodeList';
import SecucodeDetail from './components/pages/Security/SecucodeDetail/SecucodeDetail';
import ProviderList from './components/pages/Providers/ProviderList/ProviderList';
import ProviderDetail from './components/pages/Providers/ProviderDetail/ProviderDetail';
import ProcedureViewer from './components/pages/Procedures/ProcedureViewer/ProcedureViewer';
import ProcedureDetail from './components/pages/Procedures/ProcedureDetail/ProcedureDetail';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import EventList from './components/pages/Events/EventList/EventList';
import EventDetail from './components/pages/Events/EventDetail/EventDetail';
import { currentLogo } from './common/api/endpoints/logos';
import WaterUtilDetail from './components/pages/WaterUtils/WaterUtilDetail/WaterUtilDetail';
import WaterUtilList from './components/pages/WaterUtils/WaterUtilList/WaterUtilList';
import ElectricityUtilDetail from './components/pages/ElectricityUtils/ElectricityUtilDetail/ElectricityUtilDetail';
import ElectricityUtilList from './components/pages/ElectricityUtils/ElectricityUtilList/ElectricityUtilList';
import GasUtilDetail from './components/pages/GasUtils/GasUtilDetail/GasUtilDetail';
import GasUtilList from './components/pages/GasUtils/GasUtilList/GasUtilList';
import ProjectGroupDetail from './components/pages/Projects/ProjectGroups/ProjectGroupDetail';
import ClockingReport from './components/pages/ClockingReport/ClockingReport';
import WorkProfileDetail from './components/pages/WorkProfiles/WorkProfileDetail/WorkProfileDetail';

interface Props extends RouteComponentProps<RouteParams> {
}

interface State {
  token?: string | null,
  tokenExpiration?: string | null,
  tokenStamp?: string | null,
  loggedUser?: LoggedUser,
  holidays?: Holiday[],
  sysProps: SyspropDTO[],
  progress: IProgress,
  appLogo: string
  status: TRequestStatus,
  theme: string,
  accent: string,
  showMood: boolean;
  welcome: string,
  sessionExpired: boolean,
  appContext: IAppContext,
  location: {
    prevPath: string,
  },
  transitioning: boolean,
}

const defaultState = {
  progress: {
    currentStep: 0,
    totalSteps: 4
  },
  appLogo: '',
  status: 'loading' as TRequestStatus,
  theme: 'system',
  accent: '',
  showMood: false,
  welcome: 'moment-of-day',
  sysProps: [],
  sessionExpired: false,
  appContext: {
    mobileNavOpen: false,
  },
  location: {
    prevPath: ""
  },
  transitioning: false
}

class App extends Component<Props, State> {
  private appRef: React.RefObject<HTMLInputElement>;
  private themes = [
    '',
    'light-theme',
  ]

  constructor(props: Props) {
    super(props);
    this.appRef = React.createRef();
    this.state = {
      ...this.getLocalStorageData(),
      ...defaultState,
    };
  }

  private getLocalStorageData() {
    return {
      token: localStorage.getItem("id_token") || null,
      tokenExpiration: localStorage.getItem("id_token_expiration") || null,
      tokenStamp: localStorage.getItem("id_token_stamp") || null,
    }
  }

  unlockApplication = () => {
    this.setState({
      ...this.getLocalStorageData()
    }, async () => {
      // get logged user after state update
      const currentTimestamp = Date.now();
      const tokenTimestamp = Number(this.state.tokenStamp)
      const tokenExpiration = Number(this.state.tokenExpiration)

      if(currentTimestamp < tokenTimestamp + tokenExpiration * 1000) {
        let loggedUser = await this.fetchLoggedUser();
        if(!this.isPasswordExpired(loggedUser)) {
          this.fetchAppLogo();
          this.fetchHolidays();
          this.fetchUsers();
          this.fetchSystemProps();
        }
      } else {
        if(this.state.token) {
          this.setState({
            sessionExpired: true,
          }, () => {
            this.handleLogout();
          });
        }
      }
    });
  }

  isPasswordExpired = (loggedUser: LoggedUser): boolean => {
    const now = (new Date()).getTime();
    const lastPasswordUpdate = loggedUser.lastPasswordUpdate ? new Date(loggedUser.lastPasswordUpdate).getTime() : 0;
    if(now - lastPasswordUpdate > parseInt(process.env.REACT_APP_PASS_MAX_TIME || "0") || loggedUser.mustUpdatePassword) {
      return true;
    }
    return false;
  }

  lockApplication = () => {
    this.setState((prevState: State) => {
      return {
        ...this.getLocalStorageData(),
        progress: {
          currentStep: 0,
          totalSteps: prevState.progress.totalSteps
        }
      }
    });
  }

  handleLogout = () => {
    localStorage.removeItem("id_token");
    localStorage.removeItem("id_token_expiration");
    localStorage.removeItem("id_token_stamp");

    this.setState({
      ...this.getLocalStorageData(),
      ...defaultState,
    })

    this.lockApplication();
  }

  fetchHolidays = async () => {
    this.setState({
      status: 'loading'
    });
    
    const holidays = await listHolidays();

    this.setState(prevState => {
      return {
        status: 'success',
        appContext: {
          ...prevState.appContext,
          holidays,
        },
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  fetchUsers = async () => {
    this.setState({
      status: 'loading'
    });
    
    const users = await listUsers();

    this.setState(prevState => {
      return {
        status: 'success',
        appContext: {
          ...prevState.appContext,
          users,
        },
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  fetchSystemProps = async () => {
    this.setState({
      status: 'loading'
    });
    
    const sysProps = await listSysprops();

    this.setState(prevState => {
      return {
        status: 'success',
        appContext: {
          ...prevState.appContext,
          sysProps,
        },
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  fetchLoggedUser = async () => {
    this.setState({
      status: 'loading'
    });
    
    const data: any = await listLoggedUser();
    const loggedUser = {
      id:     data.id,
      email:  data.email,
      name:   data.name,
      avatar: data.avatar,
      role:   data.role,
      streak: data.streak,
      lastPasswordUpdate: data.lastPasswordUpdate,
      mustUpdatePassword: data.mustUpdatePassword
    }

    this.setState(prevState => {
      return {
        status: 'success',
        appContext: {
          ...prevState.appContext,
          loggedUser,
        },
        theme: this.getLocalStorageKey('theme', data.id, 'system'),
        accent: this.getLocalStorageKey('accent', data.id, ''),
        showMood: this.getLocalStorageKey('showMood', data.id, 'false') == 'true' ? true : false,
        welcome: this.getLocalStorageKey('welcome', data.id, 'moment-of-day'),
        progress: incrementProgress(prevState.progress),
        loggedUser: loggedUser,
      }
    });

    return loggedUser;
  }

  getLocalStorageKey = (key: string, userId: number, defaultValue: string) => {
    return localStorage.getItem(userId + key) === null ? defaultValue : localStorage.getItem(userId + key)!;
  }

  setTheme = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, selectedTheme: number | string | undefined | {value: string, label: string}) => {
    localStorage.setItem(this.state.appContext.loggedUser?.id + 'theme', String(selectedTheme));
    this.appRef.current?.classList.remove(this.themes.join(','));
    selectedTheme !== '' && this.appRef.current?.classList.add(String(selectedTheme));

    this.setState({
      theme: String(selectedTheme)
    });
  }

  setAccent = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, selectedAccent: number | string | undefined | {value: string, label: string}) => {
    localStorage.setItem(this.state.appContext.loggedUser?.id + 'accent', String(selectedAccent));
    this.appRef.current?.classList.remove(this.themes.join(','));
    selectedAccent !== '' && this.appRef.current?.classList.add(String(selectedAccent));

    this.setState({
      accent: String(selectedAccent)
    });
  }

  setMobileNavOpen = () => {
    this.setState((prevState: State) => {
      return {
        appContext: {
          ...prevState.appContext,
          mobileNavOpen: !prevState.appContext.mobileNavOpen
        }
      }
    });
  }

  closeMobileNav = () => {
    const state = {...this.state};

    this.setState({
      appContext: {
        ...state.appContext,
        mobileNavOpen: false
      }
    })
  }

  setWelcome = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, selectedWelcome: number | string | undefined | {value: string, label: string}) => {
    localStorage.setItem(this.state.appContext.loggedUser?.id + 'welcome', String(selectedWelcome));
    this.appRef.current?.classList.remove(this.themes.join(','));
    selectedWelcome !== '' && this.appRef.current?.classList.add(String(selectedWelcome));

    this.setState({
      welcome: String(selectedWelcome)
    });
  }

  setMood = (moodState: string) => {
    localStorage.setItem(this.state.appContext.loggedUser?.id + 'showMood', moodState);
    moodState == 'true' ? this.appRef.current?.classList.add('show-mood') : this.appRef.current?.classList.remove('show-mood');

    this.setState({
      showMood: moodState == 'true'
    });
  }

  setSessionExpired = (sessionExpired: boolean) => {
    this.setState({
      sessionExpired: sessionExpired,
    })
  }

  setLoggedUser = (loggedUser: LoggedUser) => {
    this.setState({
      loggedUser
    })
  }

  fetchAppLogo = async () => {
    this.setState({
      status: 'loading'
    });
    
    const appLogo = await currentLogo();

    this.setState({
        status: 'success',
        appLogo: appLogo.toString(),
    });
  }

  componentDidMount() {
    this.unlockApplication();
    this.closeSession()

    //Close mobile navigation on route change
    this.props.history.listen(location => this.closeMobileNav());
  }

  componentWillUnmount(): void {
    //Unlisten history on unmount
    this.props.history.listen(location => {});
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.location !== this.props.location) {
      this.setState({
        location: {
          prevPath: this.props.location.pathname
        }
      })
    }
  }

  evaluateTheme(): string {
    if(this.state.theme == "system" || !this.state.token) {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark-theme" : "light-theme"
    } else {
      return this.state.theme;
    }
  }

  closeSession() {
    setInterval(() => {
      this.setState({
        ...this.getLocalStorageData(),
      }, () => {
        const currentTimestamp = (new Date()).getTime();
        const tokenTimestamp = Number(this.state.tokenStamp);
        const tokenExpiration = Number(this.state.tokenExpiration) * 1000;
  
        if(currentTimestamp > tokenTimestamp + tokenExpiration) {
          this.handleLogout()
        }
      })
    }, 60 * 1000)
  }

  setTransitioning(value: boolean) {
    this.setState({
      transitioning: value
    })
  }

  renderAppRouter = () => {
    const getTransitionType = (path: string, prevPath: string) => {
      if(path === prevPath) {
        return "instant";
      }

      let currentSegments = path.split("/");
      let prevSegments = prevPath.split("/");

      //if path was deeper /user/edit and now path is shallow /user reversed is true
      let reversed = false;
      //if path is different completely vertical is true
      let horizontal = false;
      if(currentSegments[1] === prevSegments[1]) {
        horizontal = true;
        
        if(currentSegments.length < prevSegments.length) {
          reversed = true;
        }
      }

      return (horizontal ? "horizontal" : "vertical") + (reversed ? "-reverse" : "");
    } 


    return (
      <Route
        render={({ location }) => {

        // .../291387192387/... => .../:id/...
        // .../213234234243     => .../:id
        let prevPath = this.state.location.prevPath.replaceAll(/\/\d+(\/?|$)/g, '/:id$1');
        let path = location.pathname.replaceAll(/\/\d+(\/?|$)/g, '/:id$1');

        // ignore ending /:id
        prevPath = prevPath.replace(/\/:id$/, '');
        path = path.replace(/\/:id$/, '');

        return (
          <TransitionGroup
            childFactory={child => React.cloneElement(
              child,
              {classNames: getTransitionType(path, prevPath), timeout: 400}
            )}
          >
            <CSSTransition 
              key={location.key}
              // key={location.pathname}
              // key={this.props.location.pathname} 
              timeout={400}
              onEnter={() => {
                this.setTransitioning(true);
              }}
              onExited={() => {
                this.setTransitioning(false);
              }}
              classNames={[
                getTransitionType(path, prevPath),
              ].join(" ")}
              //experiments for portals
              // onExit={() => {
              //   let portalChildren = document.getElementById("toolbarControls")!.children;
              //   for(let i = 0; i < portalChildren.length; i++) {
              //     (portalChildren[i] as HTMLElement).style.display = "none";
              //   }
              // }}
            >
                <Switch location={location}>
                  <Route exact path="/">
                      <Dashboard
                        holidays={this.state.appContext.holidays}
                        theme={this.evaluateTheme()}
                        welcomeTheme={this.state.welcome}
                      />
                  </Route>
                  {/* <Route path="/avatar-generator">
                    <AvatarGenerator 
                      setLoggedUser={this.setLoggedUser} 
                      loggedUser={this.state.appContext.loggedUser} />
                  </Route> */}
                  <Route path={[
                    "/account"]}
                    render={(props: RouteComponentProps<{id?:string}>) =>
                      <Preferences
                        setLoggedUser={this.setLoggedUser} 
                        loggedUser={this.state.loggedUser!} {...props}
                      />
                    }
                  />
                  <Route path="/app-settings">
                    <AppSettings
                      setTheme={this.setTheme}
                      setAccent={this.setAccent}
                      setWelcome={this.setWelcome}
                      setMood={this.setMood}
                      loggedUser={this.state.appContext.loggedUser}
                    />
                  </Route>
                  <Route 
                    path={[
                      "/timetrack/:id",
                      "/timetrack",
                    ]}
                    render={(props: RouteComponentProps<{id?: string}>) => {
                      if(this.state.appContext.sysProps?.length && this.state.appContext.loggedUser && this.state.appContext.holidays && this.state.appContext.users) {
                        return <Timetrack
                          sysProps={this.state.appContext.sysProps}
                          loggedUser={this.state.appContext.loggedUser}
                          {...props}
                        />
                      }
                      return null;
                    }}
                  />
                  <Route path={[
                      "/bookings/:id",
                      "/bookings",
                    ]}
                    render={(props: RouteComponentProps<{id?:string}>) => {
                      if (this.state.appContext.sysProps?.length && this.state.appContext.holidays && this.state.appContext.loggedUser && this.state.appContext.users) {
                        return <Bookings
                          sysProps={this.state.appContext.sysProps}
                          loggedUser={this.state.appContext.loggedUser}
                          {...props}
                        />
                      }
                      return null;
                    }}
                  >
                  </Route>
                  <Route path={[
                      "/remotes/:id",
                      "/remotes",
                    ]}
                    render={(props: RouteComponentProps<{id?:string}>) => {
                      if (this.state.appContext.sysProps?.length && this.state.appContext.holidays && this.state.appContext.loggedUser && this.state.appContext.users) {
                        return <Remotes
                          sysProps={this.state.appContext.sysProps}
                          loggedUser={this.state.appContext.loggedUser}
                          {...props}
                        />
                      }
                      return null;
                    }}
                  >
                  </Route>
                  <Route path="/team">
                    {this.state.appContext.loggedUser &&
                      <Team />
                    }
                  </Route>
                  <Route path="/groups-board">
                    {this.state.appContext.loggedUser &&
                      <GroupsBoard />
                    }
                  </Route>
                  <Route exact path="/assigned-procedures">
                    {this.state.appContext.loggedUser &&
                      <ProceduresBoard />
                    }
                  </Route>
                  <Route exact path="/tenant">
                      <TenantList />
                  </Route>
                  <Route path={[
                    "/tenant/edit/:id",
                    "/tenant/add"]}
                    component={TenantDetail}
                  />
                  <Route exact path="/event">
                    <EventList />
                  </Route>
                  <Route path={[
                    "/event/edit/:id",
                    "/event/add"]}
                    component={EventDetail}
                  />
                  <Route exact path="/water">
                    <WaterUtilList />
                  </Route>
                  <Route path={[
                    "/water/edit/:id",
                    "/water/add"]}
                    component={WaterUtilDetail}
                  />
                  <Route exact path="/electricity">
                    <ElectricityUtilList />
                  </Route>
                  <Route path={[
                    "/electricity/edit/:id",
                    "/electricity/add"]}
                    component={ElectricityUtilDetail}
                  />
                  <Route exact path="/gas">
                    <GasUtilList />
                  </Route>
                  <Route path={[
                    "/gas/edit/:id",
                    "/gas/add"]}
                    component={GasUtilDetail}
                  />
                  <Route exact path="/item">
                    <ItemList />
                  </Route>
                  <Route path={[
                    "/item/edit/:id",
                    "/item/add"]}
                    component={ItemDetail}
                  />
                  <Route exact path="/secucard">
                    <SecucardList />
                  </Route>
                  <Route path={[
                    "/secucard/edit/:id",
                    "/secucard/add"]}
                    component={SecucardDetail}
                  />
                  <Route exact path="/secukey">
                    <SecukeyList />
                  </Route>
                  <Route path={[
                    "/secukey/edit/:id",
                    "/secukey/add"]}
                    component={SecukeyDetail}
                  />
                  <Route exact path="/secucode">
                    <SecucodeList />
                  </Route>
                  <Route path={[
                    "/secucode/edit/:id",
                    "/secucode/add"]}
                    component={SecucodeDetail}
                  />
                  <Route exact path="/provider">
                    <ProviderList />
                  </Route>
                  <Route path={[
                    "/provider/edit/:id",
                    "/provider/add"]}
                    component={ProviderDetail}
                  />
                  <Route exact path="/user">
                    <UserList loggedUser={this.state.appContext.loggedUser!} />
                  </Route>
                  <Route path={[
                    "/user/edit/:id",
                    "/user/add"]}
                    render={(props: RouteComponentProps<{id?:string}>) =>
                      <UserDetail loggedUser={this.state.appContext.loggedUser!} {...props} />
                    }
                  />
                  <Route exact path="/benefit">
                    <BenefitList />
                  </Route>
                  <Route path={[
                    "/benefit/edit/:id",
                    "/benefit/add"]}
                    render={(props: RouteComponentProps<{id?:string}>) =>
                      <BenefitDetail loggedUser={this.state.appContext.loggedUser!} {...props} />
                    }
                  />
                  <Route exact path="/group">
                    <GroupsList />
                  </Route>
                  <Route path={[
                    "/group/edit/:id",
                    "/group/add"]}
                    render={(props: RouteComponentProps<{id?:string}>) =>
                      <GroupDetail loggedUser={this.state.appContext.loggedUser!} {...props} />
                    }
                  />
                  <Route exact path="/procedure">
                    <ProceduresList />
                  </Route>
                  <Route path={[
                    "/procedure/edit/:id",
                    "/procedure/add"]}
                    render={(props: RouteComponentProps<{id?:string}>) =>
                      <ProcedureDetail {...props} />
                    }
                  />
                  <Route path={[
                    "/assigned-procedures/view/:id"
                  ]}
                    render={(props: RouteComponentProps<{id?:string}>) =>
                      <ProcedureViewer loggedUser={this.state.appContext.loggedUser!} {...props} />
                    }
                  />
                  <Route exact path="/contract">
                    <ContractList />
                  </Route>

                  <Route path={[
                    "/:entity/deep/:eid/contract/edit/:id",
                    "/:entity/deep/:eid/contract/add",
                    "/contract/edit/:id",
                    "/contract/add"]}
                    render={(props: RouteComponentProps<{entity?: string, eid?: string, id?:string}>) =>
                      <ContractDetail loggedUser={this.state.appContext.loggedUser!} {...props} />
                    }
                  />
                  <Route path={[
                    "/:entity/deep/:eid/payprofile/edit/:id",
                    "/:entity/deep/:eid/payprofile/add"]}
                    render={(props: RouteComponentProps<{entity?: string, eid?: string, id?:string}>) =>
                      <PayprofileDetail loggedUser={this.state.appContext.loggedUser!} {...props} />
                    }
                  />
                  <Route path={[
                    "/:entity/deep/:eid/:subentity/deep/:seid/work-profile/edit/:id",
                    "/:entity/deep/:eid/:subentity/deep/:seid/work-profile/add",
                    "/:entity/deep/:eid/work-profile/edit/:id",
                    "/:entity/deep/:eid/work-profile/add"]}
                    render={(props: RouteComponentProps<{entity?: string, eid?: string, id?:string}>) =>
                      <WorkProfileDetail loggedUser={this.state.appContext.loggedUser!} {...props} />
                    }
                  />
                  <Route exact path="/badge">
                    <BadgeList />
                  </Route>
                  <Route path={[
                    "/badge/edit/:id",
                    "/badge/add"]}
                    render={(props: RouteComponentProps<{id?:string}>) =>
                      <BadgeDetail loggedUser={this.state.appContext.loggedUser!} {...props} />
                    }
                  />
                  <Route exact path="/compensation">
                    <CompensationList />
                  </Route>
                  <Route path={[
                    "/:entity/deep/:eid/compensation/edit/:id",
                    "/:entity/deep/:eid/compensation/add"]}
                    component={CompensationDetail}
                  />
                  <Route path={[
                    "/client/edit/:id",
                    "/client/add"]}
                    component={ClientDetail} />
                  <Route exact path="/suspension">
                    <SuspensionList />
                  </Route>
                  <Route path={[
                    "/:entity/deep/:eid/suspension/edit/:id",
                    "/:entity/deep/:eid/suspension/add",
                    "/suspension/edit/:id",
                    "/suspension/add"]}
                    component={SuspensionDetail}
                  />
                  <Route exact path="/client">
                    <ClientList />
                  </Route>
                  <Route path={[
                    "/client/edit/:id",
                    "/client/add"]}
                    component={ClientDetail}
                  />
                  <Route path="/reports-daily-target">
                    {this.state.appContext.sysProps?.length && this.state.appContext.loggedUser && this.state.appContext.holidays &&
                      <DailyTarget
                        sysProps={this.state.appContext.sysProps}
                        loggedUser={this.state.appContext.loggedUser}
                      />
                    }
                  </Route>
                  <Route path="/clocking-report">
                    {this.state.appContext.sysProps?.length && this.state.appContext.loggedUser && this.state.appContext.holidays &&
                      <ClockingReport
                        sysProps={this.state.appContext.sysProps}
                      />
                    }
                  </Route>
                  <Route path="/reports-billability">
                    {this.state.appContext.sysProps?.length && this.state.appContext.loggedUser && this.state.appContext.users &&
                      <Billability 
                        sysProps={this.state.appContext.sysProps}
                      />
                    }
                  </Route>
                  <Route path="/reports-user-activity">
                    {this.state.appContext.sysProps?.length &&
                      <UserActivity
                      sysProps={this.state.appContext.sysProps}
                      />
                    }
                  </Route>
                  <Route
                    path="/invoices-hourly-by-project"
                    render={(props: RouteComponentProps) =>
                      <HourlyProjectInvoice sysProps={this.state.appContext.sysProps!} {...props} />
                    }
                  />
                  <Route
                    path="/invoices-hourly-by-user"
                    render={(props: RouteComponentProps) =>
                      <HourlyUserInvoice sysProps={this.state.appContext.sysProps!} {...props} />
                    }
                  />
                  <Route
                    path="/invoices-piece"
                    render={(props: RouteComponentProps) =>
                      <PieceInvoice sysProps={this.state.appContext.sysProps!} {...props} />
                    }
                  />
                  <Route exact path="/computer">
                    <ComputerList />
                    </Route>
                    <Route path={[
                      "/computer/edit/:id",
                      "/computer/add"]}
                      component={ComputerDetail}
                  />
                  <Route exact path="/project">
                    <ProjectList />
                  </Route>
                  <Route path={[
                    "/:entity/deep/:eid/project/edit/:id",
                    "/:entity/deep/:eid/project/add",
                    "/project/edit/:id",
                    "/project/add"]}
                    component={ProjectDetail}
                  />
                  <Route path={[
                    "/:entity/deep/:eid/project-groups/edit/:id",
                    "/:entity/deep/:eid/project-groups/add"]}
                    component={ProjectGroupDetail}
                  />
                  <Route exact path="/role">
                    <RoleList />
                  </Route>
                  <Route path={[
                    "/role/edit/:id",
                    "/role/add"]}
                    render={(props: RouteComponentProps<{id?:string}>) =>
                      <RoleDetail {...props} />
                    }
                  />
                  <Route exact path="/logo">
                      <LogoList />
                  </Route>
                  <Route path={[
                    "/logo/edit/:id",
                    "/logo/add"]}
                    component={LogoDetail}
                  />
                  <Route exact path="/sysprop">
                    <SysPropList />
                  </Route>
                  <Route path={[
                    "/sysprop/edit/:id",
                    "/sysprop/add"]}
                    component={SysPropDetail}
                  />
                  <Route path={[
                    "/clocking/:id",
                    "/clocking"]}
                    render={(props: RouteComponentProps<{id?:string}>) => {
                      if (this.state.appContext.sysProps?.length && this.state.appContext.loggedUser && this.state.appContext.holidays) {
                        return <Clocking
                          sysProps={this.state.appContext.sysProps}
                          loggedUser={this.state.appContext.loggedUser}
                          {...props}
                        />
                      }
                    }}
                  >
                  </Route>
                  <Route exact path="/holiday">
                    <HolidayList sysProps={this.state.appContext.sysProps}/>
                  </Route>
                  <Route path={[
                    "/holiday/edit/:id",
                    "/holiday/add"]}
                    component={HolidayDetail}
                  />
                  <Route exact path="/404">
                    <NotFoundPage
                      theme={this.evaluateTheme()}
                    />
                  </Route>
                  <Route path="*">
                    <NotFoundPage
                      theme={this.evaluateTheme()}
                    />
                  </Route>
                </Switch>
            </CSSTransition>
          </TransitionGroup>
        );
      }}
    />
    )
  };

  render() {
    return (
      <AppContext.Provider value={this.state.appContext}>
        <div
          className={[
            "CONDUIT app-like",
            this.evaluateTheme(),
            this.state.token ? this.state.accent : "red-accent",
            this.state.theme == 'dark-theme',
            this.state.showMood ? "show-mood" : "",
          ].join(" ")}
          ref={this.appRef}
        >
          {this.state.token && this.state.loggedUser && !this.isPasswordExpired(this.state.loggedUser) &&
            <React.Fragment>
              <div className="center top-tight">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
              {this.state.appContext.loggedUser && this.state.appContext.sysProps &&
                <>
                  <aside className={
                    [
                      "sidebar-component offset-start-tablet hide-print",
                      this.state.appContext.mobileNavOpen ? "open" : ""
                    ].join(" ")
                  }>
                    <div className="bar-content">
                      <Navigation
                        loggedUser={this.state.appContext.loggedUser}
                        setMobileNavOpen={this.setMobileNavOpen}
                      />
                      <div className="bar-content-end">
                        <Link className="brand-wrapper" to="/">
                          <img className="brand" src={this.state.appLogo} alt="Workstrym" />
                        </Link>
                      </div>
                    </div>
                  </aside>
                  <header className="topbar-component offset-start hide-print">
                    <div className="bar-content">
                      <Topbar
                        handleLogout={this.handleLogout}
                        loggedUser={this.state.loggedUser}
                        setMobileNavOpen={this.setMobileNavOpen}
                      />
                    </div>
                  </header>
                  <main className="main-component">
                    <div
                      id="headerBreadcrums"
                      className="breadcrumb-component hide-print"
                    ></div>
                    <div
                      // style={{
                      //   overflow: this.state.transitioning ? "hidden" : ""
                      // }}
                    >
                      {/* <div className="scroll-content"> */}
                          {this.renderAppRouter()}
                      {/* </div> */}
                    </div>
                  </main>
                </>
              }

            </React.Fragment>
          }
          {this.state.token && this.state.loggedUser && this.isPasswordExpired(this.state.loggedUser) &&
            <React.Fragment>
              <ResetPass
                callback={this.unlockApplication}
                logout={this.handleLogout}
                theme={this.evaluateTheme()}
              />
            </React.Fragment>
          }
          {!this.state.token &&
            <React.Fragment>
              <Login unlockApp={this.unlockApplication} sessionExpired={this.state.sessionExpired} setSessionExpired={this.setSessionExpired}/>
            </React.Fragment>
          }
        </div>
      </AppContext.Provider>
    );
  }
}

export default App;
