import Joi from 'joi';
import moment from 'moment';
import React, { ChangeEvent, Component, KeyboardEvent, } from 'react';
import { RouteComponentProps } from 'react-router';
import SecucardDTO from '../../../../common/api/dtos/Secucard';
import UserDTO from '../../../../common/api/dtos/User';
import { addSecucard, listSecucardDetails, updateSecucard } from '../../../../common/api/endpoints/secucards';
import { listUsers } from '../../../../common/api/endpoints/users';
import AppContext from '../../../../common/context/AppContext';
import { FormErrors } from '../../../../common/data/FormErrors';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import TextAreaControl from '../../../controls/TextAreaControl/TextAreaControl';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TextControl from '../../../controls/TextControl/TextControl';
import SelectControl from '../../../controls/SelectControl/SelectControl';
import { withTransitionEvent } from '../../../TransitionEvent';

export interface Props {
  id: string,
}

interface FormData {
  name: string,
  internalId?: string,
  iconUrl: string,
  description: string,
  comments: string,
  tileColor: string,
  lastUpdate: string,
  userId?: number | null,
  deleted: boolean,
}

interface State {
  secucardId: string,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  formData: FormData,
  secucards?: SecucardDTO[],
  users?: UserDTO[],
  formErrors: StateFormErrors,
}

type StateFormErrors = {
  [key in keyof FormData]?: string;
}

class SecucardDetail extends Component<RouteComponentProps<Props>, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    internalId: Joi.string().trim(true).required().messages(FormErrors.string),
    iconUrl: Joi.string().trim(true).allow(null, ''),
    description: Joi.string().trim(true).allow(null, ''),
    comments: Joi.string().trim(true).allow(null, ''),
    tileColor: Joi.string().trim(true).allow(null, ''),
    lastUpdate: Joi.string().trim(true).allow(null, ''),
    userId: Joi.number().allow(null, 0),
    deleted: Joi.boolean(),
  });

  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {
      secucardId: this.props.match.params.id,
      formData: {
        name: '',
        internalId: '',
        iconUrl: 'inventory.png',
        description: '',
        comments: '',
        tileColor: '',
        lastUpdate: moment(new Date()).format('Y-MM-DD'),
        deleted: false,
      },
      progress: {
        currentStep: 0,
        totalSteps: 2
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchSecucardsDetails = async () => {
    this.setState({
      pageStatus: 'loading'
    });

    const secucard = await listSecucardDetails(this.state.secucardId);

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        formData: {
          name: secucard.name,
          internalId: secucard?.internalId,
          iconUrl: secucard.iconUrl,
          description: secucard.description,
          comments: secucard.comments,
          tileColor: secucard.tileColor,
          lastUpdate: moment(new Date(secucard.lastUpdate)).format('Y-MM-DD'),
          userId: secucard.userId ?? null,
          deleted: secucard.deleted,
        },
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  fetchUsers = async () => {
    this.setState({
      pageStatus: 'loading'
    });

    const users = await listUsers();

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        users,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });

    let result = await addSecucard(this.state.formData);

    if ('error' in result) {
      if (result.error.code === 'unique') {
        this.updateFormError(result.error.field, 'InternalId must be unique');
      }
      this.setState({ formStatus: 'error' });
    } else {

      this.setState({
        formStatus: 'success',
      }, () => {
        this.props.history.push({
          pathname: '/secucard'
        })
      });
    }
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });

    let result = await updateSecucard(this.state.formData, this.state.secucardId);
    if ('error' in result) {
      if (result.error.code === 'unique') {
        this.updateFormError(result.error.field, 'InternalId must be unique');
      }
      this.setState({ formStatus: 'error' });
    } else {
      this.setState({
        formStatus: 'success',
        formData: {
          ...this.state.formData,
          lastUpdate: moment(new Date()).format('Y-MM-DD'),
        }
      });
    }
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });

      if (!isEditPage(Number(this.state.secucardId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as StateFormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    this.fetchUsers();

    if (isEditPage(Number(this.state.secucardId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 2
          }
        }
      });
      this.fetchSecucardsDetails()
    } else {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1
          }
        }
      });
    }
  }

  updateFormError<K extends keyof StateFormErrors>(field: K, value: StateFormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value
      }
    })
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setInternalId = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('internalId', ev.target.value);
  setUrl = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('iconUrl', ev.target.value);
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);
  setComments = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('comments', ev.target.value);
  setTileColor = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('tileColor', ev.target.value);
  setLastUpdate = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('lastUpdate', ev.target.value);
  setUserId = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent, userId: number | string | undefined | UserDTO) => {this.updateFormData('userId', userId as number | undefined);}
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);


  render() {
    return (
      <React.Fragment>
        <ProgressBar
          currentStep={this.state.progress.currentStep}
          totalSteps={this.state.progress.totalSteps}
        />
        <BreadcrumbControls
          items={[
            { label: 'Access cards', path: '/secucard' },
            { label: `${!isEditPage(Number(this.state.secucardId)) ? 'adding' : 'editing'} card ${this.state.formData.name}` },
          ]}
          status={this.state.pageStatus}
        />
        <div className="flex-row">
          <div className="column">
            <div className="card">
              <TextControl
                label="Name"
                type="text"
                name="name"
                id="name"
                value={this.state.formData.name}
                onChange={this.setName}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.name}
              />
              <TextControl
                label="Internal ID"
                type="text"
                name="internalId"
                id="internalId"
                value={this.state.formData?.internalId}
                onChange={this.setInternalId}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.internalId}
              />
              <div className="flex-row fill">
                <div className="column">
                  <TextControl
                    label="File Name"
                    type="text"
                    name="iconUrl"
                    id="iconUrl"
                    value={this.state.formData.iconUrl}
                    onChange={this.setUrl}
                    disabled={!loadComplete(this.state.progress)}
                    br={true}
                    onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                  />
                </div>
                <div className="column flex-v-center">
                  <img
                    className="thumbnail padded"
                    src={process.env.REACT_APP_INVENTORY_PATH + this.state.formData.iconUrl}
                    style={{
                      "backgroundColor": this.state.formData.tileColor
                    }}
                    alt="avatar"
                  />
                </div>
              </div>
              <TextControl
                label="Tile Color"
                type="text"
                name="tileColor"
                id="tileColor"
                value={this.state.formData.tileColor}
                onChange={this.setTileColor}
                disabled={!loadComplete(this.state.progress)}
                br={true}
              />
              <TextAreaControl
                label="Description"
                id="description"
                name="description"
                value={this.state.formData.description}
                onChange={this.setDescription}
                disabled={!loadComplete(this.state.progress)}
                rows={3}
                cols={30}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
              />
              <TextAreaControl
                label="Comments"
                id="comments"
                name="comments"
                value={this.state.formData.comments}
                onChange={this.setComments}
                disabled={!loadComplete(this.state.progress)}
                rows={3}
                cols={30}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
              />
              {isEditPage(Number(this.state.secucardId)) &&
                (<TextControl
                  label="Last update"
                  type="date"
                  name="lastUpdate"
                  id="lastUpdate"
                  value={this.state.formData.lastUpdate}
                  onChange={this.setLastUpdate}
                  disabled={true}
                  br={true}
                />)}
              <SelectControl
                idName="userId"
                label="Employee"
                value={(this.state.formData.userId !== undefined && this.state.formData.userId !== null) ? this.state.formData.userId : 0}
                options={[{id: 0, fullName: 'None'} as UserDTO, ...(this.state.users || [])]}
                disabled={!loadComplete(this.state.progress)}
                onChange={this.setUserId}
                getValue={(op) => op.id}
                getLabel={(op) => op?.fullName || 'Unknown user'}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
              />
              {isEditPage(Number(this.state.secucardId)) ?
                <ToggleControl
                  id="deleted"
                  name="deleted"
                  changeMethod={this.setDeleted}
                  isChecked={this.state.formData.deleted}
                  isDisabled={!loadComplete(this.state.progress)}
                  labelText="Deleted"
                  onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                />
                : ''
              }
              <div className="form-group">
                <ButtonControl
                  class="primary-button"
                  onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    this.handleSubmit(ev)
                  }}
                  disabled={!loadComplete(this.state.progress)}
                >
                  <RequestStatus status={this.state.formStatus} />
                  <span className="text">Save</span>
                </ButtonControl>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTransitionEvent(SecucardDetail);

SecucardDetail.contextType = AppContext;