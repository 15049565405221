import Fetch from "../../helpers/Fetch";
import PayprofileDTO from "../dtos/Payprofile";

export async function listPayprofiles(): Promise<PayprofileDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<PayprofileDTO[]>(`/payprofiles`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch payprofiles');
}

export async function listUserPayprofiles(userId: number): Promise<PayprofileDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<PayprofileDTO[]>(`/payprofiles/user/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch user\'s payprofiles');
}

export async function listTenantPayprofiles(tenantId: number): Promise<PayprofileDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<PayprofileDTO[]>(`/payprofiles/tenant/${tenantId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch tenant\'s payprofiles');
}