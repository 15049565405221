import React, { ChangeEvent, Component } from 'react';
import Fetch from '../../../../common/helpers/Fetch';
import SyspropDTO from '../../../../common/api/dtos/Sysprop';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { listSysprops } from '../../../../common/api/endpoints/sysprops';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../../TransitionEvent';
import truncate from '../../../../common/helpers/Truncate';

interface State {
  sysprops?: SyspropDTO[],
  status: TRequestStatus,
  tableSort: TableSort,
  filterValue: string,
}

class SysPropList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'value',
    'description',
  ];
  
  constructor(props: {}) {
    super(props);
    
    this.state = {
      filterValue: '',
      status: 'loading',
      tableSort: new TableSort('name', 'asc'),
    };
  }

  fetchSysprops = async () => {
    this.setState({
      status: 'loading'
    });
    
    const sysprops = await listSysprops();

    this.setState({
        status: 'success',
        sysprops: sysprops,
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
       };
    });
  }

  componentDidMount() {
    this.fetchSysprops();
  }

  renderTHeadRow() {
    return(
      <tr>
        <th>#</th>
        <th>
          <SortButton 
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='value' 
            text='Value'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='description' 
            text='Description'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(sysprop: SyspropDTO) {
    return (
      <tr id={`sysprop${sysprop.id}`} key={`${sysprop.id}`}>
        <td></td>
        <td>
          <span>{sysprop.name}</span>
        </td>
        <td>
          <p className="text-chunk">{truncate(sysprop.value, 100)}</p>
        </td>
        <td>
          <p className="text-chunk">{sysprop.description}</p>
        </td>
        <td className="text-right">
          <GhostButtonControl to={`/sysprop/edit/${sysprop.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredProperties: Array<SyspropDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.sysprops ? this.state.sysprops : []).sort(this.state.tableSort.sortByColumn);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Properties"
          status={this.state.status}
        />
        {this.state.sysprops && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              /> 
            </ToolbarControls>
            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                      card
                      ${!this.state.sysprops ? 'loader-border' : ''}
                  `}>
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.sysprops &&
                        <React.Fragment>
                          <tbody>
                            {filteredProperties.map(sysprop => (
                              this.renderTBodyRow(sysprop)
                            ))}
                          </tbody>
                          <tfoot>
                            {this.state.sysprops?.length === 0 &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">There are no properties defined. Check with DevOps for more info.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredProperties.length} out of {this.state.sysprops.length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}
export default withTransitionEvent(SysPropList);

