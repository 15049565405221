import React, { ChangeEvent } from 'react';
import currencyFormatter from '../../../../common/helpers/CurrencyFormatter';
import { Currency, InvoiceItem } from '../types';

interface Props {
  invoiceItem: InvoiceItem;
  hasVat: boolean;
  currencySymbol?: string;
  onChange: (_: InvoiceItem) => void;
  quantityLabelA: string;
  quantityLabelB: string;
}

interface State {
  unitPrice: string,
}

export default class InvoiceRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      unitPrice: props.invoiceItem.unitPrice.toString(),
    }
  }

  private onUnitPriceChange = (ev: ChangeEvent<HTMLInputElement>) => {
    let input = ev.target.value;
    const isValidNumber = !Number.isNaN(Number(input))
    const isOnlyMinus = input === "-"
    // don't emit for non-number values
    if (isValidNumber || isOnlyMinus) {
      this.setState({ unitPrice: input });
      this.props.onChange({
        ...this.props.invoiceItem,
        unitPrice: Number(input)
      });
    }
  };

  private onQuantityChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const number = Number(ev.target.value);
    // don't emit for non-number values
    if (Number.isNaN(number) === false) {
      this.props.onChange({
        ...this.props.invoiceItem,
        quantity: number
      });
    }
  };

  render() {
    const { invoiceItem, hasVat, currencySymbol, quantityLabelA, quantityLabelB } = this.props;

    // Issue a warning if we're not receiving the expected fields when 'hasVat' is true
    console.assert(hasVat ? typeof invoiceItem.unitPriceExclVat === 'number' : true, "Expected 'unitPriceExclVat' prop of InvoiceRow to be a number when 'hasVat' is true");
    console.assert(hasVat ? typeof invoiceItem.vatAmount === 'number' : true, "Expected 'vatAmount' prop of InvoiceRow to be a number when 'hasVat' is true");

    return <tr>
      <td></td>
      <td>
        {invoiceItem.name.split("\n").map(function(item, index) {
          return (
            <React.Fragment>
              {index !== 0 &&
                <br />
              }
              <span key={index}>
                {item}
              </span>
            </React.Fragment>
          )
        })}
      </td>
      <td>
        <span>{quantityLabelA}<br/>{quantityLabelB}</span>
      </td>
      <td className="text-right">
        <span>{invoiceItem.quantity}</span>
      </td>
      <td className="text-right">
        <input type="text" value={this.state.unitPrice} onChange={this.onUnitPriceChange} className="hide-print"/>
        {!hasVat ? <span className="show-print">{currencyFormatter(Number(this.state.unitPrice), currencySymbol)}</span>
          :
          <span className="show-print">&nbsp;{currencyFormatter(invoiceItem.unitPriceExclVat!, currencySymbol)}</span>
        }
      </td>
      <td className="text-right">
        <span>{currencyFormatter(invoiceItem.amount, currencySymbol)}</span>
      </td>

      {hasVat && <td className="text-right">
        <span>{currencyFormatter(invoiceItem.vatAmount!, currencySymbol)}</span>
      </td>
      }
      <td></td>
    </tr>;
  }
}