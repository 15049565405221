import moment from "moment";
import React, { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import SuspensionDTO from "../../../common/api/dtos/Suspension";
import { listUserSuspensions } from "../../../common/api/endpoints/suspensions";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import GhostButtonControl from "../../controls/GhostButtonControl/GhostButtonControl";
import ToolbarControls from "../../generics/Header/ToolbarControls";
import DateTCell from "../../utils/DateTCell/DateTCell";
import TextControl from "../../controls/TextControl/TextControl";
import SortButton from "../../utils/SortButton/SortButton";
import { IProgress } from "../../utils/ProgressBar/ProgressBar";
import { withTransitionEvent } from "../../TransitionEvent";

interface Props {
  entityId: number,
  entity: 'user',
  hasFullAccess?: boolean
}

interface State {
  status: TRequestStatus,
  formStatus: TRequestStatus,
  progress: IProgress,
  suspensions?: SuspensionDTO[],
  showDeleted: boolean,
  tableSort: TableSort,
	propsForm: PropForm,
  filterValue: string,
  showSensitive: boolean,
}

interface PropForm {
  employment: string,
  fullName: string,
  position: string,
  hourNorm: number,
  phone: string,
  userId: number,
  suspensions: SuspensionDTO[],
}

class SuspensionsForm extends Component<Props, State> {
  availableFilters: string[] = [
    'startDate',
    'endDate',
    'user.name',
    'description',
    'contract.name',
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: 'idle',
      formStatus: 'idle',
      propsForm: {
        employment: '',
        fullName: '',
        position: '',
        hourNorm: 8,
        phone: '',
        userId: this.props.entityId,
        suspensions: [],
      },
      tableSort: new TableSort('name', 'asc'),
      showSensitive: false,
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      filterValue: '',
    }
  }

  sensitiveTimeout: number = 0;

  toggleSensitive = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.showSensitive ? this.setState({showSensitive: false}) : this.setState({showSensitive: true});
  }

  fetchEntitySuspensions = async () => {
    switch(this.props.entity) {
      case 'user': 
        return await listUserSuspensions(this.props.entityId);
    }
  }

  fetchSuspensions = async () => {
    this.setState({
      status: 'loading'
    });
    
    const suspensions = await this.fetchEntitySuspensions();

    this.setState({
        status: 'success',
        suspensions: suspensions,
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({ showDeleted: false }) : this.setState({ showDeleted: true });
  }

  componentDidMount() {
    this.fetchSuspensions();
  }

  commponentDidUpdate(prevProps: Props, prevState: State) {
    if(this.state.showSensitive !== prevState.showSensitive) {
      if(this.state. showSensitive) {
        this.sensitiveTimeout = setTimeout(() => {
          this.setState({showSensitive: false});
        }, parseInt(String(process.env.REACT_APP_SENSITIVE_DATA_TIMEOUT))) as unknown as number;
      } else {
        clearTimeout(this.sensitiveTimeout);
      }
    }
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  setAssigned = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentProjectId: number = Number(ev.target.value);
    this.setState((prevState: State) => {
      let assignedSuspensions = prevState.propsForm.suspensions.slice(0);
      let alreadyAssigned = assignedSuspensions.map((suspension: SuspensionDTO) => { return suspension.id; }).indexOf(currentProjectId);

      if(alreadyAssigned === -1) {
        let selectedProject = this.state.suspensions?.filter((suspension: SuspensionDTO) => suspension.id === currentProjectId)[0];
        if(selectedProject) {
          assignedSuspensions.push(selectedProject);
        }
      } else {
        assignedSuspensions.splice(alreadyAssigned, 1);
      }
      
      return {
        propsForm: {
          ...prevState.propsForm,
          suspensions: assignedSuspensions
        }
      }
    });
    
  }

  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th>
          <SortButton
            column='user.name'
            text='Signatory'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column='contract.name'
            text='Issuer'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column='description'
            text='Description'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column='startDate'
            text='Start Date'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column='endDate'
            text='End Date'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton
            column='countable'
            text='Affects paid days'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(suspension: SuspensionDTO) {
    return (
      <tr id={`suspension${suspension.id}`} key={`${suspension.id}`}>
        <td></td>
        <td>
          <span>{suspension.user.name}</span>
          <br />
          <small className={!this.state.showSensitive ? 'blurred-item' : 'blurred-item deblur'}>{suspension.user.fullName}</small>
        </td>
        <td>
          <span>{suspension.contract.name}</span>
          <br />
          <small className={!this.state.showSensitive ? 'blurred-item' : 'blurred-item deblur'}>{suspension.contract.tenant.legalName}</small>
        </td>
        <td>
          <div className={!this.state.showSensitive ? 'blurred-item' : 'blurred-item deblur'}>{suspension.description}</div>
        </td>
        <DateTCell date={suspension.startDate} />
        <DateTCell date={suspension.endDate} />
        <td className="text-center">
          {suspension.countable && <span className="fas fa-check"></span>
          }
        </td>
        <td className="text-right">
          {this.props.hasFullAccess && 
            <GhostButtonControl
              to={`/${this.props.entityId}/deep/${this.props.entityId}/suspension/edit/${suspension.id}`}
              class="ghost-button"
              icon="fas fa-angle-right"
            ></GhostButtonControl>
          }
        </td>
      </tr>
    );
  }

  render() {
    const filteredSuspensions: Array<SuspensionDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.suspensions ? this.state.suspensions : []).sort(this.state.tableSort.sortByColumn).filter((suspension: SuspensionDTO) => suspension.deleted === this.state.showDeleted);
    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
            {this.props.hasFullAccess &&
              <>
                <ToggleControl
                  id="showSensitive"
                  name="showSensitive"
                  changeMethod={this.toggleSensitive}
                  isChecked={this.state.showSensitive}
                  labelText="Sensitive data"
                  icon="fas fa-shield-alt"
                  srOnly={true}
                  title="Sensitive data"
                />
                <ToggleControl 
                  id="showDeleted"
                  name="showDeleted" 
                  changeMethod={this.toggleDeleted} 
                  isChecked={this.state.showDeleted}
                  labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
                  />
                <div className="form-group">
                  <Link to={`/${this.props.entity}/deep/${this.props.entityId}/suspension/add`} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add suspension</span></Link>
                </div>
              </>
            }
            </ToolbarControls>
        </div>
        <div className={`card ${!this.state.suspensions ? 'loader-border' : ''} ${this.state.showDeleted ? "flashing-border" : ""}`}>
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 315px)'}}>
                  <table>
                    <thead>
                      {this.renderTHeadRow()}
                    </thead>
                    {this.state.suspensions &&
                      <React.Fragment>
                        <tbody>
                          {filteredSuspensions.map(suspension => (this.renderTBodyRow(suspension)
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.suspensions?.length === 0 &&
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  {this.props.hasFullAccess ? 
                                    <p className="text-chunk">
                                      There are no suspensions defined for this {this.props.entity}. Start by <Link className="link-button" to={`/${this.props.entity}/deep/${this.props.entityId}/suspension/add`}>adding</Link> the first one.
                                    </p>
                                  :
                                    <p className="text-chunk">
                                      There are no suspensions.
                                    </p>
                                  }
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  <p className="text-chunk">Showing {filteredSuspensions.length} out of {this.state.suspensions.filter((suspension: SuspensionDTO) => suspension.deleted === this.state.showDeleted).length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTransitionEvent(SuspensionsForm);