import React from "react";
import { RouteComponentProps } from "react-router";
import SyspropDTO from "../../../../common/api/dtos/Sysprop";
import { ComponentProps, InvoicesLayout } from "./InvoicesLayout";

interface Props extends RouteComponentProps {
  sysProps: SyspropDTO[],
}

export function withInvoicesLayout(Component: React.ComponentType<ComponentProps>) {
  return class InvoicesPayoutProxy extends React.Component<Props> {
    render() {
      return <InvoicesLayout {...this.props} component={Component} />
    }
  }
}