import React, { ChangeEvent, Component } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import BenefitDTO from '../../../../common/api/dtos/Benefit';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { listBenefits } from '../../../../common/api/endpoints/benefits';
import Thumbnail from '../../../utils/Thumbnail/Thumbnail';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import { withTransitionEvent } from '../../../TransitionEvent';

interface State {
  benefits?: BenefitDTO[],
  progress: IProgress,
  status: TRequestStatus,
  showDisabled: boolean,
  showDeleted: boolean,
  tableSort: TableSort,
  selectedUser: string,
  filterValue: string,
}

class BenefitList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'conditions',
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: 'loading',
      showDisabled: localStorage.getItem('showDisabledBenefits') === 'false' || localStorage.getItem('showDisabledBenefits') === null ? false : true,
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      tableSort: new TableSort('name', 'asc'),
      selectedUser: '',
      filterValue: '',
    }
  }

  dropdownSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      selectedUser: event.target.value,
    });
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({showDisabled: false}) : this.setState({showDisabled: true});
  }

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  fetchBenefits = async () => {
    this.setState({
      status: 'loading'
    });
    
    const benefits = await listBenefits();

    this.setState(prevState => {
      return {
        status: 'success',
        benefits: benefits,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
      };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchBenefits();
  }
  
  renderTHeadRow() {
    return ( 
      <tr>
        <th>#</th>
        <th>
          <SortButton
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='description' 
            text='Mechanism'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='conditions' 
            text='Conditions'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        {this.evalDisabled() && 
          <th className="text-center">
            <SortButton 
              column='enabled' 
              text='Enabled'
              tableSort={this.state.tableSort}
              onClick={this.handleSortChange}
              ></SortButton>
          </th>
        }
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(benefit: BenefitDTO) {
    return (
      <tr id={`benefit${benefit.id}`} key={`${benefit.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <Thumbnail
                thumbnailURL={benefit.iconURL}
                classes="thumbnail padded"
              />
                <span>{benefit.name}</span>
            </div>
          </div>
        </td>
        <td>
          <p className="text-chunk">{benefit.description}</p>
        </td>
        <td>
          <p className="text-chunk">{benefit.conditions}</p>
        </td>
        {this.evalDisabled() && 
        <td className="text-center">
          {benefit.enabled && 
            <span className="fas fa-check"></span>
          }
        </td>
        }
        <td className="text-right">
          <GhostButtonControl to={`/benefit/edit/${benefit.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  evalDisabled(): boolean {
    return this.state.showDisabled || this.state.showDeleted;
  }

  render() {
    const filteredBenefits: Array<BenefitDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.benefits ? this.state.benefits : []).sort(this.state.tableSort.sortByColumn).filter((benefit: BenefitDTO) => benefit.deleted === this.state.showDeleted && (benefit.enabled === true || this.state.showDisabled || this.state.showDeleted));
    return (
      <>
        <BreadcrumbControls
          pageTitle="Benefits"
          status={this.state.status}
        />
        {this.state.benefits && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showDisabledBenefits"
                name="showDisabledBenefits"
                changeMethod={this.toggleDisabled}
                isChecked={this.evalDisabled()}
                isDisabled={this.state.showDeleted}
                labelText="Disabled"
              />
              <ToggleControl
                id="showDeleted"
                name="showDeleted"
                changeMethod={this.toggleDeleted}
                isChecked={this.state.showDeleted}
                labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
              />
              <div className="form-group">
                <Link to={'/benefit/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add benefit</span></Link>
              </div>
            </ToolbarControls>
            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                      card
                      ${!this.state.benefits ? 'loader-border' : ''}
                      ${this.state.showDeleted ? "flashing-border" : ""}
                    `}
                  >
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.benefits &&
                        <React.Fragment>
                          <tbody>
                            {filteredBenefits.map(benefit => (
                                this.renderTBodyRow(benefit)
                              ))
                            }
                          </tbody>
                          <tfoot>
                            {this.state.benefits?.length === 0 &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">There are no benefits defined. Start by <Link className="link-button" to="/benefit/add">adding</Link> the first one.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredBenefits.length} out of {this.state.benefits.filter((benefit: BenefitDTO) => benefit.deleted === this.state.showDeleted && (benefit.enabled === true || this.state.showDisabled || this.state.showDeleted)).length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  };
}
export default withTransitionEvent(BenefitList);
