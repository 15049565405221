import Fetch from "../../helpers/Fetch";
import VacationsDTO, { VacationsEveryoneDTO } from "../dtos/Vacations";

export async function listUserVacations(userId: number): Promise<VacationsDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<VacationsDTO[]>(`/vacations/filter/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      uid: userId,
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch user vacations');
}

export async function listRemainingPaidDays(year: number, userId:number, contractId: number | null): Promise<number> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<number>(`/vacations/paid-days?year=${year}&userId=${userId}&contractId=${contractId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch list of remaining paid days for user ${userId}`);
}

export async function listEveryoneVacations(year: number, userId: number): Promise<VacationsEveryoneDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<VacationsEveryoneDTO[]>(`/vacations/all?tzOffset=${(new Date()).getTimezoneOffset()}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      year: year,
      uid: userId,
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch list of everyones vacation`);
}

export async function SaveBooking(vacation: VacationsDTO): Promise<VacationsDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<VacationsDTO>(`/vacations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(vacation),
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to save vacations');
}

export async function UpdateVacation(vacation: VacationsDTO): Promise<VacationsDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<VacationsDTO>(`/vacations/${vacation.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(vacation),
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to update vacations');
}

export async function CancelBooking(bookingId: number): Promise<VacationsDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<VacationsDTO>(`/vacations/${bookingId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to delete vacation');
}