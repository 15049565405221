import { ValidationError } from "joi";

export function processJoiError(error: ValidationError) {
    const result: {[key: string]: string} = {};
    
    for (const err of error.details) {
      const key = err.path[0];
      result[key] = err.message;
    }
    return result;
  }
  