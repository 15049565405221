import React, { ChangeEvent, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import ClientDTO from '../../../../common/api/dtos/Client';
import TenantDTO from '../../../../common/api/dtos/Tenant';
import SyspropDTO from '../../../../common/api/dtos/Sysprop';
import { listClients } from '../../../../common/api/endpoints/clients';
import { listTenants } from '../../../../common/api/endpoints/tenants';
import { Month, Months } from '../../../../common/data/Months';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import SelectControl from '../../../controls/SelectControl/SelectControl';
import { computeMonthRange, extractYears } from '../utils';
import ToolbarControls from '../../Header/ToolbarControls';
import PayprofileDTO from '../../../../common/api/dtos/Payprofile';
import { listTenantPayprofiles } from '../../../../common/api/endpoints/payprofiles';
import { IProgress, incrementProgress, newProgress } from '../../../utils/ProgressBar/ProgressBar';

/**
 * Required props type of the react component passed through the 'component' prop.
 */
export interface ComponentProps {
  tenant?: TenantDTO,
  client?: ClientDTO,
  vat?: number,

  language: string,
  payprofile?: PayprofileDTO,
  dateRange: {
    since: Date,
    until: Date,
  },

  requestStatus: TRequestStatus,
  requestProgress: IProgress,
}

export interface Props extends RouteComponentProps {
  sysProps: SyspropDTO[],
  component: React.ComponentType<ComponentProps>,
}

interface State {
  clients: ClientDTO[],
  tenants: TenantDTO[],
  payprofiles: PayprofileDTO[],
  years: number[],
  requestProgress: IProgress,
  requestStatus: TRequestStatus,

  // filters
  clientId?: number,
  tenantId?: number,
  payprofileId?: number,
  vatRate: number,
  vatToggle: boolean,
  language: string,
  monthNo: number,
  yearNo: number,
}

export class InvoicesLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const searchParams = new URLSearchParams(this.props.location.search);

    const vatRate = Number.parseInt(searchParams.get('vatRate') || '');
    const queryVatRate = Number.isNaN(vatRate) ? 19 : vatRate;
    const queryVatToggle = !Number.isNaN(vatRate);
    const queryTenantId = Number(searchParams.get('tenant'));
    const queryClientId = Number(searchParams.get('client'));
    const queryPayprofileId = Number(searchParams.get('payprofile'));
    const queryLanguage = searchParams.get('lang') || 'en-ro';

    this.state = {
      clients: [],
      tenants: [],
      payprofiles: [],
      years: [],
      requestStatus: 'idle',
      requestProgress: newProgress(2),

      clientId: queryClientId,
      tenantId: queryTenantId,
      vatRate: queryVatRate,
      vatToggle: queryVatToggle,
      language: queryLanguage,
      monthNo: new Date().getMonth(),
      yearNo: new Date().getFullYear(),
      payprofileId: queryPayprofileId,
    }
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.sysProps !== prevProps.sysProps) {
      this.processSysprops(this.props.sysProps);
    }
    
    if (this.state.tenantId !== prevState.tenantId) {
      this.update();
    }
  }

  private async update() {
      this.setState({ requestStatus: 'loading' });
      await Promise.all([this.loadPayprofiles()]);
      this.setState({ requestStatus: 'success' });
  }

  private async initialize() {
    this.processSysprops(this.props.sysProps);

    // load invoice information from API
    this.setState({ requestStatus: 'loading' });
    await Promise.all([
      this.loadTenants(),
      this.loadClients(),
    ]);
    this.setState({ requestStatus: 'success' });
  }

  /**
   * Extract currencies and years from sysprops.
   */
   private processSysprops(sysProps: SyspropDTO[]) {
    if (sysProps.length) {
      const years = extractYears(sysProps);

      this.setState({
        years,
      });
    }
  }

  /**
   * Load tenants and set the default tenant.
   */
  private async loadTenants() {
    const tenants = await listTenants();
    this.setState(prevState => {
      return {
        requestProgress: incrementProgress(prevState.requestProgress),
        tenants,
        // default to first
        tenantId: prevState.tenantId || tenants[0]?.id,
      }
    }, () => {
      this.loadPayprofiles();
    });
  }

  /**
   * Load payprofiles and set the default payprofile.
   */
  private async loadPayprofiles() {
    const payprofiles = await listTenantPayprofiles(this.state.tenantId!);
    this.setState(prevState => {
      return {
        requestProgress: incrementProgress(prevState.requestProgress),
        payprofiles,
        // default to first
        payprofileId: prevState.payprofileId || payprofiles[0]?.id,
      }
    });
  }
  /**
   * Load clients and set the default client.
   */
  private async loadClients() {
    const clients = await listClients();
    this.setState(prevState => {
      return {
        requestProgress: incrementProgress(prevState.requestProgress),
        clients,
        // default to first
        clientId: prevState.clientId || clients.filter((client: ClientDTO) => client.enabled === true)[0]?.id,
      }
    });
    clients.sort((a, b) => a.name.localeCompare(b.name))
  }



  private setYear = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, yearNo: number | string | undefined) => this.setStateField('yearNo', Number(yearNo));
  private setMonth = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, month: number | string | undefined | Month) => this.setStateField('monthNo', Number(month));
  private setTenant = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, tenantId: number | string | undefined | TenantDTO) => this.setStateField('tenantId', Number(tenantId));
  private setPayprofile = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, payprofileId: number | string | undefined | PayprofileDTO) => this.setStateField('payprofileId', Number(payprofileId));
  private setLanguage = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, language: number | string | undefined) => this.setStateField('language', language as string);
  private setClient = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, clientId: number | string | undefined | ClientDTO) => this.setStateField('clientId', Number(clientId));
  

  private setVatToggle = (ev: ChangeEvent<HTMLInputElement>) => this.setStateField('vatToggle', ev.target.checked);
  private setvatRate = (ev: ChangeEvent<HTMLInputElement>) => this.setStateField('vatRate', Number(ev.target.value));

  private setStateField<K extends keyof State>(field: K, value: State[K]) {
    this.setState({ [field]: value } as Pick<State, K>, this.setQuery);
  }

  /**
   * Update url to allow link sharing.
   */
   private setQuery = () => {
    // const vat = this.state.vatToggle ? this.state.vatRate : '';
    // this.props.history.push({
    //   search: `?tenant=${this.state.tenantId}&client=${this.state.clientId}&lang=${this.state.language}&payprofile=${this.state.payprofileId}&vatRate=${vat}`
    // });
  }

  render() {
    const client = this.state.clients.find(x => x.id === this.state.clientId);
    const tenant = this.state.tenants.find(x => x.id === this.state.tenantId);
    const payprofile = this.state.payprofiles.find(x => x.id === this.state.payprofileId);

    const Component = this.props.component;

    return (
      <React.Fragment>
        <ToolbarControls>
          <SelectControl
            idName="year"
            value={this.state.yearNo}
            options={this.state.years}
            onChange={this.setYear}
          />
          <SelectControl
            idName="month"
            value={this.state.monthNo}
            options={Months}
            onChange={this.setMonth}
            getValue={(op) => op.number}
            getLabel={(op) => op.name}
          />
          <SelectControl
            idName="tenant"
            value={this.state.tenantId}
            options={this.state.tenants.filter(tenant => { return tenant.enabled })}
            disabledValue={"Provider"}
            onChange={this.setTenant}
            getValue={(op) => op.id}
            getLabel={(op) => op?.name}
          />
          <SelectControl
            idName="payprofile"
            value={this.state.payprofileId}
            options={this.state.payprofiles}
            disabledValue={"Payprofile"}
            onChange={this.setPayprofile}
            getValue={(op) => op.id}
            getLabel={(op) => op?.name}
          />
          <SelectControl
            idName="language"
            value={this.state.language}
            options={["en-ro", "ro-ro"]}
            disabledValue={"Language"}
            onChange={this.setLanguage}
          />
          <SelectControl
            idName="client"
            value={this.state.clientId}
            options={this.state.clients.filter((client: ClientDTO) => client.enabled === true)}
            disabledValue={"Client"}
            onChange={this.setClient}
            getValue={(op) => op.id}
            getLabel={(op) => op?.name}
          />
          <ToggleControl
            id="useVAT"
            name="useVAT"
            changeMethod={this.setVatToggle}
            isChecked={this.state.vatToggle}
            labelText="VAT"
          />
          {this.state.vatToggle && <div className="form-group">
            <input
              type="number"
              name="vatInput"
              id="vat"
              required
              onChange={this.setvatRate}
              value={this.state.vatRate}
              placeholder="VAT %"
              style={{maxWidth: '100px'}}
            />
          </div>}
          <div className="form-group">
            <ButtonControl
              class="primary-button"
              onClick={() => { window.print(); }}
            >
              <span className="static-icon"><span className="fas fa-print"></span></span> <span className="text">Print</span>
            </ButtonControl>
          </div>
        </ToolbarControls>

        <Component
          dateRange={computeMonthRange(this.state.yearNo, this.state.monthNo)}
          language={this.state.language}
          tenant={tenant}
          client={client}
          payprofile={payprofile}
          vat={this.state.vatToggle ? this.state.vatRate : undefined}
          requestStatus={this.state.requestStatus}
          requestProgress={this.state.requestProgress}
        />
      </React.Fragment>
    );
  }
}