import React, { ChangeEvent, Component } from 'react';
import { Link } from 'react-router-dom';
import { ProjectGroupDTO } from '../../../common/api/dtos/ProjectGroup';
import { listProjectGroups } from '../../../common/api/endpoints/projectGroups';
import { getFilteredItems } from '../../../common/helpers/Filter';
import { ChangeSortBy } from '../../../common/helpers/Sorting';
import TableSort from '../../../common/helpers/TableSort';
import GhostButtonControl from '../../controls/GhostButtonControl/GhostButtonControl';
import TextControl from '../../controls/TextControl/TextControl';
import ToggleControl from '../../controls/ToggleControl/ToggleControl';
import ToolbarControls from '../../generics/Header/ToolbarControls';
import { withTransitionEvent } from '../../TransitionEvent';
import SortButton from '../../utils/SortButton/SortButton';

interface Props {
  entityId: number,
  entity: string,
  hasFullAccess: boolean
}

interface State {
  filterValue: string,
  isOpen: boolean,
  openProjectsList: number | null,
  projectGroups?: ProjectGroupDTO[],
  showDeleted: boolean,
  tableSort: TableSort,
}

class ProjectGroupsForm extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'projects'
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      filterValue: '',
      isOpen: false,
      openProjectsList: null,
      projectGroups: [],
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      tableSort: new TableSort('name', 'asc'),
    }
  }

  getProjectGroups = async () => {
    const projectGroups = await listProjectGroups(this.props.entityId);

    this.setState({projectGroups});
  }

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));

    this.setState(prevState => {
      return {
        showDeleted: !prevState.showDeleted
      }
    })
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th className="text-left">
          <SortButton 
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <span>Assigned Projects</span>
        </th>
        <th></th>
      </tr>
    )
  }

  renderTBodyRow(projectGroup: ProjectGroupDTO) {
    return (
      <tr
        id={`projectGroup${projectGroup.id}`}
        key={projectGroup.id}
      >
        <td></td>
        <td>
          <span>{projectGroup.name}</span>
        </td>
        <td id={`projectGroupList-${projectGroup.id}`}>
          <button
            className="link-button"
            id={`listTrigger-${projectGroup.id}`}
            onClick={() => {
              if (projectGroup.projects === undefined) return;
              this.openProjectsList(projectGroup.id)
            }}
          >
            {
              projectGroup.projects === undefined ?
                'No projects assigned'
              :
                `${projectGroup.projects.length} project${projectGroup.projects.length > 1 ? 's' : ''}`
            }
          </button>
        </td>
        <td className="text-right">
          <GhostButtonControl
            to={`/client/deep/${this.props.entityId}/project-groups/edit/${projectGroup.id}`}
            class="ghost-button"
            icon="fas fa-angle-right"
          />
        </td>
      </tr>
    )
  }

  renderProjectsList () {
    const projectGroup = this.state.projectGroups?.find((projectGroup: ProjectGroupDTO) => projectGroup.id === this.state.openProjectsList);
    const isListOpen = this.state.openProjectsList === projectGroup?.id;
    const listPosition = this.setListPosition();

    return (
      isListOpen
      ?
        <ul
          className="context-menu open overlap-list out-of-table"
          style={{
            left: listPosition.x + listPosition.padding, 
            top: listPosition.opensAbove === false ? listPosition.y + listPosition.height : 'unset', 
            bottom: listPosition.opensAbove === true ? window.innerHeight - listPosition.y + listPosition.height : 'unset'
          }}
        >
          {projectGroup!.projects.map((project) => {
            return (
              <li key={project.id}>
                <Link to={`/projects/edit/${project.id}`}>
                  <span>{project.name}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      :
        null
    )
  }

  setListPosition () {
    let projectsList = document.querySelector(`#projectGroup${this.state.openProjectsList}`);
    let projectsListRect = projectsList!.getBoundingClientRect();
    let projectListPadding = Number(window.getComputedStyle(projectsList as HTMLElement).getPropertyValue('padding').replace('px', ''));
    let windownInnerHeight = window.innerHeight;
    let dropdownMinHeight = 350;
    let rowHeight = 32;
    let opensAbove = windownInnerHeight - projectsListRect.top < dropdownMinHeight;

    if (opensAbove) {
      return {
        x: projectsListRect.left,
        y: projectsListRect.bottom,
        opensAbove: true,
        padding: projectListPadding,
        height: projectsListRect.height
      }
    } else {
      return {
        x: projectsListRect.left,
        y: projectsListRect.top - rowHeight,
        opensAbove: false,
        padding: projectListPadding,
        height: projectsListRect.height + rowHeight 
      }
    }
  }

  openProjectsList = (projectGroupId: number) => {
    // First close any list that is open
    this.closeProjectsList();
    this.setState({
      // Check if you are clicking on the trigger for the same list that is open
      isOpen: this.state.openProjectsList !== projectGroupId ? true : !this.state.isOpen,
      //Marker to have only one list open at the same time
      openProjectsList: projectGroupId
    })
  }

  closeProjectsList = () => {
    this.setState({
      isOpen: false,
      openProjectsList: null
    })
  }

  handleClickOutside = (ev: MouseEvent) => {
    let projectsGroup = document.querySelector(`#projectGroup-${this.state.openProjectsList}`);
    let listTrigger = document.querySelector(`#listTrigger-${this.state.openProjectsList}`);

    if (this.state.openProjectsList === null) {
      return;
    } else if (ev.target !== projectsGroup && ev.target !== listTrigger) {
      this.closeProjectsList();
    }
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.getProjectGroups();
    
    document.addEventListener("click", this.handleClickOutside);
  }

  render() {
    const filteredProjectGroups: ProjectGroupDTO[] = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.projectGroups ? this.state.projectGroups: []).sort(this.state.tableSort.sortByColumn).filter((projectGroup: ProjectGroupDTO) => projectGroup.deleted === this.state.showDeleted);

    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
            {this.props.hasFullAccess &&
              <>
                <ToggleControl 
                  id="showDeleted"
                  name="showDeleted" 
                  changeMethod={this.toggleDeleted} 
                  isChecked={this.state.showDeleted}
                  labelText="Recycle bin"
                  icon="fas fa-trash-undo"
                  srOnly={true}
                  title="Recyle bin"
                  />
                <div className="form-group">
                  <Link
                    to={`/${this.props.entity}/deep/${this.props.entityId}/project-groups/add`} className="primary-button"
                  >
                    <span className="static-icon">
                      <span className="fas fa-plus-circle"></span>
                      </span>
                      <span className="text">Add project group</span>
                  </Link>
                </div>
              </>
            }
          </ToolbarControls>
        </div>
        <div className={`card ${!this.state.projectGroups ? 'loader-border' : ''} ${this.state.showDeleted ? 'flashing-border' : ''}`}>
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div
                className="inner-v-scroll"
                style={{maxHeight: 'calc(100vh - 315px)'}}
              >
                <table>
                  <thead>
                    {this.renderTHeadRow()}
                  </thead>
                  {this.state.projectGroups && 
                    <React.Fragment>
                      <tbody>
                        {filteredProjectGroups.map((projectGroup: ProjectGroupDTO) => 
                          this.renderTBodyRow(projectGroup)
                        )}
                      </tbody>
                    </React.Fragment>
                  }
                    <tfoot>
                      {this.state.projectGroups?.length === 0 &&
                        <tr>
                          <th colSpan={12}>
                            {this.props.hasFullAccess ? 
                              <p className="text-chunk">
                                There are no project groups defined for this {this.props.entity}. Start by <Link className="link-button" to={`/${this.props.entity}/${this.props.entityId}/project-group/add`}>adding</Link> the first one.
                              </p>
                            :
                              <p className="text-chunk">
                                There are no projects.
                              </p>
                            }
                          </th>
                        </tr>
                      }
                      {this.state.filterValue && 
                        <tr>
                          <th colSpan={8}>
                            <div className="card">
                              <p className="text-chunk">
                                Showing {filteredProjectGroups.length} out of {this.state.projectGroups?.filter((projectGroup: ProjectGroupDTO) => projectGroup.deleted === this.state.showDeleted).length} results.
                              </p>
                            </div>
                          </th>
                        </tr>
                      }
                    </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        {this.state.isOpen && this.renderProjectsList()}
      </div>
    )
  }
}

export default withTransitionEvent(ProjectGroupsForm); 