import React, { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import GroupDTO from "../../../../common/api/dtos/Group";
import { listGroups } from "../../../../common/api/endpoints/groups";
import { SaveRemote } from "../../../../common/api/endpoints/remotes";
import { getFilteredItems } from "../../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../../common/helpers/Sorting";
import TableSort from "../../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../../common/types/RequestStatus";
import ToggleControl from "../../../controls/ToggleControl/ToggleControl";
import GhostButtonControl from "../../../controls/GhostButtonControl/GhostButtonControl";
import TextControl from "../../../controls/TextControl/TextControl";
import RequestStatus from "../../../utils/RequestStatus/RequestStatus";
import SortButton from "../../../utils/SortButton/SortButton";
import ToolbarControls from "../../../generics/Header/ToolbarControls";
import BreadcrumbControls from "../../../generics/Header/BreadcrumbControls";
import { IProgress, incrementProgress } from "../../../utils/ProgressBar/ProgressBar";
import Thumbnail from "../../../utils/Thumbnail/Thumbnail";
import { withTransitionEvent } from "../../../TransitionEvent";

interface State {
  groups?: GroupDTO[],
  progress: IProgress,
  status: TRequestStatus,
  showDeleted: boolean,
  tableSort: TableSort,
  selectedUser: string,
  filterValue: string,
}

class GroupsList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'description',
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: 'loading',
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      tableSort: new TableSort('name', 'asc'),
      selectedUser: '',
      filterValue: '',
    }
  }

  dropdownSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      selectedUser: event.target.value,
    });
  }

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  
  fetchGroups = async () => {
    this.setState({
      status: 'loading'
    });
    
    const groups = await listGroups();

    this.setState(prevState => {
      return {
        status: 'success',
        groups: groups,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
      };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchGroups();
  }

  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th>
          <SortButton
            column="name"
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
            ></SortButton>
        </th>
        <th>
          <SortButton
            column="description"
            text="Description"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
            ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(group: GroupDTO) {
    return (
      <tr id={`group${group.id}`} key={`${group.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <Thumbnail
                thumbnailURL={group.iconURL}
                classes="thumbnail padded"
              />
              <span>{group.name}</span>
            </div>
          </div>
        </td>
        <td>
          <p className="text-chunk">{group.description}</p>
        </td>
        <td className="text-right">
          <GhostButtonControl to={`/group/edit/${group.id}`}
          class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredGroups: Array<GroupDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.groups ? this.state.groups : []).sort(this.state.tableSort.sortByColumn).filter((group: GroupDTO) => group.deleted === this.state.showDeleted);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Groups"
          status={this.state.status}
        />
        {this.state.groups && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="FilterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showDeleted"
                name="showDeleted"
                changeMethod={this.toggleDeleted}
                isChecked={this.state.showDeleted}
                labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
              />
              <div className="form-group">
                <Link to={`/group/add`}
                className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add group</span></Link>
              </div>
            </ToolbarControls>

            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                      card
                      ${!this.state.groups ? 'loader-border' : ''}
                      ${this.state.showDeleted ? "flashing-border" : ""}  
                  `}>
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.groups &&
                        <React.Fragment>
                          <tbody>
                            {filteredGroups.map(group => (
                              this.renderTBodyRow(group)
                              ))}
                              </tbody>
                              <tfoot>
                                {this.state.groups?.length === 0 &&
                                  <tr>
                                    <th colSpan={8}>
                                      <div className="card">
                                        <p className="text-chunk">There are no groups defined. Start by <Link className="link-button" to="/group/add">adding</Link> the first one.</p>
                                      </div>
                                    </th>
                                  </tr>
                                }
                                {this.state.filterValue &&
                                  <tr>
                                    <th colSpan={8}>
                                      <div className="card">
                                        <p className="text-chunk">Showing {filteredGroups.length} out of {this.state.groups.filter((group: GroupDTO)=> group.deleted === this.state.showDeleted).length} results.</p>
                                      </div>
                                    </th>
                                  </tr>
                                }
                              </tfoot>
                            </React.Fragment>
                          }
                    </table>
                  </div>            
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  };
}
export default withTransitionEvent(GroupsList);