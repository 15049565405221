import UserDTO from "./User";

export class VacationsDTO {
  id: number | null;
  type: string;
  date: Date;
  deleted: boolean;
  approved: boolean;
  confirmed: boolean;
  user: UserDTO;
  internalCode: string;
  contractId: number | null;
  
  constructor(
    id: number | null,
    type: string,
    date: Date,
    internalCode: string = "",
    contractId: number | null = null,
    deleted: boolean = false,
    approved: boolean = false,
    confirmed: boolean = false,
    user: UserDTO = {
      id: -1,
      email: '',
      name: '',
      avatar: 'ava1.png',
      enabled:   true,
      invisible: false,
      lastPasswordUpdate: new Date(),
      mustUpdatePassword: false,
      role: {
        id: -1,
        name: '',
        description: ''
      },
    },
  ) {
    this.id = id;
    this.type = type;
    this.date = date;
    this.deleted = deleted;
    this.approved = approved;
    this.confirmed = confirmed;
    this.user = user
    this.internalCode = internalCode;
    this.contractId = contractId;
  }
}

export interface VacationsEveryoneDTO {
  date: Date,
  userId: number,
  contractName: string,
  confirmed: boolean,
}

export default VacationsDTO;