import Joi from 'joi';
import moment from 'moment';
import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import GearDTO from '../../../../common/api/dtos/Gear';
import UserDTO from '../../../../common/api/dtos/User';
import { listComputerDetails } from '../../../../common/api/endpoints/computers';
import { listUsers } from '../../../../common/api/endpoints/users';
import { FormErrors } from '../../../../common/data/FormErrors';
import Fetch from '../../../../common/helpers/Fetch';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { ComputerDTO } from '../../../../common/interfaces/Computer';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import TextAreaControl from '../../../controls/TextAreaControl/TextAreaControl';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TextControl from '../../../controls/TextControl/TextControl';
import SelectControl from '../../../controls/SelectControl/SelectControl';
import { withTransitionEvent } from '../../../TransitionEvent';

export interface Props {
  id: string,
}

interface FormData {
  name: string,
  type: string | undefined,
  description: string,
  comments: string,
  deleted: boolean,
  userId?: number | undefined,
  itemId?: number | undefined,
}

interface State {
  computerId: string,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  formData: FormData,
  computers?: ComputerDTO[],
  items?: GearDTO[],
  users?: UserDTO[],
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class ComputerDetail extends Component<RouteComponentProps<Props>, State> {
  deviceTypes = ['Notebook', 'Desktop'];
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    type: Joi.string().trim(true).required().messages(FormErrors.string),
    description: Joi.string().trim(true).allow(null, ''),
    comments: Joi.string().trim(true).allow(null, ''),
    deleted: Joi.boolean(),
    userId: Joi.number().messages(FormErrors.string),
    itemId: Joi.number().allow(null, 0),
  })

  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {
      computerId: this.props.match.params.id,
      formData: {
        name: '',
        type: undefined,
        description: '',
        comments: '',
        deleted: false,
        userId: undefined,
      },
      progress: {
        currentStep: 0,
        totalSteps: 1
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchComputersDetails = async () => {
    this.setState({
      pageStatus: 'loading'
    });

    const computer = await listComputerDetails(this.state.computerId);

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        formData: {
          name: computer.name,
          type: computer.type,
          description: computer.description,
          comments: computer.comments,
          deleted: computer.deleted,
          userId: computer.userId ?? undefined,
        },
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  addEntity = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/computers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(this.state.formData)
    })

    await fetchRequest.fetch();
    this.setState({
      formStatus: fetchRequest.getStatus()
    }, () => {
      if (this.state.formStatus === 'success') {
        this.props.history.push({
          pathname: '/computer'
        })
      }
    });
  }

  updateEntity = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/computers/` + this.state.computerId, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(this.state.formData)
    })

    await fetchRequest.fetch();
    this.setState({
      formStatus: fetchRequest.getStatus(),
      formData: {
        ...this.state.formData,
      }
    });
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });

      if (!isEditPage(Number(this.state.computerId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  fetchUsers = async () => {
    this.setState({
      pageStatus: 'loading'
    });

    const users = await listUsers();

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        users,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  componentDidMount() {
    this.fetchUsers();

    if (isEditPage(Number(this.state.computerId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1
          }
        }
      });
      this.fetchComputersDetails()
    } else {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1
          },
        }
      });
    }
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value
      }
    }, () => {
      this.validateFormField(field);
    })
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setType = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent, type: number | string | undefined) => this.updateFormData('type', String(type));
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);
  setComments = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('comments', ev.target.value);
  setUserId = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent, userId: number | string | undefined | UserDTO) => {this.updateFormData('userId', userId as number | undefined);}
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);

  render() {
    return (
      <React.Fragment>
        <ProgressBar
          currentStep={this.state.progress.currentStep}
          totalSteps={this.state.progress.totalSteps}
        />
        <BreadcrumbControls
          items={[
            { label: 'Computers', path: '/computer' },
            { label: `${!isEditPage(Number(this.state.computerId)) ? 'adding' : 'editing'} computer ${this.state.formData.name}` },
          ]}
          status={this.state.pageStatus}
        />
        <div className="flex-row">
          <div className="column">
            <div className="card">
              <TextControl
                label="Name"
                type="text"
                name="name"
                id="name"
                onChange={this.setName}
                value={this.state.formData.name}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.name}
              />
              <SelectControl
                idName="type"
                label="Type"
                required={true}
                value={this.state.formData.type}
                options={this.deviceTypes}
                disabled={!loadComplete(this.state.progress)}
                disabledValue="Select a type"
                onChange={this.setType}
                onBlur={ev => this.validateFormField(ev.target.name  as keyof FormData)}
                error={this.state.formErrors.type}
              />
              <TextAreaControl
                label="Description"
                id="description"
                name="description"
                value={this.state.formData.description}
                onChange={this.setDescription}
                disabled={!loadComplete(this.state.progress)}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
              />
              <TextAreaControl
                label="Comments"
                id="comments"
                name="comments"
                value={this.state.formData.comments}
                onChange={this.setComments}
                disabled={!loadComplete(this.state.progress)}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
              />
              <SelectControl
                idName="userId"
                label="Employee"
                value={this.state.formData.userId !== undefined ? this.state.formData.userId : 0}
                options={[{id: 0, fullName: 'None'} as UserDTO, ...(this.state.users || [])]}
                disabled={!loadComplete(this.state.progress)}
                onChange={this.setUserId}
                getValue={(op) => op.id}
                getLabel={(op) => op?.fullName ? op.fullName : op?.name}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.userId}
              />
              {isEditPage(Number(this.state.computerId)) &&
                <ToggleControl
                  id="deleted"
                  name="deleted"
                  changeMethod={this.setDeleted}
                  isChecked={this.state.formData.deleted}
                  isDisabled={!loadComplete(this.state.progress)}
                  labelText="Deleted"
                  onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                />
              }
              <div className="form-group">
                <ButtonControl
                  class="primary-button"
                  onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    this.handleSubmit(ev)
                  }}
                  disabled={!loadComplete(this.state.progress)}
                >
                  <RequestStatus status={this.state.formStatus} />
                  <span className="text">Save</span>
                </ButtonControl>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

export default withTransitionEvent(ComputerDetail);