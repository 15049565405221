import React, { Component } from 'react';
import { IAvatar } from '../../../common/interfaces/Avatar';
import { AvatarFormatter, thumbnailURL } from '../../../common/helpers/AvatarUtils';
import errorImage from '../../../common/helpers/ImageError';

export interface Props {
  thumbnailURL?: string,
  thumbnailBackground?: string,
  classes?: string,
  avatarData?: string,
}

interface State {
  avatarData: IAvatar,
  hasError: boolean,
}

class Thumbnail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      avatarData: {
        link: 'ava1.png',
        background: '#ffffff',
        hair: '',
        mouth: '',
        eyes: '',
        eyewear: '',
        beard: '',
        face: '',
      },
      hasError: false,
    }
  }

  setAvatar = (): void => {
    this.setState({
      avatarData: AvatarFormatter(this.props.avatarData || ''),
    });
  }

  componentDidMount() {
    this.setAvatar();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.avatarData !== prevProps.avatarData) {
      this.setAvatar();
    }
  }

  errorPlaceholder = (event: HTMLImageElement) => {
    if(!this.state.hasError) {
      this.setState({
        hasError: true,
      });
      event.src = process.env.REACT_APP_ERROR_IMG_URL!;
    }
  }

  render() {
    return (
      <React.Fragment>
      {!this.props.thumbnailURL && this.props.thumbnailURL !== '' ?
        <React.Fragment>
          {this.props.avatarData && 
            <img
              className={['thumbnail', this.props.classes].join(" ")}
              src={thumbnailURL(this.state.avatarData)}
              onError={(e) => errorImage(e.currentTarget)}
              alt=""
            />
          }
          </React.Fragment>
        :
        <React.Fragment>
          <img
            className={['thumbnail', this.props.classes].join(" ")}
            src={this.props.thumbnailURL ? this.props.thumbnailURL : process.env.REACT_APP_ERROR_IMG_URL}  alt=""
            style={{backgroundColor: this.props.thumbnailBackground ? this.props.thumbnailBackground : ""}}
            onError={() => this.errorPlaceholder} />
          </React.Fragment>
      }
      </React.Fragment>
    );
  }
}

export default Thumbnail;
