import React, {Component} from 'react';
import { TRequestStatus } from '../../../common/types/RequestStatus';

export interface Props {
  status: TRequestStatus,
}

interface State {
  successExpired: boolean,
}

class RequestStatus extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      successExpired: false,
    }
  }

  componentDidUpdate(prevProps: Props) {
    if(this.props.status !== prevProps.status) {
      this.setState({
        successExpired: false,
      });

      if(this.props.status === 'success') {
        setTimeout(() => {
          this.setState({
            successExpired: true,
          });
        }, 2000);
      }
    }
  }

  render() {
    return(
      <span className={`
        dynamic-icon
        ${this.props.status}
        ${this.props.status !== 'idle' && !this.state.successExpired ? 'visible' : ''}
      `}>
        {this.props.status === 'loading' && 
          <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle className="circle" fill="none" strokeWidth="12" strokeLinecap="round" cx="33" cy="33" r="27"></circle></svg>
        }
        {this.props.status === 'success' && !this.state.successExpired && 
          <span className="success-icon fas fa-check"></span>
        }
        {this.props.status === 'error' && 
          <span className="error-icon fas fa-exclamation-triangle"></span>
        }
      </span>
    )
  }
}

export default RequestStatus;
