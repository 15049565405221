import React, { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import BenefitDTO from "../../../common/api/dtos/Benefit";
import { listBenefits } from "../../../common/api/endpoints/benefits";
import { listAssignedBenefits } from "../../../common/api/endpoints/users";
import Fetch from "../../../common/helpers/Fetch";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import ToolbarControls from "../../generics/Header/ToolbarControls";
import TextControl from "../../controls/TextControl/TextControl";
import RequestStatus from "../../utils/RequestStatus/RequestStatus";
import SortButton from "../../utils/SortButton/SortButton";

interface Props {
  editUserId: number,
}

interface State {
  userPropId: number | undefined | null,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  benefits?: BenefitDTO[],
  showDisabled: boolean,
  showOnlyAssignedBenefits: boolean,
  tableSort: TableSort,
	formData: FormData,
  filterValue: string,
}

interface FormData {
  benefits: BenefitDTO[],
}

class BenefitsForm extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'conditions',
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      pageStatus: 'idle',
      formStatus: 'idle',
      formData: {
        benefits: [],
      },
      userPropId: undefined,
      tableSort: new TableSort('name', 'asc'),
      showDisabled: localStorage.getItem('showDisabled') === 'false' || localStorage.getItem('showDisabled') === null ? false : true,
      showOnlyAssignedBenefits: false,
      filterValue: '',
    }
  }

  fetchBenefits = async () => {
    this.setState({
      pageStatus: 'loading'
    });

    const benefits = await listBenefits();

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        benefits: benefits,
      }
    });
  }

  fetchAssignedBenefits = async () => {
    this.setState({
      pageStatus: 'loading'
    });

    const assignedBenefits = await listAssignedBenefits(this.props.editUserId);

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        formData: {
          benefits: assignedBenefits.length ? assignedBenefits : [],
        }
      }
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({ showDisabled: false }) : this.setState({ showDisabled: true });
  }

  toggleVisible = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showOnlyAssignedBenefits ? this.setState({ showOnlyAssignedBenefits: false }) : this.setState({ showOnlyAssignedBenefits: true });
  }

  checkIfAssigned = (benefitId: number): boolean => {
    return this.state.formData.benefits.map((benefit: BenefitDTO) => { return benefit.id; }).indexOf(benefitId) === -1 ? false : true;
  }

  componentDidMount() {
    this.fetchBenefits();
    this.fetchAssignedBenefits();
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });

    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/user/assigned-benefits/` + this.props.editUserId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(this.state.formData)
    })

    await fetchRequest.fetch();

    this.setState({
      formStatus: fetchRequest.getStatus()
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  setAssigned = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentProjectId: number = Number(ev.target.value);
    this.setState((prevState: State) => {
      let assignedBenefits = prevState.formData.benefits.slice(0);
      let alreadyAssigned = assignedBenefits.map((benefit: BenefitDTO) => { return benefit.id; }).indexOf(currentProjectId);

      if(alreadyAssigned === -1) {
        let selectedProject = this.state.benefits?.filter((benefit: BenefitDTO) => benefit.id === currentProjectId)[0];
        if(selectedProject) {
          assignedBenefits.push(selectedProject);
        }
      } else {
        assignedBenefits.splice(alreadyAssigned, 1);
      }

      return {
        formData: {
          ...prevState.formData,
          benefits: assignedBenefits
        }
      }
    });

  }

  addAllListed = () => {
    const filteredItems = this.getFilteredBenefits();

    this.setState((prevState: State) => {
      const existingIds = prevState.formData.benefits.map(e => e.id);
      const newItems = filteredItems.filter(item => !existingIds.includes(item.id));
      return {
        formData: {
          ...prevState.formData,
          benefits: [...prevState.formData.benefits, ...newItems]
        }
      }
    });
  }

  removeAllListed = () => {
    const filteredItems = this.getFilteredBenefits();

    this.setState((prevState: State) => {
      const filteredIds = filteredItems.map(u => u.id);
      return {
        formData: {
          ...prevState.formData,
          benefits: prevState.formData.benefits.filter((element) => !filteredIds.includes(element.id))
        }
      }
    });
  }

  renderToggler = () => {
    if(!this.state.benefits) {
      return;
    }

    const filteredItems = this.getFilteredBenefits();
    const formIds = this.state.formData.benefits.map(u => u.id);
    const diffAdd = filteredItems.filter(item => !formIds.includes(item.id)).length;
    const diffRemove = filteredItems.filter(item => formIds.includes(item.id)).length;

    return (
      <div className="flex-row tight flex-v-center squeeze">
        <div className="column">
          <button
            className="primary-button"
            onClick={this.addAllListed}
            disabled={!diffAdd}
            >
            Add listed {`(${diffAdd})`}
          </button>
        </div>
        <div className="column">
          <button
            className="primary-button"
            onClick={this.removeAllListed}
            disabled={!diffRemove}
          >
            Remove listed {`(${diffRemove})`}
          </button>
        </div>
      </div>
    )
  }

  getFilteredBenefits() {
    return getFilteredItems(this.state.filterValue, this.availableFilters, this.state.benefits ? this.state.benefits : [])
      .filter((benefit: BenefitDTO) => (benefit.enabled === true || this.state.showDisabled)
      &&
      (this.state.showOnlyAssignedBenefits === false || this.checkIfAssigned(benefit.id))
      );
  }

  render() {
    const filteredBenefits = this.getFilteredBenefits().sort(this.state.tableSort.sortByColumn);
    const totalBenefits = this.state.benefits?.filter((benefit: BenefitDTO) =>  benefit.enabled === true || this.state.showDisabled)
    const totalAssignedPBenefits = this.state.benefits?.filter((project: BenefitDTO) => this.checkIfAssigned(project.id));

    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
            <ToggleControl
              id="showDisabled"
              name="showDisabled"
              changeMethod={this.toggleDisabled}
              isChecked={this.state.showDisabled}
              labelText="Disabled"
            />
            <ToggleControl
              id="showOnlyAssignedBenefits"
              name="showOnlyAssignedBenefits"
              changeMethod={this.toggleVisible}
              isChecked={this.state.showOnlyAssignedBenefits}
              labelText="Assigned"
            />
          </ToolbarControls>
        </div>
        <div className={`card ${!this.state.benefits ? 'loader-border' : ''}`}>
          {this.renderToggler()}
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 315px)'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="text-left">
                          <SortButton
                            column='name'
                            text='Name'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th className="text-left">
                          <SortButton
                            column='description'
                            text='Mechanism'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th>
                          <SortButton
                            column='conditions'
                            text='Conditions'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        {this.state.showDisabled &&
                          <th className="text-center">
                            <SortButton
                              column='enabled'
                              text='Enabled'
                              tableSort={this.state.tableSort}
                              onClick={this.handleSortChange}
                            ></SortButton>
                          </th>
                        }
                        <th className="text-center">
                          <span>Assigned</span>
                        </th>
                      </tr>
                    </thead>
                    {this.state.benefits &&
                      <React.Fragment>
                        <tbody>
                          {filteredBenefits && filteredBenefits.map(benefit => (
                            <React.Fragment>
                              <tr id={`benefit${benefit.id}`} key={benefit.id}>
                                <td></td>
                                <td>
                                  <span>{benefit.name}</span>
                                </td>
                                <td>
                                  <p className="text-chunk">{benefit.description}</p>
                                </td>
                                <td>
                                  <p className="text-chunk">{benefit.conditions}</p>
                                </td>
                                {this.state.showDisabled &&
                                  <td className="text-center">
                                    {benefit.enabled &&
                                      <span className="fas fa-check"></span>
                                    }
                                  </td>
                                }
                                <td className="text-center">
                                  <ToggleControl
                                    id={`benefit-${benefit.id}`}
                                    name={`benefit-${benefit.id}`}
                                    changeMethod={this.setAssigned}
                                    isChecked={this.checkIfAssigned(benefit.id)}
                                    value={String(benefit.id)}
                                    labelText={this.checkIfAssigned(benefit.id) ? 'ON' : 'OFF'}
                                    classes="text-center"
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.benefits?.length === 0 &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk">There are no benefits defined. Start by <Link className="link-button" to="/benefits/add">adding</Link> the first one.</p>
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                 <p className="text-chunk">Showing {filteredBenefits.length} out of {this.state.showOnlyAssignedBenefits ? totalAssignedPBenefits?.length : totalBenefits?.length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
            <button
              className="primary-button"
              onClick={this.updateEntity}
              disabled={Boolean(!this.state.benefits)}
            >
              <RequestStatus status={this.state.formStatus} />
              <span className="text">Save</span>
            </button>
          </div>
      </div>
    );
  }
}

export default BenefitsForm;