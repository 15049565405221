import React, { ChangeEvent, Component } from 'react';
import { Link } from 'react-router-dom';
import Fetch from '../../../../common/helpers/Fetch';
import UserDTO from '../../../../common/api/dtos/User';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import Thumbnail  from '../../../utils/Thumbnail/Thumbnail';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { listUsers } from '../../../../common/api/endpoints/users';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import { withTransitionEvent } from '../../../TransitionEvent';
import { LoggedUser } from "../../../../common/interfaces/LoggedUser";
import { hasManagerAccess } from '../../../generics/Booking/bookingUtils';

export interface Props {
  loggedUser?: LoggedUser;
}

interface State {
  users?: UserDTO[],
  progress: IProgress,
  status: TRequestStatus,
  showSensitive: boolean,
  showDisabled: boolean,
  tableSort: TableSort,
  selectedUser: string,
  filterValue: string,
}

class UserList extends Component<Props, State> {
  availableFilters: string[] = [
    'email', 
    'name', 
    'role.name',
    'fullName'
  ];

  constructor(props: {}) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: 'loading',
      showSensitive: false,
      showDisabled: localStorage.getItem('showDisabledUsers') === 'false' || localStorage.getItem('showDisabledUsers') === null ? false : true,
      tableSort: new TableSort('name', 'asc'),
      selectedUser: '',
      filterValue: '',
    }
  }

  sensitiveTimeout: number = 0;

  dropdownSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      selectedUser: event.target.value,
    });
  }

  toggleSensitive = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.showSensitive ? this.setState({showSensitive: false}) : this.setState({showSensitive: true});
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({showDisabled: false}) : this.setState({showDisabled: true});
  }

  fetchUsers = async () => {
    this.setState({
      status: 'loading'
    });
    
    const users = await listUsers();

    this.setState(prevState => {
      return {
        status: 'success',
        users: users,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
      };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchUsers();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if(this.state.showSensitive !== prevState.showSensitive) {
      if(this.state.showSensitive){
        this.sensitiveTimeout = setTimeout(() => {
          this.setState({showSensitive: false});
        }, parseInt(String(process.env.REACT_APP_SENSITIVE_DATA_TIMEOUT))) as unknown as number;
      } else {
        clearTimeout(this.sensitiveTimeout);
      }
    }
  }
  
  renderTHeadRow() {
    return ( 
      <tr>
        <th>#</th>
        <th>
          <SortButton
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='email' 
            text='Email'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='role.name' 
            text='Role'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='invisible' 
            text='Visible'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        {this.state.showDisabled &&
          <th className="text-center">
            <SortButton 
              column='enabled' 
              text='Enabled'
              tableSort={this.state.tableSort}
              onClick={this.handleSortChange}
              ></SortButton>
          </th>
        }
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(user: UserDTO) {
    return (
      <tr id={`user${user.id}`} key={`${user.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <Thumbnail avatarData={user.avatar} />
              <div>
                <span>{user.name}</span><br />
                <small className={!this.state.showSensitive ? 'blurred-item faint-text' : 'blurred-item deblur faint-text'}>{user.fullName}</small>
              </div>
            </div>
          </div>
        </td>
        <td>
          <span className={!this.state.showSensitive ? 'blurred-item' : 'blurred-item deblur'}>{user.email}</span>
        </td>
        <td>
          <Link to={`/role`} className="link-button">
            {user.role?.name}
          </Link>
        </td>
        <td className="text-center">
          {!user.invisible && 
            <span className="fas fa-check"></span>
          }
        </td>
        {this.state.showDisabled && 
          <td className="text-center">
            {user.enabled && 
              <span className="fas fa-check"></span>
            }
          </td>
        }
        <td className="text-right">
          <GhostButtonControl to={`/user/edit/${user.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredUsers: Array<UserDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.users ? this.state.users : []).sort(this.state.tableSort.sortByColumn).filter((user: UserDTO) => user.enabled === true || this.state.showDisabled);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Users"
          status={this.state.status}
        />
        {this.state.users && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showSensitive"
                name="showSensitive"
                changeMethod={this.toggleSensitive}
                isChecked={this.state.showSensitive}
                labelText="Sensitive data"
                icon="fas fa-shield-alt"
                srOnly={true}
                title="Sensitive data"
              />
              <ToggleControl
                id="showDisabledUsers"
                name="showDisabledUsers"
                changeMethod={this.toggleDisabled}
                isChecked={this.state.showDisabled}
                labelText="Disabled"
              />
              <div className="form-group">
                {!hasManagerAccess(this.props.loggedUser!) && <Link to={'/user/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add user</span></Link>}
              </div>
            </ToolbarControls>
            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                      card
                      ${!this.state.users ? 'loader-border' : ''}
                    `}
                  >
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.users &&
                        <React.Fragment>
                          <tbody>
                            {filteredUsers.map(user => (
                              this.renderTBodyRow(user)
                            ))}
                          </tbody>
                          <tfoot>
                            {this.state.users?.length === 0 &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">There are no users defined. Start by <Link className="link-button" to="/user/add">adding</Link> the first one.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredUsers.length} out of {this.state.users.filter((user: UserDTO) => user.enabled === true || this.state.showDisabled).length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  };
}
export default withTransitionEvent(UserList);
