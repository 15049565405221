import React from "react";
import { suspensionEntry } from "../../../generics/Booking/bookingUtils";
import { ProjectDay, Props as CellProps } from "./ProjectDay";

interface Props {
  id: number,
  total: number,
  avatar: string,
  name: string,
  clientName: string,
  disabled: boolean,
  days: Omit<CellProps, 'index' | 'disabled' | 'onBlur' | 'onChange'>[],

  onDayChange: (projectId: number, cellIndex: number, hours: string, comment: string) => void,
  onDayBlur: (projectId: number, cellIndex: number) => void,
}

export default class ProjectRow extends React.Component<Props> {

  onCellChange = (index: number, hours: string, comment: string) => {
    this.props.onDayChange(this.props.id, index, hours, comment);
  }

  onBlur = (index: number) => {
    this.props.onDayBlur(this.props.id, index);
  }

  render() {
    const { avatar, name, clientName, disabled } = this.props;

    const cellElements = this.props.days.map((cell, index) => {
      return <ProjectDay
          key={index}
          index={index}
          isToday={cell.isToday}
          isHoliday={cell.isHoliday}
          isWeekend={cell.isWeekend}
          isSuspension={cell.isSuspension}
          personalDayType={cell.personalDayType}
          hours={cell.hours}
          comment={cell.comment}
          status={cell.status}
          disabled={disabled}
          onBlur={this.onBlur}
          onChange={this.onCellChange}
        />;
    });

    return <tr>
      <td></td>
      <td>
        <div className="flex-row fill">
          <div className="column flex-v-center">
            <img className="thumbnail" src={avatar} alt=""/>
            <div>
              <span>{name}</span>
              <br />
              <small className="faint-text">{clientName}</small>
            </div>
          </div>
        </div>
      </td>
      <td className="text-center">{this.props.total}</td>
      {cellElements}
    </tr>
  }
}