import React, { Component, MouseEvent, FocusEvent } from "react";
import { ErrorPayload } from "../../../common/api/endpoints/errorPayload";

interface Props {
  id: number;
  error: ErrorPayload;
  onDestroy: () => void
}

interface State {
  isPaused: boolean;
}

class Notification extends Component<Props, State> {
  private timerId: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      isPaused: false,
    };
  }

  componentDidMount() {
    // Set a timer to destroy the component after 5 seconds if it is not paused
    // this.timerId = setTimeout(() => {
    //   if (!this.state.isPaused) {
    //     this.destroyNotification();
    //   }
    // }, 5000);
  }

  componentWillUnmount() {
    // Clear the timer when the component is destroyed
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  handleMouseEnter = () => {
    // Pause the self-destruct timer when the user hovers over the notification
    this.setState({
      isPaused: true,
    });
  };

  handleMouseLeave = () => {
    // Resume the self-destruct timer when the user stops hovering over the notification
    this.setState({
      isPaused: false,
    });
  };

  handleCloseClick = (event: MouseEvent<HTMLButtonElement>) => {
    // Destroy the component when the user clicks the close button
    event.preventDefault();
    this.destroyNotification();
  };

  handleFocus = (event: FocusEvent<HTMLButtonElement>) => {
    // Pause the self-destruct timer when the user focuses on the close button
    this.setState({
      isPaused: true,
    });
  };

  destroyNotification = () => {
    // Call the parent component's callback function to destroy this notification
    this.props.onDestroy();
  };

  render() {
    return (
      <div
        className={`toaster-entry reveal-up-1 card`}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        // onFocus={this.handleFocus}
        onBlur={() => {
          // Resume the self-destruct timer when the user focuses out of the close button
          this.setState({
            isPaused: false,
          });
        }}
        style={{
          animationDelay: `0.${this.props.id}s`
        }}
      >
        <p
          style={{
            paddingRight: "50px",
          }}
        >
          <span className="">An error has ocurred!</span>
          <br />
          <p>
          <small className="negative-text">{this.props.error.code}</small>
            {this.props.error.subcode &&
              <small className="negative-text">
              &nbsp;/&nbsp;
              {this.props.error.subcode}
              </small>
            }
          </p>
          {this.props.error.friendlyMessage &&
            <p className="faint-text">
              <small>{this.props.error.friendlyMessage}</small>
            </p>
          }
        </p>
        <button
          className="ghost-button top right"
          onClick={this.handleCloseClick}
          onFocus={this.handleFocus}
        >
          <span className="fas fa-times"></span>
        </button>
      </div>
    );
  }
}

export default Notification;
