import Joi from 'joi';
import moment from 'moment';
import React, { Component, ChangeEvent, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import { addEvent, listEventDetails, updateEvent } from '../../../../common/api/endpoints/events';
import { FormErrors } from '../../../../common/data/FormErrors';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import ProgressBar from '../../../utils/ProgressBar/ProgressBar.lazy';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TextControl from '../../../controls/TextControl/TextControl';
import RoleDTO from '../../../../common/api/dtos/Role';
import { listRoles } from '../../../../common/api/endpoints/roles';
import SelectControlMultiple from '../../../controls/SelectControlMultiple/SelectControlMultiple';
import { EventPayload } from '../../../../common/api/dtos/Event';
import { withTransitionEvent } from '../../../TransitionEvent';

interface RouteParams {
  id?: string,
}

interface FormData extends EventPayload {}

interface State {
  eventId?: string,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  clientStatus: TRequestStatus,
  roles?: RoleDTO[],
  formData: FormData,
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class EventDetail extends Component<RouteComponentProps<RouteParams>, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    iconURL: Joi.string().trim(true).allow(null, ''),
    date: Joi.date().required().messages(FormErrors.date),
    roles: Joi.array().items(Joi.string()).required(),
    deleted: Joi.boolean(),
    yearly: Joi.boolean(),
  });

  constructor(props: RouteComponentProps<RouteParams>) {
    super(props);
    this.state = {
      eventId: this.props.match.params.id,
      formData: {
        name: '',
        iconURL: '',
        date: new Date(),
        roles: [],
        deleted: false,
        yearly: false
      },
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      clientStatus: 'idle',
      formErrors: {},
    }
  }

  fetchEventDetails = async () => {
    if(this.state.eventId) {
    this.setState({
      pageStatus: 'loading'
    });
    
      const event = await listEventDetails(this.state.eventId);
      const eventRoles = event.roles.map(o => o.id.toString());
      this.setState(prevState => {
        return {
          formData: {
            name: event.name,
            iconURL: event.iconURL,
            date: new Date(event.date),
            roles: eventRoles,
            deleted: event.deleted,
            yearly: event.yearly
          },
          progress: incrementProgress(prevState.progress),
          pageStatus: 'success',
        }
      });
    }
  }

  fetchRoles = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const roles = await listRoles();

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        roles: roles,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  private setRoles = (e: ChangeEvent<HTMLSelectElement>) => {
    const roleOptions = Array.from(e.target.options);
    const selectedRoles = roleOptions.filter(o => o.selected).map(o => o.value);

    this.setState({
      formData: {
        ...this.state.formData,
        roles: selectedRoles,
      },
    });
  }
  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addEvent(this.state.formData);

    this.setState({
        formStatus: 'success',
    }, () => {
      this.props.history.push({
        pathname: '/event'
      })
    });
  }

  updateEntity = async () => {
    if(this.state.eventId) {
      this.setState({
        formStatus: 'loading'
      });
      await updateEvent(this.state.formData, this.state.eventId);
  
      this.setState({
          formStatus: 'success',
      });

    }
  }

  handleSubmit = async (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();
    
    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
  
      if(!isEditPage(Number(this.state.eventId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });
    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    this.fetchRoles();
    if(isEditPage(Number(this.state.eventId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 2,
          }
        }
      });
        this.fetchEventDetails();
    }
  } 

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      }
    });
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setDate = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('date', new Date(ev.target.value))}
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);
  setYearly = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('yearly', ev.target.checked);
  setIconURL = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('iconURL', ev.target.value);

  renderEventEditCard() {
    return(
      <div className="card">
        <TextControl
          label="Name"
          type="text"
          name="name"
          id="name"
          onChange={this.setName}
          value={this.state.formData.name}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.name}
        />
        <TextControl
          label="Event date"
          type="date"
          name="date"
          id="date"
          onChange={this.setDate}
          value={moment(this.state.formData.date).format('Y-MM-DD')}
          disabled={!loadComplete(this.state.progress)}
          required={true}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        <SelectControlMultiple
          id="roles"
          name="roles"
          label="Shown to"
          value={this.state.formData.roles as string[]}
          changeMethod={this.setRoles}
          disabled={this.state.formStatus === 'loading'}
          options={this.state.roles}
          classes="mw-small"
          getOptionProps={(op: RoleDTO) => {
            return {
              key: op.id,
              value: op.id,
              label: op.name,
            }
          }}
          multiple={true}
        />
        <div className="form-group">
          <div className="flex-row">
            <div className="column large">
              <TextControl
                label="Icon"
                type="text"
                name="iconURL"
                id="iconURL"
                value={this.state.formData.iconURL}
                onChange={this.setIconURL}
                disabled={!loadComplete(this.state.progress)}
                required={true}
                br={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.iconURL}
              />
              <ToggleControl
                id="yearly"
                name="yearly"
                changeMethod={this.setYearly}
                isChecked={this.state.formData.yearly}
                isDisabled={!loadComplete(this.state.progress)}
                labelText="Yearly"
              />
              <small className="faint-text"><span className="fas fa-info-circle"></span> You can use both relative or absolute paths.</small>
            </div>
            <div className="column small flex-v-center">
              <img
                className="thumbnail radius padded large"
                src={this.state.formData.iconURL ? this.state.formData.iconURL : `/assets/icons/weekday-${this.state.formData.date ? moment(this.state.formData.date).isoWeekday() : '7'}-100.png`}
                alt="avatar"
              />
            </div>
          </div>
        </div>
        {isEditPage(Number(this.state.eventId)) &&
          <ToggleControl
            id="deleted"
            name="deleted"
            changeMethod={this.setDeleted}
            isChecked={this.state.formData.deleted}
            isDisabled={!loadComplete(this.state.progress)}
            labelText="Deleted"
          />
        }
        <div className="form-group">
          <ButtonControl
            class="primary-button"
            disabled={!loadComplete(this.state.progress)}
            onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              this.handleSubmit(ev)
            }}
          >
            <RequestStatus status={this.state.formStatus} />
            <span className="text">Save</span>
          </ButtonControl>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <BreadcrumbControls
          items={[
            {label: 'Events', path: '/event'},
            {label: `${!isEditPage(Number(this.state.eventId)) ? 'adding' : 'editing'} event ${this.state.formData.name}`},
          ]}
          status={this.state.pageStatus}
        />
        <div className="flex-row">
          <div className="column">
            <div className="flex-row tightest-top">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            {this.renderEventEditCard()}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTransitionEvent(EventDetail);