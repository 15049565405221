import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { InvoiceLabels } from '../../../common/data/InvoiceLabels';
import { ClientSection } from '../../generics/Invoices/ClientSection/ClientSection';
import { InvoiceSection } from '../../generics/Invoices/InvoiceSection/InvoiceSection';
import { ComponentProps } from '../../generics/Invoices/InvoicesLayout/InvoicesLayout';
import { withInvoicesLayout } from '../../generics/Invoices/InvoicesLayout/withInvoicesLayout';
import { InvoiceTable } from '../../generics/Invoices/InvoiceTable/InvoiceTable';
import { TenantSection } from '../../generics/Invoices/TenantSection/TenantSection';
import { LogoSection } from '../../generics/Invoices/LogoSection/LogoSection';
import { InvoiceItem } from '../../generics/Invoices/types';
import { calculateInvoicePriceInfo } from '../../generics/Invoices/utils';
import ProgressBar from '../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../utils/RequestStatus/RequestStatus';
import PieceInvoiceRow from './components/PieceInvoiceRow';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../TransitionEvent';

interface State {
  items: {
    id: number,
    name: string,
    quantity: number,
    unitPrice: number,
  }[],
}

class PieceInvoice extends Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      items: [{
        id: 1,
        name: '',
        quantity: 1,
        unitPrice: 0,
      }]
    }
  }

  /**
   * Update a row based on an invoice item.
   */
   private setInvoiceItem = (item: InvoiceItem) => {
    const index = this.state.items.findIndex(row => row.id === item.id);
    if (index === -1) {
      throw new Error('Failed to find invoice row during replace operation');
    }

    const rows = [...this.state.items];
    rows[index] = {
      ...rows[index],
      quantity: item.quantity,
      unitPrice: item.unitPrice,
    };

    this.setState({ items: rows });
  }

  private onItemAdd = () => {
    const defaultInvoice = {
      id: 1,
      name: '',
      quantity: 1,
      unitPrice: 0,
    }
    const length = this.state.items.length
    
    let tempInvoiceList = [...this.state.items]
    tempInvoiceList.push(defaultInvoice)
    
    const setInvoiceId = tempInvoiceList[length - 1].id + 1
    tempInvoiceList[length] = {...tempInvoiceList[length], id: setInvoiceId}
    this.setState({          
      items: tempInvoiceList
    });
  }

  private onItemDelete = (id: Number) => {
    const tempInvoiceList = [...this.state.items]
    let updatedInvoiceList = tempInvoiceList.filter(x => {
      return x.id != id;
    })
    
    this.setState({          
      items: updatedInvoiceList
    });
  }

  render() {
    const translations = InvoiceLabels[this.props.language];

    const payprofile = this.props.payprofile;
    const tenant = this.props.tenant;
    const client = this.props.client;
    const language = this.props.language;
    const hasVat = this.props.vat !== undefined;

    const monthRange = this.props.dateRange;

    let totalAmount = 0;
    let totalVatAmount = 0;

    const invoiceItems = this.state.items.map(i => {
      const price = calculateInvoicePriceInfo(i.quantity, i.unitPrice, this.props.vat);

      // sum about the amounts for all the rows
      totalAmount += price.amount;
      totalVatAmount += price?.vatAmount || 0;

      return {
        id: i.id,
        name: translations.pieceNameA + (translations.pieceNameB ? `\n${translations.pieceNameB}` : ""),
        quantity: i.quantity,
        unitPrice: i.unitPrice,
        amount: price.amount,
        unitPriceExclVat: price.unitPriceExclVat,
        vatAmount: price.vatAmount,
      }
    });

    return (
      <div>
        <ProgressBar
          currentStep={this.props.requestProgress.currentStep}
          totalSteps={this.props.requestProgress.totalSteps}
        />
        <BreadcrumbControls
          pageTitle="Sold by piece"
          status={this.props.requestStatus}
        />
        <div className="flex-row fill">
          <div className="column">
            <div className="card">
              <div className="flex-row fill">
                <div className="column stretch">
                  <InvoiceSection
                    translations={translations}
                    invoiceSeries={payprofile?.invoiceSeries}
                    since={monthRange.since}
                    until={monthRange.until}
                    language={this.props.language}
                    tenant={tenant}
                  />
                </div>
                <div className="column stretch">
                  <LogoSection
                    translations={translations}
                    currencySymbol={payprofile?.currency}
                    amount={totalAmount + totalVatAmount}
                    language={language}
                    hasVat={hasVat}
                    tenant={tenant}
                  />
                </div>
              </div>
              <div className="flex-row fill">
                <div className="column stretch">
                  <div className="fill">
                    {tenant ?
                      <TenantSection tenant={tenant} vatRate={this.props.vat} labels={translations} payprofile={payprofile} />
                    :
                      <div className="card">
                        <p className="text-chunk">There are no tenants defined. Start by <Link className="link-button" to="/tenant/add">adding</Link> the first one.</p>
                      </div>
                    }
                  </div>
                </div>
                <div className="column stretch">
                  <div className="fill">
                    {client ? 
                      <ClientSection client={client} labels={translations} />
                    : 
                      <div className="card">
                        <p className="text-chunk">There are no clients defined. Start by <Link className="link-button" to="/client/add">adding</Link> the first one.</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row tightest fill">
          <div className="column">
            <br />
            <div className="card">
              <div className="tableview-component">
                <InvoiceTable
                  currencySymbol={payprofile?.currency}
                  hasVat={hasVat}
                  invoiceItems={invoiceItems}
                  totalAmount={totalAmount}
                  totalVatAmount={totalVatAmount}
                  translations={translations}
                  quantityKeyA="pieceA"
                  quantityKeyB="pieceB"
                  onItemChange={this.setInvoiceItem}
                  rowComponent={PieceInvoiceRow}
                  addRows={true}
                  onItemAdd={this.onItemAdd}
                  onItemDelete={this.onItemDelete}
                />
              </div>
            </div>
            <p contentEditable suppressContentEditableWarning={true}>
              {translations?.instructionA}
              <br />
              {translations?.instructionB}
            </p>
            <br />
            <p contentEditable suppressContentEditableWarning={true}>
              {translations?.disclaimerA}
              <br />
              {translations?.disclaimerB}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(withInvoicesLayout(PieceInvoice));
