import React, { Component } from 'react';

interface Props {
  label?: string,
  id?: string,
  name: string,
  value: string,
  onChange: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void,
  fluidWidth?: boolean,
  disabled?: boolean,
  rows?: number,
  cols?: number,
  onBlur?: (ev: React.FocusEvent<HTMLTextAreaElement>) => void,
  error?: string,
  required?: boolean,
  commentBox?: string,
  placeholder?: string,
}

class TextAreaControl extends Component<Props> {
  render() {
    return (
      <>
          {this.props.commentBox ?
            <textarea
              value={this.props.value}
              onChange={this.props.onChange}
              disabled={this.props.disabled}
              onBlur={this.props.onBlur}
              className={this.props.commentBox}
              placeholder={this.props.placeholder}
            >
            </textarea>
          : 
          <div className={`form-group`}>
            <label htmlFor={this.props.id}>{this.props.label}{this.props.required && '*'}</label>
            <br />
            <textarea
              id={this.props.id}
              name={this.props.name}
              value={this.props.value}
              onChange={this.props.onChange}
              disabled={this.props.disabled}
              rows={this.props.rows}
              cols={this.props.cols}
              required={this.props.required}
              onBlur={this.props.onBlur}
              className={this.props.fluidWidth ? "fluid-width" : ""}
              aria-invalid={!!this.props.error}
            >
            </textarea>
            {this.props.error ? <ul className="error-list light"><li>{this.props.error}</li></ul> : ''}
          </div>
        }
      </>
    )
  }
}

export default TextAreaControl;
