import Fetch from "../../helpers/Fetch";
import ClientDTO from "../dtos/Client";

interface AddPayload {
  name: string,
  legalName: string,
  tradeNo: string,
  taxId: string,
  contactEmail: string,
  streetAddress: string,
  shareCapital: string,
  bankAccount: string,
  country: string,
  city: string,
  state: string,
  zipCode: string,
  contractNo: string,
  expiration: Date | null,
  enabled: boolean,
}

export async function listClients(): Promise<ClientDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ClientDTO[]>(`/clients`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch clients');
}

export async function addClient(form: AddPayload): Promise<ClientDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ClientDTO>(`/clients`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to add client`);
}

export async function updateClient(form: AddPayload, clientId: number): Promise<ClientDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ClientDTO>(`/clients/${clientId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });


  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update client`);
}

export async function listClientDetails(clientId: number): Promise<ClientDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ClientDTO>(`/clients/${clientId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to get client details`);
}