import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OfficeVacationDTO from '../../../common/api/dtos/OfficeVacation';
import Fetch from '../../../common/helpers/Fetch';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import Thumbnail  from '../../utils/Thumbnail/Thumbnail';
import GenericCard from '../../utils/GenericCard/GenericCard';
import AppContext from '../../../common/context/AppContext';

export interface Props {
  theme: string,
}

interface State {
  officeVacations?: OfficeVacationDTO[],
  status: TRequestStatus,
}

class Vacations extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'loading',
    }
  }

  fetchOfficeVacations = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch('/vacations/out-of-office?tzOffset=-180', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    });

    await fetchRequest.fetch();
    let rawData = fetchRequest.getPayload();
    let officeVacations = new Array<OfficeVacationDTO>();

    rawData.items.forEach((element: any) => {
      officeVacations.push({
        userId: element['user_id'] || null,
        userName: element['user_name'] || '',
        userAvatar: element['user_avatar'] || '',
        returns: element['returns'] || null,
        leaves: element['leaves'] || null,
      })
    });

    if(fetchRequest.getStatus() === 'success') {
      this.setState({
        officeVacations: officeVacations,
        status: fetchRequest.getStatus(),
      });
    } else {
      this.setState({
        status:fetchRequest.getStatus(),
      })
    }
  }

  componentDidMount() {
    this.fetchOfficeVacations();
  }

  evalURLAccess(userId: number) {
    const userRole = this.context.loggedUser?.role.name;
    if(userRole === 'Admin' || userRole === 'Manager' || userRole === 'Accountant' || userRole === 'Reviewer') {
      return `/bookings/${userId}`;
    } else {
      return null;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Link to={`/bookings/${this.context.loggedUser?.id}`} className="link-button"><h2 className="primary-title">Vacations</h2></Link>
        <div>
          <div className="card-list-component flex-row tightest">
            {!this.state.officeVacations &&
              <div className="column stretch">
                <GenericCard 
                  thumbnailURL={`/assets/characters/ralph_aware_${this.props.theme}.png`}
                  avatarClasses="medium"
                  thumbnailBackground='transparent'
                  cardTitle="..."
                >
                  <>
                    <strong>...</strong>
                    <br />
                    ...
                  </>
                </GenericCard>
              </div>
            }
            {this.state.officeVacations?.length === 0 &&
              <div className="column stretch">
                <div className="card">
                  <Thumbnail
                    thumbnailURL={`/assets/characters/ralph_aware_${this.props.theme}.png`}
                    classes="medium radius"
                    thumbnailBackground='transparent'
                    />
                  <div className="text-container">
                    <p className="story">
                      <span className="primary-title">No vacations!</span>
                      <br />
                      Productivity is rocketing <img className="icon" src="/assets/icons/rocket-100.png" style={{width: "16px"}} alt="icon"/>, take a breather or we'll reach the stars too soon!
                    </p>
                  </div>
                </div>
              </div>
            }
            {this.state.officeVacations && this.state.officeVacations.map(officeVacation => (
              <div key={officeVacation.userId} className="column stretch">
                <GenericCard 
                  avatarData={officeVacation.userAvatar}
                  cardTitle={officeVacation.userName}
                  internalURL={this.evalURLAccess(officeVacation.userId)}
                  classes={officeVacation.returns ? "flashing-border" : ""}
                >
                  <>
                    {officeVacation.returns ?
                      <React.Fragment>
                        <strong><span className="accent-text fas fa-arrow-alt-circle-right"></span> returns </strong>
                        {officeVacation.returns && moment(officeVacation.returns).format('LL')}
                      </React.Fragment>
                    :
                      <React.Fragment>
                        <strong>leaves </strong>
                        {officeVacation.leaves && moment(officeVacation.leaves).format('LL')}
                      </React.Fragment>
                    }
                  </>
                </GenericCard>
              </div>
              ))
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Vacations;

Vacations.contextType = AppContext;