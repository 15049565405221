import React, {Component, ChangeEvent} from 'react';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import ButtonControl from '../../controls/ButtonControl/ButtonControl';
import PasswordForm from '../../forms/PasswordForm/PasswordForm';
import TextControl from '../../controls/TextControl/TextControl';
import RequestStatus from '../../utils/RequestStatus/RequestStatus';
import Thumbnail from '../../utils/Thumbnail/Thumbnail';

export interface Props {
  callback: () => void,
  logout: () => void,
  theme: string,
}

class ResetPass extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <div className="login-component will-transition">
        <div className="login-container">
          <div className="card">
            <Thumbnail
              thumbnailURL={`/assets/characters/ralph_wondering_${this.props.theme}.png`}
              classes="large radius"
              thumbnailBackground='transparent'
            />
            <br />
            <br />
            <small className="faint-text">Ralph knows you're busy, but you must reset your password before he'll trust you again.</small>
          </div>
          <div className="p-large">
            <PasswordForm
              callback={this.props.callback}
              enforced={true}
            />
          </div>
          <div>
            <br />
            <button
              className="link-button"
              onClick={this.props.logout}
            >Log out</button>
          </div>
        </div>
      </div>
    )
  }

}

export default ResetPass;
