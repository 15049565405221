import React, { Component } from 'react';

type UserContext = 'user' | 'reports' | 'office' | 'accounting' | 'entities' | 'overview' | 'system';

interface Props {
  activeContext: UserContext | string,
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined,
  icon: string,
  label?: string,
  tenantAlias?: string,
}

class DropdownOption extends Component<Props> {
  render() {
    return (
      <li className={`${this.props.activeContext === this.props.label ? 'active' : ''}`}>
        <button className="context-menu-button" onClick={this.props.onClick}>
          <span className={`icon fas ${this.props.icon}`}></span>
          <span>&nbsp;{this.props.label ? this.props.label : this.props.tenantAlias}</span>
        </button>
      </li>
    )
  }
}

export default DropdownOption;
