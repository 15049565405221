import { Component } from 'react';
import GhostButtonControl from '../../controls/GhostButtonControl/GhostButtonControl';

interface Props {
    counter: number,
    type: string,
    title: string,
    description: string,
    action: string,
}

class LegendTile extends Component<Props> {
  render() {
    return (
      <div className="card">
        <div className="flex-row flex-v-center">
          <div className="column">
            <div className="marker-wrapper">
              <div className={this.props.type}>
                <GhostButtonControl class="ghost-button">{this.props.counter}</GhostButtonControl>
                <span className="type-label">{this.props.action}</span>
              </div>
            </div>
          </div>
          <div className="column large">
            <small>{this.props.title}<span className="faint-text">{this.props.description}</span></small>
          </div>
        </div>
      </div>
    )
  }
}

export default LegendTile;