import Joi from 'joi';
import moment from 'moment';
import React, { ChangeEvent, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { FormErrors } from '../../../../common/data/FormErrors';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import Fetch from '../../../../common/helpers/Fetch';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import { addHoliday, listHolidayDetails, updateHoliday } from '../../../../common/api/endpoints/holidays';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TextControl from '../../../controls/TextControl/TextControl';
import { withTransitionEvent } from '../../../TransitionEvent';

export interface Props {
  id: string,
}

interface FormData {
  name: string,
  date: Date | null,
  countable: boolean,
  deleted: boolean,
}

interface State {
  holidayId: string,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  formData: FormData,
  formErrors: FormErrors
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

class HolidayDetail extends Component<RouteComponentProps<Props>, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    date: Joi.date().required().messages(FormErrors.date),
    countable: Joi.boolean(),
    deleted: Joi.boolean(),
  });

  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {
      holidayId: this.props.match.params.id,
      formData: {
        name: '',
        date: null,
        countable: false,
        deleted: false,
      },
      progress: {
        currentStep: 0,
        totalSteps: 0
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchHolidayDetails = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const holiday = await listHolidayDetails(this.state.holidayId);

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        formData: {
          name: holiday.name,
          countable: holiday.countable,
          deleted: holiday.deleted,
          date: holiday.date,
        },
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addHoliday(this.state.formData);

    this.setState({
        formStatus: 'success',
    }, () => {
      this.props.history.push({
        pathname: '/holiday'
      })
    });
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await updateHoliday(this.state.formData, this.state.holidayId);

    this.setState({
        formStatus: 'success',
    });
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
  
      if(!isEditPage(Number(this.state.holidayId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    if(isEditPage(Number(this.state.holidayId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1
          }
        }
      });
      this.fetchHolidayDetails()
    } else {
      this.setState({
        pageStatus: "idle",
      })
    }
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value
      }
    })
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setDate = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('date', new Date(ev.target.value));
  setCountable = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('countable', ev.target.checked);
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);

  render() {
    return (
      <React.Fragment>
        <BreadcrumbControls
          items={[
            {label: 'Holidays', path: '/holiday'},
            {label: `${!isEditPage(Number(this.state.holidayId)) ? 'adding' : 'editing'} holiday ${this.state.formData.name}`},
          ]}
          status={this.state.pageStatus}
        />
        <div className="flex-row">
          <div className="column">
            <div className="flex-row tightest-top">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            <div className="card">
              <TextControl
                label="Name"
                type="text"
                name="name"
                id="name"
                onChange={this.setName}
                value={this.state.formData.name}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.name}
              />
              <TextControl
                label="Date"
                type="date"
                name="date"
                id="date"
                onChange={this.setDate}
                value={moment(this.state.formData.date).format('Y-MM-DD')}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.date}
              />
              <ToggleControl 
                id="countable"
                name="countable"
                changeMethod={this.setCountable}
                isChecked={this.state.formData.countable}
                isDisabled={!loadComplete(this.state.progress)}
                labelText="Affects paid days"
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
              />
              {isEditPage(Number(this.state.holidayId)) && 
                <ToggleControl
                  id="deleted"
                  name="deleted"
                  changeMethod={this.setDeleted}
                  isChecked={this.state.formData.deleted}
                  isDisabled={!loadComplete(this.state.progress)}
                  labelText="Deleted"
                  onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                />
              }
              <div className="form-group">
                <ButtonControl
                  class="primary-button"
                  onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    this.handleSubmit(ev)
                  }}
                  disabled={!loadComplete(this.state.progress)}
                >
                  <RequestStatus status={this.state.formStatus} />
                  <span className="text">Save</span>
                </ButtonControl>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

export default withTransitionEvent(HolidayDetail);
