import React, { Component, ChangeEvent } from 'react';
import Fetch from '../../../../common/helpers/Fetch';
import ProjectDTO from '../../../../common/api/dtos/Project';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus'; 
import { Link } from 'react-router-dom';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { listProjects } from '../../../../common/api/endpoints/projects';
import Thumbnail from '../../../utils/Thumbnail/Thumbnail';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../../TransitionEvent';

interface State {
  projects?: ProjectDTO[],
  status: TRequestStatus
  tableSort: TableSort,
  showDisabled: boolean,
  filterValue: string,
}

class ProjectList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'client.name',
    'projectGroup.name'
  ];

  constructor(props: {}) {
    super(props);
    this.state = {
      status: 'loading',
      tableSort: new TableSort('name', 'asc'),
      showDisabled: localStorage.getItem('showDisabledProjects') === 'false' || localStorage.getItem('showDisabledProjects') === null ? false : true,
      filterValue: '',
    }
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({showDisabled: false}) : this.setState({showDisabled: true});
  }

  fetchProjects = async () => {
    this.setState({
      status: 'loading'
    });
    
    const projects = await listProjects();

    this.setState({
        status: 'success',
        projects: projects,
    });
  }
  
  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchProjects();
  }

  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th className="text-left">
          <SortButton 
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-left">
          <SortButton 
            column='client.name' 
            text='Client'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-left">
          <SortButton 
            column='projectGroup.name' 
            text='Project Groups'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='billable' 
            text='Billable'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        {this.state.showDisabled &&
          <th className="text-center">
            <SortButton
              column='enabled'
              text='Enabled'
              tableSort={this.state.tableSort}
              onClick={this.handleSortChange}
            ></SortButton>
          </th>
        }
        <th></th>
      </tr>
    )
  }

  renderTBodyRow(project: ProjectDTO) {
    return (
      <tr id={`project${project.id}`} key={`${project.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <Thumbnail
                thumbnailURL={project.avatar}
                classes="thumbnail"
              />
              <span>{project.name}</span>
            </div>
          </div>
        </td>
        <td>
          <span>{project.client?.name}</span>
        </td>
        <td>
          <span>{project.projectGroupId !== null ? project.projectGroup?.name : '-'}</span>
        </td>
        <td className="text-center">
          {project.billable && <span className="fas fa-check"></span>}
        </td>
        {this.state.showDisabled &&
          <td className="text-center">
            {project.enabled && 
              <span className="fas fa-check"></span>
            }
          </td>
        }
        <td className="text-right">
          <GhostButtonControl to={`/project/edit/${project.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredProjects: Array<ProjectDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.projects ? this.state.projects : []).sort(this.state.tableSort.sortByColumn).filter((project: ProjectDTO) => project.enabled === true || this.state.showDisabled);
    return (
      <>
        <BreadcrumbControls
          pageTitle="projects"
          status={this.state.status}
        />
        {this.state.projects && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showDisabledProjects"
                name="showDisabledProjects"
                changeMethod={this.toggleDisabled}
                isChecked={this.state.showDisabled}
                labelText="Disabled"
              />
              <div className="form-group">
                <Link to='/project/add' className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add project</span></Link>
              </div>
            </ToolbarControls>

            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component ">
                  <div className={`
                      card
                      ${!this.state.projects ? 'loader-border' : ''}
                    `}
                  >
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.projects &&
                        <React.Fragment>
                          <tbody>
                            {filteredProjects.map(project => (
                              this.renderTBodyRow(project)
                            ))}
                          </tbody>
                          <tfoot>
                            {this.state.projects?.length === 0 &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">There are no projects defined. Start by <Link className="link-button" to="/project/add">adding</Link> the first one.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredProjects.length} out of {this.state.projects.filter((project: ProjectDTO) => project.enabled === true || this.state.showDisabled).length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}
export default withTransitionEvent(ProjectList);