import Joi from 'joi';
import moment from 'moment';
import React, { ChangeEvent, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import LogoDTO from '../../../../common/api/dtos/Logo';
import { addLogo, listLogoDetails, updateLogo } from '../../../../common/api/endpoints/logos';
import { FormErrors } from '../../../../common/data/FormErrors';
import Fetch from '../../../../common/helpers/Fetch';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import TextControl from '../../../controls/TextControl/TextControl';
import { withTransitionEvent } from '../../../TransitionEvent';

export interface Props {
  id: string,
}

interface FormData {
  name: string,
  url: string,
  enabled: boolean,
  fromDate: Date | null,
  toDate: Date | null,
  priority: number,
}

interface State {
  logoId: string,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  formData: FormData,
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class LogoDetail extends Component<RouteComponentProps<Props>, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    url: Joi.string().trim(true).required().messages(FormErrors.string),
    enabled: Joi.boolean(),
    fromDate: Joi.date().required().messages(FormErrors.date),
    toDate: Joi.date().required().messages(FormErrors.date),
    priority: Joi.number().allow(null, 0),
  });

  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {
      logoId: this.props.match.params.id,
      formData: {
        name: '',
        url: '',
        enabled: false,
        fromDate: null,
        toDate: null,
        priority: 0,
      },
      progress: {
        currentStep: 0,
        totalSteps: 0
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchLogoDetails = async () => {
    this.setState({
      pageStatus: 'loading'
    });

    const logo = await listLogoDetails(this.state.logoId);

    this.setState(prevState => {
      return {
        formData: {
          name: logo.name,
          url: logo.url,
          enabled: logo.enabled,
          fromDate: logo.fromDate,
          toDate: logo.toDate,
          priority: logo.priority,
        },
        progress: incrementProgress(prevState.progress),
        pageStatus: 'success',
      }
    });
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addLogo(this.state.formData);

    this.setState({
      formStatus: 'success',
    }, () => {
      this.props.history.push({
        pathname: '/logo'
      })
    });
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await updateLogo(this.state.formData, this.state.logoId);

    this.setState({
      formStatus: 'success',
    });
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });

      if (!isEditPage(Number(this.state.logoId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    if (isEditPage(Number(this.state.logoId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1
          }
        }
      });
      this.fetchLogoDetails()
    } else {
      this.setState({
        pageStatus: "idle",
      })
    }
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value
      }
    })
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setUrl = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('url', ev.target.value);
  setFromDate = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('fromDate', moment.utc(ev.target.value).startOf('day').toDate());
  // Use startOf('second') since API does not support millisecond precision.
  setToDate = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('toDate', moment.utc(ev.target.value).endOf('day').startOf('second').toDate());
  setEnabled = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('enabled', ev.target.checked);
  setPriority = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('priority', Number(ev.target.value));

  render() {
    return (
      <React.Fragment>
        <BreadcrumbControls
          items={[
            { label: 'Logos', path: '/logo' },
            { label: `${!isEditPage(Number(this.state.logoId)) ? 'adding' : 'editing'} logo ${this.state.formData.name}` },
          ]}
          status={this.state.pageStatus}
        />
        <div className="flex-row">
          <div className="column">
            <div className="flex-row tightest-top">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            <div className="card">
              <TextControl
                label="Name"
                type="text"
                name="name"
                id="name"
                value={this.state.formData.name}
                onChange={this.setName}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.name}
              />
              <div className="form-group">
                <div className="flex-row">
                  <div className="column large">
                    <TextControl
                      label="Image"
                      type="text"
                      name="url"
                      id="url"
                      value={this.state.formData.url}
                      onChange={this.setUrl}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      required={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                      error={this.state.formErrors.url}
                    />
                    <small className="faint-text"><span className="fas fa-info-circle"></span> You can use both relative or absolute paths.</small>
                  </div>
                  <div className="column small flex-v-center">
                    <img
                      className="thumbnail radius padded large"
                      src={this.state.formData.url}
                      alt="avatar"
                    />
                  </div>
                </div>
              </div>
              <TextControl
                label="Priority"
                type="number"
                name="priority"
                id="priority"
                value={this.state.formData.priority}
                onChange={this.setPriority}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
              />
              <TextControl
                label="From Date"
                type="date"
                name="fromDate"
                id="fromDate"
                value={moment.utc(this.state.formData.fromDate).format('Y-MM-DD')}
                onChange={this.setFromDate}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.fromDate}
              />
              <TextControl
                label="To Date"
                type="date"
                name="toDate"
                id="toDate"
                value={moment.utc(this.state.formData.toDate).format('Y-MM-DD')}
                onChange={this.setToDate}
                disabled={!loadComplete(this.state.progress)}
                br={true}
                required={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.fromDate}
              />
              <ToggleControl
                id="enabled"
                name="enabled"
                changeMethod={this.setEnabled}
                isChecked={this.state.formData.enabled}
                isDisabled={!loadComplete(this.state.progress)}
                labelText="Enabled"
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
              />
              <div className="form-group">
                <ButtonControl
                  class="primary-button"
                  onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    this.handleSubmit(ev)
                  }}
                  disabled={!loadComplete(this.state.progress)}
                >
                  <RequestStatus status={this.state.formStatus} />
                  <span className="text">Save</span>
                </ButtonControl>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


export default withTransitionEvent(LogoDetail);
