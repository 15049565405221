import Joi from 'joi';
import moment from 'moment';
import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import { NavLink, Route, RouteComponentProps } from "react-router-dom";
import ContractDTO, { ContractType } from '../../../../common/api/dtos/Contract';
import RoleDTO from '../../../../common/api/dtos/Role';
import TenantDTO from '../../../../common/api/dtos/Tenant';
import UserDTO from '../../../../common/api/dtos/User';
import { listTenants } from '../../../../common/api/endpoints/tenants';
import { listUsers } from '../../../../common/api/endpoints/users';
import { FormErrors } from '../../../../common/data/FormErrors';
import Fetch from '../../../../common/helpers/Fetch';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { LoggedUser } from '../../../../common/interfaces/LoggedUser';
import { UserPropName } from '../../../../common/interfaces/UserPropName';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import SelectControl from '../../../controls/SelectControl/SelectControl';
import TextAreaControl from '../../../controls/TextAreaControl/TextAreaControl';
import TextControl from '../../../controls/TextControl/TextControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import WorkProfilesForm from '../../../forms/WorkProfilesForm/WorkProfilesForm';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../../TransitionEvent';
import DeepFlowOption from '../../../utils/DeepFlopOption/DeepFlowOption';
import ProgressBar, { incrementProgress, IProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import Thumbnail from '../../../utils/Thumbnail/Thumbnail';

export interface Props extends RouteComponentProps<RouteParams> {
  loggedUser: LoggedUser,
}

interface DeepFlowContext {
  icon: string,
  navLink: string,
  navName: string,
  description: string,
  page: JSX.Element,
  disabled?: boolean
}

interface RouteParams {
  id?: string,
  eid?: string,
  entity?: string,
}

interface FormData {
  deleted: boolean,
  description: string,
  endDate: Date | null,
  hasTickets: boolean,
  hourNorm: number | null,
  name: string,
  overlapping: boolean,
  startDate: Date,
  userId?: number | undefined,
  tenantId?: number | undefined,
  type: ContractType | undefined,
  yearlyWorkDays: number | null,
}

interface State {
  contractId?: number,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  tenants?: TenantDTO[],
  users?: UserDTO[],
  roles?: RoleDTO[],
  usersPropsNames?: UserPropName[],
  formData: FormData,
  formErrors: FormErrors,
  indefinite: boolean,
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

class ContractDetail extends Component<Props, State> {
  formSchema = Joi.object({
    deleted: Joi.boolean(),
    description: Joi.string().trim(true).allow(null, ''),
    endDate: Joi.date().allow(null, '').messages(FormErrors.date),
    hasTickets: Joi.boolean(),
    hourNorm: Joi.number().max(8).allow(null, '').messages(FormErrors.number),
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    overlapping: Joi.boolean(),
    startDate: Joi.date().required().messages(FormErrors.date),
    userId: Joi.number().required().messages(FormErrors.string),
    tenantId: Joi.number().required().messages(FormErrors.string),
    type: Joi.string().trim(true).required().messages(FormErrors.string),
    yearlyWorkDays: Joi.number().allow(null, '').messages(FormErrors.number),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      contractId: Number(this.props.match.params.id),
      indefinite: true,
      formData: {
        deleted: false,
        description: '',
        endDate: null,
        hasTickets: false,
        hourNorm: null,
        name: '',
        overlapping: true,
        startDate: new Date(),
        userId: this.props.match.params.entity == 'user' ? Number(this.props.match.params.eid) : undefined,
        tenantId: this.props.match.params.entity == 'tenant' ? Number(this.props.match.params.eid) : undefined,
        type: undefined,
        yearlyWorkDays: null
      },
      progress: {
        currentStep: 0,
        totalSteps: 2
      },
      pageStatus: 'idle',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchContractDetails = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch<ContractDTO>(`/contracts/${this.state.contractId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    })
    
    await fetchRequest.fetch();
    if(fetchRequest.getStatus() === 'success') {
      this.setState((prevState: State) => {
        const contract = fetchRequest.getPayload();
        return {
          formData: {
            deleted: contract.deleted,
            description: contract.description,
            endDate: contract.endDate,
            hasTickets: contract.hasTickets,
            hourNorm: contract.hourNorm,
            name: contract.name,
            overlapping: contract.overlapping,
            startDate: contract.startDate,
            userId: contract.user?.id,
            tenantId: contract.tenant?.id,
            type: contract.type,
            yearlyWorkDays: contract.yearlyWorkDays
          },
          indefinite: !contract.endDate,
          progress: incrementProgress(prevState.progress),
          pageStatus: 'success',
        }
      });
    }
  }

  fetchUsers = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const users = await listUsers();

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        users,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  fetchTenants = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const tenants = await listTenants();

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        tenants,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  addEntity = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/contracts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(this.state.formData),
    });

    let entityName = this.props.match.params.entity;
    let entityId = this.props.match.params.eid;

    let returnPath = entityId ? `/${entityName}/edit/${entityId}/contract` : '/contract';
    
    await fetchRequest.fetch();
    this.setState({
      formStatus: fetchRequest.getStatus(),
    },() => {
      if(this.state.formStatus === 'success'){
        this.props.history.push({
          pathname: returnPath,
        })
      }
    });
  }

  updateEntity = async () => {
    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/contracts/` + this.state.contractId, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(this.state.formData),
    })
    
    await fetchRequest.fetch();
    this.setState({
      formStatus: fetchRequest.getStatus(),
    });
  }
  
  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
  
      if(!isEditPage(Number(this.state.contractId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  getContextPanel() {
    return [
      {
        icon: 'fal fa-file-alt',
        navLink: 'account',
        navName: 'Contract Details',
        description: 'Edit general information for this contract',
        page: this.renderDetailsForm()
      },
      {
        icon: 'fal fa-hourglass-start',
        navLink: 'work-profile',
        navName: 'Work Profiles',
        description: 'Manage aspects about this contract for a given period of time',
        disabled: this.state.formData.type === "contractor" ? true : false,
        page: (
          <React.Fragment>
            {this.state.contractId && 
              <>
                {this.props.match.params.entity ?
                  <WorkProfilesForm
                    entity={this.props.match.params.entity}
                    entityId={this.props.match.params.eid}
                    subentity="contract"
                    subentityId={this.state.contractId.toString()}
                    />
                :
                  <WorkProfilesForm
                    entity="contract"
                    entityId={this.state.contractId.toString()}
                    />
                }
              </>
            }
          </React.Fragment>
        )
      },
    ]
  }

  inDeepFlow = () => {
    const isNested = this.getContextPanel().some(context => this.props.location.pathname.includes(context.navLink));
    return isNested;
  }

  getContextName = () => {
    const contextName = this.getContextPanel().find(context => context.navLink === this.props.location.pathname.split('/').pop())?.navName;
    return contextName ? contextName : '';
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    this.fetchTenants();
    this.fetchUsers();
    
    if(isEditPage(Number(this.state.contractId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 3,
          }
        }
      });
      this.fetchContractDetails();
    }
  } 

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      }
    }, () => {
      this.validateFormField(field);
    });
  }

  isDisabled (field: string) {
    return !loadComplete(this.state.progress) || isEditPage(Number(this.state.contractId)) || (!!this.props.match.params.eid && this.props.match.params.entity == field)
  }

  renderDetailsForm() {
    const currentTenant = this.state.tenants?.find((tenant: TenantDTO) => tenant.id == this.state.formData.tenantId);
    const currentUser = this.state.users?.find((user: UserDTO) => user.id == this.state.formData.userId);
    return(
      <div className="card">
        <TextControl
          label="Name"
          type="text"
          name="name"
          id="name"
          value={this.state.formData.name}
          onChange={this.setName}
          disabled={!loadComplete(this.state.progress)}
          required={true}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.name}
        />
        <TextAreaControl
          label="Description"
          id="description"
          name="description"
          value={this.state.formData.description}
          onChange={this.setDescription}
          disabled={!loadComplete(this.state.progress)}
          rows={3}
          cols={30}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        <SelectControl
          idName="type"
          label="Type"
          required={true}
          value={this.state.formData.type}
          options={[{value: "employee", label: "Employee"}, {value: "contractor", label: "Contractor"}]}
          disabled={!loadComplete(this.state.progress) || isEditPage(Number(this.state.contractId))}
          disabledValue={!isEditPage(Number(this.state.contractId)) ? "Select a type" : null}
          onChange={this.setType}
          getValue={(op) => op?.value}
          getLabel={(op) => op?.label}
          onBlur={ev => this.validateFormField(ev.target.name  as keyof FormData)}
          error={this.state.formErrors.type}
        />
        {this.state.formData.type !== "contractor" &&
          <TextControl
            label="Hour norm"
            type="number"
            name="hourNorm"
            id="hourNorm"
            onChange={this.setHourNorm}
            placeholder=""
            value={this.state.formData.hourNorm ?? ""}
            disabled={!loadComplete(this.state.progress)}
            br={true}
          />
        }
        <TextControl
          label="Work Days (yearly)"
          type="number"
          name="yearlyWorkDays"
          id="yearlyWorkDays"
          value={this.state.formData.yearlyWorkDays ?? ''}
          onChange={this.setYearlyWorkDays}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.yearlyWorkDays}
        />
        <TextControl
          label="Start Date (first day of work)"
          type="date"
          name="startDate"
          id="startDate"
          onChange={this.setStartDate}
          value={moment(this.state.formData.startDate).format('Y-MM-DD')}
          disabled={!loadComplete(this.state.progress) || isEditPage(Number(this.state.contractId))}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        <ToggleControl 
          name="indefinite" 
          id="indefinite" 
          isChecked={this.state.indefinite}
          changeMethod={this.setIndefinite}
          isDisabled={!loadComplete(this.state.progress)}
          labelText="Indefinite"
        />
        {!this.state.indefinite &&
          <TextControl
            label="End Date (last day of work)"
            type="date"
            name="endDate"
            id="endDate"
            onChange={this.setEndDate}
            value={moment(this.state.formData.endDate).format('Y-MM-DD')}
            disabled={!loadComplete(this.state.progress)}
            br={true}
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          />
        }
        <ToggleControl 
          name="hasTickets" 
          id="hasTickets" 
          isChecked={this.state.formData.hasTickets}
          changeMethod={this.setHasTickets}
          isDisabled={!loadComplete(this.state.progress)}
          labelText="Has Food Stamps"
        />
        <ToggleControl 
          name="overlapping" 
          id="overlapping" 
          isChecked={this.state.formData.overlapping}
          changeMethod={this.setOverlapping}
          isDisabled={!loadComplete(this.state.progress)}
          labelText="Include in overlaps"
        />
        <SelectControl
          idName="userId"
          label="Signatory"
          required={true}
          value={this.state.formData.userId}
          options={this.state.users as UserDTO[] || []}
          disabled={!loadComplete(this.state.progress) || isEditPage(Number(this.state.contractId)) || (!!this.props.match.params.eid && this.props.match.params.entity == 'user')}
          disabledValue={!isEditPage(Number(this.state.contractId)) ? "Select a user" : null}
          onChange={this.setUserId}
          getValue={(op) => op?.id}
          getLabel={(op) => op?.fullName ? op.fullName : 'Unknown user'}
          onBlur={ev => this.validateFormField(ev.target.name  as keyof FormData)}
          error={this.state.formErrors.userId}
        />
        {currentUser && 
          <div className="form-group">
            <div className="card">
              <div className="flex-row squeeze">
                <div className="column flex-v-center">
                  <span className="fas fa-exclamation-circle negative-text"></span>
                </div>
                <div className="column">
                  <small>{!isEditPage(Number(this.state.contractId)) ? 'You are permanently attaching this contract to the following user:' : 'This contract is permanently attached to the following user:'}</small><br />
                  <small className="faint-text">
                    {currentUser.fullName}
                  </small>
                </div>
              </div>
            </div>
          </div>
        }
        <SelectControl
          idName="tenantId"
          label="Issuer"
          required={true}
          value={this.state.formData.tenantId}
          options={this.state.tenants || []}
          disabled={!loadComplete(this.state.progress) || isEditPage(Number(this.state.contractId)) || (!!this.props.match.params.eid && this.props.match.params.entity == 'tenant')}
          disabledValue={!isEditPage(Number(this.state.contractId)) ? "Select a tenant" : null}
          onChange={this.setTenantId}
          getValue={(op) => op?.id}
          getLabel={(op) => op?.name}
          onBlur={ev => this.validateFormField(ev.target.name  as keyof FormData)}
          error={this.state.formErrors.tenantId}
        />
        {currentTenant && 
          <div className="form-group">
            <div className="card">
              <div className="flex-row squeeze">
                <div className="column flex-v-center">
                  <span className="fas fa-exclamation-circle negative-text"></span>
                </div>
                <div className="column">
                  <small>{!isEditPage(Number(this.state.contractId)) ? 'You are permanently attaching this contract to the following tenant:' : 'This contract is permanently attached to the following tenant:'}</small><br />
                  <small className="faint-text">
                    {currentTenant.legalName}<br />
                    {currentTenant.taxId}<br />
                    {currentTenant.tradeNo}<br />
                  </small>
                </div>
              </div>
            </div>
          </div>
        }
        {isEditPage(Number(this.state.contractId)) && 
          <ToggleControl 
            name="deleted" 
            id="deleted" 
            isChecked={this.state.formData.deleted}
            changeMethod={this.setDeleted}
            isDisabled={!loadComplete(this.state.progress)}
            labelText="Deleted"
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          />
        }
        <ButtonControl
          class="primary-button"
          onClick={this.handleSubmit}
          disabled={!loadComplete(this.state.progress)}
        >
          <RequestStatus status={this.state.formStatus} />
          <span className="text">Save</span>
        </ButtonControl>
      </div>
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);
  setIndefinite = (ev: ChangeEvent<HTMLInputElement>) => {this.setState((prevState) => {
    return {
      ...prevState,
      indefinite: ev.target.checked,
      formData: {
        ...prevState.formData,
        endDate: ev.target.checked ? null : new Date()
      }
    }
  })};
  setHasTickets = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('hasTickets', ev.target.checked);
  setOverlapping = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('overlapping', ev.target.checked);
  setStartDate = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('startDate', new Date(ev.target.value))}
  setEndDate = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('endDate', new Date(ev.target.value))};
  setType = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, contractType: number | string | undefined | {value: ContractType, label: string}) => this.updateFormData('type', contractType as ContractType);
  setUserId = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent, userId: number | string | undefined | UserDTO) => this.updateFormData('userId', Number(userId));
  setHourNorm = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('hourNorm', ev.target.value ? Number(ev.target.value) : null);
  setTenantId = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent, tenantId: number | string | undefined | TenantDTO) => this.updateFormData('tenantId', Number(tenantId));
  setYearlyWorkDays = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('yearlyWorkDays', Number(ev.target.value));

  renderBreadcrumbs() {
    const entity = this.props.match.params.entity;
    const entityId: number = Number(this.props.match.params.eid);

    let entityName;

    switch(entity) {
      case 'user':
        entityName = this.state.users?.find((user: UserDTO) => user.id == entityId)!.name;
        break;
      case 'tenant':
        entityName = this.state.tenants?.find((tenant: TenantDTO) => tenant.id == entityId)!.name
        break;
      default:
        entityName = this.state.formData.name ? this.state.formData.name : 'loading'
    }

    return(
      !entity ?
        <BreadcrumbControls
          items={
            this.inDeepFlow() === false ?
              [
                {label: 'contracts', path: '/contract'},
                {label: `${!isEditPage(Number(this.state.contractId)) ? 'adding contract ' : ''}${this.state.formData.name}`},
              ]
            :
              [
                {label: 'contracts', path: '/contract'},
                {label: entityName ? entityName : '', path: `/contract/edit/${this.state.contractId}`},
                {label: this.getContextName()},
              ]
          }
        />
      :
        <BreadcrumbControls 
          items={
            this.inDeepFlow() === false ?
              [
                {label: `${entity}s`, path: `/${entity}`},
                {label: entityName ? entityName : '', path: `/${entity}/edit/${entityId}`},
                {label: 'contracts', path: `/${entity}/edit/${entityId}/contract`},
                {label: `${!isEditPage(Number(this.state.contractId)) ? 'adding contract ' : ''}${this.state.formData.name}`},
              ]
            : [
                {label: `${entity}s`, path: `/${entity}`},
                {label: entityName ? entityName : '', path: `/${entity}/edit/${entityId}`},
                {label: 'contracts', path: `/${entity}/edit/${entityId}/contract`},
                {label: `${!isEditPage(Number(this.state.contractId)) ? 'adding contract ' : ''}${this.state.formData.name}`, path: `/${entity}/deep/${entityId}/contract/edit/${this.state.contractId}`},
                {label: this.getContextName()},
              ]
          }
          status={this.state.pageStatus}
        />
    )
  }

  renderEntityHeaders() {
    let signatory = this.state.users?.filter((user: UserDTO) => user.id == this.state.formData.userId)[0];
    let issuer = this.state.tenants?.filter((tenant: TenantDTO) => tenant.id == this.state.formData.tenantId)[0];

    return(
      <div className="card-list-component flex-row tightest-top flex-v-center">
        <div className="column small">
          <div className="card transparent">
            <div className="text-container story">
              <span className="primary-title">{this.state.formData.name ? this.state.formData.name : this.state.formData!== null ? "..." : ""}</span><br />
              <span>{this.state.formData.description ? this.state.formData.description : "..."}</span>
            </div>
          </div>
        </div>
        <div className="column small">
          <div className="card transparent">
            <div className="text-container story">
              <span>Signatory</span><br />
              <span className="faint-text">{signatory ? signatory.fullName : "..."}</span>
            </div>
          </div>
        </div>
        <div className="column small">
          <div className="card transparent">
            <div className="text-container story">
              <span>Issuer</span><br />
              <span className="faint-text">{issuer ? issuer.legalName : "..."}</span>
            </div>
          </div>
        </div>
        <div className="column small">
          <div className="card transparent">
            <div className="text-container story">
              <span>Contract Start Date</span><br />
              <span className="faint-text">{this.state.formData.startDate ? moment(this.state.formData.startDate).format('LL') : "..."}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderDeepFlowOption(context: DeepFlowContext, index: number) {
    return (
      <React.Fragment key={index}>
        <div className="form-group anim-pull-right tight" id={`userDetail${index}`}>
          {!context.disabled ?
            <NavLink to={`${this.props.match.url}/${context.navLink}`} className='card-button'>
              <span className={`icon ${context.icon}`}></span>
              <span className="text-container">
                {context.navName}
                <br />
                <small className='faint-text'>{context.description}</small>
              </span>
              <span className="fas fa-angle-right"></span>
            </NavLink>
          :
            <div className='card-button disabled'>
              <span className={`icon ${context.icon}`}></span>
              <span className="text-container">
                {context.navName}
                <br />
                <small className='faint-text'>{context.description}</small>
              </span>
            </div>
          }
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        {/* {this.inDeepFlow() === false ?
          <BreadcrumbControls
            items={[
              {label: 'contracts', path: '/contract'},
              {label: `${!isEditPage(Number(this.state.contractId)) ? 'adding contract ' : ''}${this.state.formData.name}`},
            ]}
          />
        :
          <BreadcrumbControls
            items={[
              {label: 'contracts', path: '/contract'},
              {label: entityName ? entityName : '', path: `/contract/edit/${this.state.contractId}`},
              {label: this.getContextName()},
            ]}
          />
        } */}
        {this.renderBreadcrumbs()}
        <div className="flex-row tightest-top">
          <div className="column large">
            <div className="flex-row">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            {isEditPage(Number(this.state.contractId)) ?
              <>
                {this.renderEntityHeaders()}
                {this.getContextPanel().map((context, index) => {
                  return(
                    this.inDeepFlow() ?
                      <Route
                        path={`${this.props.match.url}/${context.navLink}`}
                        render={() => context.page}
                      />
                    :
                      <DeepFlowOption
                        baseURL={this.props.match.url}
                        context={context}
                        index={index}
                      />
                  )
                })}
              </>
            :
              this.renderDetailsForm()
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTransitionEvent(ContractDetail);