import Fetch from "../../helpers/Fetch";
import RemotesDTO, { RemotesEveryoneDTO } from "../dtos/Remotes";

export async function listUserRemotes(userId: number): Promise<RemotesDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<RemotesDTO[]>(`/remotes/filter/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      uid: userId,
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch user remotes');
}

export async function listEveryoneRemotes(year: number, userId: number): Promise<RemotesEveryoneDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<RemotesEveryoneDTO[]>(`/remotes/all?tzOffset=${(new Date()).getTimezoneOffset()}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      year: year,
      uid: userId,
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch list of everyones remote`);
}

export async function SaveRemote(remote: RemotesDTO): Promise<RemotesDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<RemotesDTO>(`/remotes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(remote),
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to save remotes');
}
export async function CancelRemote(bookingId: number): Promise<RemotesDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<RemotesDTO>(`/remotes/${bookingId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })  

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to delete remote');
}