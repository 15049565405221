import React, { lazy, Suspense } from 'react';
import { Props } from './ProgressBar';

const LazyProgressBar = lazy(() => import('./ProgressBar'));

const ProgressBar = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; } & Props) => (
  <Suspense fallback={null}>
    <LazyProgressBar {...props} />
  </Suspense>
);

export default ProgressBar;
