import React, { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import ContractDTO from "../../../common/api/dtos/Contract";
import { listTenantContracts, listUserContracts } from "../../../common/api/endpoints/contracts";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import GhostButtonControl from "../../controls/GhostButtonControl/GhostButtonControl";
import ToolbarControls from "../../generics/Header/ToolbarControls";
import DateTCell from "../../utils/DateTCell/DateTCell";
import TextControl from "../../controls/TextControl/TextControl";
import SortButton from "../../utils/SortButton/SortButton";
import { incrementProgress, IProgress } from "../../utils/ProgressBar/ProgressBar";
import { withTransitionEvent } from "../../TransitionEvent";

interface Props {
  entityId: number,
  entity: 'user' | 'tenant',
  hasFullAccess?: boolean
}

interface State {
  contracts?: ContractDTO[],
  status: TRequestStatus,
  formStatus: TRequestStatus,
  progress: IProgress,
  showDeleted: boolean,
  tableSort: TableSort,
	propsForm: PropForm,
  filterValue: string,
  showSensitive: boolean,
}

interface PropForm {
  employment: string,
  fullName: string,
  position: string,
  hourNorm: number,
  phone: string,
  userId: number,
  contracts: ContractDTO[],
}

class ContractsForm extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'type',
    'hourNorm',
    'user.name',
    'user.fullName',
    'tenant.name',
    'tenant.legalName'
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: 'idle',
      formStatus: 'idle',
      propsForm: {
        employment: '',
        fullName: '',
        position: '',
        hourNorm: 8,
        phone: '',
        userId: this.props.entityId,
        contracts: [],
      },
      tableSort: new TableSort('name', 'asc'),
      showSensitive: false,
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      filterValue: '',
    }
  }

  sensitiveTimeout: number = 0;

  toggleSensitive = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.showSensitive ? this.setState({showSensitive: false}) : this.setState({showSensitive: true});
  }

  fetchEntityContracts = async () => {
    switch(this.props.entity) {
      case 'user': 
        return await listUserContracts(this.props.entityId);
      case 'tenant': 
        return await listTenantContracts(this.props.entityId);
    }
  }

  fetchContracts = async () => {
    this.setState({
      status: 'loading'
    });
    
    const contracts = await this.fetchEntityContracts();

    this.setState(prevState => {
      return {
        status: 'success',
        contracts: contracts,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({ showDeleted: false }) : this.setState({ showDeleted: true });
  }

  componentDidMount() {
    this.fetchContracts();
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  setAssigned = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentProjectId: number = Number(ev.target.value);
    this.setState((prevState: State) => {
      let assignedContracts = prevState.propsForm.contracts.slice(0);
      let alreadyAssigned = assignedContracts.map((contract: ContractDTO) => { return contract.id; }).indexOf(currentProjectId);

      if(alreadyAssigned === -1) {
        let selectedProject = this.state.contracts?.filter((contract: ContractDTO) => contract.id === currentProjectId)[0];
        if(selectedProject) {
          assignedContracts.push(selectedProject);
        }
      } else {
        assignedContracts.splice(alreadyAssigned, 1);
      }
      
      return {
        propsForm: {
          ...prevState.propsForm,
          contracts: assignedContracts
        }
      }
    });
    
  }

  componentDidUpdate(prevProps: Props, prevState: State,) {
    if(this.state.showSensitive !== prevState.showSensitive) {
      if(this.state.showSensitive){
        this.sensitiveTimeout = setTimeout(() => {
          this.setState({showSensitive: false});
        }, parseInt(String(process.env.REACT_APP_SENSITIVE_DATA_TIMEOUT))) as unknown as number;
      } else {
        clearTimeout(this.sensitiveTimeout);
      }
    }
  }

  renderTHeadRow() {
    return ( 
      <tr>
        <th>#</th>
        <th>
          <SortButton
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='description' 
            text='Description'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='type' 
            text='Type'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='hourNorm' 
            text='Hour norm'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='startDate' 
            text='Start date'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='endDate' 
            text='End date'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='user.name' 
            text='Signatory'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='tenant.name'
            text='Issuer'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(contract: ContractDTO) {
    return (
      <tr id={`contract${contract.id}`} key={`${contract.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <span>{contract.name}</span>
            </div>
          </div>
        </td>
        <td>
          <div className="text-chunk">
            <span
              className={!this.state.showSensitive ? 'blurred-item' : 'blurred-item deblur'}
            >
              {contract.description}
            </span>
          </div>
        </td>
        <td>
          <span>{contract.type}</span>
        </td>
        <td className="text-center">
          <span>{contract.hourNorm ? contract.hourNorm + "h" : ""}</span>
        </td>
        <DateTCell
          date={contract.startDate}
        />
        <DateTCell
          date={contract.endDate}
          fallbackText="indefinite"
        />
        <td>
          <span>{contract.user.name}</span>
          <br />
          <small>
            <span
              className={!this.state.showSensitive ? 'blurred-item faint-text' : 'blurred-item deblur faint-text'}
            >
              {contract.user.fullName}
            </span>
          </small>
        </td>
        <td>
          <span>{contract.tenant.name}</span>
          <br />
          <small
            className={!this.state.showSensitive ? 'blurred-item faint-text' : 'blurred-item deblur faint-text'}
          >
            {contract.tenant.legalName}
          </small>
        </td>
        <td className="text-right">
          <GhostButtonControl
            to={`/contract/edit/${contract.id}`}
            class="ghost-button"
            icon="fas fa-angle-right"
          />
        </td>
      </tr>
    );
  }

  render() {
    const filteredContracts: Array<ContractDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.contracts ? this.state.contracts : []).sort(this.state.tableSort.sortByColumn).filter((contract: ContractDTO) => contract.deleted === this.state.showDeleted);
    
    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
            {this.props.hasFullAccess &&
              <>
              <ToggleControl
                id="showSensitive"
                name="showSensitive"
                changeMethod={this.toggleSensitive}
                isChecked={this.state.showSensitive}
                labelText="Sensitive data"
                icon="fas fa-shield-alt"
                srOnly={true}
                title="Sensitive data"
              />
                <ToggleControl 
                  id="showDeleted"
                  name="showDeleted" 
                  changeMethod={this.toggleDeleted} 
                  isChecked={this.state.showDeleted}
                  labelText="Recycle bin"
                  icon="fas fa-trash-undo"
                  srOnly={true}
                  title="Recyle bin"
                />
                <div className="form-group">
                  <Link to={`/${this.props.entity}/deep/${this.props.entityId}/contract/add`} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add contract</span></Link>
                </div>
              </>
            }
            </ToolbarControls>
        </div>
        <div className={`card ${!this.state.contracts ? 'loader-border' : ''} ${this.state.showDeleted ? "flashing-border" : ""}`}>
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 315px)'}}>
                  <table>
                    <thead>
                      {this.renderTHeadRow()}
                    </thead>
                    {this.state.contracts &&
                      <React.Fragment>
                        <tbody>
                          {filteredContracts.map(contract => (
                            this.renderTBodyRow(contract)
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.contracts?.length === 0 &&
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  {this.props.hasFullAccess ? 
                                    <p className="text-chunk">
                                      There are no contracts defined for this {this.props.entity}. Start by <Link className="link-button" to={`/${this.props.entity}/${this.props.entityId}/contract/add`}>adding</Link> the first one.
                                    </p>
                                  :
                                    <p className="text-chunk">
                                      There are no contracts.
                                    </p>
                                  }
                                </div>
                              </th>
                            </tr>
                      }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  <p className="text-chunk">Showing {filteredContracts.length} out of {this.state.contracts.filter((contract: ContractDTO) => contract.deleted === this.state.showDeleted).length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTransitionEvent(ContractsForm);