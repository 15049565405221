import React from 'react';
import { PartialProjectGroup, ProjectGroupDTO } from '../../../../common/api/dtos/ProjectGroup';
import currencyFormatter from '../../../../common/helpers/CurrencyFormatter';
import ButtonControl from '../../../../components/controls/ButtonControl/ButtonControl';
import InvoiceRow from '../InvoiceRow/InvoiceRow';
import { InvoiceItem } from '../types';
export interface RowComponentProps {
  deleteRows?: boolean;
  invoiceItem: InvoiceItem;
  hasVat: boolean;
  currencySymbol?: string;
  onChange: (_: InvoiceItem) => void;
  onItemDelete?: (_: Number) => void;
  quantityLabelA: string;
  quantityLabelB: string;
}
interface Props {
  addRows?: boolean,
  hasVat: boolean,
  currencySymbol?: string,
  invoiceItems: InvoiceItem[],
  totalAmount: number,
  totalVatAmount: number,
  onItemChange?: (_: InvoiceItem) => void,
  onItemAdd?: () => void;
  onItemDelete?: (_: Number) => void;
  translations: Record<string, string>,
  quantityKeyA?: string,
  quantityKeyB?: string,
  rowComponent?: React.ComponentType<RowComponentProps>,
  projectGroups?: ProjectGroupDTO[]
  otherProjectGroups?: string,
}
export class InvoiceTable extends React.Component<Props> {
  private onItemChange = (item: InvoiceItem) => {
    if (this.props.onItemChange) {
      this.props.onItemChange(item);
    }
  }

  renderProjectGroups = (projectGroup: ProjectGroupDTO) => {
    const { hasVat } = this.props
      const currencySymbol = this.props.currencySymbol;
      const filteredItems = this.props.invoiceItems.filter(item => item.quantity !== 0 &&
                                                                   item.quantity !== null &&
                                                                   item.projectGroupId === projectGroup.id);
      const subTotal = filteredItems.reduce((accumulator, item) => {
        return accumulator + item.amount;
      }, 0)
      const subTotalVat = filteredItems.reduce((accumulator, item) => {
      return accumulator + (item.vatAmount as number);
    }, 0)

      return (
        <React.Fragment key={projectGroup.id}>
          <tr className='uncounted' >
            <td colSpan={7}>{projectGroup.name}</td>
          </tr>
          {filteredItems.map(item => {
            return <InvoiceRow
            key={item.id}
            hasVat={this.props.hasVat}
            invoiceItem={item}
            currencySymbol={currencySymbol}
            quantityLabelA={this.props.translations[this.props.quantityKeyA || 'hourA']}
            quantityLabelB={this.props.translations[this.props.quantityKeyB || 'hourB']}
            onChange={this.onItemChange}
            />
          })} 
          <tr className="uncounted active">
            <td></td>
            <td className="text-right" colSpan={5}>
              <strong>SUBTOTAL {currencyFormatter(subTotal, currencySymbol)} </strong>
            </td>
            {hasVat && 
              <td className="text-right">
                <strong>SUBTOTAL {currencyFormatter(subTotalVat, currencySymbol)} </strong>
              </td>
            }
            <td></td>
          </tr>
        </React.Fragment>
      )
  }

  renderOrphans = (orphans: InvoiceItem[]) => {
    const { currencySymbol, translations, hasVat} = this.props;
    const subTotal = orphans.reduce((accumulator, item) => {
      return accumulator + item.amount;
    }, 0)
    const subTotalVat = orphans.reduce((accumulator, item) => {
      return accumulator + (item.vatAmount as number);
    }, 0)
    return(
      <>
        
          <tr className="uncounted">
            <td colSpan={7}>
            {translations.otherProjectGroups}
            </td>
          </tr>  

          {this.renderProjects(orphans)}

          <tr className="uncounted active">
            <td></td>
            <td className="text-right" colSpan={5}>
              <strong>SUBTOTAL {currencyFormatter(subTotal, currencySymbol)} </strong>
            </td>
            {hasVat && 
              <td className="text-right">
                <strong>SUBTOTAL {currencyFormatter(subTotalVat, currencySymbol)} </strong>
              </td>
            }
            <td></td>
          </tr>
      </>
    )
  }

  renderProjects = (filteredItems: InvoiceItem[]) => {
    const { hasVat, currencySymbol, translations, quantityKeyA, quantityKeyB, onItemDelete } = this.props;
    const RowComponent = this.props.rowComponent || InvoiceRow;
    return(
      <>
          {filteredItems.map((item: InvoiceItem) => {
            return <RowComponent
              key={item.id}
              hasVat={hasVat}
              invoiceItem={item}
              currencySymbol={currencySymbol}
              quantityLabelA={translations[quantityKeyA || 'hourA']}
              quantityLabelB={translations[quantityKeyB || 'hourB']}
              onChange={this.onItemChange}
              onItemDelete={onItemDelete}
              />;
          })}
      </>
    )
  }

  render() {
    const { hasVat, currencySymbol, translations, invoiceItems, totalAmount, totalVatAmount, addRows, onItemAdd, projectGroups } = this.props;
    const hasVatFields = invoiceItems.every(item => typeof item.unitPriceExclVat === 'number' && typeof item.vatAmount === 'number');
    const nonGroupedItems = projectGroups !== undefined ?
                              invoiceItems.filter(item => item.projectGroupId === null &&
                                                          item.quantity !== 0 &&
                                                          item.quantity !== null)
                            : 
                              invoiceItems.filter(item => item.projectGroupId === undefined &&
                                                          item.quantity !== 0 && item.quantity !== null);
    const deletedProjectGroups = projectGroups?.filter(projectGroup => projectGroup.deleted === true);
    const extraOrphans: InvoiceItem[] = [];
    deletedProjectGroups?.forEach(projectGroup => {
      const extraItems = invoiceItems.filter(item => item.projectGroupId === projectGroup.id);
      extraOrphans.push(...extraItems);
    })

    if (invoiceItems.length && hasVat !== hasVatFields) {
      throw new Error('Expected every invoice item to have valid VAT fields');
    }
    
    return <table>
      <thead>
        <tr>
          <th>{translations?.noA}<br />{translations?.noB}</th>
          <th>{translations?.productA}<br />{translations?.productB}</th>
          <th>{translations?.unitA}<br />{translations?.unitB}</th>
          <th className="text-right">{translations?.quantityA}<br />{translations?.quantityB}</th>

          {hasVat
            ? <th className="text-right">{translations?.unitPriceNoVatA}<br />{translations?.unitPriceNoVatB}</th>
            : <th className="text-right">{translations?.unitPriceA}<br />{translations?.unitPriceB}</th>}
            
          <th className="text-right" >{translations?.amountA}<br />{translations?.amountB}</th>
          
          {hasVat && <th className="text-right">{translations?.vatAmountA}<br />{translations?.vatAmountB}</th>}
          <th></th>
        </tr>
      </thead>

      <tbody>
        {projectGroups?.filter(projectGroup => projectGroup.deleted !== true)
                       .map(projectGroup => this.renderProjectGroups(projectGroup))}

        {projectGroups !== undefined && projectGroups.length !== 0 && nonGroupedItems.length ?
          this.renderOrphans([...nonGroupedItems, ...extraOrphans])
          :
          this.renderProjects(nonGroupedItems)
        }
        
        {addRows && 
          <tr>
            <th colSpan={hasVat ? 8 : 7}>
                <ButtonControl
                    class="primary-button fill hide-print"
                    onClick={onItemAdd}
                >
                  <span>Add</span>  
                </ButtonControl>
            </th>
          </tr>
        }
      </tbody>

      <tfoot>
        {hasVat
          ? <React.Fragment>
            <tr>
              <th colSpan={6} className="text-right">
                <span>TOTAL {currencyFormatter(totalAmount, currencySymbol)}</span>
              </th>
              <th className="text-right">
                <span>TOTAL {currencyFormatter(totalVatAmount, currencySymbol)}</span>
              </th>
              <th></th>
            </tr>
            <tr>
              <th colSpan={7} className="text-right">
                <span>{translations?.grandTotalA} / {translations?.grandTotalB} {currencyFormatter((totalAmount + totalVatAmount), currencySymbol)}</span>
              </th>
              <th></th>
            </tr>
          </React.Fragment>
          : <tr>
            <th colSpan={6} className="text-right">
              <span>TOTAL {currencyFormatter(totalAmount, currencySymbol)}</span>
            </th>
            <th></th>
          </tr>}

      </tfoot>
    </table>
  }
}
