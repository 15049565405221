import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import { RouteComponentProps, Route, NavLink } from "react-router-dom";
import { RoleDTO } from '../../../../common/api/dtos/Role';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import BenefitsForm from '../../../forms/BenefitsForm/BenefitsForm';
import PasswordForm from '../../../forms/PasswordForm/PasswordForm';
import ProjectsForm from '../../../forms/ProjectsForm/ProjectsForm';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SelectControl from '../../../controls/SelectControl/SelectControl';
import Joi from 'joi';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { FormErrors } from '../../../../common/data/FormErrors';
import { addNewRole, addRole, getNewRole, RoleEndpointDTO, updateNewRole, updateRole } from '../../../../common/api/endpoints/roles';
import Thumbnail  from '../../../utils/Thumbnail/Thumbnail';
import BadgesForm from '../../../forms/BadgesForm/BadgesForm';
import ContractsForm from '../../../forms/ContractsForm/ContractsForm';
import GroupsForm from '../../../forms/GroupsForm/GroupsForm';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TextControl from '../../../controls/TextControl/TextControl';
import SuspensionsForm from '../../../forms/SuspensionsForm/SuspensionsForm';
import CompensationsForm from '../../../forms/CompensationsForm/CompensationsForm';
import TextAreaControl from '../../../controls/TextAreaControl/TextAreaControl';
import RoleConfiguration from '../RoleConfiguration/RoleConfiguration';
import { withTransitionEvent } from '../../../TransitionEvent';

export interface Props extends RouteComponentProps<RouteParams> {
}

interface RouteParams {
  id?: string,
}

interface FormData {
  name: string,
  description: string,
  endpoints: RoleEndpointDTO[],
}

interface State {
  roleId?: number,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  formData: FormData,
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class RoleDetail extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    description: Joi.string().trim(true).allow(null, '').messages(FormErrors.string),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      roleId: Number(this.props.match.params.id),
      formData: {
        name: '',
        description: '',
        endpoints: [],
      },
      progress: {
        currentStep: 0,
        totalSteps: 0,
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchRoleDetails = async () => {
    if(this.state.roleId) {
      this.setState({
        pageStatus: 'loading'
      });

      const role = await getNewRole(this.state.roleId.toString());

      this.setState(prevState => {
        return {
          formData: {
            name: role.name,
            description: role.description,
            endpoints: role.endpoints,
          },
          progress: incrementProgress(prevState.progress),
          pageStatus: 'success',
        }
      });
    }
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addNewRole(this.createRequestPayload());

    this.setState({
        formStatus: 'success',
    }, () => {
      this.props.history.push({
        pathname: '/role'
      })
    });
  }

  updateEntity = async () => {
    if(this.state.roleId) {
      this.setState({
        formStatus: 'loading'
      });
      await updateNewRole(this.createRequestPayload(), this.state.roleId.toString());

      this.setState({
          formStatus: 'success',
      });
    }
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });

      if(!isEditPage(Number(this.state.roleId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  createRequestPayload() {
    return {
      name: this.state.formData.name,
      description: this.state.formData.description,
    };
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const payload = this.createRequestPayload();
    const result = this.formSchema.validate(payload, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    if(isEditPage(Number(this.state.roleId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1,
          }
        }
      });
      this.fetchRoleDetails();
    }
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value
      }
    }, () => {
      this.validateFormField(field);
    })
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  getContextPanel() {
    return [
      {
        icon: 'fal fa-role',
        navLink: 'account',
        navName: 'Role Details',
        description: 'Edit general information for this role',
        page: this.renderRoleEditCard()
      },
      {
        icon: 'fal fa-roles',
        navLink: 'assigned-endpoints',
        navName: 'Endpoint Access',
        description: 'Manage endpoint access and flag configuration',
        disabled: false,
        page: (
          <React.Fragment>
            <RoleConfiguration roleId={this.state.roleId!} roleEndpoints={this.state.formData.endpoints} />
          </React.Fragment>
        )
      },
    ]
  }

  renderRoleEditCard() {
    return(
      <div>
        <div className="card">
          <TextControl
            label="Name"
            type="text"
            name="name"
            id="name"
            value={this.state.formData.name}
            onChange={this.setName}
            disabled={!loadComplete(this.state.progress)}
            required={true}
            br={true}
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
            error={this.state.formErrors.name}
          />
          <TextAreaControl
            label="Description"
            id="description"
            name="description"
            value={this.state.formData.description}
            onChange={this.setDescription}
            disabled={!loadComplete(this.state.progress)}
            rows={3}
            cols={30}
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          />
          {isEditPage(Number(this.state.roleId)) &&
            <></>
          }
        </div>
        <ButtonControl
          class="primary-button"
          onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            this.handleSubmit(ev)
          }}
          disabled={!loadComplete(this.state.progress)}
        >
          <RequestStatus status={this.state.formStatus} />
          <span className="text">Save</span>
        </ButtonControl>
      </div>
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);

  checkNestedContext = () => {
    const isNested = this.getContextPanel().some(context => this.props.location.pathname.includes(context.navLink));
    return isNested;
  }

  getContextName = () => {
    const contextName = this.getContextPanel().find(context => context.navLink === this.props.location.pathname.split('/').pop())?.navName;
    return contextName ? contextName : '';
  }

  render() {
    let entityName = this.state.formData.name ? this.state.formData.name : 'loading';
    return (
      <div>
        {this.checkNestedContext() === false ?
          <BreadcrumbControls
            items={[
              {label: 'roles', path: '/role'},
              {label: `${!isEditPage(Number(this.state.roleId)) ? 'adding role ' : ''}${this.state.formData.name}`},
            ]}
          />
        :
          <BreadcrumbControls
            items={[
              {label: 'roles', path: '/role'},
              {label: entityName ? entityName : '', path: `/role/edit/${this.state.roleId}`},
              {label: this.getContextName()},
            ]}
          />
        }
        <div className="flex-row tightest-top">
          <div className="column large">
            <div className="flex-row">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            {isEditPage(Number(this.state.roleId)) &&
              <div className="card-list-component flex-row tightest-top flex-v-center">
                <div className="column">
                  <div className="card transparent">
                    <div className="text-container story">
                      <span>{this.state.formData.description ? this.state.formData.description : "..."}</span>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="card transparent">
                    <div className="text-container story">
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="card transparent">
                    <div className="text-container story">
                    </div>
                  </div>
                </div>
              </div>
            }
            {
              isEditPage(Number(this.state.roleId)) ?
                this.getContextPanel().map((context, index) => {
                  if(this.checkNestedContext() === true) {
                    return (
                      <Route
                        path={`${this.props.match.url}/${context.navLink}`}
                        render={() => context.page}
                      />
                    )
                  } else {
                    return (
                      <React.Fragment key={index}>
                        <div className="form-group anim-pull-right tight" id={`roleDetail${index}`}>
                          {!context.disabled ?
                            <NavLink to={`${this.props.match.url}/${context.navLink}`} className='card-button'>
                              <span className={`icon ${context.icon}`}></span>
                              <span className="text-container">
                                {context.navName}
                                <br />
                                <small className='faint-text'>{context.description}</small>
                              </span>
                              <span className="fas fa-angle-right"></span>
                            </NavLink>
                          :
                            <div className='card-button disabled'>
                              <span className={`icon ${context.icon}`}></span>
                              <span className="text-container">
                                {context.navName}
                                <br />
                                <small className='faint-text'>{context.description}</small>
                              </span>
                            </div>
                          }
                        </div>
                      </React.Fragment>
                    )
                  }
                })
              :
                this.renderRoleEditCard()
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(RoleDetail);