import React, { Component } from 'react';
import { NavigationLabels } from '../../../common/data/NavigationLabels';
import hasAccess from '../../../common/helpers/Access';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { NavLabel } from '../../../common/interfaces/NavLabel';
import DropdownOption from '../../utils/DropdownOption/DropdownOption';
import NavElement from '../../utils/NavElement/NavElement';

type UserContext = 'user' | 'data' | 'office' | 'tracking' | 'invoicing' | 'entities' | 'system';
type UserRoles = 'Admin' | 'Manager' | 'Accountant' | 'Reviewer' | 'User';
 
interface State {
  activeContext: UserContext,
  userRole: UserRoles,
  userContextOpen: boolean,
  navExpanded: boolean,
}

interface Props {
  loggedUser?: LoggedUser,
  setMobileNavOpen: () => void,
}

class Navigation extends Component<Props,State> {
  userContextOptionsRef: React.RefObject<HTMLUListElement>;
  userContextButtonRef: React.RefObject<HTMLButtonElement>;
  
  constructor(props: Props) {
    super(props);
    this.userContextOptionsRef = React.createRef();
    this.userContextButtonRef = React.createRef();
    this.state = { 
      activeContext: this.retrieveActiveContext(),
      userRole: this.props.loggedUser?.role.name as UserRoles || "User",
      userContextOpen: false,
      navExpanded: false
    }
  }

  retrieveActiveContext():UserContext {
    let activeContext = localStorage.getItem(this.props.loggedUser?.id + "activeContext") || 'user';
    switch(activeContext) {
      case 'user':
      case 'data':
      case 'office':
      case 'tracking':
      case 'invoicing':
      case 'entities':
      case 'system':
        return localStorage.getItem(this.props.loggedUser?.id + "activeContext") as UserContext || 'user';
    }
    return 'user';
  }

  componentDidUpdate(prevProps: Props) {
    if(this.props.loggedUser !== prevProps.loggedUser) {
      this.setState({
        userRole: this.props.loggedUser?.role.name as UserRoles
      });
    }
  }

  setUserContext = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newContext: UserContext): void => {
    event.stopPropagation();
    this.setState({
      userContextOpen: false,
      activeContext: newContext,
    }, () => {
      localStorage.setItem(this.props.loggedUser?.id + "activeContext", this.state['activeContext']);
    });
  }
   
  toggleUserContext = () => {
    this.setState((prevState) => {
      return {
        userContextOpen: !prevState.userContextOpen
      }
    });
  }

  closeUserContext = () => {
    this.setState((prevState: State) => {
      return {
        userContextOpen: false
      }
    });
  }

  setuserContextOptionsRef = (node: React.RefObject<HTMLUListElement>) => {
    this.userContextOptionsRef = node;
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.userContextOptionsRef
        && !this.userContextOptionsRef.current?.contains(event.target as HTMLElement)
        && this.userContextButtonRef
        && !this.userContextButtonRef.current?.contains(event.target as HTMLElement)
      ) {
      this.closeUserContext();
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  navLabelFilter(navLabel: NavLabel) {
    return (navLabel.production.toString() === process.env.REACT_APP_PROD || process.env.REACT_APP_PROD === "false") && !this.showClockingReport(navLabel);
  }

  showClockingReport(navLabel: NavLabel) {
    const isVisible = this.props.loggedUser?.role.name === 'Reviewer' && 
                          navLabel.path === '/clocking-report';
  
    return isVisible;
  }

  navigating = () => {
    const scrollContainer = document.querySelector('.v-scroll');
    scrollContainer?.scrollTo({
      top: 0,
      left: 0,
    })
    this.props.setMobileNavOpen();
  }

  render() {
    return (
      <>
        <nav className="navigation-component bar-content-start">
          <ul className="nav-list vertical">
            <li className="nav-li" style={{"minHeight": '2rem'}}>
              {hasAccess(this.props.loggedUser?.role) &&
                <>
                  <div className="context-menu-wrapper align-h-start nav-context">
                    <button
                      ref={this.userContextButtonRef}
                      className={[
                        'nav-link',
                        this.state.userContextOpen ? 'active' : ''
                      ].join(" ")}
                      onClick={() => this.toggleUserContext()}
                    >
                      <div className="content-wrapper">
                        <span>{this.state.activeContext}</span>
                        <span className="icon fas fa-angle-down"></span>
                      </div>
                    </button>
                    <ul ref={this.userContextOptionsRef}
                      className={`
                        context-menu
                        ${this.state.userContextOpen ? 'open' : ''}
                      `}
                    >
                      <React.Fragment>
                        <DropdownOption
                          activeContext={this.state.activeContext}
                          onClick={(e) => this.setUserContext(e, 'user')}
                          icon="fa-user"
                          label="user"
                        />
                        <DropdownOption
                          activeContext={this.state.activeContext}
                          onClick={(e) => this.setUserContext(e, 'data')}
                          icon="fa-chart-line"
                          label="data"
                        />
                        {(this.state.userRole === 'Admin' || this.state.userRole === 'Manager' || this.state.userRole === 'Accountant') &&
                          <React.Fragment>
                            <DropdownOption
                              activeContext={this.state.activeContext}
                              onClick={(e) => this.setUserContext(e, 'office')}
                              icon="fa-building"
                              label="office"
                            />
                            <DropdownOption
                              activeContext={this.state.activeContext}
                              onClick={(e) => this.setUserContext(e, 'tracking')}
                              icon="fa-search"
                              label="tracking"
                            />
                            <DropdownOption
                              activeContext={this.state.activeContext}
                              onClick={(e) => this.setUserContext(e, 'invoicing')}
                              icon="fa-coins"
                              label="invoicing"
                            />
                          </React.Fragment>
                        }
                        {(this.state.userRole === 'Admin' || this.state.userRole === 'Manager') && 
                          <>
                            <DropdownOption
                              activeContext={this.state.activeContext}
                              onClick={(e) => this.setUserContext(e, 'entities')}
                              icon="fa-users"
                              label="entities"
                            />
                          </>
                        }
                        {this.state.userRole === 'Admin' && 
                          <DropdownOption
                            activeContext={this.state.activeContext}
                            onClick={(e) => this.setUserContext(e, 'system')}
                            icon="fa-toolbox"
                            label="system"
                          />
                        }
                      </React.Fragment>
                    </ul>
                  </div>
                </>
              }
            </li>
            <NavElement
              id="homeButton"
              path="/"
              imageURL="home-100"
              labelText="Home"
              exact={true}
              onClick={this.navigating}
            />
            {NavigationLabels[this.state.activeContext].filter((navLabel: NavLabel) => this.navLabelFilter(navLabel)).map((navLabel: NavLabel, index: number) => (
              <NavElement
                id={`${navLabel.labelText.toLowerCase()}Button`}
                key={index}
                path={navLabel.path}
                imageURL={navLabel.imageURL}
                labelText={navLabel.labelText}
                onClick={this.navigating}
                basePaths={navLabel.basePaths}
              />
            ))}
          </ul>
        </nav>
      </>
    );
  }
}

export default Navigation;
