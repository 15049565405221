import React, { ChangeEvent, Component } from 'react';
import { Link } from 'react-router-dom';
import ContractDTO from '../../../../common/api/dtos/Contract';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import TextControl from '../../../controls/TextControl/TextControl';
import { listContracts } from '../../../../common/api/endpoints/contracts';
import moment from 'moment';
import DateTCell from '../../../utils/DateTCell/DateTCell';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import { withTransitionEvent } from '../../../TransitionEvent';

interface State {
  contracts?: ContractDTO[],
  progress: IProgress,
  status: TRequestStatus,
  showDeleted: boolean,
  tableSort: TableSort,
  selectedUser: string,
  filterValue: string,
  showSensitive: boolean,
}

class ContractList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'type',
    'hourNorm',
    'user.name',
    'user.fullName',
    'tenant.name',
    'tenant.legalName'
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: 'loading',
      showSensitive: false,
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      tableSort: new TableSort('name', 'asc'),
      selectedUser: '',
      filterValue: '',
    }
  }

  sensitiveTimeout: number = 0;

  dropdownSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      selectedUser: event.target.value,
    });
  }

  
  toggleSensitive = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.showSensitive ? this.setState({showSensitive: false}) : this.setState({showSensitive: true});
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  fetchContracts = async () => {
    this.setState({
      status: 'loading'
    });
    
    const contracts = await listContracts();

    this.setState(prevState => {
      return {
        status: 'success',
        contracts: contracts,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchContracts();
  }

  componentDidUpdate(prevState: State) {
    if(this.state.showSensitive !== prevState.showSensitive) {
      if(this.state.showSensitive){
        this.sensitiveTimeout = setTimeout(() => {
          this.setState({showSensitive: false});
        }, parseInt(String(process.env.REACT_APP_SENSITIVE_DATA_TIMEOUT))) as unknown as number;
      } else {
        clearTimeout(this.sensitiveTimeout);
      }
    }
  }
  
  renderTHeadRow() {
    return ( 
      <tr>
        <th>#</th>
        <th>
          <SortButton
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='description' 
            text='Description'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='type' 
            text='Type'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='hourNorm' 
            text='Hour norm'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='startDate' 
            text='Start date'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='endDate' 
            text='End date'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='user.fullName' 
            text='Signatory'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='tenant.name'
            text='Issuer'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(contract: ContractDTO) {
    return (
      <tr id={`contract${contract.id}`} key={`${contract.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <span>{contract.name}</span>
            </div>
          </div>
        </td>
        <td>
          <div className="text-chunk"><span className={!this.state.showSensitive ? 'blurred-item' : 'blurred-item deblur'}>{contract.description}</span></div>
        </td>
        <td>
          <span>{contract.type}</span>
        </td>
        <td className="text-center">
          <span>{contract.hourNorm ? contract.hourNorm + "h" : ""}</span>
        </td>
        <DateTCell date={contract.startDate}/>
        <DateTCell date={contract.endDate} fallbackText="indefinite" />
        <td>
          <span>{contract.user.name}</span>
          <br />
          <small><span className={!this.state.showSensitive ? 'blurred-item faint-text' : 'blurred-item deblur faint-text'}>{contract.user.fullName}</span></small>
        </td>
        <td>
          <span>{contract.tenant.name}</span>
          <br />
          <small className={!this.state.showSensitive ? 'blurred-item faint-text' : 'blurred-item deblur faint-text'}>{contract.tenant.legalName}</small>
        </td>
        <td className="text-right">
          <GhostButtonControl to={`/contract/edit/${contract.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredContracts: Array<ContractDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.contracts ? this.state.contracts : []).sort(this.state.tableSort.sortByColumn).filter((contract: ContractDTO) => contract.deleted === this.state.showDeleted);
    return (
      <div>
        <ToolbarControls>
          <TextControl
            label="Filter"
            placeholder="Filter"
            type="text"
            name="filterBox"
            id="filterBox"
            onChange={this.setFilterValue}
            srOnly={true}
          />
          <ToggleControl
            id="showSensitive"
            name="showSensitive"
            changeMethod={this.toggleSensitive}
            isChecked={this.state.showSensitive}
            labelText="Sensitive data"
                icon="fas fa-shield-alt"
                srOnly={true}
                title="Sensitive data"
          />
          <ToggleControl
            id="showDeleted"
            name="showDeleted"
            changeMethod={this.toggleDisabled}
            isChecked={this.state.showDeleted}
            labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
          />
          <div className="form-group">
            <Link to={'/contract/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add contract</span></Link>
          </div>
        </ToolbarControls>
        <BreadcrumbControls
          pageTitle="Contracts"
          status={this.state.status}
        />
        <div className="tableview-component flex-row fill">
          <div className="column">
            <div className={`
                card
                ${!this.state.contracts ? 'loader-border' : ''}
                ${this.state.showDeleted ? "flashing-border" : ""}
              `}
            >
              <table>
                <thead>
                  {this.renderTHeadRow()}
                </thead>
                {this.state.contracts &&
                  <React.Fragment>
                    <tbody>
                      {filteredContracts.map(contract => (
                        this.renderTBodyRow(contract)
                      ))}
                    </tbody>
                    <tfoot>
                      {this.state.contracts?.length === 0 &&
                        <tr>
                          <th colSpan={12}>
                            <div className="card">
                              <p className="text-chunk">There are no contracts defined. Start by <Link className="link-button" to="/contract/add">adding</Link> the first one.</p>
                            </div>
                          </th>
                        </tr>
                      }
                      {this.state.filterValue &&
                        <tr>
                          <th colSpan={12}>
                            <div className="card">
                              <p className="text-chunk">Showing {filteredContracts.length} out of {this.state.contracts.filter((contract: ContractDTO) => contract.deleted === this.state.showDeleted).length} results.</p>
                            </div>
                          </th>
                        </tr>
                      }
                    </tfoot>
                  </React.Fragment>
                }
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  };
}
export default withTransitionEvent(ContractList);
