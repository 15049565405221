import { extend } from "joi";
import moment from "moment";
import React, { ChangeEvent, Component, ReactNode } from "react";
import { Link } from "react-router-dom";
import GroupDTO from "../../../common/api/dtos/Group";
import { listGroups } from "../../../common/api/endpoints/groups";
import Fetch from "../../../common/helpers/Fetch";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import Thumbnail from "../../utils/Thumbnail/Thumbnail";
import TextControl from "../../controls/TextControl/TextControl";
import RequestStatus from "../../utils/RequestStatus/RequestStatus";
import SortButton from "../../utils/SortButton/SortButton";
import ToolbarControls from "../../generics/Header/ToolbarControls";
import { listAssignedGroups } from "../../../common/api/endpoints/users";

interface Props {
  editUserId: number,
}

interface State {
  userPropId: number | undefined | null,
  status: TRequestStatus,
  formStatus: TRequestStatus,
  groups?: GroupDTO[],
  showDeleted: boolean,
  showOnlyAssignedGroups: boolean,
  tableSort: TableSort,
  formData: FormData,
  filterValue: string,
}

interface FormData {
  groups: GroupDTO[],
}

class GroupsForm extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'iconURL',
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      formStatus: 'idle',
      formData: {
        groups: [],
      },
      userPropId: undefined,
      tableSort: new TableSort('name', 'asc'),
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      showOnlyAssignedGroups: false,
      filterValue: '',
    }
  }

  fetchGroups = async () => {
    this.setState({
      status: 'loading'
    });

    const groups = await listGroups();

    this.setState({
      status: 'success',
      groups: groups,
    });
  }

  fetchAssignedGroups = async () => {
    this.setState({
      status: 'loading'
    });
    
    const assignedGroups = await listAssignedGroups(this.props.editUserId);

    this.setState({
      status: 'success',
      formData: {
        groups: assignedGroups.length ? assignedGroups : [],
      }
    });
  }

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) => 
  {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({ showDeleted: false}) : this.setState({ showDeleted: true });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  toggleVisible = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showOnlyAssignedGroups ? this.setState({
      showOnlyAssignedGroups: false }) : this.setState({
      showOnlyAssignedGroups: true });
  }

  checkIfAssigned = (groupId: number): boolean => {
    return this.state.formData.groups.map((group: GroupDTO) => { return group.id; }).indexOf(groupId) === -1 ? false : true;
  }

  componentDidMount() {
    this.fetchGroups();
    this.fetchAssignedGroups();
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });

    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/user/assigned-groups/` + this.props.editUserId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(this.state.formData)
    })
    
    await fetchRequest.fetch();

    this.setState({
      formStatus: fetchRequest.getStatus()
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  setAssigned = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentProjectId: number = Number(ev.target.value);
    this.setState((prevState: State) => {
      let assignedGroups = prevState.formData.groups.slice(0);
      let alreadyAssigned = assignedGroups.map((group: GroupDTO) => { return group.id; }).indexOf(currentProjectId);

      if(alreadyAssigned === -1) {
        let selectedProject = this.state.groups?.filter((group: GroupDTO) => group.id === currentProjectId)[0];
        if(selectedProject) {
          assignedGroups.push(selectedProject);
        }
      } else {
        assignedGroups.splice(alreadyAssigned, 1);
      }

      return {
        formData: {
          ...prevState.formData,
          groups: assignedGroups
        }
      }
    });
  }

  getFilteredGroups() {
    return getFilteredItems(this.state.filterValue, this.availableFilters, this.state.groups ? this.state.groups : [])
    .filter((group: GroupDTO) => 
      (group.deleted === this.state.showDeleted) 
      &&
      (this.state.showOnlyAssignedGroups === false || this.checkIfAssigned(group.id))
    );
  }

  render() {
    const filteredGroups = this.getFilteredGroups().sort(this.state.tableSort.sortByColumn);
    const totalGroups = this.state.groups?.filter((group: GroupDTO) => group.deleted === this.state.showDeleted);
    const totalAssignedGroups = this.state.groups?.filter((group: GroupDTO) => this.checkIfAssigned(group.id));

    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
            <ToggleControl
              id="showDeleted"
              name="showDeleted"
              changeMethod={this.toggleDeleted}
              isChecked={this.state.showDeleted}
              labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
            />
            <ToggleControl
              id="showOnlyAssignedGroups"
              name="showOnlyAssignedGroups"
              changeMethod={this.toggleVisible}
              isChecked={this.state.showOnlyAssignedGroups}
              labelText="Assigned"
            />
          </ToolbarControls>
        </div>
        <div className={`card ${!this.state.groups ? 'loader-border' : ''} ${this.state.showDeleted ? "flashing-border" : ""}`}>
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 350px'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="text-left">
                          <SortButton
                            column='name'
                            text='Name'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}></SortButton>
                        </th>
                        <th className="text-left">
                          <SortButton
                            column="description"
                            text="Description"
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}></SortButton>
                        </th>
                        <th className="text-center">
                          Assigned
                        </th>
                      </tr>
                    </thead>
                    {this.state.groups && 
                      <React.Fragment>
                        <tbody>
                          {filteredGroups && filteredGroups.map(group => (
                            <React.Fragment>
                              <tr id={`group${group.id}`} key={group.id}>
                                <td></td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center">
                                      <Thumbnail
                                        thumbnailURL={group.iconURL}
                                        classes="radius padded"
                                      />
                                      <span className="">{group.name}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <p className="text-chunk">{group.description}</p>
                                </td>
                                <td className="text-center">
                                  <ToggleControl
                                    id={`group-${group.id}`}
                                    name={`group-${group.id}`}
                                    changeMethod={this.setAssigned}
                                    isChecked={this.checkIfAssigned(group.id)}
                                    value={String(group.id)}
                                    labelText={this.checkIfAssigned(group.id) ? 'ON' : 'OFF'}
                                    classes="text-center"
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.groups.length === 0 &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk">There are no groups defined. Start by <Link className="link-button" to="/groups-list/add">adding</Link> the first one.</p>
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                <p className="text-chunk">Showing {filteredGroups.length} out of {this.state.showOnlyAssignedGroups ? totalAssignedGroups?.length : totalGroups?.length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
            <button
              className="primary-button"
              onClick={this.updateEntity}
              disabled={Boolean(!this.state.groups)}
            >
              <RequestStatus status={this.state.formStatus} />
              <span className="text">Save</span>
            </button>
        </div>
      </div>
    )
  }
}

export default GroupsForm;