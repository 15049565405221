import moment from 'moment';
import React from 'react';

interface Props {
  date: string | Date | null,
  fallbackText?: string
}

function DateTCell(props: Props) {
  if (moment(props.date).isValid()) {
    return (
      <td>
        <span>{moment(props.date).format('LL')}</span>
      </td>
    );
  }
  else {
    return (<td>{props.fallbackText}</td>);
  }
}

export default DateTCell;
