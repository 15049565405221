import Fetch from "../../helpers/Fetch";
import SecucardDTO from "../dtos/Secucard";

interface AddPayload {
  name: string,
  internalId?: string,
  iconUrl: string,
  description: string,
  tileColor: string,
  lastUpdate: string,
  userId?: number | null,
  deleted: boolean,
}

export async function listSecucards(): Promise<SecucardDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SecucardDTO[]>(`/secucards`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch secucards');
}

export async function listUserSecucards(userId?: number): Promise<SecucardDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SecucardDTO[]>(`/secucards/user/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch logged user secucards');
}

export async function listSecucardDetails(itemId?: string): Promise<SecucardDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SecucardDTO>(`/secucards/${itemId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch secucard details');
}

export async function addSecucard(form: AddPayload): Promise<SecucardDTO | SecucardError> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SecucardDTO>(`/secucards`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success' || status === 'error') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update secucard`);
}

export async function updateSecucard(form: AddPayload, itemId: string): Promise<SecucardDTO | SecucardError> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SecucardDTO>(`/secucards/${itemId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success' || status === 'error') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update secucard`);
}

export type SecucardError = {
  error: {
    field: keyof AddPayload,
    code: string,
  }
}