import React, { ChangeEvent, Component } from 'react';

interface Props {
  shirt: string,
  skinTone: string,
  hairColor: string,
  hairStyle: string,
  beardColor: string,
  beardStyle: string,
  mouthStyle: string,
  eyewear: string,
  eyesColor: string,
  bgColor: string,
  checked: boolean,
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void,
}

class AvatarOption extends Component<Props> {
  render() {
    return (
      <div className="avatar-tile">
        <input
          type="radio"
          name="shirt"
          id={this.props.shirt}
          value={this.props.shirt}
          checked={this.props.checked}
          onChange={this.props.onChange}
        />
        <label
          className="avatar-stack"
          htmlFor={this.props.shirt}
        >
          <img
            src={`${process.env.REACT_APP_ASSETS}/avatars/skin/round-${this.props.skinTone}.png`}
            alt="face"
            className="avatar-face"
            style={{background: this.props.bgColor}}
          />
          <img
            src={`${process.env.REACT_APP_ASSETS}/avatars/${this.props.shirt}`}
            alt="shirt"
            className="avatar-shirt"
          />
          {this.props.hairStyle !== '' && (
            <img
              src={`${process.env.REACT_APP_ASSETS}/avatars/hair/${this.props.hairStyle}-${this.props.hairColor}.png`}
              alt="hair"
              className="avatar-hair"
            />
          )}
          {this.props.beardStyle !== '' && (
            <img
            src={`${process.env.REACT_APP_ASSETS}/avatars/beard/${this.props.beardStyle}-${this.props.beardColor}.png`}
            alt="beard"
            className="avatar-beard"
          />
          )}
          <img
            src={`${process.env.REACT_APP_ASSETS}/avatars/mouth/${this.props.mouthStyle}.png`}
            alt="mouth"
            className="avatar-mouth"
          />
          <img
            src={`${process.env.REACT_APP_ASSETS}/avatars/eyes/${this.props.eyesColor}.png`}
            alt="eyes"
            className="avatar-eyes"
          />
          <img
            src={`${process.env.REACT_APP_ASSETS}/avatars/eyewear/${this.props.eyewear}.png`}
            alt="eyewear"
            className="avatar-eyewear"
          />
          <div className="selection-helper"></div>
        </label>
      </div>
    )
  }
}

export default AvatarOption;
