import Fetch from "../../helpers/Fetch";
import ProviderDTO from "../dtos/Provider";

interface AddPayload {
  name: string,
  iconUrl: string,
  description: string,
  tileColor: string,
  lastUpdate: string,
  tenantId?: number | null,
  deleted: boolean,
}

export async function listProviders(): Promise<ProviderDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProviderDTO[]>(`/providers`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch providers');
}

export async function listUserProviders(tenantId?: number): Promise<ProviderDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProviderDTO[]>(`/providers/tenant/${tenantId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch logged tenant providers');
}

export async function listProviderDetails(itemId?: string): Promise<ProviderDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProviderDTO>(`/providers/${itemId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch provider details');
}


export async function addProvider(form: AddPayload): Promise<ProviderDTO | ProviderError> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProviderDTO>(`/providers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success' || status === 'error') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update provider`);
}

export async function updateProvider(form: AddPayload, itemId: string): Promise<ProviderDTO | ProviderError> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProviderDTO>(`/providers/${itemId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success' || status === 'error') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update provider`);
}

export type ProviderError = {
  error: {
    field: keyof AddPayload,
    code: string,
  }
}