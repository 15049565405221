import React, { ChangeEvent } from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ProcedureDTO from "../../../../common/api/dtos/Procedure";
import { listAssignedProcedures } from "../../../../common/api/endpoints/users";
import AppContext from "../../../../common/context/AppContext";
import { getFilteredItems } from "../../../../common/helpers/Filter";
import { loadComplete } from "../../../../common/helpers/LoadComplete";
import { ChangeSortBy } from "../../../../common/helpers/Sorting";
import TableSort from "../../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../../common/types/RequestStatus";
import TextControl from "../../../controls/TextControl/TextControl";
import ToggleControl from "../../../controls/ToggleControl/ToggleControl";
import GhostButtonControl from "../../../controls/GhostButtonControl/GhostButtonControl";
import BreadcrumbControls from "../../../generics/Header/BreadcrumbControls";
import ToolbarControls from "../../../generics/Header/ToolbarControls";
import { incrementProgress, IProgress } from "../../../utils/ProgressBar/ProgressBar";
import RequestStatus from "../../../utils/RequestStatus/RequestStatus";
import SortButton from "../../../utils/SortButton/SortButton";
import { withTransitionEvent } from "../../../TransitionEvent";

export interface Props {

}

interface State {
  progress: IProgress,
  status: TRequestStatus,
  procedures?: ProcedureDTO[],
  tableSort: TableSort,
  filterValue: string,
}

class ProceduresBoard extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'category',
    'tags',
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: 'loading',
      tableSort: new TableSort('name', 'asc'),
      filterValue: '',
    }
  }

  fetchAssignedProcedures = async () => {
    this.setState({
      status: 'loading'
    });
    
    const assignedProcedures = await listAssignedProcedures(this.context.loggedUser.id);

    this.setState(prevState => {
      return {
        status: 'success',
        procedures: assignedProcedures.length ? assignedProcedures : [],
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  componentDidMount() {
    this.fetchAssignedProcedures();
  }

  renderProcedure(procedure: ProcedureDTO) {
    return (
      <React.Fragment>
        <div className="column stretch">
          <div className="card wrap align-top">
            <div>
              <h2 className="primary-title section-title uppercase">
                {procedure.name}
              </h2>
              <hr />
              <p className="text-chunk faint-text"><small>{procedure.description}</small></p>
              <hr />
              <div className="faint-text text-chunk"><small>{procedure.content}</small></div>
              <hr />
              <div className="faint-text text-chunk"><small>{procedure.category}</small></div>
              <hr />
              <div className="faint-text text-chunk"><small>{procedure.tags}</small></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
  
  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
      };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  
  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th>
          <SortButton
            column="name"
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
            ></SortButton>
        </th>
        <th>
          <SortButton
            column="description"
            text="Description"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
            ></SortButton>
        </th>
        <th>
          <SortButton
            column="category"
            text="Category"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
            ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }


  renderTBodyRow(procedure: ProcedureDTO) {
    return (
      <tr id={`procedure${procedure.id}`} key={`${procedure.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <span>{procedure.name}</span>
            </div>
          </div>
        </td>
        <td>
          <p className="text-chunk">{procedure.description}</p>
        </td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <span>{procedure.category}</span>
            </div>
          </div>
        </td>
        <td className="text-right">
          <GhostButtonControl
            to={`/assigned-procedures/view/${procedure.id}`}
            class="ghost-button"
            icon="fas fa-angle-right"
          />
        </td>
      </tr>
    );
  }
  
  render() {
    const filteredProcedures: Array<ProcedureDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.procedures ? this.state.procedures : []).sort(this.state.tableSort.sortByColumn).filter((procedure: ProcedureDTO) => procedure.deleted === false);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Procedures"
          status={this.state.status}
        />
        {this.state.procedures &&
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="FilterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
            </ToolbarControls>
            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                      card
                      ${!loadComplete(this.state.progress) ? 'loader-border table-loading' : ''}`}
                  >
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.procedures &&
                        <React.Fragment>
                          <tbody>
                            {filteredProcedures.map(procedure => (
                              this.renderTBodyRow(procedure)
                              ))}
                              </tbody>
                              <tfoot>
                                {loadComplete(this.state.progress) && !this.state.procedures && 
                                  <tr>
                                    <th colSpan={8}>
                                      <div className="card">
                                        <p className="text-chunk">There are no procedures assigned, check with a Workstrym Admin or Manager for more info.</p>
                                      </div>
                                    </th>
                                  </tr>
                                }
                                {this.state.filterValue &&
                                  <tr>
                                    <th colSpan={8}>
                                      <div className="card">
                                        <p className="text-chunk">Showing {filteredProcedures.length} out of {this.state.procedures.filter((procedure: ProcedureDTO)=> procedure.deleted === false).length} results.</p>
                                      </div>
                                    </th>
                                  </tr>
                                }
                              </tfoot>
                            </React.Fragment>
                          }
                    </table>
                  </div>            
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  };
}

export default withTransitionEvent(ProceduresBoard);

ProceduresBoard.contextType = AppContext;