import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Thumbnail  from '../../utils/Thumbnail/Thumbnail';

export interface Props {
  theme: string,
}

class NotFoundPage extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  
  render(){
    return (
      <div className="flex-row will-transition">
        <div className="column">
          <div className="card-list-component">
            <NavLink to="/" className="card-button">
              <Thumbnail
                thumbnailURL={`/assets/characters/ralph_wondering_${this.props.theme}.png`}
                classes="medium radius"
                thumbnailBackground='transparent'
                />
              <div className="text-container">
                I'm lost... Take me home!
                <br />
                <small className='faint-text'>Error 404 - Resource not found</small>
              </div>
              <span className="fas fa-angle-right"></span>
            </NavLink>
          </div>
        </div>
      </div>
    )
  }
}
export default NotFoundPage;
