import Fetch from "../../helpers/Fetch";
import SecucodeDTO from "../dtos/Secucode";

interface AddPayload {
  name: string,
  internalId?: string,
  iconUrl: string,
  description: string,
  tileColor: string,
  lastUpdate: string,
  userId?: number | null,
  deleted: boolean,
}

export async function listSecucodes(): Promise<SecucodeDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SecucodeDTO[]>(`/secucodes`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch secucodes');
}

export async function listUserSecucodes(userId?: number): Promise<SecucodeDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SecucodeDTO[]>(`/secucodes/user/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch logged user secucodes');
}

export async function listSecucodeDetails(itemId?: string): Promise<SecucodeDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SecucodeDTO>(`/secucodes/${itemId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch secucode details');
}

export async function addSecucode(form: AddPayload): Promise<SecucodeDTO | SecucodeError> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SecucodeDTO>(`/secucodes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success' || status === 'error') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update secucode`);
}

export async function updateSecucode(form: AddPayload, itemId: string): Promise<SecucodeDTO | SecucodeError> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SecucodeDTO>(`/secucodes/${itemId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success' || status === 'error') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update secucode`);
}

export type SecucodeError = {
  error: {
    field: keyof AddPayload,
    code: string,
  }
}