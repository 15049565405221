import { Component, Context } from 'react';
import AppContext, { IAppContext } from '../../../common/context/AppContext';
import GhostButtonControl from '../../controls/GhostButtonControl/GhostButtonControl';

interface Props { 
  pageName: string,
  loggedUserId: number,
  children: JSX.Element[] | JSX.Element,
}

interface State {
  hideFootnote: boolean,
  hidFootnoteAt: number,
}

class AlertBanner extends Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props, context: IAppContext) {
    super(props);
    this.state = {
      ...this.getLocalStorageData(),
    }
  }

  private getLocalStorageData() {
    let hideFootnoteString = this.props.loggedUserId + "hide" + this.props.pageName + "Footnote";
    let hidFootnoteAtString = this.props.loggedUserId + "hid" + this.props.pageName + "Footnote";
    let hideFootnote = JSON.parse(localStorage.getItem(hideFootnoteString) || 'false');
    let hidFootnoteAt = JSON.parse(localStorage.getItem(hidFootnoteAtString) || 'false');
    let validFor = 7 * 24 * 60 * 60 * 1000; // 7 days
    let validUntil = hidFootnoteAt + validFor;
    let currentStamp = (new Date()).getTime();

    //expired
    if(currentStamp > validUntil) {
      hideFootnote = false;
      localStorage.removeItem(hideFootnoteString);
      localStorage.removeItem(hidFootnoteAtString);
    }

    return {
      hideFootnote: hideFootnote,
      hidFootnoteAt: hidFootnoteAt,
    }
  }

  private dismissFootnote = () => {
    let hideFootnoteString = this.props.loggedUserId + "hide" + this.props.pageName + "Footnote";
    let hidFootnoteAtString = this.props.loggedUserId + "hid" + this.props.pageName + "Footnote";
    let currentStamp = (new Date()).getTime();

    localStorage.setItem(hideFootnoteString, JSON.stringify(true));
    localStorage.setItem(hidFootnoteAtString, JSON.stringify(currentStamp));

    this.setState({
      hideFootnote: true,
      hidFootnoteAt: currentStamp,
    })
  }

  render() {
    if (this.state.hideFootnote) {
      return null;
    }

    return (
      <div className="card flashing-border alert-component">
        {this.props.children}
        <GhostButtonControl class="ghost-button v-center right" onClick={this.dismissFootnote}>
          <span className="fas fa-times"></span>
        </GhostButtonControl>
      </div>  
    )
  }
}

export default AlertBanner;