import Fetch from "../../helpers/Fetch";
import ProcedureDTO from "../dtos/Procedure";
import UserDTO from "../dtos/User";

interface AddPayload {
  name: string,
  description: string,
  content: string,
  category: string,
  tags: string,
  deleted: boolean,
}

export async function listProcedures(): Promise<ProcedureDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProcedureDTO[]>(`/procedures`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if(status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch procedures');
}

export async function listProcedureDetails(procedureId: number): Promise<ProcedureDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProcedureDTO>(`/procedures/${procedureId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch procedure details');
}

export async function listProceduresOverview(): Promise<ProcedureDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProcedureDTO[]>(`/procedures/overview`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if(status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch procedures overview');
}


export async function addProcedure(form: AddPayload): Promise<ProcedureDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProcedureDTO>(`/procedures`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add procedure`);
}

export async function updateProcedure(form: AddPayload, procedureId: number): Promise<ProcedureDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ProcedureDTO>(`/procedures/${procedureId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });


  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update client`);
}

export async function listAssignedUsers(procedureId: number): Promise<UserDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<UserDTO[]>(`/procedures/assigned-users/${procedureId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch procedure assigned users');
} 