import React, { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import PayprofileDTO from "../../../common/api/dtos/Payprofile";
import { listTenantPayprofiles } from "../../../common/api/endpoints/payprofiles";
import Fetch from "../../../common/helpers/Fetch";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import TextControl from "../../controls/TextControl/TextControl";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import GhostButtonControl from "../../controls/GhostButtonControl/GhostButtonControl";
import SortButton from "../../utils/SortButton/SortButton";
import ToolbarControls from "../../generics/Header/ToolbarControls";

interface Props {
  entityId: number,
}

interface State {
  userPropId: number | undefined | null,
  status: TRequestStatus,
  formStatus: TRequestStatus,
  payprofiles?: PayprofileDTO[],
  tableSort: TableSort,
	propsForm: PropForm
  filterValue: string,
  showDisabled: boolean
}

interface PropForm {
  employment: string,
  fullName: string,
  position: string,
  hourNorm: number,
  phone: string,
  userId: number,
  payprofiles: PayprofileDTO[],
}

class PayprofilesForm extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'iconURL',
    'invoiceSeries',
    'currency',
    'bankAccount'
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      formStatus: 'idle',
      propsForm: {
        employment: '',
        fullName: '',
        position: '',
        hourNorm: 8,
        phone: '',
        userId: this.props.entityId,
        payprofiles: [],
      },
      filterValue: '',
      showDisabled: localStorage.getItem('showDisabledPayprofiles') === 'false' || localStorage.getItem('showDisabledPayprofiles') === null ? false : true,
      userPropId: undefined,
      tableSort: new TableSort('name', 'asc'),
    }
  }

  fetchPayprofiles = async () => {
    this.setState({
      status: 'loading'
    });
    
    const payprofiles = await listTenantPayprofiles(this.props.entityId);

    this.setState({
        status: 'success',
        payprofiles: payprofiles,
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  componentDidMount() {
    this.fetchPayprofiles();
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({ showDisabled: false }) : this.setState({ showDisabled: true });
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });

    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/userprop/` + this.state.userPropId, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(this.state.propsForm)
    })
    
    await fetchRequest.fetch();

    this.setState({
      formStatus: fetchRequest.getStatus()
    });
  }

  setAssigned = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentProjectId: number = Number(ev.target.value);
    this.setState((prevState: State) => {
      let assignedPayprofiles = prevState.propsForm.payprofiles.slice(0);
      let alreadyAssigned = assignedPayprofiles.map((payprofile: PayprofileDTO) => { return payprofile.id; }).indexOf(currentProjectId);

      if(alreadyAssigned === -1) {
        let selectedProject = this.state.payprofiles?.filter((payprofile: PayprofileDTO) => payprofile.id === currentProjectId)[0];
        if(selectedProject) {
          assignedPayprofiles.push(selectedProject);
        }
      } else {
        assignedPayprofiles.splice(alreadyAssigned, 1);
      }
      
      return {
        propsForm: {
          ...prevState.propsForm,
          payprofiles: assignedPayprofiles
        }
      }
    });
    
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  render() {
    const filteredPayprofiles: Array<PayprofileDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.payprofiles ? this.state.payprofiles : []).sort(this.state.tableSort.sortByColumn).filter((payprofile: PayprofileDTO) => payprofile.enabled === true || this.state.showDisabled);
    return (
      <div>
        <ToolbarControls>
          <TextControl
            label="Filter"
            type="text"
            name="filterBox"
            id="filterBox"
            onChange={this.setFilterValue}
            placeholder="Filter"
            srOnly={true}
          />
          <ToggleControl 
            id="showDisabledPayprofiles"
            name="showDisabledPayprofiles" 
            changeMethod={this.toggleDisabled} 
            isChecked={this.state.showDisabled}
            labelText="Disabled"
            />
          <div className="form-group">
            <Link to={`/tenant/deep/${this.props.entityId}/payprofile/add`} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add payprofile</span></Link>
          </div>
        </ToolbarControls>
        <h1 className="primary-title">Payprofiles</h1>
        <div className={`card ${!this.state.payprofiles ? 'loader-border' : ''}`}>
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 315px)'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <SortButton
                            column='name' 
                            text='Name'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th>
                          <SortButton 
                            column='description' 
                            text='Description'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th>
                          <SortButton 
                            column='invoiceSeries' 
                            text='Invoice Series'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th>
                          <SortButton 
                            column='currency' 
                            text='Currency'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th>
                          <SortButton 
                            column='bankAccount' 
                            text='Bank Account'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        {this.state.showDisabled &&
                          <th className="text-center">
                            <SortButton 
                              column='enabled' 
                              text='Enabled'
                              tableSort={this.state.tableSort}
                              onClick={this.handleSortChange}
                              ></SortButton>
                          </th>
                        }
                        <th></th>
                      </tr>
                    </thead>
                    {this.state.payprofiles &&
                      <React.Fragment>
                        <tbody>
                          {filteredPayprofiles.map(payprofile => (
                            <React.Fragment key={`${payprofile.id}`}>
                              <tr id={`payprofile${payprofile.id}`}>
                                <td></td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center">
                                      <span>{payprofile.name}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="text-chunk">{payprofile.description}</div>
                                </td>
                                <td>
                                  <span>{payprofile.invoiceSeries}</span>
                                </td>
                                <td>
                                  <span>{payprofile.currency}</span>
                                </td>
                                <td>
                                  <span>{payprofile.bankAccount}</span>
                                </td>
                                {this.state.showDisabled && 
                                  <td className="text-center">
                                    {payprofile.enabled && 
                                      <span className="fas fa-check"></span>
                                    }
                                  </td>
                                }
                                <td className="text-right">
                                  <GhostButtonControl to={`/tenant/deep/${this.props.entityId}/payprofile/edit/${payprofile.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.payprofiles?.length === 0 &&
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  <p className="text-chunk">There are no payprofiles defined for this tenant. Start by <Link className="link-button" to={`/tenant/deep/${this.props.entityId}/payprofile/add`}>adding</Link> the first one.</p>
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  <p className="text-chunk">Showing {filteredPayprofiles.length} out of {this.state.payprofiles.filter((payprofile: PayprofileDTO) => payprofile.enabled === true || this.state.showDisabled).length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PayprofilesForm;