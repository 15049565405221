import { override } from "joi";
import Fetch from "../../helpers/Fetch";
import EventDTO, { EventPayload } from "../dtos/Event";

export async function listEvents(): Promise<EventDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<EventDTO[]>(`/events`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch events');
}

export async function listEventDetails(eventId: string): Promise<EventDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<EventDTO>(`/events/${eventId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch event details');
}

export async function addEvent(form: EventPayload): Promise<EventDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<EventDTO>(`/events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      ...form,
      roles: form.roles.map(o => { return {id: parseInt(o)}})
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add event`);
}

export async function updateEvent(form: EventPayload, eventId: string): Promise<EventDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<EventDTO>(`/events/${eventId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      ...form,
      roles: form.roles.map(o => { return {id: parseInt(o)}})
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update event`);
}