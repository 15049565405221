import TenantDTO from "../../../../common/api/dtos/Tenant";
import currencyFormatter from "../../../../common/helpers/CurrencyFormatter";
import { Currency } from "../types";

interface Props {
  translations: Record<string, string>,
  currencySymbol?: string,
  amount: number,
  language: string,
  hasVat: boolean,
  tenant?: TenantDTO,
}

export function LogoSection({ translations, currencySymbol, amount, language, hasVat, tenant }: Props) {
  return <div className="fill">
    <div style={{
      textAlign: "right"
    }}
    >
      <img className="invoice-logo" src={tenant ? tenant.logoURL : ''} style={{ height: '72px', display: 'inline-block' }}/>
    </div>
    <div className="tableview-component">
      <table>
        <thead>
          <tr>
            <th>
              <span>{translations?.summary}</span>
            </th>
            <th className="text-right">
              <span></span>
            </th>
          </tr>
          <tr>
            <th>
              {language === 'en-ro' ? (
                <span>{translations?.totalDueA} {hasVat ? translations?.vatSuffixA : ""}<br />
                <small>{translations?.totalDueB} {hasVat ? translations?.vatSuffixB : ""}</small></span>
              ): (
                <span>{translations?.totalDueA} {hasVat ? translations?.vatSuffixA : ""}</span>
              )}
            </th>
            <th className="text-right" style={{ fontSize: "1.5rem"}}>
              <span>{currencyFormatter(amount, currencySymbol)}</span>
            </th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
}