import moment from "moment";
import React, { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import CompensationDTO from "../../../common/api/dtos/Compensation";
import { listUserCompensations } from "../../../common/api/endpoints/compensations";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import GhostButtonControl from "../../controls/GhostButtonControl/GhostButtonControl";
import ToolbarControls from "../../generics/Header/ToolbarControls";
import DateTCell from "../../utils/DateTCell/DateTCell";
import TextControl from "../../controls/TextControl/TextControl";
import SortButton from "../../utils/SortButton/SortButton";
import { IProgress } from "../../utils/ProgressBar/ProgressBar";
import { withTransitionEvent } from "../../TransitionEvent";

interface Props {
  entityId: number;
  entity: "user";
  hasFullAccess?: boolean;
}

interface State {
  status: TRequestStatus;
  formStatus: TRequestStatus;
  progress: IProgress;
  compensations?: CompensationDTO[];
  showDeleted: boolean;
  tableSort: TableSort;
  propsForm: PropForm;
  filterValue: string;
  showSensitive: boolean;
}

interface PropForm {
  employment: string;
  fullName: string;
  position: string;
  hourNorm: number;
  phone: string;
  userId: number;
  compensations: CompensationDTO[];
}

class CompensationsForm extends Component<Props, State> {
  availableFilters: string[] = [
    "startDate",
    "endDate",
    "user.name",
    "description",
    "contract.name",
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: "idle",
      formStatus: "idle",
      propsForm: {
        employment: "",
        fullName: "",
        position: "",
        hourNorm: 8,
        phone: "",
        userId: this.props.entityId,
        compensations: [],
      },
      tableSort: new TableSort("name", "asc"),
      showSensitive: false,
      showDeleted:
        localStorage.getItem("showDeleted") === "false" ||
        localStorage.getItem("showDeleted") === null
          ? false
          : true,
      filterValue: "",
    };
  }

  sensitiveTimeout: number = 0;

  toggleSensitive = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.showSensitive
      ? this.setState({ showSensitive: false })
      : this.setState({ showSensitive: true });
  };

  fetchEntityCompensations = async () => {
    switch (this.props.entity) {
      case "user":
        return await listUserCompensations(this.props.entityId);
    }
  };

  fetchCompensations = async () => {
    this.setState({
      status: "loading",
    });

    const compensations = await this.fetchEntityCompensations();

    this.setState({
      status: "success",
      compensations: compensations,
    });
  };

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(
          column,
          prevState.tableSort.sortBy,
          prevState.tableSort.sortDirection
        ),
      };
    });
  };

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(
      event.target.name,
      JSON.stringify(event.target.checked)
    );
    this.state.showDeleted
      ? this.setState({ showDeleted: false })
      : this.setState({ showDeleted: true });
  };

  componentDidMount() {
    this.fetchCompensations();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.showSensitive !== prevState.showSensitive) {
      if (this.state.showSensitive) {
        this.sensitiveTimeout = setTimeout(() => {
          this.setState({ showSensitive: false });
        }, parseInt(String(process.env.REACT_APP_SENSITIVE_DATA_TIMEOUT))) as unknown as number;
      } else {
        clearTimeout(this.sensitiveTimeout);
      }
    }
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    });
  };

  setAssigned = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentProjectId: number = Number(ev.target.value);
    this.setState((prevState: State) => {
      let assignedCompensations = prevState.propsForm.compensations.slice(0);
      let alreadyAssigned = assignedCompensations
        .map((compensation: CompensationDTO) => {
          return compensation.id;
        })
        .indexOf(currentProjectId);

      if (alreadyAssigned === -1) {
        let selectedProject = this.state.compensations?.filter(
          (compensation: CompensationDTO) =>
            compensation.id === currentProjectId
        )[0];
        if (selectedProject) {
          assignedCompensations.push(selectedProject);
        }
      } else {
        assignedCompensations.splice(alreadyAssigned, 1);
      }

      return {
        propsForm: {
          ...prevState.propsForm,
          compensations: assignedCompensations,
        },
      };
    });
  };

  renderTHeadRow() {
    return (
      <tr>
        <th>#</th>
        <th>
          <SortButton
            column="user.name"
            text="Signatory"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column="contract.name"
            text="Issuer"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column="description"
            text="Description"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column="requestedOn"
            text="Requested On"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton
            column="honoredOn"
            text="Honored On"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton
            column="year"
            text="For"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton
            column="amount"
            text="Amount"
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(compensation: CompensationDTO) {
    return (
      <tr id={`compensation${compensation.id}`} key={`${compensation.id}`}>
        <td></td>
        <td>
          <span>{compensation.user.name}</span>
          <br />
          <small
            className={
              !this.state.showSensitive ? "blurred-item" : "blurred-item deblur"
            }
          >
            {compensation.user.fullName}
          </small>
        </td>
        <td>
          <span>{compensation.contract.name}</span>
          <br />
          <small
            className={
              !this.state.showSensitive ? "blurred-item" : "blurred-item deblur"
            }
          >
            {compensation.contract.tenant.legalName}
          </small>
        </td>
        <td>
          <div
            className={
              !this.state.showSensitive ? "blurred-item" : "blurred-item deblur"
            }
          >
            {compensation.description}
          </div>
        </td>
        <DateTCell date={compensation.requestedOn} />
        <DateTCell date={compensation.honoredOn} fallbackText="pending" />
        <td className="text-center">{compensation.year}</td>
        <td className="text-center">
          {compensation.amount} day{compensation.amount != 1 && "s"}
        </td>
        <td className="text-right">
          {this.props.hasFullAccess && (
            <GhostButtonControl
              to={`/${this.props.entity}/deep/${this.props.entityId}/compensation/edit/${compensation.id}`}
              class="ghost-button"
              icon="fas fa-angle-right"
            ></GhostButtonControl>
          )}
        </td>
      </tr>
    );
  }

  render() {
    const filteredCompensations: Array<CompensationDTO> = getFilteredItems(
      this.state.filterValue,
      this.availableFilters,
      this.state.compensations ? this.state.compensations : []
    )
      .sort(this.state.tableSort.sortByColumn)
      .filter(
        (compensation: CompensationDTO) =>
          compensation.deleted === this.state.showDeleted
      );
    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
            {this.props.hasFullAccess && (
              <>
                <ToggleControl
                  id="showSensitive"
                  name="showSensitive"
                  changeMethod={this.toggleSensitive}
                  isChecked={this.state.showSensitive}
                  labelText="Sensitive data"
                  icon="fas fa-shield-alt"
                  srOnly={true}
                  title="Sensitive data"
                />
                <ToggleControl
                  id="showDeleted"
                  name="showDeleted"
                  changeMethod={this.toggleDeleted}
                  isChecked={this.state.showDeleted}
                  labelText="Recycle bin"
                  icon="fas fa-trash-undo"
                  srOnly={true}
                  title="Recyle bin"
                />
                <div className="form-group">
                  <Link
                    to={`/${this.props.entity}/deep/${this.props.entityId}/compensation/add`}
                    className="primary-button"
                  >
                    <span className="static-icon">
                      <span className="fas fa-plus-circle"></span>
                    </span>{" "}
                    <span className="text">Add compensation</span>
                  </Link>
                </div>
              </>
            )}
          </ToolbarControls>
        </div>
        <div
          className={`card ${
            !this.state.compensations ? "loader-border" : ""
          } ${this.state.showDeleted ? "flashing-border" : ""}`}
        >
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div
                  className="inner-v-scroll"
                  style={{ maxHeight: "calc(100vh - 315px)" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <SortButton
                            column="user.name"
                            text="Signatory"
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th>
                          <SortButton
                            column="contract.name"
                            text="Issuer"
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th>
                          <SortButton
                            column="description"
                            text="Description"
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th>
                          <SortButton
                            column="requestedOn"
                            text="Requested on"
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th>
                          <SortButton
                            column="honoredOn"
                            text="Honored on"
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th className="text-center">
                          <SortButton
                            column="year"
                            text="For year"
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th className="text-center">
                          <SortButton
                            column="amount"
                            text="Amount of days"
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    {this.state.compensations && (
                      <React.Fragment>
                        <tbody>
                          {filteredCompensations.map((compensation) => (
                            <React.Fragment key={`${compensation.id}`}>
                              <tr id={`compensation${compensation.id}`}>
                                <td></td>
                                <td>
                                  <span>{compensation.user.name}</span>
                                  <br />
                                  <small className="faint-text">
                                    {compensation.user.fullName}
                                  </small>
                                </td>
                                <td>
                                  <span>{compensation.contract.name}</span>
                                  <br />
                                  <small className="faint-text">
                                    {compensation.contract.tenant.legalName}
                                  </small>
                                </td>
                                <td>
                                  <div className="text-chunk">
                                    {compensation.description}
                                  </div>
                                </td>
                                <DateTCell date={compensation.requestedOn} />
                                <DateTCell
                                  date={compensation.honoredOn}
                                  fallbackText="pending"
                                />
                                <td className="text-center">
                                  {compensation.year}
                                </td>
                                <td className="text-center">
                                  {compensation.amount} day
                                  {compensation.amount != 1 && "s"}
                                </td>
                                <td className="text-right">
                                  {this.props.hasFullAccess && (
                                    <GhostButtonControl
                                      to={`/${this.props.entity}/deep/${this.props.entityId}/compensation/edit/${compensation.id}`}
                                      class="ghost-button"
                                      icon="fas fa-angle-right"
                                    ></GhostButtonControl>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.compensations?.length === 0 && (
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  {this.props.hasFullAccess ? (
                                    <p className="text-chunk">
                                      There are no compensations defined for
                                      this {this.props.entity}. Start by{" "}
                                      <Link
                                        className="link-button"
                                        to={`/${this.props.entity}/deep/${this.props.entityId}/compensation/add`}
                                      >
                                        adding
                                      </Link>{" "}
                                      the first one.
                                    </p>
                                  ) : (
                                    <p className="text-chunk">
                                      There are no compensations.
                                    </p>
                                  )}
                                </div>
                              </th>
                            </tr>
                          )}
                          {this.state.filterValue && (
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  <p className="text-chunk">
                                    Showing {filteredCompensations.length} out
                                    of{" "}
                                    {
                                      this.state.compensations.filter(
                                        (compensation: CompensationDTO) =>
                                          compensation.deleted ===
                                          this.state.showDeleted
                                      ).length
                                    }{" "}
                                    results.
                                  </p>
                                </div>
                              </th>
                            </tr>
                          )}
                        </tfoot>
                      </React.Fragment>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTransitionEvent(CompensationsForm);
