import React, { ChangeEvent, Component } from 'react';
import UserDTO from '../../../common/api/dtos/User';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import RequestStatus from '../../utils/RequestStatus/RequestStatus';
import { listUsers } from '../../../common/api/endpoints/users';
import AppContext from '../../../common/context/AppContext';
import { listGroupsOverview } from '../../../common/api/endpoints/groups';
import GroupDTO from '../../../common/api/dtos/Group';
import Thumbnail from '../../utils/Thumbnail/Thumbnail';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import { IProgress, incrementProgress } from '../../utils/ProgressBar/ProgressBar';
import GhostButtonControl from '../../controls/GhostButtonControl/GhostButtonControl';
import { getFilteredItems } from '../../../common/helpers/Filter';
import ToolbarControls from '../../generics/Header/ToolbarControls';
import TextControl from '../../controls/TextControl/TextControl';
import SortButton from '../../utils/SortButton/SortButton';
import { ChangeSortBy } from '../../../common/helpers/Sorting';
import TableSort from '../../../common/helpers/TableSort';
import ToggleControl from '../../controls/ToggleControl/ToggleControl';
import { Link } from 'react-router-dom';
import { loadComplete } from '../../../common/helpers/LoadComplete';
import { withTransitionEvent } from '../../TransitionEvent';

export interface Props {

}

interface State {
  users: UserDTO[],
  progress: IProgress,
  status: TRequestStatus,
  groups?: GroupDTO[],
  tableSort: TableSort,
  filterValue: string,
}

interface Group {
  id: number,
  imageURL: string,
  name: string,
  members: Array<{user: UserDTO, description: string}>,
}

class GroupsBoard extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      users: [],
      progress: {
        currentStep: 0,
        totalSteps: 2,
      },
      status: 'loading',
      tableSort: new TableSort('name', 'asc'),
      filterValue: '',
    }
  }

  fetchUsers = async () => {
    this.setState({
      status: 'loading'
    });
    
    const users = await listUsers();

    this.setState(prevState => {
      return {
        status: 'success',
        users: users,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  fetchGroups = async () => {
    this.setState({
      status: 'loading'
    });
    
    const groups = await listGroupsOverview();

    this.setState(prevState => {
      return {
        status: 'success',
        groups: groups,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  evalURLAccess(user: UserDTO) {
    const userRole = this.context.loggedUser?.role.name;
    if(userRole === 'Admin' || userRole === 'Manager') {
      return `/user/edit/${user.id}`;
    } else {
      return null;
    }
  }

  filterUsers(user: UserDTO) {
    return user.invisible === false || this.context.loggedUser?.role.name !== 'Admin' && this.context.loggedUser?.role.name !== 'Manager';
  }

  componentDidMount() {
    this.fetchUsers();
    this.fetchGroups();
  }

  renderGroup(group: GroupDTO) {
    return (
      <React.Fragment>
        <div className="card">
          <img
            className="text-icon"
            src={group.iconURL}
            alt="icon"
          />
          <h2 className="primary-title section-title uppercase">
            {group.name + `(${group.users.length})`}
          </h2>
          <hr />
          <p className="text-chunk faint-text"><small>{group.description}</small></p>
          <div className="flex-row wrap">
            {group.users.map((user: UserDTO) => (
              <div className="smallest column text-center">
                <Thumbnail
                  avatarData={user.avatar}
                  classes="small"
                />
                <br />
                <small>{user.name}</small>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }

handleSortChange = (column: string) => {
  this.setState((prevState: State) => {
    return {
      tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
    };
  });
}

setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    filterValue: ev.target.value,
  })
}

renderTHeadRow() {
  return (
    <tr>
      <th>#</th>
      <th>
        <SortButton
          column="name"
          text='Name'
          tableSort={this.state.tableSort}
          onClick={this.handleSortChange}
          ></SortButton>
      </th>
      <th colSpan={2}>
        <SortButton
          column="users.length"
          text="Members"
          tableSort={this.state.tableSort}
          onClick={this.handleSortChange}
          ></SortButton>
      </th>
    </tr>
  );
}

renderTBodyRow(group: GroupDTO) {
  return (
    <tr id={`group${group.id}`} key={`${group.id}`}>
      <td></td>
      <td>
        <div className="flex-row fill">
          <div className="column flex-v-center">
            <Thumbnail
              thumbnailURL={group.iconURL}
              classes="thumbnail padded"
            />
            <div>
              <span>{group.name}</span>
              <hr />
              <p className="text-chunk">{group.description}</p>
            </div>
          </div>
        </div>
      </td>
      <td>
        {group.users.length}
      </td>
      <td>
        <div className="flex-row squeeze">
          {group.users.sort().map((user: UserDTO) => (
            <div className="smallest column" key={user.id}>
              <Thumbnail
                avatarData={user.avatar}
                classes="small"
                />
              <br />
              <small className={user.id == this.context.loggedUser.id ? 'flag-text' : ''}>{user.id == this.context.loggedUser.id ? "You too" : user.name}</small>
            </div>
          ))}
        </div>
      </td>
    </tr>
  );
}

render() {
  const filteredGroups: Array<GroupDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.groups ? this.state.groups : []).sort(this.state.tableSort.sortByColumn);
    return (
      <>
        <BreadcrumbControls
          pageTitle="Groups"
          status={this.state.status}
        />
        {this.state.groups &&
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="FilterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
            </ToolbarControls>
            
            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                      card
                      ${!loadComplete(this.state.progress) ? 'loader-border table-loading' : ''}`}
                  >
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.groups &&
                        <React.Fragment>
                          <tbody>
                            {filteredGroups.map(group => (
                              this.renderTBodyRow(group)
                              ))}
                          </tbody>
                          <tfoot>
                            {loadComplete(this.state.progress) && !this.state.groups.length &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">You are not part of any group. Reach out to a Workstrym Admin or Manager for more details</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredGroups.length} out of {this.state.groups.length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default withTransitionEvent(GroupsBoard);
GroupsBoard.contextType = AppContext;