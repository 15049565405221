import React, { ChangeEvent, Component, FormEvent } from 'react';
import AppContext from '../../../common/context/AppContext';
import Fetch from '../../../common/helpers/Fetch';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import TextControl from '../../controls/TextControl/TextControl';
import RequestStatus from '../../utils/RequestStatus/RequestStatus';
import Joi from 'joi';
import { processJoiError } from '../../../common/helpers/processJoiError';
import ButtonControl from '../../controls/ButtonControl/ButtonControl';
import { FormErrors } from '../../../common/data/FormErrors';

interface FormData {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
}

interface RequestPayload {
    statusCode: number,
    message: string,
}
  
interface State {
    formData: FormData,
    formStatus: TRequestStatus,
    invalidPasswordMatch: boolean,
    formPayload: RequestPayload,
    formErrors: FormErrors,
}

interface Props {
    editUserId?: number,
    enforced?: boolean,
    callback?: () => void
}

type FormErrors = {
    [key in keyof FormData]?: string;
}

class PasswordForm extends Component<Props, State> {
    formSchema = Joi.object({
        currentPassword: Joi.string().allow(null, ''),
        newPassword: Joi.string().required().pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[/ !"#$%&'()*+,-.\\:;<=>?@[\]^_`{|}~])[A-Za-z\d/ !"#$%&'()*+,-.\\:;<=>?@[\]^_`{|}~]{8,}/).messages(FormErrors.string),
        confirmPassword: Joi.string().required().valid(Joi.ref('newPassword')).messages(FormErrors.string),
    })
    constructor(props: Props) {
        super(props);
        this.state = {
            formData: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
            invalidPasswordMatch: false,
            formStatus: 'idle',
            formPayload: {
                statusCode: -1,
                message: '',
            },
            formErrors: {},
        }
    }

    updateEntity = async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        this.setState({
            formStatus: 'loading'
        });
        
        let fetchBody,
            fetchPath;

        if(this.props.editUserId && !this.props.enforced) {
            fetchBody = {
                newPassword: this.state.formData.newPassword,
            }

            fetchPath=`/user/change-password/${this.props.editUserId}`;
        } else {
            fetchBody = {
                currentPassword: this.state.formData.currentPassword,
                newPassword: this.state.formData.newPassword,
            }

            fetchPath=`/user/change-password/${this.context.loggedUser?.id}`;
        }

        const token = localStorage.getItem('id_token');
        const fetchRequest = new Fetch(fetchPath, {
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(fetchBody),
        })
        
        await fetchRequest.fetch();

        const fetchStatus = fetchRequest.getStatus();
        
        this.setState({
            formStatus: fetchStatus,
            formPayload: {...fetchRequest.getPayload()},
        });

        if(fetchStatus === "success" && this.props.callback) {
            this.props.callback();
        }
    }

    handleSubmit = (ev: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ev.preventDefault();
        const isValid = this.validateForm();
    
        if (isValid) {
            this.updateEntity(ev);
        }
    }
    
    setInvalidPasswordMatch = () => {
        if(this.state.formData.newPassword === this.state.formData.confirmPassword) {
            this.setState({
                invalidPasswordMatch: false,
            })
        } else {
            this.setState({
                invalidPasswordMatch: true,
            })
        }
    };
    
    validateFormField = <K extends keyof FormData>(field: K) => {
        const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
        let errorMessage = "";
    
        if (result.error) {
          for (const error of result.error.details) {
            const key = error.path[0];
    
            // ignore errors for other fields; this approach is chosen over extracting a sub-schema
            // because we use a joi ref for the confirm password
            if (key === field) {
              errorMessage = error.message;
            }
          }
        }

        this.updateFormError(field, errorMessage);
      };
    

    validateForm = () => {
        // reset form errors
        this.setState({
          formErrors: {}
        });
    
        const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
        if (result.error) {
          const formErrors = processJoiError(result.error);
          this.setState({
            // Assume type based on formSchema and Joi's error
            formErrors: formErrors as FormErrors,
          });
    
          return false;
        }
    
        return true;
    };

    updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
        this.setState(prevState => {
          return {
            formErrors: {
              ...prevState.formErrors,
              [field]: value,
            }
          }
        }, () => {
            this.setInvalidPasswordMatch();
        })
    }

    updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
        const formData = this.state.formData;
        this.setState({
          formData: {
            ...formData,
            [field]: value
          }
        }, () => {
            this.setInvalidPasswordMatch();
        })
    
        this.setState({
          formStatus: 'idle',
        })
    }

    setCurrentPassword = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('currentPassword', ev.target.value);
    setNewPassword = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('newPassword', ev.target.value);
    setConfirmPassword = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('confirmPassword', ev.target.value);
      
    render() {
      return (
        <div>
          <form onSubmit={this.updateEntity} autoComplete="off">
            <div className="flex-row pb-medium fill">
              <div className="column">
                <div className={`card ${this.context.loggedUser === undefined ? 'loader-border' : ''}`}>
                  {this.props.enforced &&
                    <TextControl
                      label="Current Password"
                      type="password"
                      name="currentPassword"
                      id="currentPassword"
                      onChange={this.setCurrentPassword}
                      autoComplete="new-password"
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                      error={this.state.formErrors.currentPassword}
                    />
                  }
                  <TextControl
                    label="New Password"
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    onChange={this.setNewPassword}
                    autoComplete="new-password"
                    br={true}
                    onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    error={this.state.formErrors.newPassword}
                  />
                  <TextControl
                    label="Confirm New Password"
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    onChange={this.setConfirmPassword}
                    autoComplete="new-password"
                    br={true}
                    onBlur={ev => { this.validateFormField(ev.target.name as keyof FormData)}}
                    error={this.state.formErrors.confirmPassword}
                  />
                  {this.state.formStatus === 'error' && 
                    <p>{this.state.formPayload.message}</p>
                  }
                </div>
              </div>
            </div>
            <ButtonControl
              class="primary-button"
              onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                this.handleSubmit(ev)
              }}
            >
              <RequestStatus status={this.state.formStatus} />
              <span className="text">Save</span>
            </ButtonControl>
          </form>
        </div>
      )
    }
}

export default PasswordForm;

PasswordForm.contextType = AppContext;