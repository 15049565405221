import React, { Component } from 'react';

export interface Props {
  currentStep: number,
  totalSteps: number,
}

export interface IProgress {
  currentStep: number,
  totalSteps: number
}

export function newProgress(total: number): IProgress {
  return {
      currentStep: 0,
      totalSteps: total,
  }
}

export function restartProgress(progress: IProgress): IProgress {
  return {
      currentStep: 0,
      totalSteps: progress.totalSteps,
  }
}

export function incrementProgress(progress: IProgress): IProgress {
  return {
      currentStep: Math.min(1 + progress.currentStep, progress.totalSteps),
      totalSteps: progress.totalSteps,
  }
}

class ProgressBar extends Component<Props> {
  calculateProgress(): number {
    return this.props.currentStep / this.props.totalSteps * 100;
  }

  render() {
    return (
      <React.Fragment>
        {Boolean(this.props.totalSteps) &&
          <div
            className={`
              progress-bar
              ${this.calculateProgress() === 100 || this.props.totalSteps <= 1 ? 'hide-it' : ''}
            `}
          >
            <div
              className="progress"
              style={{width: this.calculateProgress() + '%'}}
            >
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default ProgressBar;
