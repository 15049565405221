import React, { Component, HtmlHTMLAttributes, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Thumbnail  from '../Thumbnail/Thumbnail';

interface Props {
  avatarClasses?: string,
  thumbnailURL?: string,
  thumbnailBackground?: string,
  avatarData?: string,
  renderButton?: React.ReactNode,
  internalURL?: string | null
  classes?: string,
  cardTitle?: string,
  children: JSX.Element,
}

class GenericCard extends Component<Props> {
  renderContent() {
    return (
      <React.Fragment>
        {this.props.thumbnailURL ?
          <Thumbnail
            thumbnailURL={this.props.thumbnailURL}
            thumbnailBackground={this.props.thumbnailBackground}
            classes={this.props.avatarClasses ? this.props.avatarClasses : 'medium padded'}
          />
          :
          <Thumbnail
            avatarData={this.props.avatarData}
            classes={this.props.avatarClasses ? this.props.avatarClasses : 'medium'}
          />
        }
        <div className="text-container">
          <p className="story">
            {this.props.cardTitle &&
              <>
                <span className="primary-title">{this.props.cardTitle}</span>
                <br />
              </>
            }
            {this.props.children}
          </p>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.props.internalURL != null ? 
          <Link to={this.props.internalURL}
            className={[
              "card-button",
              this.props.classes ? this.props.classes : ''
            ].join(' ')}
          >
            {this.renderContent()}
            <span className="fas fa-angle-right"></span>
          </Link>
        :
          <div
            className={[
              "card",
              this.props.classes ? this.props.classes : ''
            ].join(' ')}
          >
            <div className="content">
              {this.renderContent()}
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default GenericCard;