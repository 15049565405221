import { ChangeEvent, Component } from 'react';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import { formatHour } from '../../generics/Booking/bookingUtils';
import { ClockingDayEntry } from '../../pages/Clocking/Clocking';
import RequestStatus from '../RequestStatus/RequestStatus';

interface Props {
  clockingDay: ClockingDayEntry,
  isDisabled: boolean,
  isWeekend: boolean,
  isToday: boolean,
  holidayName?: string,
  onBlur: (dayNumber: number, timeFrom: string | null, timeTo: string | null, updatedInput: string) => Promise<void>,
}

interface State {
  timeFrom: string | null,
  timeTo: string | null
}

class ClockingDay extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      timeFrom: this.props.clockingDay.timeFrom,
      timeTo: this.props.clockingDay.timeTo
    }
  }

  triggerBlur = (field: string) => {
    const state = {...this.state};

    if (this.state[field as keyof State] !== null) {
      this.setState({
        ...state,
        [field]: formatHour(this.state[field as keyof State] as string),
      }, () => {
        if (this.props.clockingDay[field as keyof State] !== this.state[field as keyof State]) {
          this.props.onBlur(this.props.clockingDay.dayNo, this.state.timeFrom, this.state.timeTo, field);
        }
      })
    }
  }

  updateState = <K extends keyof State>(ev: ChangeEvent<HTMLInputElement>, field: K) => {
    let value: string = ev.target.value.replace(/[^\d:]/g, '');
    const state = this.state;

    this.setState({
      ...state, 
      [field]: value, 
    })
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.setState({
        timeFrom: this.props.clockingDay.timeFrom,
        timeTo: this.props.clockingDay.timeTo
      })
    }
  }

  render () {
    return (
      <div className="column">
        <table>
          <thead>
            
            <tr>
              <th
                className={[
                  "month-day",
                  this.props.clockingDay.dayType !== '' ? this.props.clockingDay.dayType : '',
                  this.props.isWeekend ? 'weekend': '',
                  this.props.isToday ? 'today' : '',
                ].join(" ")}
              >
                <span 
                  className={[
                    "inner-text",
                    this.props.holidayName || this.props.clockingDay.dayType ? "tooltip" : ""
                  ].join(" ")}
                >
                  {this.props.clockingDay.dayNo}
                  {(this.props.holidayName || this.props.clockingDay.dayType) && 
                    <span className="tooltip-content">{this.props.holidayName ? this.props.holidayName : this.props.clockingDay.dayType + " day"}</span>
                  }
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={[
                  this.props.clockingDay.dayType !== '' ? this.props.clockingDay.dayType : '',
                  this.props.isWeekend ? 'weekend': '',
                  this.props.isToday ? 'today' : '',
                ].join(" ")}
              >
                <div className="content">
                  <label>From</label>
                  <br />
                  <input
                    className="hour-box from"
                    type="text"
                    placeholder={!this.props.isDisabled && this.props.clockingDay.timeFrom === null ? '--:--' : ''}
                    value={this.state.timeFrom !== null ? this.state.timeFrom : ''}
                    disabled={this.props.isDisabled}
                    onChange={(ev) => this.updateState(ev, 'timeFrom')}
                    onBlur={(ev) => this.triggerBlur('timeFrom')}
                    maxLength={5}
                  />
                  {this.props.clockingDay.inputStatus.timeFrom != 'idle' &&
                    <div className={`
                        status
                        ${this.props.clockingDay.inputStatus.timeFrom}
                      `}
                    >
                      <RequestStatus status={this.props.clockingDay.inputStatus.timeFrom as TRequestStatus} />
                    </div>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td
                className={[
                  this.props.clockingDay.dayType !== '' ? this.props.clockingDay.dayType : '',
                  this.props.isWeekend ? 'weekend': '',
                  this.props.isToday ? 'today' : '',
                ].join(" ")}
              >
                <div className="content">
                  <label>To</label>
                  <br />
                  <input
                    className="hour-box to"
                    type="text"
                    placeholder={!this.props.isDisabled && this.props.clockingDay.timeFrom === null ? '--:--' : ''}
                    value={this.state.timeTo !== null ? this.state.timeTo : ''}
                    disabled={this.props.isDisabled}
                    onChange={(ev) => this.updateState(ev, 'timeTo')}
                    maxLength={5}
                    onBlur={() => {this.triggerBlur('timeTo')}}
                  />
                  {this.props.clockingDay.inputStatus.timeTo != 'idle' &&
                    <div className={`
                        status
                        ${this.props.clockingDay.inputStatus.timeTo}
                      `}
                    >
                      <RequestStatus status={this.props.clockingDay.inputStatus.timeTo as TRequestStatus} />
                    </div>
                  }
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th
                className={[
                  "month-day",
                  this.props.clockingDay.dayType !== '' ? this.props.clockingDay.dayType : '',
                  this.props.isWeekend ? 'weekend': '',
                  this.props.isToday ? 'today' : '',
                ].join(" ")}
              >
                <span className="inner-text">
                  {this.props.clockingDay.totalHours}
                </span>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }
}

export default ClockingDay;
