import Joi from 'joi';
import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import { RouteComponentProps } from "react-router-dom";
import WorkProfileDTO from '../../../../common/api/dtos/WorkProfile';
import TenantDTO from '../../../../common/api/dtos/Tenant';
import RoleDTO from '../../../../common/api/dtos/Role';
import { listContractDetails } from '../../../../common/api/endpoints/contracts';
import { FormErrors } from '../../../../common/data/FormErrors';
import Fetch from '../../../../common/helpers/Fetch';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { LoggedUser } from '../../../../common/interfaces/LoggedUser';
import { UserPropName } from '../../../../common/interfaces/UserPropName';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import TextControl from '../../../controls/TextControl/TextControl';
import TextAreaControl from '../../../controls/TextAreaControl/TextAreaControl';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../../TransitionEvent';
import ContractDTO from '../../../../common/api/dtos/Contract';
import moment from 'moment';
import UserDTO from '../../../../common/api/dtos/User';
import { listUserDetails } from '../../../../common/api/endpoints/users';
import { addWorkProfile, getWorkProfile, updateWorkProfile } from '../../../../common/api/endpoints/workProfiles';
import Notification from '../../../generics/Notification/Notification';
import { ErrorPayload } from '../../../../common/api/endpoints/errorPayload';

export interface Props extends RouteComponentProps<RouteParams> {
  loggedUser: LoggedUser,
}

interface RouteParams {
  id?: string,
  entity?: string,
  eid?: string,
  subentity?: string,
  seid?: string,
}

interface FormData {
  contractId: number,
  name: string,
  description: string,
  startDate: Date,
  hourNorm: number,
  hasTickets: boolean,
  clockIn: string,
  clockOut: string
  deleted: boolean,
}

interface State {
  tenantId?: number,
  workProfileId?: number,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  contracts?: ContractDTO[],
  users?: UserDTO[],
  userId: number | null,
  roles?: RoleDTO[],
  usersPropsNames?: UserPropName[],
  formData: FormData,
  formErrors: FormErrors,
  indefinite: boolean,
  serverErrors: ErrorPayload[],
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

class WorkProfileDetail extends Component<Props, State> {
  formSchema = Joi.object({
    deleted: Joi.boolean(),
    startDate: Joi.date().required().messages(FormErrors.string),
    description: Joi.string().trim(true).allow(null, ''),
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    clockIn: Joi.string().trim(true).required().messages(FormErrors.string),
    clockOut: Joi.string().trim(true).required().messages(FormErrors.string),
    hasTickets: Joi.boolean(),
    hourNorm: Joi.number().max(8).allow(null, '').messages(FormErrors.number),
    contractId: Joi.number().required().messages(FormErrors.string),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      workProfileId: Number(this.props.match.params.id),
      indefinite: true,
      contracts: [],
      users: [],
      userId: this.props.match.params.entity === 'user' ? Number(this.props.match.params.eid) : null,
      formData: {
        contractId: this.props.match.params.entity === 'user' ? Number(this.props.match.params.seid) : Number(this.props.match.params.eid),
        startDate: new Date(),
        hourNorm: 0,
        hasTickets: false,
        name: '',
        description: '',
        clockIn: '09:00',
        clockOut: '17:00',
        deleted: false,
      },
      progress: {
        currentStep: 0,
        totalSteps: 1
      },
      pageStatus: 'idle',
      formStatus: 'idle',
      formErrors: {},
      serverErrors: [],
    }
  }

  fetchWorkProfileDetails = async () => {
    const workProfile = await getWorkProfile(this.state.workProfileId!);

    this.setState((prevState: State) => {
      return {
        formData: {
          contractId: workProfile.contractId,
          name: workProfile.name,
          description: workProfile.description,
          startDate: workProfile.startDate,
          hourNorm: workProfile.hourNorm,
          hasTickets: workProfile.hasTickets,
          clockIn: workProfile.clockIn,
          clockOut: workProfile.clockOut,
          deleted: workProfile.deleted,
        },
        progress: incrementProgress(prevState.progress),
        pageStatus: 'success',
      }
    });
  }

  fetchUsers = async () => {
    if(this.state.userId == null) {
      return;
    }
    
    this.setState({
      pageStatus: 'loading'
    });
    
    const users: UserDTO[] = [];
    users.push(await listUserDetails(this.state.userId.toString()));

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        users,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  fetchContracts = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const contracts: ContractDTO[] = [];
    contracts.push(await listContractDetails(this.state.formData.contractId));

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        contracts,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  addEntity = async () => {
    this.setState({
      serverErrors: [],
      formStatus: 'loading'
    });

    try {
      await addWorkProfile(this.state.formData);
  
      let entityName = this.props.match.params.entity;
      let entityId = this.props.match.params.eid;
      let subentityName = this.props.match.params.subentity;
      let subentityId = this.props.match.params.seid;
  
      let returnPath = subentityId ? `/${entityName}/deep/${entityId}/${subentityName}/edit/${subentityId}/work-profile` : `/${entityName}/edit/${entityId}/work-profile`;
  
      this.setState({
          formStatus: 'success',
      }, () => {
        this.props.history.push({
          pathname: returnPath,
        })
      });
    } catch (serverErrors: any) {
      this.setState({
        serverErrors,
        formStatus: 'error'
      });
    }
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await updateWorkProfile(this.state.formData, this.state.workProfileId!);

    this.setState({
        formStatus: 'success',
    });
  }
  
  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
  
      if(!isEditPage(Number(this.state.workProfileId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    this.fetchUsers();
    this.fetchContracts();
    
    if(isEditPage(Number(this.state.workProfileId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 2,
          }
        }
      });
      this.fetchWorkProfileDetails();
    }
  } 

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      }
    });
  }

  evalBreadcrumbLabel(entity: string | undefined, id: number) {
    let parentContract = this.state.contracts?.find((contract: ContractDTO) => contract.id == id);
    let parentUser = this.state.users?.find((user: UserDTO) => user.id == id);

    switch(entity) {
      case 'user':
        return parentUser ? parentUser.name : "loading";
      case 'contract':
        return parentContract ? parentContract.name : "loading";
      default:
        return this.state.formData.startDate ? moment(this.state.formData.startDate).format("MMMM DD, YYYY") : 'loading'
    }
  }

  renderBreadcrumbs() {
    const entity = this.props.match.params.entity;
    const subentity = this.props.match.params.subentity;
    const entityId: number = Number(this.props.match.params.eid);
    const subentityId: number = Number(this.props.match.params.seid);
    let entityLabel = this.evalBreadcrumbLabel(entity, entityId);
    let subentityLabel = this.evalBreadcrumbLabel(subentity, subentityId);

    return(
      <BreadcrumbControls 
        items={
          !subentity ?
          [
            {label: `${entity}s`, path: `/${entity}`},
            {label: entityLabel, path: `/${entity}/edit/${entityId}`},
            {label: 'work profiles', path: `/${entity}/edit/${entityId}/work-profile`},
            {label: `${!isEditPage(Number(this.state.workProfileId)) ? 'adding work profile ' : 'editing work profile '}${this.state.formData.name}`}
          ]
          :
          [
            {label: `${entity}s`, path: `/${entity}`},
            {label: entityLabel, path: `/${entity}/edit/${entityId}`},
            {label: `${subentity}s`, path: `/${entity}/edit/${entityId}/${subentity}`},
            {label: subentityLabel, path: `/${entity}/deep/${entityId}/${subentity}/edit/${subentityId}`},
            {label: 'work profiles', path: `/${entity}/deep/${entityId}/${subentity}/edit/${subentityId}/work-profile`},
            {label: `${!isEditPage(Number(this.state.workProfileId)) ? 'adding work profile ' : 'editing work profile '}${this.state.formData.name}`}
          ]
        }
        status={this.state.pageStatus}
      />
    )
  }

  renderDetailsForm() {
    return(
      <div className="card">
        <TextControl
          label="Name"
          type="text"
          name="name"
          id="name"
          value={this.state.formData.name}
          onChange={this.setName}
          disabled={!loadComplete(this.state.progress)}
          required={true}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.name}
        />
        <TextControl
          label="Clock In"
          type="text"
          name="clockIn"
          id="clockIn"
          value={this.state.formData.clockIn}
          onChange={this.setClockIn}
          disabled={!loadComplete(this.state.progress)}
          required={true}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.clockIn}
        />
        <TextControl
          label="Clock Out"
          type="text"
          name="clockOut"
          id="clockOut"
          value={this.state.formData.clockOut}
          onChange={this.setClockOut}
          disabled={!loadComplete(this.state.progress)}
          required={true}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.clockOut}
        />
        <TextAreaControl
          label="Description"
          id="description"
          name="description"
          value={this.state.formData.description}
          onChange={this.setDescription}
          disabled={!loadComplete(this.state.progress)}
          rows={3}
          cols={30}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        <TextControl
          label="Start Date (first day of change)"
          type="date"
          name="startDate"
          id="startDate"
          onChange={this.setStartDate}
          value={moment(this.state.formData.startDate).format('Y-MM-DD')}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        <TextControl
          label="Hour norm"
          type="number"
          name="hourNorm"
          id="hourNorm"
          onChange={this.setHourNorm}
          placeholder=""
          value={this.state.formData.hourNorm ?? ""}
          disabled={!loadComplete(this.state.progress)}
          br={true}
        />
        <ToggleControl 
          name="hasTickets" 
          id="hasTickets" 
          isChecked={this.state.formData.hasTickets}
          changeMethod={this.setHasTickets}
          isDisabled={!loadComplete(this.state.progress)}
          labelText="Has Food Stamps"
        />
        {isEditPage(Number(this.state.workProfileId)) && 
          <ToggleControl 
            name="deleted" 
            id="deleted" 
            isChecked={this.state.formData.deleted}
            changeMethod={this.setDeleted}
            isDisabled={!loadComplete(this.state.progress)}
            labelText="Deleted"
            onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          />
        }
        <ButtonControl
          class="primary-button"
          onClick={this.handleSubmit}
          disabled={!loadComplete(this.state.progress)}
        >
          <RequestStatus status={this.state.formStatus} />
          <span className="text">Save</span>
        </ButtonControl>
      </div>
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);
  setStartDate = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('startDate', new Date(ev.target.value))}
  setHourNorm = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('hourNorm', Number(ev.target.value));
  setHasTickets = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('hasTickets', ev.target.checked);
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);
  setContractId = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, contractId: number | string | undefined | TenantDTO) => this.updateFormData('contractId', Number(contractId));
  setClockIn = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('clockIn', ev.target.value)}
  setClockOut = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('clockOut', ev.target.value)}

  clearError = (index: number) => {
    // Clone array to avoid mutating the state and remove the target error
    const errors = [...this.state.serverErrors];
    errors.splice(index, 1);

    this.setState({
      serverErrors: errors
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.renderBreadcrumbs()}
        <div className="flex-row">
          <div className="column">
            <ProgressBar
              currentStep={this.state.progress.currentStep}
              totalSteps={this.state.progress.totalSteps}
            />
            {this.state.serverErrors.length !== 0 &&
              <div className="toaster-component">
                {this.state.serverErrors.map((serverError, index) => {return (
                    <Notification
                      key={index}
                      id={index}
                      error={serverError}
                      onDestroy={() => this.clearError(index)}
                    />
                  )})
                }
              </div>
            }
            {this.renderDetailsForm()}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTransitionEvent(WorkProfileDetail);