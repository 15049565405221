import Fetch from "../../helpers/Fetch";
import ContractDTO from "../dtos/Contract";

export async function listContracts(): Promise<ContractDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ContractDTO[]>(`/contracts`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch contracts');
}

export async function listUserContracts(userId: number): Promise<ContractDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ContractDTO[]>(`/contracts/user/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch user\'s contracts');
}

export async function listUserContractsLimited(userId: number): Promise<ContractDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ContractDTO[]>(`/contracts/limited/user/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch user\'s contracts');
}

export async function listEmployeeContractsByUser(userId: number): Promise<ContractDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ContractDTO[]>(`/contracts/limited/user/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload().filter((contract: ContractDTO) => { return contract.type !== 'contractor'});
  }
    throw new Error('Failed to fetch user\'s contracts');
}

export async function listTenantContracts(tenantId: number): Promise<ContractDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ContractDTO[]>(`/contracts/tenant/${tenantId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch tenant\'s contracts');
}

export async function listContractDetails(contractId: number): Promise<ContractDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ContractDTO>(`/contracts/${contractId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch contract details');
}