import { TRequestStatus } from "../types/RequestStatus";

class Fetch<T = any> {
    private apiEndpoint: string;
    private requestOptions: RequestInit;
    private payload?: T;
    private response?: Response;
    private requestStatus: TRequestStatus;
    public baseUrl?: string;
    
    constructor(apiEndpoint: string, requestOptions: RequestInit) {
        this.apiEndpoint = apiEndpoint;
        this.requestOptions = requestOptions;
        this.requestStatus = 'idle';
        this.baseUrl = process.env.REACT_APP_API_BASE_URL;
    }

    fetch = async () => {
        this.response = await fetch(this.baseUrl + this.apiEndpoint, this.requestOptions);
        this.payload = await this.response.json();

        if (this.response.status >= 400 && this.response.status < 600) {
            this.requestStatus = 'error';
        } else {
            this.requestStatus = 'success';
        }
    }

    // fetch = async () => {
    //     const base = this.apiEndpoint.startsWith('/sysprops') 
    //         ? process.env.REACT_APP_NEW_API_BASE_URL 
    //         : process.env.REACT_APP_API_BASE_URL;
    //     this.response = await fetch(base + this.apiEndpoint, this.requestOptions);
    //     this.payload = await this.response.json();

    //     if (this.response.status >= 400 && this.response.status < 600) {
    //         this.requestStatus = 'error';
    //     } else {
    //         this.requestStatus = 'success';
    //     }
    // }

    externalFetch = async () => {
        this.response = await fetch(this.apiEndpoint, this.requestOptions);
        this.payload = await this.response.json();

        if (this.response.status >= 400 && this.response.status < 600) {
            this.requestStatus = 'error';
        } else {
            this.requestStatus = 'success';
        }
    }

    getPayload = (): T => {
        return this.payload as any;
        // return this.payload || [] as any;
    }

    getStatus = (): TRequestStatus => {
        return this.requestStatus;
    }
}

export default Fetch;