import React, { ChangeEvent, KeyboardEvent } from "react"
import ToggleControl from "../../../controls/ToggleControl/ToggleControl"
import SelectControl from "../../../controls/SelectControl/SelectControl"
import { Month } from "../../../../common/data/Months";

interface Props {
  disabled: boolean,
  elevatedAccess: boolean,

  selectedUser: number,
  userOptions: {id: number, name: string}[],
  onUserChange: (userId: number) => void,

  selectedYear: number,
  selectedMonth: number,
  yearOptions: number[],
  monthOptions: {number: number, name: string}[],
  onDateChange: (year: number, month: number) => void,

  showDisabled: boolean,
  onShowDisabledChange: (checked: boolean) => void,
}

export default class Filters extends React.Component<Props> {
  onUserChange = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, userId: number | string | undefined | { id: number, name: string}) => {
    this.props.onUserChange(Number(userId));
  }

  onYearChange = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, yearNo: number | string | undefined) => {
    this.props.onDateChange(Number(yearNo), this.props.selectedMonth);
  }

  onMonthChange = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, month: number | string | undefined | Month) => {
    this.props.onDateChange(this.props.selectedYear, Number(month));
  }

  onShowDisabledChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.props.onShowDisabledChange(ev.target.checked);
  }

  render() {
    return <React.Fragment>
      {this.props.elevatedAccess &&
        <SelectControl
          idName="userId"
          value={this.props.selectedUser}
          options={this.props.userOptions || []}
          disabled={this.props.disabled}
          onChange={this.onUserChange}
          getValue={(op) => op.id}
          getLabel={(op) => op?.name}
        />
      }
      <SelectControl
        idName="year"
        value={this.props.selectedYear}
        options={this.props.yearOptions}
        disabled={this.props.disabled}
        onChange={this.onYearChange}
      />
      <SelectControl
        idName="month"
        value={this.props.selectedMonth}
        //Months should be imported not passed as pros
        options={this.props.monthOptions}
        onChange={this.onMonthChange}
        disabled={this.props.disabled}
        getValue={(op) => op.number}
        getLabel={(op) => op.name}
      />
      {this.props.elevatedAccess &&
        <React.Fragment>
          <ToggleControl
            id="showDisabledTimetrackProjects"
            name="showDisabledTimetrackProjects"
            changeMethod={this.onShowDisabledChange}
            isChecked={this.props.showDisabled}
            labelText="Disabled"
          />
        </React.Fragment>
      }
    </React.Fragment>
  }
}