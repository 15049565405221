import Fetch from "../../helpers/Fetch";
import HolidayDTO from "../dtos/Holiday";

interface AddPayload {
  name: string,
  date: Date | null,
  countable: boolean,
  deleted: boolean,
}

export async function listHolidays(): Promise<HolidayDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<HolidayDTO[]>(`/holidays`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch holidays');
}
export async function listHolidayDetails(holidayId: string): Promise<HolidayDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<HolidayDTO>(`/holidays/${holidayId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch holiday details');
}

export async function addHoliday(form: AddPayload): Promise<HolidayDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<HolidayDTO>(`/holidays`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add holiday`);
}

export async function updateHoliday(form: AddPayload, hollidayId: string): Promise<HolidayDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<HolidayDTO>(`/holidays/${hollidayId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });


  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update holiday`);
}