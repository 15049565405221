
export function FlashCard(props: { name: string, title: string, reverse?: boolean }) {
  const content = [
    <span key="span" className="primary-title">{props.title}</span>,
    <small key="small" className="faint-text">{props.name}</small>
  ];

  return(
    <div className="flash-card card">
      <div className="content">
        {props.reverse ? content.reverse() : content }
      </div>
    </div>
  );
}
