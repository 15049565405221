import { UnregisterCallback } from 'history';
import moment from 'moment';
import 'moment-timezone';
import React, { Component, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import ContractDTO from '../../../common/api/dtos/Contract';
import HolidayDTO from '../../../common/api/dtos/Holiday';
import RemotesDTO, { RemotesEveryoneDTO } from '../../../common/api/dtos/Remotes';
import RoleDTO from '../../../common/api/dtos/Role';
import SyspropDTO from '../../../common/api/dtos/Sysprop';
import UserDTO from '../../../common/api/dtos/User';
import { CancelRemote, listEveryoneRemotes, listUserRemotes, SaveRemote } from '../../../common/api/endpoints/remotes';
import AppContext from '../../../common/context/AppContext';
import { LegendTiles, RemotesTiles } from '../../../common/data/LegendTiles';
import { Months } from '../../../common/data/Months';
import hasAccess from '../../../common/helpers/Access';
import { IBookingTile } from '../../../common/interfaces/BookingTile';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import SelectControl from '../../controls/SelectControl/SelectControl';
import { getDaysInMonth } from '../../generics/Booking/bookingUtils';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import ToolbarControls from '../../generics/Header/ToolbarControls';
import { withTransitionEvent } from '../../TransitionEvent';
import AlertBanner from '../../utils/AlertBanner/AlertBanner';
import ProgressBar, { incrementProgress, IProgress } from '../../utils/ProgressBar/ProgressBar';
import LegendTile from '../Bookings/LegendTile';
import RemoteTile from './RemoteTile';

const dateToUTC = (inputDate: Date): Date => {
  return new Date(Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate()));
}

type TRemoteCounters = { remotePlanned: number, remote: number, holiday: number };

export interface RouteParams {
  id?: string
}

export interface Props extends RouteComponentProps<RouteParams> {
  sysProps: SyspropDTO[],
  loggedUser: LoggedUser,
}

interface State {
  years: number[],
  form: Form,
  users?: UserDTO[],
  status: TRequestStatus,
  progress: IProgress,
  remotes: RemotesDTO[],
  remotesEveryone: RemotesEveryoneDTO[],
  counters: TRemoteCounters,
  paidTotal: number,
  remoteMap: Map<number, IBookingTile>,
  hasAccess: boolean,
  hasFullAcess: boolean,
}

interface Form {
  year: number,
  userId: number,
}

interface suspensionEntry {
  user: UserDTO,
  date: Date,
  startDate: Date,
  endDate: Date,
  countable: boolean,
  deleted: boolean,
  description: string,
  contractId: number,
  id: number,
  contract: ContractDTO,
}

class Remotes extends Component<Props, State> {
  currentDate: Date;

  constructor(props: Props) {
    super(props);

    const targetUserId = Number(this.props.match.params.id || this.props.loggedUser.id);
    const access = hasAccess(this.props.loggedUser?.role);
    const hasFullAccess = this.hasFullAccess(this.props.loggedUser?.role);
    const userId = access ? targetUserId : this.props.loggedUser.id;

    this.state = {
      years: [],
      form: {
        year: new Date().getFullYear(),
        userId,
      },
      status: 'loading',
      progress: {
        currentStep: 0,
        totalSteps: 2,
      },
      paidTotal: 0,
      counters: {
        remotePlanned: 0,
        remote: 0,
        holiday: 0,
      },
      remotes: [],
      remotesEveryone: [],
      remoteMap: new Map<number, IBookingTile>(),
      hasAccess: access,
      hasFullAcess: hasFullAccess,
    }

    this.currentDate = new Date();
  }

  fetchUserRemotes = async () => {
    this.setState({
      status: 'loading'
    });

    const remotes = await listUserRemotes(this.state.form.userId);

    this.setState(prevState => {
      return {
        status: 'success',
        remotes: [...remotes],
        progress: incrementProgress(prevState.progress),
      }
    }, () => {
      this.loadDidComplete();
    });
  }

  fetchEveryoneRemotes = async () => {
    this.setState({
      status: 'loading'
    });

    const remotesEveryone = await listEveryoneRemotes(this.state.form.year, this.state.form.userId);

    this.setState(prevState => {
      return {
        status: 'success',
        remotesEveryone: [...remotesEveryone],
        progress: incrementProgress(prevState.progress),
      }
    }, () => {
      this.loadDidComplete();
    });
  }

  getDatesInInterval = (startDate: Date, endDate: Date) => {
    var arrOfDays = new Array();
    var date = new Date(startDate);

    while (date <= new Date(endDate)) {
      arrOfDays.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return arrOfDays;
  }

  remotesPerMonth = (month: number) => {
    let totalDaysInMonth: number = 0;
    const filterEntity = <T extends { date: Date }>(entity: T[]) => {
      return entity.filter((entity: T) => new Date(entity.date).getFullYear() === this.state.form.year && new Date(entity.date).getMonth() === month)
    }

    totalDaysInMonth = filterEntity(this.state.remotesEveryone).length;

    return totalDaysInMonth;
  }

  hasFullAccess = (userRole: RoleDTO | undefined | null): boolean => {
    return userRole ? (userRole.name === 'Admin' || userRole.name === 'Manager') : false;
  }

  generateRemoteMap = () => {
    let remoteMap = new Map<number, IBookingTile>();

    for (let dayIndex = 1; dayIndex <= (moment({ year: this.state.form.year }).isLeapYear() ? 366 : 365); dayIndex++) {
      let currentDate = dateToUTC(new Date(this.state.form.year, 0, dayIndex));

      if (!remoteMap.get(currentDate.getTime())) {
        remoteMap.set(currentDate.getTime(), {
          date: currentDate,
          isWeekend: this.isWeekend(currentDate),
          isHoliday: false,
          isUnemployed: false,
          vacation: null,
          holiday: null,
          suspension: null,
          overlaps: [],
          id: null,
          status: 'idle'
        })
      }
    }

    this.setState({
      remoteMap: remoteMap
    });
  }

  populateRemoteMap = () => {
    let remoteMap = new Map(this.state.remoteMap);
    this.setState({
      counters: {
        remotePlanned: 0,
        remote: 0,
        holiday: 0,
      }
    }, () => {
      remoteMap = this.populateUnemployment(remoteMap);
      remoteMap = this.populateRemotes(remoteMap);
      remoteMap = this.populateHolidays(remoteMap);
      remoteMap = this.populateOverlaps(remoteMap);
      this.setState({
        remoteMap: remoteMap
      });
    });
  }

  populateUnemployment(remoteMap: Map<number, IBookingTile>): Map<number, IBookingTile> {
    let map = new Map(remoteMap);
    let employment = new Date('');

    for (var m = moment(new Date(this.state.form.year, 0, 1)); m.isBefore(employment); m.add(1, 'days')) {
      let currentTimestamp = dateToUTC(m.toDate()).getTime();
      let currentEntry = map.get(currentTimestamp);

      if (currentEntry) {
        map.set(currentTimestamp, {
          ...currentEntry,
          isUnemployed: true
        })
      }
    }
    return map;
  }

  populateHolidays(remoteMap: Map<number, IBookingTile>): Map<number, IBookingTile> {
    let map = new Map(remoteMap);
    let holidayTotal = 0;

    (this.context.holidays as HolidayDTO[]).forEach((holiday: HolidayDTO) => {
      if (holiday.deleted) return;

      let currentDate = dateToUTC(new Date(holiday.date));

      if (map.get(currentDate.getTime())) {
        map.set(currentDate.getTime(), {
          ...map.get(currentDate.getTime())!,
          isHoliday: true,
          holiday,
        });
        holidayTotal++;
      }
    });

    this.setState(prevState => {
      return  {
        counters: {
          ...prevState.counters,
          holiday: holidayTotal,
        }
      }
    });

    return map;
  }

  populateRemotes(remoteMap: Map<number, IBookingTile>): Map<number, IBookingTile> {
    let map = new Map(remoteMap);
    let counters: TRemoteCounters = {
      remotePlanned: 0,
      remote: 0,
      holiday: 0,
    }

    this.state.remotes.forEach((remote: RemotesDTO) => {
      if (remote.deleted) return;

      let currentTimestamp = dateToUTC(new Date(remote.date)).getTime();
      let currentEntry = map.get(currentTimestamp);

      if (currentEntry) {
        map.set(currentTimestamp, {
          ...currentEntry,
          vacation: remote,
          status: 'idle'
        });
        counters[remote.type as keyof TRemoteCounters]++;
      }
    });

    this.setState({
      counters: counters
    });

    return map;
  }

  populateOverlaps(remoteMap: Map<number, IBookingTile>): Map<number, IBookingTile> {
    let map = new Map(remoteMap);

    this.state.remotesEveryone.forEach((remote: RemotesEveryoneDTO) => {
      let currentTimestamp = dateToUTC(new Date(remote.date)).getTime();
      let currentEntry = map.get(currentTimestamp);

      if (currentEntry) {
        let soughtUser = this.context.users?.find((user: UserDTO) => user.id === remote.userId);

        if (soughtUser && !currentEntry.overlaps.includes(soughtUser)) {
          currentEntry.overlaps.push(soughtUser);
        }

        map.set(currentTimestamp, {
          ...currentEntry,
        });
      }
    });

    return map;
  }

  loadDidComplete() {
    this.setState({
      status: 'success'
    });

    if (this.state.progress.currentStep === this.state.progress.totalSteps) {
      this.populateRemoteMap();
    }
  }

  getYearsArray = (yearsString: string): void => {
    this.setState({
      years: yearsString.split(',').map(Number),
    });
  }

  isWeekend = (inputDate: Date): boolean => {
    return moment(inputDate).isoWeekday() >= 6;
  }

  getRemoteForDay = (inputDate: Date): RemotesDTO | null => {
    return (
      this.state.remotes.find((remote: RemotesDTO) => {
        return moment(remote.date).isSame(inputDate) && remote.deleted === false;
      }) || null
    );
  }

  renderHeadCells = () => {
    const arrayOfHeadings = new Array(31).fill(0);

    return arrayOfHeadings.map((value, index) => {
      return (
        <React.Fragment>
          <th key={index} className={`month-day`}>
            <span className="inner-text">{index + 1}</span>
          </th>
        </React.Fragment>
      )
    });
  }

  renderCells = (monthNumber: number) => {
    let tileDate;
    const nrOfDays = getDaysInMonth(monthNumber, this.state.form.year);
    const arrayOfDays = new Array(nrOfDays).fill(0);

    return arrayOfDays.map((value, index) => {
      tileDate = new Date(Date.UTC(this.state.form.year, monthNumber, index + 1))
      if (this.state.remoteMap) {
        let tile = this.state.remoteMap.get(tileDate.getTime());

        return (
          <React.Fragment>
            {tile &&
              <RemoteTile
                key={monthNumber + '_' + index}
                tileData={tile}
                requestRemote={this.requestRemote}
                cancelRemote={this.cancelRemote}
                changeUser={this.changeUser}
                hasAccess={this.state.hasAccess}
                hasFullAccess={this.state.hasAccess}
                selectedUserId={this.state.form.userId}
              />
            }
          </React.Fragment>
        )
      }
    });
  }

  changeUser = (userId: number) => {
    if (this.state.form.userId === userId) return;
    this.updateForm('userId', Number(userId));
  }

  fetchSaveRemote = async (remote: RemotesDTO) => {
    const payload = await SaveRemote(remote);
    return {
      status: 'success' as TRequestStatus,
      payload: payload,
    }
  }

  fetchCancelRemote = async (remoteId: number) => {
    const payload = await CancelRemote(remoteId);
    return {
      status: 'success' as TRequestStatus,
      payload: payload,
    }
  }

  computeCounters(operation: string, counterType: string) {
    let counters = { ...this.state.counters };
    let paidTotal = this.state.paidTotal;

    switch (operation) {
      case 'save':
        counters[counterType as keyof TRemoteCounters]++;
        break;

      case 'cancel':
        counters[counterType as keyof TRemoteCounters]--;
        break;
    }

    return {
      counters,
      paidTotal
    }
  }

  requestRemote = async (inputDate: Date, type: string) => {
    let map = new Map(this.state.remoteMap);
    let currentTimestamp = inputDate.getTime();
    let currentEntry = map.get(currentTimestamp);
    const user = this.context.users.find((user: UserDTO) => user.id === this.state.form.userId);
    const remote = new RemotesDTO(null, type, inputDate, "?", null, false, false, false, user);

    if (currentEntry) {
      this.setState({
        remoteMap: map.set(currentTimestamp, {
          ...currentEntry,
          status: 'loading'
        })
      })

      const response = await this.fetchSaveRemote(remote);

      this.setState({
        remoteMap: map.set(currentTimestamp, {
          ...currentEntry,
          status: response.status
        })
      })

      if (response.payload.id) {
        currentEntry.overlaps.unshift(this.context.users?.find((user: UserDTO) => user.id == this.state.form.userId)!);
        map.set(currentTimestamp, {
          ...currentEntry,
          vacation: new RemotesDTO(response.payload.id, type, inputDate),
          status: response.status
        })

        const allCounters = this.computeCounters('save', response.payload.type);

        this.setState((prevState: State) => {
          let remotes = prevState.remotes.slice();
          remotes.push(new RemotesDTO(response.payload.id, type, inputDate));

          return {
            remoteMap: map,
            remotes: remotes,
            counters: allCounters.counters,
            paidTotal: allCounters.paidTotal
          }
        })
      }
    }
  }

  cancelRemote = async (inputDate: Date, remoteId: number) => {
    let remotes = this.state.remotes.slice();
    let seekedRemote = remotes.find((remote: RemotesDTO) => {
      return moment(remote.date).isSame(inputDate) && remote.deleted === false;
    });

    if (seekedRemote) {
      let map = new Map(this.state.remoteMap);
      let currentTimestamp = dateToUTC(new Date(seekedRemote.date)).getTime();
      let currentEntry = map.get(currentTimestamp);

      if (currentEntry) {
        this.setState({
          remoteMap: map.set(currentTimestamp, {
            ...currentEntry,
            status: 'loading'
          })
        })

        const response = await this.fetchCancelRemote(remoteId);

        this.setState({
          remoteMap: map.set(currentTimestamp, {
            ...currentEntry,
            status: response.status
          })
        })

        if (response.payload.id) {
          remotes.splice(remotes.indexOf(seekedRemote), 1);

          currentEntry.overlaps.splice(currentEntry.overlaps.findIndex((user: UserDTO) => user.id === this.state.form.userId), 1);
          map.set(currentTimestamp, {
            ...currentEntry,
            vacation: null,
            status: response.status
          })

          const allCounters = this.computeCounters('cancel', response.payload.type);

          this.setState({
            remoteMap: map,
            counters: allCounters.counters,
            paidTotal: allCounters.paidTotal
          })
        }
      }
    }
  }

  setUserUrl = () => {
    this.props.history.replace(`/${this.props.location.pathname.split('/')[1]}/${this.state.form.userId}`);
  }

  /*
   * Check if pathname exist
   *
  */
  checkPathName() {
    let url = this.props.match.params.id;
    return url === undefined || this.context.users.some((user: UserDTO) => url === user.id.toString());
  }

  /**
   * Executed once when the component loads.
  */
  private async initialize() {
    if (!this.checkPathName()) {
      this.props.history.replace('/404');
      return;
    }
    this.getYearsArray(this.props.sysProps[1].value);
    this.generateRemoteMap();
    this.fetchUserRemotes();
    this.fetchEveryoneRemotes();
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (this.state.form.userId !== prevState.form.userId) {
      this.setState({
        status: 'loading',
        progress: {
          currentStep: 0,
          totalSteps: 2,
        },
      });

      this.fetchUserRemotes();
      this.fetchEveryoneRemotes();
      this.setUserUrl();
    }

    if (this.state.form.year !== prevState.form.year) {
      this.setState({
        status: 'loading',
        progress: {
          currentStep: 0,
          totalSteps: 2,
        },
      });

      this.fetchUserRemotes();
      this.fetchEveryoneRemotes();
    }
  }

  updateForm<K extends keyof Form>(field: K, value: Form[K]) {
    const form = this.state.form;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    }, () => {
      this.generateRemoteMap();
    })
  }

  setYear = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, yearNo: number | string | undefined) => this.updateForm('year', Number(yearNo));
  setUser = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, userId: number | string | undefined | UserDTO) => {
    this.updateForm('userId', Number(userId));
  }

  render() {
    const remotesTiles = Object.values(RemotesTiles);
    return (
      <div>
        <ProgressBar
          currentStep={this.state.progress.currentStep}
          totalSteps={this.state.progress.totalSteps}
        />
        <ToolbarControls>
          {this.state.hasAccess &&
            <SelectControl
              idName="userId"
              value={this.state.form.userId}
              options={this.hasFullAccess(this.props.loggedUser.role) ? (this.context.users as UserDTO[]).filter(user => user.invisible === false) : this.context.users}
              disabled={this.state.status === 'loading'}
              onChange={this.setUser}
              getValue={(op: UserDTO) => op.id}
              getLabel={(op: UserDTO) => op?.name}
            />
          }
          <SelectControl
            idName="year"
            value={this.state.form.year}
            options={this.state.years}
            disabled={this.state.status === 'loading'}
            onChange={this.setYear}
          />
        </ToolbarControls>
        <BreadcrumbControls
          pageTitle="Remotes"
          status={this.state.status}
        />
        <div className="flex-row fill">
        <div className="column">
          <div className="booking-component">
            <div className="card">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="lead-column">
                        <span className="innert-text">Month</span>
                      </th>
                      {this.renderHeadCells()}
                    </tr>
                  </thead>
                  <tbody>
                    {Months.map((month, index) => {
                      return (
                        <tr className={[
                          month.number == this.currentDate.getMonth() && this.state.form.year == this.currentDate.getFullYear() ? "highlight-row" : ""
                        ].join(" ")}>
                          <td></td>
                          <td className="month-name">
                            <div className="month-content">
                              <span>{month.name} </span>
                              <small className="faint-text">{this.remotesPerMonth(month.number)}</small>
                            </div>
                          </td>
                          {this.renderCells(month.number)}
                        </tr>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colSpan={33}>
                        <div className="flex-row fill">
                          <div className="column">
                            <AlertBanner pageName="Remotes" loggedUserId={this.props.loggedUser.id}>
                              <small>
                                <span className="fas fa-flag"></span>&nbsp;
                                <span>Always check with your group and group lead before planning a remote. Too many overlaps might put pressure on some colleagues if things are not smooth enough.</span>
                              </small>
                              <br />
                            </AlertBanner>
                            <AlertBanner pageName="Remotes" loggedUserId={this.props.loggedUser.id}>
                              <small>
                                <span className="fas fa-exclamation-triangle"></span>&nbsp;
                                <span>This is a pilot initiative and is subject to change. We're currently experimenting with a maximum of 2 planned days per month on Fridays. Days are not rolling over. Days must fall on a Friday. Days can't be chained.</span>
                              </small>
                            </AlertBanner>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr className="visible-desktop">
                      <th colSpan={33}>
                        <div className="flex-row booking-legend-component">
                          {remotesTiles.map((tile, index) => {
                            return (
                              <div className="column stretch" key={index}>
                                <LegendTile
                                  title={tile.title}
                                  type={tile.type}
                                  description={tile.description}
                                  action={tile.action}
                                  counter={this.state.counters[tile.type as keyof TRemoteCounters]}
                                />
                              </div>
                            )
                          })}
                          <div className="column stretch">
                          </div>
                          <div className="column stretch">
                          </div>
                          <div className="column stretch">
                          </div>
                          <div className="column stretch">
                          </div>
                          <div className="column stretch">
                          </div>
                        </div>
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(Remotes);

Remotes.contextType = AppContext;