import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  onClick?: () => void,
  onContextMenu?: () => void,
  to?: string,
  id?: string,
  class: string,
  activeLink?: string,
  icon?: string,
  disabled?: boolean,
}

class GhostButton extends Component<Props> {

  render() {
    return (
      <React.Fragment>
      {this.props.to ?
        <NavLink 
          to={this.props.to} 
          className={this.props.class} 
          activeClassName={this.props.activeLink}
        >
            {this.props.icon &&
              <span className={this.props.icon}></span>
            }
            {this.props.children &&
              <span className="text">
                {this.props.children}
              </span>
            }
        </NavLink>
      :
      <button
          id={this.props.id}
          className={this.props.class}
          onClick={this.props.onClick}
          onContextMenu={this.props.onContextMenu}
          disabled={this.props.disabled}
        >
          {this.props.icon && <span className={this.props.icon}></span>}
          {this.props.children}
        </button>
      }
      </React.Fragment>
    ) 
  }
}

export default GhostButton;
