import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ProjectDTO, { PartialProjectGroup } from '../../../common/api/dtos/Project';
import { InvoiceLabels } from '../../../common/data/InvoiceLabels';
import { mergeRequestStatus, TRequestStatus } from '../../../common/types/RequestStatus';
import ProgressBar, { IProgress, incrementProgress, newProgress, restartProgress } from '../../utils/ProgressBar/ProgressBar';
import { WorklogDTO } from '../../../common/api/dtos/Worklog';
import { getWorklogsReport } from '../../../common/api/endpoints/worklogs';
import { filterProjects } from '../../../common/api/endpoints/projects';
import { TenantSection } from '../../generics/Invoices/TenantSection/TenantSection';
import { ClientSection } from '../../generics/Invoices/ClientSection/ClientSection';
import { InvoiceTable } from '../../generics/Invoices/InvoiceTable/InvoiceTable';
import { InvoiceItem } from '../../generics/Invoices/types';
import { ComponentProps } from '../../generics/Invoices/InvoicesLayout/InvoicesLayout';
import { InvoiceSection } from '../../generics/Invoices/InvoiceSection/InvoiceSection';
import { LogoSection } from '../../generics/Invoices/LogoSection/LogoSection';
import { withInvoicesLayout } from '../../generics/Invoices/InvoicesLayout/withInvoicesLayout';
import { calculateInvoicePriceInfo } from '../../generics/Invoices/utils';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import { withTransitionEvent } from '../../TransitionEvent';
import { listProjectGroups } from '../../../common/api/endpoints/projectGroups';
import { ProjectGroupDTO } from '../../../common/api/dtos/ProjectGroup';

interface Props extends ComponentProps {}

interface State {
  projects: ProjectDTO[],
  projectGroups: ProjectGroupDTO[],
  items: {
    id: number,
    name: string,
    quantity: number,
    unitPrice: number,
    projectGroupId: number | null,
    projectGroup?: PartialProjectGroup
  }[],
  progress: IProgress,
  invoiceDataProgress: IProgress,
  requestStatus: TRequestStatus,
}

class HourlyProjectInvoice extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      projects: [],
      projectGroups: [],
      requestStatus: 'idle',
      progress: newProgress(1),
      invoiceDataProgress: newProgress(1),
      items: [],
    }
  }

  componentDidMount() {
    this.init();
  }

  private async init() {
    if (this.props.client) {
      const range = this.props.dateRange;
      this.loadProjectsAndWorklogs(this.props.client.id, range.since, range.until);
    }
  }

  componentDidUpdate(prevProps: Props) {
    let clientRefresh = false;

    if (this.props.client && this.props.client !== prevProps.client) {
      const range = this.props.dateRange;
      this.loadProjectsAndWorklogs(this.props.client.id, range.since, range.until);
      clientRefresh = true;
    }

    if (
      this.props.dateRange.since.getTime() !== prevProps.dateRange.since.getTime()
      && this.props.dateRange.until.getTime() !== prevProps.dateRange.until.getTime()
    ) {
      if (clientRefresh === false && this.props.client) {
        const range = this.props.dateRange;
        this.loadWorklogs(this.props.client.id, range.since, range.until);
      }
    }
  }

  /**
   * Load the projects and worklogs for a client.
   */
  private async loadProjectsAndWorklogs(clientId: number, since: Date, until: Date) {
    this.setState(prevState => {
      return {
        requestStatus: 'loading',
        progress: restartProgress(prevState.progress),
        invoiceDataProgress: restartProgress(prevState.invoiceDataProgress),
      };
    });

    const [projects, projectGroups, reports] = await Promise.all([
      this.requestProjects(clientId),
      this.requestProjectGroups(clientId),
      this.requestWorklogs(clientId, since, until),
    ]);

    this.setState({
      requestStatus: 'success',
      projects,
      projectGroups,
      items: this.computeRows(projects, reports),
    });
  }

  /**
   * Load worklogs for a client.
   */
  private async loadWorklogs(clientId: number, since: Date, until: Date) {
    this.setState(prevState => {
      return {
        requestStatus: 'loading',
        invoiceDataProgress: restartProgress(prevState.invoiceDataProgress),
      };
    });

    const reports = await this.requestWorklogs(clientId, since, until);

    this.setState({
      requestStatus: 'success',
      items: this.computeRows(this.state.projects, reports),
    });
  }

  private async requestProjects(clientId: number) {
    const projects = await filterProjects([clientId]);
    this.setState(prevState => ({ progress: incrementProgress(prevState.progress) }));
    return projects;
  }

  private async requestWorklogs(clientId: number, since: Date, until: Date) {
    const worklogs = await getWorklogsReport(clientId, since, until);
    this.setState(prevState => ({ invoiceDataProgress: incrementProgress(prevState.invoiceDataProgress) }));
    return worklogs;
  }

  private async requestProjectGroups(clientId: number) {
    const projectGroups = await listProjectGroups(clientId);
    this.setState(prevState => ({progress: incrementProgress(prevState.progress)}));
    return projectGroups;
  }

  /**
   * Aggregate projects and worklogs.
   */
  private computeRows(projects: ProjectDTO[], worklogs: WorklogDTO[]) {
    const projectHoursMap: { [key: number]: number } = {};
    for (const log of worklogs) {
      const prevHours = projectHoursMap[log.projectId] || 0;
      projectHoursMap[log.projectId] = log.workLog_hours + prevHours;
    }

    return projects
      .filter(project => project.billable && projectHoursMap[project.id] > 0)
      .map((project: ProjectDTO) => {
        return {
          id: project.id,
          name: project.name,
          quantity: projectHoursMap[project.id] || 0,
          unitPrice: 0,
          projectGroupId: project.projectGroupId,
          projectGroup: project.projectGroup
        }
      });
  }

  /**
   * Update a row based on an invoice item.
   */
  private setInvoiceItem = (item: InvoiceItem) => {
    const index = this.state.items.findIndex(row => row.id === item.id);
    if (index === -1) {
      throw new Error('Failed to find invoice row during replace operation');
    }

    const rows = [...this.state.items];
    rows[index] = {
      ...rows[index],
      quantity: item.quantity,
      unitPrice: item.unitPrice,
    };

    this.setState({ items: rows });
  }


  render() {
    const translations = InvoiceLabels[this.props.language];
    const payprofile = this.props.payprofile;
    const tenant = this.props.tenant;
    const client = this.props.client;
    const language = this.props.language;
    const hasVat = this.props.vat !== undefined;

    const monthRange = this.props.dateRange;

    let totalAmount = 0;
    let totalVatAmount = 0;

    const invoiceItems = this.state.items.map(i => {
      const price = calculateInvoicePriceInfo(i.quantity, i.unitPrice, this.props.vat);

      // sum about the amounts for all the rows
      totalAmount += price.amount;
      totalVatAmount += price?.vatAmount || 0;

      return {
        id: i.id,
        name: i.name,
        quantity: i.quantity,
        unitPrice: i.unitPrice,
        amount: price.amount,
        unitPriceExclVat: price.unitPriceExclVat,
        vatAmount: price.vatAmount,
        projectGroupId: i.projectGroupId
      }
    });

    const activeProjectGroups = this.state.items.map(item => item.projectGroupId)
                                                .filter(projectGroupId => projectGroupId !== null);

    const projectGroups = this.state.projectGroups.map(projectGroup => {
      if (activeProjectGroups.some(pId => pId === projectGroup.id)) {
        return projectGroup;
      }
    }).filter(p => p !== undefined);

    return (
      <div>
        <ProgressBar
          currentStep={this.state.progress.currentStep + this.props.requestProgress.currentStep}
          totalSteps={this.state.progress.totalSteps + this.props.requestProgress.totalSteps}
        />
        <BreadcrumbControls
          pageTitle="Hourly by project"
          status={mergeRequestStatus(this.props.requestStatus, this.state.requestStatus)}
        />
        <div className="flex-row fill">
          <div className="column">
            <div className="card">
              <div className="flex-row fill">
                <div className="column stretch">
                  <InvoiceSection
                    translations={translations}
                    invoiceSeries={payprofile?.invoiceSeries}
                    since={monthRange.since}
                    until={monthRange.until}
                    language={language}
                    tenant={tenant}
                  />
                </div>
                <div className="column stretch">
                  <LogoSection
                    translations={translations}
                    currencySymbol={payprofile?.currency}
                    amount={totalAmount + totalVatAmount}
                    language={language}
                    hasVat={hasVat}
                    tenant={tenant}
                  />
                </div>
              </div>
              <div className="flex-row fill">
                <div className="column stretch">
                  <div className="fill">
                    {tenant
                      ? <TenantSection tenant={tenant} vatRate={this.props.vat} labels={translations} payprofile={payprofile} />
                      : <div className="card">
                        <p className="text-chunk">There are no tenants defined. Start by <Link className="link-button" to="/tenant/add">adding</Link> the first one.</p>
                      </div>
                    }
                  </div>
                </div>
                <div className="column stretch">
                  <div className="fill">
                    {client
                      ? <ClientSection client={client} labels={translations} />
                      : <div className="card">
                        <p className="text-chunk">There are no clients defined. Start by <Link className="link-button" to="/client/add">adding</Link> the first one.</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <ProgressBar
              currentStep={this.state.invoiceDataProgress.currentStep}
              totalSteps={this.state.invoiceDataProgress.totalSteps}
            />
            <div className="flex-row fill">
              <div className="column">
                <div className="card">
                  <div className="tableview-component">
                    <InvoiceTable
                      currencySymbol={payprofile?.currency}
                      hasVat={hasVat}
                      invoiceItems={invoiceItems}
                      totalAmount={totalAmount}
                      totalVatAmount={totalVatAmount}
                      translations={translations}
                      onItemChange={this.setInvoiceItem}
                      projectGroups={projectGroups as ProjectGroupDTO[]}
                    />
                  </div>
                </div>
                <p contentEditable suppressContentEditableWarning={true}>
                  {translations?.instructionA}
                  <br />
                  {translations?.instructionB}
                </p>
                <br />
                <p contentEditable suppressContentEditableWarning={true}>
                  {translations?.disclaimerA}
                  <br />
                  {translations?.disclaimerB}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(withInvoicesLayout(HourlyProjectInvoice));
