import Fetch from "../../helpers/Fetch";
import SuspensionDTO from "../dtos/Suspension";

interface AddPayload {

}

interface SuspensionsFilter {
  uid: number,
}

export async function filterSuspensions(filter: SuspensionsFilter): Promise<SuspensionDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SuspensionDTO[]>(`/suspensions/filter`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(filter)
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch suspensions');
}

export async function listSuspensions(): Promise<SuspensionDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SuspensionDTO[]>(`/suspensions`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch suspensions');
}

export async function listUserSuspensions(userId: number): Promise<SuspensionDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SuspensionDTO[]>(`/suspensions/user/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch user\'s suspensions');
}

export async function listSuspensionDetails(suspenionId: number): Promise<SuspensionDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SuspensionDTO>(`/suspensions/${suspenionId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch suspension details');
}

export async function addSuspension(form: AddPayload): Promise<SuspensionDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SuspensionDTO>(`/suspensions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add suspension`);
}

export async function updateSuspension(form: AddPayload, suspensionId: number): Promise<SuspensionDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<SuspensionDTO>(`/suspensions/${suspensionId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update suspension`);
}