export const FormErrors: any = {
    "string": {
        'string.empty': 'This field cannot be empty',
        'string.email': 'Please input a valid email address',
        'string.pattern.base': 'The password must be at least 8 characters, contain at least one number, one lowercase letter, one uppercase letter and one special character.',
        'any.required': 'This is a required field',
        'object.unknown': 'This field cannot be empty',
        'string.base': 'This field cannot be empty',
        'string.max': 'This field must have maximum 6 characters',
        'any.only': 'Passwords do not match',
    },
    "date": {
        'date.base': 'Please enter valid date',
    },
    "number": {
        'any.required': 'This is a required field',
        'object.unknown': 'This field cannot be empty',
        'number.max': 'This field must have maximum 6 characters',
    }
}