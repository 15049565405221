import Fetch from "../../helpers/Fetch";
import { LoggedUser } from "../../interfaces/LoggedUser";

export interface IWeather {
    "coord": {
      "lon": number,
      "lat": number
    },
    "weather": [
      {
        "id": number,
        "main": string,
        "description": string,
        "icon": string
      }
    ],
    "base": string,
    "main": {
      "temp": number,
      "feels_like": number,
      "temp_min": number,
      "temp_max": number,
      "pressure": number,
      "humidity": number
    },
    "visibility": number,
    "wind": {
      "speed": number,
      "deg": number
    },
    "clouds": {
      "all": number
    },
    "dt": number,
    "sys": {
      "type": number,
      "id": number,
      "message": number,
      "country": string,
      "sunrise": number,
      "sunset": number
    },
    "timezone": number,
    "id": number,
    "name": string,
    "cod": number
}

export async function getWeather(): Promise<IWeather> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<IWeather>(encodeURI(`https://api.openweathermap.org/data/2.5/weather?lat=46.770439&lon=23.591423&appid=${process.env.REACT_APP_OPEN_WEATHER_API}&units=metric`), {
    method: "GET",
  });

  await fetchRequest.externalFetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to fetch weather from Open Weather Map`);
}