import React, { ChangeEvent, Component } from 'react';
import { updateAvatars } from '../../../common/api/endpoints/avatars';
import { AvatarProps } from '../../../common/data/AvatarProps';
import { ColorGroup } from '../../../common/interfaces/ColorGroup';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import AvatarOption from '../AvatarOption/AvatarOption';
import ButtonControl from '../../controls/ButtonControl/ButtonControl';
import RadioGroup from '../../utils/RadioGroup/RadioGroup';
import RequestStatus from '../../utils/RequestStatus/RequestStatus';
import { updateLoggedUserAvatar } from '../../../common/api/endpoints/loggeduser';
import { withTransitionEvent } from '../../TransitionEvent';
import GhostButtonControl from '../../controls/GhostButtonControl/GhostButtonControl';
interface Props {
  loggedUser: LoggedUser,
  setLoggedUser: (loggedUser: LoggedUser) => void
}

interface State {
  form: Form,
  status: TRequestStatus,
  loggedUser: LoggedUser
}

interface Form {
  shirt: string,
  bgColor: string,
  hairColor: string,
  hairStyle: string,
  mouthStyle: string,
  eyewear: string,
  eyesColor: string,
  beardColor: string,
  beardStyle: string,
  faceShape: string,
  skinTone: string
}

class AvatarGenerator extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        shirt: this.props.loggedUser.avatar.split('|')[0],
        bgColor: AvatarProps.bgColor.filter((bgColor: ColorGroup) => bgColor.colorHex === this.props.loggedUser.avatar.split('|')[1].toUpperCase())[0].colorName,
        hairColor: this.props.loggedUser.avatar.split('|')[2].split('-')[1] ? this.props.loggedUser.avatar.split('|')[2].split('-')[1] : AvatarProps.hairColor[0].colorName,
        hairStyle: this.props.loggedUser.avatar.split('|')[2].split('-')[0],
        mouthStyle: this.props.loggedUser.avatar.split('|')[3],
        eyesColor: this.props.loggedUser.avatar.split('|')[4],
        beardColor: this.props.loggedUser.avatar.split('|')[5].split('-')[2] ? this.props.loggedUser.avatar.split('|')[5].split('-')[2] : AvatarProps.beardColor[0].colorName,
        beardStyle: this.props.loggedUser.avatar.split('|')[5].split('-')[1] ?? "",
        faceShape: "round",
        eyewear: this.props.loggedUser.avatar.split('|')[7],
        skinTone: this.props.loggedUser.avatar.split('|')[6].split('-')[1],
      },
      status: "idle",
      loggedUser: this.props.loggedUser
    }
  }
  
  updateAvatar = async () => {
    this.setState({
      status: 'loading'
    });

    await updateLoggedUserAvatar({avatar: this.state.loggedUser.avatar}, this.state.loggedUser.id);
     
    this.setState({
        status: 'success',
    }, () => {
      this.props.setLoggedUser(this.state.loggedUser);

    });
  }

  updateForm<K extends keyof Form>(field: K, value: Form[K]) {
    const form = this.state.form;
    this.setState({
      form: {
        ...form,
        [field]: value,
      }
    })
  }

    getRandomFromArray = (array: string[] | ColorGroup[] ) => {
    const random =  Math.floor(Math.random() * array.length)
    
    return typeof array[random] === 'object' ? Object(array[random]) : String(array[random])
  }

  getRandomAvatar = () => {
    const randomShirt = this.getRandomFromArray(AvatarProps.shirts)
    const randomBgColor = this.getRandomFromArray(AvatarProps.bgColor)
    const randomHairColor = this.getRandomFromArray(AvatarProps.hairColor)
    const randomHairStyle = this.getRandomFromArray(AvatarProps.hairStyle)
    const randomMouthStyle = this.getRandomFromArray(AvatarProps.mouthStyle)
    const randomEyesColor = this.getRandomFromArray(AvatarProps.eyesColor)
    const randomBeardColor = this.getRandomFromArray(AvatarProps.beardColor)
    const randomBeardStyle = this.getRandomFromArray(AvatarProps.beardStyle)
    const randomFaceShape = this.getRandomFromArray(AvatarProps.faceShape)
    const randomEyewear = this.getRandomFromArray(AvatarProps.eyewear)
    const randomSkinTone = this.getRandomFromArray(AvatarProps.skinTone)

    this.setState(() => ({
        form: { 
          bgColor: randomBgColor.colorName,
          shirt: randomShirt,
          hairColor: randomHairColor.colorName,
          hairStyle: randomHairStyle,
          mouthStyle:randomMouthStyle,
          eyesColor: randomEyesColor,
          beardColor: randomBeardColor.colorName,
          beardStyle: randomBeardStyle,
          faceShape: randomFaceShape,
          eyewear: randomEyewear,
          skinTone: randomSkinTone.colorName
        }
    }))
  }

  setBgColor = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('bgColor', ev.target.value);
  setSkinTone = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('skinTone', ev.target.value);
  setHairStyle = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('hairStyle', ev.target.value);
  setHairColor = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('hairColor', ev.target.value);
  setBeardStyle = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('beardStyle', ev.target.value);
  setBeardColor = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('beardColor', ev.target.value);
  setMouthStyle = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('mouthStyle', ev.target.value);
  seteyewear = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('eyewear', ev.target.value);
  setEyesColor = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('eyesColor', ev.target.value);
  setShirt = (ev: ChangeEvent<HTMLInputElement>) => this.updateForm('shirt', ev.target.value);

  setAvatar = (): void => {
    this.setState({
      loggedUser: {
        ...this.state.loggedUser,
        avatar: `${this.state.form.shirt}|${AvatarProps.bgColor.filter((bgColor: ColorGroup) => bgColor.colorName === this.state.form.bgColor)[0].colorHex}|${this.state.form.hairStyle !== "" ? this.state.form.hairStyle + "-" + this.state.form.hairColor: ""}|${this.state.form.mouthStyle}|${this.state.form.eyesColor}|${this.state.form.beardStyle !== "" ? this.state.form.faceShape + "-" +this.state.form.beardStyle + "-" + this.state.form.beardColor : ""}|${this.state.form.faceShape}-${this.state.form.skinTone}|${this.state.form.eyewear}`
      }
    }, () => {
      this.updateAvatar();
    });
  }

  render() {
    return (
      <div>
        <div className="card">
          <div className="flex-row">
            <div className="column">
              <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 285px)'}}>
                <fieldset className="form-group">
                  <legend>Random</legend>
                  <GhostButtonControl
                      onClick={this.getRandomAvatar}
                      class="ghost-button"
                      icon="fas fa-dice icon"
                    />
                </fieldset>
                <RadioGroup 
                  colors={AvatarProps.bgColor}
                  caption="Background"
                  onChange={this.setBgColor}
                  defaultChecked={this.state.form.bgColor}
                  optionType="background"
                />
                <RadioGroup 
                  colors={AvatarProps.skinTone}
                  caption="Skin Tone"
                  onChange={this.setSkinTone}
                  defaultChecked={this.state.form.skinTone}
                  optionType="skinTone"
                />
                <RadioGroup 
                  options={AvatarProps.hairStyle}
                  caption="Hair Style"
                  onChange={this.setHairStyle}
                  defaultChecked={this.state.form.hairStyle}
                  styleColor={this.state.form.hairColor}
                  optionType="hair"
                />
                <RadioGroup 
                  colors={AvatarProps.hairColor}
                  caption="Hair Color"
                  optionType="hair"
                  onChange={this.setHairColor}
                  defaultChecked={this.state.form.hairColor}
                />
                <RadioGroup 
                  options={AvatarProps.beardStyle}
                  caption="Beard Style"
                  onChange={this.setBeardStyle}
                  defaultChecked={this.state.form.beardStyle}
                  styleColor={this.state.form.hairColor}
                  optionType="beard"
                />
                <RadioGroup 
                  colors={AvatarProps.beardColor}
                  caption="Beard Color"
                  onChange={this.setBeardColor}
                  defaultChecked={this.state.form.beardColor}
                  optionType="beard"
                />
                <RadioGroup 
                  options={AvatarProps.mouthStyle}
                  caption="Expression"
                  onChange={this.setMouthStyle}
                  defaultChecked={this.state.form.mouthStyle}
                  optionType="mouth"
                />
                <RadioGroup 
                  options={AvatarProps.eyewear}
                  caption="Eyewear"
                  onChange={this.seteyewear}
                  defaultChecked={this.state.form.eyewear}
                  optionType="eyewear"
                />
                <RadioGroup 
                  options={AvatarProps.eyesColor}
                  caption="Eyes Color"
                  onChange={this.setEyesColor}
                  optionType="eyes"
                  defaultChecked={this.state.form.eyesColor}
                />
              </div>
            </div>
            <div className="column large">
              <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 285px)'}}>
                <fieldset>
                  <legend>Shirt</legend>
                  <div className="form-group radio-group">
                    {AvatarProps.shirts.map((shirt, index) => (
                      <AvatarOption
                        key={index}
                        shirt={shirt}
                        checked={this.state.form.shirt == shirt}
                        skinTone={this.state.form.skinTone}
                        hairColor={this.state.form.hairColor}
                        hairStyle={this.state.form.hairStyle}
                        beardColor={this.state.form.beardColor}
                        beardStyle={this.state.form.beardStyle}
                        mouthStyle={this.state.form.mouthStyle}
                        eyewear={this.state.form.eyewear}
                        eyesColor={this.state.form.eyesColor}
                        bgColor={AvatarProps.bgColor.filter((bgColor: ColorGroup) => bgColor.colorName === this.state.form.bgColor)[0].colorHex}
                        onChange={this.setShirt}
                      />
                    ))}
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="column">
            <ButtonControl
              class="primary-button"
              type="button"
              onClick={this.setAvatar}
            >
              <RequestStatus status={this.state.status} />
              <span>Save</span>
            </ButtonControl>
          </div>
        </div>
      </div>
    )
  }
}

export default AvatarGenerator;
