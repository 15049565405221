import { ChartDataset } from 'chart.js';
import { Component } from 'react';
import { Holiday } from '../../../common/interfaces/Holiday';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import FlashCards from '../../widgets/FlashCards/FlashCards';
import Holidays from '../../widgets/Holidays/Holidays';
import Vacations from '../../widgets/Vacations/Vacations';
import Welcome from '../../widgets/Welcome/Welcome';
import {default as TimetrackWidget} from '../../widgets/Timetrack/Timetrack'
import { currentLogo } from '../../../common/api/endpoints/logos';
import Remotes from '../../widgets/Remotes/Remotes';
import AppContext from '../../../common/context/AppContext';
import ToolbarControls from '../../generics/Header/ToolbarControls';
import SyspropDTO from '../../../common/api/dtos/Sysprop';
import Events from '../../widgets/Events/Events';
import { withTransitionEvent } from '../../TransitionEvent';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import Thumbnail from '../../utils/Thumbnail/Thumbnail';

export interface Props {
  holidays?: Holiday[],
  theme: string,
  welcomeTheme: string,
}

interface State {
  status: TRequestStatus,
  chartData?: ChartDataset[],
}


class Dashboard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      status: 'loading',
    }
  }

  render() {
    const showLoggersLeaderboard = localStorage.getItem(this.context.loggedUser?.id + 'showLoggersLeaderboard') === 'false' ? false : true;
    const streak = this.context.loggedUser.streak;
    return (
      <>
        <BreadcrumbControls
          items={[
            {label: `dashboard`}
          ]}
        />
        <div className="flex-row tight-top">
          <div className="column">
            <div className="card transparent">
              <div className="flex-row no-reflow">
                <div className="column flex-v-center flex-h-end">
                  {streak >= 2 &&
                    <span className="accent-text fas fa-leaf"
                      style={{
                        "transform": "scale(-1, 1) translate(-10px, 15px)",
                        "fontSize": "18px",
                        "opacity": ".5",
                      }}></span>
                  }
                  {streak >= 10 &&
                    <span className="accent-text fas fa-leaf"
                      style={{
                        "transform": "scale(-1, 1) translate(-10px, 15px)",
                        "fontSize": "24px",
                        "opacity": ".75",
                      }}></span>
                  }
                  {streak >= 50 &&
                    <span className="accent-text fas fa-leaf"
                      style={{
                        "fontSize": "30px",
                        "marginLeft": "-33px",
                        "transform": "translate(45px, 14px) scale(-1.15,1.15)",
                        "color": "rgba(var(--primaryLight), 1)",
                        "zIndex": 0,
                        "textShadow": "0 0 10px rgba(var(--primaryLight), .75)",
                      }}></span>
                  }
                  {streak >= 25 &&
                    <span className="accent-text fas fa-leaf"
                    style={{
                      "transform": "scale(-1, 1) translate(-10px, 15px)",
                      "fontSize": "30px",
                      "color": "rgba(var(--primaryDark), 1)",
                    }}></span>
                  }
                </div>
                <div className="column flex-h-center">
                  <div className="text-center">
                    <Thumbnail
                      classes="large icon"
                      avatarData={this.context.loggedUser?.avatar}
                    />
                    <hr />
                    {streak ? 
                      <>
                        <small className="faint-text">current logging streak</small>
                        <br />
                        <small className="flag-text">{streak}</small>
                      </>
                    :
                      <>
                        <small className="faint-text">gathering streak data</small>
                        <br />
                        <span className="accent-text fas fa-dice"></span>
                      </>  
                    }
                  </div>
                </div>
                <div className="column flex-v-center">
                  {streak >= 25 &&
                    <span className="accent-text fas fa-leaf"
                      style={{
                        "transform": "scale(1) translate(-10px, 15px)",
                        "fontSize": "30px",
                        "color": "rgba(var(--primaryDark), 1)",
                        "zIndex": 1,
                        "position": "relative",
                    }}></span>
                  }
                  {streak >= 50 &&
                    <span className="accent-text fas fa-leaf"
                      style={{
                        "fontSize": "30px",
                        "marginLeft": "-33px",
                        "transform": "translate(-10.5px, 14px) scale(1.15,1.15)",
                        "color": "rgba(var(--primaryLight), 1)",
                        "zIndex": 0,
                        "textShadow": "0 0 10px rgba(var(--primaryLight), .75)",
                      }}></span>
                  }
                  {streak >= 10 &&
                    <span className="accent-text fas fa-leaf"
                      style={{
                        "transform": "scale(1, 1) translate(-10px, 15px)",
                        "fontSize": "24px",
                        "opacity": ".75",
                      }}></span>
                  }
                  {streak >= 2 &&
                    <span className="accent-text fas fa-leaf"
                    style={{
                      "transform": "scale(1, 1) translate(-10px, 15px)",
                      "fontSize": "18px",
                      "opacity": ".5",
                    }}></span>
                  }
                </div>
              </div>
            </div>
            {this.context.loggedUser && 
              <TimetrackWidget users={this.context.users || []} loggedUser={this.context.loggedUser}/>
            }
            {this.context.loggedUser && 
              <FlashCards loggedUser={this.context.loggedUser} />
            }
            <div className="flex-row fill">
              <div className="column">
                <div dangerouslySetInnerHTML={{ __html: this.context.sysProps.find((prop: SyspropDTO) => prop.name === 'vendorText').value}}></div>
              </div>
            </div>
            <div className="flex-row fill">
              <div className="column">
                <Holidays
                  holidays={this.props.holidays}
                />
              </div>
            </div>
            {/* <div className="flex-row fill">
              <div className="column">
                {showLoggersLeaderboard &&
                  <FastLoggers
                  theme={this.props.theme}
                  />
                }
              </div>
            </div> */}
          </div>
          <div className="column large">
            <Welcome welcomeTheme={this.props.welcomeTheme} />
            <div className="flex-row fill">
              <div className="column">
                <Vacations
                  theme={this.props.theme}
                />
              </div>
            </div>
            <div className="flex-row fill">
              <div className="column">
                <Remotes
                  theme={this.props.theme}
                />
              </div>
            </div>
          </div>
          <div className="column smaller">
            <Events
              theme={this.props.theme}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withTransitionEvent(Dashboard);
Dashboard.contextType = AppContext;