import React, { Component, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import ClientDTO from '../../../../common/api/dtos/Client';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import moment from 'moment';
import DateTCell from '../../../utils/DateTCell/DateTCell';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import { listClients } from '../../../../common/api/endpoints/clients';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import TextControl from '../../../controls/TextControl/TextControl';
import { withTransitionEvent } from '../../../TransitionEvent';

interface State {
  clients?: ClientDTO[],
  status: TRequestStatus,
  showSensitive: boolean,
  showDisabled: boolean,
  tableSort: TableSort,
  filterValue: string,
  availableFilters: string[],
}

interface Props {}

class ClientList extends Component<Props, State> {
  publicFilters: string[] = [
    'name',
    'country',
    'contractNo',
    'expiration',
    'country'
  ]

  sensitiveFilters: string[] = [
    'company',
    'tradeNo',
    'streetAddress',
    'taxId',
    'shareCapital',
    'city',
    'state',
    'zipCode',
    'contactEmail',
    'bankAccount'
  ]

  constructor(props: Props){
    super(props);
    this.state = {
      filterValue: '',
      status: "loading",
      showSensitive: false,
      showDisabled: localStorage.getItem('showDisabledClients') === 'false' || localStorage.getItem('showDisabledClients') === null ? false : true,
      tableSort: new TableSort('name', 'asc'),
      availableFilters: this.publicFilters
    }
  }

  sensitiveTimeout: number = 0;

  toggleSensitive = (event: ChangeEvent<HTMLInputElement>) => {
    if (this.state.showSensitive)  {
      this.setState({showSensitive: false}) 
      this.setState({
        availableFilters: this.publicFilters
      })
    } else {
      this.setState({showSensitive: true});
      this.setState({
        availableFilters: [...this.publicFilters, ...this.sensitiveFilters]
      })
    }
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({showDisabled: false}) : this.setState({showDisabled: true});
  }

  fetchClients = async () => {
    this.setState({
      status: 'loading'
    });
    
    const clients = await listClients();

    this.setState({
        status: 'success',
        clients: clients,
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  componentDidMount() {
    this.fetchClients();      
  }

  componentDidUpdate(prevState: State) {
    if(this.state.showSensitive !== prevState.showSensitive) {
      if(this.state.showSensitive){
        this.sensitiveTimeout = setTimeout(() => {
          this.setState({showSensitive: false});
        }, parseInt(String(process.env.REACT_APP_SENSITIVE_DATA_TIMEOUT))) as unknown as number;
      } else {
        clearTimeout(this.sensitiveTimeout);
      }
    }
  }

  renderTHeadRow() {
    return (
      <tr className="default-data-header-row">
        <th>#</th>
        <th className="text-left">
          <SortButton 
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-left">
          <SortButton 
            column='legalName' 
            text='General'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-left">
          <SortButton 
            column='taxId' 
            text='Accounting'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='contactEmail' 
            text='Misc'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='contractNo' 
            text='Paperwork'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        {this.state.showDisabled &&
        <th className="text-center">
          <SortButton 
            column='enabled' 
            text='Enabled'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        }
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(client: ClientDTO) {
    return (
      <tr id={`client${client.id}`} key={`${client.id}`}>
        <td></td>
        <td>
          <span>{client.name}</span>
        </td>
        <td>
          <span>Company <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.legalName}</span></span><br />
          <span>Address <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.streetAddress}</span></span><br />
          <span>City <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.city}</span></span><br />
          <span>State <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.state}</span></span><br />
          <span>Zip <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.zipCode}</span></span><br />
          <span>Country <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.country}</span></span><br />
        </td>
        <td>
          <span>Tax ID <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.taxId}</span></span><br />
          <span>Share Capital <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.shareCapital}</span></span><br />
          <span>Trade No. <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.tradeNo}</span></span><br />
          <span>Bank Acc. <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.bankAccount}</span></span><br />
        </td>
        <td>
          <span>Email <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.contactEmail}</span></span><br />
        </td>
        <td>
          <span>Contract No. <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{client.contractNo}</span></span><br />
          {moment(client.expiration).isValid() ?
            <React.Fragment>
              <span>Expiration <span className={!this.state.showSensitive ? 'blurred-item text-chunk' : 'blurred-item deblur text-chunk'}>{moment(client.expiration).format('LL')}</span></span><br />
            </React.Fragment>
            :
            ''
          } 
        </td>
        {this.state.showDisabled &&
          <td className="text-center">
            {client.enabled &&
              <span className="fas fa-check"></span>
            }
          </td>
        }
        <td className="text-right">
          <GhostButtonControl to={`/client/edit/${client.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredClients: Array<ClientDTO> = getFilteredItems(this.state.filterValue, this.state.availableFilters, this.state.clients !== undefined ?  this.state.clients: []).sort(this.state.tableSort.sortByColumn).filter((client: ClientDTO) => client.enabled === true || this.state.showDisabled);

    return (
      <>
        <BreadcrumbControls
          pageTitle="Clients"
          status={this.state.status}
        />
        {this.state.clients?.length && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showSensitive"
                name="showSensitive"
                changeMethod={this.toggleSensitive}
                isChecked={this.state.showSensitive}
                labelText="Sensitive data"
                icon="fas fa-shield-alt"
                srOnly={true}
                title="Sensitive data"
              />
              <ToggleControl
                id="showDisabledClients"
                name="showDisabledClients"
                changeMethod={this.toggleDisabled}
                isChecked={this.state.showDisabled}
                labelText="Disabled"
              />
              <div className="form-group">
                <Link to={'/client/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle">
                  </span></span> <span className="text">Add client</span>
                </Link>
              </div>
            </ToolbarControls>
            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`card ${!this.state.clients ? 'loader-border' : ''}`}>
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.clients &&
                        <React.Fragment>
                          <tbody>
                            {filteredClients.map((client: ClientDTO) => this.renderTBodyRow(client))}
                          </tbody>
                          <tfoot>
                            {this.state.clients.length === 0 &&
                              <tr>
                                <th colSpan={7}>
                                  <div className="card">
                                    <p className="text-chunk">There are no clients defined. Start by <Link className="link-button" to="/client/add">adding</Link> the first one.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredClients.length} out of {this.state.clients.filter((client: ClientDTO) => client.enabled === true || this.state.showDisabled).length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withTransitionEvent(ClientList);
