import React, { Component, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import HolidayDTO from '../../../../common/api/dtos/Holiday';
import SyspropDTO from '../../../../common/api/dtos/Sysprop';
import Fetch from '../../../../common/helpers/Fetch';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import DateTCell from '../../../utils/DateTCell/DateTCell';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import { listHolidays } from '../../../../common/api/endpoints/holidays';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import TextControl from '../../../controls/TextControl/TextControl';
import { withTransitionEvent } from '../../../TransitionEvent';

export interface Props {
  sysProps?: SyspropDTO[],
}

interface State {
  holidays?: HolidayDTO[],
  status: TRequestStatus,
  tableSort: TableSort,
  showDeleted: boolean,
  filterValue: string,
}

class HolidayList extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'date',
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      status: 'idle',
      filterValue: '',
      tableSort: new TableSort('name', 'asc'),
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
    };
  }

  fetchHolidays = async () => {
    this.setState({
      status: 'loading'
    });
    
    const holidays = await listHolidays();

    this.setState({
      status: 'success',
      holidays,
    });
  }

  showDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  selectSysPropByName(haystack: SyspropDTO[], needle: string): SyspropDTO {
    let result = haystack.filter((element: SyspropDTO) => element.name === needle);
    return result.length ? result[0] : {id: -1, name: '', value: '', description: ''};
  }

  componentDidMount() {
    this.fetchHolidays();
  }

  renderTHeadRow() {
    return(
      <tr>
        <th>#</th>
        <th>
          <SortButton 
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='date' 
            text='Date'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th className="text-center">
          <SortButton 
            column='countable' 
            text='Affects paid days'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th></th>
      </tr>
    );
  }

  renderTBodyRow(holiday: HolidayDTO) {
    return(
      <tr id={`holiday${holiday.id}`} key={`${holiday.id}`}>
        <td></td>
        <td>
          <span>{holiday.name}</span>
        </td>
        <DateTCell date={holiday.date} />
        <td className="text-center">
          {holiday.countable && 
            <span className="fas fa-check"></span>
          }
        </td>
        <td className="text-right">
          <GhostButtonControl to={`/holiday/edit/${holiday.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredHolidays: Array<HolidayDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.holidays as HolidayDTO[] ? this.state.holidays as HolidayDTO[] : []).sort(this.state.tableSort.sortByColumn).filter((holiday: HolidayDTO) => holiday.deleted === this.state.showDeleted);

    return (
      <>
        <BreadcrumbControls
          pageTitle="Holidays"
          status={this.state.status}
        />
        {this.state.holidays?.length && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                placeholder="Filter"
                srOnly={true}
              />
              <ToggleControl
                id="showDeleted"
                name="showDeleted"
                changeMethod={this.showDeleted}
                isChecked={this.state.showDeleted}
                labelText="Recycle bin"
                icon="fas fa-trash-undo"
                srOnly={true}
                title="Recyle bin"
              />
              <div className="form-group">
                <Link to={'/holiday/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add holiday</span></Link>
              </div>
            </ToolbarControls>
            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                    card
                    ${!this.state.holidays ? 'loader-border' : ''}
                    ${this.state.showDeleted ? "flashing-border" : ""}
                  `}>
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.holidays &&
                        <React.Fragment>
                          <tbody>
                            {filteredHolidays.map(holiday => (
                              this.renderTBodyRow(holiday)
                            ))}
                          </tbody>
                          <tfoot>
                            {this.state.holidays?.length === 0 &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">There are no holidays defined. Start by <Link className="link-button" to="/holiday/add">adding</Link> the first one.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredHolidays.length} out of {this.state.holidays.filter((holiday: HolidayDTO) => holiday.deleted === this.state.showDeleted).length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withTransitionEvent(HolidayList);
