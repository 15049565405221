import React, { ChangeEvent, Component } from 'react';
import { Link } from 'react-router-dom';
import BadgeDTO from '../../../../common/api/dtos/Badge';
import TableSort from '../../../../common/helpers/TableSort';
import ToolbarControls from '../../../generics/Header/ToolbarControls';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ChangeSortBy } from '../../../../common/helpers/Sorting';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import SortButton from '../../../utils/SortButton/SortButton';
import { getFilteredItems } from '../../../../common/helpers/Filter';
import GhostButtonControl from '../../../controls/GhostButtonControl/GhostButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import { listBadges } from '../../../../common/api/endpoints/badges';
import Thumbnail  from '../../../utils/Thumbnail/Thumbnail';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import TextControl from '../../../controls/TextControl/TextControl';
import { withTransitionEvent } from '../../../TransitionEvent';
import { faThList } from '@fortawesome/free-solid-svg-icons';

interface State {
  badges?: BadgeDTO[],
  progress: IProgress,
  status: TRequestStatus,
  showDisabled: boolean,
  showDeleted: boolean,
  tableSort: TableSort,
  selectedUser: string,
  filterValue: string,
}

class BadgeList extends Component<{}, State> {
  availableFilters: string[] = [
    'name',
    'description',
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      status: 'loading',
      showDisabled: localStorage.getItem('showDisabledBadges') === 'false' || localStorage.getItem('showDisabledBadges') === null ? false : true,
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      tableSort: new TableSort('name', 'asc'),
      selectedUser: '',
      filterValue: '',
    }
  }

  dropdownSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      selectedUser: event.target.value,
    });
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({showDisabled: false}) : this.setState({showDisabled: true});
  }

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  fetchBadges = async () => {
    this.setState({
      status: 'loading'
    });
    
    const badges = await listBadges();

    this.setState(prevState => {
      return {
        status: 'success',
        badges: badges,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return { 
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection),
       };
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value,
    })
  }

  componentDidMount() {
    this.fetchBadges();
  }
  
  renderTHeadRow() {
    return ( 
      <tr>
        <th>#</th>
        <th>
          <SortButton
            column='name' 
            text='Name'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        <th>
          <SortButton 
            column='description' 
            text='Description'
            tableSort={this.state.tableSort}
            onClick={this.handleSortChange}
          ></SortButton>
        </th>
        {this.evalDisabled() && 
          <th className="text-center">
            <SortButton 
              column='enabled' 
              text='Enabled'
              tableSort={this.state.tableSort}
              onClick={this.handleSortChange}
              ></SortButton>
          </th>
        }
        <th></th>
      </tr>
    );
  }

  evalDisabled(): boolean {
    return this.state.showDisabled || this.state.showDeleted;
  }

  renderTBodyRow(badge: BadgeDTO) {
    return (
      <tr id={`badge${badge.id}`} key={`${badge.id}`}>
        <td></td>
        <td>
          <div className="flex-row fill">
            <div className="column flex-v-center">
              <Thumbnail
                thumbnailURL={badge.iconURL}
                thumbnailBackground={'transparent'}
                classes="release"
              />
              <span>{badge.name}</span>
            </div>
          </div>
        </td>
        <td>
          <p className="text-chunk">{badge.description}</p>
        </td>
        {this.evalDisabled() && 
        <td className="text-center">
          {badge.enabled && 
            <span className="fas fa-check"></span>
          }
        </td>
        }
        <td className="text-right">
          <GhostButtonControl to={`/badge/edit/${badge.id}`} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
        </td>
      </tr>
    );
  }

  render() {
    const filteredBadges: Array<BadgeDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.badges ? this.state.badges : []).sort(this.state.tableSort.sortByColumn).filter((deletedBadge: BadgeDTO) => deletedBadge.deleted === this.state.showDeleted).filter((badge: BadgeDTO) => badge.deleted === this.state.showDeleted && (badge.enabled === true || this.state.showDisabled || this.state.showDeleted));
    return (
      <>
        <BreadcrumbControls
          pageTitle="Badges"
          status={this.state.status}
        />
        {this.state.badges && 
          <div>
            <ToolbarControls>
              <TextControl
                label="Filter"
                placeholder="Filter"
                type="text"
                name="filterBox"
                id="filterBox"
                onChange={this.setFilterValue}
                srOnly={true}
              />
              <ToggleControl
                id="showDisabledBadges"
                name="showDisabledBadges"
                changeMethod={this.toggleDisabled}
                isChecked={this.evalDisabled()}
                isDisabled={this.state.showDeleted}
                labelText="Disabled"
              />
              <ToggleControl
                id="showDeleted"
                name="showDeleted"
                changeMethod={this.toggleDeleted}
                isChecked={this.state.showDeleted}
                labelText="Recycle bin"
              icon="fas fa-trash-undo"
              srOnly={true}
              title="Recyle bin"
              />
              <div className="form-group">
                <Link to={'/badge/add'} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add badge</span></Link>
              </div>
            </ToolbarControls>

            <div className="flex-row fill">
              <div className="column">
                <div className="tableview-component">
                  <div className={`
                      card
                      ${!this.state.badges ? 'loader-border' : ''}
                      ${this.state.showDeleted ? "flashing-border" : ""}
                    `}
                  >
                    <table>
                      <thead>
                        {this.renderTHeadRow()}
                      </thead>
                      {this.state.badges &&
                        <React.Fragment>
                          <tbody>
                          {filteredBadges.map(badge => (
                              this.renderTBodyRow(badge)
                            ))}
                          </tbody>
                          <tfoot>
                            {this.state.badges?.length === 0 &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">There are no badges defined. Start by <Link className="link-button" to="/badge/add">adding</Link> the first one.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                            {this.state.filterValue &&
                              <tr>
                                <th colSpan={8}>
                                  <div className="card">
                                    <p className="text-chunk">Showing {filteredBadges.length} out of {this.state.badges.filter((badge: BadgeDTO) => badge.deleted === this.state.showDeleted && (badge.enabled === true || this.state.showDisabled || this.state.showDeleted)).length} results.</p>
                                  </div>
                                </th>
                              </tr>
                            }
                          </tfoot>
                        </React.Fragment>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  };
}
export default withTransitionEvent(BadgeList);
