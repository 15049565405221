import Joi from "joi";
import moment from "moment";
import React, { ChangeEvent, Component } from "react";
import { NavLink, Route, RouteComponentProps } from "react-router-dom";
import { addClient, listClientDetails, updateClient } from "../../../../common/api/endpoints/clients";
import { FormErrors } from "../../../../common/data/FormErrors";
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from "../../../../common/helpers/LoadComplete";
import { processJoiError } from "../../../../common/helpers/processJoiError";
import { TRequestStatus } from "../../../../common/types/RequestStatus";
import ButtonControl from "../../../controls/ButtonControl/ButtonControl";
import TextControl from "../../../controls/TextControl/TextControl";
import ToggleControl from "../../../controls/ToggleControl/ToggleControl";
import ProjectGroupsForm from "../../../forms/ProjectGroupsForm/ProjectGroupsForm";
import ProjectListForm from "../../../forms/ProjectListForm/ProjectListForm";
import BreadcrumbControls from "../../../generics/Header/BreadcrumbControls";
import ProgressBar, { incrementProgress, IProgress } from "../../../utils/ProgressBar/ProgressBar";
import RequestStatus from "../../../utils/RequestStatus/RequestStatus";
import { withTransitionEvent } from "../../../TransitionEvent";
import DeepFlowOption from "../../../utils/DeepFlopOption/DeepFlowOption";

export interface Props {
  id: string,
}

interface FormData {
  name: string,
  legalName: string,
  tradeNo: string,
  taxId: string,
  contactEmail: string,
  streetAddress: string,
  shareCapital: string,
  bankAccount: string,
  country: string,
  city: string,
  state: string,
  zipCode: string,
  contractNo: string,
  expiration: Date | null,
  enabled: boolean,
}

interface State {
  progress: IProgress,
  clientId: number,
  formData: FormData,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  formErrors: FormErrors
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

class ClientDetail extends Component<RouteComponentProps<Props>, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    legalName: Joi.string().trim(true).required().messages(FormErrors.string),
    country: Joi.string().trim(true).required().messages(FormErrors.string),
    contractNo: Joi.string().trim(true).allow(null, ''),
    tradeNo: Joi.string().trim(true).allow(null, ''),
    taxId: Joi.string().trim(true).allow(null, ''),
    shareCapital: Joi.string().trim(true).allow(null, ''),
    streetAddress: Joi.string().trim(true).allow(null, ''),
    city: Joi.string().trim(true).allow(null, ''),
    state: Joi.string().trim(true).allow(null, ''),
    zipCode: Joi.string().trim(true).allow(null, '').messages(FormErrors.string),
    contactEmail: Joi.string().trim(true).email({ minDomainSegments: 2, tlds: false}).allow(null, '').messages(FormErrors.string),
    bankAccount: Joi.string().trim(true).allow(null, ''),
    expiration: Joi.date().allow(null, ''),
    enabled: Joi.boolean(),
  });

  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {
      clientId: parseInt(this.props.match.params.id),
      formData: {
        name: '',
        legalName: '',
        country: '',
        contractNo: '',
        tradeNo: '',
        taxId: '',
        shareCapital: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        contactEmail: '',
        bankAccount: '',
        expiration: null,
        enabled: false,
      },
      progress: {
        currentStep: 0,
        totalSteps: 0,
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addClient(this.state.formData);

    this.setState({
        formStatus: 'success',
    }, () => {
      this.props.history.push({
        pathname: '/client'
      })
    });
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await updateClient(this.state.formData, this.state.clientId);

    this.setState({
        formStatus: 'success',
    });
  }

  fetchClientDetails = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const client = await listClientDetails(this.state.clientId);
    this.setState((prevState: State) => {
      return {
        formData: {
          name: client.name,
          legalName: client.legalName,
          country: client.country,
          contractNo: client.contractNo,
          tradeNo: client.tradeNo,
          taxId: client.taxId,
          shareCapital: client.shareCapital,
          streetAddress: client.streetAddress,
          city: client.city,
          state: client.state,
          zipCode: client.zipCode,
          contactEmail: client.contactEmail,
          bankAccount: client.bankAccount,
          expiration: client.expiration,
          enabled: client.enabled,
        },
        progress: incrementProgress(prevState.progress),
        pageStatus: 'success'
      }
    });
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      if(!isEditPage(Number(this.state.clientId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {    
    if(isEditPage(Number(this.state.clientId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1,
          },
          pageStatus: 'idle'
        }
      });
      this.fetchClientDetails();
    } else {
      this.setState({
        pageStatus: 'idle',
      });
    }
  }

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value
      }
    });
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('name', ev.target.value)}
  setLegalName = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('legalName', ev.target.value)}
  setTradeNo = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('tradeNo', ev.target.value)}
  setTaxId = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('taxId', ev.target.value)}
  setShareCapital = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('shareCapital', ev.target.value)}
  setStreetAddress = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('streetAddress', ev.target.value)}
  setCity = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('city', ev.target.value)}
  setHidden = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('enabled', ev.target.checked)}
  setFormState = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('state', ev.target.value)}
  setZip = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('zipCode', ev.target.value)}
  setCountry = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('country', ev.target.value)}
  setEmail = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('contactEmail', ev.target.value)}
  setBankAccount = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('bankAccount', ev.target.value)}
  setContractNo = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('contractNo', ev.target.value)}
  setExpiration = (ev: ChangeEvent<HTMLInputElement>) => {this.updateFormData('expiration', new Date(ev.target.value))}

  inDeepFlow = () => {
    const isNested = this.getContextPanel().some(context => this.props.location.pathname.includes(context.navLink));
    return isNested;
  }

  getContextName = () => {
    const contextName = this.getContextPanel().find(context => context.navLink === this.props.location.pathname.split('/').pop())?.navName;
    return contextName ? contextName : '';
  }

  getContextPanel() {
     return [
      {
        icon: 'fal fa-suitcase',
        navLink: 'profile',
        navName: 'Client Details',
        description: 'Edit general information for this client',
        disabled: false,
        page: this.renderDetailsForm()
      },
      {
        icon: 'fal fa-rocket',
        navLink: 'projects',
        navName: 'Projects',
        description: 'Define and manage which projects for this client',
        disabled: false,
        page: (
          <React.Fragment>
            {this.state.clientId && 
              <ProjectListForm
                entityId={this.state.clientId}
                entity={'client'}
                hasFullAccess={true}
              />
            }
          </React.Fragment>
        )
      },
      {
        icon: 'fal fa-layer-group',
        navLink: 'project-groups',
        navName: 'Project Groups',
        description: 'Define and manage project groups for this client',
        disabled: false,
        page: (
          <React.Fragment>
            {this.state.clientId && 
              <ProjectGroupsForm
                entityId={this.state.clientId}
                entity={'client'}
                hasFullAccess={true}
              />
            }
          </React.Fragment>
        )
      }
     ]
  }

  renderDetailsForm() {
    return(
      <div>
        <div className="card">
          <div className="flex-row fill">
            <div className="column">
              <TextControl
                label="Name"
                type="text"
                name="name"
                id="name"
                onChange={this.setName}
                value={this.state.formData.name}
                disabled={!loadComplete(this.state.progress)}
                required={true}
                br={true}
                onBlur= {ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.name}
              />
              <TextControl
                label="Company Name"
                type="text"
                name="legalName"
                id="legalName"
                onChange={this.setLegalName}
                value={this.state.formData.legalName}
                disabled={!loadComplete(this.state.progress)}
                required={true}
                br={true}
                onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                error={this.state.formErrors.legalName}
              />
               <TextControl
                      label="Trade No."
                      type="text"
                      name="tradeNo"
                      id="tradeNo"
                      onChange={this.setTradeNo}
                      value={this.state.formData.tradeNo}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    />
                    <TextControl
                      label="Tax ID"
                      type="text"
                      name="taxId"
                      id="taxId"
                      onChange={this.setTaxId}
                      value={this.state.formData.taxId}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    />
                    <TextControl
                      label="Share Capital"
                      type="text"
                      name="shareCapital"
                      id="shareCapital"
                      onChange={this.setShareCapital}
                      value={this.state.formData.shareCapital}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    />
                    <TextControl
                      label="Street Address"
                      type="text"
                      name="streetAddress"
                      id="streetAddress"
                      onChange={this.setStreetAddress}
                      value={this.state.formData.streetAddress}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    />
                    <TextControl
                      label="City"
                      type="text"
                      name="city"
                      id="city"
                      onChange={this.setCity}
                      value={this.state.formData.city}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    />
                    <ToggleControl
                      name="enabled"
                      id="enabled"
                      isChecked={this.state.formData.enabled}
                      changeMethod={this.setHidden}
                      isDisabled={!loadComplete(this.state.progress)}
                      labelText="Enabled"
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    />
                  </div>
                  <div className="column">
                    <TextControl
                      label="State"
                      type="text"
                      name="state"
                      id="state"
                      onChange={this.setFormState}
                      value={this.state.formData.state}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    />
                    <TextControl
                      label="ZIP Code"
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      onChange={this.setZip}
                      value={this.state.formData.zipCode ? this.state.formData.zipCode : ''}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                      error={this.state.formErrors.zipCode}
                      maxLength={6}
                    />
                    <TextControl
                      label="Country"
                      type="text"
                      name="country"
                      id="country"
                      onChange={this.setCountry}
                      value={this.state.formData.country}
                      disabled={!loadComplete(this.state.progress)}
                      required={true}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                      error={this.state.formErrors.country}
                    />
                    <TextControl
                      label="Email"
                      type="text"
                      name="contactEmail"
                      id="contactEmail"
                      onChange={this.setEmail}
                      value={this.state.formData.contactEmail}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                      error={this.state.formErrors.contactEmail}
                    />
                    <TextControl
                      label="Bank Account"
                      type="text"
                      name="bankAccount"
                      id="bankAccount"
                      onChange={this.setBankAccount}
                      value={this.state.formData.bankAccount}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    />
                    <TextControl
                      label="Contract No."
                      type="text"
                      name="contractNo"
                      id="contractNo"
                      onChange={this.setContractNo}
                      value={this.state.formData.contractNo}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    />
                    <TextControl
                      label="Expiration Date"
                      type="date"
                      name="expiration"
                      id="expiration"
                      onChange={this.setExpiration}
                      value={moment(this.state.formData.expiration).format('Y-MM-DD')}
                      disabled={!loadComplete(this.state.progress)}
                      br={true}
                      onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
                    />
            </div>
          </div>
          <div className="form-group">
            <ButtonControl
              class="primary-button"
              onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                this.handleSubmit(ev)
              }}
              disabled={!loadComplete(this.state.progress)}
            >
              <RequestStatus status={this.state.formStatus} />
              <span className="text">Save</span>
            </ButtonControl>
          </div>
        </div>
      </div>
    );
  }

  renderEntityHeaders() {
    return(
      <div className="card-list-component flex-row flex-v-center">
        <div className="column">
          <div className="card transparent">
            <div className="text-container story">
              <span className="primary-title">{this.state.formData.legalName}</span><br />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card transparent">
            <div className="text-container story">
              <span>{this.state.formData.streetAddress}</span><br />
              <span>{this.state.formData.state}{this.state.formData.state && this.state.formData.country ? ", " : ""}{this.state.formData.country}</span><br />
              <span>{this.state.formData.zipCode}</span><br />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card transparent">
            <div className="text-container story">
              <span>{this.state.formData.tradeNo}</span><br />
              <span>{this.state.formData.taxId}</span><br />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render () {
    let entityName = this.state.formData.name ? this.state.formData.name : 'loading';
    return (
     <div>
      {this.inDeepFlow() === false ? 
      <BreadcrumbControls
        items={[
          {label: 'clients', path: '/client'},
          {label: `${!isEditPage(Number(this.state.clientId)) ? 'adding client ' : ''}${this.state.formData.name}`},
        ]}
      />
      :
        <BreadcrumbControls
          items={[
            {label: 'clients', path: '/client'},
            {label: entityName ? entityName : '', path: `/client/edit/${this.state.clientId}`},
            {label: this.getContextName()},
          ]}
        />
      }
      <div className="flex-row tightest-top">
        <div className="column large">
          <div className="flex-row">
            <div className="column">
              <ProgressBar 
                currentStep={this.state.progress.currentStep}
                totalSteps={this.state.progress.totalSteps}
              />
            </div>
          </div>
          {isEditPage(Number(this.state.clientId)) ?
            <>
              {this.renderEntityHeaders()}
              {this.getContextPanel().map((context, index) => {
                return(
                  this.inDeepFlow() ?
                    <Route
                      path={`${this.props.match.url}/${context.navLink}`}
                      render={() => context.page}
                    />
                  :
                    <DeepFlowOption
                      baseURL={this.props.match.url}
                      context={context}
                      index={index}
                    />
                )
              })}
            </>
          :
            this.renderDetailsForm()
          }
        </div>
      </div>
    </div>
    )
  }
}

export default withTransitionEvent(ClientDetail);
