import draftToHtml from "draftjs-to-html";

export function RichTextContent(data: string) {
  let parsed;
  try {
    parsed = JSON.parse(data);
  }
  catch (error) {
    return { "entityMap": {}, "blocks": [] }
  }
  return parsed;
}

export function RichTextHTML(data: string) {
  let parsed;
  try {
    parsed = JSON.parse(data);
  }
  catch (error) {
    return "";
  }
  return draftToHtml(parsed);
}
