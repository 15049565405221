import { override } from "joi";
import Fetch from "../../helpers/Fetch";
import ElectricityUtilDTO, { ElectricityUtilPayload } from "../dtos/ElectricityUtil";

export async function listElectricityUtils(): Promise<ElectricityUtilDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ElectricityUtilDTO[]>(`/electricities`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    const payloadWithDateObj = fetchRequest.getPayload().map(el => ({
      ...el,
      date: new Date(el.year, el.month - 1, 1),
    }));
    
    return payloadWithDateObj;
  }
  throw new Error('Failed to fetch electricityUtils');
}

export async function listElectricityUtilDetails(electricityUtilId: string): Promise<ElectricityUtilDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ElectricityUtilDTO>(`/electricity/${electricityUtilId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return { ...fetchRequest.getPayload(), month: fetchRequest.getPayload().month - 1 }
  }
  throw new Error('Failed to fetch electricityUtil details');
}

export async function addElectricityUtil(form: ElectricityUtilPayload): Promise<ElectricityUtilDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ElectricityUtilDTO>(`/electricity`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      ...form,
      month: form.month + 1
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add electricityUtil`);
}

export async function updateElectricityUtil(form: ElectricityUtilPayload, electricityUtilId: string): Promise<ElectricityUtilDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<ElectricityUtilDTO>(`/electricity/${electricityUtilId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      ...form,
      month: form.month + 1
    })
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update electricityUtil`);
}