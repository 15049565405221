import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import hasAccess from '../../../common/helpers/Access';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import Thumbnail  from '../../utils/Thumbnail/Thumbnail'
import GhostButtonControl from '../../controls/GhostButtonControl/GhostButtonControl';
import DropdownOption from '../../utils/DropdownOption/DropdownOption';
import NavElement from '../../utils/NavElement/NavElement';
import ButtonControl from '../../controls/ButtonControl/ButtonControl';
import AppContext from '../../../common/context/AppContext';

export interface Props {
  handleLogout: () => void,
  setMobileNavOpen: () => void,
  loggedUser?: LoggedUser
}

interface State {
  tenantContext: string,
  tenantContextOpen: boolean,
}

class Topbar extends Component<Props, State> {
  context!: React.ContextType<typeof AppContext>;
  tenantContextRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.tenantContextRef = React.createRef();
    this.state = {
      tenantContext: localStorage.getItem(this.props.loggedUser?.id + "tenantContext") as string || 'webwavers',
      tenantContextOpen: false,
    }
  }
  
  openTenantContext = () => {
    this.setState({
        tenantContextOpen: true
    });
  }

  closeTenantContext = () => {
    this.setState((prevState: State) => {
      return {
        tenantContextOpen: false
      }
    });
  }

  toggleMobileNav = () => {
    this.setState(prevState => {
      return {
        tenantContextOpen: !prevState.tenantContextOpen
      }
    }, () => {
      if (this.context.mobileNavOpen) {
        this.props.setMobileNavOpen();
      }
    })
  }

  setTenantContext = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newContext: string): void => {
    event.stopPropagation();
    if(!this.state.tenantContextOpen) {
      this.openTenantContext();
    } else {
      this.setState({
        tenantContextOpen: false,
        tenantContext: newContext,
      }, () => {
        localStorage.setItem(this.props.loggedUser?.id + "tenantContext", this.state['tenantContext']);
      });
    }
  }

  setTenantContextRef = (node: React.RefObject<HTMLDivElement>) => {
    this.tenantContextRef = node;
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.tenantContextRef && !this.tenantContextRef.current?.contains(event.target as HTMLElement)) {
      this.closeTenantContext();
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    return (
      <>
        <div
          id="toolbarControls"
          className="toolbar-component bar-content-start"
        >
          <div className="form-group show-tablet">
            <ButtonControl
              class="ghost-button"
              disabled={false}
              onClick={this.props.setMobileNavOpen}
              type="button">
                <span className="fas fa-bars"></span>
            </ButtonControl>
          </div>
        </div>
        <div className="bar-content-end">
          {process.env.REACT_APP_SHOW_DISCLAIMER === "true" &&
            <small className="negative-text" style={{"maxWidth": "600px", "paddingLeft": "8px"}}>
              {process.env.REACT_APP_DATA_DISCLAIMER_TEXT}
            </small>
          }
          <nav className="navigation-component horizontal">
            <ul className="nav-list">
              <li className="nav-li">
                <NavLink
                  to="/account"
                  className="nav-link"
                  activeClassName="active"
                  id="accountButton"
                  onClick={this.toggleMobileNav}
                >
                  <div className="content-wrapper">
                    <Thumbnail
                      classes="medium icon"
                      avatarData={this.props.loggedUser?.avatar}
                    />
                    <span>{this.props.loggedUser?.name}</span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-li">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/app-settings"
                    id="settingsButton"
                    onClick={this.toggleMobileNav}
                  >
                      <div className="content-wrapper squared">
                          <img className="icon" src="/assets/icons/settings-100.png" alt="icon" />
                      </div>
                  </NavLink>
              </li>
              <li className="nav-li">
                  <button
                    className="nav-link"
                    onClick={this.props.handleLogout}
                    id="logoutButton"
                  >
                        <div className="content-wrapper squared">
                            <span className="icon fas fa-power-off"></span>
                        </div>
                  </button>
              </li>  
            </ul>
          </nav>
        </div>
      </>
    )
  }
}

export default Topbar;
Topbar.contextType = AppContext;