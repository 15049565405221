import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import UserDTO from '../../../common/api/dtos/User';
import AppContext from '../../../common/context/AppContext';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import ToggleControl from '../../controls/ToggleControl/ToggleControl';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import SelectControl from '../../controls/SelectControl/SelectControl';
import { withTransitionEvent } from '../../TransitionEvent';
import SyspropDTO from '../../../common/api/dtos/Sysprop';
import Welcome, { WelcomeTheme } from '../../widgets/Welcome/Welcome';

interface Form {
  currentPassword: string,
  newPassword: string,
  confirmPassword: string,
}

interface RequestPayload {
  statusCode: number,
  message: string,
}

interface State {
  showClockPacific: boolean,
  showClockCentral: boolean,
  showClockEastern: boolean,
  showClockWET: boolean,
  showLoggersLeaderboard: boolean,
  showWeather: boolean,
  form: Form,
  formStatus: TRequestStatus,
  invalidPasswordMatch: boolean,
  formPayload: RequestPayload,
  status: TRequestStatus,
}

export interface Props {
  user?: UserDTO,
  loggedUser?: LoggedUser,
  setWelcome: (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, selectedWelcome: number | string | undefined | {value: string, label: string}) => void,
  setTheme: (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, selectedTheme: number | string | undefined | {value: string, label: string}) => void,
  setAccent: (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, selectedAccent: number | string | undefined | {value: string, label: string}) => void,
  setMood: (moodState: string) => void,
}

const accentOptions = [
                      {value: "", label: "⚪ Default"},
                      {value: "blue-accent", label: "🔵 Blue"},
                      {value: "green-accent", label: "🟢 Green"},
                      {value: "purple-accent", label: "🟣 Purple"},
                      {value: "yellow-accent", label: "🟡 Yellow"},
                      {value: "red-accent", label: "🔴 Red"}
                    ];
const themeOptions = [
                      {value: "system", label: "System Preference"},
                      {value: "light-theme", label: "Light Mode"},
                      {value: "dark-theme", label: "Dark Mode"}
                    ];

class AppSettings extends Component<Props, State> {
  context!: React.ContextType<typeof AppContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      showClockPacific: localStorage.getItem(this.props.loggedUser?.id + 'showClockPacific') === 'false' ? false : true,
      showClockCentral: localStorage.getItem(this.props.loggedUser?.id + 'showClockCentral') === 'false' ? false : true,
      showClockEastern: localStorage.getItem(this.props.loggedUser?.id + 'showClockEastern') === 'false' ? false : true,
      showClockWET: localStorage.getItem(this.props.loggedUser?.id + 'showClockWET') === 'false' ? false : true,
      showLoggersLeaderboard: localStorage.getItem(this.props.loggedUser?.id + 'showLoggersLeaderboard') === 'false' ? false : true,
      showWeather: localStorage.getItem(this.props.loggedUser?.id + 'showWeather') === 'false' ? false : true,
      form: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      status: 'loading',
      invalidPasswordMatch: true,
      formStatus: 'idle',
      formPayload: {
        statusCode: -1,
        message: '',
      },
    }
  }

  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.target.name]: event.target.checked,
    } as any);
    localStorage.setItem(this.props.loggedUser?.id + event.target.name, JSON.stringify(event.target.checked));
  }

  getWelcomeThemes = () => {
    const welcomeThemes = JSON.parse((this.context.sysProps?.find(sysProp => sysProp.name === 'welcomeThemes') as SyspropDTO).value)
                                                            .map((theme: WelcomeTheme) => {
                                                              if (theme.name === 'default') return {name: 'moment-of-day', label: 'Moment Of Day'}

                                                              return {
                                                                value: theme.name,
                                                                label: theme.name.split('-')
                                                                                 .map((substring: string) => substring.replace(/^./, substring[0].toUpperCase()))
                                                                                 .join(" ")
                                                              };
                                                            });

    return welcomeThemes;
  }

  render() {
    const moodState = localStorage.getItem(this.props.loggedUser?.id + 'showMood') == 'true';
    const themeState = localStorage.getItem(this.props.loggedUser?.id + 'theme') ? localStorage.getItem(this.props.loggedUser?.id + 'theme') : 'system';
    const welcomeState = localStorage.getItem(this.props.loggedUser?.id + 'welcome') == null ? 'moment-of-day' : localStorage.getItem(this.props.loggedUser?.id + 'welcome');
    const welcomeOptions = this.getWelcomeThemes();

    return (
      <div>
        <BreadcrumbControls
          items={[
            {label: 'Settings'}
          ]}
        />
        <div className="flex-row tightest-top">
          <div className="column large">
            <div className="flex-row tightest-top fill">
              <div className="column">
                <div className="flex-row squeeze">
                  <div className="column">
                    <SelectControl
                      idName="toggle-accent"
                      label="Color"
                      value={localStorage.getItem(this.props.loggedUser?.id + 'accent') == null ? "" : localStorage.getItem(this.props.loggedUser?.id + 'accent')!}
                      options={accentOptions}
                      onChange={this.props.setAccent}
                      getValue={(op) => op.value}
                      getLabel={(op) => op?.label}
                    />
                  </div>
                  <div className="column">
                    <SelectControl
                      idName="toggle-theme"
                      label="Theme"
                      value={themeState!}
                      disabled={false}
                      options={themeOptions}
                      onChange={this.props.setTheme}
                      getValue={(op) => op.value}
                      getLabel={(op) => op?.label}
                    />
                  </div>
                  <div className="column hidden-mobile">
                    <div className="form-group">
                      <SelectControl
                        idName="toggle-welcome"
                        label="Home Background"
                        value={welcomeState!} 
                        options={welcomeOptions}
                        onChange={this.props.setWelcome}
                        getValue={(op) => op.value}
                        getLabel={(op) => op?.label}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-row tightest-top fill">
                  <div className="column">
                    <ToggleControl
                      id="showMood"
                      name="showMood"
                      changeMethod={(event: ChangeEvent<HTMLInputElement>) => this.props.setMood(event.target.checked.toString())}
                      isChecked={moodState}
                      multiline={true}
                      tight={true}
                      >
                      <label htmlFor="showMood">
                        <span>Show mood</span>
                        <small className="faint-text">Uses your accent to give the app a colorful mood</small>
                        <span className="state">{moodState ? 'On' : 'Off'}</span>
                      </label>
                    </ToggleControl>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="column medium">
                    <div className="card" style={{pointerEvents: 'none'}}>
                      <div className="card flashing-border">
                        <p><small>Use this box to preview how your interface will look based on your configuration.</small></p>
                        <div className="flex-row flex-v-center squeeze">
                          <div className="column">
                            <button tabIndex={-1} className="primary-button">Button</button>
                          </div>
                          <div className="column">
                            <div className="toggle">
                              <input tabIndex={-1} checked type="checkbox" />
                              <label>Toggle</label>
                            </div>
                          </div>
                        </div>
                        <div className="holiday-widget-component"><ul><li className="next">Pipe indicator</li></ul></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-row fill">
              <div className="column">
                <ToggleControl
                  id="showClockPacific"
                  name="showClockPacific"
                  changeMethod={this.handleInput}
                  isChecked={this.state.showClockPacific}
                  multiline={true}
                  classes="hidden-mobile"
                  tight={true}
                >
                  <label htmlFor="showClockPacific">
                    <span>Show Pacific Time (PT)</span>
                    <small className="faint-text">Displays a clock on homepage</small>
                    <span className="state">{this.state.showClockPacific ? 'On' : 'Off'}</span>
                  </label>
                </ToggleControl>
                <ToggleControl
                  id="showClockCentral"
                  name="showClockCentral"
                  changeMethod={this.handleInput}
                  isChecked={this.state.showClockCentral}
                  multiline={true}
                  classes="hidden-mobile"
                  tight={true}
                >
                  <label htmlFor="showClockCentral">
                    <span>Show Central Time (CT)</span>
                    <small className="faint-text">Displays a clock on homepage</small>
                    <span className="state">{this.state.showClockCentral ? 'On' : 'Off'}</span>
                  </label>
                </ToggleControl>
                <ToggleControl
                  id="showClockEastern"
                  name="showClockEastern"
                  changeMethod={this.handleInput}
                  isChecked={this.state.showClockEastern}
                  multiline={true}
                  classes="hidden-mobile"
                  tight={true}
                >
                    <label htmlFor="showClockEastern">
                    <span>Show Eastern Time (ET)</span>
                    <small className="faint-text">Displays a clock on homepage</small>
                    <span className="state">{this.state.showClockEastern ? 'On' : 'Off'}</span>
                  </label>
                </ToggleControl>
                {/* <ToggleControl
                  id="showClockWET"
                  name="showClockWET"
                  changeMethod={this.handleInput}
                  isChecked={this.state.showClockWET}
                  multiline={true}
                  tight={true}
                >
                    <label htmlFor="showClockWET">
                    <span>Show Western European Time (WET)</span>
                    <small className="faint-text">Displays a clock on homepage</small>
                    <span className="state">{this.state.showClockWET ? 'On' : 'Off'}</span>
                  </label>
                </ToggleControl> */}
                {/* <ToggleControl
                  id="showLoggersLeaderboard"
                  name="showLoggersLeaderboard"
                  changeMethod={this.handleInput}
                  isChecked={this.state.showLoggersLeaderboard}
                  multiline={true}
                  tight={true}
                >
                    <label htmlFor="showLoggersLeaderboard">
                    <span>Show fast loggers</span>
                    <small className="faint-text">Displays leaderboard for the fastest loggers</small>
                    <span className="state">{this.state.showLoggersLeaderboard ? 'On' : 'Off'}</span>
                  </label>
                </ToggleControl> */}
                <ToggleControl
                  id="showWeather"
                  name="showWeather"
                  changeMethod={this.handleInput}
                  isChecked={this.state.showWeather}
                  multiline={true}
                  classes="hidden-mobile"
                  tight={true}
                >
                    <label htmlFor="showWeather">
                    <span>Show weather</span>
                    <small className="faint-text">Displays a weather widget on homepage</small>
                    <span className="state">{this.state.showWeather ? 'On' : 'Off'}</span>
                  </label>
                </ToggleControl>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(AppSettings);
AppSettings.contextType = AppContext; 
