import React, { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import WorkProfileDTO from "../../../common/api/dtos/WorkProfile";
import { listContractWorkProfiles } from "../../../common/api/endpoints/workProfiles";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import TextControl from "../../controls/TextControl/TextControl";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import GhostButtonControl from "../../controls/GhostButtonControl/GhostButtonControl";
import SortButton from "../../utils/SortButton/SortButton";
import ToolbarControls from "../../generics/Header/ToolbarControls";
import moment from "moment-timezone";
import DateTCell from "../../utils/DateTCell/DateTCell";

export interface Props {
  entity?: string,
  entityId?: string,
  subentity?: string,
  subentityId?: string,
}

interface State {
  userPropId: number | undefined | null,
  status: TRequestStatus,
  formStatus: TRequestStatus,
  workProfiles?: WorkProfileDTO[],
  tableSort: TableSort,
  showDeleted: boolean,
  filterValue: string,
}

class WorkProfilesForm extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'description',
    'hourNorm',
    'startDate',
    'clockIn',
    'clockOut',
  ]

  tableHeaderColumns = [
    { property: '#', text: '#' },
    { property: 'name', text: 'Name' },
    { property: 'description', text: 'Description' },
    { property: 'hourNorm', text: 'Hour Norm' },
    { property: 'startDate', text: 'Start Date' },
    { property: 'hasTickets', text: 'Has Tickets' },
    { property: 'clockIn', text: 'Clock In' },
    { property: 'clockOut', text: 'Clock Out' },
    { property: '', text: '' },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      formStatus: 'idle',
      userPropId: undefined,
      tableSort: new TableSort('startDate', 'asc'),
      showDeleted: localStorage.getItem('showDeleted') === 'false' || localStorage.getItem('showDeleted') === null ? false : true,
      filterValue: '',
    }
  }

  fetchWorkProfiles = async () => {
    this.setState({
      status: 'loading'
    });
    
    const workProfiles = await listContractWorkProfiles(Number(this.props.subentityId ? this.props.subentityId : this.props.entityId));

    this.setState({
        status: 'success',
        workProfiles,
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  componentDidMount() {
    this.fetchWorkProfiles();
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  showDeleted = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDeleted ? this.setState({showDeleted: false}) : this.setState({showDeleted: true});
  }

  buildAddLink() {
    return this.props.subentity ? `/${this.props.entity}/deep/${this.props.entityId}/${this.props.subentity}/deep/${this.props.subentityId}/work-profile/add` : `/${this.props.entity}/deep/${this.props.entityId}/work-profile/add`;
  }

  buildEditLink(id: number) {
    return this.props.subentity ? `/${this.props.entity}/deep/${this.props.entityId}/${this.props.subentity}/deep/${this.props.subentityId}/work-profile/edit/${id}` : `/${this.props.entity}/deep/${this.props.entityId}/work-profile/edit/${id}`;
  }

  renderTHeadRow() {
    return (
      <tr>
        {this.tableHeaderColumns.map((cell, index) => (
          <th key={index} className={`text-${cell.property ? 'left' : 'center'}`}>
            {cell.property ? (
              <SortButton
                column={cell.property}
                text={cell.text}
                tableSort={this.state.tableSort}
                onClick={this.handleSortChange}
              />
            ) : (
              cell.text
            )}
          </th>
        ))}
      </tr>
    );
  }

  render() {
    const filteredWorkProfiles: Array<WorkProfileDTO> = getFilteredItems(this.state.filterValue, this.availableFilters, this.state.workProfiles ? this.state.workProfiles : []).sort(this.state.tableSort.sortByColumn).filter((workProfile: WorkProfileDTO) => workProfile.deleted === this.state.showDeleted);
    return (
      <div>
        <ToolbarControls>
          <TextControl
            label="Filter"
            type="text"
            name="filterBox"
            id="filterBox"
            onChange={this.setFilterValue}
            placeholder="Filter"
            srOnly={true}
          />
          <ToggleControl
            id="showDeleted"
            name="showDeleted"
            changeMethod={this.showDeleted}
            isChecked={this.state.showDeleted}
            labelText="Recycle bin"
            icon="fas fa-trash-undo"
            srOnly={true}
            title="Recyle bin"
          />
          <div className="form-group">
            <Link to={this.buildAddLink()} className="primary-button"><span className="static-icon"><span className="fas fa-plus-circle"></span></span> <span className="text">Add work profile</span></Link>
          </div>
        </ToolbarControls>
        <div className={`card ${!this.state.workProfiles ? 'loader-border' : ''} ${this.state.showDeleted ? "flashing-border" : ""}`}>
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 315px)'}}>
                  <table>
                    <thead>
                      {this.renderTHeadRow()}
                    </thead>
                    {/* <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <SortButton
                            column='id' 
                            text='Id'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th></th>
                      </tr>
                    </thead> */}
                    {this.state.workProfiles &&
                      <React.Fragment>
                        <tbody>
                          {filteredWorkProfiles.map(workProfile => (
                            <React.Fragment key={`${workProfile.id}`}>
                              <tr id={`workProfile${workProfile.id}`}>
                                <td></td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center text-chunk">
                                      {workProfile.name}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center text-chunk">
                                      {workProfile.description}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center">
                                      {workProfile.hourNorm}
                                    </div>
                                  </div>
                                </td>
                                <DateTCell date={workProfile.startDate}/>
                                <td className="text-center">
                                  {workProfile.hasTickets && 
                                    <span className="fas fa-check"></span>
                                  }
                                </td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center">
                                      {workProfile.clockIn}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center">
                                      {workProfile.clockOut}
                                    </div>
                                  </div>
                                </td>
                                <td className="text-right">
                                  <GhostButtonControl to={this.buildEditLink(workProfile.id)} class="ghost-button" icon="fas fa-angle-right"></GhostButtonControl>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.workProfiles?.length === 0 &&
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  <p className="text-chunk">There are no workProfiles defined for this contract. Start by <Link className="link-button" to={this.buildAddLink()}>adding</Link> the first one.</p>
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={12}>
                                <div className="card">
                                  <p className="text-chunk">Showing {filteredWorkProfiles.length} out of {this.state.workProfiles.filter((workProfile: WorkProfileDTO) => workProfile.deleted === this.state.showDeleted).length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkProfilesForm;