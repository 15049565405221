import Joi from 'joi';
import moment from 'moment';
import React, { Component, ChangeEvent, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import { addWaterUtil, listWaterUtilDetails, updateWaterUtil } from '../../../../common/api/endpoints/waterUtils';
import { FormErrors } from '../../../../common/data/FormErrors';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import ToggleControl from '../../../controls/ToggleControl/ToggleControl';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import ProgressBar from '../../../utils/ProgressBar/ProgressBar.lazy';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TextControl from '../../../controls/TextControl/TextControl';
import RoleDTO from '../../../../common/api/dtos/Role';
import { listRoles } from '../../../../common/api/endpoints/roles';
import { WaterUtilPayload } from '../../../../common/api/dtos/WaterUtil';
import TextAreaControl from '../../../controls/TextAreaControl/TextAreaControl';
import { withTransitionEvent } from '../../../TransitionEvent';
import SelectControl from '../../../controls/SelectControl/SelectControl';
import { Month, Months } from '../../../../common/data/Months';
import AppContext from '../../../../common/context/AppContext';
import { extractYears } from '../../../generics/Invoices/utils';

interface RouteParams {
  id?: string,
}

interface FormData extends WaterUtilPayload {}

interface State {
  waterUtilId?: string,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  clientStatus: TRequestStatus,
  roles?: RoleDTO[],
  formData: FormData,
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class WaterUtilDetail extends Component<RouteComponentProps<RouteParams>, State> {
  formSchema = Joi.object({
    code: Joi.string().trim(true).required().messages(FormErrors.string),
    year: Joi.number().required(),
    month: Joi.number().required(),
    description: Joi.string().allow(''),
    quantity: Joi.number().required().messages(FormErrors.number),
    amount: Joi.number().required().messages(FormErrors.number),
    deleted: Joi.boolean(),
  });

  now = new Date();

  constructor(props: RouteComponentProps<RouteParams>) {
    super(props);
    this.state = {
      waterUtilId: this.props.match.params.id,
      formData: {
        code: '',
        description: '',
        year: this.now.getFullYear(),
        month: new Date().getMonth(),
        quantity: 0,
        amount: 0,
        deleted: false,
      },
      progress: {
        currentStep: 0,
        totalSteps: 1,
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      clientStatus: 'idle',
      formErrors: {},
    }
  }

  fetchWaterUtilDetails = async () => {
    if(this.state.waterUtilId) {
    this.setState({
      pageStatus: 'loading'
    });
    
      const waterUtil = await listWaterUtilDetails(this.state.waterUtilId);
      this.setState(prevState => {
        return {
          formData: {
            year: waterUtil.year,
            month: waterUtil.month,
            code: waterUtil.code,
            description: waterUtil.description,
            quantity: waterUtil.quantity,
            amount: waterUtil.amount,
            deleted: waterUtil.deleted,
          },
          progress: incrementProgress(prevState.progress),
          pageStatus: 'success',
        }
      });
    }
  }

  fetchRoles = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const roles = await listRoles();

    this.setState(prevState => {
      return {
        pageStatus: 'success',
        roles: roles,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  private setRoles = (e: ChangeEvent<HTMLSelectElement>) => {
    const roleOptions = Array.from(e.target.options);
    const selectedRoles = roleOptions.filter(o => o.selected).map(o => o.value);

    this.setState({
      formData: {
        ...this.state.formData,
      },
    });
  }
  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addWaterUtil(this.state.formData);

    this.setState({
        formStatus: 'success',
    }, () => {
      this.props.history.push({
        pathname: '/water'
      })
    });
  }

  updateEntity = async () => {
    if(this.state.waterUtilId) {
      this.setState({
        formStatus: 'loading'
      });
      await updateWaterUtil(this.state.formData, this.state.waterUtilId);
  
      this.setState({
          formStatus: 'success',
      });

    }
  }

  handleSubmit = async (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();
    
    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
  
      if(!isEditPage(Number(this.state.waterUtilId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });
    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }

  componentDidMount() {
    this.fetchRoles();
    if(isEditPage(Number(this.state.waterUtilId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 2,
          }
        }
      });
        this.fetchWaterUtilDetails();
    }
  } 

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      }
    });
  }

  setMonth = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, month: number | string | undefined | Month) => this.updateFormData('month', Number(month));
  setYear = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, yearNo: number | string | undefined) => this.updateFormData('year', Number(yearNo));
  setInvoiceCode = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('code', ev.target.value);
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);
  setQuantity = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('quantity', Number(ev.target.value));
  setAmount = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('amount', Number(ev.target.value));
  setDeleted = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('deleted', ev.target.checked);

  renderWaterUtilEditCard() {
    return(
      <div className="card">
        <TextControl
          label="Invoice Code"
          type="text"
          name="code"
          id="invoiceCode"
          onChange={this.setInvoiceCode}
          value={this.state.formData.code}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.code}
        />
        <SelectControl
          idName="year"
          label="Year"
          required={true}
          value={this.state.formData.year}
          options={extractYears(this.context.sysProps)}
          disabled={!loadComplete(this.state.progress)}
          onChange={this.setYear}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.year}
        />
        <SelectControl
          idName="month"
          label="Month"
          required={true}
          value={this.state.formData.month}
          options={Months}
          onChange={this.setMonth}
          getValue={(op) => op.number}
          getLabel={(op) => op?.name}
          onBlur={ev => this.validateFormField(ev.target.name  as keyof FormData)}
          error={this.state.formErrors.month}
        />
        <TextAreaControl
          label="Description"
          id="description"
          name="description"
          value={this.state.formData.description}
          onChange={this.setDescription}
          disabled={!loadComplete(this.state.progress)}
          rows={3}
          cols={30}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
        />
        <TextControl
          label="Quantity"
          type="number"
          name="quantity"
          id="quantity"
          onChange={this.setQuantity}
          value={this.state.formData.quantity}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.quantity}
        />
        <TextControl
          label="Amount"
          type="number"
          name="amount"
          id="amount"
          onChange={this.setAmount}
          value={this.state.formData.amount}
          disabled={!loadComplete(this.state.progress)}
          br={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.amount}
        />
        {isEditPage(Number(this.state.waterUtilId)) &&
          <ToggleControl
            id="deleted"
            name="deleted"
            changeMethod={this.setDeleted}
            isChecked={this.state.formData.deleted}
            isDisabled={!loadComplete(this.state.progress)}
            labelText="Deleted"
          />
        }
        <div className="form-group">
          <ButtonControl
            class="primary-button"
            disabled={!loadComplete(this.state.progress)}
            onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              this.handleSubmit(ev)
            }}
          >
            <RequestStatus status={this.state.formStatus} />
            <span className="text">Save</span>
          </ButtonControl>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <BreadcrumbControls
          items={[
            {label: 'Water', path: '/water'},
            {label: `${!isEditPage(Number(this.state.waterUtilId)) ? 'adding' : 'editing'} water record ${this.state.formData.code}`},
          ]}
          status={this.state.pageStatus}
        />
          <div className="flex-row">
            <div className="column">
              <div className="flex-row tightest-top">
                <div className="column">
                  <ProgressBar
                    currentStep={this.state.progress.currentStep}
                    totalSteps={this.state.progress.totalSteps}
                  />
                </div>
              </div>
              {this.renderWaterUtilEditCard()}
            </div>
          </div>
      </React.Fragment>
    )
  }
}

export default withTransitionEvent(WaterUtilDetail);
WaterUtilDetail.contextType = AppContext;
