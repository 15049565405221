import Joi from 'joi';
import React, { ChangeEvent, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import SyspropDTO from '../../../../common/api/dtos/Sysprop';
import { addSysprop, listSysPropDetails, updateSysprop } from '../../../../common/api/endpoints/sysprops';
import { FormErrors } from '../../../../common/data/FormErrors';
import Fetch from '../../../../common/helpers/Fetch';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import ButtonControl from '../../../controls/ButtonControl/ButtonControl';
import BreadcrumbControls from '../../../generics/Header/BreadcrumbControls';
import ProgressBar, { IProgress, incrementProgress } from '../../../utils/ProgressBar/ProgressBar';
import RequestStatus from '../../../utils/RequestStatus/RequestStatus';
import { isEditPage } from '../../../../common/helpers/isEditPage';
import TextAreaControl from '../../../controls/TextAreaControl/TextAreaControl';
import { loadComplete } from '../../../../common/helpers/LoadComplete';
import TextControl from '../../../controls/TextControl/TextControl';
import HTMLControl from '../../../controls/HTMLControl/HTMLControl';
import { ContentState, RawDraftContentState } from 'draft-js';
import { withTransitionEvent } from '../../../TransitionEvent';

export interface Props {
  id: string,
}

interface FormData {
  name: string,
  value: string,
  description: string,
}

interface State {
  syspropId: string,
  progress: IProgress,
  pageStatus: TRequestStatus,
  formStatus: TRequestStatus,
  formData: FormData,
  formErrors: FormErrors,
}

type FormErrors = {
  [key in keyof FormData]?: string;
}

class SysPropDetail extends Component<RouteComponentProps<Props>, State> {
  formSchema = Joi.object({
    name: Joi.string().trim(true).required().messages(FormErrors.string),
    value: Joi.string().trim(true).allow(null, ''),
    description: Joi.string().trim(true).required().messages(FormErrors.string),
  });

  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {
      syspropId: this.props.match.params.id,
      formData: {
        name: '',
        value: '',
        description: '',
      },
      progress: {
        currentStep: 0,
        totalSteps: 0,
      },
      pageStatus: 'loading',
      formStatus: 'idle',
      formErrors: {},
    }
  }

  fetchSysPropDetails = async () => {
    this.setState({
      pageStatus: 'loading'
    });
    
    const sysprop = await listSysPropDetails(this.state.syspropId);

    this.setState(prevState => {
      return {
        formData: {
          name: sysprop.name,
          value: sysprop.value,
          description: sysprop.description,
        },
        progress: incrementProgress(prevState.progress),
        pageStatus: 'success',
      }
    });
  }

  addEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await addSysprop(this.state.formData);

    this.setState({
        formStatus: 'success',
    }, () => {
      this.props.history.push({
        pathname: '/sysprop'
      })
    });
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });
    await updateSysprop(this.state.formData, this.state.syspropId);

    this.setState({
        formStatus: 'success',
    });
  }

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });
      
      if(!isEditPage(Number(this.state.syspropId))) {
        this.addEntity();
      } else {
        this.updateEntity();
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false});
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  }
  
  componentDidMount() {
    if(isEditPage(Number(this.state.syspropId))) {
      this.setState((prevState: State) => {
        return {
          progress: {
            currentStep: prevState.progress.currentStep,
            totalSteps: 1,
          }
        }
      });
      this.fetchSysPropDetails();
    } else {
      this.setState({
        pageStatus: "success",
      });
    }
  } 

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        }
      }
    })
  }

  updateFormData<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      },
    });
  }

  renderPropsEditCard() {
    return(
      <div className="card">
        <TextControl
          label="Name"
          type="text"
          name="name"
          id="name"
          value={this.state.formData.name}
          onChange={this.setName}
          br={true}
          disabled={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.name}
        />
        <TextAreaControl
          label="Value"
          id="value"
          name="value"
          value={this.state.formData.value}
          onChange={this.setValue}
          disabled={!loadComplete(this.state.progress)}
        />
        {/* {this.state.formData.value &&
          <HTMLControl
            id="description"
            name="description"
            value={JSON.parse(this.state.formData.value)}
            // value={{"entityMap": {}, "blocks": []}}
            onChange={this.setContentState}
          />
        }  */}
        <TextAreaControl
          label="Description"
          id="description"
          name="description"
          value={this.state.formData.description}
          onChange={this.setDescription}
          disabled={true}
          required={true}
          onBlur={ev => this.validateFormField(ev.target.name as keyof FormData)}
          error={this.state.formErrors.description}
        />
        <div className="form-group">
          <ButtonControl
            class="primary-button"
            onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              this.handleSubmit(ev)
            }}
            disabled={!loadComplete(this.state.progress)}
          >
            <RequestStatus status={this.state.formStatus} />
            <span className="text">Save</span>
          </ButtonControl>
        </div>
      </div>
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) => this.updateFormData('name', ev.target.value);
  setValue = (ev: ChangeEvent<HTMLTextAreaElement>) => {
    this.updateFormData('value', ev.target.value)
  }
  setContentState = (rawContentState: RawDraftContentState) => {
    this.updateFormData('value', JSON.stringify(rawContentState))
  }

  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) => this.updateFormData('description', ev.target.value);

  render() {
    return (
      <React.Fragment>
        <BreadcrumbControls
          items={[
            {label: 'Properties', path: '/sysprop'},
            {label: `${!isEditPage(Number(this.state.syspropId)) ? 'adding' : 'editing'} system property ${this.state.formData.name}`},
          ]}
          status={this.state.pageStatus}
        />
        <div className="flex-row">
          <div className="column large">
            <div className="flex-row tightest-top">
              <div className="column">
                <ProgressBar
                  currentStep={this.state.progress.currentStep}
                  totalSteps={this.state.progress.totalSteps}
                />
              </div>
            </div>
            {this.renderPropsEditCard()}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTransitionEvent(SysPropDetail);
