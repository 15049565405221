import React from "react";
import { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import ProcedureDTO from "../../../common/api/dtos/Procedure";
import { listUsers } from "../../../common/api/endpoints/users";
import { listAssignedUsers } from "../../../common/api/endpoints/procedures";
import Fetch from "../../../common/helpers/Fetch";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import TextControl from "../../controls/TextControl/TextControl";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import ToolbarControls from "../../generics/Header/ToolbarControls";
import RequestStatus from "../../utils/RequestStatus/RequestStatus";
import SortButton from "../../utils/SortButton/SortButton";
import UserDTO from "../../../common/api/dtos/User";
import Thumbnail from "../../utils/Thumbnail/Thumbnail";
import { withTransitionEvent } from "../../TransitionEvent";

interface Props {
  editProcedureId: number,
}

interface State {
  userPropId: number | undefined | null,
  status: TRequestStatus,
  formStatus: TRequestStatus,
  users?: UserDTO[],
  showDisabled: boolean,
  showOnlyAssignedProcedures: boolean,
  showSensitive: boolean,
  tableSort: TableSort,
  formData: FormData,
  filterValue: string,
}

interface FormData {
  users: UserDTO[],
}

class ProceduresUserForm extends Component<Props, State> {
  availableFilters: string[] = [
    'email', 
    'name', 
    'role.name',
    'fullName'
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      formStatus: 'idle',
      formData: {
        users: [],
      },
      showSensitive: false,
      userPropId: undefined,
      tableSort: new TableSort('name', 'asc'),
      showDisabled: localStorage.getItem('showDisabledUsers') === 'false' || localStorage.getItem('showDisabledUsers') === null ? false : true,
      showOnlyAssignedProcedures: false,
      filterValue: '',
    }
  }

  sensitiveTimeout: number = 0;

  fetchUsers = async () => {
    this.setState({
      status: 'loading'
    });

    const users = await listUsers();

    this.setState({
      status: 'success',
      users: users,
    });
  }

  fetchAssignedUsers = async () => {
    this.setState({
      status: 'loading'
    });

    const assignedUsers = await listAssignedUsers(this.props.editProcedureId);

    this.setState({
      status: 'success',
      formData: {
        users: assignedUsers.length ? assignedUsers : [],
      }
    });
  }

  toggleDeleted = (event: ChangeEvent<HTMLInputElement>) =>
  {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({ showDisabled: false}) : this.setState({ showDisabled: true });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  toggleVisible = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showOnlyAssignedProcedures ? this.setState({
      showOnlyAssignedProcedures: false }) : this.setState({
      showOnlyAssignedProcedures: true });
  }

  checkIfAssigned = (procedureId: number): boolean => {
    return this.state.formData.users.map((user: UserDTO) => { return user.id; }).indexOf(procedureId) === -1 ? false : true;
  }

  componentDidMount() {
    this.fetchUsers();
    this.fetchAssignedUsers();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if(this.state.showSensitive !== prevState.showSensitive) {
      if(this.state.showSensitive){
        this.sensitiveTimeout = setTimeout(() => {
          this.setState({showSensitive: false});
        }, parseInt(String(process.env.REACT_APP_SENSITIVE_DATA_TIMEOUT))) as unknown as number;
      } else {
        clearTimeout(this.sensitiveTimeout);
      }
    }
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });

    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/procedures/assigned-users/` + this.props.editProcedureId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(this.state.formData)
    })

    await fetchRequest.fetch();

    this.setState({
      formStatus: fetchRequest.getStatus()
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  toggleSensitive = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.showSensitive ? this.setState({showSensitive: false}) : this.setState({showSensitive: true});
  }

  setAssigned = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentProjectId: number = Number(ev.target.value);
    this.setState((prevState: State) => {
      let assignedUsers = prevState.formData.users.slice(0);
      let alreadyAssigned = assignedUsers.map((user: UserDTO) => { return user.id; }).indexOf(currentProjectId);

      if(alreadyAssigned === -1) {
        let selectedUser = this.state.users?.filter((user: UserDTO) => user.id === currentProjectId)[0];
        if(selectedUser) {
          assignedUsers.push(selectedUser);
        }
      } else {
        assignedUsers.splice(alreadyAssigned, 1);
      }

      return {
        formData: {
          ...prevState.formData,
          users: assignedUsers
        }
      }
    });
  }


  addAllListed = () => {
    const filteredItems = this.getFilteredUsers();

    this.setState((prevState: State) => {
      const existingIds = prevState.formData.users.map(e => e.id);
      const newItems = filteredItems.filter(item => !existingIds.includes(item.id));
      return {
        formData: {
          ...prevState.formData,
          users: [...prevState.formData.users, ...newItems]
        }
      }
    });
  }

  removeAllListed = () => {
    const filteredItems = this.getFilteredUsers();

    this.setState((prevState: State) => {
      const filteredIds = filteredItems.map(u => u.id);
      return {
        formData: {
          ...prevState.formData,
          users: prevState.formData.users.filter((element) => !filteredIds.includes(element.id))
        }
      }
    });
  }

  renderToggler = () => {
    if(!this.state.users) {
      return;
    }

    const filteredItems = this.getFilteredUsers();
    const formIds = this.state.formData.users.map(u => u.id);
    const diffAdd = filteredItems.filter(item => !formIds.includes(item.id)).length;
    const diffRemove = filteredItems.filter(item => formIds.includes(item.id)).length;

    return (
      <div className="flex-row tight squeeze">
        <div className="column">
          <button
            className="primary-button"
            onClick={this.addAllListed}
            disabled={!diffAdd}
            >
            Add listed {`(${diffAdd})`}
          </button>
        </div>
        <div className="column">
          <button
            className="primary-button"
            onClick={this.removeAllListed}
            disabled={!diffRemove}
          >
            Remove listed {`(${diffRemove})`}
          </button>
        </div>
      </div>
    )
  }

  getFilteredUsers() {
    return getFilteredItems(this.state.filterValue, this.availableFilters, this.state.users ? this.state.users : [])
      .filter((user: UserDTO) => 
      (user.enabled || this.state.showDisabled)
      &&
      (this.state.showOnlyAssignedProcedures === false || this.checkIfAssigned(user.id))
      );
  }

  render() {
    const filteredUsers = this.getFilteredUsers().sort(this.state.tableSort.sortByColumn)
    const totalUsers = this.state.users?.filter((user: UserDTO) => user.enabled || this.state.showDisabled)
    const totalAssignedUsers = this.state.users?.filter((user: UserDTO) => this.checkIfAssigned(user.id));
      
    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
            <ToggleControl
              id="showSensitive"
              name="showSensitive"
              changeMethod={this.toggleSensitive}
              isChecked={this.state.showSensitive}
              labelText="Sensitive data"
                icon="fas fa-shield-alt"
                srOnly={true}
                title="Sensitive data"
            />
            <ToggleControl
              id="showDisabledUsers"
              name="showDisabledUsers"
              changeMethod={this.toggleDeleted}
              isChecked={this.state.showDisabled}
              labelText="Disabled"
            />
            <ToggleControl
              id="showOnlyAssignedProcedures"
              name="showOnlyAssignedProcedures"
              changeMethod={this.toggleVisible}
              isChecked={this.state.showOnlyAssignedProcedures}
              labelText="Assigned"
            />
          </ToolbarControls>
        </div>
        <div className={`card ${!this.state.users ? 'loader-border' : ''}`}>
          {this.renderToggler()}
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 350px'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="text-left">
                          <SortButton
                            column='name'
                            text='Name'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}></SortButton>
                        </th>
                        {this.state.showDisabled &&
                          <th className="text-center">
                            <SortButton
                              column='enabled'
                              text='Enabled'
                              tableSort={this.state.tableSort}
                              onClick={this.handleSortChange}
                              ></SortButton>
                          </th>
                        }
                        <th className="text-center">
                        <span>Assigned to {this.state.formData.users.length}</span>
                        </th>
                      </tr>
                    </thead>
                    {this.state.users &&
                      <React.Fragment>
                        <tbody>
                          {getFilteredItems(this.state.filterValue, this.availableFilters, this.state.users).sort(this.state.tableSort.sortByColumn).filter((user: UserDTO) => (user.enabled === true || this.state.showDisabled) && (this.state.showOnlyAssignedProcedures === false || this.checkIfAssigned(user.id))).map(user => (
                            <React.Fragment>
                              <tr id={`user-${user.id}`} key={user.id}>
                                <td></td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center">
                                      <Thumbnail avatarData={user.avatar} />
                                      <div>
                                        <span>{user.name}</span><br />
                                        <small className={!this.state.showSensitive ? 'blurred-item faint-text' : 'blurred-item deblur faint-text'}>{user.fullName}</small>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                {this.state.showDisabled &&
                                  <td className="text-center">
                                    {user.enabled &&
                                      <span className="fas fa-check"></span>
                                    }
                                    </td>
                                }
                                <td className="text-center">
                                  <ToggleControl
                                    id={`user-option-${user.id}`}
                                    name={`user-option-${user.id}`}
                                    changeMethod={this.setAssigned}
                                    isChecked={this.checkIfAssigned(user.id)}
                                    value={String(user.id)}
                                    labelText={this.checkIfAssigned(user.id) ? 'ON' : 'OFF'}
                                    classes="text-center"
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.users.length === 0 &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk">There are no users defined. Start by <Link className="link-button" to="/users-list/add">adding</Link> the first one.</p>
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                <p className="text-chunk">Showing {filteredUsers?.length} out of {this.state.showOnlyAssignedProcedures ? totalAssignedUsers?.length : totalUsers?.length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
            <button
              className="primary-button"
              onClick={this.updateEntity}
              disabled={Boolean(!this.state.users)}
            >
              <RequestStatus status={this.state.formStatus} />
              <span className="text">Save</span>
            </button>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(ProceduresUserForm);