import moment from 'moment';
import 'moment-timezone';
import React, { Component, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router';

import HolidayDTO from '../../../common/api/dtos/Holiday';
import RoleDTO from '../../../common/api/dtos/Role';
import SuspensionDTO from '../../../common/api/dtos/Suspension';
import SyspropDTO from '../../../common/api/dtos/Sysprop';
import UserDTO from '../../../common/api/dtos/User';
import VacationsDTO, { VacationsEveryoneDTO } from '../../../common/api/dtos/Vacations';
import { IBookingTile } from '../../../common/interfaces/BookingTile';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';

import { listUserContractsLimited } from '../../../common/api/endpoints/contracts';
import { filterSuspensions, listSuspensions } from '../../../common/api/endpoints/suspensions';
import { CancelBooking, listEveryoneVacations, listRemainingPaidDays, listUserVacations, SaveBooking, UpdateVacation } from '../../../common/api/endpoints/vacations';
import { Months } from '../../../common/data/Months';

import ContractDTO from '../../../common/api/dtos/Contract';
import AppContext from '../../../common/context/AppContext';
import { LegendTiles } from '../../../common/data/LegendTiles';
import hasAccess from '../../../common/helpers/Access';
import SelectControl from '../../controls/SelectControl/SelectControl';
import { ContractPair, getDaysInMonth, processSuspensions, suspensionEntry, trimDate } from '../../generics/Booking/bookingUtils';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import ToolbarControls from '../../generics/Header/ToolbarControls';
import { withTransitionEvent } from '../../TransitionEvent';
import AlertBanner from '../../utils/AlertBanner/AlertBanner';
import ProgressBar, { incrementProgress, IProgress } from '../../utils/ProgressBar/ProgressBar';
import BookingTile from './BookingTile';
import LegendTile from './LegendTile';
import ToggleControl from '../../controls/ToggleControl/ToggleControl';

const dateToUTC = (inputDate: Date): Date => {
  return new Date(Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate()));
}

type TBookingCounters = { absence: number, extra: number, loyalty: number, sick: number, untracked: number, unpaid: number, holiday: number, paid: number };

export interface RouteParams {
  id?: string
}

export interface Props extends RouteComponentProps<RouteParams> {
  sysProps: SyspropDTO[],
  loggedUser: LoggedUser,
}

interface State {
  years: number[],
  contracts: ContractPair[],
  form: Form,
  users?: UserDTO[],
  status: TRequestStatus,
  progress: IProgress,
  vacations: VacationsDTO[],
  vacationsEveryone: VacationsEveryoneDTO[],
  counters: TBookingCounters,
  bookingMap: Map<number, IBookingTile>,
  hasAccess: boolean,
  hasFullAcess: boolean,
  suspensions: SuspensionDTO[],
  suspensionsByDay: suspensionEntry[],
  isOverlapsToggle: boolean
}

interface Form {
  year: number,
  userId: number,
  contractId: number | null,
}


class Bookings extends Component<Props, State> {
  currentDate: Date;

  constructor(props: Props) {
    super(props);

    const targetUserId = Number(this.props.match.params.id || this.props.loggedUser.id);
    const access = hasAccess(this.props.loggedUser?.role);
    const hasFullAccess = this.hasFullAccess(this.props.loggedUser?.role);
    const userId = access ? targetUserId : this.props.loggedUser.id;

    this.state = {
      years: [],
      contracts: [],
      form: {
        year: new Date().getFullYear(),
        userId,
        contractId: null,
      },
      status: 'loading',
      progress: {
        currentStep: 0,
        totalSteps: 4,
      },
      counters: {
        absence: 0,
        extra: 0,
        loyalty: 0,
        sick: 0,
        unpaid: 0,
        untracked: 0,
        paid: 0,
        holiday: 0
      },
      vacations: [],
      vacationsEveryone: [],
      suspensions: [],
      suspensionsByDay: [],
      bookingMap: new Map<number, IBookingTile>(),
      hasAccess: access,
      hasFullAcess: hasFullAccess,
      isOverlapsToggle: true,
    }

    this.currentDate = new Date();
  }

  fetchUserVacations = async () => {
    this.setState({
      status: 'loading'
    });

    const vacations = await listUserVacations(this.state.form.userId);

    this.setState(prevState => {
      return {
        status: 'success',
        vacations: [...vacations],
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  fetchRemainingPaidDays = async () => {
    this.setState({
      status: 'loading'
    });

    const paidTotal = await listRemainingPaidDays(this.state.form.year, this.state.form.userId, this.state.form.contractId);

    this.setState(prevState => {
      return {
        status: 'success',
        progress: incrementProgress(prevState.progress),
        counters: {
          ...prevState.counters,
          paid: paidTotal
        }
      }
    });
  }

  fetchEveryoneVacations = async () => {
    this.setState({
      status: 'loading'
    });

    const vacationsEveryone = await listEveryoneVacations(this.state.form.year, this.state.form.userId);

    this.setState(prevState => {
      return {
        status: 'success',
        vacationsEveryone,
        progress: incrementProgress(prevState.progress),
      }
    });
  }

  fetchSuspensions = async () => {
    this.setState({
      status: 'loading'
    });

    let suspensions: SuspensionDTO[];

    if (this.state.hasAccess) {
      suspensions = await listSuspensions();
    } else {
      suspensions = (await filterSuspensions({ uid: this.context.loggedUser.id })).map(s => {
        return {
          ...s,
          user: this.context.loggedUser,
        }
      });
    }

    this.setState({
      status: 'success',
      suspensions: suspensions,
    }, () => {
      this.createSuspensionsArray();
    });

  }

  createSuspensionsArray = () => {
    const processedSuspensions = processSuspensions(this.state.suspensions, this.state.form.year);

    this.setState({
      suspensionsByDay: processedSuspensions,
    })
  }

  getDatesInInterval = (startDate: Date, endDate: Date) => {
    var arrOfDays = new Array();
    var date = new Date(startDate);

    while (date <= new Date(endDate)) {
      arrOfDays.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return arrOfDays;
  }

  vacationsPerMonth = (month: number) => {
    let totalDaysInMonth: number = 0;
    const filterEntity = <T extends { date: Date }>(entity: T[]) => {
      return entity.filter((entity: T) => new Date(entity.date).getFullYear() === this.state.form.year && new Date(entity.date).getMonth() === month)
    }

    totalDaysInMonth = filterEntity(this.state.vacationsEveryone).length + filterEntity(this.state.suspensionsByDay).length;

    return totalDaysInMonth;
  }

  hasFullAccess = (userRole: RoleDTO | undefined | null): boolean => {
    return userRole ? (userRole.name === 'Admin' || userRole.name === 'Manager') : false;
  }

  generateBookingMap = () => {
    let bookingMap = new Map<number, IBookingTile>();

    for (let dayIndex = 1; dayIndex <= (moment({ year: this.state.form.year }).isLeapYear() ? 366 : 365); dayIndex++) {
      let currentDate = dateToUTC(new Date(this.state.form.year, 0, dayIndex));

      if (!bookingMap.get(currentDate.getTime())) {
        bookingMap.set(currentDate.getTime(), {
          date: currentDate,
          isWeekend: this.isWeekend(currentDate),
          isHoliday: false,
          isUnemployed: false,
          vacation: null,
          holiday: null,
          suspension: null,
          overlaps: [],
          id: null,
          status: 'idle'
        })
      }
    }

    this.setState({
      bookingMap: bookingMap,
    });
  }

  populateBookingMap = () => {
    let bookingMap = new Map(this.state.bookingMap);
    this.setState({
      counters: {
        absence: 0,
        extra: 0,
        loyalty: 0,
        sick: 0,
        unpaid: 0,
        untracked: 0,
        holiday: 0,
        paid: this.state.counters.paid,
      }
    }, () => {
      bookingMap = this.populateUnemployment(bookingMap);
      bookingMap = this.populateVacations(bookingMap);
      bookingMap = this.populateHolidays(bookingMap);
      bookingMap = this.populateOverlaps(bookingMap);
      bookingMap = this.populateSuspensions(bookingMap);
      this.setState({
        bookingMap: bookingMap
      });
    });
  }

  populateUnemployment(bookingMap: Map<number, IBookingTile>): Map<number, IBookingTile> {
    let map = new Map(bookingMap);
    let currentContract = this.state.contracts?.find((contract: ContractPair) => contract.id === this.state.form.contractId);
    let startDate = currentContract?.startDate ? new Date(currentContract?.startDate) : null;
    let endDate = currentContract?.endDate ? new Date(currentContract?.endDate) : null;

    for (var m = moment(new Date(this.state.form.year, 0, 1)); m.isBefore(trimDate(startDate)); m.add(1, 'days')) {
      let currentTimestamp = dateToUTC(m.toDate()).getTime();
      let currentEntry = map.get(currentTimestamp);

      if (currentEntry) {
        map.set(currentTimestamp, {
          ...currentEntry,
          isUnemployed: true
        })
      }
    }
    if (endDate !== null) {
      for (var m = moment(endDate).add(1, 'day'); m.isBefore(new Date(this.state.form.year, 11, 31)); m.add(1, 'days')) {
        let currentTimestamp = dateToUTC(m.toDate()).getTime();
        let currentEntry = map.get(currentTimestamp);

        if (currentEntry) {
          map.set(currentTimestamp, {
            ...currentEntry,
            isUnemployed: true
          })
        }
      }
    }
    return map;
  }

  populateHolidays(bookingMap: Map<number, IBookingTile>): Map<number, IBookingTile> {
    let map = new Map(bookingMap);
    let holidayTotal = 0;

    (this.context.holidays as HolidayDTO[]).forEach((holiday: HolidayDTO) => {
      if (holiday.deleted) return;

      let currentDate = dateToUTC(new Date(holiday.date));

      if (map.get(currentDate.getTime())) {
        map.set(currentDate.getTime(), {
          ...map.get(currentDate.getTime())!,
          isHoliday: true,
          holiday,
        });
        holidayTotal++;
      }
    });

    this.setState(prevState => {
      return {
        counters: {
          ...prevState.counters,
          holiday: holidayTotal
        }
      }
    });

    return map;
  }

  populateVacations(bookingMap: Map<number, IBookingTile>): Map<number, IBookingTile> {
    let map = new Map(bookingMap);
    let counters: TBookingCounters = {
      absence: 0,
      extra: 0,
      loyalty: 0,
      sick: 0,
      untracked: 0,
      paid: this.state.counters.paid,
      unpaid: 0,
      holiday: 0
    }

    this.state.vacations.forEach((vacation: VacationsDTO) => {
      if (vacation.contractId !== this.state.form.contractId || vacation.deleted) return;

      let currentTimestamp = dateToUTC(new Date(vacation.date)).getTime();
      let currentEntry = map.get(currentTimestamp);

      if (currentEntry) {
        map.set(currentTimestamp, {
          ...currentEntry,
          vacation: vacation,
          status: 'idle'
        });
        if(vacation.type !== "paid") {
          counters[vacation.type as keyof TBookingCounters]++;
        }
      }
    });

    this.setState({
      counters
    });

    return map;
  }

  populateSuspensions(bookingMap: Map<number, IBookingTile>): Map<number, IBookingTile> {
    let map = new Map(bookingMap);
    let unpaid: number = 0;

    this.state.suspensionsByDay.filter((suspension: suspensionEntry) => suspension.user.id === this.state.form.userId).forEach((suspension: suspensionEntry) => {
      if (suspension.contractId !== this.state.form.contractId || suspension.deleted) return;
      let currentTimestamp = dateToUTC(new Date(suspension.date)).getTime();
      let mySuspension = {
        user: suspension.user,
        approved: true,
        confirmed: false,
        date: suspension.date,
        deleted: suspension.deleted,
        id: null,
        type: "unpaid",
        internalCode: "S",
        suspensionId: suspension.id,
        contractId: suspension.contractId,
        contract: suspension.contract
      };

      let currentEntry = map.get(currentTimestamp);

      if (currentEntry) {
        map.set(currentTimestamp, {
          ...currentEntry,
          suspension: suspension,
          vacation: mySuspension,
          status: 'idle'
        });

        if (suspension.user && suspension.user.id === this.state.form.userId) {
          unpaid++;
        }
      }
    });

    this.setState((prevState: State) => {
      return {
        counters: {
          ...prevState.counters,
          unpaid,
        }
      }
    })

    return map;
  }

  populateOverlaps(bookingMap: Map<number, IBookingTile>): Map<number, IBookingTile> {
    let map = new Map(bookingMap);

    this.state.vacationsEveryone.forEach((vacation: VacationsEveryoneDTO) => {
      let currentTimestamp = dateToUTC(new Date(vacation.date)).getTime();
      let currentEntry = map.get(currentTimestamp);

      if (currentEntry) {
        let seekedUser = this.context.users?.find((user: UserDTO) => user.id === vacation.userId);

        if (seekedUser) {
          currentEntry.overlaps.push({...seekedUser, confirmed: vacation.confirmed ?? false});
        }

        map.set(currentTimestamp, {
          ...currentEntry,
        });
      }
    });

    this.state.suspensionsByDay.forEach((suspension: suspensionEntry) => {
      let currentTimestamp = dateToUTC(new Date(suspension.date)).getTime();
      let currentEntry = map.get(currentTimestamp);

      if (currentEntry) {
        let seekedUser = this.context.users?.find((user: UserDTO) => user.id === suspension.user.id);

        if (seekedUser) {
          currentEntry.overlaps.push(seekedUser);
        }

        map.set(currentTimestamp, {
          ...currentEntry,
        });
      }
    })

    return map;
  }

  toggleShowOverlaps = () =>  {
    this.setState( (prevState: State) => {
      return {
        isOverlapsToggle: !prevState.isOverlapsToggle,
      }
    })
  }

  loadDidComplete() {
    this.setState({
      status: 'success'
    });

    if (this.state.progress.currentStep === this.state.progress.totalSteps) {
      this.generateBookingMap();
      this.populateBookingMap();
    }
  }

  getYearsArray = (yearsString: string): void => {
    this.setState({
      years: yearsString.split(',').map(Number),
    });
  }

  isToday = (headerIndex: number): boolean => {
    return this.currentDate.getDate() === headerIndex + 1;
  }

  isWeekend = (inputDate: Date): boolean => {
    return moment(inputDate).isoWeekday() >= 6;
  }

  getVacationForDay = (inputDate: Date): VacationsDTO | null => {
    return (
      this.state.vacations.find((vacation: VacationsDTO) => {
        return moment(vacation.date).isSame(inputDate) && vacation.deleted === false;
      }) || null
    );
  }

  renderHeadCells = () => {
    const arrayOfHeadings = new Array(31).fill(0);

    return arrayOfHeadings.map((value, index) => {
      return (
        <React.Fragment key={index}>
          <th className={`month-day`}>
            <span className="inner-text">{index + 1}</span>
          </th>
        </React.Fragment>
      )
    });
  }

  renderCells = (monthNumber: number) => {
    let tileDate;
    const nrOfDays = getDaysInMonth(monthNumber, this.state.form.year);
    const arrayOfDays = new Array(nrOfDays).fill(0);

    return arrayOfDays.map((value, index) => {
      tileDate = new Date(Date.UTC(this.state.form.year, monthNumber, index + 1))
      if (this.state.bookingMap) {
        let tile = this.state.bookingMap.get(tileDate.getTime());

        return (
          <React.Fragment key={index}>
            {tile &&
              <BookingTile
                key={index}
                tileData={tile}
                requestBooking={this.requestBooking}
                cancelBooking={this.cancelBooking}
                updateBooking={this.updateBooking}
                changeUser={this.changeUser}
                hasAccess={this.state.hasAccess}
                hasFullAccess={this.state.hasFullAcess}
                selectedUserId={this.state.form.userId}
                isOverlapsToggle={this.state.isOverlapsToggle}
              />
            }
          </React.Fragment>
        )
      }
    });
  }

  changeUser = (userId: number) => {
    if (this.state.form.userId === userId) return;
    this.updateForm('userId', Number(userId));
  }

  fetchSaveBooking = async (vacation: VacationsDTO) => {
    const payload = await SaveBooking(vacation);
    return {
      status: 'success' as TRequestStatus,
      payload: payload,
    }
  }

  fetchCancelBooking = async (bookingId: number) => {
    const payload = await CancelBooking(bookingId);
    return {
      status: 'success' as TRequestStatus,
      payload: payload,
    }
  }

  computeCounters(operation: string, counterType: string) {
    let counters = { ...this.state.counters };

    switch (operation) {
      case 'save':
        if (counterType !== 'paid') {
          if (counterType === 'absence') {
            counters[counterType as keyof TBookingCounters]++;
          } else {
            counters[counterType as keyof TBookingCounters]++;
          }
        } else {
          counters.paid!--;
        }
        break;

      case 'cancel':
        if (counterType !== 'paid') {
          if (counterType === 'absence') {
            counters[counterType as keyof TBookingCounters]--;
          } else {
            counters[counterType as keyof TBookingCounters]--;
          }
        } else {
          counters.paid!++;
        }
        break;
    }

    return counters;
  }

  requestBooking = async (inputDate: Date, type: string, internalCode: string) => {
    let map = new Map(this.state.bookingMap);
    let currentTimestamp = inputDate.getTime();
    let currentEntry = map.get(currentTimestamp);
    const user = this.context.users.find((user: UserDTO) => user.id === this.state.form.userId);
    const vacation = new VacationsDTO(null, type, inputDate, internalCode, this.state.form.contractId, false, false, false, user);

    if (currentEntry) {
      this.setState({
        bookingMap: map.set(currentTimestamp, {
          ...currentEntry,
          status: 'loading'
        })
      })

      const response = await this.fetchSaveBooking(vacation);

      this.setState({
        bookingMap: map.set(currentTimestamp, {
          ...currentEntry,
          status: response.status
        })
      })

      if (response.payload.id) {
        currentEntry.overlaps.unshift(this.context.users?.find((user: UserDTO) => user.id == this.state.form.userId)!);

        map.set(currentTimestamp, {
          ...currentEntry,
          vacation: new VacationsDTO(response.payload.id, type, inputDate, internalCode, this.state.form.contractId),
          status: response.status
        })

        const allCounters = this.computeCounters('save', response.payload.type);

        this.setState((prevState: State) => {
          let vacations = prevState.vacations.slice();
          vacations.push(new VacationsDTO(response.payload.id, type, inputDate, internalCode, this.state.form.contractId));

          return {
            bookingMap: map,
            vacations: vacations,
            counters: allCounters,
          }
        })
      }
    }
  }

  cancelBooking = async (inputDate: Date, bookingId: number) => {
    let vacations = this.state.vacations.slice();
    let seekedVacation = vacations.find((vacation: VacationsDTO) => {
      return moment(vacation.date).isSame(inputDate) && vacation.deleted === false;
    });

    if (seekedVacation) {
      let map = new Map(this.state.bookingMap);
      let currentTimestamp = dateToUTC(new Date(seekedVacation.date)).getTime();
      let currentEntry = map.get(currentTimestamp);

      if (currentEntry) {
        this.setState({
          bookingMap: map.set(currentTimestamp, {
            ...currentEntry,
            status: 'loading'
          })
        })

        const response = await this.fetchCancelBooking(bookingId);

        this.setState({
          bookingMap: map.set(currentTimestamp, {
            ...currentEntry,
            status: response.status
          })
        })

        if (response.payload.id) {
          vacations.splice(vacations.indexOf(seekedVacation), 1);

          currentEntry.overlaps.splice(currentEntry.overlaps.findIndex((user: UserDTO) => user.id === this.state.form.userId), 1);
          map.set(currentTimestamp, {
            ...currentEntry,
            vacation: null,
            status: response.status
          })

          const allCounters = this.computeCounters('cancel', response.payload.type);

          this.setState({
            bookingMap: map,
            counters: allCounters,
          })
        }
      }
    }
  }

  updateBooking = async (inputDate: Date, vacation: VacationsDTO) => {
    let map = new Map(this.state.bookingMap);
    let currentTimestamp = inputDate.getTime();
    let currentEntry = map.get(currentTimestamp);

    if (currentEntry) {
      this.setState({
        bookingMap: map.set(currentTimestamp, {
          ...currentEntry,
          status: 'loading'
        })
      });

      try {
        const response = await UpdateVacation(vacation);

        this.setState({
          bookingMap: map.set(currentTimestamp, {
            ...currentEntry,
            status: 'success',
            vacation: response
          })
        }, () => {
          setTimeout(() => {
            this.setState({
              bookingMap: map.set(currentTimestamp, {
                ...this.state.bookingMap.get(currentTimestamp) as IBookingTile,
                status: 'idle'
              })
            })
          }, 2000);
        })
      } catch (error) {
        this.setState({
          bookingMap: map.set(currentTimestamp, {
            ...currentEntry,
            status: 'error'
          })
        })
      }
    }
  }

  fetchFullData = async () => {
    await this.fetchUserContracts(this.state.form.userId);
    this.fetchUserVacations();
    this.fetchEveryoneVacations();
    this.fetchSuspensions();
  }

  setUserUrl = () => {
    this.props.history.replace(`/${this.props.location.pathname.split('/')[1]}/${this.state.form.userId}`);
  }

  /*
   * Check if pathname exist
   *
  */
  checkPathName() {
    let url = this.props.match.params.id;
    return url === undefined || this.context.users.some((user: UserDTO) => url === user.id.toString());
  }

  /**
   * Executed once when the component loads.
   */
  private async initialize() {
    if (!this.checkPathName()) {
      this.props.history.replace('/404');
      return;
    }

    this.getYearsArray(this.props.sysProps[1].value);
    this.generateBookingMap();
    this.fetchFullData();
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (this.state.progress.currentStep !== prevState.progress.currentStep) {
      this.loadDidComplete();
    }


    if (this.state.form.userId !== prevState.form.userId) {
      this.setState({
        status: 'loading',
        progress: {
          currentStep: 0,
          totalSteps: 4,
        },
      });
      this.fetchFullData();
      this.setUserUrl();
    }

    if (this.state.form.year !== prevState.form.year) {
      this.setState({
        status: 'loading',
        progress: {
          currentStep: 0,
          totalSteps: 2,
        },
      });
      this.fetchRemainingPaidDays();
      this.fetchEveryoneVacations();
    }

    if (this.state.form.contractId !== prevState.form.contractId && prevState.form.contractId !== null) {
      this.setState({
        status: 'loading',
        progress: {
          currentStep: 0,
          totalSteps: 2,
        },
      });
      this.fetchRemainingPaidDays();
      this.fetchEveryoneVacations();
    }
  }

  updateForm<K extends keyof Form>(field: K, value: Form[K]) {
    const form = this.state.form;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    }, () => {
      this.generateBookingMap();
      this.createSuspensionsArray();
    })
  }

  setYear = (ev: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<Element>, yearNo: number | string | undefined) => this.updateForm('year', Number(yearNo));
  setContract = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, contractId: number | string | undefined | ContractPair) => this.updateForm('contractId', Number(contractId));
  setUser = (ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>, userId: number | string | undefined | UserDTO) => {
    this.updateForm('userId', Number(userId));
  }

  fetchUserContracts = async (userId: number) => {
    this.setState({
      status: 'loading'
    });

    const userContracts = await listUserContractsLimited(userId);
    const contracts: ContractPair[] = [];
    userContracts.forEach((userContract: ContractDTO) => {
      if(!userContract.deleted) {
        contracts.push({
          id: userContract.id,
          name: userContract.name,
          startDate: userContract.startDate,
          endDate: userContract.endDate,
          deleted: userContract.deleted
        })
      } else {
        return
      }
    });
    this.setState((prevState) => {
      return {
        status: 'success',
        contracts: contracts,
        progress: incrementProgress(prevState.progress),
        form: {
          ...prevState.form,
          contractId: contracts.length ? this.getDefaultContract(contracts)?.id as number : null
        }
      }
    }, () => this.fetchRemainingPaidDays());
  }

  getDefaultContract = (contracts: ContractPair[]) => {
    const defaultContract = contracts.find((contract: ContractPair) => contract.endDate === null);

    return defaultContract !== undefined ? defaultContract : contracts[0];
  }

  render() {
    const bookingTiles = Object.values(LegendTiles);
    return (
      <div>
        <ProgressBar
          currentStep={this.state.progress.currentStep}
          totalSteps={this.state.progress.totalSteps}
        />
        <ToolbarControls>
          {this.state.hasAccess &&
            <SelectControl
              idName="userId"
              // classes="mw-small"
              value={this.state.form.userId}
              options={this.hasFullAccess(this.props.loggedUser.role) ? (this.context.users as UserDTO[]).filter(user => user.invisible === false) : this.context.users}
              onChange={this.setUser}
              disabled={this.state.status === 'loading'}
              getValue={(op: UserDTO) => op.id}
              getLabel={(op: UserDTO) => op?.name}
            />
          }
          <SelectControl
            idName="year"
            value={this.state.form.year}
            options={this.state.years}
            disabled={this.state.status === 'loading'}
            onChange={this.setYear}
          />
          {this.state.contracts.length > 0 &&
            <SelectControl
            idName="contractId"
            value={this.state.form.contractId ?? undefined}
            options={this.state.contracts}
            disabled={this.state.status === 'loading'}
            onChange={this.setContract}
            getValue={(op) => op.id}
            getLabel={(op) => op?.name}
            />
          }
          <ToggleControl
            id="toggleBookingsOverlaps"
            name="toggleBookingsOverlaps"
            changeMethod={this.toggleShowOverlaps}
            isChecked={this.state.isOverlapsToggle}
            labelText="Overlaps"
          />
        </ToolbarControls>
        <BreadcrumbControls
          pageTitle="Bookings"
          status={this.state.status}
        />
        <div className="flex-row fill">
          <div className="column">
            <div className="booking-component">
              <div className="card">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="lead-column">
                        Month
                      </th>
                      {this.renderHeadCells()}
                    </tr>
                  </thead>
                  <tbody>
                    {Months.map((month, index) => {
                      return (
                        <tr className={[
                          month.number == this.currentDate.getMonth() && this.state.form.year == this.currentDate.getFullYear() ? "highlight-row" : ""
                        ].join(" ")} key={index}>
                          <td></td>
                          <td className="month-name">
                            <div className="month-content">
                              <span>{month.name} </span>
                              <small className="faint-text">{this.vacationsPerMonth(month.number)}</small>
                            </div>
                          </td>
                          {this.renderCells(month.number)}
                        </tr>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colSpan={33}>
                        <div className="flex-row fill">
                          <div className="column">
                            <AlertBanner pageName="Bookings" loggedUserId={this.props.loggedUser.id}>
                              <small>
                                <span className="fas fa-flag"></span>
                                <span> &nbsp; Always check with your group and group lead before planning a trip. Too many overlaps can hinder the project by risking deadlines and will lower that month's income while also increases work pressure on teammates.</span>
                              </small>
                            </AlertBanner>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr className="visible-desktop">
                      <th colSpan={33}>
                        <div className="flex-row booking-legend-component">
                          {bookingTiles.map((bookingTile, index) => {
                            return (
                              <div className="column stretch" key={index}>
                                <LegendTile
                                  title={bookingTile.title}
                                  type={bookingTile.type}
                                  description={bookingTile.description}
                                  action={bookingTile.action}
                                  counter={this.state.counters[bookingTile.type as keyof TBookingCounters] ?? 0}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={33}>
                        <small className="faint-text">A green check ( <span className="icon fas positive-text fa-check-circle"></span> ) or a green border ( <span className="icon far positive-text fa-circle"></span> ), means that day has been formally confirmed.</small>
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTransitionEvent(Bookings);
// export default Bookings;
Bookings.contextType = AppContext;