import moment from "moment";
import Fetch from "../../helpers/Fetch";
import LogoDTO from "../dtos/Logo";

interface AddPayload {
  name: string,
  url: string,
  enabled: boolean,
  fromDate: Date | null,
  toDate: Date | null,
  priority: number,
}

export async function listLogos(): Promise<LogoDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<LogoDTO[]>(`/logos`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    const logos = fetchRequest.getPayload();
    return logos.map((logo) => ({...logo, toDate: moment.utc(logo.toDate).local(true).toDate()}));
  }
  throw new Error('Failed to fetch logos');
}

export async function currentLogo(): Promise<LogoDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<LogoDTO[]>(`/logos/current`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch current logo');
}

export async function listLogoDetails(logoId: string): Promise<LogoDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<LogoDTO>(`/logos/${logoId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch logo details');
}

export async function addLogo(form: AddPayload): Promise<LogoDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<LogoDTO>(`/logos`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add logo`);
}

export async function updateLogo(form: AddPayload, logoId: string): Promise<LogoDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<LogoDTO>(`/logos/${logoId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });


  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update logo`);
}