import moment from "moment";
import TenantDTO from "../../../../common/api/dtos/Tenant";
import { Currency } from "../types";

interface Props {
  translations: Record<string, string>,
  invoiceSeries?: string,
  since: Date,
  until: Date,
  language: string,
  tenant?: TenantDTO,
}

export function InvoiceSection({ translations, invoiceSeries, since, until, language, tenant}: Props) {
  const formatInvoiceDate = (when: Date, language: string) => {
    return language === 'en-ro' ? formatType(when, 'MMMM Do YYYY') + "/" + formatType(when, 'DD-MM-YYYY') : formatType(when, 'DD-MM-YYYY');
  };
  
  const formatType = (when: Date, dateType: string) => {
    // Added one extra month for invoices period
    return since === when && moment(when).add(1, 'M').format(dateType).replaceAll('-', '.') 
    || until === when && moment(when).add(30, 'd').format(dateType).replaceAll('-', '.')
  };
  
  return (
    <div className="fill">
      <h2>{translations?.title}</h2>
      <span>{translations?.series}
        <span> {invoiceSeries} </span>
        {translations?.no}
        <span contentEditable suppressContentEditableWarning={true}>000</span>
      </span>
      <br />
      <span>{translations?.date} <span contentEditable suppressContentEditableWarning={true}>
        {formatInvoiceDate(since, language)}
        </span></span>
      <br />
      <span>{translations?.due} <span contentEditable suppressContentEditableWarning={true}>
      {formatInvoiceDate(until, language)}  
      </span></span>
    </div>
  )
}