export interface Month {
    number: number,
    name: string,
}

export const Months: Array<Month> = [
    {number: 0, name:'January'},
    {number: 1, name:'February'},
    {number: 2, name:'March'},
    {number: 3, name:'April'},
    {number: 4, name:'May'},
    {number: 5, name:'June'},
    {number: 6, name:'July'},
    {number: 7, name:'August'},
    {number: 8, name:'September'},
    {number: 9, name:'October'},
    {number: 10, name:'November'},
    {number: 11, name:'December'},
];