import Fetch from "../../helpers/Fetch";
import CompensationDTO from "../dtos/Compensation";

interface AddPayload {

}

interface CompensationsFilter {
  uid: number,
}

export async function filterCompensations(filter: CompensationsFilter): Promise<CompensationDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<CompensationDTO[]>(`/compensations/filter`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(filter)
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch compensations');
}

export async function listCompensations(): Promise<CompensationDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<CompensationDTO[]>(`/compensations`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch compensations');
}

export async function listUserCompensations(userId: number): Promise<CompensationDTO[]> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<CompensationDTO[]>(`/compensations/user/${userId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error('Failed to fetch user\'s compensations');
}

export async function listCompensationDetails(suspenionId: number): Promise<CompensationDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<CompensationDTO>(`/compensations/${suspenionId}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  })

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error('Failed to fetch compensation details');
}

export async function addCompensation(form: AddPayload): Promise<CompensationDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<CompensationDTO>(`/compensations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }

  throw new Error(`Failed to add compensation`);
}

export async function updateCompensation(form: AddPayload, compensationId: number): Promise<CompensationDTO> {
  const token = localStorage.getItem('id_token');
  const fetchRequest = new Fetch<CompensationDTO>(`/compensations/${compensationId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(form)
  });

  await fetchRequest.fetch();

  const status = fetchRequest.getStatus();

  if (status === 'success') {
    return fetchRequest.getPayload();
  }
  throw new Error(`Failed to update compensation`);
}