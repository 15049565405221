import moment from 'moment';
import { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import ProjectDTO from '../../../common/api/dtos/Project';
import SyspropDTO from '../../../common/api/dtos/Sysprop';
import UserDTO from '../../../common/api/dtos/User';
import VacationsDTO from '../../../common/api/dtos/Vacations';
import { Holiday } from '../../../common/interfaces/Holiday';
import { LoggedUser } from '../../../common/interfaces/LoggedUser';
import { TRequestStatus } from '../../../common/types/RequestStatus';

import { listProjectDetails } from '../../../common/api/endpoints/projects';
import { listAssignedProjects } from '../../../common/api/endpoints/users';
import { listUserVacations } from '../../../common/api/endpoints/vacations';
import { createWorklog, filterWorklogs, FilterWorklogsItem as Worklog, updateWorklog } from '../../../common/api/endpoints/worklogs';
import { Months } from '../../../common/data/Months';
import { ChangeSortBy } from '../../../common/helpers/Sorting';
import { computeMonthRange, extractYears } from '../../generics/Invoices/utils';

import SuspensionDTO from '../../../common/api/dtos/Suspension';
import { listUserSuspensions } from '../../../common/api/endpoints/suspensions';
import AppContext from '../../../common/context/AppContext';
import { loadComplete } from '../../../common/helpers/LoadComplete';
import TableSort from '../../../common/helpers/TableSort';
import { hasElevatedAccess, processSuspensions, suspensionEntry, trimDate } from '../../generics/Booking/bookingUtils';
import BreadcrumbControls from '../../generics/Header/BreadcrumbControls';
import ToolbarControls from '../../generics/Header/ToolbarControls';
import { withTransitionEvent } from '../../TransitionEvent';
import AlertBanner from '../../utils/AlertBanner/AlertBanner';
import ProgressBar, { incrementProgress, IProgress, newProgress } from '../../utils/ProgressBar/ProgressBar';
import SortButton from '../../utils/SortButton/SortButton';
import { RouteParams } from '../Bookings/Bookings';
import Filters from './components/Filters';
import ProjectRow from './components/ProjectRow';

export interface Props extends RouteComponentProps<RouteParams> {
  sysProps: SyspropDTO[],
  loggedUser: LoggedUser,
}

interface State {
  activeYears: number[],
  tableSort: TableSort,
  form: Form,
  vacations?: VacationsDTO[],
  suspensions: SuspensionDTO[],
  suspensionsByDay: suspensionEntry[],
  progress: IProgress,
  status: TRequestStatus,
  daysProps: DayProps[],
  showDisabled: boolean,
  projectsMap: Map<number, Project>,
}

interface DayProps {
  isHoliday: boolean,
  isWeekend: boolean,
  isToday: boolean,
  personalDayType: string,
  description: string,
}

interface Form {
  selectedYear: number,
  month: number,
  userId: number,
}

interface Project {
  id: number;
  avatar: string;
  name: string;
  clientName: string;
  enabled: boolean;
  days: ProjectDay[];
}

interface ProjectDay {
  isHoliday: boolean,
  isWeekend: boolean,
  isToday: boolean,
  isSuspension: boolean,
  personalDayType: string,

  // A non NaN string to allow input of values like '1.'
  hours?: string,

  comment: string,
  status: string,
  isDirty: boolean,
  entryId?: number,
}

class Timetrack extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeYears: [],
      tableSort: new TableSort('name', 'asc'),
      form: {
        selectedYear: new Date().getFullYear(),
        month: new Date().getMonth(),
        userId: Number(this.props.match.params.id || this.props.loggedUser.id),
      },
      progress: {
        currentStep: 0,
        totalSteps: 5,
      },
      status: 'loading',
      showDisabled: localStorage.getItem('showDisabledTimetrackProjects') === 'false' || localStorage.getItem('showDisabledTimetrackProjects') === null ? false : true,
      daysProps: [],
      projectsMap: new Map(),
      suspensions: [],
      suspensionsByDay: [],
    }
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.form.userId !== this.state.form.userId) {
      this.setUserUrl();
    }
  }

  /**
   * Executed once when the component loads.
   */
  private async initialize() {
    if (!this.checkPathName()) {
      this.props.history.replace('/404');
      return;
    }

    this.setState({
      status: 'loading',
      activeYears: extractYears(this.props.sysProps),
      progress: newProgress(4),
    });

    const [vacations, assignedProjects, worklogs, suspensions] = await Promise.all([
      listUserVacations(this.state.form.userId).then(this.incrementProgress),
      this.fetchAssignedProjects().then(this.incrementProgress),
      this.fetchWorklogs().then(this.incrementProgress),
      listUserSuspensions(this.state.form.userId).then(this.incrementProgress)
    ]);

    const updatedProjects = await this.checkWorklogs(assignedProjects ? assignedProjects : [], worklogs).then(this.incrementProgress);
    const daysProps = this.computeDaysProps(this.context.holidays, vacations, this.state.form.selectedYear, this.state.form.month);
    const projectMap = this.createProjectRows(updatedProjects, worklogs, daysProps);
    this.getSuspensionsByDay(suspensions);
    
    this.setState({
      status: 'success',
      vacations,
      suspensions,
      daysProps,
      projectsMap: projectMap
    });
  }

  setUserUrl = () => {
    this.props.history.replace(`/${this.props.location.pathname.split('/')[1]}/${this.state.form.userId}`);
  }

  /*
   * Check if pathname exist
   *
  */
  checkPathName() {
    let url = this.props.match.params.id;
    return url === undefined || this.context.users.some((user: UserDTO) => url === user.id.toString());
  }

  /*
   * Create array of suspended days
   *
  */
  private getSuspensionsByDay = (suspensions: SuspensionDTO[]) => {
    const suspensionsByDay = processSuspensions(suspensions, this.state.form.selectedYear);

    this.setState({
      suspensionsByDay
    })
  }

  /**
   * Executed on a year or month change.
   *
   * Updated the properties of each day and the worklog of each project.
   */
  private onDateRangeChange = async () => {
    this.setState({
      status: 'loading',
      activeYears: extractYears(this.props.sysProps),
      progress: newProgress(4),
    });

    const [assignedProjects, worklogs, suspensions] = await Promise.all([
      this.fetchAssignedProjects().then(this.incrementProgress),
      this.fetchWorklogs().then(this.incrementProgress),
      listUserSuspensions(this.state.form.userId).then(this.incrementProgress)
    ]);

    const updatedProjects = await this.checkWorklogs(assignedProjects, worklogs).then(this.incrementProgress);
    const daysProps = this.computeDaysProps(this.context.holidays, this.state.vacations || [], this.state.form.selectedYear, this.state.form.month);
    const projectMap = this.createProjectRows(updatedProjects, worklogs, daysProps);
    this.getSuspensionsByDay(suspensions);


    this.setState({
      status: 'success',
      daysProps,
      projectsMap: projectMap,
    });
  }

  /**
   * Executed when the selected user is changed.
   */
  private onUserChange = async () => {
    this.setState({
      status: 'loading',
      progress: newProgress(4),
    });

    const [vacations, assignedProjects, worklogs, suspensions] = await Promise.all([
      listUserVacations(this.state.form.userId).then(this.incrementProgress),
      this.fetchAssignedProjects().then(this.incrementProgress),
      this.fetchWorklogs().then(this.incrementProgress),
      listUserSuspensions(this.state.form.userId).then(this.incrementProgress)
    ]);

    const updatedProjects = await this.checkWorklogs(assignedProjects, worklogs).then(this.incrementProgress);
    const daysProps = this.computeDaysProps(this.context.holidays, vacations, this.state.form.selectedYear, this.state.form.month);
    const projects = this.createProjectRows(updatedProjects, worklogs, daysProps);

    this.getSuspensionsByDay(suspensions);

    this.setState({
      status: 'success',
      vacations,
      suspensions,
      daysProps,
      projectsMap: projects,
    });
  }

  private incrementProgress = <T,>(result: T) => {
    this.setState(prevState => {
      return {
        progress: incrementProgress(prevState.progress),
      }
    });

    return result;
  }

  private async fetchAssignedProjects(): Promise<ProjectDTO[]> {
    return await listAssignedProjects(this.state.form.userId);
  }

  private fetchWorklogs = async () => {
    const dateRange = computeMonthRange(this.state.form.selectedYear, this.state.form.month);
    return await filterWorklogs({
      uid: this.state.form.userId,
      from: dateRange.since,
      until: dateRange.until,
      billable: null
    });
  }

  /**
   * Check if worklogs contain unassigned projects.
   * Return all projects (assigned + unassigned with logged hours).
   */
  private async checkWorklogs(projects: ProjectDTO[], worklogs: Worklog[]): Promise<ProjectDTO[]> {
    let updatedProjects: ProjectDTO[] = [...projects]

    for (const worklog of worklogs) {
      if (!updatedProjects.some(project => project.id === worklog.project.id)) {
        await listProjectDetails(String(worklog.project.id))
          .then(response => { updatedProjects.push({ ...response, enabled: false }) })
      }
    }

    return updatedProjects
  }

  /**
   * Compute the properties for each day of the selected month based on holidays and user vacations.
   */
  private computeDaysProps(holidays: Holiday[], vacations: VacationsDTO[], year: number, month: number): DayProps[] {
    let daysProps = [];

    for (let dayOfMonth = 1; dayOfMonth <= 31; dayOfMonth++) {
      let holidayName = "";
      // check if this day is a holiday
      const isHoliday = holidays.some(holiday => {
        const date = typeof holiday.date === 'string' ? new Date(holiday.date) : holiday.date;
        holidayName = holiday.name;
        return date.getFullYear() === year && date.getMonth() === month && date.getDate() === dayOfMonth;
      });

      // check if this day is Saturday or Sunday
      let currentDay = new Date(year, month, dayOfMonth);
      const isWeekend = [0, 6].includes(currentDay.getDay());

      // check if this day is today
      const today = new Date();
      const isToday = today.getFullYear() === year && today.getMonth() === month && today.getDate() === dayOfMonth;

      // get type of first vacation if any
      const vacation = vacations.find((vacation: VacationsDTO) => {
        const date = typeof vacation.date === 'string' ? new Date(vacation.date) : vacation.date;
        return vacation.deleted === false
          && date.getUTCFullYear() === year
          && date.getMonth() === month
          && date.getDate() === dayOfMonth
      });
      const datType = vacation ? vacation.type : '';

      daysProps.push({
        isHoliday,
        isWeekend,
        isToday,
        personalDayType: datType,
        description: isHoliday ? holidayName : datType + " day",
      })
    }

    return daysProps;
  }

  /**
   * Create project rows based on projects, worklogs and properties of each day.
   */
  private createProjectRows(projects: ProjectDTO[], worklogs: Worklog[], daysProps: DayProps[]): Map<number, Project> {
    const result = new Map();

    projects.forEach(project => {
      const projectWorklogs = worklogs.filter(e => e.project.id === project.id);
      const projectDays = this.createProjectDays(projectWorklogs, daysProps);
      const row = {
        id: project.id,
        avatar: project.avatar,
        name: project.name,
        clientName: project.client.name,
        enabled: project.enabled,
        days: projectDays,
      };
      result.set(project.id, row);
    });

    return result;
  }

  /**
   * Create project days based on the project worklogs and the properties of each day.
   */
  private createProjectDays(projectWorklogs: Worklog[], daysProps: DayProps[]): ProjectDay[] {
    const result = [];
    const nrOfDays = new Date(this.state.form.selectedYear, this.state.form.month + 1, 0).getDate();

    // create a map to avoid looping over worklogs in the loop over the days of the month
    let worklogsMap = new Map();
    projectWorklogs.forEach(worklog => {
      const dayOfMonth = moment.utc(worklog.date).date();
      worklogsMap.set(dayOfMonth, worklog);
    });

    for (let index = 0; index < nrOfDays; index++) {
      const dayWorklog = worklogsMap.get(index + 1);
      const day = {
        ...daysProps[index],
        entryId: dayWorklog?.id,
        hours: dayWorklog?.hours || undefined,
        comment: dayWorklog?.comments || '',
        status: 'idle',
        isDirty: false,
        isSuspension: false,
      };
      result.push(day);
    }

    return result;
  }

  private isDaySuspension = (dayIndex: number) => {
    const date = new Date(this.state.form.selectedYear, this.state.form.month, dayIndex + 2);
    const isSuspension = this.state.suspensionsByDay.some(suspension => trimDate(new Date(suspension.date)) === trimDate(date));

    return isSuspension;
  }

  private updateProjectDay(projectId: number, index: number, obj: Partial<ProjectDay>) {
    this.setState(prev => {
      // clone projectRows, project and days
      const projectRows = new Map(prev.projectsMap);
      const project = {
        ...projectRows.get(projectId)!
      };
      project.days = [...project.days];

      // update target day
      project.days[index] = {
        ...project.days[index],
        ...obj,
      };

      // update projectRows and return updated state
      projectRows.set(projectId, project);
      return { projectsMap: projectRows };
    });
  }

  private onProjectDayChange = (projectId: number, index: number, rawHours: string, comment: string) => {
    const payload: Partial<ProjectDay> = { comment, isDirty: true };

    if (false === Number.isNaN(Number(rawHours))) {
      payload.hours = rawHours;
    }

    this.updateProjectDay(projectId, index, payload);
  }

  private onProjectDayBlur = async (projectId: number, index: number) => {
    const projectRows = new Map(this.state.projectsMap);
    const project = projectRows.get(projectId)!;
    const timeEntry = project.days[index];
    // the worklog for a project day is valid if:
    // - it has been changed and we have a id for the entry
    // - it has been changed and we have hours or comment
    const isValid = timeEntry.isDirty && (timeEntry.entryId || timeEntry.hours || timeEntry.comment !== '');

    if (isValid) {
      const payload = {
        comment: timeEntry.comment,
        date: moment.utc({ year: this.state.form.selectedYear, month: this.state.form.month, day: index + 1 }).toDate(),
        // we always have a value here since it has been tested above
        hours: timeEntry.hours ? timeEntry.hours.toString() : '',
        project: { id: projectId },
        user: { id: this.state.form.userId },
      };

      this.updateProjectDay(projectId, index, { isDirty: false, status: 'loading' });
      const partial: Partial<ProjectDay> = { status: 'success' };

      try {
        if (timeEntry.entryId) {
          await updateWorklog(timeEntry.entryId, payload);
        } else {
          const response = await createWorklog(payload);
          partial.entryId = response.id;
        }

        this.updateProjectDay(projectId, index, partial);

        // set project day back to idle
        setTimeout(() => {
          this.updateProjectDay(projectId, index, { status: 'idle' });
        }, 1000);
      } catch (err) {
        console.error(err);
        this.updateProjectDay(projectId, index, { status: 'error' });
      }
    }
  }

  private setShowDisabled = (checked: boolean) => {
    localStorage.setItem('showDisabledTimetrackProjects', JSON.stringify(checked));
    this.setState({ showDisabled: checked });
  }

  private setDateRange = (year: number, month: number) => {
    this.setState(prev => ({
      form: {
        ...prev.form,
        selectedYear: year,
        month,
      },
    }), this.onDateRangeChange);
  }

  private setUser = (userId: number) => {
    this.setState(prev => ({
      form: {
        ...prev.form,
        userId,
      },
    }), this.onUserChange);
  };

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  render() {
    const columns = new Array(this.state.daysProps.length).fill(0);

    const projects = Array.from(this.state.projectsMap.values())
      .filter(e => {
        return e.enabled || this.state.showDisabled || e.days.some(d => d.hours);
      })
      .sort(this.state.tableSort.sortByColumn);

    const projectTotals: number[] = [];
    let total = 0;

    const updatedProjects = projects.map(p => {
      const projectTotal = p.days.reduce((prev, day) => prev + (Number(day.hours || '')), 0);
      projectTotals.push(projectTotal);
      total += projectTotal;
      const days = p.days.map((projectDay, index) => {
        if (this.isDaySuspension(index)) {
          return {...projectDay, isSuspension: true};
        } else {
          return projectDay;
        }
      })
      return {...p, days: days}
    });

    // compute the total of hours per column
    const columnHours = columns.map((_, index) => {
      return projects.reduce((prev, row) => {
        const day = row.days[index];
        const dayHours = Number(day?.hours) || 0;
        return prev + dayHours;
      }, 0);
    });

    const hasFullAccess = this.props.loggedUser?.role.name === 'Admin' || this.props.loggedUser?.role.name === 'Manager';

    const today = new Date();
    const currentMonth = this.state.form.selectedYear === today.getFullYear() && this.state.form.month === today.getMonth();

    return (
      <div>
        <ProgressBar
          currentStep={this.state.progress.currentStep}
          totalSteps={this.state.progress.totalSteps}
        />
        <ToolbarControls>
          <Filters
            disabled={this.state.status === 'loading'}
            elevatedAccess={hasElevatedAccess(this.props.loggedUser)}
            selectedUser={this.state.form.userId}
            userOptions={this.context.users && this.context.users.filter((user: UserDTO) => user.hasOwnProperty('invisible') ? user.invisible === false : user)}
            selectedYear={this.state.form.selectedYear}
            yearOptions={this.state.activeYears}
            selectedMonth={this.state.form.month}
            monthOptions={Months}
            showDisabled={this.state.showDisabled}
            onUserChange={this.setUser}
            onDateChange={this.setDateRange}
            onShowDisabledChange={this.setShowDisabled}
          />
        </ToolbarControls>
        <BreadcrumbControls
          pageTitle="Timetrack"
          status={this.state.status}
        />
        <div className="flex-row fill">
          <div className="column">
            <div className="timetrack-component">
              <div className={`
                  card
                  ${!loadComplete(this.state.progress) ? 'loader-border' : ''}
                `}
              >
                <div>
                  <table style={{ minHeight: 'calc(100vh - 148px)' }}>
                    {loadComplete(this.state.progress) &&
                      <>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th className="lead-column">
                              <SortButton
                                column='name'
                                text='Projects'
                                tableSort={this.state.tableSort}
                                onClick={this.handleSortChange}
                              ></SortButton>
                            </th>
                            <th>
                              Total
                            </th>
                            {columns.map((_, index) => {
                              return (
                                <th
                                  key={index}
                                  className={[
                                    'month-day',
                                    this.state.daysProps[index].isToday ? 'today' : '',
                                    this.state.daysProps[index].isHoliday ? 'holiday' : '',
                                    this.state.daysProps[index].isWeekend ? 'weekend' : '',
                                    this.isDaySuspension(index) ? 'unpaid': '',
                                    this.state.daysProps[index].personalDayType,
                                  ].join(" ")}
                                >
                                  <span
                                    className={[
                                      "inner-text",
                                      this.state.daysProps[index].personalDayType || this.state.daysProps[index].isHoliday ? "tooltip" : ""
                                    ].join(" ")}
                                  >
                                    {index + 1}
                                    {(this.state.daysProps[index].personalDayType || this.state.daysProps[index].isHoliday) &&
                                      <span className="tooltip-content">{this.state.daysProps[index].description}</span>
                                    }
                                  </span>
                                </th>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {updatedProjects.map((project, index) => {
                            // A project is editable:
                            // - user has elevated access
                            // OR
                            // - the project is enabled and user is looking at the current month
                            // OR
                            // - the user has elavated access and we're showing disabled projects
                            const editable =
                              (hasFullAccess || currentMonth && this.props.loggedUser.id === this.state.form.userId && project.enabled && project.enabled === true)
                              ||
                              (hasFullAccess && this.state.showDisabled);

                            return <ProjectRow
                              total={projectTotals[index]}
                              key={project.id}
                              id={project.id}
                              avatar={project.avatar}
                              name={project.name}
                              clientName={project.clientName}
                              disabled={!editable}
                              days={project.days}
                              onDayChange={this.onProjectDayChange}
                              onDayBlur={this.onProjectDayBlur}
                            />
                          })}
                          <tr><th className="spacer-cell" colSpan={100} style={{ height: '100%', pointerEvents: 'none' }}></th></tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th className="lead-column">
                              <a className="ghost-button" href="">Totals</a>
                            </th>
                            <th className="text-center"><span className="inner-text">{total}</span></th>
                            {columns.map((_, index) => {
                              return (
                                <th key={index} className={
                                  `text-center
                                        ${this.state.daysProps[index].isToday ? 'today' : ''}
                                        ${this.state.daysProps[index].isHoliday ? 'holiday' : ''}
                                        ${this.state.daysProps[index].isWeekend ? 'weekend' : ''}
                                        ${this.isDaySuspension(index) ? 'unpaid': ''}
                                        ${this.state.daysProps[index].personalDayType}
                                      `
                                }>
                                  <span className="inner-text">{columnHours[index]}</span>
                                </th>
                              )
                            })}
                          </tr>
                          <tr>
                            <th colSpan={34}>
                              <AlertBanner pageName="Timetrack" loggedUserId={this.props.loggedUser.id}>
                                <small>
                                  <span className="fas fa-flag"></span> Please do not do overtime if you're working on an internal project, as this will cause a shortage of availability in the near future for external projects.
                                </small>
                                <br>
                                </br>
                              </AlertBanner>
                              <AlertBanner pageName="Timetrack" loggedUserId={this.props.loggedUser.id}>
                                <small>
                                  <span className="fas fa-info-circle"></span> Please log the actual amount of hours you worked each day <em>(even if more or less than normal)</em>. This will help everyone better track if there is undertime or overtime and balance it out over the next days.
                                </small>
                              </AlertBanner>
                            </th>
                          </tr>
                        </tfoot>
                      </>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withTransitionEvent(Timetrack);

Timetrack.contextType = AppContext;