import moment from "moment";
import React, { ChangeEvent, Component } from "react";
import { Link } from "react-router-dom";
import ProjectDTO from "../../../common/api/dtos/Project";
import { listProjects } from "../../../common/api/endpoints/projects";
import { listAssignedProjects } from "../../../common/api/endpoints/users";
import Fetch from "../../../common/helpers/Fetch";
import { getFilteredItems } from "../../../common/helpers/Filter";
import { ChangeSortBy } from "../../../common/helpers/Sorting";
import TableSort from "../../../common/helpers/TableSort";
import { TRequestStatus } from "../../../common/types/RequestStatus";
import ToggleControl from "../../controls/ToggleControl/ToggleControl";
import ToolbarControls from "../../generics/Header/ToolbarControls";
import TextControl from "../../controls/TextControl/TextControl";
import RequestStatus from "../../utils/RequestStatus/RequestStatus";
import SortButton from "../../utils/SortButton/SortButton";
import Thumbnail from "../../utils/Thumbnail/Thumbnail";

interface Props {
  editUserId: number,
}

interface State {
  userPropId: number | undefined | null,
  status: TRequestStatus,
  formStatus: TRequestStatus,
  projects?: ProjectDTO[],
  showDisabled: boolean,
  showOnlyAssignedProjects: boolean,
  tableSort: TableSort,
	formData: FormData,
  filterValue: string,
}

interface FormData {
  projects: ProjectDTO[],
}

class ProjectsForm extends Component<Props, State> {
  availableFilters: string[] = [
    'name',
    'client.name',
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      formStatus: 'idle',
      formData: {
        projects: [],
      },
      userPropId: undefined,
      tableSort: new TableSort('name', 'asc'),
      showDisabled: localStorage.getItem('showDisabledProjects') === 'false' || localStorage.getItem('showDisabledProjects') === null ? false : true,
      showOnlyAssignedProjects: false,
      filterValue: '',
    }
  }

  fetchProjects = async () => {
    this.setState({
      status: 'loading'
    });
    
    const projects = await listProjects();

    this.setState({
        status: 'success',
        projects: projects,
    });
  }

  fetchAssignedProjects = async () => {
    this.setState({
      status: 'loading'
    });
    
    const assignedProjects = await listAssignedProjects(this.props.editUserId);

    this.setState({
      status: 'success',
      formData: {
        projects: assignedProjects.length ? assignedProjects : [],
      }
    });
  }

  handleSortChange = (column: string) => {
    this.setState((prevState: State) => {
      return {
        tableSort: ChangeSortBy(column, prevState.tableSort.sortBy, prevState.tableSort.sortDirection)
      };
    });
  }

  toggleDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showDisabled ? this.setState({ showDisabled: false }) : this.setState({ showDisabled: true });
  }

  toggleVisible = (event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(event.target.name, JSON.stringify(event.target.checked));
    this.state.showOnlyAssignedProjects ? this.setState({ showOnlyAssignedProjects: false }) : this.setState({ showOnlyAssignedProjects: true });
  }

  checkIfAssigned = (projectId: number): boolean => {
    return this.state.formData.projects.map((project: ProjectDTO) => { return project.id; }).indexOf(projectId) === -1 ? false : true;
  }

  componentDidMount() {
    this.fetchProjects();
    this.fetchAssignedProjects();
  }

  updateEntity = async () => {
    this.setState({
      formStatus: 'loading'
    });

    const token = localStorage.getItem('id_token');
    const fetchRequest = new Fetch(`/user/assigned-projects/` + this.props.editUserId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(this.state.formData)
    })
    
    await fetchRequest.fetch();

    this.setState({
      formStatus: fetchRequest.getStatus()
    });
  }

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterValue: ev.target.value
    })
  }

  setAssigned = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentProjectId: number = Number(ev.target.value);
    this.setState((prevState: State) => {
      let assignedProjects = prevState.formData.projects.slice(0);
      let alreadyAssigned = assignedProjects.map((project: ProjectDTO) => { return project.id; }).indexOf(currentProjectId);

      if(alreadyAssigned === -1) {
        let selectedProject = this.state.projects?.filter((project: ProjectDTO) => project.id === currentProjectId)[0];
        if(selectedProject) {
          assignedProjects.push(selectedProject);
        }
      } else {
        assignedProjects.splice(alreadyAssigned, 1);
      }
      
      return {
        formData: {
          ...prevState.formData,
          projects: assignedProjects
        }
      }
    });
  }

  addAllListed = () => {
    const filteredItems = this.getFilteredProjects();

    this.setState((prevState: State) => {
      const existingIds = prevState.formData.projects.map(e => e.id);
      const newItems = filteredItems.filter(item => !existingIds.includes(item.id));
      return {
        formData: {
          ...prevState.formData,
          projects: [...prevState.formData.projects, ...newItems]
        }
      }
    });
  }

  removeAllListed = () => {
    const filteredItems = this.getFilteredProjects();

    this.setState((prevState: State) => {
      const filteredIds = filteredItems.map(u => u.id);
      return {
        formData: {
          ...prevState.formData,
          projects: prevState.formData.projects.filter((element) => !filteredIds.includes(element.id))
        }
      }
    });
  }

  renderToggler = () => {
    if(!this.state.projects) {
      return;
    }

    const filteredItems = this.getFilteredProjects();
    const formIds = this.state.formData.projects.map(u => u.id);
    const diffAdd = filteredItems.filter(item => !formIds.includes(item.id)).length;
    const diffRemove = filteredItems.filter(item => formIds.includes(item.id)).length;

    return (
      <div className="flex-row tight flex-v-center squeeze">
        <div className="column">
          <button
            className="primary-button"
            onClick={this.addAllListed}
            disabled={!diffAdd}
            >
            Add listed {`(${diffAdd})`}
          </button>
        </div>
        <div className="column">
          <button
            className="primary-button"
            onClick={this.removeAllListed}
            disabled={!diffRemove}
          >
            Remove listed {`(${diffRemove})`}
          </button>
        </div>
      </div>
    )
  }

  getFilteredProjects() {
    return getFilteredItems(this.state.filterValue, this.availableFilters, this.state.projects ? this.state.projects : [])
    .filter((project: ProjectDTO) =>
      (project.enabled === true || this.state.showDisabled)
      &&
      (this.state.showOnlyAssignedProjects === false || this.checkIfAssigned(project.id))
    );
  }

  render() {
    const filteredProjects = this.getFilteredProjects().sort(this.state.tableSort.sortByColumn);
    const totalProjects = this.state.projects?.filter((project: ProjectDTO) =>  project.enabled === true || this.state.showDisabled)
    const totalAssignedProjects = this.state.projects?.filter((project: ProjectDTO) => this.checkIfAssigned(project.id));

    return (
      <div>
        <div className="flex-row squeeze flex-v-center tight">
          <ToolbarControls>
            <TextControl
              label="Filter"
              type="text"
              name="filterBox"
              id="filterBox"
              onChange={this.setFilterValue}
              placeholder="Filter"
              srOnly={true}
            />
            <ToggleControl 
              id="showDisabledProjects"
              name="showDisabledProjects" 
              changeMethod={this.toggleDisabled} 
              isChecked={this.state.showDisabled}
              labelText="Disabled"
            />
            <ToggleControl 
              id="showOnlyAssignedProjects"
              name="showOnlyAssignedProjects" 
              changeMethod={this.toggleVisible} 
              isChecked={this.state.showOnlyAssignedProjects}
              labelText="Assigned"
            />
          </ToolbarControls>
        </div>
        <div className={`card ${!this.state.projects ? 'loader-border' : ''}`}>
          {this.renderToggler()}
          <div className="tableview-component flex-row fill">
            <div className="column">
              <div>
                <div className="inner-v-scroll" style={{maxHeight: 'calc(100vh - 315px)'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="text-left">
                          <SortButton
                            column='name'
                            text='Name'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th className="text-left">
                          <SortButton
                            column='client.name'
                            text='Client'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        <th className="text-center">
                          <SortButton
                            column='billable'
                            text='Billable'
                            tableSort={this.state.tableSort}
                            onClick={this.handleSortChange}
                          ></SortButton>
                        </th>
                        {this.state.showDisabled &&
                          <th className="text-center">
                            <SortButton
                              column='enabled'
                              text='Enabled'
                              tableSort={this.state.tableSort}
                              onClick={this.handleSortChange}
                            ></SortButton>
                          </th>
                        }
                        <th className="text-center">
                          Assigned
                        </th>
                      </tr>
                    </thead>
                    {this.state.projects &&
                      <React.Fragment>
                        <tbody>
                          {filteredProjects && filteredProjects.map(project => (
                            <React.Fragment key={project.id}>
                              <tr id={`project${project.id}`}>
                                <td></td>
                                <td>
                                  <div className="flex-row fill">
                                    <div className="column flex-v-center">
                                      <Thumbnail
                                        thumbnailURL={project.avatar}
                                        classes="thumbnail"
                                      />
                                      <span>{project.name}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span>{project.client?.name}</span>
                                </td>
                                <td className="text-center">
                                  {project.billable && <span className="fas fa-check"></span>}
                                </td>
                                {this.state.showDisabled &&
                                  <td className="text-center">
                                    {project.enabled &&
                                      <span className="fas fa-check"></span>
                                    }
                                  </td>
                                }
                                <td className="text-center">
                                  <ToggleControl 
                                    id={`project-${project.id}`}
                                    name={`project-${project.id}`} 
                                    changeMethod={this.setAssigned} 
                                    isChecked={this.checkIfAssigned(project.id)}
                                    value={String(project.id)}
                                    labelText={this.checkIfAssigned(project.id) ? 'ON' : 'OFF'}
                                    classes="text-center"
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {this.state.projects?.length === 0 &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk"> <Link className="link-button" to="/project/add">adding</Link> the first one.</p>
                                </div>
                              </th>
                            </tr>
                          }
                          {this.state.filterValue &&
                            <tr>
                              <th colSpan={8}>
                                <div className="card">
                                  <p className="text-chunk">Showing {filteredProjects.length} out of {this.state.showOnlyAssignedProjects ? totalAssignedProjects?.length : totalProjects?.length} results.</p>
                                </div>
                              </th>
                            </tr>
                          }
                        </tfoot>
                      </React.Fragment>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
            <button
              className="primary-button"
              onClick={this.updateEntity}
              disabled={Boolean(!this.state.projects)}
            >
              <RequestStatus status={this.state.formStatus} />
              <span className="text">Save</span>
            </button>
          </div>
      </div>
    );
  }
}

export default ProjectsForm;